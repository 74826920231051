import React, { useState } from "react";
import * as PropTypes from "prop-types";
import TableComponent from "../../../components/TableComponent/TableComponent";
import TableToolbarComponent from "../../../components/TableComponent/TableToolbarComponent";
import { useCheckboxStorage, useModal, usePerPage } from "../../../../hooks/modal.hook";
import DButton from "../../../components/Button";
import { useHistory } from "react-router-dom";
import { goToImportDataPage } from "../../Transaction/utils";
import EditAddTransactionModal from "../../Transaction/CustomersSuppliers/EditAddTransactionModal";
import withConfirmHook from "../../../../hooks/withConfirm.hook";
import TableCell from "@material-ui/core/TableCell";
import TransactionModal, {valuesTitle} from "../../Transaction/TransactionModal/TransactionModal";
import { headRowsModal } from "../../Transaction/EnterTransactionsBanking/headRowsModal";
import { filterItems } from "../../utils";
import useSuppliersCustomers from "../../../../hooks/suppliersCustomers.hook";

const CustomersSuppliersWizard = ({ confirm, step }) => {
  const [filterCheckboxes, setFilterCheckboxes] = useCheckboxStorage("categoriesWizard", filterItems);
  const [currentRow, setCurrentRow] = useState(null);
  const [isEditModalShow, toggleEditModal] = useModal();
  const [isNewModalShow, toggleNewModal] = useModal();
  const [isDownloadModalShow, toggleDownloadModal] = useModal();
  const [isReceiptUploadsModalShow, toggleReceiptUploadsModal] = useModal();
  const [rowsPerPage, onPerPage] = usePerPage();
  const [isTransactionModalShow, toggleTransactionModal] = useModal();
  const [isExportModalShow, toggleExportModal] = useModal();
  // eslint-disable-next-line
  const [searchQuery, setSearchQuery] = useState(null);

  const {
    customers,
    suppliers,
    deleteCustomer,
    deleteSupplier
  } = useSuppliersCustomers({ customers: true, suppliers: true });

  const headRowsCustomer = [
    {id: 'customer_ref', numeric: false, disablePadding: true, label: 'Customer Reference', isActive: true},
    {
      id: 'name', numeric: false, disablePadding: true, label: 'Display Name', isActive: true,
      render: (row, {onNameClick}) => {
        return <TableCell align="left" key={`${row.id}--name`} onClick={onNameClick(row)} className="table__cell--link">
          <span className="table__cell--link__text">{row.name}</span>
        </TableCell>
      }
    },
    {id: 'actions', label: 'Action', isActive: true,}
  ];

  const headRowsSupplier = [
    {id: 'supplier_ref', numeric: false, disablePadding: true, label: 'Supplier Reference', isActive: true},
    {
      id: 'name', numeric: false, disablePadding: true, label: 'Display Name', isActive: true,
      render: (row, {onNameClick}) => {
        return <TableCell align="left" key={`${row.id}--name`} onClick={onNameClick(row)} className="table__cell--link">
          <span className="table__cell--link__text">{row.name}</span>
        </TableCell>
      }
    },
    {id: 'actions', label: 'Action', isActive: true,}
  ];

  const title = step ? "Customers" : "Suppliers";

  let [customersColumns, setCustomersColumns] = useCheckboxStorage("customersWizard", headRowsCustomer);
  let [suppliersColumns, setSuppliersColumns] = useCheckboxStorage("suppliersWizard", headRowsSupplier);
  let activeColumns =  step ? customersColumns : suppliersColumns;
  let setActiveColumns =  step ? setCustomersColumns : setSuppliersColumns;

  const onRefresh = () => {};

  const onEdit = (row) => () => {
    setCurrentRow(row);
    toggleEditModal(!isEditModalShow);
  };

  const onCloseEdit = () => {
    toggleEditModal();
    setCurrentRow(null);
  };

  const onDownloadPDF = (row) => () => {
    setCurrentRow(row);
    toggleDownloadModal(!isDownloadModalShow);
  };

  const onAttachments = (row) => () => {
    setCurrentRow(row);
    toggleReceiptUploadsModal(!isReceiptUploadsModalShow);
  };

  const goToPage = (row) => () => {
    setCurrentRow(row);
  };

  const onNameClick = (row) => () => {
    toggleTransactionModal(!isTransactionModalShow);
    setCurrentRow(row);
  };

  const onDelete = (row) => (
    confirm(() => {
        if (step) {
          deleteCustomer({ id: row.id })
        } else {
          deleteSupplier({ id: row.id })
        }
      },
      {description: 'This action is permanent!'})
  );

  const onExportPDF = () => toggleExportModal(!isExportModalShow);
  const onExport = () => console.log("onExport");

  let history = useHistory();
  const dataName = step ? "Customer" : "Supplier";
  const onImportData = () => goToImportDataPage(history, dataName);

  return (
    <>
      <TableToolbarComponent
        title={title}
        setSearchQuery={setSearchQuery}
        actionsHeader={{
          onRefresh,
          onExport,
          selectMenu: { setFunc: onPerPage, type: "select" },
          filterMenu: { array: filterCheckboxes, setFunc: setFilterCheckboxes, type: "filter" },
          viewColumnMenu: { array: activeColumns, type: "filter", setFunc: setActiveColumns, disabled: true },
        }}
      />

      <TableComponent
        headRows={activeColumns.filter(column => column.isActive)}
        items={step ? customers : suppliers}
        rowsPerPageSelected={rowsPerPage}
        rowProps={{ goToPage, onEdit, onDownloadPDF, onAttachments, onDelete, onNameClick }}
      />
      <div className="container-button container-button--modal">
        <DButton typeOfButton="add" onClickCustom={toggleNewModal}>
          {step ? "Add New Customer" : "Add New Supplier"}
        </DButton>
        <DButton typeOfButton="add" onClickCustom={onImportData}>
          {step ? "Import Customers" : "Import Suppliers"}
        </DButton>
      </div>
      <EditAddTransactionModal
        show={isNewModalShow}
        onClose={toggleNewModal}
        currentTab={step ? "customers" : "suppliers"}
      />
      {currentRow && <EditAddTransactionModal
        show={isEditModalShow}
        onClose={onCloseEdit}
        currentTab={step ? "customers" : "suppliers"}
        initValues={currentRow}
      />}
      {currentRow &&
      <TransactionModal
        data={currentRow}
        show={isTransactionModalShow}
        onClose={toggleTransactionModal}
        headRowsModal={headRowsModal}
        valueTab={step ? valuesTitle.CUSTOMERS : valuesTitle.SUPPLIERS}
        onExportPDF={onExportPDF}
      />
      }
    </>
  );
};

CustomersSuppliersWizard.propTypes = {
  confirm: PropTypes.func,
  step: PropTypes.number,
};

export default withConfirmHook(CustomersSuppliersWizard);