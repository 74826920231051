import { requests } from "./utils";

export const ENDPOINT = "/company";

export default {
    update: (id, data) => requests.put(`${ENDPOINT}/${id}`, data),
    all: () => requests.get(ENDPOINT),
    getUserCompany: (userId) => {
        return requests.get(`${ENDPOINT}/${userId}`)
    },
    updateCompany: (id, values) => requests.put(`/company/${id}`, values),
    deleteCompany: (id) => requests.del(`/company/${id}`),
    // Officers
    getOfficers: () => requests.get(`/officers`),
    addOfficer: (values) => requests.post(`/officers`, values),
    updateOfficer: (id, values) => requests.put(`/officers/${id}`, values),
    deleteOfficer: (id) => requests.del(`/officers/${id}`),

    //VAT Schemes
    getVATSchemes: () => requests.get(`/vat_schemes`),
    addVATScheme: (values) => requests.post(`/vat_schemes`, values),
    updateVATScheme: (id, values) => requests.put(`/vat_schemes/${id}`, values),
    deleteVATScheme: (id) => requests.del(`/vat_schemes/${id}`),
};
