import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import * as builder from "../ducks/builder";
import classNames from "clsx";

function KtContent({ children, contentContainerClasses, containerWidth, urlCurrent }) {
  let location = useLocation();

  const ref = useRef();

  useEffect(() => {
    if (!ref || !ref.current) {
      return;
    }

    const element = ref.current;
    element.classList.remove("kt-grid--animateContent-finished");

    const timeoutId = setTimeout(() => {
      element.classList.add("kt-grid--animateContent-finished");
    }, 1);

    return () => {
      clearTimeout(timeoutId);
      element.classList.remove("kt-grid--animateContent-finished");
    };
  }, [location]);

  // const fulWidthPage = ["dashboard", "products", "sales", "repricer", "reconciliation", 'jorney', "books", "tax", "reports"];
  // const fullWidth = fulWidthPage.find(name => urlCurrent.includes(name)) && "kt-container--fluid";
  const fixedWidthPage = ["settings"];
  const fullWidth = !fixedWidthPage.find(name => urlCurrent.includes(name)) && "kt-container--fluid";
  const widthBlock = containerWidth && "kt-container--fluid";

  return (
    <div
      ref={ref}
      className={classNames(
        "kt-grid--animateContent kt-container kt-grid__item kt-grid__item--fluid kt-grid--animateContent-finished",
        fullWidth,
        widthBlock,
        contentContainerClasses
      )}
    >
      {children}
    </div>
  );
}

const mapStateToProps = store => ({
  contentContainerClasses: builder.selectors.getClasses(store, {
    path: "content_container",
    toString: true
  })
});

export default connect(mapStateToProps)(KtContent);
