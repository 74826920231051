import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {momentFormatOptions} from "../../home/utils";

export const formatDatePicker = (date) => moment(date, momentFormatOptions.display).format(momentFormatOptions.backend);

const DateTimeField = (props) => {
  const { label, name, value, setFieldValue, disabled, fullWidth = true, handleChange, handleBlur } = props;
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    value && setSelectedDate(moment(value, momentFormatOptions.backend).format(momentFormatOptions.display));
  }, [value]);

  function handleDateChange(date, dateString) {
    const formattedDate = date && formatDatePicker(date);
    console.log("formattedDate", formattedDate)
    if (handleChange) {
      handleChange(formattedDate)
    } else {
      setFieldValue(name, formattedDate)
    }
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} className="form-group">
      <KeyboardDatePicker
        className="form-group__date-picker"
        fullWidth={fullWidth}
        margin="dense"
        label={label}
        value={selectedDate}
        onChange={handleDateChange}
        onBlur={handleBlur}
        format="dd MMM yyyy"
        // format="dd/MM/yyyy"
        disabled={disabled}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        views={["year", "month", "date"]}
        autoOk
      />
    </MuiPickersUtilsProvider>
  )
};

DateTimeField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setFieldValue: PropTypes.func,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
};

export default DateTimeField;