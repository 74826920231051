import React from "react";
import BottomHeaderCategories from "./BottomHeaderCategories";
import TableCell from "@material-ui/core/TableCell";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import * as PropTypes from "prop-types";

export const headRows = (step) => {
  return [
  {id: 'nominal_code', numeric: false, disablePadding: true, label: 'CC', isActive: true, align: "center"},
  {
    id: 'name', numeric: false, disablePadding: true, label: 'Category', isActive: true,
    render: (row, {onNameClick}) => {
      return <TableCell align="left" key={`${row.id}--name`} onClick={onNameClick(row)} className="table__cell--link">
        <span className="table__cell--link__text">{row.name}</span>
      </TableCell>
    }
  },
  ...step
    // for Wizard
    ? [{
      id: 'currency', numeric: false, disablePadding: true, label: 'Currency', isActive: true,
      renderText: (row) => {
        return row.currency ? row.currency.currency_code : "GBP";
      },
    },
      {
        id: 'conv_rate', numeric: false, disablePadding: true, label: 'Conv. Rate', isActive: true,
        renderText: (row) => {
          return <FormControl margin="dense">
            <InputLabel htmlFor="component-error" margin="dense"/>
            <Input
              id="conv_rate"
              name="conv_rate"
              type="number"
              value={row.conv_rate || ''}
              disabled={true}
            />
          </FormControl>
        },
      }]
    //  for Categories
    : [{
      id: 'category_type', numeric: false, disablePadding: true, label: 'Category type', isActive: true, align: "center",
      renderText: (row) => {
        return row.category_type ? row.category_type : 'Asset';
      },
    },
      {
        id: 'status', numeric: false, disablePadding: true, label: 'Status', isActive: true, align: "center",
        renderText: (row) => {
          return row.status && row.status === true ? 'Active' : 'In active';
        },
      }],
  ...step
    // for Wizard
    ? [{
      id: 'debit', numeric: false, disablePadding: true, label: 'Debit', isActive: true,
      renderText: (row) => {
        return <FormControl margin="dense">
          <InputLabel htmlFor="component-error" margin="dense"/>
          <Input
            id="debit"
            name="debit"
            type="number"
            value={row.debit || ''}
            disabled={true}

          />
        </FormControl>
      },
    },
      {
        id: 'credit', numeric: false, disablePadding: true, label: 'Credit', isActive: true,
        renderText: (row) => {
          return <FormControl margin="dense" className="categories-wizard__cell--number">
            <InputLabel htmlFor="component-error" margin="dense"/>
            <Input
              id="credit"
              name="credit"
              type="number"
              value={row.credit || ''}
              disabled={true}
            />
          </FormControl>
        }
      }]
    //  for Categories
    : [{id: 'debit', numeric: false, disablePadding: true, label: 'Debit', isActive: true, align: "center"},
      {id: 'credit', numeric: false, disablePadding: true, label: 'Credit', isActive: true, align: "center"}],
  {id: 'actions', numeric: false, disablePadding: true, label: 'Actions', align: 'right', isActive: true},
]};

headRows.propTypes = {
  step: PropTypes.number,
};

export const datePicker = ({ onRefresh, errorDate, getStartDate, startDate, getEndDate, endDate }) => {
  const fieldsDate = [
    { id: "date_start", label: "Financial Year Start Date", function: getStartDate, value: startDate },
    { id: "date_end", label: "Financial Year End Date", function: getEndDate, value: endDate },
  ];
 return <BottomHeaderCategories
    className="categories__data-picker offset-md-7 col-sm-12 col-md-5 "
    fieldsDate={fieldsDate}
    onDateFilter={onRefresh}
    errorDate={errorDate}
 />;
};

datePicker.propTypes = {
  errorDate: PropTypes.bool,
  onRefresh: PropTypes.func,
  getStartDate: PropTypes.func,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  getEndDate: PropTypes.func,
};

export const cloneCategory = (categories) => categories.map(category => {
  let clone = {};
  for (let key in category) {
    if (key === "nominal_code") {
      clone[key] = +category[key]
    } else {
      clone[key] = category[key]
    }
  }
  return clone;
});