import React, { useEffect, useState } from "react";
import { TableCell, TableRow } from '@material-ui/core';
import * as BankSelector from "../../../../../selectors/bank.selectors";
import * as importDuck from "../../../../../store/ducks/import.duck";
import { connect } from "react-redux";
import * as PropTypes from "prop-types";
import { useModal, useParseHistory } from "../../../../../hooks/modal.hook";
import * as BankDuck from "../../../../../store/ducks/bunk.duck";
import * as Selector from "../../../../../selectors/imortBankTransactions.selectors";
import TransactionForm from "../TransactionForm/TranasactionForm";
import ActionButtonsImportTransactions from "../TransactionForm/ActionButtonsImportTransactions";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import IconButton from "@material-ui/core/IconButton";
import useTransaction from "../../../../../hooks/transaction.hook";
import BankRuleModal from "../../../Tools/BankRules/BankRuleModal";
import withConfirmHook from "../../../../../hooks/withConfirm.hook";
import Spinner from "../../../../components/Spinner";
import DButton from "../../../../components/Button";
import { useHistory } from "react-router-dom";
import { goToImportsPage, goToNewImportPage } from "../../utils";
import PopperMenu from "../../../../components/PopperMenu";
import AddIcon from '@material-ui/icons/Add';

const SHOW_BY_DEFAULT_COUNT = 10;

const formatServerErrors = (data) => {
  let msg = '';
  Object.entries(data).forEach(([key, value]) => {
    msg += key + ': ' + value.map((e) => e).join(',');
  });
  return msg;
};

const CheckTab = (props) => {
  const [isBankModalShow, toggleBankModalShow] = useModal();
  const [isAddNewBank, setAddNewBank] = useModal();
  const {
    tabProps: { bank, getBankEntry, getBank },
    bankEntry,
    confirm,
  } = props;
  const bankEntryId = useParseHistory().bank_entry_id;
  const bankId = useParseHistory().bank_account_id;
  const [showingCount, setShowingCount] = useState(SHOW_BY_DEFAULT_COUNT);
  const [currentRow, setCurrentRow] = useState();
  const [extraInfo, setExtraInfo] = useState({});
  const history = useHistory();

  const { transactions, transactionsLoading, confirmTransaction, ignoreTransaction } = useTransaction({
    filters: { check: true }
  });

  useEffect(() => {
    getBank({ id: bankId });
    getBankEntry({ id: bankEntryId });
  }, []);
  if (!bank || bankEntry.loading || transactionsLoading) {
    return <Spinner className="table__spinner" />
  }

  const showMore = () => {
    setShowingCount(showingCount + SHOW_BY_DEFAULT_COUNT);
  };

  const initialData = {
    account_type: "BankAccount",
    transactions,
  };

  const onSubmit = () => {};

  const onConfirm = ({ id, values, index }) => {
    confirmTransaction({
      id,
      values,
      onDone: () => {
        console.log('done');
      },
      onError: (err) => {
        setExtraInfo({
          ...extraInfo,
          [index]: formatServerErrors(err.response.data),
        })
      }
    });
  };

  const renderRowInfo = ({ index, columnsLength }) => {
    const info = extraInfo[index];
    if (info) {
      return <TableRow key={index + 'expand'}>
        <TableCell colSpan={columnsLength}>
          {info}
        </TableCell>
      </TableRow>
    }
    return null;
  };

  const openBankModal = (row) => {
    setCurrentRow(row);
    toggleBankModalShow(true);
  };

  const addNewBankRule = (row) => {
    openBankModal(row);
    setAddNewBank(true);
  };

  const editBankRule = (row) => {
    openBankModal(row);
    setAddNewBank(false);
  };

  const onAddMore = () => {goToNewImportPage(history, bank.id)};
  const onBack = () => {
    goToImportsPage(history)
  };

  const onDelete = (row) => {
    confirm(() => {
        ignoreTransaction({ id: row.id })
      },
      { description: 'This action is permanent!' })()
  };

  const quantityTrans = transactions.length;

  return <div>
    <TransactionForm
      initialData={initialData}
      bank={bank}
      onSubmit={onSubmit}
      actionComponent={(props) => (
        <ActionButtonsImportTransactions
          {...props}
          addNewBankRule={() => addNewBankRule(props.row)}
          editBankRule={() => editBankRule(props.row)}
          onDelete={() => onDelete(props.row)}
        />)}
      extraColumns={[
        {
          id: "confirm",
          label: "Confirm",
          render: ({ id, values, index }) => {
            return (
              <IconButton
                className="action-buttons-table action-buttons-table--confirm"
                color="secondary"
                onClick={() => onConfirm({ id, values, index })}
              >
                <CheckBoxIcon/>
              </IconButton>
            )
          },
        }
      ]}
      renderRowInfo={renderRowInfo}
    />
    <div className="container-button container-button--modal container-button--multi">
      <div>
        <PopperMenu
          contentBtn={<DButton typeOfButton="arrow">Actions</DButton>}
          popperProps={{
            array: [
              { func: onAddMore, content: <><AddIcon />Add More</> },
              // { func: onRemove, content: <><DeleteIcon />Remove</> },
            ]
          }}
          styleEl="transactions"
        />
        <DButton typeOfButton="backPrimary" onClickCustom={onBack}>Back</DButton>
      </div>
      <div>
        Showing transactions 1 to {quantityTrans} of {100}
        <DButton typeOfButton="search" onClickCustom={showMore}>Show more</DButton>
      </div>
    </div>

    {currentRow
    && <BankRuleModal
      isAddNewBank={isAddNewBank}
      transaction={currentRow}
      show={isBankModalShow}
      onClose={() => toggleBankModalShow(false)}
    />}
  </div>
};

CheckTab.propTypes = {
  getBank: PropTypes.func.isRequired,
  bank: PropTypes.object,
  importFormats: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    importFormats: Selector.selectImportFormats(state, props),
    bank: BankSelector.getBank(state, props),
    banks: BankSelector.getBanks(state, props),
    loading: Selector.getLoading(state, props),
    bankEntry: Selector.selectBankEntry(state, props),
  }
};

const mapDispatchToProps = {
  getBank: BankDuck.actions.getBank,
  getBanks: BankDuck.actions.getBanks,
  getBankEntry: importDuck.actions.getBankEntry,
  createBankEntry: importDuck.actions.createBankEntry,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withConfirmHook(CheckTab));