import moment from 'moment'
import {addMonths, parseISO} from 'date-fns'
import _ from "lodash";

export const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
]

export const years = _.range(moment().subtract(12, "years").year(), moment().add(3, "year").year(), 1);

export const bimonthlyDates = {
    first_entry: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
    second_entry: [15,16,17,18,19,20,21,22,23,24,25,26,27,28,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17]
}

export const GetMonthName = (date, monthCount, months) => {
   let newDate = addMonths(parseISO(date), monthCount);

    return months[moment(newDate).month()]
}