import React, {useEffect, useState} from "react";
import useReports from "../../../hooks/reports.hook";
import {FieldArray, Form as FormikForm, Formik} from 'formik'
import {
  onSubmit,
  newExpense,
  limitExpenses,
  checkOpen,
  onExpand,
  onAction,
} from "./utils";
import {ExpensesActions} from "./expensesActions";
import withConfirmHook from "../../../hooks/withConfirm.hook";
import ExpensesRows from "./ExpensesRows";
import {Pagination} from "@material-ui/lab";
import {getCountPage} from "../../components/TableComponent/TableComponent";
import DSplashScreen from "../../components/SplashScreen/DSplashScreen";
import TransactionsRows from "./TransactionsRows";
import EmptyBlock from "../../components/EmptyBlock";
import _ from "lodash";


const ExpensesContent = ({confirm}) => {
  const {
    expenses,
    getExpenses,
    updateExpenses,
    deleteExpenses,
    getExpensesCategories,
    expensesCount,
    expensesLoading,
    expensesTransactions,
  } = useReports();

  const [page, setPage] = useState(1);
  const [countPage, setCountPage] = useState(0);
  const [countRows, setCountRows] = useState(0);
  const [offset, setOffset] = useState(0);
  const [opened, setOpened] = useState([]);
  const [saveFormTouched, setSaveFormTouched] = useState({});

  const [saveFormValues, setSaveFormValues] = useState({
    expenses: {},
    transactions: {},
  });

  useEffect(() => {
    getExpensesCategories();
  }, []);

  useEffect(() => {
    setOffset((page - 1) * limitExpenses);
  }, [page]);

  useEffect(() => {
    setCountPage(getCountPage({totalCount: expensesCount, rowsPerPage: limitExpenses}));
    // if (!countRows) {
    //   console.log('expensesCount');
    //   getExpenses({limit: limitExpenses, offset});
    // }
  }, [expensesCount]);

  useEffect(() => {
    if (!saveFormValues.expenses[offset]) {
      getExpenses({limit: limitExpenses, offset});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  useEffect(() => {
    setCountRows(saveFormValues?.expenses?.[offset]?.length || 0);
  }, [page, saveFormValues?.expenses]);

  useEffect(() => {
    if (!countRows) {
      if (page === countPage) {
        setPage(page - 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countRows]);

  useEffect(() => {
    if (_.isEmpty(saveFormValues.expenses) && !expensesLoading) {
      setSaveFormValues((values) => ({
        ...values,
        expenses: {
          0: [newExpense]
        }
      }))
    }
    if (expenses.length && !expensesLoading) {
      setSaveFormValues((values) => {
          const oldValues = values.expenses?.[offset]?.filter((item) => item.id) || [];
          return ({
            ...values,
            expenses: {
              ...values.expenses,
              [offset]: expenses.reduce((res, val) => {
                const isRepeat = res.some((item) => item.id === val.id);
                return isRepeat ? res : [...res, val];
              }, oldValues)
            }
          })
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expenses]);

  useEffect(() => {
    setSaveFormValues((values) => {
      const old = values.transactions;
      const updateTransactions = Object.entries(expensesTransactions).reduce((res, [expenseId, expanseData]) => {
        const {data} = expanseData;
        const oldTransactions = old[expenseId]?.data;
        if (oldTransactions) {
          const updateData = Object.entries(data).reduce((allData, [offset, values]) => {
            if (oldTransactions[offset]) {
              return allData
            }

            return [...allData, [offset, values]];
          }, Object.entries(data));

          return [...res, [expenseId, {...expanseData, data: Object.fromEntries(updateData)}]]
        }

        return [...res, [expenseId, expanseData]]
      }, Object.entries(old));

      return {
        ...values,
        transactions: Object.fromEntries(updateTransactions)
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expensesTransactions]);

  const addNewLine = () => setSaveFormValues((values) => ({
    ...values,
    expenses: {
      ...values.expenses,
      [offset]: [...(values.expenses?.[offset] || []), newExpense]
    }
  }));

  const onRemoveLine = ({index}) => {
    setSaveFormValues((values) => ({
      ...values,
      expenses: {
        ...values.expenses,
        [offset]: values.expenses[offset].filter((item, ndx) => ndx !== index)
      }
    }))
  };

  const onMore = (rowsId) => setOpened(rowsId);

  const onChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const onFormValues = (values) => setSaveFormValues(values);
  const onFormTouched = (touched) => setSaveFormTouched(touched);

  return (
    <Formik
      enableReinitialize
      initialValues={saveFormValues}
      initialTouched={saveFormTouched}
      onSubmit={onSubmit({updateExpenses})}
    >
      {({...form}) => {
        return <FormikForm>
          <div className="cog-settings__tab content__tab expenses">
            <div className="expenses-settings__tab__forms page__table__wrap ">
              <FieldArray name="expenses">
                {(arrayHelpers) => {
                  const {values} = arrayHelpers?.form;
                  const rowData = values?.expenses?.[offset];
                  const isProcessing = expensesLoading || !!(!rowData && expenses.length);
                  return <div className="cog-settings__expenses-container">
                    <div className="d-splash-screen--wrap">
                      {isProcessing && <DSplashScreen/>}

                      {!!(!rowData && expenses.length) && (
                        <EmptyBlock />
                      )}

                      {rowData?.map((valuesRow, index) => {
                        return (
                          <div className="cog-settings__expense" key={index}>
                            <ExpensesRows
                              opened={opened}
                              isOpenRow={checkOpen({opened, rowId: valuesRow.id})}
                              onAction={onAction({
                                confirm,
                                index,
                                idExpense: valuesRow.id,
                                onRemoveLine,
                                deleteExpenses,
                                offset
                              })}
                              onExpand={onExpand({idExpense: valuesRow.id, opened, onMore})}
                              form={form}
                              index={index}
                              values={valuesRow}
                              onFormValues={onFormValues}
                              onFormTouched={onFormTouched}
                              offset={offset}
                            />
                            <TransactionsRows
                              form={form}
                              id={valuesRow.id}
                              opened={opened}
                              onFormValues={onFormValues}
                              onFormTouched={onFormTouched}
                              method={valuesRow.calculation_method}
                            />
                          </div>
                        )
                      })}
                    </div>
                    <div className="table__pagination-block">
                      <Pagination
                        color="primary"
                        size="small"
                        page={page}
                        defaultPage={1}
                        siblingCount={2}
                        count={countPage}
                        onChange={onChangePage}
                      />
                    </div>
                    <ExpensesActions addNewLine={addNewLine} form={form} disabled={_.isEmpty(saveFormTouched)}/>
                  </div>
                }}
              </FieldArray>
            </div>
          </div>
        </FormikForm>
      }}
    </Formik>
  )
};

export default withConfirmHook(ExpensesContent);