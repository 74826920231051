import React, { useState } from "react";
import TableComponent from "../../../components/TableComponent/TableComponent";
import { useModal } from "../../../../hooks/modal.hook";
import NewRecurringModal from "./NewRecurringModal";
import DButton from "../../../components/Button";
import withConfirmHook from "../../../../hooks/withConfirm.hook";
import * as PropTypes from "prop-types";
import CreateEditRecurTransModal from "./CreateEditRecurTransModal";
import { goToDetailsRecurringTransactionsPage, goToEditRecurringInvoicesPage } from "../../Transaction/utils";
import { useHistory } from "react-router-dom";
import { goToEditRecurAdjustmentPage } from "../../Category/utils";

function RecurringTransactionsTable({ confirm, rowsPerPage, activeColumns, items }) {
  const [isNewRecurringModalShow, toggleNewRecurringModal] = useModal();
  const [currentRow, setCurrentRow] = useState(null);
  const [isNewRecurTransModalShow, toggleNewRecurTransModalShow] = useModal();
  const history = useHistory();

  const reset = () => setCurrentRow(null);

  const onCloseCreateEditModal = () => {
    setCurrentRow(null);
    toggleNewRecurTransModalShow();
  };

  const onEdit = (row) => () => {
    if (row.type.value === "Adjustment") {
      goToEditRecurAdjustmentPage(history, row.id, row.type.value)
    } else {
      goToEditRecurringInvoicesPage(history, row.id, row.type.value)
    }
  };

  const onEditTemplate = (row) => () => {
    setCurrentRow(row);
    toggleNewRecurTransModalShow();
  };

  const onSearch = (row) => () => {
    goToDetailsRecurringTransactionsPage(history, row.id);
  };

  const onDelete = (row) => (
    confirm(() => {
        // deleteRec({
        //   id: row.id,
        // })
      },
      {
        description: 'This action is permanent!'
      })
  );

  return <>
    <TableComponent
      headRows={activeColumns.filter(column => column.isActive)}
      items={items}
      rowsPerPageSelected={rowsPerPage}
      rowProps={{
        onEdit,
        onDelete,
        onEditTemplate,
        onSearch
      }}
    />
    <div className="container-button">
      <DButton typeOfButton="add" onClickCustom={toggleNewRecurringModal} >Add New Recurring Transaction</DButton>
    </div>
    <NewRecurringModal show={isNewRecurringModalShow} onClose={toggleNewRecurringModal}/>
    <CreateEditRecurTransModal
      show={isNewRecurTransModalShow}
      onClose={onCloseCreateEditModal}
      initValues={currentRow}
      reset={reset}
    />
  </>
}

RecurringTransactionsTable.propTypes = {
  confirm: PropTypes.func.isRequired,
};

export default withConfirmHook(RecurringTransactionsTable);
