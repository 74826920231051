import React from "react";
import EmptyBlock from "../../components/EmptyBlock";
import Wrapper from "../../components/Wrapper";
import "./tax-styles.scss"

const Tax = () => {
  return (
    <Wrapper className="tax-page">
      <EmptyBlock>In Development</EmptyBlock>
    </Wrapper>
  )
};

export default Tax;