import moment from "moment";
import {momentFormatOptions} from "../../utils";

export const headRowsRecurring = [
  {
    id: 'type', numeric: false, disablePadding: true, label: 'Type', isActive: true,
    renderText: (row) => row.type.label,
  },
  {id: 'name', numeric: false, disablePadding: true, label: 'Display Name', isActive: true},
  {id: 'frequency', numeric: false, disablePadding: true, label: 'Frequency', isActive: true},
  {
    id: 'start_date', numeric: false, disablePadding: true, label: 'Start Date', isActive: true,
    renderText: (row) => moment(row.start_date).format(momentFormatOptions.display)
  },
  {
    id: 'next_date', numeric: false, disablePadding: true, label: 'Next Date', isActive: true,
    renderText: (row) => moment(row.next_date).format(momentFormatOptions.display)
  },
  {id: 'status', numeric: false, disablePadding: true, label: 'Status', isActive: true},
  {id: 'actions', numeric: false, disablePadding: true, label: 'Action', isActive: true}
];

export const headRowsRecurred = [
  {id: 'date', numeric: false, disablePadding: true, label: 'Date', isActive: true,
    renderText: (row) => row.next_date,
  },
  {id: 'ref', numeric: false, disablePadding: true, label: 'Ref', isActive: true},
  {id: 'description', numeric: false, disablePadding: true, label: 'Description', isActive: true},
  {id: 'total', numeric: false, disablePadding: true, label: 'Total', isActive: true}
];

export const items = [
  {
    id: 2,
    type: { label: "Customer Invoice", "value": "CI" },
    name: "Unallocated account",
    frequency: "Every 1 month",
    start_date: "2020-01-17",
    next_date: "2020-09-17",
    ref: "ref",
    description: "description",
    total: 123,
    repeat: 2,
    repeat_period: { label: "Months", "value": "months"},
    set_end_date: true,
    no_of_occurrences: undefined,
    status: "Active",

  },
  {
    id: 1,
    type: {label: "Supplier Invoice", "value": "SI"},
    name: "Business Account",
    frequency: "Every 10 days",
    start_date: "2020-05-14",
    next_date: "2020-06-14",
    status: "Inactive",
    ref: "ref2",
    description: "test",
    total: 213,
    repeat: 1,
    repeat_period: { label: "Days", "value": "days" },
    set_end_date: undefined,
    no_of_occurrences: undefined,
  },
  {
    id: 0,
    type: {label: "Adjustment", "value": "Adjustment"},
    name: "Account",
    frequency: "Every 1 week",
    start_date: "2020-01-14",
    next_date: "2020-01-21",
    status: "Inactive",
    ref: "ref3",
    description: "descript",
    total: 321,
    repeat: 3,
    repeat_period: { label: "Months", "value": "months"},
    set_end_date: true,
    no_of_occurrences: 1,
  },
];

export const itemsDetails = [
  { id: 0, name:"Type", data: "Customer Invoice" },
  { id: 1, name:"Frequency", data: "Every 1 month" },
  { id: 2, name:"Start Date", data: "17/04/2020" },
  { id: 3, name:"Status", data: "Active" },
];

export const itemsTemplate = [
  { id: 0, name:"Account", data: "asd | asd" },
  { id: 1, name:"Category", data: "400 | Sales" },
  { id: 2, name:"Description", data: "" },
  { id: 3, name:"Total", data: "₴1.00" },
];