import React from 'react';
import Form from "react-bootstrap/Form";
import {requiredValidator} from "../../../components/controls/validators";
import FormField from "../../../components/controls/FormField";
import * as PropTypes from "prop-types";
import {getCurrentCompanyId} from "../../../../crud/utils";
import CreateEditModal from "../../../components/CreateEditModal";
import toast from "../../../components/toast/toast";

export default function UserCreateEditModal({ initValues, groups, updateUser, addUser, showModal, onClose, users }) {
  initValues = initValues || {};
  const mainFields = [
    {
      id: 'first_name',
      label: 'First name',
      type: 'string',
      validator: requiredValidator('First name is required'),
    },
    {
      id: 'last_name',
      label: 'Last name',
      type: 'string',
      validator: requiredValidator('Last name is required'),
    },
    {
      id: 'email',
      label: 'Email',
      type: 'string',
      validator: requiredValidator('Email is required'),

    },
    {
      id: 'doddle_group',
      label: 'User permission',
      type: 'RSelect',
      defaultValue: !initValues.id && {label: "Restricted", value: 22},
      customProps: {
        options: groups.map((group) => ({ label: group.name, value: group.id })),
      }
    },
  ];

  const onSubmit = (values, { setSubmitting, resetForm, validateForm }) => {
      const onDone = () => {
        setSubmitting(false);
        onClose();
        resetForm();
      };
      // TODO add validation
    const onError = (error) => {
      // alert(JSON.stringify(error.response.data));
      // processError(error);
      setSubmitting(false);
    };

    const duplicateName = users.find(user => user.fullname === values.first_name + ' ' + values.last_name);
    if (duplicateName) {
      setSubmitting(false);
      toast.error(`User with name ${duplicateName.fullname} already exists`);
      return;
    }

      if (initValues.id) {
        updateUser({
          id: initValues.id,
          values,
          onDone,
          onError
        });
      } else {
        addUser({
          values: {...values, "company": +getCurrentCompanyId()},
          onDone,
          onError
        });
      }
    };

    return (
      <CreateEditModal
        onClose={onClose}
        onSubmit={onSubmit}
        modalTitle={initValues && initValues.id ? "Edit User" :  "Invite new user"}
        show={showModal}
        className="modal--small"
        nameNotification="User"
        initValues={initValues}>
        {(form) => (
          mainFields.map((item) => (
            <Form.Group key={item.id} controlId={item.id}>
              <Form.Label>{item.label}</Form.Label>
              <FormField
                name={item.id}
                label={item.label}
                type={item.type}
                validate={item.validator}
                customProps={item.customProps}
                defaultValue={item.defaultValue}
                {...form}
              />
            </Form.Group>
          ))
        )}
      </CreateEditModal>
  );
}

UserCreateEditModal.propTypes = {
  addUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  initValues: PropTypes.object,
  groups: PropTypes.array.isRequired,
};
