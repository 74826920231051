export const validatorValue = (type, value, errorMsg) => {
  const error = errorMsg || 'The category code is not valid for the account type. Please enter a code within the specified range';
  const optionsRange = {
    "Asset": [0, 199],
    "Liability": [200, 299],
    "Equity": [300, 399],
    "Revenue": [400, 499],
    "Expense": [500, 998],
  };
  const min = optionsRange[type][0];
  const max = optionsRange[type][1];
  if (min > value || value > max) {
    return error;
  }
};