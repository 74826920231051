import React from "react";
import { FieldArray, Form as FormikForm, Formik } from "formik";
import { Button } from "@material-ui/core";
import classNames from "clsx";
import * as PropTypes from "prop-types";
import Collapse from "@material-ui/core/Collapse";

const FormArray = (props) => {
  const {
    successNotification,
    initData,
    onSubmit,
    className,
    mainTabName,
    header,
    footer,
    footerButtons,
    expandId,
    dataName,
    line,
  } = props;
  return (
    <Formik
      enableReinitialize
      initialValues={initData}
      onSubmit={onSubmit}
    >
      {({ ...form }) => {
        return <FormikForm>
          <div className={classNames("content__tab", className, mainTabName && `${mainTabName}-settings__tab`)}>
            <div className={classNames(`${mainTabName}-settings__tab__forms`, dataName)}>
              <FieldArray
                name={dataName}
                render={(arrayHelpers) => {
                  const data = arrayHelpers.form.values[dataName];
                  return <>
                    {header}
                    {data && data.map((values, index) => {
                      let open = false;
                      if (expandId) {
                        open = expandId.some(item => item === values.name);
                      }
                      let content = line({ arrayHelpers, index, values, form });
                      return values.parent
                        ? <Collapse key={index} in={open} timeout="auto" unmountOnExit>{content}</Collapse>
                        : <React.Fragment key={index}>{content}</React.Fragment>
                    })}
                    {data && footer && footer({ form })}
                    {footerButtons
                      ? footerButtons({ form })
                      : (
                        <div className={classNames("settings__wrap-btn", `${mainTabName}-settings__wrap-btn`)}>
                          <Button
                            className={classNames("settings__btn", `${mainTabName}-settings__btn`)}
                            variant="contained"
                            type="submit"
                            disabled={form.isSubmitting}
                          >
                            Update
                          </Button>
                        </div>
                      )
                    }
                  </>
                }}
              />
            </div>
            {successNotification}
          </div>
        </FormikForm>
      }}
    </Formik>
  )
};

FormArray.propTypes = {
  successNotification: PropTypes.object,
  initData: PropTypes.object,
  onSubmit: PropTypes.func,
  className: PropTypes.string,
  mainTabName: PropTypes.string,
  dataName: PropTypes.string,
  expandId: PropTypes.array,
  footerButtons: PropTypes.func,
  header: PropTypes.node,
  footer: PropTypes.func,
  line: PropTypes.func,
};

export default FormArray;