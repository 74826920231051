import React, {useEffect, useState}  from "react";
import {Redirect} from "react-router";
import {connect} from "react-redux";
// @ts-ignore
import _ from 'lodash';
import {useParseHistory} from "../../../../hooks/modal.hook";
import {OnboardingLayout} from "../components";
import {Button, Container} from "@material-ui/core";
import Step3 from "../../ConnectSeller/Step3";
import toast from "../../../components/toast/toast";
import * as auth from "../../../../store/ducks/auth.duck";
import useUsers from "../../../../hooks/users.hook";
import CloseIcon from '@material-ui/icons/Close';
import {useHistory} from "react-router-dom";

const AmazonFinish = ({finishSetup}: any) => {
  const {user} = useUsers({fetch: false});
  const history: any = useParseHistory();
    let historyLocation = useHistory();


  const isRedirect = !_.isEmpty(history);
  const [isFinish, setFinish] = useState(false);
  const [finishInfo, setFinishInfo] = useState<{}>({});
  const [amazonInfo, setAmazonInfo] = useState<{selling_partner_id?: string}>({});

  useEffect(() => {
    if (user.email && isRedirect && !amazonInfo?.selling_partner_id) {
      setAmazonInfo(history);
      finishSetup({
        values: {...history},
        onDone: (data: any) => {
          toast.success("Success save!", {duration: 4000}, {isClose: false});
          // console.log('success finishSetup', data);
          setFinishInfo(data)
        },
        onError: (error: any) => {
          toast.error(error, {duration: null}, {isClose: false});
          console.log('error');
        }
      });
    }
  }, [history]);

  const onRedirectToFinish = () => {
    setFinish(true);
  };

  if (isFinish) {
    return <Redirect to={{pathname: "/finish"}} />
  }

  return (
    <OnboardingLayout
      currentStep={3}
      title="Connect your Amazon Account."
      button={
        <Button
          size="large"
          className="connect-doddled__logout"
          endIcon={<CloseIcon/>}
          onClick={() => {historyLocation.push('/logout');}}
        >
          Logout
        </Button>
      }
    >
      <div className="connect-doddled onboarding__amazon">
        <Container className="connect-doddled__container">
          <div className="connect-doddled__text">
            <Step3 onRedirectToFinish={onRedirectToFinish} finishInfo={finishInfo} />
          </div>
        </Container>
      </div>
    </OnboardingLayout>
  )
};

export default connect(
  null,
  auth.actions
)(AmazonFinish);