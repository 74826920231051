import React from "react";
import DetailsCreateDelete from "./DetailsCreateDelete";
import withConfirmHook from "../../../hooks/withConfirm.hook";
import * as PropTypes from "prop-types";
import classNames from "clsx";

const ContentCell =  ({ row, add, update, deleteItem, confirm, type, isMainPage, onUpdate, className, ...props }) => {
  const onAdd = (row) => (values) => {
    return new Promise((resolve, reject) => {
      add({
        parent: row.id,
        values,
        onDone: (data) => {
          resolve(true)
        },
        onError: (error) => {
          reject(error)
        }
      })
    });
  };

  const onDelete = (row) => () => {
    confirm(() => {
        deleteItem({
          id: row.id,
          type,
          onDone: (data) => {
            // console.log('Done');
          },
          onError: (error) => {
            console.log(error);
          }
        })
      },
      {
        description: 'This Action is Permanent!',
        style: "d-confirm"
      })()
  };

  const onEdit = (row) => async (values) => {
    return new Promise((resolve, reject) => {
      update({
        id: row.id,
        values,
        type,
        onDone: (data) => {
          resolve(true)
        },
        onError: (error) => {
          reject(error)
        }
      })
    });
  };
  return (
    <div className={classNames("action-buttons-table", className)}>
      <DetailsCreateDelete
        row={row}
        onEdit={onUpdate ? onUpdate : onEdit(row)}
        onAdd={onAdd(row)}
        onDelete={onDelete(row)}
        {...props}
      />
    </div>
  )
};

ContentCell.propTypes = {
  row: PropTypes.object,
  update: PropTypes.func,
  add: PropTypes.func,
  deleteItem: PropTypes.func,
  confirm: PropTypes.func,
  type: PropTypes.string,
  className: PropTypes.string,
};

export default withConfirmHook(ContentCell);