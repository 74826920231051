import React, {useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import * as PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Input from "@material-ui/core/Input";
import DButton from "../../../components/Button";

export default function QuickConversionTransactionModal({ submit, show, onClose }) {
  const [transactionTotal, setTransactionTotal] = useState("");
  const [gbpTotal, setGbpTotal] = useState("");
  const [convRate, setConvRate] = useState("");

  const calculateConvRate = () => {
    if (transactionTotal && gbpTotal) {
      setConvRate(gbpTotal/transactionTotal);
    } else {
      setConvRate("");
    }
  };
  const onTransactionTotalChange = (e) => setTransactionTotal(parseFloat(e.target.value));
  const onGbpTotalChange = (e) => setGbpTotal(parseFloat(e.target.value));

  useEffect(() => {
    calculateConvRate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gbpTotal, convRate]);

  const onSubmit = () => {
    submit(convRate);
    onClose();
  };

  return (
    <Modal show={show} onHide={onClose} className="modal--small quick-conv-transaction-modal" >
      <Modal.Header closeButton>
        <Modal.Title>Quick Conversion</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row wrap-new-bank-transaction-list">
          <Table className="form__table">
            <TableHead>
              <TableRow className="form__row">
                <TableCell className="form__cell">Transaction Total</TableCell>
                <TableCell className="form__cell">GBP Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className="form__row form__row--body">
                <TableCell aria-label="Transaction Total" className="form__cell">
                  <Input
                    value={transactionTotal}
                    name="transaction_total"
                    type="number"
                    onChange={onTransactionTotalChange}
                  />
                </TableCell>
                <TableCell aria-label="GBP Total" className="form__cell">
                  <Input value={gbpTotal} name="gbp_total" type="number" onChange={onGbpTotalChange} />
                </TableCell>
              </TableRow>
              <TableRow className="form__row form__row--body">
                <TableCell aria-label="Conversion Rate" className="form__cell">Conversion Rate:</TableCell>
                <TableCell className="form__cell">
                  <Input value={convRate} onChange={() => {}} name="conv_rate" type="number" disabled />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="container-button container-button--modal">
          <DButton type="submit" typeOfButton="doneAllSecondary" onClickCustom={onSubmit} disabled={!convRate}>
            Use
          </DButton>
          <DButton typeOfButton="close" onClickCustom={onClose}>Close</DButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

QuickConversionTransactionModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
};
