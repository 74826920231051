import React from "react";
import moment from "moment";
import {Avatar, Grid, Typography} from "@material-ui/core";
import {momentFormatOptions} from "../../utils";


type ChangeInfoProps = {
  data: { [key: string]: string }[]
  image_url?: string
  currentTitle?: string
  titleBlock: string
  anchor?: string
}

const ChangeInfo = ({data, image_url, currentTitle, titleBlock, anchor}: ChangeInfoProps) => {
  if (!data?.length) {
    return null;
  }

  return (
    <Grid container spacing={4} className="metrics-row__block">
      <div className="metrics-row__inventory-inform metrics-row__inventory-inform--extra metrics-row--border full-width">
        <div className="metrics-row__main-header metrics-row__header">
          <Typography variant="h6" className="title">{titleBlock}</Typography>
        </div>
        {data.slice(0, 9).map(({ title, created_at, image }, index) => {
          const name = title || currentTitle;
          const img = image || image_url;
          return (
            <Grid key={index} item xs={12} className="block__row">
              <Grid key={index} item xs={1} justifyContent="center" className="flex">
                <Avatar alt="img-product" variant="square" src={img}/>
              </Grid>

              <Grid key={index} item xs={10} className="">
                <Typography variant="body1">
                  {name}
                </Typography>
              </Grid>

              <Grid key={index} item xs={1} justifyContent="center" className="flex">
                <Typography variant="body1" className="date">
                  {moment(created_at).format(momentFormatOptions.display)}
                </Typography>
              </Grid>
            </Grid>
          )
        })}
        {!!anchor && <div id={anchor} />}
      </div>
    </Grid>
  )
};

export default ChangeInfo;