import React, {useRef, useState} from "react";
import {Form as FormikForm, Formik} from "formik";
import toast from "../../components/toast/toast";
import FormField from "../../components/controls/FormField";
import Form from "react-bootstrap/Form"
import {Typography, Button} from "@material-ui/core";
import useSettings from "../../../hooks/settings.hook";
import {useNotification} from "../../../hooks/notification.hook";
import useUsers from "../../../hooks/users.hook";
import DSplashScreen from "../../components/SplashScreen/DSplashScreen";
import HeaderInformation from "./components/HeaderInformation";
import {accountUser, permissions, notificationSettings} from "./utils";
import {toAbsoluteUrl} from "../../../../_metronic";

const AccountSettings = () => {
  const {accountSettings, accountLoading: loading, updateAccountSetting} = useSettings({fetch: false});
  const {user} = useUsers({fetch: false});
  const [images, setImages] = useState([]);
  const [successNotification, setIsShowNotification] = useNotification(
    false,
    "success",
    "Success",
    {vertical: "bottom", horizontal: "right"}
  );

  let initData = {};
  if (accountSettings) {
    initData = {...accountSettings}
  }
  const {avatar_image} = initData;

  const notificationSound = accountSettings.available_notification_sounds ? accountSettings.available_notification_sounds.map(sound => ({
    label: sound.label,
    value: sound.id,
    sound: new Audio(sound.file_url),
  })) : [];

  const empty = {
    label: 'no sound',
    value: null,
    sound: null,
  };

  notificationSound.unshift(empty);

  const onDeleteImage = () => setImages([]);
  const onImage = (files) => setImages(files);

  const onSubmit = (values, {setSubmitting, resetForm}) => {
    let data = {...values};
    const formdata = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      const field = [...accountUser, ...permissions, ...notificationSettings].find(({id}) => id === key);

      if (value !== null && !!field?.id) {
        formdata.append(key, value);
      }
    });

    if (images?.length) {
      const file = images[0]?.file;
      formdata.append("avatar_image", file, file.name);
    } else {
      formdata.append("avatar_image", "");
    }

    const onDone = () => {
      setSubmitting(false);
      resetForm();
      setIsShowNotification(true);
    };
    const onError = (error) => {
      setSubmitting(false);
      toast.error(error);
    };
    updateAccountSetting({
      id: user.id,
      values: formdata,
      onDone,
      onError
    })
  };

  const soundRef = useRef(null);

  const handleSoundMouseEnter = (option) => {
    if (soundRef.current) {
      soundRef.current.pause();
      soundRef.current.currentTime = 0;
    }
    if (option.sound) {
      option.sound.play();
    }
    soundRef.current = option.sound;
  };

  const handleClickTest = (val) => {
    toast.info(
      'New order!',
      {duration: 3000, position: "top-right"},
      {className: "new-order__notification"}
    );
    const opt = notificationSound.find(({value}) => value === val);
    if (opt.sound) {
      opt.sound.play();
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initData}
      onSubmit={onSubmit}
    >
      {({...form}) => (
        <FormikForm>
          <div className="settings-main__account settings-main__default">
            <HeaderInformation
              isImage
              name="Profile information"
              imgUrl={avatar_image}
              defaultImg={toAbsoluteUrl("./media/d-icons/user-svgrepo-com.svg")}
              images={images}
              onImage={onImage}
              onDeleteImage={onDeleteImage}
            />
            <div className="d-splash-screen--wrap">
              {loading && <DSplashScreen/>}
              <div className="settings-main__account__form">
                <div className="form__body">
                  {accountUser.map((item) => (
                    <Form.Group key={item.id} controlId={item.id} className={`form-group--${item.id}`}>
                      <Form.Label column={false}>{item.label}</Form.Label>
                      <FormField
                        name={item.id}
                        label={item.label}
                        type={item.type}
                        render={item.render}
                        {...form}
                      />
                    </Form.Group>
                  ))}
                </div>
                <Typography variant="h5" className="settings__subtitle">
                  Permissions
                </Typography>
                <div className="user-permissions-wrapper">
                  {permissions.map((item) => (
                    <Form.Group key={item.id} controlId={item.id} className={`form-group--${item.id}`}>
                      {!item.showLabel && <Form.Label column={false}>{item.label}</Form.Label>}
                      <FormField
                        name={item.id}
                        label={item.label}
                        showLabel={item.showLabel}
                        type={item.type}
                        customProps={item.customProps}
                        {...form}
                      />
                    </Form.Group>
                  ))}
                </div>
                <Typography variant="h5" className="settings__subtitle">
                  Notification Sound
                </Typography>
                <div className="settings__form__footer">
                  {
                    notificationSettings.map((item) => (
                      <FormField
                        key={item.id}
                        name={item.id}
                        type={item.type}
                        label={item.label}
                        customProps={item.customProps({
                          accountSettings,
                          handleSoundMouseEnter,
                          notificationSound
                        })}
                        {...form}
                      />
                    ))
                  }

                  <Button onClick={() => handleClickTest(form.values['notification_sound'])}>test</Button>
                </div>
                <div className="settings__wrap-btn">
                  <Button
                    variant="contained"
                    className="settings-main__vat__btn settings__btn"
                    type="submit"
                    disabled={form.isSubmitting}
                  >
                    {/*{user ? "Update" : "Save"}*/}
                    Update
                  </Button>
                </div>
              </div>
            </div>
            {successNotification}
          </div>
        </FormikForm>
      )}
    </Formik>
  )
};

export default AccountSettings;