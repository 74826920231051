import React, {useState} from 'react';
import Modal from "react-bootstrap/Modal";
import * as PropTypes from "prop-types";
import DButton from "../../../components/Button";
import RSelect from "../../../components/controls/RSelect";
import Form from "react-bootstrap/Form";
import {useHistory} from "react-router-dom";
import {goToNewRecurringAdjustmentPage} from "../../Category/utils";
import {goToRecurringInvoicesPage} from "../../Transaction/utils";

export default function NewRecurringModal({show, onClose}) {
  const [value, setFieldValue] = useState('');
  let history = useHistory();

  const recurringTransactionOptions = [
    {label: "Adjustment", "value": "A"},
    {label: "Customer Invoice", "value": "CI"},
    {label: "Supplier Invoice", "value": "SI"},
  ];

  const onAddNew = () => {
    switch (value.label) {
      case "Adjustment":
        goToNewRecurringAdjustmentPage(history);
        break;
      case "Customer Invoice":
        goToRecurringInvoicesPage(history, "SalesInvoice");
        break;
      case "Supplier Invoice":
        goToRecurringInvoicesPage(history, "PurchaseInvoice");
        break;
      default:
        break;
    }
  };

  const handleChange = (value) => {
    setFieldValue(value);
  };
  return (
    <Modal show={show} onHide={onClose} className="modal--small">
      <Modal.Header closeButton>
        <Modal.Title>New Recurring Transaction</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="recurring_trans_type">
          <Form.Label column={false}>Select Recurring Transaction Type</Form.Label>
          <RSelect
            name="recurring_trans_type"
            label="Select Recurring Transaction Type"
            type="RSelect"
            onChange={handleChange}
            value={value}
            options={recurringTransactionOptions}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <div className="container-button container-button--modal">
          <DButton typeOfButton="add" onClickCustom={onAddNew}>Add New Recurring Transaction</DButton>
          <DButton typeOfButton="close" onClickCustom={onClose}>Close</DButton>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

NewRecurringModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
