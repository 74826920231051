import React, { useState, useEffect } from "react";
import TableComponent from "../../../../components/TableComponent/TableComponent";
import TableToolbarComponent from "../../../../components/TableComponent/TableToolbarComponent";
import { useCheckboxStorage, usePerPage } from "../../../../../hooks/modal.hook";
import DButton from "../../../../components/Button";
import withConfirmHook from "../../../../../hooks/withConfirm.hook";
import * as PropTypes from "prop-types";
import { goToEditCustomFilePage, goToImportCustomNewPage } from "../../utils";
import { useHistory } from "react-router-dom";
import Wrapper from "../../../../components/Wrapper";
import withHelmet from "../../../../../hocs/withHelmet";
import { connect } from "react-redux";
import * as Selector from "../../../../../selectors/imortBankTransactions.selectors";
import * as duck from "../../../../../store/ducks/import.duck";
import Spinner from "../../../../components/Spinner";

const title = "Custom File Import Format\n";
const headRows = [
  {id: 'name', numeric: false, disablePadding: true, label: 'Name', isActive: true},
  {id: 'columns', numeric: false, disablePadding: true, label: 'Columns', isActive: true},
  {id: 'summary', numeric: false, disablePadding: true, label: 'Summary', isActive: true},
  {id: 'actions', numeric: false, disablePadding: true, label: 'Actions', align: 'right' },
];

function ImportCustom({ loading, getImportFormats, confirm, importFormats, deleteImport }) {
  const [active, setActive] = useCheckboxStorage("importCustomBankTrans", headRows);
  // eslint-disable-next-line
  const [currentRow, setCurrentRow] = useState(null);
  const [rowsPerPage, onPerPage] = usePerPage();
  let history = useHistory();

  useEffect(() => {
    getImportFormats()
  }, []);

  if (importFormats.loading) {
    return <Spinner className="d-spinner--full-height"/>
  }

  const onRefresh = () => console.log("onRefresh");
  const onEdit = (row) => () => {
    setCurrentRow(row);
    goToEditCustomFilePage(history, row.id)
  };
  const onNewCustomImport = () => goToImportCustomNewPage(history);
  const onDelete = (row) => (
    confirm(() => {
        deleteImport({id: row.id,})
      },
      {description: 'This action is permanent!'}
    )
  );

  return <Wrapper>
    <TableToolbarComponent
      title={title}
      actionsHeader={{
        onRefresh,
        selectMenu: { setFunc: onPerPage, type: "select" },
        viewColumnMenu: { array: active, type: "filter", setFunc: setActive, disabled: true },
      }}
    />
    <TableComponent
      headRows={active.filter(column => column.isActive)}
      items={importFormats.details}
      rowsPerPageSelected={rowsPerPage}
      rowProps={{
        onEdit,
        onDelete,
      }}
    />
    <div className="container-button">
      <DButton typeOfButton="add" onClickCustom={onNewCustomImport}>Add New Custom File Import</DButton>
    </div>
  </Wrapper>
}

ImportCustom.propTypes = {
  confirm: PropTypes.func.isRequired,
  getImportFormats: PropTypes.func.isRequired,
  importFormats: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    importFormats: Selector.selectImportFormats(state, props),
    loading: Selector.getLoading(state, props),
  }
};

const mapDispatchToProps = {
  getImportFormats: duck.actions.getImportFormats,
  deleteImport: duck.actions.deleteImport,
};


export default withHelmet(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withConfirmHook(ImportCustom)),
  {title: "Import custom"}
)
