import React from 'react'
import { Link } from 'react-router-dom'
import {toAbsoluteUrl} from "../../../../../../_metronic";

function Collaborate() {
  return (
    <>
      <section className="row_am collaborate_section">
        <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="collaborate_image" data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100">
              <div className="img_block" data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100">
                <img className="icon_img moving_animation" src={toAbsoluteUrl("media/codely-theme-files/assets/images/skuify/chart.png")} alt="image"/>
                <div>
                  <img src={toAbsoluteUrl("media/codely-theme-files/assets/images/skuify/user.png")} alt="image"/>
                </div>
              </div>
              <div className="img_block" data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100">
                <img src={toAbsoluteUrl("media/codely-theme-files/assets/images/skuify/user1.png")} alt="image"/>
                <img className="icon_img moving_animation" src={toAbsoluteUrl("media/codely-theme-files/assets/images/skuify/logo.png")} alt="image"/>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="collaborate_text" data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100">
              <span className="icon"><img src={toAbsoluteUrl("media/codely-theme-files/assets/images/new/securely.png")} alt="image"/></span>
              <div className="section_title">
                <h2>2 Years FREE Back Data</h2>
                <p>Some tools charge you extra to pull your old data. We don't think that's fair. And without your old data, your PROFIT ANALYTICS doesn't work as well as it should. So we include 2 years back data for free!</p>
              </div>
              <ul>
                <li data-aos="fade-up" data-aos-duration="2000">
                  <h3>What does this mean?</h3>
                  <p>By hopping on board with SKUify and syncing up your Amazon account, we're taking a trip down memory lane for you, going back a full two years to gather all your sales data. This isn't just a snapshot; it's the full movie of your Amazon business journey. You get to see the whole story, not just the latest chapter!</p>
                </li>
                <li data-aos="fade-up" data-aos-duration="2000">
                  <h3>Why is it so important?</h3>
                  <p>
                    Ever tried to solve a puzzle with missing pieces? Yeah, it's not fun. If you only start collecting data from the moment you sign up, you're missing out on crucial context. That could mean you've lost thousands and you wouldn't even know it. With SKUify, you get the full picture, making sure you know exactly how your business is performing, down to the last penny.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      </section>
    </>
  )
    
}

export default Collaborate
