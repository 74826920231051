import React, {useEffect, useState} from "react";
import PPPTabsToolbar from "./PPPTabsToolbar";
import PPPTable from "./PPPTable";
import useUsers from "../../../hooks/users.hook";
import useSettings from "../../../hooks/settings.hook";
import {headRows} from "./headRows";
import {
  getProductsData,
  getOptionsData,
  getGeneralData,
  defSortOptions,
  handlerSelectedMarketplace,
  handlerSearchDebounce,
  handleChangeSort,
  handleChangeFilter,
  initPPPData,
  initParams
} from "./utils";
import {Dialog, DialogContent} from "@material-ui/core";
import ErrorPPP from "./ErrorPPP";

const PPPContent = () => {
  const {user} = useUsers({fetch: false});
  const {getAccountSettings, accountSettings, accountLoading} = useSettings({fetch: false});
  const {ppp_api_key, ppp_email} = accountSettings;

  const [credentials, setCredentials] = useState({});
  const [pppData, setPppData] = useState(initPPPData);

  const [params, setParams] = useState(initParams);
  const [strategies, setStrategies] = useState({});
  const [eMarketplaces, setEMarketplaces] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);
  const [sortOptions, setSortOptions] = useState([]);
  const [errorModal, setErrorModal] = useState(false);

  const [isResetPagination, setResetPagination] = useState(false);

  const handleQueryParams = (newParams) => {
    setResetPagination(true);
    setParams({ ...params, pageNumber: 1, ...newParams });
  };

  useEffect( () => {
    getAccountSettings({ id: user.id })
  }, []);

  useEffect(() => {
    pppData.Error  && accountSettings.ppp_api_key && setErrorModal(true);
  }, [pppData]);

  useEffect( () => {
    if (ppp_api_key) {
      const cred = {
        apiKey: ppp_api_key,
        email: ppp_email,
        ppp_api_key,
      };

      setCredentials(cred);
      setParams(() => ({...params, ...cred}))
    }
  }, [accountSettings]);

  const getSortOptions = (options) => {
    const sorts = options.map(option => {
      const generalOption = defSortOptions.find(defOption => option.SortName === defOption.SortName);
      if (generalOption) {
        return generalOption
      } else {
        const _option = headRows.find(({ id }) => option.SortName.includes(id.toLowerCase()));
        if (_option) {
          const incl = option.SortName.includes("asc");
          return incl ? {...option, value: _option.id} : {...option, value: `-${_option.id}`};
        }
        return option;
      }
    });
    setSortOptions(sorts)
  };

  useEffect( () => {
    if (ppp_api_key) {
      getOptionsData({ params: {email: ppp_email, apiKey: ppp_api_key}, setFunction: setStrategies });
      getGeneralData(setEMarketplaces, "get_active_marketplaces", "enabledMarketplaces");
      getGeneralData(setFilterOptions, "get_filters", "filters");
      getGeneralData(getSortOptions, "get_sorts", "sorts");
    }
  }, [ppp_api_key]);

  useEffect(() => {
    if (params.apiKey) {
      getProductsData({ params, setPppData });
    }
  }, [params]);

  const getParams = (newParams) => {
    const { limit: itemsPerPage, ordering, page, offset } = newParams;
    const pageNumber = page || (offset / itemsPerPage) + 1;
    const sort = sortOptions.find(({ value }) => value === ordering)?.SortName;
    const queryParams = {
      itemsPerPage,
      ...(ordering && {sort}),
      pageNumber,
    };
    setParams((old) => ({ ...old, ...queryParams }))
  };

  const handleClose = () => setErrorModal(false);

    return (
      <>
        <PPPTabsToolbar
          params={params}
          handleChangeFilter={handleChangeFilter({handleQueryParams})}
          handleChangeSort={handleChangeSort({handleQueryParams})}
          handlerSearchDebounce={handlerSearchDebounce({handleQueryParams})}
          handlerSelectedMarketplace={handlerSelectedMarketplace({handleQueryParams, eMarketplaces})}
          eMarketplaces={eMarketplaces}
          filterOptions={filterOptions}
          sortOptions={sortOptions}
          pppData={pppData}
        />
        <PPPTable
          pppData={pppData}
          strategies={strategies}
          credentials={{credentials}}
          enabledMarketplaces={eMarketplaces}
          getParams={getParams}
          setParams={setParams}
          isResetPagination={isResetPagination}
          setResetPagination={setResetPagination}
        />

        <Dialog
          open={errorModal}
          onClose={handleClose}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
          className="ppp-page__modal"
          maxWidth="sm"
          scroll="body"
        >
          {/*<DialogContent><ErrorPPP /></DialogContent>*/}
        </Dialog>
      </>
    )
};

export default PPPContent;