import React from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import en from 'date-fns/locale/en-GB'
import moment from 'moment'
import {addDays, addMonths, parseISO} from 'date-fns'
import {years, bimonthlyDates} from './utils'
import "./style.scss"
import CustomHeader from "./CustomHeader";
import {expensesDateFormatFront} from "../../../home/Expenses/utils";
import {momentFormatOptions} from "../../../home/utils";

// const qtyMonth = {
//   'MONTHLY': 1,
//   'QUARTERLY': 3,
//   'BIANNUAL': 6,
//   'YEARLY': 12,
// }

const ReactDatePicker = (fieldProps) => {
    const {customProps, name, setFieldValue, setFieldTouched, value} = fieldProps;
    const {interval, minDate, onChange} = customProps;

    // const minDate = moment(parseISO(start));

    // let currentMonth = months[moment(minDate).month()]
    //
    // const [monthDate, setMonthDate] = useState(currentMonth) // init value for month in controls button
    // const [yearsDate, setYearsDate] = useState(moment().year()) // init value for year in controls button
    // const excludeDateIntervals = []
    // let includeDates = null
    
    // useEffect(() => { // use when have initValue

        // if (value) {
        //
        //     if (typeof value === 'string' && value !== '') {
        //         if (value !== 'none date') {
        //             setFieldValue(name, parseISO(value))
        //         }
        //     } else {
        //         setFieldValue(name, value)
        //     }
        //
        // }
    // },[value])

    // switch (interval) { // set DateIntervals for date picker
    //     case 'ONCE':
    //         let onceInterval = {
    //             start: minDate,
    //             end: new Date(years[years.length - 1], 11, 31)
    //         }
    //         excludeDateIntervals.push(onceInterval)
    //         break
    //     case 'WEEKLY':
    //         // let endDateWeekly = new Date(years[years.length - 1], 11, 31)
    //         // let differenceInDaysWeekly = moment(endDateWeekly).diff(minDate, 'days')
    //         // for (let i = 0; i <= differenceInDaysWeekly; i += 7) {
    //         //     excludeDateIntervals.push({
    //         //         start: addDays(minDate, i),
    //         //         end: addDays(minDate, i + 6)
    //         //     })
    //         // }
    //         if (includeDates === null) {
    //             includeDates = []
    //         }
    //         let endDateWeekly = new Date(years[years.length - 1], 11, 31)
    //         let differenceInMonthsWeekly = moment(endDateWeekly).diff(minDate, 'days')
    //
    //         for (let i = 0; i <= differenceInMonthsWeekly; i+=7) {
    //             let firstEntryDate = addDays(parseISO(minDate), i)
    //
    //             if (i !== 0) {
    //                 includeDates.push(firstEntryDate)
    //             }
    //
    //         }
    //         break
    //     case 'BIMONTHLY':
    //         if (includeDates === null) {
    //             includeDates = []
    //         }
    //         let endDateBimonthly = new Date(years[years.length - 1], 11, 31);
    //
    //         let differenceInMonthsBimonthly = moment(endDateBimonthly).diff(minDate, 'months')
    //         let firstEntryDay = +moment(minDate).format('DD')
    //         let secondEntryDay = bimonthlyDates.second_entry[bimonthlyDates.first_entry.indexOf(firstEntryDay)]
    //
    //         for (let i = 0; i <= differenceInMonthsBimonthly; i++) {
    //             let firstEntryDate = addMonths(parseISO(minDate), i)
    //             let secondEntryDate = moment(minDate).set('date', secondEntryDay).toDate()
    //             secondEntryDate = addMonths(parseISO(secondEntryDate), i)
    //             if (i !== 0) {
    //                 includeDates.push(firstEntryDate)
    //             }
    //             includeDates.push(secondEntryDate)
    //         }
    //
    //         break
    //     case 'MONTHLY':
    //         if (includeDates === null) {
    //             includeDates = []
    //         }
    //         let endDateMonthly = new Date(years[years.length - 1], 11, 31)
    //         let differenceInMonthsMonthly = moment(endDateMonthly).diff(minDate, 'months')
    //
    //         for (let i = 0; i <= differenceInMonthsMonthly; i++) {
    //             let firstEntryDate = addMonths(parseISO(minDate), i)
    //
    //             if (i !== 0) {
    //                 includeDates.push(firstEntryDate)
    //             }
    //
    //         }
    //         break
    //     case 'QUARTERLY':
    //         if (includeDates === null) {
    //             includeDates = []
    //         }
    //         let endDateQuarterly = new Date(years[years.length - 1], 11, 31)
    //         let differenceInMonthsQuarterly = moment(endDateQuarterly).diff(minDate, 'months')
    //
    //         for (let i = 0; i <= differenceInMonthsQuarterly; i += 3) {
    //             let firstEntryDate = addMonths(parseISO(minDate), i)
    //
    //             if (i !== 0) {
    //                 includeDates.push(firstEntryDate)
    //             }
    //         }
    //         break
    //     case 'BIANNUAL':
    //         if (includeDates === null) {
    //             includeDates = []
    //         }
    //         let endDateBiannual = new Date(years[years.length - 1], 11, 31)
    //         let differenceInMonthsBiannual = moment(endDateBiannual).diff(minDate, 'months')
    //
    //         for (let i = 0; i <= differenceInMonthsBiannual; i += 6) {
    //             let firstEntryDate = addMonths(parseISO(minDate), i)
    //
    //             if (i !== 0) {
    //                 includeDates.push(firstEntryDate)
    //             }
    //
    //         }
    //         break
    //     case 'YEARLY':
    //         if (includeDates === null) {
    //             includeDates = []
    //         }
    //         let endDateYearly = new Date(years[years.length - 1], 11, 31)
    //         let differenceInMonthsYearly = moment(endDateYearly).diff(minDate, 'months')
    //
    //         for (let i = 0; i <= differenceInMonthsYearly; i += 12) {
    //             let firstEntryDate = addMonths(parseISO(minDate), i)
    //
    //             if (i !== 0) {
    //                 includeDates.push(firstEntryDate)
    //             }
    //         }
    //         break
    // }

    const onChangePicker = (date) =>
      !!onChange
        ? onChange(expensesDateFormatFront(date))
        : setFieldValue(name, expensesDateFormatFront(date));

    return <DatePicker
        renderCustomHeader={(propsPicker) => <CustomHeader {...propsPicker} />}
        selected={value ? new Date(value) : null}
        value={value ? moment(value).format(momentFormatOptions.display) : null}
        locale={en}
        onChange={onChangePicker}
        onBlure={() => setFieldTouched(name, true)}
        minDate={new Date(minDate)}
        dateFormat={momentFormatOptions.display}
        className="react-datepicker_custom"
    />
}

export default ReactDatePicker