import React from 'react';
import ReportsGroup from "./ReportsGroup";
import * as PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { goToBalanceSheetPage, goToProfitLossPage, goToTrialBalancePage } from "./utils";
import { general } from "./group";
import "./reports.scss";

export const items = [
  {
    "id": 12,
    "export_type": "Customers",
    "status": "Complete",
    "calculated": "27/12/2019 14:48:25",
    "start_date": null,
    "end_date": null
  },
  {
    "id": 10,
    "export_type": "Customers",
    "status": "Complete",
    "calculated": "27/12/2019 14:48:30",
    "start_date": null,
    "end_date": null
  },
];

function Reports() {
  const history = useHistory();

  const onProfitLoss = () => {
    goToProfitLossPage(history);
  };

  const onBalanceSheet = () => {
    goToBalanceSheetPage(history);
  };

  const onTrialBalance = () => {
    goToTrialBalancePage(history);
  };

  const generalProps = { onProfitLoss, onBalanceSheet, onTrialBalance };
  return (
    <div className="card-list">
      <ReportsGroup data={general(generalProps)} nameGroup="General"/>
    </div>
  )
}

Reports.propTypes = {
  confirm: PropTypes.func.isRequired,
  deleteReports: PropTypes.func,
};

export default Reports;
