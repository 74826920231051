import React from "react";
import {FormControl, FormHelperText, TextField} from "@material-ui/core";
import classNames from 'classnames'
import Autocomplete from "@material-ui/lab/Autocomplete";

const AutocompleteField = allProps => {
  const {
    name,
    setFieldValue,
    setFieldTouched,
    placeholder,
    label,
    meta,
    showLabel,
    customProps = {},
    submitCount,
    autofocus,
    ...props
  } = allProps;

  const {onOpen, onChange, options, onClose, className, ...otherProps} = customProps;
  const handleChange = (e, value) => {
    if (onChange) {
      onChange(value);
    } else {
      setFieldValue(name, value)
    }
  };
  return <Autocomplete
    freeSolo
    className={classNames("form-group__input-text--auto", className)}
    options={options}
    // show tooltip
    onClose={onClose}
    // hide tooltip
    onOpen={onOpen}
    onChange={handleChange}
    onInputChange={handleChange}
    value={props.value}
    renderInput={params =>
      <FormControl
        error={!!(meta && (meta.touched || submitCount) && meta.error)}
        fullWidth
        margin="dense"
      >
        <TextField {...params} fullWidth {...otherProps} />
        {meta && (meta.touched || submitCount) && meta.error && (
          <FormHelperText error id="component-error-text">
            {meta.error}
          </FormHelperText>
        )}
      </FormControl>
    }
  />
};

export default AutocompleteField;