import React from "react";
import {Chip, FormControl, FormHelperText, Input, MenuItem, Select} from "@material-ui/core";
import classNames from 'classnames'

const MSelect = allProps => {
  const {
    name,
    setFieldValue,
    setFieldTouched,
    placeholder,
    label,
    meta,
    showLabel,
    customProps = {},
    submitCount,
    ...props
  } = allProps;
  const {options, className, onChange} = customProps;

  const handleChangeMulti = (event) => {
    if (onChange) {
      onChange(event);
    } else {
      setFieldValue(name, event.target.value);
    }
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      className: `${className}--m-select__paper`,
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP + 100,
        width: 250,
      },
    },
  };

  return <>
    <FormControl
      className="form-control--multiple-select"
      error={!!(meta && (meta.touched || submitCount) && meta.error)}
      fullWidth
      margin="dense"
    >
      <Select
        id={name}
        multiple
        disabled={props.disabled}
        className={classNames("multiple-select", {[`${className}--m-select`]: className})}
        value={props.value || []}
        options={options}
        onChange={handleChangeMulti}
        input={<Input id={label}/>}
        renderValue={selected => (
          <div className="multiple-select__chips">
            {selected.map(value => {
              const opt = options.find((opt) => opt.value === value);
              return (
                <Chip
                  key={value}
                  label={opt ? opt.label : ''}
                  className="multiple-select__chips"
                />
              )
            })}
          </div>
        )}
        MenuProps={MenuProps}
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {(meta.touched || submitCount) && meta.error && !meta.value && (
        <FormHelperText error id="component-error-text">
          {meta.error}
        </FormHelperText>
      )}
    </FormControl>
  </>
};

export default MSelect;