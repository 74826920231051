import React from "react";
import classNames from "clsx";
import { TableCell, TableSortLabel } from "@material-ui/core";
import PropTypes from "prop-types";
import TooltipComponent from "../TooltipComponent";

const getAlign = (row) => {
  if (row.id === 'actions' || row.id === 'headings' || row.id === 'first_row_of_data' || row.align === "center") {
    return 'center';
  }
  return row.numeric ? 'right' : 'left'
};

const tooltipContent = (row) => {
  if (row.help) {
    return row.help(row)
  }
  if (row.helpText) {
    return row.helpText
  }
  if (row.label) {
    return row.label
  }
  return ""
};

const HeaderTableCell = ({ row, orderBy, createSortHandler, disabled, order, rowCount, isHideLabel }) => {
  const isSortDirection = orderBy === row.id ? order : false;
  return (
    <TableCell
      key={row.id}
      className={`cell__header--${row.id}`}
      align={getAlign(row)}
      padding='normal'
      sortDirection={isSortDirection}
      disabled={disabled || row.disabled}
      colSpan={row.colspan}
    >
      <TooltipComponent
        placement={row.tooltipPlacement}
        tooltip={
          // !(disabled || row.disabled) &&
          rowCount !== 0 && tooltipContent(row)
        }
        tooltipStyle={classNames({ "cell__header__overlay": row.help })}
      >
        {row.label && !isHideLabel && (
          <div className={classNames("cell__header__wrap", {
            "cell__header__wrap--no-sort": disabled || row.disabled,
            "cell__header__wrap--active": orderBy === row.id,
          })}>
            {disabled || row.disabled
              ? row.label
              : <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id)}
                className="cell__header__sort "
              >
                {row.img}
                {row.label}
              </TableSortLabel>
            }
          </div>
        )}
      </TooltipComponent>
    </TableCell>
  )
};

HeaderTableCell.propTypes = {
  row: PropTypes.object,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  createSortHandler: PropTypes.func,
};

export default HeaderTableCell;
