import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import React from "react";
import * as PropTypes from "prop-types";
import FormField from "../../components/controls/FormField";
import CreateEditModal from "../../components/CreateEditModal";
import {processError} from "../Transaction/EnterTransactionsBanking/EditAddBankingModal";
import {requiredValidator} from "../../components/controls/validators";
import DButton from "../../components/Button";

export const optionsVATScheme = [
  {label: "Invoice Based", "value": "IB"},
  {label: "Cash Accounting", "value": "CA"},
  {label: "Flat Rate - Invoice Based", "value": "FR-IB"},
  {label: "Flat Rate - Cash Accounting", "value": "FR-CA"},
];


const CreateEditVATScheme = ({ show, onClose, addVATScheme, updateVATScheme, initValues, }) => {

  const disabled = initValues && true;

  const headRowsVATSchemesModal = [
    {
      id: 'start_date',
      label: 'Start Date',
      type: 'date',
      isActive: true,
      validator: requiredValidator('Start Date is required'),
    },
    ...initValues
      ? [{
        id: 'end_date',
        label: 'End Date',
        type: 'date',
        isActive: true,
      }]
      : [],
    {
      id: 'vat_scheme',
      label: 'VAT Scheme',
      type: 'RSelect',
      isActive: true,
      validator: requiredValidator('VAT Scheme is required'),
      customProps: {
        options: optionsVATScheme
      },
    },
    {
      id: 'rate',
      label: 'Rate',
      type: 'text',
      isActive: true,
      disabled: disabled,
      placeholder: "5 %"
    },
  ];

  const onSubmit = (values, {setSubmitting, resetForm}) => {
    const data = {...values};
    const onDone = () => {
      setSubmitting(false);
      resetForm();
      onClose();
    };
    const onError = (error) => {
      processError(error);
      setSubmitting(false);
    };

    // if (initValues) {
    //   updateVATScheme({
    //     id: initValues.id,
    //     values: data,
    //     onDone,
    //     onError,
    //   });
    // } else {
    //   addVATScheme({
    //     values: data,
    //     onDone,
    //     onError,
    //   });
    // }
  };

  const style = initValues ? "modal__table-form--edit" : "modal__table-form--add";

  const closeButton = <DButton typeOfButton="backPrimary" onClickCustom={onClose}>Back</DButton>;

  return <CreateEditModal
    onSubmit={onSubmit}
    onClose={onClose}
    show={show}
    modalTitle={initValues ? "VAT Scheme" : "New VAT Scheme"}
    initValues={initValues}
    closeButton={closeButton}
    className={`modal__table-form ${style}`}>
    {(form) =>
      <Table className="form__table">
        <TableHead>
          <TableRow className="form__row">
            {headRowsVATSchemesModal.map(item => (
              <TableCell key={item.id} className="form__cell">{item.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow className="form__row form__row--body">
            {
              headRowsVATSchemesModal.map((item) => (
                <TableCell key={item.id} aria-label={item.label} className={"form__cell cell__" + item.id}>
                  <FormField
                    name={item.id}
                    label={item.label}
                    type={item.type}
                    disabled={item.disabled}
                    placeholder={item.placeholder}
                    validate={item.validator}
                    customProps={item.customProps }
                    {...form}
                  />
                </TableCell>
              ))
            }
          </TableRow>
        </TableBody>
      </Table>
    }
  </CreateEditModal>
};

CreateEditVATScheme.propTypes = {
  addVATScheme: PropTypes.func,
  updateVATScheme: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onCreateEditModal: PropTypes.func,
  toggle: PropTypes.func,
  show: PropTypes.bool.isRequired,
  initValues: PropTypes.object,
};

export default CreateEditVATScheme;