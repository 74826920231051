import React, { useEffect, useState, useRef } from "react";
import * as PropTypes from "prop-types";
import { Switch, FormControlLabel } from "@material-ui/core";
import DSearch from "../../components/DSearch/DSearch";
import ImportCOG from "./ImportCOG";
import { useModal } from "../../../hooks/modal.hook";
import ExportCOG from "./ExportCOG";
import { useNotification } from "../../../hooks/notification.hook";
import OverlayComponent from "../../components/OverlayComponent";
import Button from "@material-ui/core/Button";
import useProducts from "../../../hooks/products.hook";
import { importCOGWarningText } from "./utils";
import { ReactComponent as ImportIcon } from "../../../media/icons/import.svg";

const TabsToolbar = (props) => {
  const {
    initSearch,
    setSearchQueryParam,
    setStockParam,
    setNoInventoryCostParam,
    tab,
    setWarningSearchParam,
    isWarning,
    setWarning,
    usedParams,
    noInventoryCost,
    setNoInventoryCost,
    outOfStock,
    setOutOfStock,
    importAction,
    exportAction,
    isBottom
  } = props;

  const [isClear, setClear] = useState(false);
  const [isModalShow, toggleModal] = useModal();
  const { addImportsCOG, noteImportsCOG } = useProducts();

  const [successImport, setIsShowSuccessImport] = useNotification(
    false,
    "success",
    "SKUify is currently importing your COGs from an external file - please check back later",
    { vertical: "bottom", horizontal: "right" },
    5000,
  );

  const [errorImport, setIsShowError] = useNotification(
    false,
    "error",
    noteImportsCOG && noteImportsCOG.errors && importCOGWarningText({ noteImportsCOG: noteImportsCOG.errors }),
    { vertical: "bottom", horizontal: "right" },
    null,
    { mouseEvent: false }
  );

  const [warning, setIsShowWarning] = useNotification(
    false,
    "warning",
    noteImportsCOG && noteImportsCOG.warnings && importCOGWarningText({ noteImportsCOG: noteImportsCOG.warnings }),
    { vertical: "bottom", horizontal: "right" },
    null,
    { mouseEvent: false }
  );

  const isFirstRender = useRef(false);

  useEffect(() => {
    if (tab && isFirstRender.current) {
      setOutOfStock(false);
      setClear(true);
      setWarning(false);
      setNoInventoryCost(false);
    } else {
      isFirstRender.current=true
    }
  }, [tab]);

  useEffect(() => {
    if (usedParams.includes("q") && !usedParams.includes("stock_or_inactive")) {
      setOutOfStock(true);
      setClear(false);
    }
  }, [usedParams]);

  const handleChangeStock = (event) => {
    setClear(false);
    setStockParam(event.target.checked ? event.target.checked : "");
    setOutOfStock(event.target.checked);
  };
  const handleChangeInventory = (event) => {
    setClear(false);
    setNoInventoryCostParam(event.target.checked ? event.target.checked : "");
    setNoInventoryCost(event.target.checked);
  };

  const handleWarning = (event) => {
    setClear(false);
    setWarningSearchParam(event.target.checked ? event.target.checked : "");
    setWarning(event.target.checked);
  };


  const showNotifications = (notifications) => {
    if (!!notifications && notifications.errors) {
      setIsShowError(true);
    } else {
      setIsShowSuccessImport(true);
    }
    if (!!notifications && notifications.warnings) {
      setIsShowWarning(true);
    }
  };

  const switches = [
    {
      label: 'Include Out of Stock or Inactive',
      tooltip: 'Toggle on to include products that are currently Out Of Stock  or Inactive in your inventory',
      checked: outOfStock,
      onChange: handleChangeStock,
    },
    {
      label: 'Warnings only',
      tooltip: `SKUify has built in warnings when we spot an issue with your listings or a title change in Amazon.
      Use this toggle to view the warning symbols on the affected ASINs.`,
      checked: isWarning,
      onChange: handleWarning,
    },
    {
      label: 'No COGs',
      tooltip: 'Toggle on to include products that are have no COGs',
      checked: noInventoryCost,
      onChange: handleChangeInventory,
    }
  ];

  return (
    <div className="full-width">
      {
        isBottom ? (
          <div className="header--checkboxes">
            {tab !== 'reimbursement' ? switches.map((el, index) => (
              <div key={index} className='space_header_checkboxes'>
                <OverlayComponent  tooltip={el.tooltip} key={el.label} >
                  <FormControlLabel
                    className='space_checkboxes_label'
                    labelPlacement="start"
                    control={
                      <Switch
                        className="d-switch space_checkboxes"
                        checked={el.checked}
                        onChange={el.onChange}
                        inputProps={{'aria-label': `checkbox`}}
                      />
                    }
                    label={el.label}
                  />
                </OverlayComponent>
              </div>
            )) : <div style={{height: 28.5}}/>}
          </div>
        ) : (
          <>
            {successImport}
            {noteImportsCOG?.warnings && warning}
            {noteImportsCOG?.errors && errorImport}
            <div className="header--search tabs__toolbar">
              <DSearch
                initValue={initSearch}
                setSearchQuery={setSearchQueryParam}
                // options={optionsSearch}   //presets
                isClear={isClear}
                setClear={setClear}
              />
              <div className="wrap-button">
                {exportAction && (
                  <ExportCOG
                    handleChangeStock={handleChangeStock}
                    checkedStock={outOfStock}
                    handleChangeInventory={handleChangeInventory}
                    checkedInventory={setNoInventoryCost}
                  />
                )}
                {importAction && (
                  <OverlayComponent
                    tooltip="Click here to import the CSV and automatically update your Cost of Goods information"
                  >
                    <Button variant="contained" size="medium" className="header-button" onClick={toggleModal}>
                      <ImportIcon className="icon-component import-icon" />
                      Import
                    </Button>
                  </OverlayComponent>
                )}
              </div>
            </div>
            <ImportCOG
              show={isModalShow}
              onClose={toggleModal}
              showNotifications={showNotifications}
              addImportsCOG={addImportsCOG}
            />
          </>
        )
      }
    </div>
  )
};

TabsToolbar.propTypes = {
  setSearchQuery: PropTypes.func,
  setNoInventoryCost: PropTypes.func,
  setStock: PropTypes.func,
  setWarningSearch: PropTypes.func,
  setWarning: PropTypes.func,
  isWarning: PropTypes.bool,
  tab: PropTypes.string,
  usedParams: PropTypes.array,
  setRoiMethod: PropTypes.func,
};

export default TabsToolbar;
