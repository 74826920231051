import React from "react";
import {floatNumber, floatNumberFixed, numberWithCommas} from "../utils";
import productApi from "../../../crud/product.crud";

export const totalItems = [
  "prep_centre",
  "packaging",
  "postage",
  "vat_free_postage",
  "handling",
  "other",
  "extra_fee",
];

export const allCOGFields = ["bundling", ...totalItems];

export const formatItems = [
  "bundling",
  "prep_centre",
  "packaging",
  "postage",
  "vat_free_postage",
  "handling",
  "other",
  "price",
  "inventory_cost",
  "break_even_point",
  "quantity",
  "product__break_even_point",
];

export const generalGOGSInterface = [
  {id: "bundling", label: "Bundling Fee", type: "RNumber",},
  {id: "prep_centre", label: "Prep Fee", type: "RNumber",},
  {id: "packaging", label: "Packaging", type: "RNumber",},
  {id: "handling", label: "Handling", type: "RNumber",},
  {id: "other", label: "Other", type: "RNumber",},
  {id: 'postage', label: 'Shipping', type: 'RNumber',},
  {id: "vat_free_postage", label: "VAT Free Shipping", type: "RNumber",},
  {id: "inbound_shipping", label: `Inbound Shipping Cost`, type: "RNumber",},
  {id: "extra_fee", label: "Extra Fee", type: "RNumber",},
  {id: "extra_fee_perc", label: "Extra Fee", type: "RNumber", beforeField: '%'},
  {id: "inbound_shipping_units", label: "Inbound Shipping Cost Units", type: "RNumber",},
];

export const filterFields = ({values, name, value, noCost, fields, fieldsList, weight}) => {
  const values_data = {...values};

  let val = floatNumber(value);

  if (name === 'extra_fee_perc') {
    val = 0
  }

  let filterItems = fieldsList ? fieldsList : allCOGFields;
  let filterTotalValues = Object.entries(values_data).filter(item => filterItems.includes(item[0]));
  const withoutActiveField = filterTotalValues.filter(item => item[0] !== name);
  let data = [];
  let result = [];
  if (fields) {
    data = withoutActiveField.map(([name, value]) => ({[name]: floatNumber(value)}));
    result = [...data, {[name]: val}]
  } else {
    data = Object.values(Object.fromEntries(withoutActiveField));
    result = [...data, val];
  }
  if (noCost && name === "inventory_cost") {
    result = data;
  }
  return result;
};

export const getTotalCOG = (data, inventory_cost, extra_fee_perc) => {
  const inventoryCost = extra_fee_perc ? inventory_cost * (1 + extra_fee_perc / 100) : inventory_cost;
  return [...data, inventoryCost]
    .filter(item => !!item && item !== '')
    .map(item => typeof item === "string" ? floatNumber(item) : item)
    .reduce((a, b) => a + b, 0).toFixed(2);
};

export const isInvalidDate = (values) => values.date_added === "Invalid date" || values.purchased_date === "Invalid date";

export const getBEP = async ({cogs, inventory_cost, extra_fee_perc, setFieldValue, bepId, bepType}) => {
  const inventoryCost = extra_fee_perc
    ? inventory_cost * (1 + extra_fee_perc / 100)
    : inventory_cost;
  const response = await productApi.getBreakEvenPoint(bepId, {cogs, inventory_cost: inventoryCost}, bepType);
  if (response) {
    const {data} = response;
    if (data) {
      const value = (data.break_point_even || data.break_point_even === 0) ? data.break_point_even : "-";
      setFieldValue("break_even_point", numberWithCommas(value));
    }
  }
};

export const setDefaultConstSettings = (form, type, product, setDefaultData, bepId, bepType) => () => {
  const {setFieldValue, values} = form;
  // const floatInventoryCost = !!form.values.inventory_cost ? numberWithCommas(form.values.inventory_cost) : null;
  setDefaultData(true);
  let cog = {};

  if (type === "clear") {
    // clear
    allCOGFields.forEach(i => cog[i] = 0); // cog
    const cogs = allCOGFields.map(i => ({[i]: 0})); // cogs for bep

    form.setValues({
      ...form.values,
      ...cog,
      total_cog: form.values.inventory_cost ? floatNumberFixed(form.values.inventory_cost) : null,
    });

    getBEP({
      cogs,
      inventory_cost: floatNumber(form.values.inventory_cost),
      extra_fee_perc: form.values.extra_fee_perc,
      setFieldValue,
      bepId,
      bepType
    })
    // clear end
  } else if (type === "undo") {
    // undo
    const fieldsCOG = values.bundled ? allCOGFields : totalItems;

    let total_cog = fieldsCOG.map(i => product[i]);// for total field
    allCOGFields.forEach(i => cog[i] = numberWithCommas(product[i])); // cog
    const cogs = fieldsCOG.map(i => ({[i]: floatNumber(product[i])})); // cogs for bep

    form.setValues({
      ...form.values,
      ...cog,
      total_cog: getTotalCOG(total_cog, form.values.inventory_cost, form.values.extra_fee_perc),
    });

    getBEP({
      cogs,
      inventory_cost: floatNumber(form.values.inventory_cost),
      extra_fee_perc: form.values.extra_fee_perc,
      setFieldValue,
      bepId,
      bepType
    })
    // undo end
  }
};

export const importCOGWarningText = ({noteImportsCOG = []}) => (
  <ul className="import-cog__warning-note">
    {noteImportsCOG.map((item, index) => <li key={index} className="warning-note__item">{item}</li>)}
  </ul>
);

export const bbpImportCOGS = {
  currency_code: "GBP",
  currency_symbol: "£",
  name: "COGS",
  total_label: "COGS Breakdown",
  data: [
    {"key": "inventory_cost", "name": "Unit Cost"},
    {"key": "prep_centre", "name": "Prep Centre"},
    {"key": "bundling", "name": "Bundling"},
    {"key": "packaging", "name": "Packaging"},
    {"key": "postage", "name": "Inbound Shipping Cost"},
    {"key": "vat_free_postage", "name": "VAT Free Postage"},
    {"key": "handling", "name": "Handling"},
    {"key": "other", "name": "Other"},
    {"key": "extra_fee", "name": "Extra Fee"},
  ],
};

export const cog_tooltip = {
  "prep_centre": 'This is the per item cost you are charged by your prep centre. If you self prep you can also work out an item fee and add this here.',
  "packaging": 'Use this to allow a fee assigned to each ASIN to allow for poly bags, wrap or special packaging material required by Amazon.',
  "postage": 'Postage fee for the inventory to be delivered to your home, office or prep centre from suppliers.',
  "extra_fee": 'Add in additional fees you would like included in the calculations.',
  "extra_fee_perc": 'Add in additional % you would like included in the calculations.',
  "handling": 'This allows you to assign a set fee to each sale for your handling of the inventory, this can cover your time to process inventory before sending into Amazon or onto prep centres.',
  "other": 'If you want to allow a set fee per ASIN instead of assigning individual costs you can simply add this here.',
  "inbound_shipping": 'This is the fee that Amazon (or 3PL) charges you for sending your packages into the fulfilment centre.',
};

export const help = "Use the add button when you have restocked this ASIN and are using a different SKU. " +
  "This option allows you to add a different COG figure too.";

export const IS_REIMBURSEMENTS_TESTER = id => [10, 73, 46].includes(id);

export const options = {
  "q": "q",
  "stock_or_inactive": "stock_or_inactive",
  "only_warning": "only_warning",
  "no_inventory_cost": "no_inventory_cost",
  'roi_method': 'roi_method',
};

export const actionProps = {fontSize: "large", htmlColor: "var(--main)"};

const popupCOGSInterface = [
  {id: "inventory_cost", label: "Unit Cost", type: "RNumber",},
  ...generalGOGSInterface,
  {id: "total_cog", label: "Total COG", type: "RNumber",}
];

const excludeGOG = ['inbound_shipping', 'extra_fee_perc', 'total_cog'];

export const getDataWithCOGSPopup = (data) => data.map((row) => {
  const cogsData = popupCOGSInterface.reduce((prev, {id, label}) => {
      let value = 0;
      if (row[id]) {
        if (!excludeGOG.includes(id)) {
          value = row[id] * row.quantity_ordered
        } else {
          value = row[id]
        }
      }

      return [...prev,
        {
          value,
          name: id === 'extra_fee_perc' ? `${label}%` : label,
          unit: id === 'extra_fee_perc' ? "%" : null,
        }
      ]
    },
    []);
  return {...row, cogsData};
});
