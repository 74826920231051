import React, {useEffect, useState} from 'react'
import {DropzoneArea} from 'material-ui-dropzone'
import * as PropTypes from "prop-types";

const UploadFileDropzone = ({ fileUpload, acceptedFiles, filesLimit=1 }) => {
  const [files, setFiles] = useState([]);

  const handleChange = (files) => {
    setFiles(files);
    fileUpload(files);
  };

  useEffect( ()=> {
  }, []);

  return (
    <>
      <DropzoneArea
        onChange={handleChange}
        acceptedFiles={acceptedFiles}
        filesLimit={filesLimit}
        dropzoneClass="upload-file-screen"
        dropzoneParagraphClass="title-dropzone"
        dropzoneText="Drag and drop your file here or click"
      />
    </>
  )
};

UploadFileDropzone.propTypes = {
  // e.g ['.csv']
  acceptedFiles: PropTypes.array.isRequired,
};

export default UploadFileDropzone;
