import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import DateTimeField from "../../components/controls/DateTimeField";
import * as PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import DButton from "../../components/Button";

const BottomHeaderCategories = (props) => {
  const {onDateFilter, errorDate, className, fieldsDate} = props;

  return (
    <Toolbar>
      <div className={className}>
        <div className="col-sm-12">
          <div className="row">
            {fieldsDate.map(field => {
              return <div key={field.id} className="form-group__date-picker">
                <DateTimeField
                  id={field.id}
                  name={field.id}
                  label={field.label}
                  setFieldValue={field.function}
                  value={field.value}
                />
              </div>
            })}
            <DButton typeOfButton="refresh" type="submit" onClickCustom={onDateFilter} className="date-picker__btn"/>
          </div>
          {errorDate
          && <div className="row">
            <Typography className="table__date-picker__error">
              The Start Date cannot be later than the End Date
            </Typography>
          </div>}
        </div>
      </div>
    </Toolbar>
  );
};

BottomHeaderCategories.propTypes = {
  onDateFilter: PropTypes.func.isRequired,
  errorDate: PropTypes.bool,
  className: PropTypes.string,
  fieldsDate: PropTypes.array,
};

export default BottomHeaderCategories;
