import React from "react";
import {FormControl, FormHelperText} from "@material-ui/core";
import classNames from 'classnames'
import DateTimeField from "../DateTimeField";

const DateField = allProps => {
  const {
    name,
    setFieldValue,
    setFieldTouched,
    placeholder,
    label,
    meta,
    showLabel,
    customProps = {},
    submitCount,
    ...props
  } = allProps;

  const {onChange} = customProps;
  const handleChange = (value) => {
    if (onChange) {
      onChange(value);
    } else {
      setFieldValue(name, value)
    }
  };

  const handleBlur = () => {
    setFieldTouched(name, true);
  };
  return (
    <FormControl
      error={!!(meta && (meta.touched || submitCount) && meta.error)}
      fullWidth
    >
      <DateTimeField
        id={name}
        label={label}
        name={name}
        setFieldValue={setFieldValue}
        handleChange={handleChange}
        handleBlur={handleBlur}
        className={classNames({"number-input--error": meta && (meta.touched || submitCount) && meta.error})}
        aria-describedby="component-error-text"
        {...props}
      />
      {meta && (meta.touched || submitCount) && meta.error && (
        <FormHelperText error id="component-error-text">
          {meta.error}
        </FormHelperText>
      )}
    </FormControl>
  )
};

export default DateField;