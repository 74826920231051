import React, { useEffect, useState } from "react";
import * as BankSelector from "../../../../../selectors/bank.selectors";
import * as importDuck from "../../../../../store/ducks/import.duck";
import { connect } from "react-redux";
import * as PropTypes from "prop-types";
import { useCheckboxStorage, useParseHistory, usePerPage } from "../../../../../hooks/modal.hook";
import * as BankDuck from "../../../../../store/ducks/bunk.duck";
import * as Selector from "../../../../../selectors/imortBankTransactions.selectors";
import ConfirmTab from "./ConfirmTab";
import IgnoredTab from "./IgnoredTab";
import CheckTab from "./CheckTab";
import DTabs from "../../../../components/DTabs/DTabs";
import withConfirmHook from "../../../../../hooks/withConfirm.hook";
import useTab from "../../../../../hooks/tab.hook";
import { headRows } from "./elements";

const TABS = {
  CHECK: {value: 'check', label: 'Check', index: 0},
  CONFIRMED: {value: 'confirmed', label: 'Confirmed', index: 1},
  IGNORED: {value: 'ignored', label: 'Ignored', index: 2},
};

const ImportedBankTransactions = (props) => {
  const { getBank, bank, getBankEntry } = props;
  // eslint-disable-next-line
  const [searchQuery, setSearchQuery] = useState(null);
  const [rowsPerPage, onPerPage] = usePerPage();
  const [tab, setTab] = useTab(TABS.CHECK.value);
  const [confirmActiveColumns, setConfirmActiveColumns] = useCheckboxStorage("confirmedList", headRows);
  const [ignoredActiveColumns, setIgnoredActiveColumns] = useCheckboxStorage("ignoredList", headRows);

  const bankEntryId = +useParseHistory().bank_entry_id;
  const bankId = useParseHistory().bank_account_id;

  useEffect(() => {
    getBank({ id: bankId });
    getBankEntry({ id: bankEntryId });
  }, []);

  const onRefresh = () => {
    getBank({ id: bankId });
    getBankEntry({ id: bankEntryId });
  };

  const onExportData = () => {};

  const title = bank && `Imported Transactions for ${bank.name} (${bank.id})`;
  let subtitle = null;
  let isHideSearch = false;
  let columns = undefined;
  let setColumns = undefined;
  switch (tab) {
    case TABS.CHECK.value:
      subtitle = `It looks like your transactions are overlapping previous `+
        `transactions entered up to 16/03/2020. Please delete prior transactions`+
        `up to the start date of your file import or delete the below transactions up to 16/03/2020`;
      isHideSearch = true;
      break;
    case TABS.CONFIRMED.value:
      columns = confirmActiveColumns;
      setColumns = setConfirmActiveColumns;
      break;
    case TABS.IGNORED.value:
      columns = ignoredActiveColumns;
      setColumns = setIgnoredActiveColumns;
      break;
  }

  const toolbarProps = {
    title,
    subtitle,
    isHideSearch,
    setSearchQuery,
    actionsHeader: tab !== TABS.CHECK.value && {
      onRefresh,
      onExportData,
      selectMenu: { setFunc: onPerPage, type: "select" },
      viewColumnMenu: { array: columns, type: "filter", setFunc: setColumns, disabled: true },
    }
  };

  const tabProps = {
    bankEntryId,
    bank,
    getBankEntry,
    getBank,
    bankId,
    rowsPerPage,
    activeColumns: columns,
    tab
  };

  const tabsData = [
    {
      value: TABS.CHECK.value,
      label: TABS.CHECK.label,
      content: <CheckTab tabProps={tabProps} />
    },
    {
      value: TABS.CONFIRMED.value,
      label: TABS.CONFIRMED.label,
      content: <ConfirmTab tabProps={tabProps} />
    },
    {
      value: TABS.IGNORED.value,
      label: TABS.IGNORED.label,
      content: <IgnoredTab tabProps={tabProps} />
    }
  ];

  return <DTabs
      className="imported-bank"
      value={tab}
      onChange={setTab}
      toolbarProps={toolbarProps}
      tabsData={tabsData}
    />
};

ImportedBankTransactions.propTypes = {
  getBank: PropTypes.func.isRequired,
  bank: PropTypes.object,
  // createCustomImportFormat: PropTypes.func.isRequired,
  importFormats: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    importFormats: Selector.selectImportFormats(state, props),
    bank: BankSelector.getBank(state, props),
    banks: BankSelector.getBanks(state, props),
    loading: Selector.getLoading(state, props),
    bankEntry: Selector.selectBankEntry(state, props),
  }
};

const mapDispatchToProps = {
  getBank: BankDuck.actions.getBank,
  getBanks: BankDuck.actions.getBanks,
  getBankEntry: importDuck.actions.getBankEntry,
  createBankEntry: importDuck.actions.createBankEntry,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withConfirmHook(ImportedBankTransactions));