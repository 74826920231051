import React from "react";
import { requiredValidator } from "../../../components/controls/validators";
import Form from "react-bootstrap/Form";
import WarningIcon from "@material-ui/icons/Warning";
import Typography from "@material-ui/core/Typography";
import { entryMethodOptions } from "../bankOptions";
import * as PropTypes from "prop-types";
import FormComponent from "../../../components/controls/FormComponent";

export const fields = (props) => {
  const { categories, valueTab, initValues, history, currencies, toggleInactiveModal } = props;
  const categoriesOptions = categories.map(({ name, id }) => ({ label: name, value: id }));

  return [
    {
      id: 'name',
      label: 'Display Name',
      type: 'text',
      validator: requiredValidator('Display Name is required'),
    },
    ...valueTab
      // field for only customer
      ? [{ id: 'customer_ref', label: 'Customer Reference', type: 'text' }]

      // field for only supplier
      : [{ id: 'supplier_ref', label: 'Supplier Reference', type: 'text' }],
    { id: 'default_category', label: 'Default category', type: 'RSelect', customProps: { options: categoriesOptions } },
    {
      id: 'currency',
      label: 'Currency',
      type: "RSelect",
      validator: requiredValidator('Currency is required'),
      customProps: { options: currencies.map(i => ({ label: i.name, value: i.id })) },
    },
    { id: 'add_invoice_reference_prefix', label: 'Add invoice reference prefix?', type: 'checkbox', showLabel: true },
    {
      id: 'invoice_ref_prefix',
      label: 'Invoice ref prefix:',
      showLabel: true,
      type: 'text',
      render: ({ setFieldValue, showLabel, customProps, values, ...props }) => {
        const onChange = (event) => {
          setFieldValue("invoice_ref_prefix", event.target.value);
        };
        const visible = values && values.add_invoice_reference_prefix;
        return <>
          {visible && (
            <Form.Group key="invoice_ref_prefix" controlId="invoice_ref_prefix">
              <Form.Label className="invoice_ref_prefix">Invoice ref prefix:</Form.Label>
              <FormComponent customProps={{ onChange }} values={values} {...props} />
            </Form.Group>
          )}
        </>
      }
    },
    ...valueTab
      // field for only customer
      ? [{
        id: 'send_payment_reminders',
        label: 'Send payment reminders',
        type: 'checkbox',
        showLabel: true,
        render: ({ setFieldValue, customProps, ...props }) => {
          const onChange = (e) => setFieldValue("send_payment_reminders", e.target.checked);
          const value = props.value || props.value === false ? !!props.value : true;
          const onDocument = () => history.push("/books/enter-transaction");

          return <>
            <FormComponent customProps={{ onChange }} {...props} />
            {value && (
              <div className="form-group__text-block">
                <WarningIcon/>
                Automated reminders are not active, please enable from the
                {/*TODO add link Document Settings*/}
                <Typography color="primary" variant="subtitle1" onClick={onDocument} className="form-group__link">
                  Document Settings
                </Typography>
              </div>
            )}
          </>
        }
      }]
      : [],
    {
      id: 'days_until_payment_due',
      label: 'Days until payment due',
      type: 'number',
      customProps: { type_number: "integer" }
      },
    ...valueTab
      // field for only customer
      ? [{ id: 'credit_limit', label: 'Credit limit', type: 'number' }]
      : [],
    { id: 'eu_vat', label: 'EU VAT?', type: 'checkbox', showLabel: true },

    ...valueTab
      // fields for only customer
      ? [{
        id: 'vat_number',
        label: 'VAT number',
        type: 'text',
        showLabel: true,
        render: ({ setFieldValue, showLabel, values, ...props }) => {
          const name = "vat_number";
          const onChange = (event) => setFieldValue(name, event.target.value);
          const isVisible = values && values.eu_vat;
          return <>
            {isVisible && (
              <Form.Group key={name} controlId={name}>
                <Form.Label className="vat_number">VAT number</Form.Label>
                <FormComponent customProps={{ onChange }} values={values} {...props} />
              </Form.Group>
            )}
          </>
        }
      },
      {
        id: 'country_code',
        label: 'Country code',
        type: 'RSelect',
        showLabel: true,
        //TODO add country_code options
        render: ({ customProps, values, ...props }) => {
          const name = "country_code";
          const isVisible = values && values.eu_vat;
          return <div>
            {isVisible && (
              <Form.Group key={name} controlId={name}>
                <Form.Label className="country_code">Country code</Form.Label>
                <FormComponent customProps={{ options: entryMethodOptions }} {...props} />
              </Form.Group>
            )}
          </div>
        }
      },
      {
        id: 'customer_group',
        label: 'Customer Group',
        type: 'RSelect',
        //TODO add customer_group options
        customProps: { options: entryMethodOptions }
      }]
      : [],
    ...initValues
      ? [{
        id: 'inactive',
        label: 'Inactive?',
        type: 'checkbox',
        showLabel: true,
        customProps: () => ({ setFieldValue }) => {
          const onChange = (event) => {
            setFieldValue("inactive", event.target.checked);
            if (event.target.checked) {
              toggleInactiveModal();
            }
          };
          return { onChange }
        }
      }]
      : [],
  ];

};

fields.propTypes = {
  categories: PropTypes.array,
  valueTab: PropTypes.bool,
  initValues: PropTypes.object,
  history: PropTypes.object,
  currencies: PropTypes.array,
  toggleInactiveModal: PropTypes.func,
};