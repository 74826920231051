import React from 'react'

const featuresOptions1 = [
  {
    title: 'Multiple Marketplaces',
    text: 'We support your business in multiple Amazon marketplaces including the UK & EU, Germany, France, Italy, Spain, Netherlands & Sweden. Plus we\'re adding more all the time! (US + more coming soon!)',
  },
  {
    title: 'Product Tax Coder Database & AI Notifier',
    text: 'Tax & VAT is complicated and most sellers are doing their calculations based on INCORRECT product tax codes. In the UK if your biscuit has chocolate on the INSIDE of the biscuit or on the outside effects the amount of VAT to be charged. SKUify simplifies PTC codes with our AI bot and manually verified by an accountant database.',
  },
  {
    title: 'Cost of Goods Fees',
    text: 'You can enter your prep fees, bundling fees, packaging, handling and shipping fees, inbound shipping cost plus more so not only you can keep track of per unit and whole business cost of goods, but SKUify can do it for you!',
  },
  {
    title: 'Sales Sound Notification ',
    text: 'Ding! You\'ve made another sale! You can have SKUify notify you with a audio sound of your choice in the background every time you make a sale! Nothing better than hearing the cash register "Kerching" sound knowing that you\'ve made some more money!',
  },
  {
    title: 'Grandfathered in Prices',
    text: 'We NEVER increase our prices on current customers. As long as you remain a customer of SKUify you will get today\'s current price LOCKED in for life! We are ALWAYS adding in new features and then we increase the price, as we do, you\'ll get those features included for FREE in your plan, no matter what happens to the price!',
  },
];

const featuresOptions2 = [
  {
    title: 'FBA, FBM and SFP Supported',
    text: 'We support Fulfilled by Amazon, fulfilled by merchant and seller fulfilled prime. So however you sell on Amazon, we have you covered!',
  },
  {
    title: 'UK, EFN, PAN-EU & Multi-Country Supported',
    text: 'If you sell in and around Europe, SKUify has you covered, however you choose to do it.',
  },
  {
    title: 'Multiple Currencies Supported',
    text: 'Select your preferred currency, US dollars $\'s, UK pounds £\'s or European Euros.',
  },
  {
    title: 'Multiple Currencies Supported',
    text: 'Select your preferred currency, US dollars $\'s, UK pounds £\'s or European Euros.',
  },
  {
    title: 'PDF Reports',
    text: 'One-click, easy to read, nicely formatted downloadable reports including period end inventory report, sales, cost of goods, winners, order reports, refund reports & more.',
  },
  {
    title: 'Title Change Monitor and Notifier',
    text: 'SKUify monitors your listings and if it detects an ASIN title change it will tell you so you can check your products are appropriately listed avoiding potential account problems.',
  },
  {
    title: 'Smart ASIN & SKU Notes',
    text: 'If you want to add a note for some of your stock, you can do that quickly and easily. 241? Where you purchased it? Price you listed it at? Whatever! Get creative!',
  },
];

function Features() {
  return (
    <>
     <section id="features-section" className="row_am analyze_section features_section">
      <div className="container">
        <div
          className="section_title"
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-delay="100"
        >
          <h2>
            SKUify is packed with even more features designed to supercharge your Amazon selling experience...
          </h2>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="analyze_text first-block" data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100">
              <ul>
                {featuresOptions1.map(({ title, text }, index) => (
                  <li key={index} data-aos="fade-up" data-aos-duration="2000">
                    <h3>{title}</h3>
                    <p>{text}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="col-md-6">
            <div className="analyze_text" data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100">
              <ul>
                {featuresOptions2.map(({ title, text }, index) => (
                  <li key={index} data-aos="fade-up" data-aos-duration="2000">
                    <h3>{title}</h3>
                    <p>{text}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section> 
    </>
  )
}

export default Features
