import React, {useLayoutEffect, useRef, useState} from "react";
import { Popper } from "@material-ui/core";
import { Fade } from "@material-ui/core";
import classNames from "clsx";
import * as PropTypes from "prop-types";

const TooltipComponentTitle = (props) => {
  const { children, tooltip, className, placement = "bottom", tooltipStyle, id="tooltip-popper", isBlur } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const ref = useRef(null);

  const useTips = () => {
    if (children) return true
    return ref.current?.scrollWidth > ref.current?.clientWidth;
  }

  const onMouseEnter = (event) => {
    const oldAnchor = document.getElementsByClassName("d-tooltip--popper");
    const arr = [...oldAnchor];

    arr?.length && arr.forEach(item => {
      item.firstChild && item.firstChild.remove();
    });
    setAnchorEl(event.currentTarget);
  };

  const onMouseLeave = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const _id = open ? id : undefined;
  return (
    <>
      {children ?
          (<div
            className="d-tooltip-children"
            aria-describedby={_id}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            ref={ref}
          >
            {children}
          </div>) :
          (<span
            className="copy-hover"
            aria-describedby={_id}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            ref={ref}
          >
            {tooltip}
          </span>)
      }
      {useTips() && (
          <Popper
            onMouseMove={onMouseLeave}
            id={_id}
            open={!!open}
            anchorEl={anchorEl}
            transition
            placement={placement}
            className={classNames("d-tooltip--popper", className)}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={{ enter: 400, exit: 100 }}>
                <div
                  className={classNames(tooltipStyle, "d-tooltip", {
                    "d-tooltip--empty": !tooltip,
                    blur: isBlur
                  })}
                >
                  {tooltip}
                </div>
              </Fade>
            )}
          </Popper>
      )}
    </>
  );
};

TooltipComponentTitle.propTypes = {
  className: PropTypes.string,
  tooltipStyle: PropTypes.string,
  placement: PropTypes.string,
};

export default TooltipComponentTitle;
