import { createSelector } from "reselect";

const Base = () => state => state.chart;

export const getChart = createSelector(
  Base(),
  (state) => state,
);

export const getOrdersRefunds = createSelector(
  Base(),
  (state) => state.ordersRefunds,
);

export const getOrderNotification = createSelector(
  Base(),
  (state) => state.orderNotification,
);