import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import * as transactionDuck from "../store/ducks/transactions.duck";
import * as Selector from "../selectors/transactions.selectors";
import {getCurrentCompanyId} from "../crud/utils";

const useTransaction = (props) => {
  let fetch = props?.fetch;
  if (fetch === undefined) {
    fetch = true;
  }

  const filters = props?.filters || {};

  const dispatch = useDispatch();
  const companyId = getCurrentCompanyId();

  const getTransactions = () => {
    dispatch(transactionDuck.actions.getTransactions({
      companyId,
      filters,
    }));
  };


  useEffect(() => {
    if (fetch) {
        getTransactions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const confirmTransaction = ({ id, values, onDone, onError }) => {
    dispatch(transactionDuck.actions.confirmTransaction({ idCompany: companyId, id, values, onDone, onError }));
  };

  const ignoreTransaction = ({ id, onDone, onError }) => {
    dispatch(transactionDuck.actions.ignoreTransaction({ idCompany: companyId, id, onDone, onError }));
  };
  const state = useSelector(state => state);

  return {
    transactions: Selector.selectTransactions(state),
    transactionsLoading: Selector.selectTransactionsLoading(state),
    confirmTransaction,
    ignoreTransaction,
  };
};

export default useTransaction;