import React, { useState } from 'react';
import Form from "react-bootstrap/Form";
import { entryMethodOptions } from "../bankOptions";
import FormField from "../../../components/controls/FormField";
import {requiredValidator} from "../../../components/controls/validators";
import * as PropTypes from "prop-types";
import CreateEditModal from "../../../components/CreateEditModal";
import RSelect from "../../../components/controls/RSelect";
import { getCurrentCompanyId } from "../../../../crud/utils";
import classNames from 'classnames';
import useCurrencies from "../../../../hooks/currencies.hook";
import FormComponent from "../../../components/controls/FormComponent";
import BtnEntryMethod from "./BtnEntryMethod";

const getSelectValue = (value) => {
  if (value && value.label) {
    return value.value;
  } else if (value && value.id) {
    return value.id;
  }
  return value;
};

export const processError = (error) => {
  const errorsCount = Object.keys(error.response.data).length;
  let msg = `${errorsCount} error prevented this bank account from being saved or updated. 
  Please see below for more details.`;
  Object.entries(error.response.data).forEach(([key, value]) => {
    msg += value.map((e) => e).join(',');
  });
  alert(msg);
};

export const PLAID_ACCESS_TOKEN_FIEL_NAME = 'plaid_access_token';

export default function EditAddBankingModal(props) {
  const {reset, initValues, updateBank, addBank, show, onClose, categories, onSuccess} = props;
  const [processErrors, setProcessErrors] = useState(null);
  const { currencies } = useCurrencies();

  // create and sort array of categories nominal code
  const allNominalCodes = categories && categories.map((category) => +category.nominal_code).sort((a, b) => a - b);

  // calculate the value of the current nominal code
  let currentCategoryCode = null;
  if (allNominalCodes) {
    currentCategoryCode = allNominalCodes.filter((nominalCode, prevCode) => nominalCode === prevCode + 1).length + 1
  }

  //defaultValue for nominal_code
  let defaultNominalCode = currentCategoryCode;
  if (initValues && initValues.nominal_code) {
    defaultNominalCode = initValues.nominal_code
  }

  const formFields = [
    {
      id: PLAID_ACCESS_TOKEN_FIEL_NAME,
      label: PLAID_ACCESS_TOKEN_FIEL_NAME,
      type: 'hidden',
    },
    {
      id: 'name',
      label: 'Name',
      type: 'string',
      validator: requiredValidator('Name is required'),
    },
    {
      id: 'nominal_code',
      label: 'Category code',
      type: "number",
      disabled: true,
      render: ({ value, ...props}) => {
        return <FormComponent value={defaultNominalCode} {...props} />
      }
    },
    {
      id: 'currency',
      label: 'Currency',
      type: 'RSelect',
      validator: requiredValidator('Currency is required'),
      customProps: () => () => {
        return {
          options: currencies.details.map((i) => ({
            label: i.name,
            value: i.id,
          })),
        }
      },
    },
    {
      id: 'entry_method',
      label: 'Entry Method',
      type: 'RSelect',
      validator: requiredValidator('Entry Method is required'),
      render: ({ setFieldValue, customProps, ...props }) => {
        const handleChange = (value) => {
          setFieldValue(props.name, value);
        };
        return <>
          <FormComponent customProps={{ onChange: handleChange, options: entryMethodOptions }} {...props} />
          {/*buttons for options Stripe Feed and Bank Feed at the entry_method*/}
          <BtnEntryMethod value={props.value} values={props.values} setFieldValue={setFieldValue} />
        </>
      },
    },
  ];
  const onSubmit = (values, {setSubmitting, resetForm}) => {
    const data = {...values};
    const onDone = () => {
      setSubmitting(false);
      resetForm();
      onSuccess();
      reset();
    };
    const onError = (error) => {
      // set errors in state
      setProcessErrors(Object.values(error.response.data));
      setSubmitting(false);

    };

    data.currency = getSelectValue(data.currency);
    data.entry_method = getSelectValue(values.entry_method);

    if (initValues && initValues.id) {
      updateBank({
        id: initValues.id,
        values: data,
        onDone,
        onError,
      });
    } else {
      data.nominal_code = currentCategoryCode;
      data.account_currency_balance = 0;
      data.company = +getCurrentCompanyId();
      data.category_code = currentCategoryCode;
      addBank({
        values: data,
        onDone,
        onError,
      });
    }

  };
  return (
    <CreateEditModal
      modalTitle={initValues && initValues.id ? "Edit Bank Account" : "New Bank Account"}
      onSubmit={onSubmit}
      onClose={onClose}
      show={show}
      className="modal--small"
      initValues={initValues}
      nameNotification="Bank Account"
      processErrors={processErrors}>
      {(form) => (
        formFields.map((item) => (
          <Form.Group key={item.id} controlId={item.id} className={classNames({'hidden': item.type === 'hidden'})}>
            <Form.Label>{item.label}</Form.Label>
            <FormField
              render={item.render}
              name={item.id}
              label={item.label}
              type={item.type}
              disabled={item.disabled}
              validate={item.validator}
              customProps={item.customProps && item.customProps()}
              {...form}
            />
          </Form.Group>
        ))
      )}
    </CreateEditModal>
  );
}

EditAddBankingModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  addBank: PropTypes.func,
  updateBank: PropTypes.func.isRequired,
  initValues: PropTypes.object,
  reset: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  categoriesCode: PropTypes.array,
};
