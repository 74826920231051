import React, {useState} from "react";
import {Accordion} from "react-bootstrap";
import {Typography} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import './help.scss'
import {Button, Collapse} from "@material-ui/core";

const data = [
  {
    title: 'How to upgrade to Pro account?',
    text: 'Enjoy instant access to our vast library of 5,121 premium products and all upcoming new releases with super-fast download speeds powered by Amazon S3. Yes, you read that right. Getting $127,035 in value means you\'re saving more than 99% on all products making it the sweetest deal for premium design assets around.'
  },
  {
    title: 'I forgot my password',
    text: 'Enjoy instant access to our vast library of 5,121 premium products and all upcoming new releases with super-fast download speeds powered by Amazon S3. Yes, you read that right. Getting $127,035 in value means you\'re saving more than 99% on all products making it the sweetest deal for premium design assets around.'
  },
  {
    title: 'I can’t reset my password',
    text: 'Enjoy instant access to our vast library of 5,121 premium products and all upcoming new releases with super-fast download speeds powered by Amazon S3. Yes, you read that right. Getting $127,035 in value means you\'re saving more than 99% on all products making it the sweetest deal for premium design assets around.'
  },
  {
    title: 'How to upgrade to Pro account?',
    text: 'Enjoy instant access to our vast library of 5,121 premium products and all upcoming new releases with super-fast download speeds powered by Amazon S3.'
  },
  {
    title: 'How do I change and reset my password',
    text: 'Enjoy instant access to our vast library of 5,121 premium products and all upcoming new releases with super-fast download speeds powered by Amazon S3. Yes, you read that right. Getting $127,035 in value means you\'re saving more than 99% on all products making it the sweetest deal for premium design assets around.'
  },
];

const Help = () => {
  const [active, setActive] = useState();
  const onTab = (index) => setActive(active === index ? -1 : index);
  return (
    <div className="help-page">
      {
        data.map(({title, text}, index) => {
          const show = active === index;
          return (
            <React.Fragment key={index}>
              <Button
                className="header"
                variant="contained"
                endIcon={show ?  <RemoveIcon className="remove"/> : <AddIcon className="add"/>}
                onClick={() => onTab(index)}
              >
                <Typography>{title}</Typography>
              </Button>
              <Collapse className="content" orientation="horizontal" in={show}>
                <Typography>
                  {text}
                </Typography>
              </Collapse>
            </React.Fragment>
          )
        })
      }
    </div>
  )
};

export default Help;