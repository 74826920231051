import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { actions } from "../store/ducks/chart.duck.js";
import * as ChartSelector from "../selectors/chart.selectors";

const useOrders = (props = {}) => {
  const { fetch = true } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (fetch) {
     dispatch(actions.getOrders({ autoRefresh: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch]);

  const getOrders = (args) => {
    dispatch(actions.getOrders(args));
  };

  const updateOrderNote =  (args) => {
    dispatch(actions.updateOrderNote(args));
  };

  const updateOrderCOG =  (args) => {
    dispatch(actions.updateOrderCOG(args));
  };

  const ordersRefunds = ChartSelector.getOrdersRefunds(useSelector(state => state));
  const orderNotification = ChartSelector.getOrderNotification(useSelector(state => state));
  return {
    getOrders,
    orders: ordersRefunds.orders,
    refunds: ordersRefunds.refunds,
    loadingOrders: ordersRefunds.loading,
    updateOrderNote,
    updateOrderCOG,
    notification: orderNotification.notification,
  };
};

export default useOrders;