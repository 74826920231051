import React, {useState} from "react";
import Form from "react-bootstrap/Form";
import {Form as FormikForm, Formik} from "formik";
import FormField from "../../components/controls/FormField";
import {Button, Link, Typography} from "@material-ui/core";
import * as PropTypes from "prop-types";
import classNames from "clsx";
import useUsers from "../../../hooks/users.hook";
import {currencySymbols, floatNumber, floatNumberFixed, momentFormatOptions, numberWithCommas} from "../utils";
import moment from 'moment';
import {requiredValidator} from "../../components/controls/validators";
import {
  allCOGFields,
  cog_tooltip,
  filterFields,
  formatItems,
  getBEP,
  getTotalCOG,
  isInvalidDate,
  setDefaultConstSettings,
  totalItems,
  generalGOGSInterface
} from "./utils";
import FormComponent from "../../components/controls/FormComponent";
import {dataRefactoring} from "../Settings/COGSettings/utils";
import toast from "../../components/toast/toast";
import TooltipComponent from "../../components/TooltipComponent";

const DetailsForm = ({ editing, product={}, onAdd, onEdit, onDelete, isImportProduct, pageName, asin_update, bepType }) => {
  const [isDefaultData, setDefaultData] = useState(false);
  const { user } = useUsers({ fetch: false });
  const isSales = pageName === "sales" || pageName === "dash";
  const {
    id,
    bundling,
    prep_centre,
    packaging,
    postage,
    vat_free_postage,
    handling,
    other,
    extra_fee,
    extra_fee_perc,
    inventory_cost,
    parent,
    seller_sku,
    relatedProduct,
    marketplace,
    amazon_product_url,
    restock,
    otherMarketplace,
    inbound_shipping_units,
    inbound_shipping,
  } = product;
  const isMainRestock = !!restock && !relatedProduct && !otherMarketplace;
  let { price, asin1, weight, purchased_from, purchased_date, item_name, quantity, date_added, break_even_point, fulfillment, storage_fee } = product;

  if (isSales) {
    const {
      product__purchased_from,
      product__purchased_date,
      product__storage_fee,
      order__fulfillment_channel,
      product__weight,
      item_price_amount,
      asin,
      title,
      quantity_ordered,
      product__date_added,
    } = product;
    fulfillment = order__fulfillment_channel;
    purchased_from = product__purchased_from;
    weight = product__weight;
    purchased_date = product__purchased_date;
    price = item_price_amount;
    asin1 = asin;
    item_name = title;
    quantity = quantity_ordered;
    date_added = product__date_added;
    storage_fee = product__storage_fee;
  }
  const bepId = id;

  const onBlur = (values, name, setFieldValue) => (value) => {
    setDefaultData(false);
    setFieldValue(name, value);
    let fieldsList = values.bundled ? allCOGFields : totalItems;

    const cogs = filterFields({ values, name, value, fields: true, noCost: true, fieldsList, weight });

    let inventory_cost = 0;
    if (name === "inventory_cost") {
      inventory_cost = !!value ? floatNumber(value) : 0;
    } else {
      inventory_cost = !!values.inventory_cost ? floatNumber(values.inventory_cost) : 0;
    }

    const extra_fee_perc_ = name === 'extra_fee_perc' ? floatNumber(value) : values.extra_fee_perc;

    const total_cog = getTotalCOG(filterFields({ values, name, value, fieldsList, weight, noCost: true }), inventory_cost, extra_fee_perc_);
    setFieldValue("total_cog", total_cog);

    getBEP({ cogs, inventory_cost, extra_fee_perc: extra_fee_perc_, setFieldValue, bepId, bepType })
  };

  const getCustomProps = (name, other={}) => ({ values, setFieldValue }) =>
    ({ onBlur: onBlur(values, name, setFieldValue), ...other });

  const isEditing = editing || parent;

  let initInventoryCost = "";
  if (!!inventory_cost || inventory_cost === 0) {
    initInventoryCost = numberWithCommas(inventory_cost);
  }

  const cog = [
    prep_centre,
    packaging,
    handling,
    other,
    extra_fee,
    postage,
    ...(fulfillment === "AFN" ? [inbound_shipping] : [vat_free_postage])
    // storage_fee, TODO: STORAGE FEE TASK
  ];

  const productQuantity = (!!quantity || quantity === 0) ? quantity : "";

  const init = {
    bundling: numberWithCommas(bundling),
    prep_centre: numberWithCommas(prep_centre),
    packaging: numberWithCommas(packaging),
    seller_sku: seller_sku,
    price: numberWithCommas(price),
    quantity: isEditing || isImportProduct || isSales ? productQuantity : "",
    date_added: date_added,
    inventory_cost: initInventoryCost,
    purchased_from: purchased_from,
    purchased_date: (isEditing || isImportProduct) ? purchased_date : purchased_date,
    // purchased_date: (isEditing || isImportProduct) ? purchased_date : undefined,
    break_even_point: numberWithCommas(break_even_point),
    total_cog: getTotalCOG(product.bundled ? [...cog, bundling] : cog, inventory_cost, extra_fee_perc),
    bundled: !!product.bundled,
    handling: numberWithCommas(handling),
    other: numberWithCommas(other),
    extra_fee: numberWithCommas(extra_fee),
    extra_fee_perc: numberWithCommas(extra_fee_perc),
    postage: numberWithCommas(postage),
    // storage_fee: numberWithCommas(storage_fee), TODO: STORAGE FEE TASK
  };

  if (fulfillment === 'AFN') {
    init.inbound_shipping = numberWithCommas(inbound_shipping);
  } else {
    init.vat_free_postage = numberWithCommas(vat_free_postage);
  }

  const inventoryCost = [
    {
      id: "inventory_cost",
      label: "Unit cost",
      sub_label: "Inclusive of VAT",
      type: "RNumber",
      // disabled: relatedProduct,
      validator: editing ? null : requiredValidator('Unit cost is required'),
      render: ({ customProps, ...props }) => {
        const values = props.values;
        const setFieldValue = props.setFieldValue;
        let valFree = values.inventory_cost;
        const handleBlur = (value) => {
          valFree = !!value ? floatNumberFixed(value) : "";
          onBlur(values, "inventory_cost", setFieldValue)(value, values.inventory_cost)
        };
        return <div className={classNames("inventory_cost__wrap-input")}>
          <FormComponent
            customProps={{
              beforeField: currencySymbols[user.currency],
              onBlur: handleBlur,
              // prefixValue:  currencySymbols[user.currency]
            }}
            {...props}
          />
          {valFree === "0.00" && <p className="form-group__text-input">Free</p>}
        </div>
      }
    },
    {
      id: "quantity",
      label: "Quantity",
      type: "number",
      disabled: isSales || isEditing,
      customProps: { variant: "outlined", type_number: "integer" },
      validator: editing  ? null : requiredValidator('Quantity is required'),
    },
    { id: "purchased_from",
      label: "Purchased from",
      type: "text",
      disabled: isSales,
      placeholder: " ",
      customProps: { noLabel: true },
    },
    {
      id: "purchased_date",
      label: "Purchase date",
      type: "dateRB",
      validator: isSales ? null : requiredValidator('Date purchased is required'),
    },
    {
      id: "bundled",
      label: "Bundle",
      type: "switch",
      customProps: (props) => {
        const { values, setFieldValue } = props;
        const onChange = (event) => {
          const value = event.target.checked;
          setFieldValue("bundled", value);
          const bundlingVal = value ? values.bundling : 0;
          let fieldsList = value ? allCOGFields : totalItems;
          const total_cog = getTotalCOG(filterFields({
            values,
            name: "bundling",
            value: bundlingVal,
            fieldsList,
          }), values.inventory_cost, values.extra_fee_perc);
          setFieldValue("total_cog", total_cog);
          const cogs = filterFields({ values, name: "bundling", value: bundlingVal, fields: true, noCost: true });
          getBEP({
            cogs,
            inventory_cost: floatNumber(values.inventory_cost),
            extra_fee_perc: values.extra_fee_perc,
            setFieldValue, bepId, bepType
          });
        };
        return { onChange }
      }
    },
  ];

  const inboundShippingUnits = inbound_shipping_units === 1 ? 'kg' : 'lb';

  const filterOptions = ["bundling", fulfillment === 'AFN' ? "vat_free_postage" : "inbound_shipping" ];
  const filterInterface = generalGOGSInterface.filter(({ id }) => !filterOptions.includes(id));

  const cogInterface = filterInterface.map(({ beforeField, label,  ...item }) =>
    ({
      ...item,
      label: label,
      customProps: getCustomProps(item.id, { beforeField: beforeField || currencySymbols[user.currency] })
    })
  );

  const allCOGInterface = [
    {
      ...generalGOGSInterface.find(({ id }) => id === "bundling"),
      render: ({ customProps, ...props }) => {
        const { name, setFieldValue, values } = props;
        let isShow = values.bundled;
        return isShow &&
          <div className="form-group__render-wrap">
            <Form.Label column={false}>Bundling Fee</Form.Label>
            <FormComponent
              customProps={{ onBlur: onBlur(values, name, setFieldValue), beforeField: currencySymbols[user.currency] }}
              {...props}
            />
          </div>
      }
    },
    ...cogInterface
  ];

  let finalCOGInterface = allCOGInterface.filter(cogs => cogs.id !== "inbound_shipping_units")
  finalCOGInterface = [
    ...finalCOGInterface,
    {
      id: "inbound_shipping_units",
      name: "inbound_shipping_units",
      label: "Inbound Shipping Units",
      type: "radio",
      value: inbound_shipping_units,
      customProps: {
        radio: [
          {label: "kg", value: "1", disabled: true, size: "small", color: "primary"},
          {label: "lb", value: "2", disabled: true, size: "small", color: "primary"}
        ],
      },
    },
  ]

  const cogTotalInterface = [
    {
      id: "total_cog",
      label: "Total cost of goods",
      type: "RNumber",
      disabled: true,
      customProps: { beforeField: currencySymbols[user.currency] }
    },
    {
      id: "break_even_point",
      label: "",
      type: "RNumber",
      disabled: true,
      render: ({ customProps, ...props }) => {
        const { values } = props;
        const bep = floatNumber(values.break_even_point);
        let isShow = !!bep;
        if (bep === "NaN") {
          isShow = false;
        }
        return isShow && <div className="form-group__render-wrap">
          <Form.Label className="form-group__label-item" column={false}>Break Even Point</Form.Label>
          <FormComponent customProps={{ beforeField: currencySymbols[user.currency] }}{...props} />
        </div>
      }
    },
  ];

  const onDeleteWithClose = async () => {
    await onDelete();
    // close();
  };

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    const data = {...values};
    data.note = isEditing ? product.note : null;
    if (isSales) {
      data.product__date_added = data.date_added;
      data.product__purchased_date = data.purchased_date && moment(data.purchased_date).format('YYYY-MM-DDThh:mm');
      data.product__purchased_from = data.purchased_from;
      data.asin = asin1;
      data.quantity_ordered = (!!data.quantity || data.quantity === 0) ? data.quantity : null;
      data.asin_update = asin_update;
      data.item_price_amount = data.price.replaceAll(',', '');

      const removeItems = ["purchased_date", "purchased_from", "quantity", "date_added", "price"];
      removeItems.forEach(key => delete data[key]);
    } else {
      data.date_added = moment(data.date_added).format('YYYY-MM-DDThh:mm');
      data.purchased_date = data.purchased_date && moment(data.purchased_date).format('YYYY-MM-DDThh:mm');
      data.asin1 = asin1;
      data.quantity = (!!data.quantity || data.quantity === 0) ? data.quantity : null;
    }
    data.marketplace = (isEditing || isSales) ? marketplace : null;
    delete data["total_cog"];

    const [action, successMsg] = (isEditing || isImportProduct || isSales) ? [onEdit, "Updated"] : [onAdd, "Saved"];
    try {
      await action(dataRefactoring(data, floatNumber, formatItems));
      setSubmitting(false);
      resetForm();
      setDefaultData(false);
      toast.success(`${successMsg} Successfully!`, { duration: 3000 })
      // close();
    } catch (error) {
      setSubmitting(false);
      // toast.error(error)
    }
  };

  const isDisabledDelete = isImportProduct ? false : !isMainRestock;
  return (
    <Formik
      enableReinitialize
      initialValues={init}
      onSubmit={onSubmit}
    >
      {({ ...form }) => {
        return <FormikForm>
          <div className="products__details__body" onClick={event => event.stopPropagation()}>
            <div className="top wrap">
              <div className="products__details__head">
                <Link
                  title={item_name}
                  target="_blank"
                  href={amazon_product_url}
                  className="products__details__link"
                >
                  <span className="subhead--m">Title:</span> {item_name}
                </Link>

                <div className="products__details__subhead">
                  <Typography variant="body2" className="subhead--item">
                    <span className="bold subhead--m">ASIN:</span>
                    {asin1}
                  </Typography>

                  {isImportProduct ?
                    (
                      <Form.Group>
                        <Form.Label column={false}  style={{fontSize: 12}}>
                          <span className="bold subhead--m editable-sku-form-group">SKU:</span>
                        </Form.Label>
                        <FormField
                            name="seller_sku"
                            type="text"
                            label="SKU"
                            placeholder={seller_sku}
                            customProps={{
                              style: { fontWeight: 'bold' }
                            }}
                            {...form}
                        />
                      </Form.Group>
                    ) : (
                      <Typography variant="body2" className="subhead--item">
                        <span className="bold subhead--m">SKU:</span>
                        {seller_sku}
                      </Typography>
                    )
                  }

                  <Typography variant="body2" className="subhead--item subhead--item__price">
                    <span className="bold subhead--m">Price:</span>
                    {`${currencySymbols[user.currency]}${price}`}
                  </Typography>

                  <Typography variant="body2" className="subhead--item">
                    <span className="bold subhead--m">Date Added:</span>
                    {moment(date_added).format(momentFormatOptions.display)}
                  </Typography>
                </div>
              </div>

              <div className="products__details__inventory-cost">
                {inventoryCost.map(item => (
                  <Form.Group key={item.id} controlId={item.id} className={`form-group--${item.id}`}>
                    <Form.Label column={false}>
                      {item.label}
                      {item.sub_label && <p className="form-group--label-notes">{item.sub_label} </p>}
                    </Form.Label>
                    <FormField
                      name={item.id}
                      label={item.label}
                      type={item.type}
                      placeholder={item.placeholder}
                      customProps={item.customProps}
                      render={item.render}
                      disabled={item.disabled}
                      validate={item.validator}
                      {...form}
                    />
                  </Form.Group>
                ))}
              </div>
            </div>

            <div className="bottom wrap">
              <Typography variant="h6" className="products__details__title">cost of goods information</Typography>
              <div className={classNames("form__details--cog", {
                "form__details--auto": isDefaultData,
                "form__details--edit": isEditing,
              })}>
                {finalCOGInterface.map(item => (
                  <Form.Group key={item.id} controlId={item.id} className={`form-group--${item.id}`}>
                    <Form.Label column={false}>
                      <TooltipComponent
                          tooltip={cog_tooltip[item.id]}
                          id={`${item.id}-tooltip`}
                          className="big-z-index"
                      >
                        {item.label}
                      </TooltipComponent>
                    </Form.Label>
                    <FormField
                      render={item.render}
                      name={item.id}
                      label={item.label}
                      showLabel={item.showLabel}
                      type={item.type}
                      validate={item.validator}
                      customProps={item.customProps}
                      disabled={item.disabled}
                      placeholder={item.placeholder}
                      radio={item.radio}
                      {...form}
                    />
                  </Form.Group>
                ))}
              </div>

              <div className="products__details__wrap-btn">
                <Button
                  onClick={setDefaultConstSettings(form, "undo", product, setDefaultData, bepId, bepType)}
                  className="products__details__btn products__details__btn--yellow"
                >
                  Undo
                </Button>

                <Button
                  onClick={setDefaultConstSettings(form, "clear", product, setDefaultData, bepId, bepType)}
                  className="products__details__btn products__details__btn--orange"
                >
                  Clear
                </Button>
              </div>
            </div>

            <div className="total wrap">
              <div className={classNames("form__details--cog", {
                "form__details--auto": isDefaultData,
                "form__details--edit": isEditing,
              })}>
                {cogTotalInterface.map(item => (
                  <Form.Group key={item.id} controlId={item.id} className={`form-group--${item.id}`}>
                    <Form.Label column={false}>{item.label}</Form.Label>
                    <FormField
                      render={item.render}
                      name={item.id}
                      label={item.label}
                      showLabel={item.showLabel}
                      type={item.type}
                      validate={item.validator}
                      customProps={item.customProps}
                      disabled={item.disabled}
                      placeholder={item.placeholder}
                      {...form}
                    />
                  </Form.Group>
                ))}
              </div>

              <div className="products__details__wrap-btn">
                <Button
                  onClick={onDeleteWithClose}
                  className="products__details__btn products__details__btn--red"
                  disabled={isDisabledDelete}
                >
                  Delete
                </Button>

                <Button
                  type="submit"
                  disabled={isInvalidDate(form.values)}
                  className={classNames("products__details__btn", {"products__details__btn--add": !isEditing})}>
                  {(isEditing || isImportProduct) ? 'Update' : 'Save'}
                </Button>
              </div>
            </div>
          </div>
        </FormikForm>
      }}
    </Formik>
  );
};

DetailsForm.propTypes = {
  product: PropTypes.object,
  onEdit: PropTypes.func,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  editing: PropTypes.bool,
  isImportProduct: PropTypes.bool
};

export default DetailsForm;
