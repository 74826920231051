import { createSelector } from 'reselect'

export const getSettings = createSelector(
  state => state.setting,
  (setting) => setting.settings
)

export const getVATSettings = createSelector(
  state => state.setting,
  (setting) => setting.vatSettings
)

export const getROISettings = createSelector(
    state => state.setting,
    (setting) => setting.roiSettings
)


// export const getROISettings = createSelector(
//     state => state.setting,
//     (setting) => setting.roiSettings
// );

export const getAccountSettings = createSelector(
  state => state.setting,
  (setting) => setting.accountSettings
)

export const getAmazonSettings = createSelector(
  state => state.setting,
  (setting) => setting.amazonSettings
)

export const getMarketplaces = createSelector(
  state => state.setting,
  (setting) => setting.marketplaces
)

export const getShippingPlans = createSelector(
  state => state.setting,
  (setting) => setting.shippingPlans
)

export const getOptionsShippingPlans = createSelector(
  state => state.setting,
  (setting) => setting.optionsShippingPlans
)

export const getGeolocation = createSelector(
  state => state.setting,
  (setting) => setting.geolocation
)

export const updatePassword = createSelector(
  state => state.setting,
  (setting) => setting.updateUserPassword
)

export const onWalk = createSelector(
  state => state.setting,
  (setting) => setting.walk
)

export const firstWalk = createSelector(
  state => state.setting,
  (setting) => setting.firstWalk
)