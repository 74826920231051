import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import * as Selector from "../selectors/imortBankTransactions.selectors";
import * as importDuck from "../store/ducks/import.duck";

const useCustomImportCrud = (props) => {
  const fetch = props && props.fetch || true;

  const dispatch = useDispatch();
  useEffect(() => {
    if (fetch)
      dispatch(importDuck.actions.getImportFormats());
  }, []);
  const importFormats = Selector.selectImportFormats(useSelector(state => state));

  const update = (id, values) => {
    dispatch(importDuck.actions.updateImport({
      id: id,
      values: values,
    }));
  };
  const detail = (id) => {
    dispatch(importDuck.actions.getCustomImport({
      id: id,
    }));
  };

  const create = ({ values, onDone, onError }) => {
    dispatch(importDuck.actions.createImportFormat({
      values,
      onDone,
      onError
    }));
  };

  return {
    importFormats: importFormats,
    updateBankFile: update,
    createCustomImportFormat: create,
  };
};

export default useCustomImportCrud;