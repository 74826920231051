import {
  AUTO_CONFIRMED_BANK_TRANSACTIONS,
  BANK_RULES,
  EDIT_BANK_RULE,
  NEW_BANK_RULE
} from "../../../../router/routesMap";

export const goToEditBankRulePage = (history, bankRuleId) => {
  history.push(EDIT_BANK_RULE + `?bank_rule_id=${bankRuleId}`);
};

export const goToNewRulesPage = (history) => {
  history.push(NEW_BANK_RULE);
};

export const goToBankRulesPage = (history) => {
  history.push(BANK_RULES);
};

export const goToAutoConfirmPage = (history, bankRuleId) => {
  history.push(AUTO_CONFIRMED_BANK_TRANSACTIONS + `?bank_rule_id=${bankRuleId}`);
};