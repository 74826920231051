import { requests } from "./utils";

export default {
  getUsers: () => requests.get('/users'),
  addUser: (values) => requests.post(`/users`, values),
  updateUser: (id, values) => requests.patch(`/users/${id}`, values),
  deleteUser: (id) => requests.del(`/users/${id}`),

  getGroups: () => requests.get('/users/groups'),
  addGroup: (values) => requests.post(`/users/groups`, values),
  updateGroup: (id, values) => requests.patch(`/users/groups/${id}`, values),
  deleteGroup: (id) => requests.del(`/users/groups/${id}`),

  addPermission: () => requests.post(`/groups/permissions`),
  updatePermission: (id, values) => requests.patch(`/users/groups/permissions/${id}`, values),
  deletePermission: (id) => requests.del(`/groups/permissions/${id}`),
};
