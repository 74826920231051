import { createTheme } from "@material-ui/core/styles";
import { colors } from "../dataChart";
import { getNumber } from "../../../utils";

export const styleColor = (color1, color2) =>
  createTheme({
    palette: {
      primary: {
        main: `${color1}`
      },
      secondary: {
        main: `${color2}`
      }
    }
  });

const initChart = [0, 0];

const checkChartData = (chartData, labels) => {
  return !!chartData?.length && chartData.some(val => val > 0)
    ? chartData?.length === 1
      ? { chart_info: [0, ...chartData], labels: ["", ...labels] }
      : { chart_info: chartData, labels }
    : { chart_info: initChart, labels: ["", ""] };
};

export const getChartLegend = (chart, currency, colors, isHide) => {
    let chart_data = chart.chart_data || {};
    return [
        {
            name: "Sales",
            balance: getNumber(chart.sales),
            style: styleColor(colors.sales1, colors.sales2),
            prev: getNumber(chart.sales_compared),
            recurring: getNumber(chart.sales),
            units: currency,
            tooltip: "help",
            width: 2,
            url: '/media/d-icons/dash/dash_sales.svg',
            ...checkChartData(chart_data.sales?.values, chart_data.labels)
        },
        {
            name: "Units Sold",
            balance: chart.units_sold,
            style: styleColor(colors.units1, colors.units2),
            prev: getNumber(chart.units_sold_compared),
            recurring: getNumber(chart.units_sold),
            tooltip: "help",
            type_number: "integer",
            width: 2,
            url: '/media/d-icons/dash/dash_units_sold.svg',
            ...checkChartData(chart_data.units_sold?.values, chart_data.labels)
        },
        {
            name: "Profit",
            balance: getNumber(chart.profit),
            style: styleColor(colors.profit1, colors.profit2),
            prev: getNumber(chart.profit_compared),
            recurring: getNumber(chart.profit),
            units: currency,
            tooltip: "help",
            width: 2,
            url: '/media/d-icons/dash/dash_profit.svg',
              ...checkChartData(chart_data.profit?.values, chart_data.labels)
        },
        {
            name: "Refunds",
            balance: getNumber(chart.refunds),
            style: styleColor(colors.refunds1, colors.refunds2),
            prev: getNumber(chart.refunds_compared),
            recurring: getNumber(chart.refunds),
            units: currency,
            tooltip: "help",
            width: 2,
            url: '/media/d-icons/dash/dash_refunds.svg',
            ...checkChartData(chart_data.refunds?.values, chart_data.labels),
        },
        {
            name: "Margin",
            balance: getNumber(chart.margin),
            style: styleColor(colors.margin1, colors.margin2),
            prev: getNumber(chart.margin_compared),
            recurring: getNumber(chart.margin),
            units: "%",
            tooltip: "marginHelp",
            width: 2,
            url: '/media/d-icons/dash/dash_margin.svg',
            ...checkChartData(chart_data.margin?.values, chart_data.labels)
        },
        {
            name: "ROI",
            balance: getNumber(chart.roi),
            style: styleColor(colors.roi1, colors.roi2),
            prev: getNumber(chart.roi_compared),
            recurring: getNumber(chart.roi),
            units: "%",
            tooltip: "roiHelp",
            width: 2,
            url: '/media/d-icons/dash/dash_roi.svg',
            ...checkChartData(chart_data.roi?.values, chart_data.labels)
        },
        {
            name: "Expenses",
            balance: getNumber(chart.expenses),
            style: styleColor(colors.expenses, colors.expenses),
            prev: getNumber(chart.expenses_compared),
            recurring: getNumber(chart.expenses),
            units: currency,
            tooltip: "help",
            width: 2,
            url: '/media/d-icons/dash/dash_expenses.svg',
            ...checkChartData(chart_data.expenses?.values, chart_data.labels)
        },
        {
            // name: "Inventory Cost",
            name: "Total Inventory Cost",
            balance: chart.inventory_cost,
            style: styleColor(colors.inventoryCost, colors.inventoryCost),
            prev: getNumber(chart.inventory_cost_compared),
            recurring: getNumber(chart.inventory_cost),
            units: currency,
            tooltip: "inventoryCostHelp",
            type_number: "integer",
            width: 2,
            url: '/media/d-icons/dash/dash_inventory_cost.svg',
            ...checkChartData(chart_data.inventory_cost?.values, chart_data.inventory_cost_labels)
        }
        // {
        //     name: "Resale Value",
        //     balance: chart.resale_value,
        //     style: styleColor(colors.resaleValue, colors.resaleValue),
        //     prev: getNumber(chart.resale_value_compared),
        //     recurring: getNumber(chart.resale_value),
        //     units: currency,
        //     tooltip: "resaleValueHelp",
        //     type_number: "integer",
        //     width: 2
        // },
    ]
};
