import React from 'react';
import * as PropTypes from "prop-types";
import NoteTabTable from "../Dashboard/components/NoteTabTable";
import useProducts from "../../../hooks/products.hook";

const NoteReconciliation = ({ row }) => {
  const { updateReconciliationNote } = useProducts({ fetch: false });
  return <NoteTabTable update={updateReconciliationNote} row={row} target="reconciliation" />;
};

NoteReconciliation.propTypes = {
  row: PropTypes.object,
};

export default NoteReconciliation;