import React from 'react';
import { IconButton, Paper, Typography, Popover } from "@material-ui/core";
import DetailsForm from "./DetailsForm";
import * as PropTypes from "prop-types";
import EditIcon from '@material-ui/icons/Edit';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import TooltipComponent from "../../components/TooltipComponent";
import TypeReplenishCell from "./TypeReplenishCell/TypeReplenishCell";
import { toAbsoluteUrl } from "../../../../_metronic";

const noCogTooltip ="Please complete your Cost of Goods information to enable the calculation to be completed";

const WithTooltip = ({ isWrap, children }) => isWrap
  ?  <TooltipComponent tooltip={noCogTooltip}>{children}</TooltipComponent>
  : children;

const Content = ({ value, isEdit, row, state, isShowTooltip, isImportProduct, isTooltip, valueIcon }) => {
  // color of the active/inactive button in the "Add SKU" cell
  const colorAdd = state.isOpen ? "secondary" : "primary";

  let ContentItem = null;
  if (value) {
    if (typeof value === "string") {
      ContentItem = (
        <WithTooltip isWrap={value === "-" && isTooltip}>
          <Typography className="text-btn" {...bindTrigger(state)}>
            <span>
              {value}
              {isEdit && !valueIcon && <img alt="img" className="cog-cell__icon hover edit" src={toAbsoluteUrl("/media/d-icons/pen.svg")}/>}
            </span>
            {isEdit && valueIcon}
          </Typography>
        </WithTooltip>
      )
    } else {
      ContentItem = (
        <WithTooltip isWrap={isShowTooltip}>
          <div className="text-btn" {...bindTrigger(state)}>{value}</div>
        </WithTooltip>
      )
    }
  } else if (valueIcon) {
    ContentItem = <div className="text-btn" {...bindTrigger(state)}>{valueIcon}</div>;
  } else if (!row.restock && !row.relatedProduct) {
    ContentItem = isImportProduct
      ? (
        <IconButton
          className="products__add-button"
          aria-controls="add-button"
          color={colorAdd}
          {...bindTrigger(state)}
        >
          <EditIcon fontSize="large"/>
        </IconButton>
      )
      : (
        <IconButton
          className="products__add-button"
          aria-controls="add-button"
          color={colorAdd}
          {...bindTrigger(state)}
        >
          {/*<AddIcon fontSize="large"/>*/}
          <img
          alt="img"
          className="table__cell__img--icon"
          src={toAbsoluteUrl("/media/d-icons/add.svg")}
          // onClick={handleEditClick}
          />
        </IconButton>
        // <img
        //   alt="img"
        //   className="table__cell__img--icon"
        //   src={toAbsoluteUrl("/media/d-icons/add.svg")}
        //   onClick={handleEditClick}
        // />
      )
  }
  else if(row.inbound_shipment && !row.merged) {
    ContentItem = (
      <TypeReplenishCell row={row} />
    )
  }
  return ContentItem;
};

Content.propTypes = {
  row: PropTypes.object,
  state: PropTypes.object,
  isEdit: PropTypes.bool,
  isImportProduct: PropTypes.bool,
  isShowTooltip: PropTypes.bool,
  value: PropTypes.oneOfType([ PropTypes.string,  PropTypes.element]),
};

const DetailsCreateDelete = (props) => {
  
  const {
    row,
    isEdit,
    isImportProduct,
    value,
    isShowTooltip,
    isTooltip,
    valueIcon,
    isShowPopup,
    customAnchor,
  } = props;



  return (
    <PopupState variant="popper" popupId={`${row.id}`}>
      {popupState => {
        let editing = false;
        let anchor = null;

        const state = {...popupState};
        const { anchorEl, isOpen } = state;

        if (!anchorEl && isOpen) {
          editing = false;
        }
        if (anchorEl) {
          const classList = Object.values(anchorEl.classList);
          if (classList.some(item => item === "products__add-button")) {
            // anchor = anchorEl = cell "Add SKU"
            anchor = anchorEl;
          } else {
            const parent = anchorEl.closest(".MuiTableRow-root");
            // anchor = cell "COG"
            anchor = parent.querySelector(".table__cell--cog").querySelector(".action-buttons-table");
            editing = true;
          }
        }

        if (customAnchor && !isShowPopup) {
          state.isOpen = false;
        } else if (isShowPopup) {
          state.isOpen = true;
          anchor = customAnchor;
        }

        state.anchorEl = anchor;
        
        const contentProps = { isEdit, row, value, state, isShowTooltip, isImportProduct, isTooltip, valueIcon };
      
        return (
          <div className="products__details__wrap">
            <Content {...contentProps} />
            <Popover
              {...bindPopover(state)}
              anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              className="products__details__popover"
            >
              <Paper square={true} className="products__details__paper">
                <DetailsForm product={row} editing={editing} {...props} />
              </Paper>
            </Popover>
          </div>
        )
      }}
    </PopupState>
  );
};

DetailsCreateDelete.propTypes = {
  row: PropTypes.object,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  isEdit: PropTypes.bool,
  isImportProduct: PropTypes.bool,
  currency: PropTypes.string,
  isShowTooltip: PropTypes.bool,
};

export default DetailsCreateDelete;