import React from "react";
import LoginForm from "./components/LoginForm";
import * as auth from "../../store/ducks/auth.duck";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";

function Login() {
  return (
    <>
      <div className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-login__title">
            <Typography className="onboarding__title">Sign in to start using SKUify</Typography>
          </div>
          <LoginForm redirect={'/dashboard'} />
        </div>
      </div>
    </>
  );
}

export default connect(
    null,
    auth.actions
  )(Login);
