import api from '../../crud/settings.crud'
import {
  call,
  put,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects'
import { createSlice } from '@reduxjs/toolkit'
import {shortBaseState, shortBaseStateObject} from "./utils";

const common = createSlice({
  name: 'setting',
  initialState: {
    loading: true,
    fetched: false,
    settings: shortBaseState,
    vatSettings: shortBaseState,
    roiSettings: shortBaseStateObject,
    accountSettings: shortBaseStateObject,
    amazonSettings: shortBaseState,

    marketplaces: shortBaseState,
    shippingPlans: shortBaseStateObject,
    optionsShippingPlans: shortBaseState,
    updateUserPassword: {
      // details: [],
      loading: false,
    },
    setting: null,
    walk: false,
    firstWalk: 'idle'
  },
  reducers: {
    stopLoading: (state, actions) => {
      const {type} = actions.payload;
      if (type) {
        state[type].loading = false;
      } else {
        state.loading = false;
      }
      return state;
    },
    getSettings: (state, actions) => {
      state.settings.loading = true
      return state
    },
    getSettingsSuccess: (state, actions) => {
      const { settings } = actions.payload
      state.settings.loading = false
      state.fetched = true
      state.settings.details = settings
      return state
    },
    addSetting: (state, actions) => {
      state.settings.loading = true
      return state
    },
    addSettingSuccess: (state, actions) => {
      const { setting } = actions.payload
      state.settings.details = state.settings.details.map(item => {
        if (item.id === setting.cog_setting) {
          return { ...item, items: [...item.items, setting]}
        }
        return item
      })
      state.settings.loading = false
      return state
    },
    updateSetting: (state, actions) => {
      state.settings.loading = true
      return state
    },
    updateSettingSuccess: (state, actions) => {
      const { settingItems, type } = actions.payload
      state.settings.details = state.settings.details.map((item) => {
        if (type === item.settings_type) {
          return {...item, items: settingItems}
        }
        return item
      })
      state.settings.loading = false
      return state
    },
    getVATSettings: (state, actions) => {
      state.vatSettings.loading = true
      return state
    },
    getVATSettingsSuccess: (state, actions) => {
      const { vatSettings } = actions.payload
      state.vatSettings.loading = false
      state.vatSettings.details = vatSettings
      state.fetched = true
      return state
    },
    addVATSetting: (state, actions) => {
      state.vatSettings.loading = true
      return state
    },
    addVATSettingSuccess: (state, actions) => {
      const { values } = actions.payload
      state.vatSettings.details = {
        ...state.vatSettings.details,
        vat_schemes: [...state.vatSettings.details.vat_schemes, ...values.vat_schemes]
      }
      state.vatSettings.loading = false
      return state
    },
    updateVATSetting: (state, actions) => {
      state.vatSettings.loading = true
      return state
    },
    updateVATSettingSuccess: (state, actions) => {
      state.vatSettings.details = actions.payload.vatSetting
      state.vatSettings.loading = false
      return state
    },
    getROISettings: (state) => {
      state.roiSettings.loading = true
      return state
    },
    getROISettingsSuccess: (state, actions) => {
      state.roiSettings.details = actions.payload
      state.roiSettings.loading = false
      return state
    },
    updateROISettings: (state) => {
      state.roiSettings.loading = true;
      return state
    },
    updateROISettingsSuccess: (state, actions) => {
      state.roiSettings.loading = false;
      return state
    },
    getAccountSettings: (state, actions) => {
      state.accountSettings.loading = true
      return state
    },
    getAccountSettingsSuccess: (state, actions) => {
      const { accountSettings } = actions.payload
      state.accountSettings.loading = false
      state.accountSettings.details = accountSettings || {}
      state.amazonSettings.details = accountSettings?.amazon_settings || []
      state.fetched = true
      return state
    },
    updateAccountSetting: (state, actions) => {
      state.accountSettings.loading = true
      return state
    },
    updateAccountSettingSuccess: (state, actions) => {
      state.accountSettings.details = actions.payload.accountSetting
      state.accountSettings.loading = false
      return state
    },
    updateAmazonSetting: (state, actions) => {
      state.amazonSettings.loading = true
      return state
    },
    updateAmazonSettingSuccess: (state, actions) => {
      const { amazonSetting, id } = actions.payload
      state.amazonSettings.details = state.amazonSettings.details.map(setting => {
        if (setting.id === id) {
          return amazonSetting
        } else {
          return setting
        }
      })
      state.amazonSettings.loading = false
      return state
    },
    deleteAmazonSetting: (state, actions) => {
      state.amazonSettings.loading = true
      return state
    },
    deleteAmazonSettingSuccess: (state, actions) => {
      state.amazonSettings.details = []
      state.amazonSettings.loading = false
      return state
    },
    getMarketplaces: (state, actions) => {
      state.marketplaces.loading = true
      return state
    },
    getMarketplacesSuccess: (state, actions) => {
      const { marketplaces } = actions.payload
      state.marketplaces.loading = false
      state.marketplaces.details = marketplaces
      state.fetched = true
      return state
    },

    autoAddCog: (state, actions) => {
      return state
    },
    autoAddCogSuccess: (state, actions) => {
      return state
    },

    getShippingPlans: (state, actions) => {
      state.shippingPlans.loading = true
      return state
    },
    getShippingPlansSuccess: (state, actions) => {
      const { shippingPlans } = actions.payload
      state.shippingPlans.loading = false
      state.fetched = true
      state.shippingPlans.details = shippingPlans
      return state
    },

    updateShippingPlans: (state, actions) => {
      state.shippingPlans.loading = true
      return state
    },
    updateShippingPlansSuccess: (state, actions) => {
      const { settingItems } = actions.payload
      state.shippingPlans.details = settingItems
      state.shippingPlans.loading = false
      return state
    },
    getOptionsShippingPlans: (state, actions) => {
      state.optionsShippingPlans.loading = true
      return state
    },
    getOptionsShippingPlansSuccess: (state, actions) => {
      const { options } = actions.payload
      state.optionsShippingPlans.details = options
      state.optionsShippingPlans.loading = false
      return state
    },

    updatePassword: (state, actions) => {
      state.updateUserPassword.loading = true;
      return state
    },
    updatePasswordSuccess: (state, actions) => {
      state.updateUserPassword.loading = false;
      return state;
    },

    onWalk: (state, actions) => {
      // localStorage.setItem("walk", "run");
      // state.walk = true;
      return state;
    },
    firstOnWalk: (state, actions) => {
      let isFirst = null
      try {
        isFirst = localStorage.getItem("firstWalk");
        state.firstWalk = isFirst !== "passed" ? 'start' : "passed"
        return state
      } catch {
        state.firstWalk = 'start'
        return state;
      }
    },
    firstOnWalkSuccess: (state, actions) => {
        localStorage.setItem("firstWalk", "passed");
        return state;
    },
    onWalkSuccess: (state, actions) => {
      localStorage.setItem("walk", "run");
      state.walk = true;
      return state;
    },
  }
});

export const actions = common.actions
export const reducer = common.reducer

export function* saga() {
  yield takeLatest(actions.getSettings, function* getSettingsSaga() {
    try {
      const { data } = yield call(api.getSettings)
      data
        ? yield put(actions.getSettingsSuccess({ settings: data }))
        : yield put(actions.stopLoading({type: "settings"}))
    } catch (err) {
      yield put(actions.stopLoading({type: "settings"}))
      console.log(err)
    }
  })

  yield takeLatest(actions.addSetting, function* addSettingSaga({ payload: { values, onDone, onError } }) {
    try {
      const { data, ...props } = yield call(api.addSetting, values);
      if (data) {
        yield put(actions.addSettingSuccess({ setting: data }));
        onDone()
      } else {
        yield put(actions.stopLoading({type: "settings"}));
        onError(props)
      }
    } catch (err) {
      yield put(actions.stopLoading({type: "settings"}));
      onError(err);
      console.error(err)
    }
  })

  yield takeEvery(actions.updateSetting, function* updateSettingSaga({ payload: { type, values, onDone, onError } }) {
    try {
      const { data, ...props } = yield call(api.updateSetting, values);
      if (data) {
        yield put(actions.updateSettingSuccess({ settingItems: data, type }))
        onDone()
      } else {
        yield put(actions.stopLoading({type: "settings"}));
        onError(props)
      }
    } catch (err) {
      yield put(actions.stopLoading({type: "settings"}));
      onError(err);
      console.log(err)
    }
  })

  yield takeLatest(actions.getVATSettings, function* getVATSettingsSaga() {
    try {
      const { data } = yield call(api.getVATSettings)
      data
      ? yield put(actions.getVATSettingsSuccess({ vatSettings: data }))
      : yield put(actions.stopLoading({type: "vatSettings"}));
    } catch (err) {
      yield put(actions.stopLoading({type: "vatSettings"}))
      console.log(err)
    }
  })

  yield takeLatest(actions.addVATSetting, function* addVATSettingSaga({ payload: { values, onDone, onError } }) {
    try {
      const { data, ...props } = yield call(api.addVATSetting, values)
      if (data) {
        yield put(actions.addVATSettingSuccess({ vatSetting: data, values }))
        onDone();
      } else {
        yield put(actions.stopLoading({type: "vatSettings"}))
        onError(props)
      }
    } catch (err) {
      yield put(actions.stopLoading({type: "vatSettings"}))
      onError(err)
      console.error(err)
    }
  })

  yield takeLatest(actions.updateVATSetting, function* updateVATSettingSaga({ payload: { values, onDone, onError } }) {
    try {
      const { data, ...props } = yield call(api.updateVATSetting, values)
      if (data) {
        yield put(actions.updateVATSettingSuccess({ vatSetting: data }))
        onDone()
      } else {
        yield put(actions.stopLoading({type: "vatSettings"}))
        onError(props)
      }
    } catch (err) {
      yield put(actions.stopLoading({type: "vatSettings"}))
      onError(err)
      console.log(err)
    }
  })

  yield takeLatest(actions.getROISettings, function* getROISettingsSaga() {
    try {
      const { data } = yield call(api.getROISettings)
      data
        ? yield put(actions.getROISettingsSuccess(data))
        : yield put(actions.stopLoading({type: "roiSettings"}))
    } catch (err) {
      yield put(actions.stopLoading({type: "roiSettings"}));
      console.log(err)
    }
  })

  yield takeLatest(actions.updateROISettings, function* updateROISettingsSaga({payload: {method, onDone}}) {
    try {
      const { data } = yield call(api.updateROISettings, method)
      if (data) {
        yield put(actions.updateROISettingsSuccess(data))
        onDone()
      } else {
        yield put(actions.stopLoading({type: "roiSettings"}));
      }
    } catch (err) {
      yield put(actions.stopLoading({type: "roiSettings"}));
      console.log(err)
    }
  })

  yield takeLatest(actions.getAccountSettings, function* getAccountSettingsSaga({ payload: { id } }) {
    try {
      const { data } = yield call(api.getAccountSettings, id)
      data
        ? yield put(actions.getAccountSettingsSuccess({ accountSettings: data }))
        : yield put(actions.stopLoading({type: "accountSettings"}));
    } catch (err) {
      yield put(actions.stopLoading({type: "accountSettings"}));
      console.log(err)
    }
  })

  yield takeLatest(actions.updateAccountSetting,
    function* updateAccountSettingSaga({ payload: { id, values, onError, onDone } }) {
    try {
      const { data, ...props } = yield call(api.updateAccountSetting, id, values)
      if (data) {
        yield put(actions.updateAccountSettingSuccess({ accountSetting: data }))
        onDone && onDone();
      } else {
        yield put(actions.stopLoading({type: "accountSettings"}));
        onError && onError(props);
      }
    } catch (err) {
      yield put(actions.stopLoading({type: "accountSettings"}));
      onError && onError(err);
      console.log(err)
    }
  })

  yield takeLatest(actions.updateAmazonSetting, function* updateAmazonSettingSaga({ payload: {id, values, onDone, onError } }) {
    try {
      const { data, ...props } = yield call(api.updateAmazonSetting, id, values)
      if (data) {
        yield put(actions.updateAmazonSettingSuccess({ amazonSetting: data, id }))
        onDone();
      } else {
        yield put(actions.stopLoading({type: "amazonSettings"}));
        onError(props)
      }
    } catch (err) {
      yield put(actions.stopLoading({type: "amazonSettings"}));
      onError(err)
      console.log(err)
    }
  })

  yield takeLatest(actions.deleteAmazonSetting, function* deleteAmazonSettingSaga({ payload: {id, values, onDone, onError } }) {
    try {
      const { ...props } = yield call(api.deleteAmazonSetting, id, values)
      if (props.status === 204) {
        yield put(actions.deleteAmazonSettingSuccess())
        onDone();
      } else {
        yield put(actions.stopLoading({type: "amazonSettings"}));
        onError(props)
      }
    } catch (err) {
      yield put(actions.stopLoading({type: "amazonSettings"}));
      onError(err)
      console.log(err)
    }
  })

  yield takeLatest(actions.getMarketplaces, function* getMarketplacesSaga() {
    try {
      const { data } = yield call(api.getMarketplaces)
      data
        ? yield put(actions.getMarketplacesSuccess({ marketplaces: data }))
        : yield put(actions.stopLoading({type: "marketplaces"}))
    } catch (err) {
      yield put(actions.stopLoading({type: "marketplaces"}));
      console.log(err)
    }
  })

  yield takeLatest(actions.autoAddCog, function* autoAddCogSaga({ payload: { onDone } }) {
    try {
      const { data } = yield call(api.autoAddCog)
      yield put(actions.autoAddCogSuccess())
      onDone()
    } catch (err) {
      console.log(err)
    }
  })

  yield takeLatest(actions.getShippingPlans, function* getShippingPlansSaga({payload = {}}) {
    const {onError} = payload;
    try {
      const res = yield call(api.getShippingPlans)
      if (res?.data) {
        yield put(actions.getShippingPlansSuccess({ shippingPlans: res.data }))
      } else {
        yield put(actions.stopLoading({type: "shippingPlans"}));
        onError("Shipping Plans error. Something went wrong.")
      }
    } catch (err) {
      yield put(actions.stopLoading({type: "shippingPlans"}));
      onError(err)
      console.error(err)
    }
  })

  yield takeLatest(actions.updateShippingPlans, function* updateShippingPlansSaga({ payload: { values, onDone, onError } }) {
    try {
      const { data } = yield call(api.updateShippingPlans, values)
      yield put(actions.updateShippingPlansSuccess({ settingItems: values }))
      onDone()
    } catch (err) {
      yield put(actions.stopLoading({type: "shippingPlans"}));
      onError(err)
      console.error(err)
    }
  });
  yield takeLatest(actions.getOptionsShippingPlans, function* getOptionsShippingPlansSaga({payload}) {
    try {
      const { data } = yield call(api.getOptionsShippingPlans, payload)
      data
        ? yield put(actions.getOptionsShippingPlansSuccess({ options: data }))
        : yield put(actions.stopLoading({type: "optionsShippingPlans"}));
    } catch (err) {
      yield put(actions.stopLoading({type: "optionsShippingPlans"}));
      console.error(err);
    }
  })

  yield takeLatest(actions.updatePassword, function* updatePasswordSaga({ payload: { values, onDone, onError } }) {
    try {
      const {data, ...props} = yield call(api.updatePassword, values);
      if (data) {
        yield put(actions.updatePasswordSuccess());
        onDone()
      } else {
        yield put(actions.stopLoading({type: "updateUserPassword"}));
        onError(props.errorText);
      }
    } catch (err) {
      yield put(actions.stopLoading({type: "updateUserPassword"}));
      onError(err);
    }
  })

  yield takeLatest(actions.onWalk, function* onWalkSaga({ payload }) {
    try {
      yield put(actions.onWalkSuccess());
    } catch (err) {
      console.error(err)
    }
  })

  yield takeLatest(actions.firstOnWalk, function* firstOnWalkSaga({ payload }) {
    try {
      yield put(actions.firstOnWalkSuccess());
    } catch (err) {
      console.error(err)
    }
  })
}
