import React from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles} from "@material-ui/core";
import * as PropTypes from "prop-types";
import classNames from "clsx";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  paper: {
    width: '100%',
    // marginBottom: theme.spacing(2),
    // paddingTop: '10px',
    // paddingBottom: '10px',
  },
}));

const Wrapper = ({ children, className, classWrapper, elevation}) => {
  const classes = useStyles();
  const stylePaper = className && `${className}__paper`;
  return (
    <div className={classNames(className, classWrapper, classes.root)}>
      <Paper className={classNames(classes.paper, stylePaper)} elevation={elevation}>
        {children}
      </Paper>
    </div>
  );
};

Wrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  className: PropTypes.string,
  classWrapper: PropTypes.string,
};

export default Wrapper;
