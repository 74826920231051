import React from "react";
import ReactDOM from "react-dom";
import Block from "./Block";
import * as PropTypes from "prop-types";
const { createPortal } = ReactDOM;

export const LegendContainer = ({ data, colorDash }) => {
  return <div className="chart-legend--container">
    {data.map((block, index) => <Block key={index} block={block} colorDash={colorDash} />)}
  </div>
};

LegendContainer.propTypes = {
  data: PropTypes.array,
};


export const ChartLegendPortal = ({ children }) => {
  return createPortal(
    <div id="chart-legend">
      {children}
    </div>, document.body)
};