import {useEffect, useState} from "react";

const useTableParams = (fn, customParams) => {
  const [params, setParams] = useState({
    limit: 15,
    offset: 0,
    ordering: "",
    q: "",
    stock_or_inactive: "",
    only_warning: "",
    no_inventory_cost: "",
    ...customParams,
  });
  const _setPrams = (key, value) => {
    // if we pass key and value on start, call in that time, if pass only key, create a closure
    // eg you can pass setParams('q') as props or you can call setParams('q', 'some query')
    // or setParams({ q: 'some query' })
    if (typeof key === "object") {
      let _params = {...params};
      Object.entries(key).forEach(item => {
         _params = {..._params, [item[0]]: item[1]};
        return setParams({..._params})
      });
      return null;
    }
    if (value || value === 0) {
      setParams({...params, [key]: value});
      return null;
    }
    return (value) => {
      setParams({...params, [key]: value});
    }
  };

  useEffect(() => {
    fn(params);
  }, Object.values(params));

  return {
    params,
    setParams: _setPrams,
  }
};


export default useTableParams;