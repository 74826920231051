import React from "react";
import {FormControl, MenuItem, Select} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const MSelectSingle = allProps => {
  const {
    name,
    setFieldValue,
    setFieldTouched,
    placeholder,
    label,
    meta,
    showLabel,
    customProps = {},
    submitCount,
    ...props
  } = allProps;
  const {options, renderOptionsCustom, renderValueCustom} = customProps;

  const renderOptions = () => options.map(option => (
    <MenuItem key={option.value} value={option.value}>
      <Typography className="multiple-select__item">
        {option.label}
      </Typography>
    </MenuItem>
  ));

  const renderValue = (value) => <span>{value}</span>;
  const longestOption = options.sort((a, b) => (b.label?.length || 0) - (a.label?.length || 0))[0];
  const width = longestOption ? longestOption.label.length * 10 : null;
  return <>
    <FormControl className="form-control--multiple-select" style={{width}}>
      <Select
        autoWidth
        id={name}
        displayEmpty
        disabled={props.disabled}
        className="multiple-select"
        value={props.value || ''}
        options={options}
        onChange={(event) => setFieldValue(name, event.target.value)}
        renderValue={renderValueCustom ? renderValueCustom : renderValue}
      >
        {
          renderOptionsCustom ? renderOptionsCustom() : renderOptions()
        }
      </Select>
    </FormControl>
  </>
};

export default MSelectSingle;