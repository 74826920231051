import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import TableComponent from "../../../components/TableComponent/TableComponent";
import EnterTransactionModal from "./EnterTransactionModal";
import { connect } from "react-redux";
import * as bank from "../../../../store/ducks/bunk.duck";
import * as commonDuck from "../../../../store/ducks/common.duck";
import * as Selector from "../../../../selectors/bank.selectors";
import { useHistory } from "react-router-dom";
import { goToImportTransactionPage, goToNewTransactionPage } from "../utils";
import { useModal } from "../../../../hooks/modal.hook";
import withConfirmHook from "../../../../hooks/withConfirm.hook";
import ReceiptUploadsModal from "../../../components/ReceiptUploadsModal/ReceiptUploadsModal";
import EditAddBankingModal from "./EditAddBankingModal";
import DButton from "../../../components/Button";
import TransactionModal, { valuesTitle } from "../TransactionModal/TransactionModal";
import EnterMethodModal from "./EnterMethodModal";
import { entryMethods } from "../bankOptions";
import useBankFilesCrud from "../../../../hooks/bankFiles.hook";
import { useNotification } from "../../../../hooks/notification.hook";
import { headRowsModal } from "./headRowsModal";
import useCompany from "../../../../hooks/company.hook";
import DSplashScreen from "../../../components/SplashScreen/DSplashScreen";
import useCategories from "../../../../hooks/categories.hook";

function EnterTransactionsBanking(props) {
  const {
    confirm,
    updateBank,
    addBank,
    getCurrencies,
    getBanks,
    banks,
    loading,
    deleteBank,
    tabProps,
  } = props;
  const  { headRows, rowsPerPage, reload, searchQuery } = tabProps;
  const [showEnterTransactionModal, toggleEnterTransactionModal] = useModal();
  const [isEditModalShow, toggleEditModal] = useModal();
  const [isTransactionModalShow, toggleTransactionModal] = useModal();
  const [isEnterMethodModalShow, toggleEnterMethodModal] = useModal();
  const [isReceiptUploadsModalShow, toggleReceiptUploadsModal] = useModal();
  const [currentRow, setCurrentRow] = useState(null);
  const [successNotification, setIsShowNotification] = useNotification(false, "success", "Updated");
  const { company } = useCompany();
  const { categories, getCategories } = useCategories();

  const { bankFiles, updateBankFile, addBankFile } = useBankFilesCrud();

  useEffect(() => {
    getBanks({ q: searchQuery });
    getCurrencies();
    // getCategories();
  }, [reload, searchQuery]);

  const reset = () => {
    setCurrentRow(null);
  };

  const toggleShowNewBankModal = () => {
    getCategories();
    toggleEditModal();
    reset();
  };

  const onSuccess = () => {
    toggleShowNewBankModal();
    setIsShowNotification(true);
  };

  let history = useHistory();

  const onEdit = (row) => () => {
    setCurrentRow(row);
    toggleEditModal();
  };

  const goToPage = (row) => () => {
    if (row.entry_method === entryMethods.FILE_IMPORT) {
      goToImportTransactionPage(history, row.id)
    } else {
      goToNewTransactionPage(history, row.id);
    }
  };

  const onNameClick = (row) => () => {
    toggleTransactionModal();
    setCurrentRow(row);
  };

  const onMethodClick = (row) => () => {
    toggleEnterMethodModal();
    setCurrentRow(row);
  };

  const onCloseMethod = () => {
    toggleEnterMethodModal();
    reset();
  };

  const onDelete = (row) => {
    return confirm(() => {
        deleteBank({id: row.id,})
      },
      {description: 'This action is permanent!'})
  };

  const onAttachments = (row) => () => {
    setCurrentRow(row);
    toggleReceiptUploadsModal();
  };

  const onBankFeed = (row) => () => {
    goToImportTransactionPage(history, row.id);
  };

  return (
    <div>
      <div className="d-splash-screen--wrap">
        {loading && <DSplashScreen />}
        <TableComponent
          headRows={headRows}
          items={banks}
          rowsPerPageSelected={rowsPerPage}
          loading={loading}
          rowProps={{
            goToPage,
            onDoneAll: onBankFeed,
            onEdit,
            onAttachments: (company && company.uses_receipt_upload) && onAttachments,
            onDelete,
            onNameClick,
            onMethodClick,
          }}
        />
      </div>
      <div className="container-button">
        <DButton typeOfButton="add" onClickCustom={toggleShowNewBankModal}>Add new Bank Account</DButton>
        <DButton typeOfButton="exit" onClickCustom={toggleEnterTransactionModal}>Enter Transaction</DButton>
      </div>
      <EnterTransactionModal banks={banks} show={showEnterTransactionModal} onClose={toggleEnterTransactionModal}/>
      <EditAddBankingModal
        reset={reset}
        categories={categories.details}
        updateBank={updateBank}
        addBank={addBank}
        show={isEditModalShow}
        onClose={toggleShowNewBankModal}
        initValues={currentRow}
        onSuccess={onSuccess}
      />

      {currentRow && (
        <ReceiptUploadsModal
          addBankFile={addBankFile}
          bankFiles={bankFiles}
          bankId={currentRow.id}
          show={isReceiptUploadsModalShow}
          onClose={toggleReceiptUploadsModal}
          updateBankFile={updateBankFile}
        />
      )}

      {currentRow &&
      <TransactionModal
        data={currentRow}
        show={isTransactionModalShow}
        onClose={toggleTransactionModal}
        valueTab={valuesTitle.BANKING}
        headRowsModal={headRowsModal}
        reset={reset}/>}

      {currentRow &&
      <EnterMethodModal
        bank={currentRow}
        show={isEnterMethodModalShow}
        onClose={onCloseMethod}
        initValues={currentRow}
        updateBank={updateBank}
        reset={reset} />}

      {successNotification}
    </div>
  )
}

EnterTransactionsBanking.propTypes = {
  getBanks: PropTypes.func.isRequired,
  updateBank: PropTypes.func.isRequired,
  addBank: PropTypes.func.isRequired,
  getCurrencies: PropTypes.func.isRequired,
  deleteBank: PropTypes.func.isRequired,
  banks: PropTypes.array.isRequired,
  headRows: PropTypes.array,
  confirm: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number,
  reload: PropTypes.bool,
  loading: PropTypes.bool,
  searchQuery: PropTypes.string,
};

const mapStateToProps = (state, props) => {
  return {
    banks: Selector.getBanks(state, props),
    loading: Selector.getLoading(state, props),
  }
};

const mapDispatchToProps = {
  getBanks: bank.actions.getBanks,
  addBank: bank.actions.addBank,
  updateBank: bank.actions.updateBank,
  deleteBank: bank.actions.deleteBank,
  getCurrencies: commonDuck.actions.getCurrencies,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withConfirmHook(EnterTransactionsBanking))
