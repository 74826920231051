import React from "react";
import { Typography, Button } from "@material-ui/core";
import { useModal } from "../../../hooks/modal.hook";
import useProducts from "../../../hooks/products.hook";
import ImportCOG from "../Products/ImportCOG";
import {useNotification} from "../../../hooks/notification.hook";
import {importCOGWarningText} from "../Products/utils";

const Migration = () => {
  const [isModalShow, toggleModal] = useModal();
  const { addImportsCOG, noteImportsCOG } = useProducts();
  const handleSTK = () => toggleModal();
  const handleSB = () => toggleModal();

  const [successImport, setIsShowSuccessImport] = useNotification(
    false,
    "success",
    "SKUify is currently importing your COGs from an external file - please check back later",
    { vertical: "bottom", horizontal: "right" },
    5000,
  );

  const [errorImport, setIsShowError] = useNotification(
    false,
    "error",
    noteImportsCOG && noteImportsCOG.errors && importCOGWarningText({ noteImportsCOG: noteImportsCOG.errors }),
    { vertical: "bottom", horizontal: "right" },
    null,
    { mouseEvent: false }
  );

  const [warning, setIsShowWarning] = useNotification(
    false,
    "warning",
    noteImportsCOG && noteImportsCOG.warnings && importCOGWarningText({ noteImportsCOG: noteImportsCOG.warnings }),
    { vertical: "bottom", horizontal: "right" },
    null,
    { mouseEvent: false }
  );

  const showNotifications = (notifications) => {
    if (!!notifications && notifications.errors) {
      setIsShowError(true);
    } else {
      setIsShowSuccessImport(true);
    }
    if (!!notifications && notifications.warnings) {
      setIsShowWarning(true);
    }
  };

  return (
    <div className="settings-main__migration settings-main__default">
      {successImport}
      {noteImportsCOG?.warnings && warning}
      {noteImportsCOG?.errors && errorImport}
      <Typography variant="h5" color="primary" className="settings__title">
        Migration
      </Typography>
      <div className="settings-main__migration content--migration">
        <Typography variant="h5" color="primary" className="settings-main__migration title title--first">
          Select Software
        </Typography>

        <Typography variant="h5" color="primary" className="settings-main__migration title">
          Click the link below to choose the software you are currently using <br/>
          SKUify makes the importing of your cost of goods attached to each ASIN really easy!
        </Typography>
        <div className="settings-main__migration buttons">
          <Button className="stk" variant="outlined" size="large" onClick={handleSTK}>STK</Button>
          <Button className="sb" variant="outlined" size="large" onClick={handleSB}>SB</Button>
        </div>
        {/*<Typography variant="h5" color="primary" className="settings-main__migration title">*/}
          {/*SKUify makes the importing of your cost of goods attached to each ASIN really easy!*/}
        {/*</Typography>*/}
      </div>
      <ImportCOG
        show={isModalShow}
        onClose={toggleModal}
        addImportsCOG={addImportsCOG}
        format="CSV/XLSX/TSV/TXT"
        acceptedFiles={['.csv', '.tsv', '.xls', '.xlsx', '.txt']}
        showNotifications={showNotifications}
      />
    </div>
  )
};

export default Migration;