import { createSelector } from "reselect";

export const getAsinHistories = createSelector(
    state => state.asin,
    (product) => product.asinHistories,
);

export const getAsinProfitReport = createSelector(
    state => state.asin,
    (product) => product.asinProfitReport,
);