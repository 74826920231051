import React from "react";
import "./books-styles.scss"
import EmptyBlock from "../../components/EmptyBlock";
import Wrapper from "../../components/Wrapper";

const Books = () => {
  return (
    <Wrapper className="books-page">
      <EmptyBlock>In Development</EmptyBlock>
    </Wrapper>
  )
};

export default Books;