import React from 'react';
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import * as PropTypes from "prop-types";
import {Form as FormikForm, Formik} from "formik";
import BankApi from "../../../../crud/bank.crud";
import FormField from "../../../components/controls/FormField";
import {requiredValidator} from "../../../components/controls/validators";
import UploadFileScreen from "../../../components/UploadFileScreen";
import DButton from "../../../components/Button";

const initialData = {
  recurringTransactions: [
    {}
  ],
};

export default function ReceiptUpModal({show, onClose, addBankFile}) {

  const formFields = [
    {
      id: 'recurring_trans_type',
      label: 'Select Recurring Transaction Type',
      type: 'string',
      validators: ["required"]
    },
  ];

  return (
    <Formik
      enableReinitialize
      initialValues={initialData}
      onSubmit={(values, {setSubmitting, resetForm}) => {
        const data = {...values};
        const onDone = () => {
          setSubmitting(false);
          resetForm();
          onClose();
        };

        addBankFile({
          values: data,
          onDone
        })
      }}
    >
      {(form) => (
        <Modal show={show} onHide={onClose} className="modal--small">
          <FormikForm>
            <Modal.Header closeButton>
              <Modal.Title>New Recurring Transaction</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {
                formFields.map((item) => (
                  <Form.Group key={item.id} controlId={item.id}>
                    <Form.Label>{item.label}</Form.Label>
                    <FormField
                      name={item.id}
                      label={item.label}
                      type={item.type}
                      validate={requiredValidator()}
                      {...form}
                    />
                  </Form.Group>
                ))
              }
              <UploadFileScreen/>
            </Modal.Body>
            <Modal.Footer>
              <div className="container-button container-button--modal">
                <DButton typeOfButton="doneAllSecondary" > Upload </DButton>
                <DButton typeOfButton="close" onClickCustom={onClose} > Close </DButton>
              </div>
            </Modal.Footer>
          </FormikForm>
        </Modal>
      )}
    </Formik>
  )
}

ReceiptUpModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
