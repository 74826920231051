export const BOOKS = "/books";
export const EXPENSES = "/expenses";
export const NEW_BANK_TRANSACTION = "/books/bank_transactions/new";
export const EDIT_TRANSACTION = "/books/transactions/edit";
export const BULK_TRANSACTION_EDITOR = "/books/bank_transactions/bulk-editor";
export const BANK_RULES = "/books/tools/bank_rules";
export const NEW_BANK_RULE = BANK_RULES + "/new";
export const EDIT_BANK_RULE = BANK_RULES + "/edit";
export const AUTO_CONFIRMED_BANK_TRANSACTIONS = BANK_RULES + "/auto_confirmed_bank_transactions";
export const IMPORT_BANK_TRANSACTION = "/imports";
export const CUSTOM_IMPORT = "/custom_file_import_formats";
export const NEW_CUSTOM_IMPORT = "/custom_file_import_formats/new";
export const UPLOAD_EXAMPLE_CUSTOM_IMPORT = "/custom_file_import_formats/columns/new";
export const NEW_FILE_IMPORTS = "/file_imports/new";
export const EDIT_CUSTOM_IMPORT = CUSTOM_IMPORT + "/edit";
export const IMPORTED_BANK_TRANSACTIONS = "/imported_bank_transactions";
export const SETTINGS = "/settings";
export const NOTIFICATIONS = "/notifications";
export const PRODUCTS = "/products";
export const SALES = "/sales";

export const RECURRING_TRANSACTIONS = "/books/tools/recurring-transactions";
export const DETAILS_RECURRING_TRANSACTIONS = RECURRING_TRANSACTIONS + "/details";

const ADJUSTMENT = "/books/journal_entries";
export const NEW_ADJUSTMENT = ADJUSTMENT + "/new";
export const EDIT_ADJUSTMENT = ADJUSTMENT + "/edit";

export const RECURRING_ADJUSTMENT = "/books/journal_entry/as_templates";
export const NEW_RECURRING_ADJUSTMENT = RECURRING_ADJUSTMENT + "/new";
export const EDIT_RECURRING_ADJUSTMENT = RECURRING_ADJUSTMENT + "/edit";

const CUSTOMER_INVOICES = "/books/sales_invoices/batches";
export const NEW_CUSTOMER_INVOICES = CUSTOMER_INVOICES + "/new";
export const EDIT_CUSTOMER_INVOICES = CUSTOMER_INVOICES + "/edit";

const SUPPLIER_INVOICES = "/books/purchase_invoices";
export const NEW_SUPPLIER_INVOICES = SUPPLIER_INVOICES + "/new";
export const EDIT_SUPPLIER_INVOICES = SUPPLIER_INVOICES + "/edit";

export const RECURRING_INVOICES = "/books/invoice/as_templates";
export const NEW_RECURRING_INVOICES = RECURRING_INVOICES + "/new";
export const EDIT_RECURRING_INVOICES = RECURRING_INVOICES + "/edit";

export const REPORTS = "/books/reports";
export const PROFIT_AND_LOSS = REPORTS + "/profit_and_loss";
export const BALANCE_SHEET = REPORTS + "/balance_sheet";
export const TRIAL_BALANCE = REPORTS + "/trial_balance";

export const TAX = "/tax";

export const SUBSCRIPTION_PAGE = "/subscription_page";
export const THANK_YOU_PAGE = "/thank_you_page";

export const INVITE = "/invite";
export const CONTACT = "/contact";
export const ON = "/on";
export const ON_SUBSCRIPTION_PAGE = `${ON}/subscription`;
export const ON_REGISTRATION = `${ON}/registration`;
export const ON_LOGIN = `${ON}/login`;
export const ON_COMPANY = `${ON}/company`;
export const ON_AMAZON = `${ON}/amazon`;
export const ON_AMAZON_FINISH = `${ON}/amazon-finish`;
export const FINISH = "/finish";
export const IMPORT_PPP = "/sync_buy_prices_ppp";
