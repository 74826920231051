import _ from "lodash";

export const colorsLight = {
  sales1: "#3f37c9",
  sales2: "#3cba54",
  profit1: "#366900",
  profit2: "#4885ed",
  units1: "#D30C0C",
  units2: "#ff9a36",
  refunds1: "#f16700",
  refunds2: "#db3236",
  roi1: "#E8A100",
  roi2: "#ae3fe6",
  margin1: "#28adee",
  margin2: "#f4c20d",
  expenses: "#00b999",
  inventoryCost: "#ff206f",
  resaleValue: "#565493"
};

export const colorsDark = {
  sales1: "#C380FF",
  sales2: "#3cba54",
  profit1: "#C1FF80",
  profit2: "#4885ed",
  units1: "#FF7F7F",
  units2: "#ff9a36",
  refunds1: "#f16700",
  refunds2: "#db3236",
  roi1: "#FFB67F",
  roi2: "#ae3fe6",
  margin1: "#80D5FF",
  margin2: "#FFD880",
  expenses: "#00b999",
  inventoryCost: "#ff206f",
  resaleValue: "#565493"
};

export const asinColorsLight = {
  sales1: "#28ADEE",
  // sales2: "#3cba54",
  profit1: "#7B2CC2"
  // profit2: "#4885ed",
};

export const asinColorsDark = {
  sales1: "#80D5FF",
  // sales2: "#3cba54",
  profit1: "#C380FF"
  // profit2: "#4885ed",
};

const catchError = (v, path) => {
  try {
    return _.get(v, path);
  } catch (e) {
    return [];
  }
};

export const getChartOptions = ({ chart, isCompare, colors }) => {
  const { currency, chart_data, type } = chart;
  const withComperative = obj => {
    if (isCompare) {
      return obj;
    }
    return [obj[0]];
  };

  return {
    chart1: {
      name: "Sales",
      charts: withComperative([
        {
          name: "Current",
          id: "sales",
          data: catchError(chart_data, "sales.values"),
          color: colors.sales1,
          label: currency,
          ...(type && { type })
          // extraData: ["alex"],
        },
        {
          name: "Comparative",
          compare: true,
          id: "sales_compare",
          data: catchError(chart_data, "sales.compare"),
          color: colors.sales1,
          label: currency,
          ...(type && { type })
          // extraData: ["alexcomperative"]
        }
      ])
    },
    chart2: {
      name: "Profit",

      charts: withComperative([
        {
          id: "profit",
          name: "Current",
          data: catchError(chart_data, "profit.values"),
          color: colors.profit1,
          label: currency,
          ...(type && { type })
        },
        {
          name: "Comparative",
          compare: true,
          id: "profit_compare",
          data: catchError(chart_data, "profit.compare"),
          color: colors.profit1,
          label: currency,
          ...(type && { type })
        }
      ])
    },
    chart3: {
      name: "Refunds",
      charts: withComperative([
        {
          name: "Current",
          data: catchError(chart_data, "refunds.values"),
          color: colors.refunds1,
          label: currency,
          id: "refunds",
          ...(type && { type })
        },
        {
          name: "Comparative",
          compare: true,
          data: catchError(chart_data, "refunds.compare"),
          color: colors.refunds1,
          label: currency,
          id: "refunds_compare",
          ...(type && { type })
        }
      ])
    },
    chart4: {
      name: "Units Sold",
      charts: withComperative([
        {
          name: "Current",
          // yAxisID: 'y-axis-2',
          // label: "Units Sold",
          data: catchError(chart_data, "units_sold.values"),
          color: colors.units1,
          id: "units_sold",
          ...(type && { type })
          // type: "bar",
        },
        {
          name: "Comparative",
          compare: true,
          // yAxisID: 'y-axis-2',
          data: catchError(chart_data, "units_sold.compare"),
          color: colors.units1,
          id: "units_sold_compare",
          ...(type && { type })
        }
      ])
    },
    chart5: {
      name: "ROI",
      charts: withComperative([
        {
          name: "Current",
          data: catchError(chart_data, "roi.values"),
          color: colors.roi1,
          label: "%",
          id: "roi",
          ...(type && { type })
        },
        {
          name: "Comparative",
          compare: true,
          data: catchError(chart_data, "roi.compare"),
          color: colors.roi1,
          id: "roi_compare",
          label: "%",
          ...(type && { type })
        }
      ])
    },
    chart6: {
      name: "Margin",
      charts: withComperative([
        {
          name: "Current",
          data: catchError(chart_data, "margin.values"),
          color: colors.margin1,
          label: "%",
          id: "margin",
          ...(type && { type })
        },
        {
          name: "Comparative",
          compare: true,
          data: catchError(chart_data, "margin.compare"),
          color: colors.margin1,
          label: "%",
          id: 'margin_compare',
          ...(type && {type}),
        },
      ]),
    },
    chart7: {
      name: "Expenses",
      charts: withComperative([
        {
          name: "Current",
          data: catchError(chart_data, 'expenses.values'),
          color: colors.expenses,
          label: currency,
          id: 'expenses',
          ...(type && {type}),
        },
        {
          name: "Comparative",
          compare: true,
          data: catchError(chart_data, 'expenses.compare'),
          color: colors.expenses,
          label: currency,
          id: 'expenses_compare',
          ...(type && {type}),
        },
      ]),
    },
    chart8: {
      // name: "Inventory Cost",
      name: "Total Inventory Cost",
      charts: withComperative([
        {
          name: "Current",
          data: catchError(chart_data, "inventory_cost.values"),
          color: colors.inventoryCost,
          label: currency,
          id: "inventory_cost",
          ...(type && { type })
        },
        {
          name: "Comparative",
          compare: true,
          data: catchError(chart_data, "inventory_cost.compare"),
          color: colors.inventoryCost,
          label: currency,
          id: "inventory_cost_compare",
          ...(type && { type })
        }
      ])
    }
  }
};

export const dateOptions = [
  {
    label: "Today",
    period: "today",
    value: "0",
    default: true,
    subtitle: "Yesterday",
    tooltip:
      "Click to view data above just for today and use the Compare toggle to compare to yesterday"
  },
  {
    label: "Yesterday",
    period: "yesterday",
    value: "1",
    subtitle: "Two Days Ago",
    tooltip:
      "Click to view data above just for yesterday" +
      " and use the Compare toggle to compare to the day before yesterday"
  },
  {
    label: "7 days",
    period: "week",
    value: "6",
    subtitle: "Last 7 days",
    tooltip:
      "Click to view data above for the previous 7 days and use the Compare toggle to compare to the 7 days before"
  },
  {
    label: "30 days",
    period: "month",
    value: "29",
    subtitle: "Last 30 days",
    tooltip:
      "Click to view data above for the most recent 30 days " +
      "and use the Compare toggle to compare to the 30 days before"
  },
  {
    label: "90 days",
    period: "quarter",
    value: "89",
    subtitle: "Last 90 Days",
    tooltip:
      "Click to view data above for the most recent 90 days " +
      "and use the Compare toggle to compare to the 90 days before"
  },
  {
    label: "365 days",
    period: "year",
    value: "364",
    subtitle: "Last 365 days",
    tooltip:
      "Click to view data above for the most recent 365 days" +
      " and use the Compare toggle to compare to the 365 days before"
  },
  {
    label: "All",
    period: "all",
    value: 365 * 5,
    tooltip: ""
  },
  {
    label: "Custom",
    period: "custom",
    value: "custom",
    tooltip:
      "Click to custom set a specific date range for the data shown above"
  }
];

export const chartContentDash = {
  x: {
    ticks: {
      maxRotation: 0,
      minRotation: 0,
      autoSkip: false,
      callback: function(label, index, labels) {
        if (labels.length) {
          return this.getLabelForValue(label);
        }
        return "";
      }
    }
  },
  y: {
    ticks: {
      maxTicksLimit: 5,
      callback: function(label, index, labels) {
        // if (index === 0 || index === labels.length - 1) {
        //   return this.getLabelForValue(label);
        // }
        // return '';
      }
    }
  }
};

export const chartContentAsin = {
  type: "bar",
  x: {
    ticks: {
      maxRotation: 0,
      minRotation: 0,
      autoSkip: false,
      callback: function(label, index, labels) {
        if (index === 0 || index === labels.length - 1) {
          return this.getLabelForValue(label);
        }
        return "";
      }
    }
  },
  y: {
    ticks: {
      maxTicksLimit: 5,
      autoSkip: true
    },
    grid: {
      borderDash: [6, 6]
    }
  }
};

export const updateChartValues = props => {
  const { lines, chart, xIndex, chartContent } = props;
  const { chart_data } = chartContent;
  const dates = chart_data.dates;
  const compare_dates = chart_data.compare_dates;

  return lines.reduce((res, block) => {
    const lineValues = { ...block };
    const { id } = lineValues;
    const chartData = chart?.data?.datasets?.find(set => set.id === id);
    lineValues.value = chartData.data?.[xIndex] || 0;
    if (chartData?.compare) {
      lineValues.legend_label =
        chartContent?.chart_data?.legend_labels_compared?.[xIndex];
      lineValues.date = compare_dates?.[xIndex];
    } else {
      lineValues.legend_label =
        chartContent?.chart_data?.legend_labels?.[xIndex];
      lineValues.date = dates?.[xIndex];
    }
    return [...res, lineValues];
  }, []);
};
