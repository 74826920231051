import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import OfficersCreateEditModal from "./OfficersCreateEditModal";
import TableComponent from "../../../components/TableComponent/TableComponent";
import * as Duck from "../../../../store/ducks/officersSettings.duck.js";
import {connect} from "react-redux";
import withConfirmHook from "../../../../hooks/withConfirm.hook";
import {useModal} from "../../../../hooks/modal.hook";
import * as Selectors from "../../../../selectors/officers.selectors";
import DButton from "../../../components/Button";
import {useHistory} from "react-router-dom";
import {goToEnterTransactionPage} from "../utils";
import Wrapper from "../../../components/Wrapper";
import NotificationForm from "../../../components/NotificationForm";

function OfficersTable({confirm, loading, updateOfficer, deleteOfficer, getOfficers, officers, addOfficer}) {
  const [currentRow, setCurrentRow] = useState(null);
  const [isModalShow, toggleModal] = useModal();
  const [showNotification, onShowNotification] = useState(false);

  useEffect(() => {
    getOfficers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEdit = (row) => () => {
    setCurrentRow(row);
    toggleModal();
  };

  const onDelete = (row) => (
    confirm(() => {
        deleteOfficer({id: row.id})
      },
      {
        description: 'This action is permanent!'
      })
  );

  const headRows = [
    {id: 'name', numeric: false, disablePadding: true, label: 'Name', isActive: true,},
    {id: 'actions', numeric: false, disablePadding: true, label: 'Actions', align: 'right'},
  ];

  const reset = () => {
    setCurrentRow(null);
  };

  const onClose = () => {
    reset();
    toggleModal();
  };

  const onNotification = () => onShowNotification(true);

  let history = useHistory();
  const onCancel = () => goToEnterTransactionPage(history);

  return (
    <Wrapper classWrapper="offset-sm-3 col-sm-6 officers-book ">
      <TableComponent
        headRows={headRows}
        items={officers}
        rowProps={{
          onDelete,
          onEdit,
        }}
      />
      <div className="container-button">
        <DButton typeOfButton="add" onClickCustom={toggleModal}>Add New Officer</DButton>
        <DButton typeOfButton="exit" onClickCustom={onCancel}>Cancel</DButton>
      </div>

      <NotificationForm open={showNotification} onClose={() => onShowNotification(false)}/>

      <OfficersCreateEditModal
        reset={reset}
        addOfficer={addOfficer}
        onClose={onClose}
        showModal={isModalShow}
        initValues={currentRow}
        updateOfficer={updateOfficer}
        onNotification={onNotification}
      />
    </Wrapper>

  );
}

const OfficerType = PropTypes.shape({
  name: PropTypes.string.isRequired,
});

OfficersTable.propTypes = {
  officers: PropTypes.arrayOf(OfficerType).isRequired,
  getOfficers: PropTypes.func.isRequired,
  addOfficer: PropTypes.func.isRequired,
  deleteOfficer: PropTypes.func.isRequired,
  updateOfficer: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    officers: Selectors.getOfficers(state, props),
    loading: Selectors.getLoading(state, props),
  }
};

const mapDispatchToProps = {
  addOfficer: Duck.actions.addOfficer,
  getOfficers: Duck.actions.getOfficers,
  deleteOfficer: Duck.actions.deleteOfficer,
  updateOfficer: Duck.actions.updateOfficer,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withConfirmHook(OfficersTable))
