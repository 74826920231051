import React from "react";
import IconButton from "@material-ui/core/IconButton";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import * as PropTypes from "prop-types";
// import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import { createTheme } from "@material-ui/core/styles";
import { amber } from "@material-ui/core/colors";

const theme = createTheme({
  palette: {
    primary: amber,
  },
});

export const ActionButtonsNewTransaction = ({addNewRow, deleteTransaction, onSplit, onReceiptUploads}) => (
  <div className="action-buttons-table new-bank">

    {addNewRow
    && <IconButton color="primary" onClick={addNewRow}><AddIcon/></IconButton>}

    {deleteTransaction
    && <ThemeProvider theme={theme}>
      <IconButton color="secondary" onClick={deleteTransaction}><RemoveIcon/></IconButton>
    </ThemeProvider>}

    {onSplit
    && <ThemeProvider theme={theme}>
      <IconButton color="primary" onClick={onSplit}><CallSplitIcon/></IconButton>
    </ThemeProvider>}

    {onReceiptUploads
    && <IconButton color="secondary" onClick={onReceiptUploads}><AttachFileIcon/></IconButton>}
  </div>
);

ActionButtonsNewTransaction.propTypes = {
  addNewRow: PropTypes.func,
  deleteTransaction: PropTypes.func,
  onSplit: PropTypes.func,
  onReceiptUploads: PropTypes.func,
};
