import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import React from "react";
import * as PropTypes from "prop-types";
import {withCurrency} from "../Transaction/utils";

const TotalTable = ({ total }) => total.map(({id, name, data}) => {
  const creditDebit = name === "Total";
  const cellData = creditDebit ? [
    {name: data.credit, currency: data.currency.currency_symbol},
    {name: data.debit, currency: data.currency.currency_symbol}
  ]
  : [
      {name: data.balance, currency: data.currency.currency_symbol}
    ];
  return <TableRow key={id} className="bottom-table">
    <TableCell colSpan={2}>
      <Typography variant="subtitle2" align="right">
        {`${name}:`}
      </Typography>
    </TableCell>
    {cellData.map((item, index) => {
      return <TableCell key={index}>
        <Typography variant="subtitle2">
          {withCurrency(item.name, item.currency)}
        </Typography>
      </TableCell>
    })}
  </TableRow>
});
TotalTable.propTypes = {
  total: PropTypes.array,
};
export default TotalTable;