import api from "../../crud/category.crud";
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects'
import { createSlice } from "@reduxjs/toolkit";

const common = createSlice({
  name: 'category',
  initialState: {
    categories: [],
    loading: true,
    fetched: false,
  },
  reducers: {
    stopLoading: (state, actions) => {
      state.loading = false;
      return state;
    },
    getCategories: (state, actions) => {
      state.loading = true;
      return state;
    },
    getCategoriesSuccess: (state, actions) => {
      const { categories } = actions.payload;
      state.categories = categories;
      state.loading = false;
      state.fetched = true;
      return state;
    },
    addCategory: (state, actions) => {
      state.loading = true;
      return state;
    },
    addCategorySuccess: (state, actions) => {
      const { category } = actions.payload;
      state.categories = [...state.categories, category];
      state.loading = false;
      return state;
    },
    deleteCategory: (state, actions) => {
      state.loading = true;
      return state;
    },
    deleteCategorySuccess: (state, actions) => {
      const {id} = actions.payload;
      state.categories = state.categories.filter((category) => (category.id !== id));
      state.loading = false;
      return state;
    },
    updateCategory: (state, actions) => {
      state.loading = true;
      return state;
    },
    updateCategorySuccess: (state, actions) => {
      state.categories = state.categories.map((category) => {
        if (category.id === actions.payload.id) {
          return actions.payload.category;
        }
        return category
      });
      state.loading = false;
      return state;
    },
  }
});

export const actions = common.actions;
export const reducer = common.reducer;

export function* saga() {
  yield takeLatest(actions.getCategories, function* getCategoriesSaga({ payload }) {
    try {
      let start_data = payload && payload.start_data;
      let end_data = payload && payload.end_data;
      let report_date = payload && payload.report_date;
      let q = payload && payload.q;
      const { data } = yield call(api.getCategories, start_data, end_data, report_date, q);
      if (data) {
        yield put(actions.getCategoriesSuccess({ categories : data }));
      }
      yield put(actions.stopLoading())
    } catch (err) {
      yield put(actions.stopLoading())
      console.log(err);
    }
  });

  yield takeLatest(actions.addCategory, function* addCategorySaga({ payload: { values, onDone, onError } }) {
    try {
      const { data, ...props } = yield call(api.addCategory, values);
      if (data) {
        yield put(actions.addCategorySuccess({ category: data }));
        onDone();
      } else {
        yield put(actions.stopLoading());
        onError(props);
      }
    } catch (err) {
      yield put(actions.stopLoading());
      onError(err);
      console.error(err);
    }
  });

  yield takeLatest(actions.deleteCategory, function* deleteCategorySaga({ payload: { id, onDone } }) {
    try {
      yield call(api.deleteCategory, id);
      yield put(actions.deleteCategorySuccess({ id }));
      if (onDone)
        onDone();
      yield put(actions.stopLoading());
    } catch (err) {
      yield put(actions.stopLoading());
      console.log(err);
    }
  });

  yield takeLatest(actions.updateCategory, function* updateCategorySaga({ payload: { id, values, onDone, onError } }) {
    try {
      const { data, props } = yield call(api.updateCategory, id, values);
      if (data) {
        yield put(actions.updateCategorySuccess({ category: data, id }));
        onDone();
      } else {
        yield put(actions.stopLoading());
        onError(props);
      }
    } catch (err) {
      yield put(actions.stopLoading());
      onError(err);
      console.log(err);
    }
  });
}


