import {
  CUSTOM_IMPORT,
  EDIT_CUSTOM_IMPORT,
  IMPORT_BANK_TRANSACTION,
  NEW_BANK_TRANSACTION,
  NEW_CUSTOM_IMPORT,
  NEW_FILE_IMPORTS,
  UPLOAD_EXAMPLE_CUSTOM_IMPORT,
  IMPORTED_BANK_TRANSACTIONS,
  BULK_TRANSACTION_EDITOR,
  EDIT_TRANSACTION,
  NEW_CUSTOMER_INVOICES,
  NEW_SUPPLIER_INVOICES,
  EDIT_CUSTOMER_INVOICES,
  EDIT_SUPPLIER_INVOICES,
  NEW_RECURRING_INVOICES,
  NEW_RECURRING_ADJUSTMENT, EDIT_RECURRING_INVOICES, RECURRING_TRANSACTIONS, DETAILS_RECURRING_TRANSACTIONS,
} from "../../../router/routesMap";
import {URLSearchParamsSkipNull} from "../../../crud/utils";

export const goToNewTransactionPage = (history, bankId) => {
  history.push(NEW_BANK_TRANSACTION + `?bank_account_id=${bankId}`);
};

export const goToTransactionsListPage = (history) => {
  history.push("/books/transactions");
};

export const goToCustomersTab = (history) => {
  history.push("/books/enter-transaction#tab=customers");
};

export const goToSuppliersTab = (history) => {
  history.push("/books/enter-transaction#tab=suppliers");
};

export const goToBulkEditorPage = (history) => {
  history.push(BULK_TRANSACTION_EDITOR);
};

export const goToEditTransactionPage = (history, id, bankId) => {
  history.push(EDIT_TRANSACTION + `?transaction_id=${id}&bank_account_id=${bankId}`);
};

export const goToImportsPage = (history) => {
  history.push(IMPORT_BANK_TRANSACTION );
};

export const goToImportTransactionPage = (history, bankId) => {
  history.push(IMPORT_BANK_TRANSACTION + `?bank_account_id=${bankId}&type=file_imports`);
};

export const goToNewImportPage = (history, bankId) => {
  history.push(NEW_FILE_IMPORTS + `?bank_account_id=${bankId}`);
};

export const goToImportCustomPage = (history) => {
  history.push(CUSTOM_IMPORT);
};

export const goToImportCustomNewPage = (history) => {
  history.push(NEW_CUSTOM_IMPORT);
};

export const goToUploadExampleFile = (history, id) => {
  history.push(UPLOAD_EXAMPLE_CUSTOM_IMPORT + `/?id=${id}`);
};

export const goToImportedBankTransactions = (history, fileImportId, bankId) => {
  history.push(IMPORTED_BANK_TRANSACTIONS + `/?bank_entry_id=${fileImportId}&bank_account_id=${bankId}`);
};


export const goToEditCustomFilePage = (history, id) => {
  history.push(EDIT_CUSTOM_IMPORT + `/?id=${id}`);
};

export const isImportPage = (pathname) => pathname === NEW_FILE_IMPORTS;

export const getInvoicePageName = (pathname) => {
  switch (pathname) {
    case NEW_CUSTOMER_INVOICES:
      return "new customers";
    case NEW_SUPPLIER_INVOICES:
      return "new suppliers";
    case EDIT_CUSTOMER_INVOICES:
      return "edit customers";
    case EDIT_SUPPLIER_INVOICES:
      return "edit suppliers";
    case NEW_RECURRING_INVOICES:
      return "new recurring invoice";
    case NEW_RECURRING_ADJUSTMENT:
      return "new recurring adjustment";
    case EDIT_RECURRING_INVOICES:
      return "edit recurring invoice";
    default:
      return "invoices";
  }
};

export const goToImportDataPage = (history, type) => {
  history.push(NEW_FILE_IMPORTS + `/?type=${type}`);
};

export const goToWizardPage = (history) => {
  history.push(`/books/tools/opening-balances`);
};

export const goToRecurringTransactionsPage = (history) => {
  history.push(RECURRING_TRANSACTIONS);
};

export const goToDetailsRecurringTransactionsPage = (history, id) => {
  history.push(DETAILS_RECURRING_TRANSACTIONS + `?id=${id}`);
};

export const getRecurringPageName = (pathname) => {
  if (pathname === DETAILS_RECURRING_TRANSACTIONS) {
    return "details"
  } else {
    return "recurring"
  }
};

export const goToRecurringInvoicesPage = (history, type) => {
  history.push(NEW_RECURRING_INVOICES + `?type=${type}`);
};

export const goToEditRecurringInvoicesPage = (history, id, type) => {
  history.push(EDIT_RECURRING_INVOICES + `?id=${id}&type=${type}`);
};

export const goToCustomersInvoicesPage = (history, id) => {
  if (id) {
    history.push(NEW_CUSTOMER_INVOICES + `?customer_id=${id}`)
  } else {
    history.push(NEW_CUSTOMER_INVOICES)
  }
};

export const goToEditInvoicesPage = (history, id,  valueTab) => {
  if (valueTab === "customers") {
    history.push(EDIT_CUSTOMER_INVOICES + `?customer_id=${id}`)
  } else {
    history.push(EDIT_SUPPLIER_INVOICES + `?supplier_id=${id}`)
  }
};

export const goToSuppliersInvoicesPage = (history, id) => {
  if (id) {
    history.push(NEW_SUPPLIER_INVOICES + `?supplier_id=${id}`)
  } else {
    history.push(NEW_SUPPLIER_INVOICES)
  }
};

const apiRoot = process.env.REACT_APP_BASE_URL;

export const getLinkTransaction = (row) =>{
  const params = URLSearchParamsSkipNull({
    transaction_id: row.id,
    bank_account_id: row.bank_account.id
  });

  return apiRoot + EDIT_TRANSACTION + `?${params}`;
};

/**
 * Return - or ''
 * @param number {float}
 * @returns {string}
 */
export const getNumberSign = (number) => {
  const numberString = Math.sign(number).toString();
  if (numberString.length > 1) {
    // means that number is negative
    return '-';
  }
  // number is positive
  return '';
};

/**
 * Gets number and currency symbol return `-$2`
 * @param number Float
 * @param currencySymbol {string}
 * @returns {string}
 */
export const withCurrency = (number, currencySymbol) => {
  return `${getNumberSign(number)}${currencySymbol}${Math.abs(number)}`;
};

export function round(num, precision = 2) {
  num = parseFloat(num);
  // half epsilon to correct edge cases.
  var c = 0.5 * Number.EPSILON * num;
//	var p = Math.pow(10, precision); //slow
  var p = 1;
  while (precision-- > 0) p *= 10;
  if (num < 0)
    p *= -1;
  return Math.round((num + c) * p) / p;
}