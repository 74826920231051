import React from 'react'
import { Avatar, TableCell } from '@material-ui/core'
import { flags, marketplaceIds } from '../../../components/Marketplaces/Marketplaces'
import { getNumber, withUnits } from '../../utils'

export const headRows = (currency) => [
  {
    id: "flag",
    label: "Market",
    colspan: 2,
    align: "left",
    render: (row) => {
      const flag = flags[marketplaceIds[row.marketplace]];
      return (
        <TableCell align="left" key={`${row.id}--flag`} className="table__cell--country-flag">
          <div className="table__cell--country-flag wrap">
            {flag && <Avatar alt="img" src={flag} className="table__cell--country-flag__img" />}
          </div>
        </TableCell>
      )
    }
  },
  {
    id: "country",
    label: undefined,
    colspan: 0,

  },
  {
    id: "sales",
    label: "Sales",
    align: "center",
    renderText: (row) => withUnits(currency, getNumber(row.sales)),
  },
  {
    id: "profit",
    label: "Profit",
    align: "center",
    renderText: (row) => withUnits(currency, getNumber(row.profit)),
  },
  {
    id: "refunds",
    label: "Refunds",
    align: "center",
    renderText: (row) => withUnits(currency, getNumber(row.refunds)),
  },
  {
    id: "units_sold",
    label: "Unit",
    align: "center",
    renderText: (row) => withUnits(undefined, getNumber(row.units_sold), "integer"),
  },
  {
    id: "roi",
    label: "ROI",
    align: "center",
    renderText: (row) => withUnits("%", getNumber(row.roi)),
  },
  {
    id: "margin",
    label: "Margin",
    align: "center",
    renderText: (row) => withUnits("%", getNumber(row.margin)),
  },
  {
    id: "expenses",
    label: "Expenses",
    align: "center",
    renderText: (row) => withUnits(currency, getNumber(row.expenses)),
  },
  {
    id: "inventory_cost",
    label: "Total Inventory Cost",
    align: "center",
    renderText: (row) => withUnits(currency, getNumber(row.inventory_cost)),
  },
];
