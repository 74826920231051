import React from "react";
import TableCell from "@material-ui/core/TableCell";
import {round, withCurrency} from "../utils";

export const headRows = [
  { id: 'customer_ref', numeric: false, disablePadding: true, label: 'Customer Reference', isActive:true},
  { id: 'name', numeric: false, disablePadding: true, label: 'Display Name',  isActive:true,
    render: (row, { onNameClick }) => {
      return <TableCell align="left" key={`${row.id}--name`} onClick={onNameClick(row)} className="table__cell--link">
        <span className="table__cell--link__text">{row.name}</span>
      </TableCell>
    }},
  { id: 'account_currency_balance', numeric: false, disablePadding: true, label: 'Balance',  isActive:true,
    renderText: (row) => round(row.account_currency_balance),
      // withCurrency(round(row.account_currency_balance),row.currency.currency_symbol)),
  },
  { id: 'inactive', numeric: false, disablePadding: true, label: 'Status',  isActive:true,
    renderText: (row) => {
      return row.inactive && row.inactive === true ? 'In active': 'Active';
    },
  },
  { id: 'actions', label: 'Action', isActive: true,}
];
