import React from 'react'
import Wrapper from '../../components/Wrapper'
import Expenses from './Expenses'

const ExpensesContainer = () => {
    return (
        <Wrapper elevation={0} className="expenses">
            <Expenses />
        </Wrapper>
    )
};

export default ExpensesContainer