import React from "react";
import {toAbsoluteUrl} from "../../../../../_metronic";
import {Header, OnboardingLayout} from "../components";
import ContentSubscribe from "./ContentSubscribe";

const OnboardingSubscribe = () => {
  return (
    <OnboardingLayout title='Subscribe to a plan.' currentStep={0}>
      <ContentSubscribe header={(props) => <Header{...props} />} isRedirect />
    </OnboardingLayout>
  );
};

export default OnboardingSubscribe;