import React, { useEffect, useState } from "react";
import Switch from "@material-ui/core/Switch";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { Themes } from "../../types/enums";
import Spinner from "../../pages/components/Spinner";
import toast from "../../pages/components/toast/toast";
import useUserTheme from "../../hooks/updateTheme.hook";
import useSettings from "../../hooks/settings.hook";

const UserThemes = () => {
  const {switcher, status} = useThemeSwitcher();
  const { updateTheme } = useUserTheme()
  const {getAccountSettings, accountSettings} = useSettings({fetch: true});
  const { id, light_theme } = accountSettings;
  const [isDarkMode, setIsDarkMode] = useState(!light_theme);

  useEffect(() => {
      switcher({
        theme: light_theme ? Themes.light : Themes.dark
      });
      setIsDarkMode(!light_theme);
  }, [light_theme]);

  const onDone = () => {
    console.log('Theme updated success. Light:', !light_theme ? Themes.light : Themes.dark);
  };

  const onError = (error) => {
    toast.error(error, { duration: null });
    console.log('error');
  };

  const updUser = (light_theme) => {
    const values = { light_theme };
    updateTheme({ id, values, onDone, onError });
    getAccountSettings({ id })
  };

  const toggleDarkMode = () => {
    setIsDarkMode(previous => {
      switcher({ theme: previous ? Themes.light : Themes.dark });
      updUser(previous);
      return !previous;
    });
  };

  return (
    <div className="sku-themes">
      {status === "loading" ? (
        <Spinner size={20} />
      ) : (
        <Switch
          className="sku-themes__switch"
          checked={isDarkMode}
          onChange={toggleDarkMode}
        />
      )}
    </div>
  );
};

export default UserThemes;