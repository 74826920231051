import React, { useState } from "react";
import TableToolbarComponent from "../../../components/TableComponent/TableToolbarComponent";
import ReceiptUpModal from "./ReceiptUpModal";
import { useModal } from "../../../../hooks/modal.hook";
import Typography from "@material-ui/core/Typography";
import Wrapper from "../../../components/Wrapper";
import BottomHeaderFilterForm from "../../Transaction/TransactionsPage/TransactionList/BottomHeaderFilterForm";
import useBankFilesCrud from "../../../../hooks/bankFiles.hook";
import FileComponent from "../../../components/ReceiptUploadsModal/FileComponent";

const title = "Receipt Uploads";
function ReceiptUploads() {
  const [isFilterFormShow, setIsFilterFormShow] = useState(false);
  const [isReceiptUpModalShow, toggleReceiptUpModal] = useModal();
  // eslint-disable-next-line
  const [searchQuery, setSearchQuery] = useState(null);
  const {bankFiles, addBankFile, updateBankFile} = useBankFilesCrud();

  const onFilterForm = () => setIsFilterFormShow(!isFilterFormShow);
  const filterForm = (isFilterFormShow ? <BottomHeaderFilterForm/> : null);

  const filesList = bankFiles && (
    <div className="receipt-up-trans__list">
      <Typography component="div" style={{padding: 8}} className="upload-file__tab">
        {bankFiles.map((file) => <FileComponent key={file.id} file={file} updateBankFile={updateBankFile}/>)}
      </Typography>
    </div>
  );
  const noFiles = !bankFiles && (
    <div className="receipt-up-trans__empty">
      <Typography gutterBottom variant="h4" component="h4" className="receipt-up-trans__h4">
        No Files Available
      </Typography>
    </div>
  );
  return <Wrapper>
    <TableToolbarComponent
      title={title}
      actionsHeader={{ onFilter: onFilterForm, onAttachments: toggleReceiptUpModal }}
      bottomHeader={filterForm}
      setSearchQuery={setSearchQuery}
    />
    {filesList}
    {noFiles}
    <ReceiptUpModal show={isReceiptUpModalShow} onClose={toggleReceiptUpModal} addBankFile={addBankFile}/>
  </Wrapper>
}

export default ReceiptUploads;
