import api from "../../crud/thank.crud";
import {call, put, takeLatest,} from 'redux-saga/effects'
import {createSlice} from "@reduxjs/toolkit";

const common = createSlice({
    name: 'thank',
    initialState: {
        loading: true,
        fetched: false,
    },
    reducers: {
        stopLoading: (state, actions) => {
            state.loading = false;
            return state;
        },
        addThankData: (state, actions) => {
            state.loading = true;
            return state;
        },
        addThankDataSuccess: (state, actions) => {
            state.loading = false;
            state.fetched = true;
            return state;
        },
    }
});

export const actions = common.actions;
export const reducer = common.reducer;

export function* saga() {
    yield takeLatest(actions.addThankData, function* addThankDataSaga({payload: {values, onDone, onError}}) {
        try {
            yield call(api.addThankData, values);
            yield put(actions.addThankDataSuccess());
            onDone();
        } catch (err) {
            yield put(actions.stopLoading());
            onError(err);
            console.log(err);
        }
    });
}
