import React, { useState } from "react";
import { toAbsoluteUrl } from "../../../../_metronic";
import Avatar from "@material-ui/core/Avatar";
import * as PropTypes from "prop-types";
import OverlayComponent from "../OverlayComponent";
import useUsers from "../../../hooks/users.hook";
import classNames from "clsx";
import { getDefaultMarketplace } from "../../home/utils";

export const flags = {
  GB: toAbsoluteUrl("/media/flags/260-united-kingdom.svg"),
  UK: toAbsoluteUrl("/media/flags/260-united-kingdom.svg"),
  DE: toAbsoluteUrl("/media/flags/162-germany.svg"),
  FR: toAbsoluteUrl("/media/flags/195-france.svg"),
  ES: toAbsoluteUrl("/media/flags/128-spain.svg"),
  IT: toAbsoluteUrl("/media/flags/013-italy.svg"),
  NL: toAbsoluteUrl("/media/flags/237-netherlands.svg"),
  US: toAbsoluteUrl("/media/flags/226-united-states.svg"),
  CA: toAbsoluteUrl("/media/flags/243-canada.svg"),
  MX: toAbsoluteUrl("/media/flags/252-mexico.svg"),
  BR: toAbsoluteUrl("/media/flags/255-brazil.svg"),
  AE: toAbsoluteUrl("/media/flags/151-united-arab-emirates.svg"),
  IN: toAbsoluteUrl("/media/flags/246-india.svg"),
  TR: toAbsoluteUrl("/media/flags/218-turkey.svg"),
  AU: toAbsoluteUrl("/media/flags/234-australia.svg"),
  DK: toAbsoluteUrl("/media/flags/174-denmark.svg"),
  SE: toAbsoluteUrl("/media/flags/184-sweden.svg"),
  EG: toAbsoluteUrl("/media/flags/158-egypt.svg"),
  JP: toAbsoluteUrl("/media/flags/063-japan.svg"),
  SA: toAbsoluteUrl("/media/flags/133-saudi-arabia.svg"),
  SG: toAbsoluteUrl("/media/flags/230-singapore.svg"),
  PL: toAbsoluteUrl("/media/flags/211-poland.svg"),
  all_marketplaces: toAbsoluteUrl("/media/flags/all.svg"),
  no_marketplace: toAbsoluteUrl("/media/d-icons/hourglass.svg"),
};

const SORT_MARKETPLACES = [
  "UK",
  "FR",
  "ES",
  "IT",
  "DE",
  "SE",
  "PL",
  "NL",
  "US",
  "CA",
  "MX",
  "all_marketplaces"
]

export const marketplaceIds = {
  'A1PA6795UKMFR9': 'DE',
  'A1RKKUPIHCS9HS': 'ES',
  'A13V1IB3VIYZZH': 'FR',
  'APJ6JRA9NG5V4': 'IT',
  'A1805IZSGTT6HS': 'NL',
  'A1F83G8C2ARO7P': 'UK',
  'ATVPDKIKX0DER': 'US',
  'A2EUQ1WTGCTBG2': 'CA',
  'A1AM78C64UM0Y8': 'MX',
  'A2NODRKZP88ZB9': 'SE',
  'A2Q3Y263D00KWC': 'BR',
  'A2VIGQ35RCS4UG': 'AE',
  'ARBP9OOSHTCHU': 'EG',
  'A21TJRUUN4KGV': 'IN',
  'A17E79C6D8DWNP': 'SA',
  'A33AVAJ2PDY3EV': 'TR',
  'A19VAU5U5O7RUS': 'SG',
  'A39IBJ37TRP1C6': 'AU',
  'A1VC38T7YXB528': 'JP',
  'A1C3SOZRARQ6R3': 'PL',
  'all_marketplaces': 'all_marketplaces',
};

export const marketplaceNames = {
  'A1PA6795UKMFR9': 'Germany',
  'A1RKKUPIHCS9HS': 'Spain',
  'A13V1IB3VIYZZH': 'France',
  'APJ6JRA9NG5V4': 'Italy',
  'A1805IZSGTT6HS': 'Netherlands',
  'A1F83G8C2ARO7P': 'UK',
  'ATVPDKIKX0DER': 'US',
  'A2EUQ1WTGCTBG2': 'Canada',
  'A1AM78C64UM0Y8': 'Mexico',
  'A2NODRKZP88ZB9': 'Sweden',
  'A1C3SOZRARQ6R3': 'Poland',
  'all_marketplaces': 'All Marketplaces',
};

const Marketplaces = ({ onSelectedMarketplace, activeMarketplaces, pppMarketplaces }) => {
  const { user } = useUsers({ fetch: false });
  const [active, setActive] = useState(getDefaultMarketplace());
  const amazonSettings = user.amazon_settings;
  const naSettings = amazonSettings.find(({ location }) => location === "North america");
  let keyNA = naSettings && naSettings.auth_code;
  let userMarketplaces;
  if (activeMarketplaces) {
    userMarketplaces = user.marketplaces
        .filter(
            ({ marketplace_id }) => activeMarketplaces.map(m => m.marketplace).includes(marketplace_id)
        );
  } else {
    userMarketplaces = user.marketplaces.filter(({ participate, location }) => {
      if (keyNA && keyNA.auth_code === "") {
        return participate && location === "Europe"
      }
      return participate;
    });
  }

  const getMarketplace = (value) => () => {
    setActive(value);
    onSelectedMarketplace && onSelectedMarketplace(value);
  };

  const allMarketplacesItem = {
    country_code: "all_marketplaces",
    country: "All Marketplaces",
  };

  let data = [];

  if (pppMarketplaces) {
    data = [...pppMarketplaces, allMarketplacesItem];
    const tempData = [];
    SORT_MARKETPLACES.forEach((el) => {
      const findData = data.find((fEl) => fEl?.country_code === el );
      if (findData) {
        tempData.push(findData)
      }
    });
    data = tempData
  } else {
    data = amazonSettings.international_fulfillment_options === "UK"
      ? [userMarketplaces.find(({ country_code }) => country_code === "UK")]
      : [...userMarketplaces, allMarketplacesItem];
  }

  return (
    <div className='marketplaces'>
      {(userMarketplaces || pppMarketplaces) && data.map(({ country_code, country, participate }) => {
        const style = classNames("marketplaces__item", {
          "marketplaces__item--active": active === country_code,
        });
        const countryTooltip = participate === "past" ? "you no longer sell on this marketplace" : country;
        return (
          <OverlayComponent key={country_code} tooltip={countryTooltip} className="marketplace-tooltip">
            <Avatar
              key={country_code}
              alt={country_code}
              src={flags[country_code]}
              className={style}
              onClick={getMarketplace(country_code)}
            />
          </OverlayComponent>
        )
      })}
    </div>
  )
};

Marketplaces.propTypes = {
  onSelectedMarketplace: PropTypes.func,
};

export default Marketplaces;