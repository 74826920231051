import api from '../../crud/onboarding.crud'
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects'
import { createSlice } from '@reduxjs/toolkit'
import { shortBaseState } from "./utils";

const common = createSlice({
  name: 'onboarding',
  initialState: {
    loading: true,
    fetched: false,
    onboarding: shortBaseState,
    subscriptions: shortBaseState,
    contactGetResponse: shortBaseState,
  },
  reducers: {
    stopLoading: (state, actions) => {
      const {type} = actions.payload;
      if (type) {
        state[type].loading = false;
      } else {
        state.loading = false;
      }
      return state;
    },

    getSubscription: (state, actions) => {
      state.subscriptions.loading = true;
      return state
    },
    getSubscriptionSuccess: (state, actions) => {
      const { options } = actions.payload;
      state.subscriptions.details = options.reverse();
      state.subscriptions.loading = false;
      return state
    },

    sendContact: (state, actions) => {
      state.loading = true;
      return state
    },
    sendContactSuccess: (state, actions) => {
      state.loading = false;
      return state
    },

    addContactGetResponse: (state, actions) => {
      state.contactGetResponse.loading = true;
      return state
    },
    addContactGetResponseSuccess: (state, actions) => {
      state.contactGetResponse.loading = false;
      return state
    },
  }
});

export const actions = common.actions;
export const reducer = common.reducer;

export function* saga() {
  yield takeLatest(actions.getSubscription, function* getSubscriptionSaga({payload}) {
    try {
      const { data } = yield call(api.getSubscription, payload);
      data
        ? yield put(actions.getSubscriptionSuccess({ options: data }))
        : yield put(actions.stopLoading({type: "subscriptions"}));
    } catch (err) {
      yield put(actions.stopLoading({type: "subscriptions"}));
      console.log(err)
    }
  });

  yield takeLatest(actions.sendContact, function* sendContactSaga({payload = {}}) {
    const {values, onDone, onError} = payload;
    try {
      const { data, ...res } = yield call(api.sendContact, values);
      if (data) {
        yield put(actions.sendContactSuccess());
        onDone(data)
      } else {
        yield put(actions.stopLoading());
        onError(res);
      }
    } catch (err) {
      yield put(actions.stopLoading());
      console.log(err);
      onError(err);
    }
  });

  yield takeLatest(actions.addContactGetResponse, function* addContactGetResponseSaga({payload = {}}) {
    const {values, onDone, onError} = payload;
    try {
      const { data } = yield call(api.addContactGetResponse, { values });
      if (data) {
        yield put(actions.addContactGetResponseSuccess());
        onDone(data)
      } else {
        onError('Something went wrong!');
        yield put(actions.stopLoading({type: 'contactGetResponse'}))
      }
    } catch (err) {
      onError(err);
      yield put(actions.stopLoading({type: 'contactGetResponse'}));
      console.log(err)
    }
  });
}
