import React from "react";
import {numberWithCommas} from "../../utils";
import {calculateTotalCogsForEdit, calculateTotalCogsForPreset, getStrategyName} from './utils';
import FormField from "../../../components/controls/FormField";
import Form from "react-bootstrap/Form";
import clsx from "clsx"

export const DirectCost = ({currency, cost}) => (
  <div className="cost-container">
    <span>{cost.label}</span>
    <span>{`${cost.isCurrency ? currency : ''} ${numberWithCommas(cost.value)}${cost.isCurrency ? '' : '%'}`}</span>
  </div>
);

export const EditDirectCost = ({form, item, disabled, currency, costs}) => (
  <Form.Group controlId={item.id} className={clsx("cost-container", {disabled})}>
    <Form.Label column={false}>
      {item.label}
    </Form.Label>
    <FormField
      name={item.id}
      label={item.label}
      type={item.type}
      customProps={item.customProps}
      extraProps={{currency, costs}}
      {...form}
    />
  </Form.Group>
);


const EditCogs = ({price, form, currency, cogInterface, row}) => {
  const totalCogs = calculateTotalCogsForEdit( form.values, currency);
  return (
    <>
      {cogInterface.map(item => <EditDirectCost key={item.id} form={form} item={item}/>)}

      <div className="cost-container">
        <b>Total costs</b>
        <b>{`${currency} ${numberWithCommas(totalCogs)}`}</b>
      </div>

      <div className="cost-container">
        <b>Gross Profit</b>
        <b>{`${currency} ${numberWithCommas(price - totalCogs)}`}</b>
      </div>
    </>
  );
};

export const PresetedCogs = ({currency, costs, price, row, form}) => {
  const totalCogs = calculateTotalCogsForPreset({cogs: costs, inventoryCostForm: form.values.inventory_cost});
  return (
    <>
      {costs.map(cost => <DirectCost key={cost.label} cost={cost} currency={currency}/>)}
      <div className="cost-container">
        <b>Total costs</b>
        <b>{`${currency} ${numberWithCommas(totalCogs)}`}</b>
      </div>

      <div className="cost-container">
        <b>Gross Profit</b>
        <b>{`${currency} ${numberWithCommas(price - totalCogs)}`}</b>
      </div>
    </>
  );
}


export const Option = ({form, option, handleChangeStrategy, row, currency, cogInterface}) => (
  <div key={option.label} className="option-container">
    <div className="option-title-container">
      <span className="option-title">{option.label}</span>
      <FormField
        label={option.label}
        name={getStrategyName(option.label)}
        type="switch"
        customProps={{
          onChange: (e) => handleChangeStrategy(option.label, e.target.checked, form)
        }}
        {...form}
      />
    </div>
    <div className="costs-wrapper">
      <span className="costs-wrapper__title">Direct Costs</span>
      {option.label === 'Edit' ?
        <EditCogs row={row} cogInterface={cogInterface} price={row.price} form={form} currency={currency}/> :
        <PresetedCogs form={form} row={row} currency={currency} costs={option.costs} price={row.price}/>
      }
    </div>
  </div>
);

export const GeneralData = ({item, form}) => (
  <Form.Group key={item.id} controlId={item.id} className={`form-group--${item.id} ${item.groupClassName}`}>
    <Form.Label column={false}>{item.label}:</Form.Label>
    <FormField
      name={item.id}
      label={item.label}
      type={item.type}
      customProps={item.customProps}
      disabled={item.disabled}
      {...form}
    />
  </Form.Group>
);