import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { actions } from "../store/ducks/dashboardTiles.duck.js";
import * as Selector from "../selectors/dashboardTiles.selectors";

const useDashboardTiles = (props = {}) => {
  const {
    fetch = true,
    period = null,
    start_date = null,
    end_date = null,
    marketplace = null
  } = props;

  const dispatch = useDispatch();

  const refresh = (params) => {
    dispatch(actions.getExpensesDash(params));
    // dispatch(actions.getInventoryCost(params));
    dispatch(actions.getResaleValue(params));
  };
  useEffect(() => {
    if (fetch) {
      refresh({ period, start_date, end_date, marketplace });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch]);

  const getExpensesDash = () => {
    dispatch(actions.getExpensesDash())
  } 

  const getInventoryCost = () => {
    dispatch(actions.getInventoryCost());
  };

  const getResaleValue = () => {
    dispatch(actions.getResaleValue());
  };

  const expenses = Selector.getExpensesDash(useSelector(state => state));
  const inventoryCost = Selector.getInventoryCost(useSelector(state => state));
  const inventoryCostWarning = Selector.getInventoryCostWarning(
    useSelector(state => state)
  );
  const inventoryQuantity = Selector.getInventoryQuantity(
    useSelector(state => state)
  );
  const inventoryCostMarketplaces = Selector.getInventoryMarketplaces(
    useSelector(state => state)
  );
  const resaleValue = Selector.getResaleValue(useSelector(state => state));

  return {
    expenses: expenses.data,
    expensesLoading: expenses.loading,
    inventoryCost,
    inventoryCostWarning,
    inventoryQuantity,
    inventoryCostMarketplaces,
    resaleValue,
    getExpensesDash,
    getInventoryCost,
    getResaleValue,
    refresh
  };
};

export default useDashboardTiles;
