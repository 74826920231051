import { requests, URLSearchParamsSkipNull } from "./utils";

export default {
  getChart: async _params => {
    const params = URLSearchParamsSkipNull(_params);
    const [mainChartResponse, additionalChartResponse] = await Promise.all([
      requests.get(`/chart/?${params}`),
      // TODO: should be replaced with production endpoint
      requests.getDev(`/inventory_cost_chart/?${params}`)
    ]);
    const consolidatedData = {
      ...mainChartResponse.data,
      inventory: { ...additionalChartResponse.data }
    };
    return { data: consolidatedData };
  },
  // orders and refunds
  getOrders: () => requests.get(`/order/newest`),
  getOrderNotification: interval =>
    requests.get(`/order-notification/?interval=${interval}&`),

  // order's note
  updateOrderNote: (id, values) =>
    requests.post(`/order-item/${id}/update_note`, values),
  updateOrderCOG: (id, values) => requests.patch(`/order-item/${id}`, values),
  getSales: _params => {
    const params = URLSearchParamsSkipNull(_params);
    return requests.get(`/sales/?${params}`);
  }
};
