import React from "react";
import Form from "react-bootstrap/Form";
import FormField from "./FormField";
import classNames from "clsx";
import FormArray from "../../home/Settings/COGSettings/FormArray";
import * as PropTypes from "prop-types";

export const formContent = (props) =>  {
  const { interfaces, form, disabled, className, classNameLabel, extraProps={} } = props;
  const { index, dataName } = extraProps;

  return (
    interfaces.map(item => {
      const { customProps, showLabel, id } = item;
      const nameField = index || index === 0 ?`${dataName}.${index}.${id}` : id;
      const _customProps = customProps && typeof customProps === "function"
        ? customProps(form)
        : customProps;

      const _className = className && typeof className === "function"
        ? className(id)
        : className;

      return (
        <Form.Group key={nameField} controlId={nameField} className={classNames(`form-group--${nameField}`, _className)}>
          {!showLabel && <Form.Label className={classNames(classNameLabel)} column={false}>{item.label}</Form.Label>}
          <FormField
            render={item.render}
            name={nameField}
            label={item.label}
            showLabel={showLabel}
            type={item.type}
            disabled={disabled || item.disabled}
            validate={item.validator}
            placeholder={item.placeholder}
            customProps={_customProps}
            extraProps={{...extraProps, initId: item.id}}
            {...form}
          />
      </Form.Group>
      )
    })
  )
};

FormArray.propTypes = {
  interfaces: PropTypes.array,
  form: PropTypes.object,
  disabled: PropTypes.bool,
  extraProps: PropTypes.object,
  className: PropTypes.string,
  classNameLabel: PropTypes.string,
};