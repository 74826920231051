import React from "react";
import { headRows } from "./marketplaceTotalsHead";
import * as PropTypes from "prop-types";
import DashboardTable from "../components/DashboardTable";
import useUsers from "../../../../hooks/users.hook";
import { currencySymbols } from "../../utils";

const MarketplacesTotals = ({ data, loading, expenses }) => {
  // const [height, setHeight] = useState(null);
  const { user } = useUsers({ fetch: false });

  // const getHeightBlock = (height) => {
  //   setHeight(height - 85)
  // };

  return (
    <div className="row">
      <div className="col-md-12  col-xl-12">
        <DashboardTable
          title="Marketplace"
          helpText="Marketplace"
          headRows={headRows(currencySymbols[user.currency])}
          data={data}
          isPagination={false}
          className="all-market"
          loading={loading}
          // getHeightBlock={getHeightBlock}
          disabled={true}
        />
      </div>
    </div>
  )
};

MarketplacesTotals.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  expenses: PropTypes.number,
};

export default MarketplacesTotals;
