import React from "react";
import {Button, Typography} from "@material-ui/core";
import { profit } from "./interfacesForms";
import FormField from "../../../components/controls/FormField";
import Form from "react-bootstrap/Form";
import {TYPES} from "./utils";

const WLSettings = ({ currency, form, setSubmitCustomProps }) => {
  return (
    <div className="settings-main__vat__form settings-main__w-and-l">
      {/*<OverlayComponent tooltip={tooltipWL} placement="bottom-start">*/}
      <Typography variant="h5" color="primary" className="settings__subtitle--main">
        Winners & Losers Setting
      </Typography>
      <div className="settings__subtitle">
        <Typography variant="subtitle1" className="settings__subtitle__item bottom-border">
          On the Dash page you have the option to see Sales that were a Winner (i.e. made you a profit)
          and Sales that were a Loser (i.e lost you money). The default is set to in profit as a Winner
          and anything that loses you money as a Loser. You may adjust those figures here if you wish
          to personalize the feature.
        </Typography>
      </div>
      {/*</OverlayComponent>*/}
      <div className="form form--row">
        {profit({ currency }).map((item) => (
          <Form.Group key={item.id} controlId={item.id} className={`form-group--${item.id}`}>
            <Form.Label column={false}>{item.label}</Form.Label>
            <FormField
              name={item.id}
              label={item.label}
              type={item.type}
              customProps={item.customProps}
              {...form}
            />
          </Form.Group>
        ))}
      </div>

      <div className="settings__wrap-btn">
        <Button
          variant="contained"
          className="settings-main__vat__btn settings__btn"
          type="submit"
          onClick={() => setSubmitCustomProps({
            type: TYPES.wl,
            successNote: "Success!",
          })}
          disabled={form.isSubmitting}
        >
          Update
        </Button>
      </div>
    </div>
  )
};

export default WLSettings;
