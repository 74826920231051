import React from 'react';
import * as PropTypes from "prop-types";
import CreateEditModal from "../../../components/CreateEditModal";
import Form from "react-bootstrap/Form";
import {requiredValidator} from "../../../components/controls/validators";
import FormField from "../../../components/controls/FormField";
import RSelect from "../../../components/controls/RSelect";
import toast from "../../../components/toast/toast";

const PERMISSION_TYPE_CHOICES = [
  [0, 'No access'],
  [1, 'Read'],
  [2, 'Create edit delete '],
];

const getPermissionTypeByValue = (value) => (PERMISSION_TYPE_CHOICES.find(([key]) => key === value)[1]);

const getPermissionField = ({ name, id, description }) => {
  return {
    id: id,
    label: name,
    type: 'RSelect',
    validator: requiredValidator(`${name} is required`),
    customProps: {
      options: PERMISSION_TYPE_CHOICES.map(([value, label]) => ({ label: label, value: value })),
    }
  }
};

const getPermissionsFieldLIst = (permissions) => {
  return permissions.map((permission) => (getPermissionField(permission)))
};

export default function GroupCreateEditModal({ initValues, groups, permissions, updateGroup, addGroup, showModal, onClose }) {
  const mainFields = [
    {
      id: 'name',
      label: 'Permission set name',
      type: 'string',
      validator: requiredValidator(`Permission set name is required`),
    },
    ...getPermissionsFieldLIst(permissions),
  ];

  if (initValues) {
    const { id, name, permissions } = initValues;
    initValues = {};
    initValues.id = id;
    initValues.name = name;
    permissions.forEach((permission) => {
      initValues[permission.permission] = {
        label: getPermissionTypeByValue(permission.permission_type),
        value: permission.permission_type,
      }
    });
  }
  return <CreateEditModal
    initValues={initValues}
    show={showModal}
    onClose={onClose}
    modalTitle={"Group"}
    className="modal--small"
    nameNotification="Group"
    onSubmit={(values, { setSubmitting, resetForm }) => {
      const onDone = () => {
        setSubmitting(false);
        onClose();
        resetForm();
      };
      /*
      format to create group
      {
        "name": "New test group",
        "permissions": [
          {
            "id": "1",
            "permission_type": "1"
          },
        ]
      }
      */
      const { id, name, ...permissions } = values;
      const formattedValues = {
        name: name,
        permissions: Object.entries(permissions).map(([key, permission_type]) => (
          {
            id: parseInt(key),
            permission_type: parseInt(permission_type.value),
          }
        ))
      };
      if (initValues && initValues.id) {
        updateGroup({
          id: initValues.id,
          values: formattedValues,
          onDone,
        });
      } else {
        const duplicateName = groups.find(group => group.name === values.name);
        if (duplicateName) {
          setSubmitting(false);
          toast.error(`Group with name ${duplicateName.name} already exists`);
          return;
        }
        addGroup({
          values: formattedValues,
          onDone,
        });
      }
    }}
  >
    {
      (form) => (
        mainFields.map((item) => (
          <Form.Group key={item.id} controlId={item.id}>
            <Form.Label>{item.label}</Form.Label>
            <FormField
              render={item.render}
              name={`${item.id}`}
              label={item.label}
              type={item.type}
              disabled={item.disabled}
              validate={item.validator}
              customProps={item.customProps}
              {...form}
            />
          </Form.Group>
        ))

      )    }
  </CreateEditModal>
}

GroupCreateEditModal.propTypes = {
  addGroup: PropTypes.func.isRequired,
  updateGroup: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  initValues: PropTypes.object,
  groups: PropTypes.array.isRequired,
  permissions: PropTypes.array.isRequired,
};
