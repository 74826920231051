import { requests, URLSearchParamsSkipNull } from "./utils";

export default {
    getExpensesDash: (_params) => {
        const params = URLSearchParamsSkipNull(_params)
        return requests.get(`/expense_chart/?${params}`)
    },
    getInventoryCost: (_params)=> {
        const params = URLSearchParamsSkipNull(_params)
        return requests.get(`/inventory_cost_chart/?${params}`)
    },
    getResaleValue: (_params) => {
        const params = URLSearchParamsSkipNull(_params)
        return requests.get(`/dashboard-tiles/resale-value/?${params}`)
    },
};

// https://skuify.com/api/v1/inventory_cost_chart/?period=today&start_date=2023-12-22&marketplace=all_marketplaces&%2F=null&debug_user_id=57
