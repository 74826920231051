import React, { useEffect, useState } from 'react';
import * as PropTypes from "prop-types";
import Typography from '@material-ui/core/Typography';
import { makeStyles } from "@material-ui/core/styles";
import { formContent } from "../../../../components/controls/formContent";
import UploadFileScreen from "../../../../components/UploadFileScreen";
import DButton from "../../../../components/Button";
import { useHistory } from "react-router-dom";
import {
  goToImportCustomPage,
  goToImportedBankTransactions,
  isImportPage,
  goToImportsPage,
  goToUploadExampleFile,
  goToWizardPage,
  goToCustomersTab,
  goToSuppliersTab
} from "../../utils";
import { requiredValidator } from "../../../../components/controls/validators";
import Wrapper from "../../../../components/Wrapper";
import * as BankSelector from "../../../../../selectors/bank.selectors";
import * as BankDuck from "../../../../../store/ducks/bunk.duck";
import { connect } from "react-redux";
import { Form as FormikForm, Formik } from "formik";
import { useParseHistory } from "../../../../../hooks/modal.hook";
import * as Selector from "../../../../../selectors/imortBankTransactions.selectors";
import * as importDuck from "../../../../../store/ducks/import.duck";
import Spinner from "../../../../components/Spinner";
import useCustomImportCrud from "../../../../../hooks/customImport.hook";
import toast from "../../../../components/toast/toast";
import useAsyncTask from "../../../../../hooks/asyncTask.hook";

const useStyles = makeStyles(theme => ({
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    padding: "25px 25px",
  },
}));

const AddCustomImportUploadBank = ({ banks, createBankEntry, getBanks, getBank, bank }) => {

  const {
    createCustomImportFormat,
    importFormats
  } = useCustomImportCrud();

  const { getTaskStatus } = useAsyncTask();

  const [importing, setImporting] = useState(false);

  useEffect(() => {
    // getImportFormats();
    getBanks();
  }, []);
  const [rows, setRows] = useState([]);
  const classes = useStyles();
  let history = useHistory();
  const bankId = useParseHistory().bank_account_id;
  const typeImport = useParseHistory().type;

  useEffect(() => {
    if (bankId) {
      getBank({id: bankId})
    }
  }, []);

  if (importFormats.loading || !banks) {
    return <Spinner className="d-spinner--full-height"/>
  }

  // show spinner when importing files after submitting file
  if (importing) {
    return (
      <div>
        <h2>Processing import</h2>
        <Spinner className="d-spinner--full-height"/>
      </div>
    )
  }

  const formFieldUpload = [
    {
      id: 'description',
      label: 'Description',
      type: 'string',
      validator: requiredValidator("Description is required"),
    },
    {
      id: 'import_format',
      label: 'Upload type',
      type: 'RSelect',
      customProps: {
        options: importFormats.details.map(({ id, name }) => ({ label: name, value: id })),
      },
      validator: requiredValidator("Upload type is required"),
    },
    // {
    //   id: 'bank_account',
    //   label: 'Bank account',
    //   type: 'RSelect',
    //   customProps: {
    //     options: banks.map(({id, name}) => ({label: name, value: id})),
    //   }
    // },
  ];

  const importType = [
    {
      id: 'description',
      label: 'Description',
      type: 'string',
      validator: requiredValidator("Description is required"),
    },
    {
      id: 'import_format',
      label: 'Type',
      type: 'RSelect',
      // TODO checked options
      customProps: {
        options: importFormats.details.map(({ id, name }) => ({ label: name, value: id })),
      },
      validator: requiredValidator("Type is required"),
    },
  ];

  const formFieldNew = [
    {
      id: 'name',
      label: 'Name',
      type: 'string',
      validator: requiredValidator('Name is required'),

    },
  ];
  const className = "form-group--upload";

  const onUploadExampleFile = (id) => {
    goToUploadExampleFile(history, id)
  };

  const pathName = history.location.pathname;
  const toggleImportPage = isImportPage(pathName);

  let title;
  if (toggleImportPage && bank) {
    title = `Upload Bank Transactions for ${bank.name} (${bank.id})`
  } else if (typeImport) {
    title = "New Data Import"
  } else {
    title = "New Custom File Import Format"
  }

  const onCancel = () => {
    if (toggleImportPage) {
      goToImportsPage(history)
    } else if (typeImport) {
      switch (typeImport) {
        case "Customer":
          goToCustomersTab(history);
          break;
        case "Supplier":
          goToSuppliersTab(history);
          break;
        default:
          goToWizardPage(history);
      }
    } else {
      goToImportCustomPage(history)
    }
  };

  const onDoneImport = (id) => toggleImportPage ? null : onUploadExampleFile(id);

  return (
    <Formik
      enableReinitialize
      initialValues={{}}
      onSubmit={
        (values, {setSubmitting}) => {
          const onError = (error) => {
            toast.error(error);
            setSubmitting(false);
          };

          if (toggleImportPage) {
            setImporting(true);
            createBankEntry({
              values: {
                description: values.description,
                type: 'File import',
                rows: rows,
                import_format: values.import_format.value,
                bank_account: bankId,
              },
              onDone: ({ status, task_id }) => {
                // To process all transaction from file takes a lot of time
                getTaskStatus({
                  taskId: task_id,
                  onDone: ({ id }) => {
                    setSubmitting(false);
                    setImporting(false);
                    goToImportedBankTransactions(history, id, bankId);
                  }
                })
              },
              onError,
            });

          } else {
            const onDone = ({ id }) => {
              setSubmitting(false);
              onDoneImport(id);
            };

            if (rows.length !== 0) {
              // we don't need to store all lines from file for example
              // 5 is enough
              values.example = rows.slice(0, 5);
              createCustomImportFormat({
                values,
                onDone,
                onError
              });
            } else {
              alert('Please, upload csv example file');
            }
          }
        }}>
      {(form) => {

        let fieldFormContent;
        if (toggleImportPage) {
          fieldFormContent = formContent({ interfaces: formFieldUpload, form, className })
        } else if (typeImport) {
          fieldFormContent = formContent({ interfaces: importType, form, className })
        } else {
          fieldFormContent = formContent({ interfaces: formFieldNew, form, className })
        }

        return (
          <FormikForm>
            <Wrapper className="import-upload">
              <Typography className={classes.title} variant="h5"> {title} </Typography>
              <div className="row">
                <div className="offset-sm-3 col-sm-6">
                  {fieldFormContent}
                  <Typography variant="body1" className="import-upload__content__text">
                    Please upload a CSV file containing the data you want to import.
                    Your CSV file can have any number of columns, in any order.
                    The only requirement is that there is a heading row which uses some of the headings
                    in the list. We will import the data from all the columns which match these headings.
                  </Typography>
                  <Typography variant="body1" className="import-upload__content__text import-upload__content__text--italic">
                    Please select an import type to see what headings are valid for use in your CSV file.
                  </Typography>
                  <UploadFileScreen setRows={setRows}/>
                  <div className="container-button container-button--modal container-button--page">
                    <DButton typeOfButton="doneAllSecondary" disabled={form.isSubmitting} type="submit">
                      {toggleImportPage
                        ? "Save and upload"
                        : typeImport
                          ? "Save and Upload"
                          : "Upload Example File"}
                    </DButton>
                    <DButton typeOfButton="close" onClickCustom={onCancel}>Cancel</DButton>
                  </div>
                </div>
              </div>
            </Wrapper>
          </FormikForm>
        )
      }}
    </Formik>
  );
};

AddCustomImportUploadBank.propTypes = {
  getBank: PropTypes.func.isRequired,
  bank: PropTypes.object,
  // createCustomImportFormat: PropTypes.func.isRequired,
  // importFormats: PropTypes.object.isRequired,
  // loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    importFormats: Selector.selectImportFormats(state, props),
    bank: BankSelector.getBank(state, props),
    banks: BankSelector.getBanks(state, props),
    loading: Selector.getLoading(state, props),
  }
};

const mapDispatchToProps = {
  getBank: BankDuck.actions.getBank,
  getBanks: BankDuck.actions.getBanks,
  createBankEntry: importDuck.actions.createBankEntry,
  // getImportFormats: duck.actions.getImportFormats,
  // createCustomImportFormat: importDuck.actions.createImportFormat,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddCustomImportUploadBank);