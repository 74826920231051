import { requests, URLSearchParamsSkipNull } from "./utils";

export const ENDPOINT = "/category";

export default {
    getCategories: (start_data, end_data, report_date, q) => {
        const params = URLSearchParamsSkipNull({start_data, end_data, report_date, q});
        return requests.get(`${ENDPOINT}/?${params}`)
    },
    getCategory: (id) => requests.get(`${ENDPOINT}/${id}`),
    addCategory: (values) => requests.post(`/category`, values),
    updateCategory: (id, values) => requests.put(`/category/${id}`, values),
    deleteCategory: (id) => requests.del(`/category/${id}`),

    //Adjustment
    getAdjustments: (companyId, q) => {
        return requests.get(`${companyId}/adjustment/?q=${q}`)
    },
    getAdjustment: (companyId, id) => requests.get(`${companyId}/adjustment/${id}`),
    addAdjustment: (companyId, values) => requests.post(`${companyId}/adjustment`, values),
    updateAdjustment: (companyId, id, values) => requests.put(`${companyId}/adjustment/${id}`, values),
    deleteAdjustment: (companyId, id) => requests.del(`${companyId}/adjustment/${id}`),
};
