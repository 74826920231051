export const messages = [
  {label: "Profit & Loss Account", message: 'You can see detailed information about the transactions ' +
      'hat make up the Profit & Loss Account balance in the General Ledger export from the ' +
      '"All Reports" page.'
  },
  // {label: "Sales", message: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.' +
  //     ' Asperiores, inventore, neque? Commodi earum expedita neque nostrum, reiciendis ' +
  //     'temporibus voluptates. Amet commodi error illo ratione? Aliquid laborum nulla' +
  //     ' odit soluta voluptate'
  // },
  // {label: "Business Account", message: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.' +
  //     ' Asperiores, inventore, neque? Commodi earum expedita neque nostrum, reiciendis ' +
  //     'temporibus voluptates. Amet commodi error illo ratione? Aliquid laborum nulla' +
  //     ' odit soluta voluptate'
  // },
];