import React from "react";
import classNames from "clsx";
import * as PropTypes from "prop-types";
import TooltipComponent from "../../../components/TooltipComponent";
import { fulfilmentIcon } from "./utils";

const FulfillmentCell = ({ row, fulfillment }) => {

  const isFulfilment = !!fulfillment && fulfilmentIcon[fulfillment];
  return (
    <TooltipComponent tooltip={isFulfilment ? fulfilmentIcon[fulfillment].name : ""}>
      <div className="table__cell__img--wrap">
        {isFulfilment && fulfilmentIcon[fulfillment].url
          ? (
            <div className="table__cell__img">
              <img
                alt="img"
                className={classNames("table__cell__img--icon", { "icon--small": fulfilmentIcon[fulfillment].small })}
                src={fulfilmentIcon[fulfillment].url}
              />
            </div>
          )
          : "-"
        }
      </div>
    </TooltipComponent>
  )
};

FulfillmentCell.propTypes = {
  row: PropTypes.object,
};

export default FulfillmentCell;