import React from "react";
import Wrapper from "../../components/Wrapper";
import TableComponent from "../../components/TableComponent/TableComponent";
import { headRows } from "./headRows";
import TabsToolbar from "./TabsToolbar";
import useReports from "../../../hooks/reports.hook";
import useTableParams from "../../../hooks/useTableParams";
import { getDefaultMarketplace } from "../utils";
import withConfirmHook from "../../../hooks/withConfirm.hook";
import toast from "../../components/toast/toast";

const Reports = ({ confirm }) => {
  const { deleteMainReports, mainReportsLoading, mainReportsCount, getMainReports, mainReports } = useReports();
  let initParams = {
    marketplace: getDefaultMarketplace(),
    page_name: "reports",
    filter: "",
  };

  const { setParams, params } = useTableParams(getMainReports, initParams);

  const onDeleteReport = (id) => () => {
    confirm(() => {
        deleteMainReports({
          values: [{ id }],
          ids: [id],
          onDone: (msg) => {
            msg && toast.success(msg, { duration: 3000 })
          },
          onError: (error) => {
            console.log(error);
          }
        })
      },
      {
        description: 'This Action is Permanent!',
        style: "d-confirm"
      })()
  };

  return (
    <Wrapper elevation={0} classWrapper="main-reports--wrap">
      <div className="products page__table__wrap kt-portlet sales main-reports">
        <TabsToolbar params={params} setParams={setParams} />
        <TableComponent
          className="products__table main-reports__table"
          headRows={headRows}
          items={mainReports}
          count={mainReportsCount}
          loading={mainReportsLoading}
          setParams={setParams}
          rowProps={{
            onDeleteReport,
          }}
          emptyWording="No reports have been generated"
        />
      </div>
    </Wrapper>
  )
};

export default withConfirmHook(Reports);