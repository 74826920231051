import { requests, URLSearchParamsSkipNull } from "./utils";

export const ENDPOINT = "/product";

export default {
// uncomment for test
//     getProducts: () => new Promise(resolve => resolve({ data: testData })), // local products

// comment out for test
    getProducts: (params) => requests.get(`/inventory/?${URLSearchParamsSkipNull(params)}`),

    getProduct: (id) => requests.get(`${ENDPOINT}/${id} `),
    addProduct: (values) => requests.post(`${ENDPOINT}`, values),
    updateProduct: (id, values) => requests.patch(`${ENDPOINT}/${id}`, values),
    deleteProduct: (id) => requests.del(`${ENDPOINT}/${id}`),

    // Winners & Losers
    getWinners: (params) => requests.get(`${ENDPOINT}/winners/?${URLSearchParamsSkipNull(params)}`),
    getLosers: (params) => requests.get(`${ENDPOINT}/losers/?${URLSearchParamsSkipNull(params)}`),


    //Settings
    getSettings: () => requests.get(`${ENDPOINT}/settings`),

    //Notifications
    getNotifications: () =>  requests.get('/note'),
    addNotification: (values) => requests.post(`/note`, values),
    updateNotification: (id, values) => requests.patch(`/note/${id}`, values),
    deleteNotification: (id) => requests.del(`/note/${id}`),

    // Imports
    getImportsProducts: (params) => requests.get(`${ENDPOINT}/bbp/?${URLSearchParamsSkipNull(params)}`),
    addImportsProduct: (values) => requests.post(`${ENDPOINT}/bbp`, values),
    deleteBBP: (values) => requests.del(`${ENDPOINT}/${values}`),

    //Import COG
    addImportsCOG: (filename, values) => requests.file(`${ENDPOINT}/import/${filename}`, values),

    //Export products
    getExportProducts: (url, fileName) => requests.downloadFile(url, fileName),

    // product's note
    updateProductNote: (id, values) => requests.post(`/product/${id}/update_note`, values),

    // product's BEP
    getBreakEvenPoint: (id, values, type) => requests.post(`/break-point-even/${id}/${type}`, values),

    skipWarning: (id) => requests.post(`/product-cautions/${id}`),
    getReimbursements: (params) => requests.get(`/reimbursement/?${URLSearchParamsSkipNull(params)}`),
    getAsinHisties: (params) => requests.get(`/journey/?${URLSearchParamsSkipNull(params)}`),
    updateAsinHistory: (jorneyId, shipmentId, values) => requests.post(`/journey/${jorneyId}/update_inbound_shipment/${shipmentId}`, values),
    updateReimbursement: (id, values) => requests.patch(`/reimbursement/${id}`, values),
    updateProductBBPImportStrategy: (id, values) => requests.post(`/product/${id}/update_bbp_import_strategy`, values),
    mergeRestocks: (id1, id2, quantity_from) => requests.post(`/restock_merge/${id1}/${id2}`, {quantity_from}),

    // Reconciliation
    getReconciliation: (params) => requests.get(`/reconciliation/?${URLSearchParamsSkipNull(params)}`),
    getRaiseCase: ({ id, button }) => requests.get(`/reconciliation/${id}/case/?button=${button}&`),
    updateRaiseCase: (id, values) => requests.post(`/reconciliation/${id}/case`, values),
    updateReconciliationNote: (id, values) => requests.post(`/reconciliation/${id}/update_note`, values),
};
