import api from "../../crud/product.crud";
import apiReports from "../../crud/reports.crud";
import {call, put, takeLatest,} from 'redux-saga/effects'
import {createSlice} from "@reduxjs/toolkit";
import {baseListState, shortBaseState} from "./utils";

const common = createSlice({
    name: 'asin',
    initialState: {
        asinHistories: {...baseListState},
        asinProfitReport: {...shortBaseState},
        loading: true,
        fetched: false,
    },
    reducers: {
        stopLoading: (state, actions) => {
            const {type} = actions.payload;
            if (type) {
                state[type].loading = false;
            } else {
                state.loading = false;
            }
            return state;
        },
        getAsinHisties: (state, actions) => {
            state.asinHistories.loading = true;
            return state;
        },
        getAsinHistiesSuccess: (state, actions) => {
            const {results, count} = actions.payload;
            state.asinHistories.details = results;
            state.asinHistories.totalCount = count;
            state.asinHistories.loading = false;
            state.fetched = true;
            return state;
        },
        updateAsinHistory: (state, actions) => {
            state.asinHistories.loading = true;
            return state;
        },
        updateAsinHistorySuccess: (state, actions) => {
            state.asinHistories.details = state.asinHistories.details
                .map(ah => ah.id === actions.payload.id ? actions.payload : ah);
            state.asinHistories.loading = false;
            return state;
        },

        getAsinProfitReport: (state, actions) => {
            state.asinProfitReport.loading = true;
            return state;
        },
        getAsinProfitReportSuccess: (state, actions) => {
            const {data} = actions.payload;
            state.asinProfitReport.details = data;
            state.asinProfitReport.loading = false;
            return state;
        },
    }
});

export const actions = common.actions;
export const reducer = common.reducer;

export function* saga() {
    yield takeLatest(actions.getAsinHisties, function* getAsinHistiesSaga({payload}) {
        try {
            const params = payload || {};
            const {data} = yield call(api.getAsinHisties, params);
            data
                ? yield put(actions.getAsinHistiesSuccess(data))
                : yield put(actions.stopLoading({type: "asinHistories"}));
        } catch (err) {
            yield put(actions.stopLoading({type: "asinHistories"}));
            console.log(err);
        }
    });

    yield takeLatest(actions.updateAsinHistory, function* updateAsinHistorySaga({payload: {jorneyId, shipmentId, values}}) {
        try {
            const {data} = yield call(api.updateAsinHistory, jorneyId, shipmentId, values);
            data
              ? yield put(actions.updateAsinHistorySuccess(data))
              : yield put(actions.stopLoading({type: "asinHistories"}));
        } catch (err) {
            yield put(actions.stopLoading({type: "asinHistories"}));
            console.log(err);
        }
    });

    yield takeLatest(actions.getAsinProfitReport, function* getAsinProfitReportSaga({payload}) {
        try {
            const params = payload || {};
            const {data} = yield call(apiReports.getAsinProfitReport, params);
            data
              ? yield put(actions.getAsinProfitReportSuccess({data}))
              : yield put(actions.stopLoading({type: "asinProfitReport"}));
        } catch (err) {
            yield put(actions.stopLoading({type: "asinProfitReport"}));
            console.log(err);
        }
    });
}
