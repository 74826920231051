import React, { useState } from 'react';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import { Paper } from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import classNames from "clsx";

const PopperMenu = ({ contentBtn, popperProps, styleEl }) => {
  const { array=[], setFunc, type, disabled, className, placement } = popperProps;
  const [anchorEl, setAnchorEl] = useState(null);

  function handleClick(event) {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  }

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const handleChangeCheckbox = (event, row) => {
    const checked = event.target.checked;
    setFunc(activeColumns => (
      activeColumns.map(activeColumn => {
        if (activeColumn.id === row.id) {
          activeColumn.isActive = checked
        }
        return activeColumn
      })
    ))
  };

  return (
    <div className={classNames("popper-menu", styleEl)}>
      <div onClick={handleClick} className={classNames("popper-menu__action-wrap", { active: open })}>{contentBtn}</div>
      <Popper id={id} open={open} anchorEl={anchorEl} transition className={className} placement={placement}>
        {({TransitionProps}) => (
          <Fade {...TransitionProps} timeout={100}>
            <Paper>
              {!type && array.map(({ func, content, selected }, index) =>
                <MenuItem key={index} onClick={func} className="classNameChild" selected={selected} >{content}</MenuItem>
              )}
              <ClickAwayListener onClickAway={handleClickAway}>
                <div>
                  {type === "filter" &&
                  <FormGroup>{array.map((item) => {
                    const {isActive, id, label} = item;
                    const disabledItem = disabled && isActive === true && array.filter(el => el.isActive).length === 1;
                    return (
                      <FormControlLabel
                        key={item.id}
                        control={<Checkbox
                          checked={id && isActive}
                          disabled={disabledItem}
                          color="primary"
                          onChange={(event) => handleChangeCheckbox(event, item)}
                        />}
                        label={label}
                      />
                    )
                  })}
                  </FormGroup>
                  }
                </div>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

export default PopperMenu;