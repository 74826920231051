import React, {useEffect} from "react";
import * as auth from "../../store/ducks/auth.duck";
import { connect } from "react-redux";
import {Redirect} from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";

const Logout = ({hasAuthToken, user, logout}) => {
  useEffect(() => {
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return hasAuthToken
    ? <LayoutSplashScreen />
    : <Redirect to={{pathname: "/auth/login"}} />

};

const mapStateToProps = (state) => ({
  hasAuthToken: Boolean(state.auth.authToken),
});

const mapDispatchToProps = {
  logout: auth.actions.logout,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Logout)