import React from 'react';
import Modal from "react-bootstrap/Modal";
import DButton from "../../../components/Button";
import * as PropTypes from "prop-types";
import {Link} from "react-router-dom";

const InactiveModal = ({ show, onClose }) => {
  return (
    <Modal show={show} onHide={onClose} className="modal--small edit-add__modal--inactive">
      <Modal.Header closeButton>
        <Modal.Title>Are you sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        All recurring transactions for this customer will be paused.
        You can review the status of your recurring transactions
        on the
        <Link className="edit-add__modal__link" to="/books/tools/recurring-transactions">Recurring Transactions</Link>
        page.
      </Modal.Body>
      <Modal.Footer>
        <div className="container-button container-button--modal">
          <DButton typeOfButton="defaultSecondary" onClickCustom={onClose}>
            Pause All Recurring Transactions
          </DButton>
          <DButton onClickCustom={onClose}>Keep Status Active</DButton>
        </div>
      </Modal.Footer>
    </Modal>
  )
};

InactiveModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default InactiveModal;