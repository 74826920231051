import { createSelector } from "reselect";

export const getProducts = createSelector(
  state => state.product,
  (product) => product.products
);

export const getProductsSelector = createSelector(
  state => state.product,
  (product) => product
);

export const getImportsProducts = createSelector(
  state => state.product,
  (product) => product.imports
);

export const addImportsCOG = createSelector(
  state => state.product,
  (product) => product.noteImportsCOG
);

export const getReimbursementProducts = createSelector(
    state => state.product,
    (product) => product.reimbursements,
);
// export const getAsinHistories = createSelector(
//     state => state.product,
//     (product) => product.asinHistories,
// );

export const getReconciliation = createSelector(
    state => state.product,
    (product) => product.reconciliation,
);

export const getRaiseCase = createSelector(
    state => state.product,
    (product) => product.raiseCase,
);