import React from "react";
import LabelTab from "../../../components/DTabs/LabelTab";
import Help from "../Help";

export const helpTabs = [
  {
    value: "get_started",
    label: <LabelTab title="Get started"/>,
    content: <Help />,
  },
  {
    value: "login_access",
    label: <LabelTab title="Login & access"/>,
    content: <Help />,
  },
  {
    value: "billing_payments",
    label: <LabelTab title="Billing & payments"/>,
    content: <Help />,
  },
  {
    value: "my_benefits",
    label: <LabelTab title="My benefits" />,
    content: <Help />,
  },
  {
    value: "account_settings",
    label: <LabelTab title="Account settings"/>,
    content: <Help />,
  },
];