import React from 'react';
import * as PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import DButton from "../../components/Button";
import Typography from "@material-ui/core/Typography";
import DateTimeField from "../../components/controls/DateTimeField";
import TableComponent from "../../components/TableComponent/TableComponent";
import Alert from "react-bootstrap/Alert";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

function VATDeRegistrationModal({ show, onClose }) {
  const form = [
    {
      id: 1,
      name: 'one_vat_return_present',
      label: 'At least one VAT Return present?',
      value: 1,
    },
    {
      id: 2,
      name: 'vat_returns_locked',
      label: 'All VAT Returns locked?',
      value: 0,
    },
    {
      id: 3,
      name: 'final_vat_return',
      label: 'Final VAT Return Completed?',
      value: 0,
    },
    {
      id: 4,
      name: 'vat_money_on_after_de_registration_date',
      value: 0,
      label: 'No VAT invoices or money in/out on or after de-registration date?',
    },
    {
      id: 5,
      name: 'ec_sales_lists_complete',
      label: 'All EC sales lists complete?',
      value: 1,
    },
  ];

  const headRows = [
    {id: 'label', numeric: false, disablePadding: true,},
    {
      id: 'value', numeric: false, disablePadding: true,
      renderText: (row) => {
        const getIcon = () => row.value === 0
          ? <CancelIcon color="error" fontSize="large"/> // no confirmed
          : <CheckCircleIcon color="secondary" fontSize="large"/>; // confirmed
        return <div>{getIcon()}</div>
      }
    }
  ];
  // const onSubmit = (values, { setSubmitting, }) => {
  //   const data = {...values};
  //   const onDone = () => {
  //     setSubmitting(false);
  //     onClose();
  //   };
  //   const onError = (error) => {
  //     alert(error);
  //     setSubmitting(false);
  //   };
  // deRegistrationVAT({
  //     id: initValues.id,
  //     values: data,
  //     onDone,
  //     onError,
  //   });
  // };
  const noConfirmed = form.find(item => item.value === 0);
  return <Modal show={show} onHide={onClose} className="modal--deregister modal--small">
    <Modal.Header closeButton>
      <div className="modal--deregister__title">
        <Typography variant="h6">VAT De-registration</Typography>
        <div className="modal--deregister__subtitle">
          <Typography variant="body1">Date of VAT de-registration:</Typography>
          <DateTimeField
            id="date_de_registration"
            label=""
            name="date_de_registration"
            className="modal__date-picker"
            disabled={noConfirmed && true}
            // setFieldValue={setFieldValue}
          />
        </div>
      </div>
    </Modal.Header>
    <Modal.Body>
      <Typography variant="subtitle1" className="modal--deregister__title--content">
        All of the following conditions must be met before you can de-register for VAT:
      </Typography>

      <TableComponent
        className="table--deregister"
        headRows={headRows}
        items={form}
        isPagination={false}
        isHeader={false}
      />
      {
        noConfirmed
        && <Alert variant="warning" className="modal__alert">
          <span className="modal__alert__span">Warning:</span>
          Your recurring transactions with VAT tax codes will be paused.
          You will need to edit them to have taxcode NV if you wish to unpause them.
        </Alert>
      }
    </Modal.Body>
    <Modal.Footer>
      <div className="container-button container-button--modal">
        <DButton typeOfButton="defaultSecondary" disabled={true}>Deregister</DButton>
        <DButton typeOfButton="close" onClickCustom={onClose}>Close</DButton>
      </div>
    </Modal.Footer>
  </Modal>
}

VATDeRegistrationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};


export default VATDeRegistrationModal;