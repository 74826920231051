import React, {forwardRef, useState} from "react";
import {formatNumber, replaceIndex} from "../../../home/utils";
import {FormControl, FormHelperText, Input} from "@material-ui/core";
import NumberFormat from 'react-number-format';
import classNames from 'classnames'
import TextField from "@material-ui/core/TextField";

const PercentField = forwardRef((props, ref) => <NumberFormat {...props} />);

const RNumberPercent = allProps => {
  const {
    name,
    setFieldValue,
    setFieldTouched,
    // placeholder,
    label,
    meta,
    showLabel,
    customProps = {},
    submitCount,
    autofocus,
    customInput,
    ...props
  } = allProps;

  const [showOptions, setOptions] = useState(false);
  // const percentField = forwardRef(null);

  const {
    beforeField,
    onChange,
    type_number,
    noteField,
    suffixValue,
    prefixValue,
    options,
    placeholder = "0.00",
    NonVatable,
    ...otherProps
  } = customProps;

  let val = props.value;
  if (!val && val !== "") {
    if (type_number === "integer") {
      val = "0"
    } else {
      val = "0.00"
    }
  }
  const handleChangeSecond = (event) => {
    let value = event.target.value;
    if (onChange) {
      onChange(event, val);
    } else {
      if (prefixValue || suffixValue) {
        value = replaceIndex(value)
      }
      setFieldValue(name, formatNumber(value, val));
    }
  };
  const handleFocus = (event) => {

  };
  const handleBlur = () => {
    setFieldTouched(name, true);
  };

  const clickOption = (value) => {
    if (onChange) {
      onChange(value, true);
    } else {
      if (!value) {
        let inputField = document.querySelector(`input[name="${name}"]`);
        inputField.focus();
      }
      setFieldValue(name, value);
    }
    setOptions(false);
  };

  const optionsArray = options.map(item => (
      <li key={item.label} onClickCapture={() => clickOption(item.value)}>
        {item.label}
      </li>
    )
  );

  return (
    <FormControl
      error={!!(meta && (meta.touched || submitCount) && meta.error)}
      fullWidth
      margin="dense"
    >
      <div className="form-group__wrap-number-format">
        {beforeField && <span className="form-group__before-field">{beforeField}</span>}
        {NonVatable
          ? (
            <Input
              {...props}
              prefix={prefixValue}
              suffix={suffixValue}
              autoFocus={autofocus}
              {...otherProps}
              id={name}
              name={name}
              type="text"
              label={label}
              placeholder={placeholder}
              onChange={handleChangeSecond}
              onBlur={handleBlur}
              onFocus={handleFocus}
              value={NonVatable ? 'NonVatable' : val}
              className={classNames({"number-input--error": meta && (meta.touched || submitCount) && meta.error})}
            />
          )
          : (
            <PercentField
              {...props}
              prefix={prefixValue}
              suffix={'%'}
              autoFocus={autofocus}
              thousandSeparator=","
              decimalSeparator="."
              fixedDecimalScale={true}
              {...otherProps}
              id={name}
              name={name}
              type="text"
              label={label}
              placeholder={placeholder}
              onClick={() => {
                setOptions(true)
              }}
              onBlur={handleBlur}
              onFocus={handleFocus}
              value={val}
              customInput={TextField}
              className={classNames({"number-input--error": meta && (meta.touched || submitCount) && meta.error})}
            />
          )}

        {meta && (meta.touched || submitCount) && meta.error && (
          <FormHelperText error id="component-error-text">
            {meta.error}
          </FormHelperText>
        )}
        {showOptions && <div className="helper-options-for-RNumberPercent">
          <ul>{optionsArray}</ul>
        </div>}
        {noteField && <span className="form-group__note-field">{noteField}</span>}
      </div>
    </FormControl>
  )
};

export default RNumberPercent;