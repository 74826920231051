import React from "react";
import {FormControl, FormHelperText, Input, InputLabel, OutlinedInput} from "@material-ui/core";

const NumberField = allProps => {
  const {
    name,
    setFieldValue,
    setFieldTouched,
    placeholder,
    label,
    meta,
    showLabel,
    customProps = {},
    submitCount,
    autofocus,
    ...props
  } = allProps;

  const {
    beforeField,
    onChange,
    type_number,
    noteField,
    empty,
    onBlur,
    variant = 'contained',
    ...otherProps
  } = customProps;

  const handleChange = (event) => {
    if (onChange) {
      onChange(event);
    } else {
      setFieldValue(name, event.target.value);
    }
  };
  const handleBlur = (event) => {
    if (onBlur) {
      onBlur(event)
    }
    setFieldTouched(name, true);
  };

  let val = props.value;
  if (!val && val !== "" && !empty) {
    if (type_number === "integer") {
      val = "0"
    } else {
      val = "0.00"
    }
  }
  const Component = variant === 'outlined' ? OutlinedInput : Input;
  return (
    <FormControl
      error={!!(meta && (meta.touched || submitCount) && meta.error)}
      fullWidth
      margin="dense"
    >
      <InputLabel htmlFor="component-error" margin="dense">{label}</InputLabel>

      {beforeField && <span className="form-group__before-field">{beforeField}</span>}
      <Component
        {...props}
        {...otherProps}
        autoFocus={autofocus}
        id={name}
        name={name}
        type="number"
        label={label}
        inputProps={{step: type_number === "integer" ? 1.00 : 0.01}}
        placeholder={placeholder}
        aria-describedby="component-error-text"
        onChange={handleChange}
        onBlur={handleBlur}
        value={val}
      />
      {meta && (meta.touched || submitCount) && meta.error && (
        <FormHelperText error id="component-error-text">
          {meta.error}
        </FormHelperText>
      )}
      {noteField && <span className="form-group__note-field">{noteField}</span>}
    </FormControl>
  )
};

export default NumberField;