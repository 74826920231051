import {requestPassword} from "../../crud/auth.crud";

export const validate = (intl) => values => {
  const errors = {};

  if (!values.email) {
    errors.email = intl.formatMessage({
      id: "AUTH.VALIDATION.REQUIRED_FIELD"
    });
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
  ) {
    errors.email = intl.formatMessage({
      id: "AUTH.VALIDATION.INVALID_FIELD"
    });
  }

  return errors;
}

export const handleSubmit = ({setIsRequested, intl}) => async (values, {setStatus, setSubmitting}) => {
  setIsRequested(false);
  try {
    await requestPassword(values.email);
    setIsRequested(true);
    setStatus(null)
  } catch (e) {
    console.log({e})
    setSubmitting(false);
    setStatus(
      intl.formatMessage(
        {id: "AUTH.VALIDATION.NOT_FOUND"},
        {name: values.email}
      )
    );
  }
};
