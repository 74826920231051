import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import api from "../../crud/bank.crud";
import { createSlice } from "@reduxjs/toolkit";
import {shortBaseState} from "./utils";

const company = createSlice({
    name: 'suppliers',
    initialState: {
      loading: true,
      suppliers: shortBaseState
    },
    reducers: {
      stopLoading: (state, actions) => {
        state.suppliers.loading = false;
        return state;
      },
      getSuppliers: (state, actions) => {
        state.suppliers.loading = true;
        return state;
      },
      getSuppliersSuccess: (state, actions) => {
        const { suppliers } = actions.payload;
        state.suppliers.details = suppliers;
        state.suppliers.loading = false;
        return state;
      },
      addSupplier: (state, actions) => {
        state.suppliers.loading = true;
        return state;
      },
      addSupplierSuccess: (state, actions) => {
        const { supplier } = actions.payload;
        state.suppliers.details = [...state.suppliers.details, supplier];
        state.suppliers.loading = false;
        return state;
      },
      deleteSupplier: (state, actions) => {
        state.suppliers.loading = true;
        return state;
      },
      deleteSupplierSuccess: (state, actions) => {
        const { id } = actions.payload;
        state.suppliers.details = state.suppliers.details.filter(supplier => supplier.id !== id);
        state.suppliers.loading = false;
        return state;
      },
      updateSupplier: (state, actions) => {
        state.suppliers.loading = true;
        return state;
      },
      updateSupplierSuccess: (state, actions) => {
        state.suppliers.details = state.suppliers.details.map(supplier => {
          if (supplier.id === actions.payload.id) {
            return actions.payload.suppliers;
          }
          return supplier
        });
        state.suppliers.loading = false;
        return state;
      },
    },
  }
);

export const actions = company.actions;
export const reducer = company.reducer;

export function* saga() {
  yield takeLatest(actions.getSuppliers, function* getSupplierSaga({ payload }) {
    try {
      let q = payload && payload.q;
      const { data } = yield call(api.getSuppliers, q );
      data
        ? yield put(actions.getSuppliersSuccess({ suppliers: data }))
        : yield put(actions.stopLoading());
    } catch (err) {
      yield put(actions.stopLoading());
      console.log(err);
    }
  });

  yield takeLatest(actions.addSupplier, function* addSupplierSaga({ payload: { values, onDone, onError } }) {
    try {
      const { data, ...props } = yield call(api.addSupplier, values);
      if (data) {
        yield put(actions.addSupplierSuccess({ supplier: data }));
        onDone();
      } else {
        yield put(actions.stopLoading());
        onError(props);
      }
    } catch (err) {
      yield put(actions.stopLoading());
      onError(err);
      console.log(err);
    }
  });

  yield takeLatest(actions.deleteSupplier, function* deleteSupplierSaga({ payload: { id, onDone, onError } }) {
    try {
      yield call(api.deleteSupplier, id);
      yield put(actions.deleteSupplierSuccess({ id }));
      if (onDone)
        onDone();
    } catch (err) {
      yield put(actions.stopLoading());
      onError(err);
      console.log(err);
    }
  });

  yield takeLatest(actions.updateSupplier, function* updateSupplierSaga({ payload: { id, values, onDone, onError } }) {
    try {
      const { data, ...props } = yield call(api.updateSupplier, id, values);
      if (data) {
        yield put(actions.updateSupplierSuccess({ suppliers: data, id }));
        onDone();
      } else {
        yield put(actions.stopLoading());
        onError(props);
      }
    } catch (err) {
      yield put(actions.stopLoading());
      onError(err);
      console.log(err);
    }
  });
}
