import React, {useState} from 'react';
import Modal from "react-bootstrap/Modal";
import * as PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import Typography from '@material-ui/core/Typography';
import UploadFileDropzone from "../UploadFileDropzone";
import DButton from "../Button";
import FileComponent from "./FileComponent";

function TabContainer(props) {
  return (
    <Typography component="div" style={{padding: 8}} className="upload-file__tab">
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const FileList = ({files, updateBankFile, bankId}) => {
  return files && files.map((file) =>
    <FileComponent key={file.id} file={file} updateBankFile={updateBankFile} bankId={bankId}/>)
};

export default function ReceiptUploadsModal({addBankFile, updateBankFile, bankFiles, bankId, show, onClose}) {
  const [value, setValue] = useState("link_existing_file");
  const attachedFiles = bankFiles && bankFiles.filter((file) => file.bank_account === bankId);
  const existingFiles = bankFiles && bankFiles.filter((file) => !file.bank_account);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  const fileUpload = (bankId, file) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('bank_account', bankId);
    return addBankFile({ values: formData });
  };

  const quantityExistingFiles = existingFiles ? `(${existingFiles.length})` : ``;
  const quantityAttachedFiles = attachedFiles ? `(${attachedFiles.length})` : ``;

  return (
    <Modal show={show} onHide={onClose} className="modal--receipt-uploads">
      <Modal.Header closeButton>
        <Modal.Title>
          Receipt Uploads
          <h6 className="modal-title__sub-title">
            From here you can upload a new file from your device,
            link this transaction to an existing file,
            or view a list of files already attached to this transaction.
          </h6>
        </Modal.Title>

      </Modal.Header>
      <Modal.Body>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab value="link_existing_file" label={`Link To An Existing File ` + quantityExistingFiles}/>
            <Tab value="upload_new_file" label="Upload A New File"/>
            <Tab value="existing_attachments" label={`View Existing Attachments ` + quantityAttachedFiles}/>
          </Tabs>
        </AppBar>
        {value === "link_existing_file" && existingFiles && (
          <TabContainer>
            <FileList files={existingFiles} updateBankFile={updateBankFile} bankId={bankId}/>
          </TabContainer>
        )}
        {value === "upload_new_file" && <TabContainer>
          <UploadFileDropzone acceptedFiles={['.png', '.jpg', '.pdf']}
                              fileUpload={(files) => fileUpload(bankId, files[0])}/>
        </TabContainer>}
        {value === "existing_attachments" && attachedFiles && (
          <TabContainer>
            <FileList files={attachedFiles} updateBankFile={updateBankFile} bankId={bankId}/>
          </TabContainer>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="container-button container-button--modal">
          <DButton typeOfButton="defaultSecondary" onClickCustom={onClose}>
            Back to Top
          </DButton>
          <DButton typeOfButton="doneAll" onClickCustom={onClose}>
            Done
          </DButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

ReceiptUploadsModal.propTypes = {
  bankId: PropTypes.number,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  bankFiles: PropTypes.array,
  updateBankFile: PropTypes.func,
  addBankFile: PropTypes.func,
};
