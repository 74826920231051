import React from "react";
import { Button, Typography } from "@material-ui/core";
import classNames from "clsx";
import * as PropTypes from "prop-types";
import FormField from "../../../components/controls/FormField";
import Form from "react-bootstrap/Form";
import IconButton from "@material-ui/core/IconButton";
import {ReactComponent as AddIcon} from "../../../../media/icons/add-without-border.svg";
import {ReactComponent as RemoveIcon} from "../../../../media/icons/remove-without-border.svg";

export const Headers = ({ currentTab, currency }) => (
  <div className="forms__headers forms__headers--hide">
    <div className="forms__headers__field-header">
      <Typography variant="h6" className="forms__headers--name">Name</Typography>
      {currentTab === "sfp"
        ? <Typography variant="h6" className="forms__headers--description">Description</Typography>
        : <>
            <Typography variant="h6" className="forms__headers--height size">Height(cm)</Typography>
            <Typography variant="h6" className="forms__headers--length size">Length(cm)</Typography>
            <Typography variant="h6" className="forms__headers--width size">Width(cm)</Typography>
            <Typography variant="h6" className="forms__headers--weight size">Weight(kg)</Typography>
          </>
      }
      <Typography variant="h6" className="forms__headers--amount size">{`Amount(${currency})`}</Typography>
      <Typography variant="h6" className="forms__headers--action">Action</Typography>
    </div>
  </div>
);

export const Buttons = ({ form }) => (
  <div className="settings__wrap-btn">
    <Button
      className="settings__btn"
      variant="contained"
      type="submit"
      disabled={form.isSubmitting}
    >
      Update
    </Button>
  </div>
);

export const LineArray = (props) => {
  const {
    interfaceArray,
    dataName,
    arrayHelpers,
    index,
    values,
    form,
    onAction,
  } = props;
  const lastIndex = form.values[dataName].length - 1;
  return (
    <div key={index} className={classNames("forms__line multiline", {"first-line": !index})}>
      {interfaceArray.map((item, i) => {
        const name = `${dataName}.${index}.${item.id}`;
        const isSize = dataName === "fbm" && (i > 0 && i < interfaceArray.length - 1);
        const isValues = interfaceArray.some(({ id }) => values[id]);
        return (
          <Form.Group
            key={name}
            controlId={name}
            className={classNames(`form-group--${name}`, {'size': isSize})}
          >
            <Form.Label className={classNames("form-group__label-item", {"forms__headers--item": !index})} column={false}>{item.label}</Form.Label>

            <FormField
              name={name}
              label={item.label}
              type={item.type}
              customProps={item.customProps}
              extraProps={{ index, arrayHelpers, lastIndex, onAction }}
              validate={isValues ? item.validator : null}
              render={item.render}
              {...form}
            />
          </Form.Group>
        )
      })}

    </div>
  )
};

LineArray.propTypes = {
  interfaceArray: PropTypes.array,
  dataName: PropTypes.string,
  arrayHelpers: PropTypes.object,
  index: PropTypes.number,
  values: PropTypes.object,
  form: PropTypes.object,
  onAction: PropTypes.func,
};