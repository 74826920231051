import React, { useEffect, useState } from "react";
import ChartSection from "./ChartSection/ChartSection";
import useChart from "../../../hooks/chart.hook";
import moment from "moment";
import OrdersRefunds from "./OrdersRefunds/OrdersRefunds";
import { dateOptions } from "./ChartSection/dataChart";
import DSplashScreen from "../../components/SplashScreen/DSplashScreen";
import MarketplacesTotals from "./MarketplacesTotals/MarketplacesTotals";
import WinnersAndLosers from "./WinnersAndLosers/WinnersAndLosers";
import useOrders from "../../../hooks/orders.hook";
import { getDefaultMarketplace, momentFormatOptions } from "../utils";
import useDashboardTable from "../../../hooks/dashboardTable.hook";
import useDashboardTiles from "../../../hooks/dashboardTiles.hook";
import { Empty, keysToCheck, onSubmitImportWizard } from "./components/utils";
import { useHistory } from "react-router";
import Wizard from "../../components/SWizard";
import { wizardData } from "../../components/SWizard/utils";
import useProducts from "../../../hooks/products.hook";
import UploadFileScreen from "../../components/UploadFileScreen";
import useSettings from "../../../hooks/settings.hook";
import useUsers from "../../../hooks/users.hook";
import {Redirect} from "react-router-dom";

export const date = time =>
  time &&
  moment()
    .subtract(time, "days")
    .format(momentFormatOptions.backend);

export default function Dashboard({ location }) {
  const history = useHistory();
  const [chartData, setChartData] = useState({});
  const [marketplacesData, setMarketplacesData] = useState([]);
  const [isSync, setSync] = useState('');
  const [isWizard, setWizard] = useState(false);
  const onWizard = state => setWizard(state);

  const { addImportsCOG } = useProducts();
  const [rows, setRows] = useState([]);

  const [time, setTime] = useState(dateOptions.find(i => i.default));
  const [marketplace, setMarketplace] = useState(getDefaultMarketplace());
  const getCurrentPeriod = item => item.period;

  const { user } = useUsers({ fetch: false });
  const { synchronization_in_standby_mode } = user;

  const propsChart = {
    start_date: date(time.value),
    period: getCurrentPeriod(time),
    marketplace,
    autoRefresh: false
  };

  const dashboardTilesProps = {
    start_date: propsChart.start_date,
    period: propsChart.period,
    end_date: time.end_date,
    marketplace
  };

  const { loading, chart, refresh, by_marketplaces, haveInvalidProfit, haveInvalidSales } = useChart(propsChart);
  const { expenses, expensesLoading, resaleValue, refresh: refreshDashboardTiles } = useDashboardTiles(dashboardTilesProps);
  const { orders, refunds, loadingOrders, getOrders } = useOrders();
  const { getWinners, getLosers } = useDashboardTable();
  const { onWalk, firstOnWalk, firstWalk } = useSettings({ fetch: false });

  useEffect(() => {
    if (expenses?.by_marketplaces?.length || chart?.inventoryCostMarketplaces?.length) {
      const allMarketplacesData = by_marketplaces.map((market) => {
        const expensesMarket = expenses?.by_marketplaces?.length
          ? expenses.by_marketplaces.find(({ marketplace }) => marketplace === market.marketplace)
          : []
        const inventoryCostMarket = chart?.inventoryCostMarketplaces.length
          ? chart?.inventoryCostMarketplaces.find(({ marketplace }) => marketplace === market.marketplace)
          : []
        return {...market, ...expensesMarket, ...inventoryCostMarket}
      });

      setMarketplacesData(allMarketplacesData);
    }
  }, [chart, expenses, chart?.inventoryCostMarketplaces]);

  useEffect(() => {
    setChartData({
      ...expenses,
      ...chart,
      chart_data: {
        ...expenses.chart_data,
        ...chart.chart_data,
      },
    })
  }, [chart, expenses]);

  useEffect(() => {
    firstOnWalk();
    let from = history?.location?.state?.from;
    if (from) {
      setSync(from);
      setTimeout(() => setSync(""), 5000);
    }
  }, []);

  useEffect(() => {
    if (firstWalk === "start") {
      let walkTimeoutId = null;
      walkTimeoutId = setTimeout(() => {
        onWalk();
        walkTimeoutId = null;
        firstOnWalk();
      }, 5000);
      return () => {
        if (walkTimeoutId) {
          clearTimeout(walkTimeoutId);
        }
      };
    }
  }, [firstWalk]);

  let activeMarketplaces = marketplacesData.slice(0,-1).filter(
      row => Object.values(
          Object.fromEntries(Object.entries(row).filter(([k, _]) => keysToCheck.includes(k)))
      ).some(v => !!Number(v))
  ).concat(marketplacesData.slice(-1))

  const onSelectedPeriod = value => {
    let period = getCurrentPeriod(value);
    if (value.value === "custom") {
      return;
    }
    setTime(value);
    refresh({ ...propsChart, period, start_date: date(value.value) });
    refreshDashboardTiles({
      ...dashboardTilesProps,
      period,
      start_date: date(value.value)
    });
  };

  const onSelectedMarketplace = market => {
    const marketplace = market;
    if (time.label === "Custom") {
      refresh({
        ...propsChart,
        start_date: time.start_date,
        end_date: time.end_date,
        marketplace
      });
      refreshDashboardTiles({
        ...dashboardTilesProps,
        start_date: time.start_date,
        end_date: time.end_date,
        marketplace
      });
    } else {
      refresh({ ...propsChart, marketplace });
      refreshDashboardTiles({ ...dashboardTilesProps, marketplace });
    }
    setMarketplace(marketplace);
  };

  const onCustomPeriod = period => {
    setTime({
      label: "Custom",
      start_date: period.startDate,
      end_date: period.endDate,
      period: "custom"
    });
    refresh({
      ...propsChart,
      period: "custom",
      start_date: period.startDate,
      end_date: period.endDate
    });
    refreshDashboardTiles({
      ...dashboardTilesProps,
      period: "custom",
      start_date: period.startDate,
      end_date: period.endDate
    });
  };
  const refreshPage = extraParams => {
    const refreshParams =
      getCurrentPeriod(time) === "custom"
        ? {
            ...propsChart,
            start_date: time.start_date,
            end_date: time.end_date
          }
        : propsChart;

    const refreshDashboardTilesParams =
      getCurrentPeriod(time) === "custom"
        ? {
            ...dashboardTilesProps,
            start_date: time.start_date,
            end_date: time.end_date
          }
        : dashboardTilesProps;

    refresh({ ...refreshParams, ...extraParams });
    refreshDashboardTiles({ ...refreshDashboardTilesParams, ...extraParams });
    getOrders(extraParams);
    getWinners({ limit: 10, ...extraParams });
    getLosers({ limit: 10, ...extraParams });
  };

  let dashboardTilesData = {
    inventory_cost: chart.inventory_cost,
    inventory_cost_warning: chart.inventoryCostWarning,
    inventory_cost_compared: chart.inventory_cost_compared,
    inventory_quantity: chart.inventory_cost_units,
    resale_value: resaleValue,
    resale_value_compared: 0
  };

  if (isSync) return <Empty />;

  return synchronization_in_standby_mode ? (
    <Redirect to={{ pathname: "/" }} />
  ) : (
    <div className="dashboard">
      <div className="d-splash-screen--wrap">
        {(loading || expensesLoading) && <DSplashScreen/>}
        <Wizard
          open={isWizard}
          onClose={() => onWizard(false)}
          list={wizardData({
            uploadComponent: (
              <UploadFileScreen
                className="wizard__import"
                setRows={setRows}
                noParse={true}
                dropzoneText={`Drag and drop or ${"\n"}browse from computer`}
              />
            ),
            onSubmitImportWizard: onSubmitImportWizard({ rows, addImportsCOG })
          })}
        />
        <ChartSection
          activeMarketplaces={activeMarketplaces}
          haveInvalidProfit={haveInvalidProfit}
          haveInvalidSales={haveInvalidSales}
          chart={chartData}
          onSelectedPeriod={onSelectedPeriod}
          time={time}
          onCustomPeriod={onCustomPeriod}
          onSelectedMarketplace={onSelectedMarketplace}
          dashboardTilesData={dashboardTilesData}
          expenses={expenses}
        />
        <MarketplacesTotals data={activeMarketplaces} loading={loading} />
      </div>
      <WinnersAndLosers />
      <OrdersRefunds
        loading={loadingOrders}
        orders={orders}
        refunds={refunds}
        disabled
        isPagination={false}
        refreshPage={refreshPage}
        location={location}
      />
    </div>
  );
}
