import React, {useState, useEffect} from 'react'
import {Select, Typography, MenuItem, FormControlLabel, Button} from '@material-ui/core'
import {rois} from './utils'
import Form from 'react-bootstrap/Form'
import FormField from '../../../../components/controls/FormField'
import styles from '../styles.module.scss'
import DSplashScreen from "../../../../components/SplashScreen/DSplashScreen";
import {textAlertROI, TYPES} from "../utils";
import {useModal} from "../../../../../hooks/modal.hook";
import AlertDialog from "../../../../components/AlertDialog/AlertDialog";
import {actionsCOG} from "../../../Dashboard/OrdersRefunds/components";
import useSettings from "../../../../../hooks/settings.hook";

const ROISettings = props => {
  const {
    updateROISetting,
  } = useSettings({ fetch: false });
  let { form, loading, setSubmitCustomProps, initData } = props;
  const { values } = form;
  const [roi, setRoi] = useState('');
  const [openDialog, toggleDialog] = useModal();
  const [updateData, setUpdateData] = useState({});

  const [roisText, setRoisText] = useState("");

  useEffect(() => {
    !!values.roi_method && setRoi(values.roi_method)
  }, [values.roi_method]);

  useEffect(() => {
    if (roi) {
      let description = rois[roi].vat_exempt_on_amazon;

      if (values?.global_vat_current?.[0].is_vat_registered) {
        const currentSchemeName = values?.schemes_current?.[0]?.scheme_name?.value;
        const newSchemeName = values?.schemes_new?.[0]?.scheme_name?.value;
        const checkSchemeName = newSchemeName ? newSchemeName : currentSchemeName;

        if (checkSchemeName?.includes('FR')) {
          description = rois[roi].scheme;
        } else {
          description = rois[roi].is_vat_registered;
        }
      }

      setRoisText(description)
    }
  }, [roi, values]);

  const onChangeRoi = (event) => {
    form.setFieldValue('roi_method', event.target.value);
  };

  const handleDialog = (props) => {
    toggleDialog();
    setUpdateData(props);
  };

  const onConfirmDialog = () => {
    toggleDialog();
    updateROISetting(updateData);
  };

  return (
    <div className="settings-main__roi">
      <Typography variant="h5" color="primary" className="settings__subtitle--main bottom-border">
        ROI Calculation
      </Typography>
      <div className="settings-main__vat__form">
        <div className="d-splash-screen--wrap">
          {loading && <DSplashScreen/>}
          <div className="form">
            <div className="form__content">
              <FormControlLabel
                labelPlacement='start'
                control={
                  <Select
                    labelId="demo1-simple-select-filled-label"
                    id="demo1-simple-select-filled"
                    value={roi}
                    onChange={onChangeRoi}
                    className={`${styles.test}`}
                  >
                    {
                      Object.keys(rois).map(
                        (item, index) =>
                          <MenuItem
                            key={index}
                            disabled={rois[item].disabled}
                            className={styles.inner_test}
                            value={item}
                          >
                            {rois[item].title}
                          </MenuItem>
                      )
                    }
                  </Select>
                }
                label={<span className="form__content__select-title">ROI Method</span>}
              />

              <Form.Group controlId="roi_method" className="form-group--roi_method">
                <FormField
                  name="roi_method"
                  label="ROI method"
                  render={(registered) =>
                    <div className="wrap">
                      <div className="text1">
                        {roisText.text1}
                      </div>
                      <div className="divider">
                        <div className="number">
                          {roisText.text3}
                        </div>
                      </div>
                      <div className="text2">
                        {roisText.text2}
                      </div>
                    </div>
                  }
                  {...form}
                />
              </Form.Group>
            </div>
            <div className="settings__wrap-btn">
              <Button
                variant="contained"
                className="settings-main__vat__btn settings__btn"
                type="submit"
                onClick={() => setSubmitCustomProps({
                  type: TYPES.roi,
                  successNote: "ROI calculation will be update soon!",
                  handleDialog,
                  initData
                })}
                disabled={form.isSubmitting}
              >
                Update
              </Button>
            </div>
         </div>
        </div>
      </div>
      <AlertDialog
        className="roi-edit__dialog"
        open={openDialog}
        onClose={toggleDialog}
        actions={actionsCOG({ onClose: toggleDialog, onConfirm: onConfirmDialog })}
        content={textAlertROI({roi_method: form.values?.roi_method, initROI: initData.roi_method})}
      />
    </div>
  )
};

export default ROISettings
