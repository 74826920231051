import { createSelector } from "reselect";

const base = () => state => state.transaction.transactions;

export const selectTransactions = createSelector(
  base(),
  (state) => state.transactions,
);

export const selectTransactionsLoading = createSelector(
  base(),
  (state) => state.transactionsLoading,
);

export const getTransactionsByBank = createSelector(
  base(),
  (state) => state.transactions,
);

export const getDescriptions = createSelector(
  base(),
  (state) => state.descriptions,
);

export const selectLoading = createSelector(
  base(),
  (state) => state.loading,
);
