import {requests, URLSearchParamsSkipNull} from "./utils";

export default {

    getTransactions: (company_id, filters, q) => {
        const params = URLSearchParamsSkipNull({filters, q});
        return requests.get(`/companies/${company_id}/transactions/?${params}`)
    },

    getTransactionsByBank: (company_id, bank_account_id) => requests.get(
        `/companies/${company_id}/bank_accounts/${bank_account_id}/bank_transactions`),
    addTransaction: (company_id, values) => requests.post(
        `/companies/${company_id}/transactions/`, values),
    updateTransaction: (company_id, id, values) => requests.put(
        `/companies/${company_id}/transactions/${id}`, values),
    confirmTransaction: (company_id, id, values) => requests.post(
        `/companies/${company_id}/transactions/${id}/confirm`, values),
    ignoreTransaction: (company_id, id) => requests.del(
        `/companies/${company_id}/transactions/${id}/confirm`),
    deleteTransaction: (company_id, id) => requests.del(
            `/companies/${company_id}/transactions/${id}/ignore`),
    deleteTransactions: (company_id, ids) => requests.del(
            `/companies/${company_id}/transactions/delete?ids=${ids}`),

    descriptionAutocomplete: (companyId, q) => requests.get(`/companies/${companyId}/autocomplete/description/?q=${q}`),
};
