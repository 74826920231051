import React, { useState } from "react";
import Popper from "@material-ui/core/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Fade from "@material-ui/core/Fade";
import { Form as FormikForm, Formik } from "formik";
import Paper from "@material-ui/core/Paper";
import Form from "react-bootstrap/Form";
import useProducts from "../../../../hooks/products.hook";
import toast from "../../../components/toast/toast";
import FormField from "../../../components/controls/FormField";
import DButton from "../../../components/Button";
import classNames from "clsx";

const textCanEdit = `
Your VAT rate change will be updated in the figures shortly as we recalculate profit, ROI and Margin. 
`;

const textCanNotEdit = `
Our VAT rates have been checked and are monitored by Accountants, However if you think this is incorrect 
please tell us by pressing the notify button. We will be notified and we will check.
`;


const defaultVats = [
  { label: "0%", value: 0 },
  { label: "5%", value: 5 },
  { label: "20%", value: 20 },
];

const UserVATRate = ({ row={}, parent, tableName, disabled }) => {
  const { id, product_id, vat_validated } = row;
  const [anchorEl, setAnchorEl] = useState(null);
  const [otherVat, setOtherVat] = useState({ isShow: false, value: null });
  const [activeButton, setActiveButton] = useState(null);
  const { updateProduct } = useProducts({ fetch: false });
  const handleClick = (event) => setAnchorEl(anchorEl ? null : event.currentTarget);
  const handleEditClick = (event) => handleClick(event);
  const close = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const idPopper = open ? 'tab-table__vat__form' : undefined;
  const placement = open ? 'bottom' : undefined;

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    const { user_vat_rate, notified_by_user } = values;
    const onDone = () => {
      setSubmitting(false);
      resetForm();
      close();
    };
    const onError = (error) => {
      setSubmitting(false);
      toast.error(error);
    };
    const data = { user_vat_rate, notified_by_user };

    updateProduct({
      id: tableName === "sales" ? product_id : id,
      values: data,
      type: tableName,
      onDone,
      onError,
    })
  };

  const isValid = (value) => {
    return value >= 0 && value <= 100
  };

  const handleDefuaultVatButtonClick = (vat, form) => () => {
    form.setFieldValue('user_vat_rate', vat.value);
    setOtherVat(val => ({...val, isShow: false}));
    setActiveButton(vat.label)
  };

  const handleOtherVatButtonClick = () => {
    setOtherVat(val => ({...val, isShow: true}));
    setActiveButton('Other')
  }

  const getButtonVariant = (label) => {
    return activeButton === label ? 'contained' : 'outlined';
  }

  const getFormBody = (form) => (
    <div className="tab-table__number__body">
      <Form.Label column={false}>{textCanEdit}</Form.Label>

      <div className="buttons-row">
          {
            defaultVats.map((vat) => (
              <DButton
                key={vat.value}
                type="button" 
                variant={getButtonVariant(vat.label)}
                onClickCustom={handleDefuaultVatButtonClick(vat, form)}
              >{vat.label}</DButton>
            ))
          }
          <DButton 
            type="button" 
            variant={getButtonVariant('Other')}
            onClickCustom={handleOtherVatButtonClick}
          >Other</DButton>
      </div>
      <div className={classNames('buttons-row', {flexEnd: !otherVat.isShow})}>
      {
        otherVat.isShow && (
          <FormField
            name="user_vat_rate"
            label=""
            type="number"
            customProps={{type_number: 'integer', variant: 'outlined'}}
            {...form}
          />
        )
      }
      <DButton type="submit" disabled={!isValid(form.values.user_vat_rate)}>Save</DButton>
      </div>
    </div>
  )

  const handleNotifyButtonClick = (form) => () => {
    onSubmit({notified_by_user: true}, form)
  }

  const canNotEditForm = (form) => (
    <div className="tab-table__number__body">
      <Form.Label column={false}>{textCanNotEdit}</Form.Label>
      <div className="buttons-row">
        <DButton 
            type="button" 
            variant={getButtonVariant('Notify')}
            onClickCustom={handleNotifyButtonClick(form)}
          >Notify Us</DButton>
        <DButton type="button" onClickCustom={close}>Close</DButton>
      </div>
    </div>
  )

  return (
    <>
      {parent({ handleEditClick })}
      <Popper
        id={idPopper}
        open={!disabled && open}
        anchorEl={anchorEl}
        transition
        className="tab-table__note__root"
        placement={placement}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={close}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper className="tab-table__note__paper ">
              <Formik
                enableReinitialize
                initialValues={{ user_vat_rate: undefined, notified_by_user: false }}
                onSubmit={onSubmit}
              >
                {({ ...form }) => (
                  <FormikForm>
                    {
                      vat_validated ? canNotEditForm(form) : getFormBody(form)
                    }
                    </FormikForm>
                )}
              </Formik>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

export default UserVATRate;