import React from "react";
import Avatar from "@material-ui/core/Avatar";
import TableCell from "@material-ui/core/TableCell";
import moment from 'moment';
import WarningCell from "../Dashboard/components/WarningCell";
import NameCell from "../Dashboard/components/NameCell";
import {fulfilmentIcon, tooltipProps, typeReports} from "../Dashboard/components/utils";
import {getNumber, momentFormatOptions, withUnits} from "../utils";
import classNames from "clsx";
import Help from "../Dashboard/components/Help";
import Flag from "../Dashboard/components/Flag";
import FulfillmentCell from "../Dashboard/components/FulfilmentCell";
import {icon as notesIcons} from "../Dashboard/components/NoteTabTable";
import NoteProducts from "./NoteProducts";
import {marketplaceIds, marketplaceNames} from "../../components/Marketplaces/Marketplaces";
import TooltipComponentState from "../../components/TooltipComponentState";
import {Button, Link} from "@material-ui/core";
import TooltipComponent from "../../components/TooltipComponent";
import ContentCell from "./ContentCell";
import {WithTooltip, marketplacesProduct} from "./elements";
import {help} from "./utils";
import {toAbsoluteUrl} from "../../../../_metronic";
import AddInfo from "../Dashboard/components/AddInfo";

export const headRows = [
  {
    id: "image_url",
    label: "Product",
    disabled: true,
    render: (row, {metricsRow, showMetricsRow}) => {
      const {image_url, amazon_product_url, id, full_image_url, restock, relatedProduct, otherMarketplace, isMetricsRow} = row;

      const isParent = !restock && !relatedProduct;
      const main = !relatedProduct && !otherMarketplace;

      return (
        isMetricsRow
          ? row.product_id === showMetricsRow
            ? metricsRow(row)
            : null
          : (
            <TableCell align="left" key={`${id}--photo`} className={classNames("table__cell--photo", {
              "table__cell--child": !main, "table__cell--parent": main,
            })}>
              {isParent && (
                <TooltipComponentState
                  placement="right-start"
                  className="cell--photo__tooltip"
                  isBlur
                  tooltip={
                    <Avatar alt="img" variant="square" src={full_image_url} className="cell__tooltip__photo"/>
                  }
                >
                  <Link target="_blank" href={amazon_product_url}>
                    <Avatar alt="img-product" variant="square" src={image_url}/>
                  </Link>
                </TooltipComponentState>
              )}
            </TableCell>
          )
      )
    },
  },
  {
    id: "item_name",
    // label: '',
    fewLabel: [
      {id: "item_name", label: "", align: "center"},
      {id: "vat_rate", label: "", tooltipPlacement: "bottom-start"},
    ],
    render: (row, {onShowRelated, onShowRestock, contentCellProps, handleDisplayMetrics, showMetricsRow}) => {
      const {
        date_added, item_name, restock, relatedProduct, related,
        restocks, id, asin1, vat_rate, amazon_product_url, seller_sku, marketplaces,
        otherMarketplace, vat_validated, cautions,
        inbound_shipment, isMetricsRow
      } = row;
      let title = item_name;
      const isReplenish = !!restock && !relatedProduct;
      const isMainRestock = isReplenish && !otherMarketplace;
      if (isMainRestock) {
        let dateAdded = moment(date_added).format(momentFormatOptions.display);
        title = `Replenish ${dateAdded}`;
      }
      const withChild = related.length !== 0 || restocks.length !== 0;
      const main = !relatedProduct && !otherMarketplace;

      const errorTooltips = cautions.filter(c => c.priority === 'error');
      return (!isMetricsRow &&
        <NameCell
          url={amazon_product_url}
          sku={seller_sku}
          relatedProduct={relatedProduct}
          restock={restock}
          row={row}
          key={`${id}--name`}
          asin={asin1}
          vat={vat_rate}
          title={title}
          onShowRelated={onShowRelated(row)}
          onShowRestock={onShowRestock(row)}
          marketplaces={related && marketplacesProduct(related)}
          restockMarketplaces={isMainRestock && marketplaces}
          colSpan={2}
          withChild={withChild}
          tableName="products"
          replenish={isReplenish}
          shouldRenderName={main}
          isMainReplenish={isMainRestock}
          vatValidated={vat_validated}
          errors={errorTooltips}
          inbound_shipment={inbound_shipment}
          handleDisplayMetrics={(e) => handleDisplayMetrics(e, row)}
          showMetricsRow={showMetricsRow}
        />
      )
    },
  },
  {
    id: "marketplace",
    label: "Marketplace",
    align: "center",
    render: (row) => {
      const {marketplace} = row;
      const tooltip = marketplace ? marketplaceNames[marketplace] : "";
      return (!row.isMetricsRow &&
        <TableCell
          align="left"
          key={`${row.id}--marketplace`}
          className={classNames("table__cell--marketplace", {"table__cell--child": row.parent})}
        >
          <div className="wrap--marketplace">
            <Flag marketplace={marketplace} placement="left" tooltip={tooltip}/>
          </div>
        </TableCell>
      )
    },
  },
  {
    id: "price",
    label: "Price",
    align: "center",
    helpText: "The total sales price paid by the customer",
    render: (row, {currency, contentCellProps}) => {
      const value = withUnits(currency, row.price);
      let tooltip = "";
      if (marketplaceIds[row.marketplace] !== "UK") {
        if (row.original_price) {
          tooltip = `${withUnits(row.original_price.currency, row.original_price.price)}`;
        }
      }
      return (!row.isMetricsRow &&
        <TableCell align="center" key={`${row.id}--price`} className={classNames("table__cell--price nowrap", {
          "table__cell--child": row.parent,
        })}>
          <TooltipComponent tooltip={tooltip}>
            <div>
              <ContentCell {...contentCellProps} row={row} value={value} isTooltip={false}/>
            </div>
          </TooltipComponent>
        </TableCell>
      )
    }
  },
  {
    id: "quantity",
    label: "Stock",
    align: "center",
    helpText: "The number you have in stock for this ASIN",
    render: (row, {contentCellProps}) => {
      const value = withUnits(undefined, getNumber(row.quantity), "integer");
      const shifted_value = row.quantity_difference ? ` (${row.quantity_difference})` : '';
      return (!row.isMetricsRow &&
        <TableCell align="center" key={`${row.id}--quantity`} className={classNames("table__cell--quantity", {
          "table__cell--child": row.parent,
        })}>
          <ContentCell {...contentCellProps} row={row} value={`${value}${shifted_value}`} isTooltip={false}/>
        </TableCell>
      )
    }
  },
  {
    id: "cog",
    align: "center",
    label: "COG",
    helpText: "Your costs of goods are calculated by adding together your unit cost, " +
      "prep centre, bundle cost, packaging, postage, handling and others as appropriate",
    render: (row, {currency, contentCellProps}) => {
      const value = withUnits(currency, row.cog);
      const noInventoryCost = !row.inventory_cost && row.inventory_cost !== 0;
      const isCOG = row.cog || row.cog === 0;
      const tooltip = "You haven't entered a unit cost so SKUify will be unable to give you accurate financial data";
      return (!row.isMetricsRow &&
        <TableCell align="center" key={`${row.id}--cog`} className={classNames("table__cell--cog", {
          "table__cell--child": row.parent,
          "color": noInventoryCost && isCOG,
        })}>
          <WithTooltip isWrap={noInventoryCost && isCOG} tooltip={tooltip} id={row.id}>
            <ContentCell {...contentCellProps} row={row} value={value} isEdit={true}/>
          </WithTooltip>
        </TableCell>
      )
    }
  },
  {
    id: "fulfillment",
    label: "Fulfilment",
    align: "center",
    help: () => <Help data={fulfilmentIcon} className="help--fulfillment"/>,
    render: (row, {contentCellProps}) => {
      const fulfillment = row.fulfillment;

      const value = <FulfillmentCell row={row} fulfillment={fulfillment}/>;

      return (!row.isMetricsRow &&
        <TableCell align="center" key={`${row.id}--fulfillment`} className={classNames("table__cell--fulfilment", {
          "table__cell--child": row.parent,
        })}>
          <ContentCell {...contentCellProps} row={row} value={value}/>
        </TableCell>
      )
    }
  },
  {
    id: "amazon_fees",
    align: "center",
    label: "Amazon Fees",
    helpText: "The total Amazon fees",
    render: (row, {currency, contentCellProps, currentRow, reportType, orderReport, onHoverRow, isLoading}) => {
      const value = withUnits(currency, row.amazon_fees);
      const isShowTooltip = !!row.amazon_fees || row.amazon_fees === 0;
      const tooltipData = isShowTooltip && currentRow.id === row.id && reportType === typeReports.AMAZON_PRODUCT
        ? orderReport
        : null;
      const reportTooltipProps = tooltipData
        ? tooltipProps({report: tooltipData, type: typeReports.AMAZON_PRODUCT, isLoading})
        : null;

      return (!row.isMetricsRow &&
        <TableCell align="center" key={`${row.id}--amazon_fees`} className={classNames("table__cell--amazon_fees", {
          "table__cell--child": row.parent,
        })}>


          <div onMouseEnter={onHoverRow(row, typeReports.AMAZON_PRODUCT, isShowTooltip)}>
            {
              !!tooltipData ? (
                <TooltipComponentState {...reportTooltipProps}>
                  <ContentCell {...contentCellProps} row={row} value={value} />
                </TooltipComponentState>
              ) : (
                <ContentCell {...contentCellProps} row={row} value={value} />
              )
            }
          </div>
        </TableCell>
      )
    }
  },
  {
    id: "profit",
    label: "Gross Profit",
    align: "center",
    render: (row, {currency, contentCellProps, onHoverRow, currentRow, orderReport, isLoading}) => {
      const isShowReport = !!row.profit || row.profit === 0;
      const tooltipData = isShowReport && currentRow.id === row.id ? orderReport : null;
      const reportTooltipProps = tooltipData ? tooltipProps({
        report: tooltipData,
        type: typeReports.PRODUCTS,
        isLoading
      }) : null;

      const value = reportTooltipProps ? (
        <TooltipComponentState {...reportTooltipProps}>
          <WarningCell
            key={`${row.id}--profit`}
            name="profit"
            value={row.profit}
            units={currency}
            text
          />
        </TooltipComponentState>
      ) : (
        <WarningCell
          key={`${row.id}--profit`}
          name="profit"
          value={row.profit}
          units={currency}
          text
        />
      );
      return (!row.isMetricsRow &&
        <TableCell align="center" key={`${row.id}--profit`} className={classNames("table__cell--profit", {
          "table__cell--child": row.parent,
        })}>
          <div
            onMouseEnter={onHoverRow(row, typeReports.PRODUCTS, isShowReport)}
            className={classNames("full-size placement--center", {"report__tooltip": isShowReport})}
          >
            <ContentCell {...contentCellProps} row={row} value={value} isShowTooltip={!row.profit}/>
          </div>
        </TableCell>
      )
    },
    helpText: "Your estimated profit assuming your product sells for the price in the ‘price’ column",
  },
  {
    id: "roi",
    label: "ROI",
    align: "center",
    helpText: "Your estimated Return On Investment assuming your product sells for the price in the ‘price’ column",
    render: (row, {currency, contentCellProps}) => {
      const value =
        <WarningCell
          key={`${row.id}--roi`}
          id={row.id}
          name="roi"
          value={row.roi}
          units="%"
          text
          nowrap
        />;
      return (!row.isMetricsRow &&
        <TableCell align="center" key={`${row.id}--roi`} className={classNames("table__cell--roi", {
          "table__cell--child": row.parent,
        })}>
          <ContentCell {...contentCellProps} row={row} value={value} isShowTooltip={!row.roi}/>
        </TableCell>
      )
    },
  },
  {
    id: "margin",
    label: "Margin",
    align: "center",
    helpText: "Your estimated Profit Margin assuming your product sells for the price in the ‘price’ column",
    render: (row, {currency, contentCellProps}) => {
      const value =
        <WarningCell
          key={`${row.id}--margin`}
          id={row.id}
          name="margin"
          value={row.margin}
          units="%"
          text
          nowrap
        />;
      return (!row.isMetricsRow &&
        <TableCell align="center" key={`${row.id}--margin`} className={classNames("table__cell--margin", {
          "table__cell--child": row.parent,
        })}>
          <ContentCell {...contentCellProps} row={row} value={value} isShowTooltip={!row.margin}/>
        </TableCell>
      )
    },
  },
  {
    id: "asin_overview",
    label: "ASIN Overview",
    align: "center",
    render: (row, {handleDisplayMetrics}) => {
      const {restock, relatedProduct} = row;
      const isParent = !restock && !relatedProduct;

      return (!row.isMetricsRow && (
          <TableCell align="center" key={`${row.id}--summary`} className={classNames("table__cell--summary")}>
            {isParent && (
              <Button variant="contained" color="primary" onClick={(e) => handleDisplayMetrics(e, row)} className="summary-btn">
                {/*<FormatListBulletedIcon htmlColor="var(--white)" fontSize="large" />*/}
                <img className="table__cell__img--icon" src={toAbsoluteUrl("/media/d-icons/list-text.svg")} alt="img"/>
              </Button>
            )}
          </TableCell>
        )
      )
    }
  },
  {
    id: "replenish",
    label: "Replenish",
    align: "center",
    helpText: help,
    render: (row, {contentCellProps}) => {
      return (!row.isMetricsRow &&
        <TableCell align="center" key={`${row.id}--add`} className={classNames("table__cell--add", {
          "table__cell--child": row.restock,
        })}>
          <ContentCell {...contentCellProps} row={row}/>
        </TableCell>
      )
    }
  },
  {
    id: "add_info",
    label: "Add. Info",
    align: "center",
    disabled: true,
    render: (row, {onShowRelated, onShowRestock}) => {
      const {
        date_added, item_name, restock, relatedProduct, related,
        restocks, id, asin1, vat_rate, listing_edit_url, amazon_product_url, seller_sku, marketplaces,
        otherMarketplace, vat_validated, cautions,
        inbound_shipment
      } = row;
      let title = item_name;
      const isReplenish = !!restock && !relatedProduct;
      const isMainRestock = isReplenish && !otherMarketplace;
      if (isMainRestock) {
        let dateAdded = moment(date_added).format(momentFormatOptions.display);
        title = `Replenish ${dateAdded}`;
      }
      const withChild = related.length !== 0 || restocks.length !== 0;
      const main = !relatedProduct && !otherMarketplace;
      const errorTooltips = cautions.filter(c => c.priority === 'error');
      return (!row.isMetricsRow &&
        <TableCell
          align="center" key={`${row.id}--add-info`} className={classNames("table__cell--add-info", {
          "table__cell--child": row.parent,
        })}
        >
          <AddInfo
            listing_edit_url={listing_edit_url}
            url={amazon_product_url}
            sku={seller_sku}
            relatedProduct={relatedProduct}
            restock={restock}
            row={row}
            key={`${id}--name`}
            asin={asin1}
            vat={vat_rate}
            title={title}
            onShowRelated={onShowRelated(row)}
            onShowRestock={onShowRestock(row)}
            marketplaces={related && marketplacesProduct(related)}
            restockMarketplaces={isMainRestock && marketplaces}
            colSpan={2}
            withChild={withChild}
            tableName="products"
            replenish={isReplenish}
            shouldRenderName={main}
            isMainReplenish={isMainRestock}
            vatValidated={vat_validated}
            errors={errorTooltips}
            inbound_shipment={inbound_shipment}
          />
        </TableCell>
      )
    },
  },
  {
    id: "note",
    label: "Note",
    align: "center",
    disabled: true,
    help: () => <Help data={notesIcons}/>,
    render: (row) => {
      return (!row.isMetricsRow &&
        <TableCell
          align="center"
          key={`${row.id}--note`}
          className={classNames("table__cell--note", {"table__cell--child": row.parent})}>
          <NoteProducts row={row}/>
        </TableCell>
      )
    },
  },
];
