import React, { useEffect, useState } from "react";
import DButton from "../../../components/Button";
import RSelect from "../../../components/controls/RSelect";
import { useModal } from "../../../../hooks/modal.hook";
import CategoryEditAddModal from "../../Category/CategoryEditAddModal";
import { connect } from "react-redux";
import { getTaxes } from "../../../../selectors/common.selectors";
import * as commonDuck from "../../../../store/ducks/common.duck";
import * as PropTypes from "prop-types";
import useCategories from "../../../../hooks/categories.hook";

const SplitsCategory = ({ taxes, getTaxes, ...props }) => {
  const [newCategory, setNewCategory] = useState(null);
  const [isShowAdd, toggleShowAdd] = useModal();

  const { categories: { details: categories } } = useCategories();

  useEffect(() => {
    getTaxes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddNewCategory = (data) => {
    setNewCategory({
      label: data.name,
      value: data.id
    });
  };

  const onFocus = () => setNewCategory(null);

  //show Create Category modal
  const onAddButton = () => toggleShowAdd();
  const handleChange = (value) => props.setFieldValue(props.name, value);
  const handleBlur = () => props.setFieldTouched(props.name, true);

  return <div className="table__cell--category">
    <RSelect
      onFocus={onFocus}
      onChange={handleChange}
      handleBlur={handleBlur}
      label={props.label}
      name={props.name}
      value={newCategory}
      className="form-group__select--button"
      options={categories.map(({ name, id }) => ({ label: name, value: id }))}
    />
    <span className="back-slash">&#x2f;</span>
    <DButton className="form-group__button" onClickCustom={onAddButton}>Add Category</DButton>
    <CategoryEditAddModal
      onClose={toggleShowAdd}
      showModal={isShowAdd}
      taxes={taxes}
      onAddNewCategory={onAddNewCategory}
      className="modal--splits"
    />
  </div>
};

SplitsCategory.propTypes = {
  taxes: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  return {
    taxes: getTaxes(state),
  }
};

const mapDispatchToProps = {
  getTaxes: commonDuck.actions.getTaxes,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SplitsCategory);