import { createSelector } from "reselect";

export const getBanks = createSelector(
  state => state.bank,
  (bank) => bank.banks
);

export const getBank = createSelector(
  state => state.bank,
  (bank) => bank.bank
);

export const getBankRules = createSelector(
  state => state.bank,
  (bank) => bank.bankRules
);

export const selectBankRule = createSelector(
  state => state.bank,
  (bank) => bank.bankRule
);

export const selectBankRuleLoading = createSelector(
  state => state.bank,
  (bank) => bank.bankRuleLoading
);


export const getLoading = createSelector(
  state => state.bank,
  (bank) => bank.loading
);

export const selectBankFiles = createSelector(
  state => state.bank,
  (bank) => bank.bankFiles
);