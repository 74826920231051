import React from "react";
import { flags, marketplaceIds } from "../../../components/Marketplaces/Marketplaces";
import { Avatar } from "@material-ui/core";
import TooltipComponentState from "../../../components/TooltipComponentState";
import BlockIcon from '@material-ui/icons/Block';
import * as PropTypes from "prop-types";

const Flag = ({ marketplace, marketplaceCode, className, classNameTooltip, placement="bottom", tooltip }) => {
  let _marketplace = flags["no_marketplace"];
  let tooltipMarketplace = tooltip ? tooltip : "Awaiting Amazon data - please check back later";
  if (marketplaceCode) {
    _marketplace = flags[marketplaceCode]
      ? flags[marketplaceCode]
      : `http://purecatamphetamine.github.io/country-flag-icons/3x2/${marketplaceCode}.svg`;
    return (
      <TooltipComponentState tooltip={tooltipMarketplace} placement={placement} className={classNameTooltip}>
        {marketplaceCode === "Canceled"
          ? <BlockIcon fontSize="large" htmlColor="var(--main)" />
          : <Avatar alt="img" src={_marketplace} className={className} />
        }
      </TooltipComponentState>
    )
  }
  if (marketplaceIds[marketplace]) {
    _marketplace = flags[marketplaceIds[marketplace]];
    return tooltip
      ? <TooltipComponentState tooltip={tooltipMarketplace} placement={placement}>
        <Avatar alt="img" src={_marketplace} className={className} />
      </TooltipComponentState>
      : <Avatar alt="img" src={_marketplace} className={className} />
  }
  return <TooltipComponentState tooltip={tooltipMarketplace} placement={placement}>
    <Avatar alt="img" src={_marketplace} className={className} />
  </TooltipComponentState>
};

Flag.propTypes = {
  marketplace: PropTypes.string,
  className: PropTypes.string,
  placement: PropTypes.string,
  tooltipMarketplace: PropTypes.string,
  marketplaceCode: PropTypes.string,
  classNameTooltip: PropTypes.string,
};

export default Flag;