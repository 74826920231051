import React from "react";
import {TableCell} from "@material-ui/core";
import FormField from "../../../../components/controls/FormField";


function areEqual(prevProps, nextProps) {
  return prevProps.value === nextProps.value;
}

const PreventRerender = React.memo(({ children }) => children , areEqual);

const TransactionCell = ({ form, values, item, index}) => {
  const name = `transactions.${index}.${item.id}`;
  // console.log(name, 'debug1');
  const value = values[name];
  return (
    <PreventRerender name={name} value={value}>
      <TableCell
        key={item.id}
        aria-label={item.label}
        className={"form__cell cell__transactions__" + item.id}>
        <FormField
          render={item.render}
          name={name}
          label={item.label}
          index={index}
          type={item.type}
          validate={item.validator}
          customProps={item.customProps && item.customProps()}
          {...form}
          values={values}
        />
      </TableCell>
    </PreventRerender>
  )
};

export default TransactionCell;