import React from 'react'
import {toAbsoluteUrl} from "../../../../../../_metronic";

function Analyze() {
  return (
    <>
     <section className="row_am analyze_section">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="analyze_text" data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100">
              <span className="icon"><img src={toAbsoluteUrl("media/codely-theme-files/assets/images/new/Analyze_Icon.png")} alt="image"/></span>
              <div className="section_title">
                <h2>ASIN Journey Inventory Management</h2>
                <p>Track your stock all the way from purchasing it, through to the prep centre or you, then onto Amazon and then see what happens to it along the way, sold? Stranded? Returned? Received back into inventory? EVERYTHING! Never lose any stock again! This one's a GAME CHANGER!</p>
              </div>
              <ul>
                <li data-aos="fade-up" data-aos-duration="2000">
                  <h3>Full Visibility, Zero Guesswork</h3>
                  <p>With SKUify's ASIN Journey Inventory Management, you've got a bird's-eye view of your stock's entire lifecycle. From the moment you purchase it, through its stay at the prep center, its journey onto Amazon, and its final destination—be it sold, stranded, returned, or back in your inventory—you're in the know. It's like having GPS for your products!</p>
                </li>
                <li data-aos="fade-up" data-aos-duration="2000">
                  <h3>Never Lose Stock Again</h3>
                  <p>Say goodbye to those "Where did it go?" moments. This feature is a game-changer in inventory management, ensuring that you'll never lose track of a single unit again. Whether it's being prepped, sold, or returned, you'll always know where your stock is and what's happening to it, giving you peace of mind and full control.</p>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="analyze_image" data-aos="fade-in" data-aos-duration="1000">
              <img data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100" className=""
                src={toAbsoluteUrl("media/codely-theme-files/assets/images/skuify/banner-2.png")} alt="image"/>
            </div>
          </div>
        </div>
      </div>
    </section> 
    </>
  )
}

export default Analyze
