import React from "react";
import Typography from "@material-ui/core/Typography";
import * as PropTypes from "prop-types";

const EmptyBlock = ({ className, children }) => {
  const style = className ? className + "__empty" : "trans-modal__empty";
  const child = typeof children === "string" ? "h4" : "div";
  return (
    <div className={"empty-block " + style}>
      <Typography gutterBottom variant="h4" component={child} className="trans-modal__h4">
        {children}
      </Typography>
    </div>
  )
};

EmptyBlock.propTypes = {
  className: PropTypes.string,
};

export default EmptyBlock;
