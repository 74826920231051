import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import * as CompanySelector from "../selectors/company.selectors";
import * as companyDuck from "../store/ducks/company.duck";
import {getCurrentCompanyId} from "../crud/utils";

const useCompany = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(companyDuck.actions.getCompany({ id: getCurrentCompanyId() }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const company = CompanySelector.getCompany(useSelector(state => state));

  return {
    company: company,
  };
};

export default useCompany;