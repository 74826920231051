import React, { useEffect, useRef, useState } from "react";
import * as PropTypes from "prop-types";
import DashboardTable from "../components/DashboardTable";
import DSplashScreen from "../../../components/SplashScreen/DSplashScreen";
import useUsers from "../../../../hooks/users.hook";
import { currencySymbols, fulfillmentFullNames } from "../../utils";
import { useGeolocation } from "../../../../hooks/geolocation.hook";
import classNames from "clsx";

const WithSplashScreen = ({ loading, children, isDisplay, data }) => {
    const [height, setHeight] = useState(0);
    useEffect(()=> {
      refElem.current && setHeight(refElem.current.clientHeight);
    },[data]);

  const clientHeight =  window.innerHeight;
  const remainingHeight = (clientHeight - height - 200) > 0;
  const refElem = useRef(null);
  if (isDisplay)  {
    return (
      <div
        className={classNames("d-splash-screen--wrap", { "small-screen": remainingHeight })}
        ref={refElem}
      >
        {loading && <DSplashScreen className="tab-table"/>}
        {children}
      </div>
    )
  } else {
    return children
  }
};

const TabWithTable = (props) => {
  const { loading, data, isDisplay, headRows, searchQuery, pageName, emptyWording: empty } = props;
  const { user } = useUsers({ fetch: false });
  const { geolocation } = useGeolocation({ fetch: false });
  const emptyWording = searchQuery in fulfillmentFullNames && `You don't have any ${fulfillmentFullNames[searchQuery]} Sales`;
  return (
    <WithSplashScreen loading={loading} isDisplay={isDisplay} data={data}>
      <DashboardTable
        {...props}
        emptyWording={emptyWording || empty}
        headRows={
          typeof(headRows)  === "function"
            ? headRows({ pageName, currency: currencySymbols[user.currency], location: geolocation.country_code })
            : headRows
        }
      />
    </WithSplashScreen>
  )
};

TabWithTable.propTypes = {
  loading: PropTypes.bool,
  headRows: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
  isDisplay: PropTypes.bool,
  data: PropTypes.array,
};

export default TabWithTable;
