import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Box from '@material-ui/core/Box';
import Typography from "@material-ui/core/Typography";

const OrdersHeadPending = ({ withUnits, currency, rowItemPriceAmount }) => (
  <Grid container spacing={1}>
    <Grid item xs={12} sm container>
      <Grid item xs container direction="column" spacing={1}>
        <Grid item>
            <Typography>
              {withUnits(currency, rowItemPriceAmount)}
            </Typography>
          </Grid>
        {/*<Grid item>Estimated</Grid>*/}
      </Grid>
    </Grid>
  </Grid>
);

OrdersHeadPending.propTypes = {
  withUnits: PropTypes.func,
  currency: PropTypes.string,
  rowItemPriceAmount: PropTypes.number,
};

export default OrdersHeadPending;
