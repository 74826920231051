import React, {useEffect, useState} from "react";
import RSelect from "../../components/controls/RSelect";
import DSearch from "../../components/DSearch/DSearch";
import Marketplaces from "../../components/Marketplaces/Marketplaces";
import {COUNTRY_LABEL} from "./utils";
import Form from "react-bootstrap/Form";
import {Typography} from '@material-ui/core';
import useUsers from "../../../hooks/users.hook";
import Button from "@material-ui/core/Button";
import OverlayComponent from "../../components/OverlayComponent";
import {ReactComponent as ImportIcon} from "../../../media/icons/import.svg";
import integrationCrud from '../../../crud/integration.crud';
import LogoutPPPButton from "./LogoutPPPButton";
import {useNotification} from "../../../hooks/notification.hook";

const PPPTabsToolbar = (props) => {
  const {
    handlerSearchDebounce,
    handlerSelectedMarketplace,
    handleChangeFilter,
    handleChangeSort,
    params,
    eMarketplaces,
    filterOptions,
    sortOptions,
    pppData,
  } = props;
  const {user} = useUsers({fetch: false});

  const {sort, filter} = params;
  const [isClear, setClear] = useState(false);
  const [visibleMarketplaces, setVisibleMarketplaces] = useState([]);

  const [successImport, setIsShowSuccessImport] = useNotification(
    false,
    "success",
    "SKUify is currently importing your Unit Cost from an PPP - please check back later",
    { vertical: "bottom", horizontal: "right" },
    5000,
  );

  const tooltip = 'Toggle on to include products that are currently Out Of Stock  or Inactive in your inventory';

  useEffect(() => {
    pppData?.visibleMarketplaces && setVisibleMarketplaces([...pppData?.visibleMarketplaces, pppData?.defaultMarketplace])
  }, [pppData.visibleMarketplaces, pppData.defaultMarketplace]);

  let marketplaces = eMarketplaces.map(marketplace => ({
    ...marketplace,
    country_code: marketplace.CountryCode,
    country: COUNTRY_LABEL[marketplace.DisplayName] ? COUNTRY_LABEL[marketplace.DisplayName] : marketplace.DisplayName
  }));

  let filterMarketplaces = visibleMarketplaces.map(id => marketplaces.find((m) => m.MarketplaceID === id));

  let filters = [
    ...filterOptions.map(({FilterName, DisplayName}) => ({value: FilterName, label: DisplayName})),
    {value: "All products", label: "All products"}
  ];

  let sorts = sortOptions.map(({SortName, DisplayName}) => ({value: SortName, label: DisplayName}));

  const onImport = async () => {
    const res = await integrationCrud.importPPP();

    if (res.status === 200) {
      setIsShowSuccessImport(true)
    }
  };

  return (<>
      <div className='wrapper-switch sales'>
        {successImport}
        <Typography className="page-title">Repricing</Typography>

        {user.ppp_s3_file && (
          <OverlayComponent tooltip="">
            <Button variant="contained" size="medium" className="header-button" onClick={onImport}>
              <ImportIcon className="import-icon"/>
              Import buy prices
            </Button>
          </OverlayComponent>
        )}
        <DSearch setSearchQuery={handlerSearchDebounce} isClear={isClear} setClear={setClear}/>

        <Form.Group className="form-group form-group--filter">
          {/*<Form.Label column={false}>Filter</Form.Label>*/}
          <RSelect
            value={filter ? filter : 'All products'}
            label=""
            name='select filter'
            options={filters}
            onChange={handleChangeFilter}
            className='switch-select'
          />
        </Form.Group>

        <Form.Group className="form-group form-group--sort">
          {/*<Form.Label column={false}>Sort</Form.Label>*/}
          <RSelect
            value={sort}
            label=""
            name='select sort'
            options={sorts}
            onChange={handleChangeSort}
            className='switch-select'
          />
        </Form.Group>

        <Marketplaces
          pppMarketplaces={filterMarketplaces}
          onSelectedMarketplace={handlerSelectedMarketplace}
          // handlerSelectedMarketplace={handlerSelectedMarketplace}
        />
        <LogoutPPPButton />
      </div>

    </>
  )
};


export default PPPTabsToolbar;
