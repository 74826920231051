import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "./ducks/auth.duck";
import * as transaction from "./ducks/transaction.duck";
import * as bank from "./ducks/bunk.duck";
import * as company from "./ducks/company.duck";
import * as importFormats from "./ducks/import.duck";
import * as category from "./ducks/category.duck";
import * as common from "./ducks/common.duck";
import * as product from "./ducks/product.duck";
import * as asin from "./ducks/asin.duck";
import * as chart from "./ducks/chart.duck";
import * as reports from "./ducks/reports.duck";
import * as setting from "./ducks/settings.duck";
import * as thank from "./ducks/thank.duck";
import * as onboarding from "./ducks/onboarding.duck";
import * as notifications from "./ducks/notifications.duck";
import { metronic } from "../../_metronic";
import * as dashboardTiles from "./ducks/dashboardTiles.duck.js";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  i18n: metronic.i18n.reducer,
  builder: metronic.builder.reducer,
  company: company.reducer,
  category: category.reducer,
  transaction: transaction.reducer,
  bank: bank.reducer,
  common: common.reducer,
  importFormats: importFormats.reducer,
  product: product.reducer,
  asin: asin.reducer,
  chart: chart.reducer,
  reports: reports.reducer,
  setting: setting.reducer,
  thank: thank.reducer,
  dashboardTiles: dashboardTiles.reducer,
  onboarding: onboarding.reducer,
  notifications: notifications.reducer,
});

export function* rootSaga() {
  yield all([
    auth.saga(),
    company.saga(),
    category.saga(),
    transaction.saga(),
    bank.saga(),
    common.saga(),
    importFormats.saga(),
    product.saga(),
    asin.saga(),
    chart.saga(),
    reports.saga(),
    setting.saga(),
    thank.saga(),
    dashboardTiles.saga(),
    onboarding.saga(),
    notifications.saga(),
  ]);
}
