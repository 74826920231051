import React from "react";
import {defaultCode} from "./CompanySettings/utils";
import FormComponent from "../../components/controls/FormComponent";
import {MenuItem, Typography} from "@material-ui/core";
import {NotificationCustomProps, NotificationSoundProps} from "./types";

export const callingCode = [
  { label: <><span className="name-country">USA</span><span>+1</span></>, value: "USA +1", country: "USA" },
  { label: <><span className="name-country">Canada</span><span>+1</span></>, value: "Canada +1", country: "Canada" },
  {
    label: <><span className="name-country">United Kingdom</span><span>+44</span></>,
    value: "United Kingdom +44",
    country: "United Kingdom"
  },
  { label: <><span className="name-country">Spain</span><span>+34</span></>, value: "Spain +34", country: "Spain" },
  { label: <><span className="name-country">France</span><span>+33</span></>, value: "France +33", country: "France" },
  { label: <><span className="name-country">Italy</span><span>+39</span></>, value: "Italy +39", country: "Italy" },
  { label: <><span className="name-country">Germany</span><span>+49</span></>, value: "Germany +49", country: "Germany"},
  {
    label: <><span className="name-country">Netherlands</span><span>+31</span></>,
    value: "Netherlands +31",
    country: "Netherlands"
  },
  { label: <><span className="name-country">Mexico</span><span>+52</span></>, value: "Mexico +52", country: "Mexico" },
];

export const accountUser = [
  {
    id: "first_name",
    label: "First Name",
    type: "text",
  },
  {
    id: "last_name",
    label: "Surname",
    type: "text",
  },

  {
    id: "email",
    label: "Email",
    type: "email",
  },
  {
    id: "contact_number",
    label: "Phone",
    type: "phone",
    render: ({customProps, ...props}: any) => {
      const {values} = props;
      let phoneCode = defaultCode;
      if (values.phone_code) {
        phoneCode = callingCode.find(({ country }) => country === values.phone_code) || defaultCode;
      }
      return (
        <FormComponent customProps={{ defaultCode: phoneCode }} {...props} />
      )
    }
  },
];

export const permissions = [
  {
    id: "perm_email",
    label: "Email",
    type: "switch",
    showLabel: true,
    customProps: ({ setFieldValue }: any) => {
      const onChange = (e: any) => {
        setFieldValue("perm_email", e.target.checked);
        if (e.target.checked) {
          setFieldValue("perm_none", false);
        }
      };
      return  {
        onChange
      }
    }
  },
  // {
  //   id: "perm_post",
  //   label: "Post",
  //   type: "switch",
  //   showLabel: true,
  //   customProps: ({ setFieldValue }) => {
  //     const onChange = (e) => {
  //       setFieldValue("perm_post", e.target.checked);
  //       if (e.target.checked) {
  //         setFieldValue("perm_none", false);
  //       }
  //     };
  //     return {
  //       onChange
  //     }
  //   },
  // },
  {
    id: "perm_phone",
    label: "Phone",
    type: "switch",
    showLabel: true,
    customProps: ({ setFieldValue }: any) => {
      const onChange = (e: any) => {
        setFieldValue("perm_phone", e.target.checked);
        if (e.target.checked) {
          setFieldValue("perm_none", false);
        }
      };
      return  {
        onChange
      }
    }
  },
  {
    id: "perm_none",
    label: "None",
    type: "switch",
    showLabel: true,
    customProps: ({ setFieldValue }: any) => {
      const onChange = (e: any) => {
        setFieldValue("perm_none", e.target.checked);
        if (e.target.checked) {
          setFieldValue("perm_phone", false);
          setFieldValue("perm_post", false);
          setFieldValue("perm_email", false);
        }
      };
      return  {
        onChange
      }
    }
  },
];

export const notificationSettings = [
  {
    id: "notification_sound",
    label: "Notification Sound",
    type: "MSelectSingle",
    showLabel: true,
    customProps: ({ accountSettings, handleSoundMouseEnter, notificationSound }: NotificationCustomProps) => {
      const optionsCustom = () => notificationSound.map(option => (
        <MenuItem
          key={option.value}
          className="notification_sound__block"
          value={option.value}
          onMouseEnter={() => handleSoundMouseEnter(option)}
        >
          <Typography className="notification_sound__item">{option.label}</Typography>
        </MenuItem>
      ));

      const renderValueCustom = (val: string) => {
        const opt = notificationSound.find(({value}) => value === val);
        return (
        <div style={{
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          marginLeft: 5
        }}
        >
        {opt?.label || 'No sound'}
        </div>
        );
      };

      return  {
        options: notificationSound,
        renderOptionsCustom: optionsCustom,
        renderValueCustom
      }
    }
},
];

export const security = [
  {
    id: "old_password",
    label: "Old password",
    type: "password",
  },
  {
    id: "new_password",
    label: "New password",
    type: "password",
  },
  {
    id: "confirm_password",
    label: "Confirm new password",
    type: "password",
  },
];