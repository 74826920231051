import React from "react";
import * as PropTypes from "prop-types";
import _ from "lodash";
import { Form as FormikForm, Formik}  from "formik";
import {Button, Dialog, DialogContent, IconButton, Link, Typography} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Form from "react-bootstrap/Form";
import FormField from "../../components/controls/FormField";

const ReconciliationModal = (props) => {
  const { openModal, closeModal, interfaceForm, initValues, onSubmit, supportAmazon, submitButtonText, disabled } = props;
  return (
    <Dialog
      open={openModal}
      onClose={closeModal}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      className="reconciliation__modal sales__reports__modal  "
      maxWidth="sm"
      scroll="body"
    >
      <Formik
        enableReinitialize
        initialValues={initValues}
        onSubmit={onSubmit}
      >
        {form => (
          <FormikForm>
            <DialogContent>
              <div className="products__details__body">
                <div className="products__details__inventory-cost">
                  <IconButton className="extra-row__close" onClick={closeModal}>
                    <CloseIcon fontSize="medium" htmlColor="var(--color-on-surface-1)"/>
                  </IconButton>

                  {interfaceForm.map(item => {
                    return (
                      <Form.Group key={item.id} controlId={item.id} className={`form-group--${item.id}`}>
                        {!item.showLabel && <Form.Label column={false}>{item.label}</Form.Label>}
                        <div className="form-inputs-wrapper">
                          <FormField
                            name={item.id}
                            label={item.label}
                            type={item.type}
                            customProps={item.customProps}
                            render={item.render}
                            validate={item.validate}
                            disabled={item.disabled}
                            {...form}
                          />
                        </div>                  
                      </Form.Group>
                    )
                  })}
                </div>
                <div className="products__details__wrap-btn">
                  <Button disabled={!_.isEmpty(form.errors) || disabled} type="submit" className="products__details__btn products__details__btn--add">
                    {submitButtonText}
                  </Button>
                  <Typography className="reconciliation__modal__title">
                    <Link target="_blank" href={supportAmazon}>
                      Amazon Contact Us
                      <OpenInNewIcon fontSize="small" className="icon" htmlColor="var(--color-on-surface-3)" />
                    </Link>
                  </Typography>
                </div>
              </div>
            </DialogContent>
          </FormikForm>
        )}
      </Formik>
    </Dialog>
  )
};

ReconciliationModal.propTypes = {
  openRaiseCase: PropTypes.bool,
  toggleOpenRaiseCase: PropTypes.func,
  interfaceForm: PropTypes.array,
  initValues: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default ReconciliationModal;