import {filterObject, floatNumber, getKeyByValue, uniqByKey} from "../../utils";
import moment from 'moment';
import {strategies, all_cogs, currency_cogs, cogLabel, bbpToCog, bbpSettingsApiFields, productApiFields, FfTypes} from "./interfaces";

export const getStrategyName = strategy => `strategy_${strategy}`;
export const getStrategy = ({values, strategies}) => {
    const chosenStrategy = Object.entries(values).filter(([k, v]) => k.startsWith('strategy_') && !!v)[0];
    return chosenStrategy && getKeyByValue(strategies, chosenStrategy[0].replace('strategy_', ''))
};

// GETTING COSTS
export const getSumfullyCosts = sumfullyCosts => sumfullyCosts ? uniqByKey(sumfullyCosts, 'label')
    .filter(cog => all_cogs.includes(cog.name))
    .map(cog => ({label: cog.label, value: cog.value, isCurrency: currency_cogs.includes(cog.name)}))
    .sort((a, b) => a.label.localeCompare(b.label)) : [];

export const getBBPCosts = raw_bbp => all_cogs
    .map(cog => ({
        label: cogLabel[cog],
        value: raw_bbp?.fees && raw_bbp.fees[bbpToCog[cog]] || 0,
        isCurrency: currency_cogs.includes(cog)
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

export const getProductCosts = product => Object.keys(product)
    .filter(key => all_cogs.includes(key))
    .map(key => ({label: cogLabel[key], value: product[key], isCurrency: currency_cogs.includes(key)}))
    .sort((a, b) => a.label.localeCompare(b.label));

// CALCULATING TOTAL ROWS
export const calculateTotalCogsForPreset = ({cogs, inventoryCostForm}) => {
    const extraFeePerc = cogs.find(el => el.label === cogLabel['extra_fee_perc'])
    const inventoryCost = extraFeePerc ? inventoryCostForm * (1 + extraFeePerc.value / 100) : inventoryCostForm;

    const currencySum = cogs
        .filter(el => el.label !== cogLabel['extra_fee_perc'])
        .filter(cog => cog.isCurrency)
        .map(cog => floatNumber(cog.value))
        .reduce((a, b) => a + b, inventoryCost || 0);
    return currencySum;
};

export const calculateTotalCogsForEdit = cogs => {
    const inventoryCost = cogs.extra_fee_perc ? cogs.inventory_cost * (1 + cogs.extra_fee_perc / 100) : cogs.inventory_cost;
    const total =  Object.keys(cogs)
        .filter(key => currency_cogs.includes(key))
        .map(key => cogs[key])
        .map(item => floatNumber(item))
        .filter(item => !!item)
        .reduce((a, b) => a + b, floatNumber(inventoryCost) || 0).toFixed(2);
    return total;
};

export const onSubmit = ({updateProductBBPImportStrategy, row, close}) => formValues => {
    const bbpSettings = filterObject(formValues, ([k, _]) => bbpSettingsApiFields.includes(k));
    const product = filterObject(formValues, ([k, _]) => productApiFields.includes(k));
    if (product.purchased_date) product.purchased_date = moment(product.purchased_date).format('YYYY-MM-DDThh:mm');
    const data = {
        product: {...product, id: row.id},
        bbp_import_strategy: {
            ...bbpSettings,
            strategy: getStrategy({values: formValues, strategies}),
            id: row.bbp_import_strategy.id
        },
        id: row.id,
    };
    updateProductBBPImportStrategy(data);
    close();
};
