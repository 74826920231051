import React, {useState} from "react";
import NotificationForm from "../pages/components/NotificationForm";

export const useNotification = (open = false, variant, message, anchorOrigin, duration, ClickAwayListenerProps) => {
  const [isShowNotification, setIsShowNotification] = useState(open);
  const durationItem = {
    success: duration ? duration : 3000,
  };

  const notificationComponent = <NotificationForm
      open={isShowNotification}
      onClose={() => setIsShowNotification(false)}
      variant={variant}
      message={message}
      anchorOrigin={anchorOrigin}
      autoHideDuration={durationItem[variant] || null}
      ClickAwayListenerProps={ClickAwayListenerProps}
  />;
  return [notificationComponent, setIsShowNotification]
};