import React from "react";
import {FormControl, Input, InputAdornment, InputLabel} from "@material-ui/core";
import RSelect from "../RSelect";
import {callingCode} from "../../../home/Settings/utils";

const PhoneField = allProps => {
  const {
    name,
    setFieldValue,
    setFieldTouched,
    placeholder,
    label,
    type,
    showLabel,
    customProps = {},
    submitCount,
    autofocus,
    ...props
  } = allProps;

  const {defaultCode,...otherProps} = customProps;

  const handleChange = (value) => {
    setFieldValue(name, value.target.value);
  };

  const handleChangeSelect = (value) => {
    setFieldValue("phone_code", value.country);
  };

  const handleBlur = () => {
    setFieldTouched(name, true);
  };

  return <FormControl>
    <InputLabel htmlFor={name}>{label}</InputLabel>
    <Input
      {...props}
      id={name}
      startAdornment={
        <InputAdornment position="start">
          <RSelect
            defaultValue={defaultCode}
            options={callingCode}
            name={name}
            label=""
            onChange={handleChangeSelect}
            style="form-group__phone_code"
          />
        </InputAdornment>
      }
      onChange={handleChange}
      onBlur={handleBlur}
      value={props.value || ''}
      {...otherProps}
    />
  </FormControl>
};

export default PhoneField;