import React from 'react';
import * as PropTypes from "prop-types";
import NoteTabTable from "../Dashboard/components/NoteTabTable";
import useProducts from "../../../hooks/products.hook";

const NoteProducts = ({ row, link }) => {
  const { updateProductNote } = useProducts({ fetch: false });
  return <NoteTabTable update={updateProductNote} row={row} target="product" link={link} />;
};

NoteProducts.propTypes = {
  row: PropTypes.object,
  link: PropTypes.string,
};

export default NoteProducts;