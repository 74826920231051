import React from 'react'
import {Typography, Button} from '@material-ui/core'
import ExpensesContent from "./ExpensesContent";
import {ReactComponent as ImportIcon} from "../../../media/icons/import.svg";
import OverlayComponent from "../../components/OverlayComponent";
import {useModal} from "../../../hooks/modal.hook";
import useReports from "../../../hooks/reports.hook";
import ImportCOG from "../Products/ImportCOG";
import ExportCOG from "../Products/ExportCOG";
import {useNotification} from "../../../hooks/notification.hook";
import {importCOGWarningText} from "../Products/utils";

const Expenses = () => {
  const {addImportsExpenses, noteImportsExpenses} = useReports();
  const [isModalShow, toggleModal] = useModal();
  const exportProps = {
    url: "/expense",
    downloadFileName: "SKUify Expenses Export"
  };

  const [successImport, setIsShowSuccessImport] = useNotification(
    false,
    "success",
    " SKUify is currently importing your Expense from an external file - please check back later",
    { vertical: "bottom", horizontal: "right" },
    5000,
  );

  const [errorImport, setIsShowError] = useNotification(
    false,
    "error",
    noteImportsExpenses?.errors && importCOGWarningText({ noteImportsCOG: noteImportsExpenses.errors }),
    { vertical: "bottom", horizontal: "right" },
    null,
    { mouseEvent: false }
  );

  const [warning, setIsShowWarning] = useNotification(
    false,
    "warning",
    noteImportsExpenses?.warnings && importCOGWarningText({ noteImportsCOG: noteImportsExpenses.warnings }),
    { vertical: "bottom", horizontal: "right" },
    null,
    { mouseEvent: false }
  );

  const showNotifications = (notifications) => {
    if (!!notifications && notifications.errors) {
      setIsShowError(true);
    } else {
      setIsShowSuccessImport(true);
    }
    if (!!notifications && notifications.warnings) {
      setIsShowWarning(true);
    }
  };
  return (
    <div className="settings-main__cog settings-main__expenses settings-main__default">
      <div className="settings__subtitle">
        <div className="block">
          <OverlayComponent tooltip="You can use this area to record expenses that need to be factored into your financial figures for example, sourcing services, sourcing software, deal analysis tools etc.">
            <Typography className="page-title">Expenses</Typography>
          </OverlayComponent>
        </div>

        <div className="actions">
          {successImport}
          {noteImportsExpenses?.warnings && warning}
          {noteImportsExpenses?.errors && errorImport}
          <ExportCOG extraProps={exportProps} />
          <OverlayComponent
            tooltip="Click here to import the CSV and automatically update your Cost of Goods information"
          >
            <Button variant="contained" size="medium" className="header-button" onClick={toggleModal}>
              <ImportIcon className="icon-component import-icon" />
              Import
            </Button>
          </OverlayComponent>
        </div>
      </div>

      <ExpensesContent />
      <ImportCOG
        short
        title="Import Expenses"
        show={isModalShow}
        onClose={toggleModal}
        addImports={addImportsExpenses}
        showNotifications={showNotifications}
      />
    </div>
  )
};

export default Expenses;
