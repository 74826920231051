import React, { useEffect, useState } from 'react'
import { DropzoneArea } from 'material-ui-dropzone'
import Papa from 'papaparse'
import * as PropTypes from "prop-types";
import classNames from 'clsx';

const formatFiles = ['.csv', '.tsv', '.xls', '.xlsx', '.txt'];

const UploadFileScreen = ({ setRows, noParse, acceptedFiles=formatFiles, className, dropzoneText }) => {
  const [files, setFiles] = useState([]);

  const handleChange = (files) => {
    if (noParse) {
      setRows(files)
    }
    setFiles(files)
  };

  useEffect( ()=> {
   if (files.length === 0) return;
   !noParse && Papa.parse(files[0], {
      worker: true,
      complete: function(results, file) {
        setRows(results.data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  return (
    <>
      <DropzoneArea
        onChange={handleChange}
        acceptedFiles={acceptedFiles}
        dropzoneClass={classNames("upload-file-screen", className)}
        dropzoneParagraphClass="title-dropzone"
        dropzoneText={dropzoneText || "Drag and drop your file here or click"}
      />
      {/*<img*/}
        {/*alt="img"*/}
        {/*className="upload-file-screen"*/}
        {/*src={toAbsoluteUrl("/media/d-icons/cloud-computing.svg")}*/}
        {/*onChange={handleChange}*/}
      {/*/>*/}
    </>
  )
};

UploadFileScreen.propTypes = {
  setRows: PropTypes.func.isRequired,
  noParse: PropTypes.bool,
  acceptedFiles: PropTypes.array,
};

export default UploadFileScreen;
