import { createSelector } from "reselect";


export const selectBankEntry = createSelector(
  state => state.importFormats,
  (state) => state.bankEntry
);


export const selectImportFormats = createSelector(
  state => state.importFormats,
  (state) => state.importFormats
);

export const getLoading = createSelector(
  state => state.importFormats,
  (state) => state.loading
);

export const selectImport = createSelector(
  state => state.importFormats,
  (state) => state.customImport
);

export const selectBankEntries = createSelector(
  state => state.importFormats,
  (state) => state.bankEntries
);