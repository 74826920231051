import React from "react";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import InfoIcon from '@material-ui/icons/Info';
import classNames from 'classnames';
import * as PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import {optionHeaderVAT} from "../components";

const HistoryInfo = ({ handleInfo, currentVATTabData, values, index }) => {
  const isDisplay = values.vat_number;
  return (
    <div
      onClick={isDisplay ? handleInfo({ values }) : null}
      className={classNames("form-group info-field", {"pointer": isDisplay, "disabled": !isDisplay })}
    >
      <Form.Label
        column={false}
        className={classNames("form-group__label-item", {"forms__headers--item": index})}
      >
        {optionHeaderVAT.find((header) => header.id === "vat_info")?.label}
      </Form.Label>
      {values.id === currentVATTabData.id ? <InfoIcon fontSize="large" /> : <InfoOutlinedIcon fontSize="large" />}
    </div>
  )
};

HistoryInfo.propTypes = {
  currentVATTabData: PropTypes.object,
  values: PropTypes.object,
  handleInfo: PropTypes.func,
};
export default HistoryInfo;