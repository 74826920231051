import { useDispatch } from "react-redux";
import * as commonDuck from "../store/ducks/common.duck";
/**
 * celery statuses
 PENDING = 'PENDING'
 RECEIVED = 'RECEIVED'
 STARTED = 'STARTED'
 SUCCESS = 'SUCCESS'
 FAILURE = 'FAILURE'
 REVOKED = 'REVOKED'
 REJECTED = 'REJECTED'
 RETRY = 'RETRY'
 IGNORED = 'IGNORED'
 **/
const taskStatuses = {
  SUCCESS: 'SUCCESS',
};

const THROTLE_INTERVAL = 3000;
/**
 * Get async task status
 * @returns {{getTaskStatus: getTaskStatus}}
 */
const useAsyncTask = () => {
  const dispatch = useDispatch();
  let intervalId;

  const getTaskStatus = ({ taskId, onDone, onError }) => {
    // call getTaskStatus in setInterval while get success status
    const throttleOnDone = (data) => {
      if (data.status === taskStatuses.SUCCESS) {
        // success response should content id of created object
        onDone({ id: data.result });
        if (intervalId) {
          clearInterval(intervalId)
        }
      }
    };

    intervalId = setInterval(() => {
      dispatch(commonDuck.actions.getTaskStatus({
        taskId,
        onDone: throttleOnDone,
        onError
      }));
    }, THROTLE_INTERVAL)
  };

  return {
    getTaskStatus,
  };
};

export default useAsyncTask;