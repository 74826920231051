import React from 'react';
import * as PropTypes from "prop-types";
import CreateEditModal from "../../components/CreateEditModal";
import Form from "react-bootstrap/Form";
import FormField from "../../components/controls/FormField";
import Typography from "@material-ui/core/Typography";

function EditUploadModal({show, onClose, updateBankFile, file}) {
  const onSubmit = (values, {setSubmitting, resetForm}) => {
    let data = {...values};
    const onDone = () => {
      setSubmitting(false);
      resetForm();
      onClose();
    };
    const onError = (error) => {
      alert(error);
    };
    updateBankFile({
      id: file.id,
      values: {
        name: data.name
      },
      onDone,
      onError
    });
    onClose();

  };
  return (
    <CreateEditModal
      modalTitle="Receipt Uploads"
      onSubmit={onSubmit}
      onClose={onClose}
      show={show}
      className="modal--small"
      initValues={file}>
      {(form) => {
        return <>
          <Form.Group controlId="name">
            <Form.Label>Name</Form.Label>
            <FormField
              name="name"
              label="Name"
              type="text"
              {...form}
            />
          </Form.Group>
          <Typography variant="subtitle1" color="textSecondary">
            {/*TODO add format file*/}
            <span className="modal__alert__span">Attached: </span>
            {file.name}
          </Typography>
        </>
      }}
    </CreateEditModal>
  )
}

EditUploadModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  updateBankFile: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default EditUploadModal;
