import {withCurrency} from "../../utils";

export const headRows = [
  {id: 'id', numeric: false, disablePadding: true, label: '#', isActive: true},
  {id: 'date', numeric: false, disablePadding: true, label: 'Date', isActive: true},
  {id: 'type', numeric: false, disablePadding: true, label: 'Type', isActive: true,},
  {id: 'bank_account.entry_method', numeric: false, disablePadding: true, label: 'Entry Method', isActive: true},
  {id: 'description', numeric: false, disablePadding: true, label: 'Description', isActive: true},
  {id: 'category', numeric: false, disablePadding: true, label: 'Category', isActive: true,
    renderText: row => row.bank_account.name
  },
  {
    id: 'tax_code', numeric: false, disablePadding: true, label: 'Tax code', isActive: true,
    renderText: (row) => {
      switch (row.tax_code) {
        case 1:
          return "NV";
        case 2:
          return "ST";
        case 3:
          return "RR";
        case 4:
          return "EX";
        case 5:
          return "ES";
        default:
          return "EG";
      }
    },
  },
  {id: 'total_amount', numeric: false, disablePadding: true, label: 'Total', isActive: true,
    renderText: row => withCurrency(row.total_amount, row.bank_account.currency.currency_symbol),
  },
  {id: 'actions', numeric: false, disablePadding: true, label: 'Action', isActive: true}
];