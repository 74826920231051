import React, {useState} from "react";
import {Formik} from "formik";
import {connect} from "react-redux";
import {TextField, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import {injectIntl} from "react-intl";
import * as auth from "../../store/ducks/auth.duck";
import {validate, handleSubmit} from "./utils";
import { ReactComponent as MailIcon } from "../../media/icons/mail.svg";

const renderHeader = ({subtitle}) => (
  <div className="kt-login__title">
    <Typography className="onboarding__title">Forgotten your password?</Typography>
    <Typography className="onboarding__subtitle">
      {subtitle}
    </Typography>
  </div>
);

const renderForm = ({bottom}) => props => {
  const {
    values,
    status,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    isValid,
  } = props;
  return (
    <form onSubmit={handleSubmit} className="kt-form">
      {status && (
        <div role="alert" className="alert alert-danger">
          <div className="alert-text">{status}</div>
        </div>
      )}

      {/*{isRequested &&*/}
      {/*<div role="alert" className="alert alert-info">*/}
        {/*<div className="alert-text">We have emailed your password reset link</div>*/}
      {/*</div>*/}
      {/*}*/}

      <div className="form-group">
        <TextField
          type="email"
          label="Email"
          name="email"
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
          fullWidth
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.email}
          helperText={touched.email && errors.email}
          error={Boolean(touched.email && errors.email)}
        />
      </div>

      <div className="kt-login__actions full-size">
        <button
          type="submit"
          className="btn btn-primary btn-elevate kt-login__btn-primary"
          disabled={!isValid || isSubmitting}
        >
          Recover Password
        </button>
      </div>
      {bottom}
    </form>
  )
};

const bottom = (
  <div className="kt-login__actions full-size">
    <div className="kt-login__head">
      <span className="kt-login__signup-label">Return to</span>
      &nbsp;&nbsp;
      <Link to="/auth/login" className="kt-link kt-login__signup-link">
        Sign in
      </Link>
    </div>
  </div>
)

const ForgotPassword = ({intl}) => {
  const [isRequested, setIsRequested] = useState(false);

  const subtitle = isRequested
    ? 'We’ve sent you an email to help you reset your password. Remember to check your spam inbox if you can’t find it.'
    : 'Don’t worry, we’ll send you a message to help you reset your password.';

  return (
    <div className="kt-login__body reset-password">
        <div className="kt-login__form">
          {renderHeader({subtitle})}
          <Formik
            initialValues={{email: ""}}
            validate={validate(intl)}
            onSubmit={handleSubmit({setIsRequested, intl})}
          >
            {isRequested
              ? (
                <div>
                  {bottom}
                  <div className="kt-login__actions full-size">
                    <MailIcon />
                  </div>
                </div>
              )
              : renderForm({isRequested, bottom})}
          </Formik>
        </div>
      </div>
  );
}


export default injectIntl(
  connect(
    null,
    auth.actions
  )(ForgotPassword)
);
