import { createSelector } from "reselect";

const companyBase = () => state => state.company.company;

export const getCompanies = createSelector(
  companyBase(),
  (state) => state.companies,
);

export const getCompany = createSelector(
  companyBase(),
  (company) => company.company,
);

export const getVATSchemes = createSelector(
  companyBase(),
  (state) => state.VATSchemes,
);

export const getLoading = createSelector(
  companyBase(),
  (company) => company.loading,
);