import React, { useEffect, useState } from "react";
import TableComponent from "../../../components/TableComponent/TableComponent";
import { useModal } from "../../../../hooks/modal.hook";
import DownloadCustomerModal from "./DownloadCustomerModal";
import ReceiptUploadsModal from "../../../components/ReceiptUploadsModal/ReceiptUploadsModal";
import NewCustomerGroupModal from "./NewCustomerGroupModal";
import * as PropTypes from "prop-types";
import TransactionModal, { valuesTitle } from "../TransactionModal/TransactionModal";
import { headRowsModal } from "../EnterTransactionsBanking/headRowsModal";
import withConfirmHook from "../../../../hooks/withConfirm.hook";
import EditAddTransactionModal from "../CustomersSuppliers/EditAddTransactionModal";
import { goToCustomersInvoicesPage, goToImportDataPage } from "../utils";
import { useHistory } from "react-router-dom";
import { useNotification } from "../../../../hooks/notification.hook";
import useCompany from "../../../../hooks/company.hook";
import PopperMenu from "../../../components/PopperMenu";
import DButton from "../../../components/Button";
import useSuppliersCustomers from "../../../../hooks/suppliersCustomers.hook";
import DSplashScreen from "../../../components/SplashScreen/DSplashScreen";

function EnterTransactionsCustomers({ confirm, tabProps }) {
  const { headRows, rowsPerPage, reload, items, searchQuery, currentTab } = tabProps;
  const [isEditModalShow, toggleEditModal] = useModal();
  const [isDownloadModalShow, toggleDownloadModal] = useModal();
  const [isExportModalShow, toggleExportModal] = useModal();
  const [isReceiptUploadsModalShow, toggleReceiptUploadsModal] = useModal();
  const [isNewCustomerModalShow, toggleNewCustomerModal] = useModal();
  const [isNewCustomerGroupModalShow, toggleNewCustomerGroupModal] = useModal();
  const [currentRow, setCurrentRow] = useState(null);
  const [isTransactionModalShow, toggleTransactionModal] = useModal();
  const [successNotification, setIsShowNotification] = useNotification(false, "success", "Updated");
  const valueTab = valuesTitle.CUSTOMERS;
  const history = useHistory();
  const { company } = useCompany();
  const { customers, customersLoading, getCustomers, deleteCustomer } = useSuppliersCustomers({ fetch: false });

  useEffect(() => {
    getCustomers({ q: searchQuery });
  }, [reload, searchQuery]);

  const reset = () => setCurrentRow(null);
  const onEdit = (row) => () => {
    setCurrentRow(row);
    toggleEditModal(!isEditModalShow);
  };
  const onDownloadPDF = (row) => () => {
    setCurrentRow(row);
    toggleDownloadModal(!isDownloadModalShow);
  };

  const onAttachments = (row) => () => {
    setCurrentRow(row);
    toggleReceiptUploadsModal(!isReceiptUploadsModalShow);
  };

  const goToPage = (row) => () => {
    goToCustomersInvoicesPage(history, row.id);
  };

  const onDelete = (row) => (
    confirm(() => {
        deleteCustomer({
          id: row.id,
        })
      },
      { description: 'This action is permanent!' })
  );
  const onNameClick = (row) => () => {
    toggleTransactionModal(!isTransactionModalShow);
    setCurrentRow(row);
  };

  const onExportPDF = () => toggleExportModal(!isExportModalShow);
  const onNewInvoice = () => goToCustomersInvoicesPage(history);
  const onNewImports = () => goToImportDataPage(history, "Customer");

  const onSuccessNew = () => {
    toggleNewCustomerModal();
    setIsShowNotification(true)
  };

  const onSuccessEdit = () => {
    toggleEditModal();
    setIsShowNotification(true)
  };

  const actionsBottom = (<>
    <PopperMenu
        contentBtn={<DButton typeOfButton="addArrow">New</DButton>}
        popperProps={{ array: [
            { func: onNewInvoice, content:  "Invoice" },
            { func: toggleNewCustomerModal, content: "Customer"  },
            { func: toggleNewCustomerGroupModal, content: "Customer Group"  },
            { func: onNewImports, content: "Imports"  },
          ]}}
        styleEl="transactions"
      />
      <PopperMenu
        contentBtn={<DButton typeOfButton="searchArrow">View</DButton>}
        popperProps={{ array: [
            { func: () => {}, content:  "Invoice" },
            { func: () => {}, content: "Receivables"  },
            { func: () => {}, content: "Imports"  },
          ]}}
        styleEl="transactions"
      />
  </>);

  return (
    <div>
       <div className="d-splash-screen--wrap">
         {customersLoading && <DSplashScreen />}
         <TableComponent
           headRows={headRows}
           items={items(customers)}
           rowsPerPageSelected={rowsPerPage}
           loading={customersLoading}
           rowProps={{
             goToPage,
             onEdit,
             onDownloadPDF,
             onAttachments: (company && company.uses_receipt_upload) && onAttachments,
             onDelete,
             onNameClick
           }}
         />
       </div>
      <div className="container-button">{actionsBottom}</div>
      {currentRow &&
      <EditAddTransactionModal
        show={isEditModalShow}
        onClose={toggleEditModal}
        initValues={currentRow}
        currentTab={currentTab}
        onSuccess={onSuccessEdit}
      />
      }
      {currentRow &&
      <DownloadCustomerModal show={isDownloadModalShow} onClose={toggleDownloadModal} data={currentRow} />}
      {currentRow &&
      <ReceiptUploadsModal show={isReceiptUploadsModalShow} onClose={toggleReceiptUploadsModal} data={currentRow} />}
      <EditAddTransactionModal
        show={isNewCustomerModalShow}
        onClose={toggleNewCustomerModal}
        onSuccess={onSuccessNew}
        currentTab={currentTab}
      />
      <NewCustomerGroupModal show={isNewCustomerGroupModalShow} onClose={toggleNewCustomerGroupModal} />
      {currentRow &&
      <TransactionModal
        data={currentRow}
        show={isTransactionModalShow}
        onClose={toggleTransactionModal}
        headRowsModal={headRowsModal}
        valueTab={valueTab}
        onExportPDF={onExportPDF}
        reset={reset}
      />
      }
      <DownloadCustomerModal show={isExportModalShow} onClose={toggleExportModal} />
      {successNotification}
    </div>
  )
}

EnterTransactionsCustomers.propTypes = {
  confirm: PropTypes.func.isRequired,
  headRows: PropTypes.array,
  reload: PropTypes.bool,
  rowsPerPage: PropTypes.number,
  searchQuery: PropTypes.string,
  items: PropTypes.func,
};

export default withConfirmHook(EnterTransactionsCustomers);
