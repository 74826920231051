export const shortBaseState = {
  details: [],
  loading: false,
};

export const shortBaseStateObject = {
  details: {},
  loading: false,
};

export const baseListState = {
  details: [],
  loading: false,
  totalCount: 0,
};

export const baseListStateRes = {
  results: [],
  loading: false,
};

export const fullBaseListStateRes = {
  ...baseListStateRes,
  totalCount: 0
};