import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import queryString from 'query-string';

const useTab = (defaultTab) => {
  let history = useHistory();
  const {tab: hashTab} = queryString.parse(history.location.hash);
  const [valueTab, setValueTab] = useState(hashTab || defaultTab);
  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
    history.push({search: `#tab=${newValue}`});
  };
  return [valueTab, handleChangeTab]
};

export default useTab;