import {requests, withCompany} from "./utils";

export default {
    getBanks: withCompany( (companyId, q) => requests.get(`/companies/${companyId}/banks/?q=${q}`)),
    getBank: withCompany((companyId, id) => requests.get(`/companies/${companyId}/banks/${id}`)),
    deleteBank: withCompany((companyId, id) => requests.del(`/companies/${companyId}/banks/${id}`)),
    addBank: withCompany((companyId, values) => requests.post(`/companies/${companyId}/banks`, values)),
    updateBank: withCompany((companyId, id, values) => requests.put(`/companies/${companyId}/banks/${id}`, values)),
    batchCreateTransaction: (data) => requests.post(`/bank_transactions/batch_create`, data),

    //customers
    getCustomers: (q) => requests.get(`/customers/?q=${q}`),
    updateCustomer: (id, values) => requests.put(`/customers/${id}`, values),
    addCustomer: (values) => requests.post(`/customers`, values),
    deleteCustomer: (id) => requests.del(`/customers/${id}`),

    //suppliers
    //TODO waiting companyId from back-end side
    getSuppliers: (q) => requests.get(`/suppliers/?q=${q}`),
    updateSupplier: (id, values) => requests.put(`/suppliers/${id}`, values),
    addSupplier: (values) => requests.post(`/suppliers`, values),
    deleteSupplier: (id) => requests.del(`/suppliers/${id}`),

    //rules
    getBankRules: () => requests.get('/bank-rules'),
    getBankRule: (id) => requests.get(`/bank-rules/${id}`),
    updateBankRule: (id, values) => requests.put(`/bank-rules/${id}`, values),
    addBankRule: (values) => requests.post(`/bank-rules`, values),
    deleteBankRule: (id) => requests.del(`/bank-rules/${id}`),


    //files
    getBankFiles: withCompany((companyId) => requests.get(`/companies/${companyId}/bank-files`)),
    uploadBankFile: withCompany(
      (companyId, body) => requests.file(`/companies/${companyId}/bank-files`, body)
    ),
    updateBankFile: withCompany(
      (companyId, id, data) => requests.patch(`/companies/${companyId}/bank-files/${id}`, data)),
};
