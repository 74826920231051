import React from "react";
import axios from "axios";
import toast from "../../components/toast/toast";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import debounce from "@material-ui/core/utils/debounce";

export const ENDPOINT_PPP = 'https://api.profitprotectorpro.com/api';

export const defaultTablePerPage = 15;
export const initPPPData ={
  totalSKUs: 0,
  Products: [],
  defaultMarketplace: '',
  visibleMarketplaces: [],
};

export const initParams = {
  itemsPerPage: defaultTablePerPage,
  pageNumber: 1,
  apiKey: "",
  ppp_api_key: "",
  email: "",
  sort: 'az',
  filter: 'All products',
};

export const getGeneralData = async (setData, param, key) =>
  await axios.post(`${ENDPOINT_PPP}/${param}.php`, {}, {
    headers: {
      'X-Authorization':'D227-93F7-A61E-B73D-B720',
    }
  })
  .then(res => {
    const data = res?.data;
    if (data?.isSuccess) {
      setData(res.data[key]);
    } else if (data?.Error || data?.message) {
      const errorData = data.Error || data.message;
      toast.error(errorData, { duration: 3000 })
    }
  })
  .catch(e => toast.error(e));

const baseConfig = ({ apiKey, path, data, extraHeaders, type, ppp_api_key }) => {
  const qs = require('qs');
  return (
    {
      method: 'post',
      url: `${ENDPOINT_PPP}/${path}.php`,
      headers: {
        'x-api-key': apiKey,
        'X-Authorization':'D227-93F7-A61E-B73D-B720',
        extraHeaders
      },
      data: type
        ? data
        : qs.stringify({
        ...data,
      }),
      ppp_api_key
    }
  );
};

const baseResponse = ({ config, setData, updateTable }) => {
  const {ppp_api_key, ...axiosConfig} = config;

  axios(axiosConfig)
    .then( (response) => {
      const data = response?.data;
      setData && setData(data);
      updateTable && updateTable(data);
      if (!data?.isSuccess && !ppp_api_key) {
        const errorData = data?.Error || data?.message || '';
        toast.error(errorData, { duration: null })
      }
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const getProductsData = async ({ params, setPppData }) => {
  const { pageNumber, itemsPerPage, apiKey, email, filter, sort, search, marketplaces, ppp_api_key } = params;
  const config = { email, itemsPerPage, pageNumber, filter, sort, search }
  if (marketplaces) {
    config['marketplaces'] = JSON.stringify([marketplaces])
  }
  baseResponse({
    config: baseConfig({
      apiKey,
      path: "lookup_product",
      data: config,
      ppp_api_key
    }),
    setData: setPppData
  })
};

export const getOptionsData = async ({ params, setFunction }) => {
  const { apiKey, email, ppp_api_key } = params;
  baseResponse({
    config: baseConfig({
      apiKey,
      path: "get_strategies",
      data: { email },
      ppp_api_key
    }) ,
    setData: setFunction
  })
};

export const updateProduct = async ({ params, type, updateTable }) => {
  const { apiKey, data, ppp_api_key } = params;
  baseResponse({
    config: baseConfig({
      apiKey,
      path: "update_prices",
      data,
      type,
      ppp_api_key
    }),
    updateTable,
  })
};

export const deleteProduct = async ({ params }) => {
  const { apiKey, data, ppp_api_key } = params;
  baseResponse({
    config: baseConfig({
      apiKey,
      path: "delete_product",
      data,
      ppp_api_key
    }),
  })
};

export const autoMinMax = async ({ params, setData }) => {
  const { apiKey, data, ppp_api_key } = params;
  baseResponse({
    config: baseConfig({
      apiKey,
      path: "auto_min_max",
      data,
      ppp_api_key
    }),
    setData,
  })
};

export const updateMarketplace = ({ credentials, row, value, updateTable }) => {
  const { SKU, ASIN, MarketplaceID } = row;
  const data = JSON.stringify({
    email: credentials.email,
    SKU,
    ASIN,
    Marketplaces: [{ MarketplaceID, ...value}],
  });
  updateProduct({ params: { apiKey: credentials.apiKey, data }, type: "JSON", updateTable});
};

export const updateNoteMarketplace = ({ credentials, row, onRefresh }) => ({ values, onDone }) => {
  const updateTable = () => onRefresh(row.id, { NoteValue: values.note || "" });
  updateMarketplace({ ...credentials, row, onDone, value: { NoteValue: values.note || ""}, updateTable });
  onDone();
};

export const AutoRefresh = ({ updatedData, indexField, marketplace }) => {
  const { setFieldValue } = useFormikContext();
  useEffect(() => {
    if (updatedData.data && !Array.isArray(updatedData.data)) {
      if (updatedData.name === "MinPrice") {
        setFieldValue(`MinPrice--${indexField}`, updatedData.data[`${marketplace}-MinPrice`]);
      }

      if (updatedData.name === "MaxPrice") {
        setFieldValue(`MaxPrice--${indexField}`, updatedData.data[`${marketplace}-MaxPrice`]);
      }

      if (!updatedData.name) {
        setFieldValue(`MinPrice--${indexField}`, updatedData.data[`${marketplace}-MinPrice`]);
        setFieldValue(`MaxPrice--${indexField}`, updatedData.data[`${marketplace}-MaxPrice`])
      }
    }

    if (updatedData.breakEvenData?.[`${marketplace}-BreakEvenVal`]) {
      setFieldValue(`BreakEvenPrice--${indexField}`,updatedData.breakEvenData[`${marketplace}-BreakEvenVal`]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedData]);
  return null;
};

export const defSortOptions = [
  {
    "SortName": "az",
    "DisplayName": "A-Z",
    value: "item_name"
  },
  {
    "SortName": "za",
    "DisplayName": "Z-A",
    value: "-item_name"
  },
  {
    "SortName": "qty",
    "DisplayName": "Quantity",
    value: "-StockLevel"
  },
  {
    "SortName": "strategy",
    "DisplayName": "Sort by Strategy",
    value: "StrategyID"
  },
  {
    "SortName": "pricelh",
    "DisplayName": "Price Low - High",
    value: "SellerPrice",
  },
  {
    "SortName": "pricehl",
    "DisplayName": "Price High - Low",
    value: "-SellerPrice",
  },
  {
    DisplayName: "Break Even Ascending",
    SortName: "breakevenasc",
    value: "BreakEvenPrice",
  },
  {
    DisplayName: "Break Even Descending",
    SortName: "breakevendesc",
    value: "-BreakEvenPrice",
  },
  {
    DisplayName: "Low FBA Ascending",
    SortName: "lowfbaasc",
    value: "LowFBAPrice",
  },
  {
    DisplayName: "Low FBA Descending",
    SortName: "lowfbadesc",
    value: "-LowFBAPrice",
  }
];

export const COUNTRY_LABEL = {
  'UK': 'United Kingdom',
  'US': 'United States',
};

export  const onShowNotifications = ({ setIdCurrentRow, marketplaceValues, id, name }) => (data) => {
  if (data.MarketplacesUpdated && (!!marketplaceValues.MinPrice && !!marketplaceValues.MaxPrice) ) {
    setIdCurrentRow({ id, param: name });
    setTimeout(() => {
      setIdCurrentRow({});
    }, 10000)
  }

  toast.success(`${name.split(/(?=[A-Z])/).join(' ')} Saved!`, { duration: 3000 });
};

export const handlerSearchDebounce = ({ms, handleQueryParams}) => debounce((search) => {
  handleQueryParams({search})
}, ms || 500);

export const handleChangeFilter = ({handleQueryParams}) => (_filter) => {
  let filter = _filter.value === 'All products' ? '' : _filter.value;
  handleQueryParams({filter});
};

export const handleChangeSort = ({handleQueryParams}) => (_sort) => {
  let sort = _sort === 'Oldest - Newest' ? 'date' :  _sort.value;
  handleQueryParams({sort});
};

export const handlerSelectedMarketplace= ({handleQueryParams, eMarketplaces}) => (_marketplace) => {
  const marketplaceID = eMarketplaces.find(({ CountryCode }) => _marketplace === CountryCode )?.MarketplaceID;
  let marketplaces = _marketplace === 'all_marketplaces' ? '' : marketplaceID;
  handleQueryParams({marketplaces});
};
