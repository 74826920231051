import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import * as SettingsDuckSelector from '../selectors/settings.selectors'
import * as settingsDuck from '../store/ducks/settings.duck'
import toastr from "../pages/components/toast/toast";

export const onErrorDefault = (e) => toastr.error(e, {duration: 5000}, {isClose: false})

const useSettings = (props = {}) => {
  const {
    fetch = true,
    cog,
    vat,
    marketplace,
    shipping,
  } = props

  const dispatch = useDispatch()
  useEffect(() => {
    if (fetch) {
      cog && dispatch(settingsDuck.actions.getSettings())
      vat && dispatch(settingsDuck.actions.getVATSettings())
      marketplace && dispatch(settingsDuck.actions.getMarketplaces())
      shipping && dispatch(settingsDuck.actions.getShippingPlans())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch])

  // cog
  const addSetting = (args) => {
    dispatch(settingsDuck.actions.addSetting(args))
  }

  const updateSetting = (args) => {
    dispatch(settingsDuck.actions.updateSetting(args))
  }

  // vat
  const addVATSetting = (args) => {
    dispatch(settingsDuck.actions.addVATSetting(args))
  }

  const updateVATSetting = (args) => {
    dispatch(settingsDuck.actions.updateVATSetting(args))
  }
  
  // roi
  const getROISetting = () => {
    dispatch(settingsDuck.actions.getROISettings())
  }
  const updateROISetting = (method) => {
    dispatch(settingsDuck.actions.updateROISettings(method))
  }
  
  // account
  const getAccountSettings = (args) => {
    dispatch(settingsDuck.actions.getAccountSettings(args))
  }
  const updateAccountSetting = (args) => {
    dispatch(settingsDuck.actions.updateAccountSetting(args))
  }

  // Amazon
  const updateAmazonSetting = (args) => {
    dispatch(settingsDuck.actions.updateAmazonSetting(args))
  }
  const deleteAmazonSetting = (args) => {
    dispatch(settingsDuck.actions.deleteAmazonSetting(args))
  }
  
  const autoAddCog = (args) => {
    dispatch(settingsDuck.actions.autoAddCog(args))
  }

  // shipping plans
  const getShippingPlans = (args) => {
    dispatch(settingsDuck.actions.getShippingPlans({...args, onError: onErrorDefault}))
  }

  const updateShippingPlans = (args) => {
    dispatch(settingsDuck.actions.updateShippingPlans(args))
  }

  const getOptionsShippingPlans = (args) => {
    dispatch(settingsDuck.actions.getOptionsShippingPlans(args))
  }

  const updatePassword = (args) => {
    dispatch(settingsDuck.actions.updatePassword(args))
  }

  const onWalk = (args) => {
    dispatch(settingsDuck.actions.onWalk(args))
  }

  const firstOnWalk = (args) => {
    dispatch(settingsDuck.actions.firstOnWalk(args))
  }
    
  const settings = SettingsDuckSelector.getSettings(useSelector(state => state))
  const vatSettings = SettingsDuckSelector.getVATSettings(useSelector(state => state))
  const roiSettings = SettingsDuckSelector.getROISettings(useSelector(state => state))
  const accountSettings = SettingsDuckSelector.getAccountSettings(useSelector(state => state))
  const amazonSettings = SettingsDuckSelector.getAmazonSettings(useSelector(state => state))
  const marketplaces = SettingsDuckSelector.getMarketplaces(useSelector(state => state))
  const shippingPlans = SettingsDuckSelector.getShippingPlans(useSelector(state => state))
  const optionsShippingPlans = SettingsDuckSelector.getOptionsShippingPlans(useSelector(state => state))
  const updateUserPassword = SettingsDuckSelector.updatePassword(useSelector(state => state))
  const walk = SettingsDuckSelector.onWalk(useSelector(state => state))
  const firstWalk = SettingsDuckSelector.firstWalk(useSelector(state => state))

  return {
    settings: settings.details,
    loading: settings.loading,
    updateSetting,
    addSetting,

    vatSettings: vatSettings.details,
    vatLoading: vatSettings.loading,
    updateVATSetting,
    addVATSetting,
    
    roiSettings: roiSettings.details,
    roiLoading: roiSettings.loading,
    getROISetting,
    updateROISetting,

    accountSettings: accountSettings.details,
    accountLoading: accountSettings.loading,
    getAccountSettings,
    updateAccountSetting,

    amazonSettings: amazonSettings.details,
    amazonLoading: amazonSettings.loading,
    updateAmazonSetting,
    deleteAmazonSetting,

    marketplaces: marketplaces.details,
    marketplacesLoading: marketplaces.loading,
    autoAddCog,

    shippingPlans: shippingPlans.details,
    shippingPlansLoading: shippingPlans.loading,
    getShippingPlans,
    updateShippingPlans,

    options: optionsShippingPlans.details,
    getOptionsShippingPlans,

    updatePassword,
    updatePasswordLoading: updateUserPassword.loading,

    onWalk,
    walk,

    firstOnWalk,
    firstWalk
  }
};

export default useSettings;
