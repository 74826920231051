import React, { useEffect } from 'react';
import { FieldArray, Form as FormikForm, Formik } from "formik";
import FormField from "../../../components/controls/FormField";
import { requiredValidator } from "../../../components/controls/validators";
import Form from "react-bootstrap/Form";
import { Card, CardHeader, CardContent, Typography } from "@material-ui/core";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import RemoveIcon from '@material-ui/icons/Remove';
import MenuIcon from '@material-ui/icons/Menu';
import DButton from "../../../components/Button";
import { useHistory } from "react-router-dom";
import { goToBankRulesPage } from "./utils";
import Wrapper from "../../../components/Wrapper";
import { connect } from "react-redux";
import * as PropTypes from "prop-types";
import * as Selector from "../../../../selectors/bank.selectors";
import * as categoryDuck from "../../../../store/ducks/category.duck";
import * as BankDuck from "../../../../store/ducks/bunk.duck";
import * as commonDuck from "../../../../store/ducks/common.duck";
import * as commonSelectors from "../../../../selectors/common.selectors";
import * as CategorySelector from "../../../../selectors/category.selectors";
import { useParseHistory } from "../../../../hooks/modal.hook";
import { useSubmit } from "../../../../hooks/submit.hook";
import Notification from "../../../components/Notification";
import { fieldsRule, conditions } from "./options";
import toast from "../../../components/toast/toast";
import Spinner from "../../Transaction/NewBankTransaction/ImportBankTransactions/EditCustomFile";
import { DFab } from "../../../components/TableComponent/TableToolbarActions";

const initCondition = {
  subscription: null,
  condition: null,
  field: null,
};

const getConditionFieldName = (index, name) => `conditions.${index}.${name}`;

const EditBankRule = (props) => {
  const {
    getCategories,
    categories,
    getBanks,
    banks,
    getTaxes,
    taxes,
    getBankRule,
    bankRule,
    bankRuleLoading,
    updateBankRule,
    addBankRule,
    inModal, // we can show this Form in modal or as a separated page
    onClose,
  } = props;
  let { bankRuleId, initialData } = props;

  const [errors, isErrorsShow, toggleErrorsShow, onSubmitFormClick] = useSubmit("bankRule");
  let history = useHistory();
  bankRuleId = useParseHistory().bank_rule_id || bankRuleId;


  useEffect(() => {
    getBanks();
    getCategories();
    getTaxes();
    if (bankRuleId) {
      getBankRule({id: bankRuleId});
    }
  }, []);

  initialData = initialData || {
    conditions: [
      initCondition,
    ],
  };

  if (bankRuleId && bankRuleLoading) {
    return <Spinner className="d-spinner--full-height"/>
  }

  if (bankRuleId && bankRule) {
    initialData = {
      ...initialData,
      ...bankRule,
      // name: bankRule.name,
      // description: bankRule.description,
      // subscription: bankRule.name,
      // conditions: bankRule.conditions,
    }
  }

  const mainFields = [
    {
      id: 'name',
      label: 'Name',
      type: 'string',
      validator: requiredValidator('Name is required'),
    },
    { id: 'description', label: 'Description', type: 'string' },
  ];

  const conditionsFields = [
    { id: 'field', label: 'Field', type: 'RSelect',
      validator: requiredValidator('Conditions target is required'),
      customProps: () => ({ index, setFieldValue, values }) => {
        const onChange = (value) => {

          // set value for RSelect with id "field"
          setFieldValue(getConditionFieldName(index, 'field'), value);

          // reset value for RSelect with id "condition" if its options are not suitable for the value of RSelect with id "field"
          // values.conditions[index].condition - value RSelect with id "condition"
          const conditionValue = values.conditions[index].condition;
          const option = conditionValue  && conditions.find(condition => condition.value === conditionValue.value);
          if (conditionValue && conditionValue.value && option && !option.for_fields.includes(value.value)) {
            setFieldValue(getConditionFieldName(index, 'condition'), []);
          }
        };

        return { options: fieldsRule, onChange }
      },
    },
    {
      id: 'condition',
      label: 'Condition',
      type: 'RSelect',
      validator: requiredValidator('Conditions matcher is required'),
      customProps: () => ({ index, setFieldValue, values }) => {
        let options = [];
        const fields = values.conditions[index].field && values.conditions[index].field.value;
        // Money In
        // Money Out
        // Description
        // Default All
        switch (fields) {
          case 'MI':
          case 'MO':
            options = conditions.filter(conditions => conditions.for_fields.includes("MO"));
            break;
          case 'description':
            options = conditions.filter(conditions => conditions.for_fields.includes("description"));
            break;
          default:
            options = conditions;
        }
        const onChange = (value) => {
          setFieldValue(getConditionFieldName(index, 'condition'), value);
        };

        return {
          options,
          onChange,
        }
      }
    },
    { id: 'subscription', label: 'Subscription', type: 'string' },
  ];

  const conditionsSelected = [
    { id: 'bank_accounts', label: 'Bank account', type: 'MSelect',
      // validator: requiredValidator('Bank account is required'),
      customProps: { options: banks.map(({ name, id }) => ({label: name, value: id})) }
    },
    {
      id: 'assign_category',
      label: 'Assign to this category ',
      type: 'RSelect',
      validator: requiredValidator('Category account is required'),
      customProps: { options: categories.map(({ name, id }) => ({label: name, value: id})) }
    },
    {
      id: 'assign_tax_code',
      label: 'Assign to tax code',
      type: 'RSelect',
      customProps: {
        byId: true,
        options: taxes.map(tax => (
          { label: tax.taxcode + " | " + tax.name + ' - ' + tax.tax_rate + "%", value: tax.id }
          )),
      },
    },
  ];

  const conditionsCheckboxes = [
    {
      id: 'auto_confirm_transactions',
      label: 'Auto confirm transactions',
      type: 'checkbox',
      showLabel: true,
      customProps: () => ({ setFieldValue, values }) => {
        const onChange = (event) => {
          setFieldValue("auto_confirm_transactions", event.target.checked);
          if (event.target.checked === true) {
            setFieldValue("auto_ignore_transactions", !event.target.checked);
          }
        };
        return { onChange }
      }
    },
    {
      id: 'auto_ignore_transactions',
      label: 'Auto ignore transactions',
      type: 'checkbox',
      showLabel: true,
      customProps: () => ({ setFieldValue, values }) => {
        const onChange = (event) => {
          setFieldValue("auto_ignore_transactions", event.target.checked);
          if (event.target.checked === true) {
            setFieldValue("auto_confirm_transactions", !event.target.checked);
          }
        };
        return { onChange }
      }
    },
  ];

  const onBack = () => goToBankRulesPage(history);

  const addNewCondition = (arrayHelpers) => () => arrayHelpers.push(initCondition);

  const removeCondition = (arrayHelpers, index) => () => arrayHelpers.remove(index);

  const errorNotification = errors && isErrorsShow &&
    <Notification onClose={toggleErrorsShow} errors={errors} name="bank rule"/>;

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialData}
        onSubmit={(values, {setSubmitting, resetForm}) => {
          const data = {...values};

          data.conditions = data.conditions.map((condition) => ({
            ...condition,
            condition: condition.condition.value,
            field: condition.field.value,
          }));

          data.assign_category = data.assign_category.value;
          data.assign_tax_code = data.assign_tax_code.value;
          // data.bank_accounts = valueMultiple;

          const onDone = () => {
            setSubmitting(false);
            resetForm();

            if(inModal) {
              onClose()
            } else {
              onBack();
            }
          };
          const onError = (err) => {
            // alert(JSON.stringify(err.response.data));
            toast.error(err);
            setSubmitting(false);
          };
          if (bankRule) {
            updateBankRule({
              id: bankRuleId,
              values: data,
              onDone,
              onError,
            });
          } else {
            addBankRule({
              values: data,
              onDone,
              onError,
            })
          }
        }}
      >
        {({...form}) => {
          return (
            <FormikForm>
              <Wrapper>
                <div className="edit-bank-rule ">
                  {/*title for page*/}
                  <Typography gutterBottom variant="h5" component="h2">
                    <MenuIcon/>
                    Bank Rule
                    <Typography component="p">
                      Here you can edit a bank rule. Please consider the notes below when editing a bank rule.
                    </Typography>
                  </Typography>
                  {errorNotification}
                  <div className="row edit-bank-rule__content">
                    <div className="col-sm-4">
                      <div className="row">
                        <div className="col-sm-12">
                          {
                            mainFields.map((item) => (
                              <Form.Group key={item.id} controlId={item.id}>
                                <Form.Label>{item.label}</Form.Label>
                                <FormField
                                  name={item.id}
                                  label={item.label}
                                  type={item.type}
                                  validate={item.validator}
                                  {...form}
                                />
                              </Form.Group>
                            ))
                          }
                        </div>

                        <Card className="col-sm-12 edit-bank-rule__inform ">
                          <div className="inform__header">
                            <ErrorOutlineIcon/>
                            <CardHeader title="How bank rules work" className="inform__title"/>
                          </div>
                          <CardContent className="inform__content">
                            <Typography variant="body2" color="textSecondary" component="p">
                              Please select the rule conditions and then how you would
                              like the transactions to be treated based on those conditions.
                            </Typography>
                          </CardContent>
                        </Card>

                        <Card className="col-sm-12 edit-bank-rule__inform ">
                          <div className="inform__header">
                            <ErrorOutlineIcon/>
                            <CardHeader title="Please note" className="inform__title"/>
                          </div>
                          <CardContent className="inform__content">
                            <Typography variant="body2" color="textSecondary" component="p">
                              If you select auto confirm, your transactions will be processed
                              automatically although you can still check through them later.
                            </Typography>
                          </CardContent>
                        </Card>
                      </div>
                    </div>
                    <div className="offset-sm-1 col-sm-7 edit-bank-rule__conditions">
                      <div className="row">
                        <Typography component="p">Rule conditions</Typography>
                      </div>
                      <FieldArray
                        name="conditions"
                        render={(arrayHelpers) => {
                          return <>
                            {
                              form.values.conditions.map((values, index) => {
                                return <div key={index} className="row edit-bank-rule__table">
                                  {
                                    conditionsFields.map((item) => (
                                      <Form.Group key={item.id} controlId={item.id} className="edit-bank-rule__form-group">
                                        <FormField
                                          name={`conditions.${index}.${item.id}`}
                                          label={item.label}
                                          type={item.type}
                                          index={index}
                                          validate={item.validator}
                                          customProps={item.customProps && item.customProps()}
                                          {...form}
                                        />
                                      </Form.Group>
                                    ))
                                  }
                                  <DFab key={index} onClick={removeCondition(arrayHelpers, index)} name="remove row">
                                    <RemoveIcon/>
                                  </DFab>
                                </div>
                              })
                            }
                            <div className="container-button container-button--modal edit-bank-rule__btn">
                              <DButton typeOfButton="add" onClickCustom={addNewCondition(arrayHelpers)}>
                                Add condition
                              </DButton>
                            </div>
                          </>
                        }}
                      />
                      <div className="row edit-bank-rule__table edit-bank-rule__secondTable">
                        {
                          conditionsSelected.map((item) => (
                            <Form.Group key={item.id} controlId={item.id} className="edit-bank-rule__selected">
                              <Form.Label>{item.label}</Form.Label>
                              <FormField
                                name={item.id}
                                label={item.label}
                                type={item.type}
                                validate={item.validator}
                                customProps={item.customProps}
                                {...form}
                              />
                            </Form.Group>
                          ))
                        }
                      </div>
                      <div className="row edit-bank-rule__table">
                        {conditionsCheckboxes.map((item) => (
                          <Form.Group key={item.id} controlId={item.id} className="edit-bank-rule__checkbox">
                            <FormField
                              name={item.id}
                              label={item.label}
                              showLabel={item.showLabel}
                              type={item.type}
                              customProps={item.customProps && item.customProps()}
                              {...form}
                            />
                          </Form.Group>
                        ))
                        }
                      </div>
                    </div>
                  </div>

                  <div className="container-button container-button--modal row edit-bank-rule__bottom-btns">
                    {inModal ? <DButton typeOfButton="back" onClickCustom={onClose}>Close</DButton>: <DButton typeOfButton="back" onClickCustom={onBack}>Back</DButton> }
                    <DButton typeOfButton="doneAll" onClickCustom={onSubmitFormClick(form)}>
                      {bankRuleId ? "Update Rule" : "Apply this bank rule"}
                    </DButton>
                  </div>
                </div>
              </Wrapper>
            </FormikForm>
          )
        }}
      </Formik>
    </div>
  )
};

EditBankRule.propTypes = {
  getCategories: PropTypes.func.isRequired,
  addBankRule: PropTypes.func.isRequired,
  updateBankRule: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  getTaxes: PropTypes.func.isRequired,
  taxes: PropTypes.array.isRequired,
  getBanks: PropTypes.func.isRequired,
  banks: PropTypes.array.isRequired,
  getBankRule: PropTypes.func,
  bankRule: PropTypes.object,
  // if in modal
  inModal: PropTypes.bool,
  onClose: PropTypes.func,
  bankRuleId: PropTypes.number,
  initialData: PropTypes.object,
};

const mapStateToProps = (state, props) => {
  return {
    categories: CategorySelector.getCategories(state, props),
    bankRule: Selector.selectBankRule(state, props),
    bankRuleLoading: Selector.selectBankRuleLoading(state, props),
    banks: Selector.getBanks(state, props),
    taxes: commonSelectors.getTaxes(state),
  }
};

const mapDispatchToProps = {
  getCategories: categoryDuck.actions.getCategories,
  addBankRule: BankDuck.actions.addBankRule,
  updateBankRule: BankDuck.actions.updateBankRule,
  getBankRule: BankDuck.actions.getBankRule,
  getBanks: BankDuck.actions.getBanks,
  getTaxes: commonDuck.actions.getTaxes,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditBankRule);

