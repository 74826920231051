import React from "react";
// import { createMuiTheme } from "@material-ui/core";
import {createTheme} from "@material-ui/core/styles";
import {ThemeProvider as MuiThemeProvider} from "@material-ui/styles";
import {useThemeSwitcher} from "react-css-theme-switcher";
import {Themes} from "../../app/types/enums";
import {lightTheme, darkTheme} from "../utils/utils";


const theme = (isDark) =>  createTheme(
  /**
   * @see https://material-ui.com/customization/themes/#theme-configuration-variables
   */
  {
    // direction: "rtl",
    typography: {
      fontFamily: ["Manrope"].join(",")
    },

    palette: isDark ? darkTheme : lightTheme,

    /**
     * @see https://material-ui.com/customization/globals/#default-props
     */
    props: {
      // Name of the component ⚛️
      MuiButtonBase: {
        // The properties to apply
        disableRipple: true // No more ripple, on the whole application 💣!
      },

      // Set default elevation to 1 for popovers.
      MuiPopover: {
        elevation: 1
      }
    }
  }
);

export default function ThemeProvider(props) {
  const {children} = props;
  const {currentTheme} = useThemeSwitcher();

  return <MuiThemeProvider theme={theme(currentTheme === Themes.dark)}>{children}</MuiThemeProvider>;
}
