import React from 'react';
import * as PropTypes from "prop-types";
import CreateEditModal from "../../../components/CreateEditModal";
import Form from "react-bootstrap/Form";
import FormField from "../../../components/controls/FormField";
import { requiredValidator } from "../../../components/controls/validators";
import { entryMethodOptions } from "../bankOptions";
import toast from "../../../components/toast/toast";
import BtnEntryMethod from "./BtnEntryMethod";
import FormComponent from "../../../components/controls/FormComponent";

const getSelectValue = (value) => {
  if (value && value.label) {
    return value.value;
  }
  return value;
};
function EnterMethodModal({ show, onClose, initValues, updateBank, reset }) {

  const formFields = [
    {
      id: 'entry_method',
      label: 'Entry Method',
      type: 'RSelect',
      validator: requiredValidator('Entry Method is required'),
      render: ({ setFieldValue, customProps, ...props }) => {
        const handleChange = (value) => {
          setFieldValue(props.name, value);
        };
        return <>
          <FormComponent customProps={{ onChange: handleChange, options: entryMethodOptions }} {...props} />
          {/*buttons for options Stripe Feed and Bank Feed at the entry_method*/}
          <BtnEntryMethod value={props.value} values={props.values} setFieldValue={setFieldValue} />
        </>
      },
    },
  ];

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    const data = {...values};
    const onDone = () => {
      setSubmitting(false);
      onClose();
      resetForm();
      reset();
    };
    const onError = (error) => {
      toast.error(error);
      // alert(error);
      setSubmitting(false);
    };
    data.entry_method = getSelectValue(values.entry_method);
    data.currency = data.currency.id;
      updateBank({
        id: initValues.id,
        values: data,
        onDone,
        onError,
      });
  };
  return (
    <CreateEditModal
      modalTitle="Select Entry Method"
      onSubmit={onSubmit}
      onClose={onClose}
      show={show}
      className="modal--small"
      initValues={initValues} >
      {(form) => (
        formFields.map((item) => (
          <Form.Group key={item.id} controlId={item.id}>
            <Form.Label>{item.label}</Form.Label>
            <FormField
              render={item.render}
              name={item.id}
              label={item.label}
              type={item.type}
              validate={item.validator}
              {...form}
            />
          </Form.Group>
        ))
      )}
    </CreateEditModal>
  )
}

EnterMethodModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  updateBank: PropTypes.func.isRequired,
  reset: PropTypes.func,
  show: PropTypes.bool.isRequired,
  initValues: PropTypes.object.isRequired,
};


export default EnterMethodModal;
