import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Form, Formik} from "formik";
import DButton from "../../../../components/Button";
import TableComponent from "../../../../components/TableComponent/TableComponent";
import TableCell from "@material-ui/core/TableCell";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Typography from "@material-ui/core/Typography";
import Wrapper from "../../../../components/Wrapper";
import {goToEditCustomFilePage} from "../../utils";
import {useHistory} from "react-router-dom";
import {useParseHistory} from "../../../../../hooks/modal.hook";
import * as importDuck from "../../../../../store/ducks/import.duck";
import * as Selector from "../../../../../selectors/imortBankTransactions.selectors";
import {connect} from "react-redux";
import Spinner from "../../../../components/Spinner";


const UploadExampleFile = ({ customImport, getCustomImport, updateImport }) => {
  const history = useHistory();
  const idImport = useParseHistory().id;
  useEffect(() => {
    getCustomImport({ id: idImport });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idImport]);

  const [activeHeading, setActiveHeading] = useState(null);
  const [activeFirstDate, setActiveFirstDate] = useState(null);

  if (customImport.loading || !customImport.details) {
    return <Spinner className="d-spinner--full-height"/>
  }
  const items = customImport.details.example.map((row, index) => ({
    id: index,
    row: row,
  }));

  const headRows = [
    {
      id: 'row',
      label: 'Row',
      renderText: ({ id, row }) => {
        return row.join(", ");
      }
    }, {
      id: 'headings', label: 'Headings?',
      render: (row, {onHeading}) => {
        return <TableCell align="center" key={`${row.id}--headings`} className="table__cell--radio">
          <FormControlLabel control={<Radio/>} onChange={onHeading(row)} checked={activeHeading === row.id}/>
        </TableCell>
      }
    },
    {
      id: 'first_row_of_data', label: 'First Row Of Data?',
      render: (row, {onFirstDate}) => {
        return <TableCell align="center" key={`${row.id}--first_row_of_data`} className="table__cell--radio">
          <FormControlLabel control={<Radio/>} onChange={onFirstDate(row)} checked={activeFirstDate === row.id}/>
        </TableCell>
      }
    }
  ];

  const onHeading = (row) => () => {
    setActiveHeading(row.id)
  };

  const onCreate = (values, { setSubmitting }) => {
    const data = {
      header: customImport.details.example[activeHeading],
      first_row: customImport.details.example[activeFirstDate],
    };
    const onDone = () => {
      setSubmitting(false);
      goToEditCustomFilePage(history, idImport)
    };
    // onDone();
    updateImport({ id: idImport, values: data, onDone});
  };

  const onFirstDate = (row) => () => {
    setActiveFirstDate(row.id)
  };
  const initialData = {
    transactions: [
      {}
    ],
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initialData}
      onSubmit={onCreate}
    >
      {(form) => (
        <Form>
          <Wrapper>
            <Typography gutterBottom variant="h5" component="h2" className="table__header--title" >
              Identify Important Rows
            </Typography>
            <TableComponent
              headRows={headRows}
              items={items}
              isPagination={false}
              rowProps={{
                onHeading,
                onFirstDate
              }}
            />
            <div className="container-button">
              <DButton typeOfButton="add" type="submit">Create</DButton>
            </div>
          </Wrapper>
        </Form>
      )}
    </Formik>
  );
};

UploadExampleFile.propTypes = {
  customImport: PropTypes.object,
  updateImport: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    customImport: Selector.selectImport(state, props),
  }
};

const mapDispatchToProps = {
  getCustomImport: importDuck.actions.getCustomImport,
  updateImport: importDuck.actions.updateImport,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UploadExampleFile);