import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import * as categoryDuck from "../../../../store/ducks/category.duck";
import * as BankDuck from "../../../../store/ducks/bunk.duck";
import * as TransactionsDuck from "../../../../store/ducks/transactions.duck";
import * as CategorySelector from "../../../../selectors/category.selectors";
import * as BankSelector from "../../../../selectors/bank.selectors";
import * as TransactionsSelector from "../../../../selectors/transactions.selectors";
import * as PropTypes from "prop-types";
import {Typography} from '@material-ui/core';
import withHelmet from "../../../../hocs/withHelmet";
import * as commonDuck from "../../../../store/ducks/common.duck";
import * as commonSelectors from "../../../../selectors/common.selectors";
import {BankType} from "../../../../types";
import {useParseHistory} from "../../../../hooks/modal.hook";
import TransactionForm from "./TransactionForm/TranasactionForm";
import BankApi from "../../../../crud/bank.crud";
import logger from "../../../logger";
import Spinner from "../../../components/Spinner";
import Wrapper from "../../../components/Wrapper";
import {getCurrentCompanyId} from "../../../../crud/utils";

const NewBankTransaction = (props) => {
  const {
    bank,
    getBank,
    getTransactionsByBank,
    transactions,
  } = props;
  const bankId = useParseHistory().bank_account_id;
  const transactionId = +useParseHistory().transaction_id;
  const [isShowSuccess, setIsShowSuccess] = useState(false);

  useEffect(() => {
    if (transactionId) {
      getBank({ id: bankId });
      getTransactionsByBank({companyId: getCurrentCompanyId(), bankId: bankId })
    } else {
      getBank({ id: bankId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!bank) {
    return <Spinner className="d-spinner--full-height" />
  }

  const initialData = {

  };

  const transactionForEdit = transactions && transactions.find(transaction => transaction.id === transactionId);

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    const data = {...values};
    const onDone = () => {
      setSubmitting(false);
      resetForm();
    };
    const onError = (err) => {
      alert(JSON.stringify(err.response.data));
      setSubmitting(false);
    };
     if (transactions) {
       // updateTransaction({
       //   idCompany: getCurrentCompanyId(),
       //   id: transactionForEdit.id,
       //   values: data,
       //   onDone,
       //   onError,
       // })
     } else {
       BankApi.batchCreateTransaction([values]).then(() => {
         setSubmitting(false);
         // resetForm(initialData);
         setIsShowSuccess(true);
       }).catch((e) => {
         setSubmitting(false);
         logger.error(e);
       });
     }
  };

  return (
    <Wrapper>
      <Typography gutterBottom variant="h5" component="h2" className="transaction__title">
        {transactionId
          ? `Edit Bank Transaction for ${bank.name} (${bank.id})`
          : `New Bank Transaction for ${bank.name} (${bank.id})`}
      </Typography>
      <TransactionForm
        bank={bank}
        onSubmit={onSubmit}
        isShowSuccess={isShowSuccess}
        setIsShowSuccess={setIsShowSuccess}
        initialData={transactionForEdit}
      />
    </Wrapper>
  )
};

NewBankTransaction.propTypes = {
  getCategories: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  // descriptions: PropTypes.array,
  getDescriptions: PropTypes.func.isRequired,
  saveTransaction: PropTypes.func.isRequired,
  getConvRates: PropTypes.func.isRequired,
  bank: BankType,
};


const mapStateToProps = (state, props) => {
  return {
    categories: CategorySelector.getCategories(state, props),
    bank: BankSelector.getBank(state, props),
    descriptions: TransactionsSelector.getDescriptions(state, props),
    taxes: commonSelectors.getTaxes(state),
    transactions:TransactionsSelector.getTransactionsByBank(state,props)
  }
};

const mapDispatchToProps = {
  getCategories: categoryDuck.actions.getCategories,
  getTransactionsByBank: TransactionsDuck.actions.getTransactionsByBank,
  updateTransaction: TransactionsDuck.actions.updateTransaction,
  saveTransaction: BankDuck.actions.saveTransaction,
  getBank: BankDuck.actions.getBank,
  getDescriptions: TransactionsDuck.actions.getDescriptions,
  getTaxes: commonDuck.actions.getTaxes,
  getConvRates: commonDuck.actions.getConvRates,
};


export default withHelmet(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(NewBankTransaction),
  // TODO change the name to "Edit Bank Transaction" when editing a transaction
  {title: "Add New Transaction"}
)
