import React from 'react'
import PropTypes from 'prop-types'
import {TextareaAutosize} from '@material-ui/core'
import DateTimeField from './DateTimeField'
import RSelect from './RSelect'
import './switch.scss'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-daterangepicker/daterangepicker.css'
import './form-component.scss'
import RBDateRangePicker from './RBDateRangePicker'
import 'jquery-mask-plugin/dist/jquery.mask.min'
import ReactDatePicker from './ReactDatePicker/ReactDatePicker'
import RSelect_custom from "./fields/RSelect_custom";
import RSelectComponent from "./fields/RSelectComponent";
import MSelect from "./fields/MSelect";
import MSelectWithButton from "./fields/MSelectWithButton";
import MSelectSingle from "./fields/MSelectSingle";
import SwitchField from "./fields/SwitchField";
import IntegerNumberField from "./fields/IntegerNumberField";
import DefaultField from "./fields/DefaultField";
import NumberField from "./fields/NumberField";
import RNumber from "./fields/RNumber";
import RNumberPercent from "./fields/RNumberPercent";
import CurrencyInput from "./fields/CurrencuInput";
import DateField from "./fields/DateField";
import PhoneField from "./fields/PhoneField";
import AutocompleteField from "./fields/AutocompleteField";
import RadioField from "./fields/RadioField";
import CheckboxField from "./fields/CheckboxField";

const FormComponent = allProps => {
    const {
        name,
        setFieldValue,
        setFieldTouched,
        placeholder: inputPlaceholder,
        label: inputLabel,
        type,
        meta,
        showLabel,
        autofocus,
        customProps = {},
        submitCount: inputSubmitCount,
        ...props
    } = allProps;

    let placeholder = inputPlaceholder || inputLabel;
    let submitCount = !!inputSubmitCount;
    let label = inputLabel;

    if (!showLabel || customProps.hideLabel) {
        label = "";
    }

    const fieldProps = {
        ...allProps,
        placeholder,
        submitCount,
        label,
        autofocus,
    };

    switch (type) {
        case "RSelect_custom": {
            return <RSelect_custom {...fieldProps} />
        }
        case "RSelect": {
            return <RSelectComponent {...fieldProps} />
        }
        case "MSelect": {
            return <MSelect {...fieldProps} />
        }
        case "MSelectWithButton": {
            return <MSelectWithButton {...fieldProps} />
        }
        case "MSelectSingle": {
            return <MSelectSingle {...fieldProps} />
        }
        case "checkbox": {
            return <CheckboxField {...fieldProps} />
        }
        case "radio": {
            return <RadioField {...fieldProps} />
        }
        case "switch": {
            return <SwitchField {...fieldProps} />
        }
        case "number": {
            return <NumberField {...fieldProps} />
        }
        case "integerNumber": {
            return <IntegerNumberField {...fieldProps} />
        }
        case "RNumber": {
            return <RNumber {...fieldProps} />
        }
        case "RNumberPercent": {
            return <RNumberPercent {...fieldProps} />
        }
        case "currencyInput": {
            return <CurrencyInput {...fieldProps} />
        }
        case "date": {
            return <DateField {...fieldProps} />
        }
        case "date-time": {
            return <DateTimeField {...fieldProps} />
        }
        case "dateRB": {
            return (
                <RBDateRangePicker
                    customProps={customProps}
                    meta={meta}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    name={name}
                    submitCount={submitCount}
                    {...props}
                />
            )
        }
        case "dateSecondRB": {
            return (
                <ReactDatePicker
                    {...props}
                    customProps={customProps}
                    meta={meta}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    name={name}
                    submitCount={submitCount}
                />
            )
        }
        case "hidden": {
            const value = props.value || "1";
            return <input
                {...props}
                id={name}
                type="hidden"
                name={name}
                value={value}
            />
        }
        case "textareaAutosize": {
            const {placeholder, rowsMax, beforeField} = customProps;
            return (
                <div className="form-group__wrap-textarea">
                    {beforeField && <span className="form-group__before-field">{beforeField}</span>}
                    <TextareaAutosize
                        id={name}
                        name={name}
                        aria-label={name}
                        minRows={3}
                        maxRows={rowsMax ? rowsMax : 5}
                        placeholder={placeholder || ""}
                        {...props}
                        value={props.value || ''}
                    />
                </div>
            )
        }
        case "phone": {
            return <PhoneField {...fieldProps} />
        }
        case "autocomplete": {
            return <AutocompleteField {...fieldProps} />
        }
        default: {
            return <DefaultField {...fieldProps} />
        }
    }
};

FormComponent.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    showLabel: PropTypes.bool,
    customProps: PropTypes.object,
};

function areEqual(prevProps, nextProps) {
    if (prevProps.options) {
        return false;
    }
    return prevProps.value === nextProps.value;
}

// export default React.memo(FormComponent, areEqual);
export default FormComponent;