import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { FormControl, FormHelperText } from "@material-ui/core";
import moment from "moment";
import classNames from "clsx";
import {momentFormatOptions} from "../../home/utils";

const RBDateRangePicker = (fieldProps) => {
  const { name, value, setFieldValue, meta, submitCount, customProps={}, setFieldTouched, ...props } = fieldProps
  //https://github.com/skratchdot/react-bootstrap-daterangepicker/
  let { onChange, autoUpdateInput = true, singleDatePicker = true, childEl, opens="center", isPayDate } = customProps
  const myRef = useRef()

  const changeDate = (date) => {
    if (singleDatePicker) {
      myRef.current.setStartDate(date)
    }
    else {
      // date?._i?.startDate && myRef.current.setStartDate(date._i.startDate)
      // date?._i?.endDate && myRef.current.setEndDate(date._i.endDate)
      date?.startDate && myRef?.current?.setStartDate && myRef.current.setStartDate(date.startDate)
      date?.endDate && myRef?.current?.setEndDate && myRef.current.setEndDate(date.endDate)
    }
  }

  useEffect(() => {
    changeDate(value ? moment(value) : moment())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const handleChange = (startDate, endDate, picker) => {
    const date = singleDatePicker ? startDate : { startDate, endDate }
    let val = picker.startDate.format(momentFormatOptions.display)
    if (!singleDatePicker) {
      val = picker.startDate.format(momentFormatOptions.display) + "-" + picker.endDate.format(momentFormatOptions.display)

    }

    if (onChange) {
      picker.element.val(val)
      // setFieldValue(name, date);
      onChange(date, picker)
    } else {
      setFieldTouched && setFieldValue(name, date)
      picker.element.val(val)
    }
  };

  const [disabled, setDisabled] = useState(props.disabled)

  const handleEvent = (event, picker) => {
  if (event.type === 'show') {
    setDisabled(true)
  } else if (event.type === 'hide') {
    setDisabled(props.disabled)
  }

    // picker.autoUpdateInput = true;
    document.querySelectorAll("div.daterangepicker")
      .forEach(tag => {

        /// this "if" for current show datepicker in bottom page
        if (tag.style.top) {
          let top = tag.style.top.replace('px', '')
          let heightWithDatePicker = +top + tag.clientHeight
          if (heightWithDatePicker > window.innerHeight) {
            let difference = heightWithDatePicker - window.innerHeight
            tag.style.top = (top - (difference + 50)) + 'px'
          }
        }


        tag.classList.add("dateRB__picker")
      })
  }

  const handleApply = (event, picker) => {
    handleChange(
      moment(picker.startDate._d).format(momentFormatOptions.backend),
      moment(picker.endDate._d).format(momentFormatOptions.backend),
      picker
    )
    picker.autoUpdateInput = true;
  }

  const onHide = () => {
    setFieldTouched && setFieldTouched(name, true)
  }

  let initStartDate = null
  let initEndDate = null
  if (value) {
    initStartDate = singleDatePicker
      ? moment(value).format(momentFormatOptions.display)
      : moment(value.startDate).format(momentFormatOptions.display)
    initEndDate = moment(value.endDate).format(momentFormatOptions.display)
    autoUpdateInput = true
  } else {
    autoUpdateInput = false
  }

  let initialSettings = {
    showDropdowns: true,
    singleDatePicker: singleDatePicker,
    startDate: initStartDate,
    autoApply: true,
    locale: {'format': momentFormatOptions.display},
    autoUpdateInput: autoUpdateInput,
    opens: opens,
    minDate: moment().subtract(3, "year").startOf("year"),
    maxDate: moment().add(2, "year").endOf("year"),
  };
  
  const isError = !!(meta && (meta.touched || submitCount) && meta.error);
  
  return <FormControl
    error={isError} fullWidth margin="dense">
    <div
      className={classNames(
        `MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl
        MuiInput-formControl MuiInputBase-marginDense wrap-dateRB__child`, {
        "Mui-error Mui-error": isError
      })}
    >
      {isPayDate && <div className="pay_date_info_text">Paid Date</div>}
    <DateRangePicker
      ref={myRef}
      initialSettings={singleDatePicker ? initialSettings : {...initialSettings, endDate: initEndDate}}
      onApply={handleApply}
      onEvent={handleEvent}
      onHide={onHide}
    >
      {/*You MUST pass a single child element to the <DateRangePicker /> component- and it MUST be a DOM element.*/}
      {childEl
        ? childEl
        : (
              <input
                  className={classNames("MuiInputBase-input dateRB__child", { "no-value": value === "" })}
                  disabled={disabled}
                  placeholder={momentFormatOptions.placeholder}
              />
        )
      }
    </DateRangePicker>
    </div>
     {isError && (
      <FormHelperText id="component-error-text">
        {meta.error}
      </FormHelperText>
    )}
  </FormControl>
};

RBDateRangePicker.propTypes = {
  customProps: PropTypes.object,
  setFieldValue: PropTypes.func,
  meta: PropTypes.object,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

export default RBDateRangePicker