import React, {useEffect, useState} from "react";
import OrdersRefunds from "../Dashboard/OrdersRefunds/OrdersRefunds";
import useChart from "../../../hooks/chart.hook";
import {getDefaultMarketplace, pageViewEvent} from "../utils";
import queryString from 'query-string';
import Wrapper from "../../components/Wrapper";

const Sales = ({ location }) => {
  const [marketplace, setMarketplace] = useState(getDefaultMarketplace());
  const [isResetPagination, setResetPagination] = useState(false);
  const {state} = location;
  const tab = queryString.parse(window.location.hash).tab;

  const commonParams = {
    period: "all",
    start_date: null,
    end_date: null,
    marketplace,
    page_name: 'sales',
    offset: 0,
    salesOnly: true,
    table: tab,
    ...state,
  };

  const {
    loading,
    orders,
    refunds,
    refresh,
    setParams,
  } = useChart({...commonParams, limit: 15, ordering: "", fetch: false});

  useEffect(() => {
    refresh({ ...commonParams, limit: 15, ordering: "", autoRefresh: true})
  }, []);

  useEffect(() => {
    pageViewEvent(); // For tracking page view
    document.title = 'SKUify - #1 Profit Analytics & Inventory Management For Amazon Sellers'
  }, []);

  const onSelectedMarketplace = (value) => {
    setResetPagination(true);
    setMarketplace(value);
    refresh({ ...commonParams, marketplace: value})
  };

  const refreshPage = (params) => {
    return refresh({ ...commonParams, ...params });
  };

  return (
    <Wrapper elevation={0}>
      <div className="sales">
        <OrdersRefunds
          orders={orders}
          refunds={refunds}
          onSelectedMarketplace={onSelectedMarketplace}
          loading={loading}
          setParams={setParams}
          isResetPagination={isResetPagination}
          setResetPagination={setResetPagination}
          refreshPage={refreshPage}
          location={location}
        />
      </div>
    </Wrapper>
  )
};

export default Sales;