import React from "react";
import { IconButton, TableCell } from "@material-ui/core";
import { withCurrency } from "../../utils";
import TableComponent from "./TransactionList";
import EditIcon from '@material-ui/icons/Edit';
import AttachFileIcon from "@material-ui/icons/AttachFile";
import LinkIcon from "@material-ui/icons/Link";
import DeleteIcon from '@material-ui/icons/Delete';

export const headRows = [
  {
    id: 'id', numeric: false, disablePadding: true, label: '#', isActive: true, align: "center",
    renderText: (row, props, items) => {
      items.indexOf(items.find(item => item.id === row.id));
      return items.indexOf(items.find(item => item.id === row.id))+1
    }
  },
  {id: 'date', numeric: false, disablePadding: true, label: 'Date', isActive: true, align: "center"},
  {id: 'type', numeric: false, disablePadding: true, label: 'Type', isActive: true, align: "center",
    render: (row, { onEdit }) => {
      return <TableCell align="left" key={`${row.id}--type`} onClick={onEdit(row)} className="table__cell--link">
        <span className="table__cell--link__text" >
          {row.type}
        </span>
      </TableCell>
    }},
  {id: 'bank_account.entry_method', numeric: false, disablePadding: true, label: 'Entry Method', isActive: true, align: "center"},
  {id: 'description', numeric: false, disablePadding: true, label: 'Description', isActive: true},
  {
    id: 'category', numeric: false, disablePadding: true, label: 'Category', isActive: true,
    renderText: row => row.bank_account.name
  },
  {
    id: 'tax_code', numeric: false, disablePadding: true, label: 'Tax code', isActive: true, align: "center",
    renderText: (row) => {
      const taxCode = { 1: "NV", 2: "ST", 3: "RR", 4: "EX", 5: "ES" };
      return taxCode[row.tax_code] || "EG"
    }
  },
  {
    id: 'total_amount', numeric: false, disablePadding: true, label: 'Total', isActive: true, align: "center",
    renderText: row => withCurrency(row.total_amount, row.bank_account.currency.currency_symbol),
  },
  {id: 'actions', numeric: false, disablePadding: true, label: 'Action', isActive: true, align: "center",
    render: (row, { onEdit, onAttachments, onDelete, onLink }) => {
      return (
        <TableComponent.ActionCell key={row.id}>
          <IconButton data-action="edit" onClick={onEdit(row)}>
            <EditIcon style={{color: "var(--warning)"}} />
          </IconButton>
          {onAttachments && <IconButton onClick={onAttachments(row)}><AttachFileIcon color="secondary" /></IconButton>}
          <IconButton onClick={onLink(row)}><LinkIcon color="primary" /></IconButton>
          <IconButton onClick={onDelete(row)}><DeleteIcon color="error" /></IconButton>
        </TableComponent.ActionCell>
      )
    }
  }
];