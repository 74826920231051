import { call, put, takeLatest } from 'redux-saga/effects';
import api from "../../crud/bank.crud";
import { createSlice } from "@reduxjs/toolkit";
import {shortBaseState} from "./utils";

const company = createSlice({
    name: 'customers',
    initialState: {
      loading: true,
      customers: shortBaseState,
    },
    reducers: {
      stopLoading: (state, actions) => {
        state.customers.loading = false;
        return state;
      },
      getCustomers: (state, actions) => {
        state.customers.loading = true;
        return state;
      },
      getCustomersSuccess: (state, actions) => {
        const { customers } = actions.payload;
        state.customers.details = customers;
        state.customers.loading = false;
        return state;
      },
      addCustomer: (state, actions) => {
        state.customers.loading = true;
        return state;
      },
      addCustomerSuccess: (state, actions) => {
        const { customer } = actions.payload;
        state.customers.details = [...state.customers.details, customer];
        state.customers.loading = false;
        return state;
      },
      deleteCustomer: (state, actions) => {
        state.customers.loading = true;
        return state;
      },
      deleteCustomerSuccess: (state, actions) => {
        const { id } = actions.payload;
        state.customers.details = state.customers.details.filter(customer => customer.id !== id);
        state.customers.loading = false;
        return state;
      },
      updateCustomer: (state, actions) => {
        state.customers.loading = true;
        return state;
      },
      updateCustomerSuccess: (state, actions) => {
        state.customers.details = state.customers.details.map(customer => {
          if (customer.id === actions.payload.id) {
            return actions.payload.customer;
          }
          return customer
        });
        state.customers.loading = false;
        return state;
      },
    },
  }
);

export const actions = company.actions;
export const reducer = company.reducer;

export function* saga() {
  yield takeLatest(actions.getCustomers, function* getCustomerSaga({ payload }) {
    try {
      let q = payload && payload.q;
      const { data } = yield call(api.getCustomers, q);
      data
        ? yield put(actions.getCustomersSuccess({ customers: data }))
        : yield put(actions.stopLoading());
    } catch (err) {
      yield put(actions.stopLoading());
      console.log(err);
    }
  });

  yield takeLatest(actions.addCustomer, function* addCustomerSaga({ payload: { values, onDone, onError } }) {
    try {
      const { data, ...props } = yield call(api.addCustomer, values);
      if (data) {
        yield put(actions.addCustomerSuccess({ customer: data }));
        onDone();
      } else {
        yield put(actions.stopLoading());
        onError(props);
      }
    } catch (err) {
      yield put(actions.stopLoading());
      onError(err);
      console.log(err);
    }
  });

  yield takeLatest(actions.deleteCustomer, function* deleteCustomerSaga({ payload: { id, onDone, onError } }) {
    try {
      yield call(api.deleteCustomer, id);
      yield put(actions.deleteCustomerSuccess({ id }));
      if (onDone)
        onDone();
      yield put(actions.stopLoading());
    } catch (err) {
      yield put(actions.stopLoading());
      onError(err);
      console.log(err);
    }
  });

  yield takeLatest(actions.updateCustomer, function* updateCustomerSaga({ payload: { id, values, onDone, onError } }) {
    try {
      const { data, ...props } = yield call(api.updateCustomer, id, values);
      if (data) {
        yield put(actions.updateCustomerSuccess({ customer: data, id }));
        onDone();
      } else {
        yield put(actions.stopLoading());
        onError(props);
      }
    } catch (err) {
      onError(err);
      console.log(err);
    }
  });
}
