import React, {useEffect, useRef, useState} from "react";
import * as PropTypes from "prop-types";
import DSearch from "../../components/DSearch/DSearch";
import {useModal} from "../../../hooks/modal.hook";
import {importCOGWarningText} from "./utils";
import Typography from "@material-ui/core/Typography";

const TabsToolbar = (props) => {
    const {
        setSearchQueryParam,
        tab,
        usedParams,
    } = props;
    const [isClear, setClear] = useState(false);
    const [isModalShow, toggleModal] = useModal();


    const isFirstRender = useRef(false);
    useEffect(() => {
        if (tab && isFirstRender.current) {
            setClear(true);
        } else {
            isFirstRender.current = true
        }
    }, [tab]);
    useEffect(() => {
        if (usedParams.includes("q")) {
            setClear(false);
        }
    }, [usedParams]);


    return (
       <>
           <Typography variant="h4" className="page-title">Journey</Typography>

           <div className="full-width">
            <div className="header--search tabs__toolbar">
                <DSearch
                    setSearchQuery={setSearchQueryParam}
                    isClear={isClear}
                    setClear={setClear}
                />
            </div>
        </div>
           </>
    )
};

TabsToolbar.propTypes = {
    setSearchQuery: PropTypes.func,
    setNoInventoryCost: PropTypes.func,
    setStock: PropTypes.func,
    setWarningSearch: PropTypes.func,
    setWarning: PropTypes.func,
    isWarning: PropTypes.bool,
    tab: PropTypes.string,
    usedParams: PropTypes.array,
};

export default TabsToolbar;