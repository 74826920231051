import React, { useEffect, useState } from 'react'
import { Form as FormikForm, Formik } from "formik";
import { Collapse, Typography } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../../../../_metronic";
import toast from "../../../../components/toast/toast";
import FormField from "../../../../components/controls/FormField";
import useOnboarding from "../../../../../hooks/onboarding.hook";
import { INTERCOM_ID } from "../../../../home/utils";
import {requiredValidator} from "../../../../components/controls/validators";

function ContactList() {
  const [showEmail, setShowEmail] = useState(false);
  const { sendContact } = useOnboarding();
  const handleDisplay = () => {
    setShowEmail(!showEmail)
  };

  useEffect(() => {
    //chat bot
    window.Intercom('boot', {
      app_id: INTERCOM_ID,
      custom_launcher_selector:'#live-chat'
    });
  }, []);

  const cards = [
    {
      id: "live-chat",
      icon: "media/codely-theme-files/assets/images/new/mail.png",
      title: "Live Chat",
    },
    {
      id: "",
      icon: "media/codely-theme-files/assets/images/new/location.png",
      title: "Email",
      action: handleDisplay,
    },
  ];
  return (
    <>
      <section className="row_am contact_list_section">
        <div className="container">
          <div className="contact_list_inner" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="100">
            {cards.map((card, index) => (
              <div id={card.id} key={index} className="c_list_card" onClick={card.action ? card.action : undefined}>
                <div className="icons">
                  <img src={card.icon} alt="image"/>
                  <div className="dot_block">
                    <span className="dot_anim"></span>
                    <span className="dot_anim"></span>
                    <span className="dot_anim"></span>
                  </div>
                </div>
                <div className="inner_text">
                  <h3>{card.title}</h3>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Collapse in={showEmail} className="contact__collapse">
       <section className="contact_form_section">
        <div className="container">
          <div className="contact_inner">
            <div className="contact_form">
              <div className="section_title">
                <h2>Talk to a representative..</h2>
                <p>We understand that sometimes you just need to talk to someone. That's why our friendly support team are ready and waiting to chat with you 24/7!</p>
              </div>
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  subject: "",
                  message: "",
                }}
                // validate={values => {
                //   const errors = {email: ""};
                //
                //   if (!values.email) {
                //     errors.email = intl.formatMessage({
                //       id: "AUTH.VALIDATION.REQUIRED_FIELD"
                //     });
                //   } else if (
                //     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                //   ) {
                //     errors.email = intl.formatMessage({
                //       id: "AUTH.VALIDATION.INVALID_FIELD"
                //     });
                //   }
                //
                //   return errors;
                // }}
                onSubmit={(values, { setStatus, setSubmitting, resetForm }) => {
                  const onDone = (data) => {
                    setSubmitting(false);
                    toast.success(data.message);
                    handleDisplay();
                  };

                  const onError = (error) => {
                    setSubmitting(false);
                    resetForm();
                    toast.error(error?.message || 'Something went wrong!', { duration: 5000 });
                  };

                  sendContact({ values, onDone, onError })
                }}
              >
                {({ ...form }) => (
                  <FormikForm className="settings-main__default">
                    <div className="name-email">
                      <div className="form-group half">
                        <Typography variant="body1" className="form-group__label">Name</Typography>
                        <FormField
                          type="text"
                          label="Name"
                          name="name"
                          placeholder=" "
                          {...form}
                        />
                      </div>

                      <div className="form-group half">
                        <Typography variant="body1" className="form-group__label">Email</Typography>
                        <FormField
                          label="Email"
                          type="email"
                          name="email"
                          placeholder=" "
                          validate={requiredValidator('Email is required')}
                          {...form}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <Typography variant="body1" className="form-group__label">Subject</Typography>
                      <FormField
                        label="Subject"
                        type="text"
                        name="subject"
                        placeholder=" "
                        {...form}
                      />
                    </div>

                    <div className="form-group">
                      <Typography variant="body1" className="form-group__label">Message</Typography>
                      <FormField
                        type="textareaAutosize"
                        label="Message"
                        name="message"
                        {...form}
                      />
                    </div>
                    <button className="btn btn_main" type="submit">SEND</button>

                  </FormikForm>
                )}
              </Formik>
              <div className="form-graphic">
                <img src={toAbsoluteUrl("media/codely-theme-files/assets/images/new/paperplane.png")} alt="image"/>
              </div>
            </div>
          </div>
        </div>
      </section>
      </Collapse>
    </>
  )
}

export default ContactList
