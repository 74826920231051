import React from "react";
import {ListItem, Typography} from "@material-ui/core";
// @ts-ignore
import {ListChildComponentProps} from 'react-window';
import {formatDate} from "../Dashboard/components/utils";
import {Link} from "react-router-dom";
import {NOTIFICATIONS} from "../../../router/routesMap";
import useNotifications from "../../../hooks/notifications.hook";
import {DefaultData, modificationNotification, NotificationItemExtension} from "./utils";


export default (list: NotificationItemExtension[]) => (props: ListChildComponentProps) => {
  const {index, style} = props;
  const data: NotificationItemExtension = list?.[index] || {};
  const {id} = data;
  return (
    <ListItem className="notifications__line" style={style} key={id}>
      {/*<Avatar alt="img" variant="circular" className="notifications__image"/>*/}
      <NotificationsItem isList {...data} />
    </ListItem>
  );
};

export const NotificationsItem = (props: NotificationItemExtension & { isList?: boolean }) => {
  const {updateNotifications} = useNotifications({fetch: false});
  const {isList, ...data} = props;
  const {type, skipped, created_at, id, pathname, title, search, hash} = data;
  // @ts-ignore
  const values: T = data[modificationNotification[type].dataKey] || {};
  const onUpdate = () => updateNotifications({id, values: {...data, skipped: true}});

  return (
    <Link
      to={{
        pathname: isList ? pathname : NOTIFICATIONS,
        state: {
          q: !!search ? search(values) : ""
        },
        hash
      }}
    >
      <div className="notifications__block" onClick={isList ? onUpdate : undefined}>
        <div className="notifications__content">
          {isList && !!title && <Typography className="notifications__title popup-text">{title(values)}</Typography>}
          <div className="notifications__top">
            <Name {...values} />

            <Typography className="notifications__time">
              {(formatDate(created_at))}
              {!skipped && <span className="notifications__new"/>}
            </Typography>
          </div>

          {/*{!isList && <Name {...values} />}*/}
          {/*{isList*/}
          {/*? <Typography className="notifications__title">*/}
          {/*<span className="notifications__status">Referred by</span>*/}
          {/*Jelly Belly*/}
          {/*</Typography>*/}
          {/*: <Name {...values} />*/}
          {/*}*/}
        </div>
      </div>
    </Link>
  )
};

export const Name = ({event_type, name}: DefaultData) => (
  <Typography className="notifications__title--product">
    {event_type && <span className="notifications__status">{event_type}</span>}
    {name}
  </Typography>
);