import React from "react";
import classNames from "clsx";
import NameCell from "../Dashboard/components/NameCell";
import Avatar from "@material-ui/core/Avatar";
import Link from "@material-ui/core/Link";
import TooltipComponentState from "../../components/TooltipComponentState";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import NoteReconciliation from './NoteReconciliation'
import Help from "../Dashboard/components/Help";
import { icon as notesIcons } from "../Dashboard/components/NoteTabTable";
import {momentFormatOptions, withUnits} from "../utils";
import moment from "moment";
import {toAbsoluteUrl} from "../../../../_metronic";

export const headRows = [
  {
    id: "image_url",
    label: "",
    render: (row) => {
      const { image_url, amazon_product_url, id, full_image_url } = row;
      return (
          <TableCell align="left" key={`${id}--photo`} className={classNames("table__cell--photo")}>
            <TooltipComponentState
              placement="right-start"
              className="cell--photo__tooltip"
              isBlur
              tooltip={
                <Avatar alt="img" variant="square" src={full_image_url} className="cell__tooltip__photo" />
              }
            >
              <Link target="_blank" href={amazon_product_url}>
                <Avatar alt="img-product" variant="square" src={image_url}/>
              </Link>
            </TooltipComponentState>
          </TableCell>
        )
    },
  },
  {
    id: "item_name",
    label: "",
    render: (row) => {
      const { amazon_product_url, seller_sku, fnsku, id, product__vat_rate, asin, title } = row;
      return  (
        <NameCell
          url={amazon_product_url}
          sku={seller_sku}
          fnsku={fnsku}
          row={row}
          key={`${id}--name`}
          asin={asin}
          vat={product__vat_rate}
          title={title}
          tableName="reconciliation"
          shouldRenderName
          extra={{
            fnsku: fnsku
          }}
        />
      )
    },
  },
  {
    id: "reconciliation_update_time",
    label: "Reconcile Date",
    align: "center",
    render: (row) => {
      const { reconciliation_update_time, id } = row;
      const time = reconciliation_update_time
        ? moment(reconciliation_update_time).format(momentFormatOptions.display)
        : "-";

      return (
        <TableCell align="center" key={`${id}--update_time`} className={classNames("table__cell--update_time")}>
          {time}
        </TableCell>
      )
    }
  },
  {
    id: "damaged",
    label: "Damaged/Lost Not Returned",
    align: "center",
    render: (row) => {
      const { damaged, id } = row;
      return (
        <TableCell align="center" key={`${id}--damaged`} className={classNames("table__cell--damaged")}>
          {damaged}
        </TableCell>
      )
    }
  },
  {
    id: "potential_reimbursements",
    label: "Potential Reimbursements",
    align: "center",
    render: (row) => {
      const { potential_reimbursements, id } = row;
      return (
        <TableCell
          align="center"
          key={`${id}--potential_reimbursements`}
          className={classNames("table__cell--potential_reimbursements")}
        >
          {potential_reimbursements}
        </TableCell>
      )
    }
  },
  {
    id: "pending_reimbursements",
    label: "Pending Reimbursements",
    align: "center",
    render: (row) => {
      const { pending_reimbursements, id } = row;
      return (
        <TableCell
          align="center"
          key={`${id}--pending_reimbursements`}
          className={classNames("table__cell--pending_reimbursements")}
        >
          {pending_reimbursements}
        </TableCell>
      )
    }
  },
  {
    id: "missing_inventory",
    label: "Missing Inventory",
    align: "center",
    render: (row) => {
      const { missing_inventory, id } = row;
      return (
        <TableCell align="center" key={`${id}--missing_inventory`} className={classNames("table__cell--missing_inventory")}>
          {missing_inventory ? missing_inventory : 0}
        </TableCell>
      )
    }
  },
  {
    id: "ready_to_claim_reimbursements",
    label: "Ready to Claim Reimbursements",
    align: "center",
    render: (row) => {
      const { ready_to_claim_reimbursements, id } = row;
      return (
        <TableCell align="center" key={`${id}--ready_to_claim`} className={classNames("table__cell--ready_to_claim")}>
          {ready_to_claim_reimbursements ? ready_to_claim_reimbursements : 0}
        </TableCell>
      )
    }
  },
  {
    id: "amount_reimbursed",
    label: "Amount Reimbursed",
    align: "center",
    render: (row, {currency_symbol}) => {
      const { amount_reimbursed, id } = row;
      return (
        <TableCell
          align="center"
          key={`${id}--amount_reimbursed`}
          className={classNames("table__cell--amount_reimbursed")}
        >
          {withUnits(currency_symbol, amount_reimbursed)}
        </TableCell>
      )
    }
  },
  // {
  //   id: "amount_owed",
  //   label: "Amount Owed",
  //   align: "center",
  //   render: (row, {currency_symbol}) => {
  //     const { id, amount_owed, extraRow } = row;
  //     return (!extraRow && (
  //         <WarningCell
  //           key={`${id}--amount_owed`}
  //           id={id}
  //           name="amount_owed"
  //           value={amount_owed}
  //           units={currency_symbol}
  //           nowrap
  //         />
  //       )
  //     )
  //   }
  // },
  {
    id: "reconciliation_summary",
    label: "Reconciliation Summary",
    align: "center",
    disabled: true,
    render: (row, { handleChange }) => {
      return (
        <TableCell align="center" key={`${row.id}--summary`} className={classNames("table__cell--summary")}>
          <Button variant="contained" color="primary" onClick={(e) => handleChange(e, row)} className="summary-btn">
            <img className="table__cell__img--icon" src={toAbsoluteUrl("/media/d-icons/list-text.svg")} alt="img"/>
          </Button>
        </TableCell>
      )
    }
  },
  {
    id: "note",
    label: "Note",
    align: "center",
    disabled: true,
    help: () => <Help data={notesIcons} />,
    render: (row) => {
      return  (
        <TableCell
          align="center"
          key={`${row.id}--note`}
          className={classNames("table__cell--note", { "table__cell--child": row.parent })}>
          <NoteReconciliation row={row} />
        </TableCell>
      )
    },
  },
];
