import React from "react";
import * as PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import { Typography } from "@material-ui/core";
import classNames from 'classnames';
import DSplashScreen from "../SplashScreen/DSplashScreen";
import FormComponent from "../controls/FormComponent";
import {withUnits, numberWithCommas, withoutSpace, floatNumber} from "../../home/utils";
import "./style-report-component.scss";
import { typeReports } from "../../home/Dashboard/components/utils";

const getFormData = (reportData, currency_symbol) =>
  reportData.map(({ name, value, noCurrency, data, unit }) => {
    const symbol = unit || currency_symbol;
    if (name === "Inbound Shipping Cost Units") {
      value = value === 1 ? "kg" : "lb";
      return ({
        id: name,
        label: withoutSpace(name),
        type: "text",
        value: value,
      })
    }
    return (
      data
        ? {
          id: name,
          label: withoutSpace(name),
          type: "text",
          value: noCurrency ? numberWithCommas(value, "integer") : withUnits(symbol, value),
          data: getFormData(data, currency_symbol),
        }
        : {
          id: name,
          label: withoutSpace(name),
          type: "text",
          value: noCurrency ? numberWithCommas(value, "integer") : withUnits(symbol, value),
        }
    )
  });

const ReportComponent = ({ reportData=[], loading, order, isReturn, reportType, extraStyle, keyItem }) => {
  const report = reportData.map(({ data, name, total_label, total, currency_symbol, noCurrency }) => {
    const dataSection = total_label ? [...data, { name: total_label, value: total }] : data;
    return {
      name,
      data: getFormData(dataSection, currency_symbol, noCurrency)
    }
  });

  return (
    <div className={classNames("reports__body reports--global reports--global--color", extraStyle, {
      "reports--global--loading": loading,
      "order": order,
      "amazon-fees": reportType === typeReports.AMAZON
    })}>
      <div className="d-splash-screen--wrap">
        {loading
          ? <DSplashScreen />
          : <>
            {report.map(({ name, data }, index) => {
              return (
                  <div key={name || index} className="reports-item">
                    {name && <Typography variant="h5" color="primary" className="reports__title">{name}</Typography>}
                    {data.map(item => {
                      const content = (item) => <Form.Group
                        key={keyItem ? `${item.id}--${keyItem}` : item.id}
                        controlId={keyItem ? `${item.id}--${keyItem}` : item.id}
                        className={classNames(`form-group--${item.id}`, {
                          "last-block": isReturn && (report.length === index + 1),
                          "is-value" : !!floatNumber(item.value),
                        })}
                      >
                        <Form.Label column={false}>{item.label}</Form.Label>
                        <FormComponent
                          id={keyItem ? `${item.id}--${keyItem}` : item.id}
                          name={item.id}
                          label={item.label}
                          type={item.type}
                          placeholder={item.placeholder}
                          disabled={true}
                          value={item.value}
                        />
                      </Form.Group>;
                      return item.data
                        ? <React.Fragment key={keyItem ? `${item.id}--${keyItem}` : item.id}>
                          <Typography variant="h5" color="primary" className="reports__subtitle">{item.label}</Typography>
                          {item.data.map(item => content(item))}
                        </React.Fragment>
                        : content(item)
                    })}
                  </div>
                )
              }
            )}
          </>
        }
      </div>
    </div>
  )
};

ReportComponent.propTypes = {
  loading: PropTypes.bool,
  reportData: PropTypes.array,
  order: PropTypes.bool,
};

export default ReportComponent;
