import { createSelector } from "reselect";

export const getUsers = createSelector(
  state => state.company.users,
  (users) => users.users,
);

export const getLoading = createSelector(
  state => state.company.users,
  (users) => users.loading,
);

export const getGroups = createSelector(
  state => state.company.groups,
  (groups) => groups.groups,
);

export const getLoadingGroups = createSelector(
  state => state.company.groups,
  (groups) => groups.loading,
);