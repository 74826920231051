import React, {useState} from "react";
import {Card, CardHeader, CardActions, CardContent} from "@material-ui/core";
import {IconButton, Typography} from "@material-ui/core";
import * as PropTypes from "prop-types";
import StarIcon from '@material-ui/icons/Star';
import SearchIcon from '@material-ui/icons/Search';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import EditIcon from '@material-ui/icons/Edit';
import ImportExportIcon from '@material-ui/icons/ImportExport';

const ReportsGroup = ({data, nameGroup}) => {
  const [markedCardList, setMarkedCardList] = useState([]);

  const onMark = (item) => {
    if (markedCardList.includes(item)) {
      setMarkedCardList(markedCardList.filter((markedItem) => markedItem !== item))
    } else {
      setMarkedCardList((marked) => [...marked, item]);
    }
  };

  return (<>
    <Typography variant="h6" color="textSecondary" className="card-list__title-group">
      {nameGroup}
    </Typography>
    <div className="card-list__row">
      {
        data.map((card) => {
            let cardStyle;
            if (card.id === "request_export") {
              cardStyle = "card-list__item--request-export"
            } else if (nameGroup === "Exports") {
              cardStyle = "card-list__item--export"
            }

            let actions;
            if (card.id === "request_export") {
              actions = <IconButton onClick={() => console.log(card.id)} className="card-list__button">
                <EditIcon/>
              </IconButton>
            } else if (nameGroup === "Exports") {
              actions = <IconButton onClick={() => console.log(card.id)}  className="card-list__button">
                <ImportExportIcon/>
              </IconButton>
            } else {
              actions = <>
                <IconButton className="card-list__button" onClick={() => onMark(card.id)}>
                  {markedCardList.includes(card.id) ? <StarIcon/> : <StarBorderIcon/>}
                </IconButton>
                <IconButton onClick={card.function} className="card-list__button">
                  <SearchIcon/>
                </IconButton>
              </>
            }

            return <div key={card.id} className="card-list__cell">
              <Card className={"card-list__item " + cardStyle}>
                <div className="card-list__header">
                  <CardHeader title={card.title} className="card-list__title"/>
                  <CardActions>
                    {actions}
                  </CardActions>
                </div>
                <CardContent className="card-list__content">
                  <Typography variant="body2" color="textSecondary" component="p">
                    {card.text}
                  </Typography>
                </CardContent>
              </Card>
            </div>
          }
        )
      }
    </div>
  </>)
};

ReportsGroup.propTypes = {
  data: PropTypes.array.isRequired,
  nameGroup: PropTypes.string,
};

export default ReportsGroup;