import React from "react";
import Form from "react-bootstrap/Form";
import {Button, Typography} from "@material-ui/core";
import FormField from "../../../components/controls/FormField";
import clsx from 'clsx'

const IntegrationBlock = props => {
  const {title, form, formInterface, buttonInterface, buttonApiKey, classNameForm} = props;
  return (
    <>
      {title && (
        <Typography variant="h5" color="primary" className="settings__subtitle--main bottom-border">
          {title}
        </Typography>
      )}
      <div className="settings-main__integration__form content__tab">
        {formInterface.map((item) => (
          <Form.Group key={item.id} controlId={item.id} className={clsx(`form-group--${item.id}`, classNameForm)}>
            {!item.showLabel && <Form.Label column={false}>{item.label}</Form.Label>}
            <FormField
              name={item.id}
              label={item.label}
              showLabel={item.showLabel}
              type={item.type}
              disabled={item.disabled}
              {...form}
            />
          </Form.Group>
        ))}

        <div className="settings__wrap-btn">
          {buttonInterface.map(({name, onClick, type, disabled}) => {
            return (
              <Button
                key={name}
                variant="contained"
                className="settings-main__integration__btn settings__btn"
                onClick={onClick ? onClick(form, buttonApiKey) : undefined}
                type={type}
                disabled={type && disabled(form)}
              >
                {name}
              </Button>
            )
          })}
        </div>
      </div>
    </>
  )
};

export default IntegrationBlock;