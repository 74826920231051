import React from "react";
import TableComponent from "../../../components/TableComponent/TableComponent";
import * as PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";

const headRows = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Name', isActive: true },
  { id: 'data', numeric: false, disablePadding: true, label: 'Data', isActive: true },
];

function DetailsTemplateTable({ initialData, title, items }) {

  return <div className="recurring__details__item">
    <Typography variant="h6" className="recurring__header">{title}</Typography>
    <TableComponent
      headRows={headRows}
      items={items}
      isHeader={false}
      isPagination={false}
    />
  </div>
}

DetailsTemplateTable.propTypes = {
  initialData: PropTypes.object,
  title: PropTypes.string,
};

export default DetailsTemplateTable;
