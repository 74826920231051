import React from "react";
import * as PropTypes from "prop-types";
import Line from "./Line";
import { withUnits } from "../../../utils";

const Block = ({ block, colorDash }) => {
  return <div className="chart-legend--block">
    <div className="chart-legend--block__title">{block.name}</div>
    {block.charts.map((item, index) => {
      return <div key={index} className="chart-legend--block__item">
        <div className="item__left-part">
          <Line color={item.color} compare={item.compare} colorDash={colorDash} />
          <span className="item__date">{item.legend_label}</span>
        </div>
        <span className="item__value">{withUnits(item.label, item.value, !item.label && "integer")}</span>
      </div>
    })}
  </div>
};

Block.propTypes = {
  block: PropTypes.object,
};

export default Block;