import React, {useEffect, useState} from "react";
import useSettings from "../../../../hooks/settings.hook";
import { useNotification } from "../../../../hooks/notification.hook";
import useUsers from "../../../../hooks/users.hook";
import Typography from "@material-ui/core/Typography";
import { interfaceArray, types, onSubmit, init, onAddDelete, refactorTabData } from "./utils";
import { Headers, Buttons, LineArray, LineActions } from "./components";
import DTabs from "../../../components/DTabs/DTabs";
import FormArray from "./FormArray";
import DSplashScreen from "../../../components/SplashScreen/DSplashScreen";
import { generalGOGSInterface } from "../../Products/utils"
import {ReactComponent as ExpandIcon} from "../../../../media/settings/Expand Line.svg";

const tabsNames = Object.keys(types);

const COGSettings = () => {
  const { settings, loading, updateSetting, addSetting } = useSettings({ cog: true });

  // we need to find inbound_shipping_units
  //console.log(settings[0]?.items[11]?.value);
  let inboundShipmentUnitsArrayData = settings[0]?.items[11];
  const [currentTab, setTab] = useState(tabsNames[0]);
  const [tabData, setTabData] = useState({ [`${currentTab}_cog`]: [] });

  const [expandId, setExpandId] = useState([]);
  const [withHistory, setWithHistory] = useState([]);
  const [withNewLine, setWithNewLine] = useState([]);

  const handleChange = (event, newValue) => {
    setExpandId([]);
    setWithHistory([]);
    setTab(newValue);
  };

  const [successNotification, setIsShowNotification] = useNotification(
    false,
    "success",
    "Updated",
    { vertical: "bottom", horizontal: "right" }
  );

  const { user } = useUsers({ fetch: false });

  const dataName = `${currentTab}_cog`;
  const initCOG = generalGOGSInterface.map(({ label, id }) =>
    ({ ...init, label: id === "extra_fee_perc" ? `${label} %` : label, name: id })
  );

  useEffect(() => {
    const type  = settings.find(({ settings_type }) => settings_type === currentTab);
    const items = type && type.items;

    if (!loading && items && type.items.length === 0) {
      setTabData({ [`${dataName}`]: initCOG});
    }
    if (items) {
      refactorTabData({ initCOG, withHistory, setWithHistory, setTabData, dataName, items });
    }
  },[settings, currentTab]);

  const addLineProps = { expandId, setExpandId, setWithHistory, withHistory, withNewLine, setWithNewLine };
  const submitProps = { dataName, setIsShowNotification, addSetting, updateSetting, currentTab };
  const tabs = tabsNames.map((name) => (
    {
      value: name,
      label: types[name],
      content: (
        <FormArray
          successNotification={successNotification}
          initData={tabData}
          onSubmit={onSubmit(submitProps)}
          mainTabName="cog"
          dataName={dataName}
          footerButtons={(props) => <Buttons {...props} />}
          expandId={expandId}
          line={(props) => (
            <LineArray
              interfaceArray={interfaceArray(user.currency, dataName)}
              dataName={dataName}
              expandId={expandId}
              setExpandId={setExpandId}
              setWithHistory={setWithHistory}
              withHistory={withHistory}
              actions={(props) => (
                <LineActions expandId={expandId} setExpandId={setExpandId} withHistory={withHistory} {...props} />
              )}
              onAddDelete={(props) => onAddDelete({...props, ...addLineProps })}
              inboundShippingUnits={settings[0]?.items[11]?.value}
              inboundShipmentUnitsArrayData={inboundShipmentUnitsArrayData}
              {...props}
            />
          )}
        />
      )
    }
  ));

  return (
    <div className="settings-main__cog settings-main__default">
      <Typography variant="h5" className="settings__title">Cost of Goods Information</Typography>

      <div className="settings__subtitle">
        <Typography variant="subtitle1" className="settings__subtitle__item">
          This page controls your global cost of goods information.
        </Typography>

        <Typography variant="subtitle1" className="settings__subtitle__item">
          You can see historic COGS by clicking the expand button
          <ExpandIcon className="settings__subtitle__img" />
          {/*<img*/}
            {/*src={toAbsoluteUrl("/media/d-icons/settings-icon/Expand Line.svg")}*/}
            {/*alt="collapse"*/}
            {/*className="settings__subtitle__img"*/}
          {/*/>*/}

          next to the item
        </Typography>

        <Typography variant="subtitle1" className="settings__subtitle__item">
          If you pay VAT on your services please enter the net price in the 'Amount' box and toggle VAT on
        </Typography>
      </div>

      <div className="d-splash-screen--wrap">
        {loading && <DSplashScreen />}
        <DTabs
          value={currentTab}
          tabsData={tabs}
          onChange={handleChange}
          className="cog-settings"
        />
      </div>
    </div>
  );
};

export default COGSettings;
