export const typeOptionsReceipt = [
  { label: "Customer Invoice", "value": "CI"},
  { label: "Supplier Invoice", "value": "SI"},
  { label: "Quote", "value": "quote"},
  { label: "VAT Return", "value": "VR"},
  { label: "Customer Credit Note", "value": "CCN"},
  { label: "Supplier Credit Note", "value": "SCN"},
  { label: "Customer Invoice", "value": "CI"},
  { label: "Supplier Invoice", "value": "SI"},
  { label: "Money In", "value": "MI"},
  { label: "Money Out", "value": "MO"},
  { label: "Adjustment", "value": "adjustment"},
  { label: "Wages Adjustment", "value": "WA"},
  { label: "Customer", "value": "customer"},
  { label: "Supplier", "value": "supplier"},
  { label: "Bank account", "value": "BA"},
  { label: "Project", "value": "project"},
];
