import React from "react";
import moment from "moment";
import {months, years} from "./utils";
import Button from "@material-ui/core/Button";

const CustomHeader = (propsPicker) => {
  const {
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  } = propsPicker;

  return (
    <div className="react-datepicker__block">
      <Button
        className="react-datepicker_arrow-container react-datepicker_arrow-container-left"
        disabled={prevMonthButtonDisabled}
        onClick={decreaseMonth}
      >
        <span className="react-datepicker_arrow react-datepicker_arrow-left" />
      </Button>
      <select
        className="react-datepicker__select"
        value={months[moment(date).month()]}
        onChange={({ target: { value } }) =>
          changeMonth(months.indexOf(value))
        }
      >
        {months.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <select
        className="react-datepicker__select"
        value={moment(date).year()}
        onChange={({ target: { value } }) => changeYear(value)}
      >
        {years.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <Button
        className="react-datepicker_arrow-container react-datepicker_arrow-container-right"
        disabled={nextMonthButtonDisabled}
        onClick={increaseMonth}
      >
        <span className="react-datepicker_arrow react-datepicker_arrow-right" />
      </Button>
    </div>
  )
};

export default CustomHeader