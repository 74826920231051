import React from "react";
import moment from "moment";
import {extraTitles} from "./utils";
import {marketplaceIds, flags} from "../../../components/Marketplaces/Marketplaces";
import {Avatar, Typography} from "@material-ui/core";
import {dateOptions} from "../../Dashboard/ChartSection/dataChart";

export const informList = [
  {id: "condition", name: "Condition"},
  {id: "available", name: "Available"},
  {
    id: "inbound_stock",
    name: "Inbound",
    extraData: {
      type: 'array',
      id: "inbound_stock_list",
      content: ({extra}) => [{...extraTitles, name: "Inbound"}, ...extra]
    }
  },
  {
    id: "reserved_stock",
    name: "Reserved",
    extraData: {
      type: 'array',
      id: "reserved_stock_list",
      content: ({extra}) => [{...extraTitles, name: "Reserved"}, ...extra]
    }
  },
];

export const informList2 = [
  {
    id: "unfulfillable_stock",
    name: "Unfulfillable",
    extraData: {
      type: 'array',
      id: "unfulfillable_stock_list",
      content: ({extra}) => [{...extraTitles, name: "Unfulfillable"}, ...extra]
    }
  },
  {id: "reimbursed", name: "Reimbursed"},
  {id: "title_changed", name: "Title Changes"},
  {id: "image_changed", name: "Image Changes"},

];

export const informList3 = [
  {id: "overall_profit", name: "Overall Profit", unit: (currency) => currency},
  {id: "average_roi", name: "Overall ROI ", unit: () => "%"},
  {id: "average_margin", name: "Margin Percentage", unit: () => "%"},
  {id: "average_buy_price", name: "Average Buy Price ", unit: (currency) => currency},
];

export const informList4 = [
  {id: "average_price", name: "Average Sell Price", unit: (currency) => currency},
  {id: "quantity_sold", name: "Total units sold"},
  {id: "last_recalculate", name: "Last update"},
  {id: "last_order_date", name: "Last order"},
];

export const overviewInterfaces = [
  {data: informList, name: "informList"},
  {data: informList2, name: "informList2"},
  {data: informList3, name: "informList3"},
  {data: informList4, name: "informList4"}
];

export const initValues = {
  informList2: {},
  informList3: {},
  informList4: {},
};

export const periodName = {
  profit: "profit",
  sales: "sales",
  sales_profit: "sales_profit",
};

export const periodTitles = {
  profit: "Profit",
  sales: "Sales",
  sales_profit: "Sales & Profit",
};

const endDate = moment().format('YYYY-MM-DD');
const startDate = moment(endDate).subtract(1, 'week').format('YYYY-MM-DD')
const initPeriod = {startDate, endDate: startDate, period: dateOptions.find((i) => i.default)}
export const initPeriods = {
  [periodName.profit]: initPeriod,
  [periodName.sales]: initPeriod,
  [periodName.sales_profit]: initPeriod,
  // [periodName.sales_profit]: {
  //   startDate: moment().subtract(16, "week").format(momentFormatOptions.backend),
  //   endDate: moment().subtract(15, "week").format(momentFormatOptions.backend)
  // },
};

export const inventoryByCategory = [
  {id: "category", name: "Category", align: "left"},
  {id: "unit", name: "Unit", align: "center"},
  {id: "total_unit_cost", name: "Total Unit Cost",  unit: (currency) => currency, align: "center"},
  {id: "resale", name: "Resale",  unit: (currency) => currency, align: "center"},
  {id: "potential_profit", name: "Potential Profit",  unit: (currency) => currency, align: "center"},
];

export const asinProfit = [
  {id: "profit", name: "Profit", unit: (currency) => currency},
  {id: "units_sold", name: "Units"},
  {id: "sales", name: "Sales", unit: (currency) => currency},
  // {id: "shipping_charge", name: "Shipping Charge", unit: (currency) => currency},
  // {id: "cog", name: "CoG", unit: (currency) => currency},
  // {id: "shipping_cost", name: "Shipping Cost", unit: (currency) => currency},
  // {id: "fees", name: "Fees", unit: (currency) => currency},
  // {id: "vat", name: "VAT", unit: (currency) => currency},
  {id: "roi", name: "ROI", unit: () => "%"},
];

export const asinSales = [
  {
    id: "marketplace", name: "MKT", render: (values) => {
      const country_code = marketplaceIds[values.marketplace];
      return (
        <div className="block__value">
          <Avatar
            alt={country_code}
            src={flags[country_code]}
            // className={style}
          />
          <Typography>{country_code}</Typography>
        </div>
      )
    }
  },
  {id: "units_sold", name: "Unit"},
  {id: "sales", name: "Sales", unit: (currency) => currency},
  {id: "profit", name: "Profit", unit: (currency) => currency},
];