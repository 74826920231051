import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import classNames from 'classnames';
import "./alert-dialog-style.scss";

const AlertDialog = ({ open, onClose, content, parentComponent, actions, title, className }) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classNames("alert-dialog", className)}
      >
        {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}

        <DialogContent>
          <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
        </DialogContent>

        <DialogActions>
          <div className="container-button--dialog">{actions}</div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AlertDialog;