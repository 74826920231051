import * as RoutesMap from "../../../router/routesMap";
import {NotificationsType} from "../../../types/enums";


export type DefaultData = {
  name: string;
  asin: string;
  event_type: string;
};

export type Order = DefaultData & {
  amazon_order_id: string;
  amount: number;
}

export type Product = DefaultData;

export type NotificationItem = {
  id: number;
  order_data: Order | null;
  product_data: Product | null,
  type: NotificationsType;
  created_at: string;
  skipped: boolean;
}

export type ModificationContent = {
  dataKey?: string;
  pathname?: string;
  title?: (data: Order) => string;
  search?: (data: Order) => string;
  hash?: string;
}

export type ModificationNotification = {
  [key: string]: ModificationContent;
}

export type NotificationItemExtension = NotificationItem & ModificationContent;

export const modificationNotification: ModificationNotification = {
  [NotificationsType.Product]: {
    dataKey: "product_data",
    pathname: RoutesMap.PRODUCTS,
    hash: "",
    title: (data: Order) => `${data.asin}`,
    search: (data: Order) => data.asin,
  },

  [NotificationsType.Order]: {
    dataKey: "order_data",
    pathname: RoutesMap.SALES,
    hash: "",
    title: (data: Order) => `You have made a sale ${data.asin} Price: ${data.amount}.`,
    search: (data: Order) => data.amazon_order_id,
  },

  [NotificationsType.Refunded]: {
    dataKey: "",
    pathname: RoutesMap.SALES,
    hash: `#tab=refunds`,
    title: (data: Order) => `Order status ${data.amazon_order_id} has been changed to ${data.event_type}.`,
    search: (data: Order) => data.amazon_order_id,
  },
};

export const onUpdateNotifications = (list: NotificationItem[]) => list.map((notification) => {
  const {type} = notification;
  const modificationType = modificationNotification[type];

  // @ts-ignore
  const typeData: DefaultData = notification[modificationType.dataKey] || {};

  const modification = modificationNotification[typeData.event_type] || modificationType;

  return {...notification, ...modification}
});