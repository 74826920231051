import React from "react";
import moment from "moment";
import {copy, momentFormatOptions, withUnits} from "../utils";
import toast from "../../components/toast/toast";
import FormComponent from "../../components/controls/FormComponent";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { requiredValidator } from "../../components/controls/validators";
import _ from "lodash";

export const CASE_BUTTON = {
  chase_a_case: 'chase_a_case',
  raise_a_case: 'raise_a_case',
};

export const supportAmazon = 'https://sellercentral.amazon.co.uk/ap/signin?clientContext=261-4048971-6059668&openid.return_to=https%3A%2F%2Fsellercentral.amazon.co.uk%2Fhelp%2Fhub%2Fsupport%2Fbrowse-GB-fulfillment_by_amazon_fba-investigate_other_fba_issues-other_fba_issue&openid.identity=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&openid.assoc_handle=sc_uk_amazon_v2&openid.mode=checkid_setup&openid.claimed_id=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&openid.ns=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0&mons_redirect=sign_in&ssoResponse=eyJ6aXAiOiJERUYiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiQTI1NktXIn0.UvFHWEw6Xg_0Jn-YIo--TfIg6MQMcKEOxBkQXwmACyaNr_wDUe2I7g.qCZsHX9qvbgf-pAs.bzL-9AmYzJaDeKjfe0Tml5eRuHMdyMVsjPCq1bE16fZ-hYPABWwaBz_LqXbwI8U700sTuWQyjtSEgZovxCTMKABTiktxeTrIrekNu6Uwr4BMQsRHn_qJzoBYyMdgZRZTVa53Jk4M6Qj_j2Rvg2v76zP4cjOmjvRaTq4A1_teE0VSPf2wVwLLgaXLHiYRhF-UfzII_nO6Mt1VU8_t-jl5TctVdN4BlORYiveEGUVNcifE2blcmbLuoFARcXVn7OrWKAvr8A.vF74xaI9DR6E1U5IE1jfrQ';

export const onCopy = (content, name) => () => {
  copy(content);
  toast.success(`${name} copied!`);
};

export const interfaceRaiseCase = [
  {id: "case_id", type: "text", label: "Case ID", validate: requiredValidator("Case ID is required!")},
  {id: "date_raised", type: "dateRB", label: "Date Case Raised",},
  {
    id: "subject",
    type: "textareaAutosize",
    label: "Subject",
    disabled: true,
    render: ({ customProps, ...props }) => {
      const { label, name, values } = props;
      const _customProps = {
        rows: 2,
        rowsMax: 2,
        beforeField: (
          <span onClick={onCopy(values[name], label)} className="pointer">
           <FileCopyIcon fontSize="small" className="icon" htmlColor="var(--color-secondary)"/>
          </span>
        )
      };
      return (
        <FormComponent customProps={_customProps} {...props} />
      )
    }
  },
  {
    id: "text",
    type: "textareaAutosize",
    label: "Text",
    disabled: true,
    render: ({ customProps, ...props }) => {
      const { label, name, values } = props;
      const _customProps = {
        rowsMax: 15,
        beforeField: (
          <span onClick={onCopy(values[name], label)} className="pointer">
           <FileCopyIcon fontSize="small" className="icon" htmlColor="var(--main)"/>
          </span>
        )
      };
      return (
        <FormComponent customProps={{..._customProps}} {...props} />
      )
    }
  },
];

export const onError = (e) => {
  toast.error(`${e} 
    Some error occurred`, { duration: 5000 })
};

export const onSubmit = ({ toggleOpenModal, updateRaiseCase, id,  }) => (values, { setSubmitting, resetForm }) => {
  const data = {...values};
  data.date_raised = moment(data.date_raised).format('YYYY-MM-DD');

  const onDone = () => {
    setSubmitting(false);
    toggleOpenModal();
    resetForm();
    toast.success("Your case was successfully sent!", { duration: 1500 })
  };
  updateRaiseCase({ blockName: "Case Logs", id, values: data, onDone, onError });
};


export const getInitValuesRaiseCase = ({ data, user }) => {
  const textData = _.omit(data, ["SKU", "FNSKU", "total missing items"]);
  const formatText = _.map(textData, (val, key) => `${key} = ${val ? val : '0'}`).join("\n");
  const listBlock = formatText
    ? `-----------------------------------------------
${formatText}
-----------------------------------------------`
    : "";
  const SKU = data.SKU || "—";
  const FNSKU = data.FNSKU || "—";

  const totalBlock = data["total missing items"]
    ? `Giving total missing items of ${data["total missing items"] || ""}`
    : "";
  return  {
    date_raised: moment().format(""),
    // important!!! the text formatting will appear in textarea
    subject: `Missing Reimbursements for SKU: ${SKU} (FNSKU: ${FNSKU} )`,
    text:
  `Dear Amazon Support,

I'd like to bring to your attention certain items that appear to have been misplaced or lost within your warehouses. As the designated time for reimbursement has surpassed, I kindly request that these items be reimbursed.

Here's a breakdown of the inventory:

${listBlock}

${totalBlock} 


Thank you for your attention to this matter.
    
Warm regards,

${user.first_name} ${user.fullname}`
  ,
  }
};

const checkVal = (val) => !!val && val !== 'None' ? val : '';

export const getInitValuesChaseCase = ({ data, user }) => {
  const {SKU, FNSKU, inventory_adjustments, transaction_detail_list, not_returned_detail_list } = data;
  const valueSKU = SKU || "—";
  const valueFNSKU = FNSKU || "—";

  let textInventory = '', textTransactions = '', notReturnedText = '';
  if (inventory_adjustments) {
    const {
      adjustments_type,
      adjustments,
      reimbursements,
      'missing reimbursements': missingReimbursements
    } = inventory_adjustments;
    const _adjustments = checkVal(adjustments) ? `adjustments = ${adjustments}` : '';
    const _reimbursements = `reimbursements = ${reimbursements}`;
    const _missingReimbursements = checkVal(missingReimbursements) ? `missing reimbursements = ${missingReimbursements}` : '';
      textInventory = `${checkVal(adjustments_type)}
${_adjustments} ${_reimbursements} ${_missingReimbursements}
`;
  }

  if (transaction_detail_list?.length) {
    textTransactions = transaction_detail_list?.reduce((prev, value) => {
      const { Disposition, Date, Transaction, ['Number Of Units']: numberOfUnits } = value;
      return `${prev}
Disposition Date Transaction Number of units
${checkVal(Disposition)} ${checkVal(Date)} ${checkVal(Transaction)} ${checkVal(numberOfUnits)}
`
    }, ``);
  }

  if (not_returned_detail_list?.length) {
   const notReturnedValues = not_returned_detail_list .reduce((prev, curr) => {
      const {['Refund Date']: date, ['Order ID']: order, ['Number Of Units not returned']: numberOfUnits} = curr;
      const dateVal = checkVal(date) ? moment(date).format('MM-DD-YYYY') : '';
      return `${prev}
Refund Date Order Id Number of unit not returned
${dateVal} ${checkVal(order)} ${checkVal(numberOfUnits)}
`
    }, '');
    notReturnedText = `----------------------------------------
Customer Orders - Non Returned Details:
${notReturnedValues}`
  }

  return {
    date_raised: moment().format(""),
    // important!!! the text formatting will appear in textarea
    subject: `Missing Reimbursements for SKU:${valueSKU} (FNSKU: ${valueFNSKU} )`,
    text: `Dear Amazon,

I'm reaching out to request reimbursement for specific inventory items that, to my knowledge, haven't been addressed. The details indicate that these items haven't been returned by customers, and the reimbursement for the same hasn't been processed.

----------------------------------------
Inventory Adjustments:
${textInventory}
----------------------------------------
Transaction Details:
${textTransactions}
${notReturnedText}
        
Given the situation, I'd be grateful if you could process the reimbursement promptly. Thank you in advance for your attention and assistance.

Warm regards,

${user.first_name} ${user.fullname}`
,
  };
};


export const caseLogsFields = [
  {name: 'case_id', label: 'Case ID',},
  {name: 'date_raised', label: 'Date Raised',},
  {name: 'reason', label: 'Reason',},
  {name: 'amount_total', label: 'Amount Total',},
  {name: 'reimbursed_cash', label: 'Reimbursed Cash',},
  {name: 'reimbursed_inventory', label: 'Reimbursed Inventory',},
];

export const caseLogs = caseLogsFields.map(({name}) => name);

export const fieldsWithCurrency = ['amount_total'];
export const dateFields = ['date_raised'];

export const formatRowData = ({name, field, currency}) => {
  let value = field;
    if (fieldsWithCurrency.includes(name)) {
      value = withUnits(currency, field)
    }
    if (dateFields.includes(name)) {
      value = moment(field).format(momentFormatOptions.display)
    }
  return value;
};