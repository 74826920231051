import React, {useEffect, useState} from "react";
import moment from "moment";
import {Collapse, Grid, Button, Paper, Typography, TableCell} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import {
  asinSales,
  initPeriods,
  initValues,
  overviewInterfaces,
  periodName,
  inventoryByCategory
} from "./asinIntarfaces";
import AsinList, {contentItem} from "./AsinList";
import {momentFormatOptions} from "../../utils";
import clsx from "clsx";
import DSplashScreen from "../../../components/SplashScreen/DSplashScreen";
import DashboardChart from "../../Dashboard/ChartSection/DashboardChart";
import {useThemeSwitcher} from "react-css-theme-switcher";
import {Themes} from "../../../../types/enums";
import {asinColorsDark, asinColorsLight, chartContentAsin, dateOptions} from "../../Dashboard/ChartSection/dataChart";
import useChart from "../../../../hooks/chart.hook";
import AsinDate from "./AsinDate";
import {date} from "../../Dashboard/Dashboard";
import useAsin from "../../../../hooks/asin.hook";
import {changedOptions, errorId} from "./utils";
import ChangeInfo from "./ChangeInfo";

export const getDate = (date) => moment(date).format(momentFormatOptions.display);
export const getOption = (period) =>
  dateOptions.find((item) => item.period === period);

export const getTitleLabel = ({time}) => {
  return time.period.period === "custom" ? `${getDate(time.startDate)} - ${getDate(time.endDate)}` : time.period.label ;
}

const AsinOverview = ({row, handleDisplayMetrics, showMetricsRow, colSpan, currency}) => {
  let {
    id,
    product_id,
    average_units,
    reimbursed,
    last_recalculate,
    cautions,
    last_order_date,
    unfulfillable_stock,
    estimated_ltsf_list,
    inventory_age_list,
    inventory_by_category_list,
    unfulfillable_stock_list,
    image_url,
    title_changes,
    item_name,
    picture_changes,
  } = row;

  const isShowRow = id === `${showMetricsRow}--metrics-row`;

  const propsChart = {
    marketplace: "all_marketplaces",
    autoRefresh: false,
    product_id: showMetricsRow,
  };

  const {getAsinProfitReport, asinProfitReport, loadingAsinProfitReport} = useAsin();
  const {loading: loadingChart, chart = {}, refresh} = useChart({fetch: false});
  const {by_marketplaces} = chart;

  const [anchor, setAnchor] = useState({anchorEl: null, name: ''});
  const loading = false;
  const [rowsValues, setRowsValues] = useState(initValues);
  const [profit, setProfit] = useState([]);
  const [profitValues, setProfitValues] = useState({});

  const [periods, setPeriods] = useState(initPeriods);

  const {currentTheme} = useThemeSwitcher();
  const isDark = currentTheme === Themes.dark;
  const colors = isDark ? asinColorsDark : asinColorsLight;
  const legend = [
    {name: 'Sales', color: colors.sales1},
    {name: 'Profit', color: colors.profit1},
  ];

  const checked = legend.map(({name}) => name);

  useEffect(() => {
    const changed = changedOptions.reduce((res, name) =>
      ({...res, [name]: !!cautions?.find(({type}) => type === name)}), {});

    const listValues = {
      informList2: {
        ...initValues.informList2,
        unfulfillable_stock,
        unfulfillable_stock_list,
        reimbursed,
        ...changed
      },
      informList3: {
        ...initValues.informList3,
        ...average_units,
        currency
      },
      informList4: {
        ...initValues.informList4,
        ...average_units,
        last_recalculate: last_recalculate ? moment(last_recalculate).format("lll") : "-",
        last_order_date: last_order_date ? moment(last_order_date).format("lll") : "-",
        currency
      }
    };

    setRowsValues(listValues)
  }, [row]);

  useEffect(() => {
    const initPeriod = dateOptions.find((option) => option.default);
    if (isShowRow) {
      const start_date = date(initPeriod.value);

      refresh({
        ...propsChart,
        period: initPeriod.period,
        start_date,
        names: Object.values(periodName)
      });

      getAsinProfitReport({
        start_date,
        end_date: moment().format(momentFormatOptions.backend),
        product_id
      })
    }
  }, [isShowRow]);

  useEffect(() => {
    if (asinProfitReport?.length) {
      const updateValues = asinProfitReport.map((item) => ({
        ...item,
        ...(item.format === "currency_format" ? {unit: (currency) => currency} : {}),
        ...(item.format === "percentages_format" ? {unit: () => "%"} : {}),
      }));

      const values = asinProfitReport.reduce((res, val) => ({...res, [val.id]: val.value}), {});
      setProfit(() => updateValues);
      setProfitValues(values);
    }
  },[asinProfitReport]);

  const handleClickDate = (event, title) => {
    setAnchor(anchor.name ? {anchorEl: null, name: "" } : {anchorEl: event.currentTarget, name: title});
  };

  const onChangePeriod = ({name, value, period, colName}) => {
    const start_date = value.startDate;
    const end_date = value.endDate;
    setPeriods({
      ...periods,
      [name]: {...value, period: getOption(period)}
    });

    if (colName === periodName.profit) {
      getAsinProfitReport({
        start_date,
        end_date: end_date || moment().format(momentFormatOptions.backend),
        product_id
      })
    } else {
      refresh({
        ...propsChart,
        period,
        start_date,
        end_date,
        names: [name]
      });
    }
  };

  const onSelectedPeriod = (colName) => ({name, ...data}) => {
    const {value, period } = data;
    onChangePeriod({name, value: {startDate: date(value)}, period, colName})
  };

  const onCustomPeriod = (colName) => ({name, ...period}) => {
    onChangePeriod({name, value: period, period: "custom", colName })
  };

  return (isShowRow &&
    <TableCell className="table__cell--photo--extra" colSpan={colSpan}>
      <Collapse in={isShowRow}>
        <Paper elevation={0} className="metrics-row">

          <Button
            variant="contained"
            className="metrics-row__close"
            startIcon={<CloseIcon fontSize="small" htmlColor="var(--color-active)"/>}
            onClick={(e) => handleDisplayMetrics(e, row)}
          >
            close
          </Button>
          <Grid container>
            <div className="metrics-row__main-header">
              <Typography variant="h6" className="title">
                ASIN Overview
              </Typography>
            </div>

            <Grid container spacing={4} className="metrics-row__block metrics-row--border">
              <Grid item xs={12}>
                <div className="metrics-row__main-header metrics-row__header">
                  <Typography variant="h6" className="title">Overview</Typography>
                </div>
              </Grid>

              {overviewInterfaces.map(({data, name}, index) => (
                <Grid key={index} item xs={3}>
                  <div className="metrics-row__inventory-inform metrics-row__inventory-inform--extra">
                    <AsinList data={data} values={rowsValues[name] || row}/>
                  </div>
                </Grid>
              ))}
            </Grid>

            <Grid container spacing={4} className="metrics-row__block">
              {/*Inventory by Category start*/}
              <Grid item xs={6}>
                <div
                  className="metrics-row__inventory-inform metrics-row__inventory-inform--extra metrics-row--border full-height">
                  <div className="metrics-row__main-header metrics-row__header">
                    <Typography variant="h6" className="title">Inventory by Category</Typography>
                  </div>
                  {inventory_by_category_list.map((values, index) => {
                    return (
                      <div
                        key={index}
                        className={clsx(`metrics-row__table-row metrics-col--${inventoryByCategory.length}`, {
                          "title": !index
                        })}
                      >
                        <AsinList
                          isVertical
                          data={inventoryByCategory}
                          values={{...values, currency}}
                        />
                      </div>
                    )
                  })}
                </div>
              </Grid>
              {/*Inventory by Category start*/}

              {/*Profit start*/}
              <Grid item xs={3}>
                <div
                  className="metrics-row__inventory-inform metrics-row__inventory-inform--extra metrics-row--border full-height">
                  <AsinDate
                    onCustomPeriod={onCustomPeriod(periodName.profit)}
                    onSelectedPeriod={onSelectedPeriod(periodName.profit)}
                    name={periodName.profit}
                    time={periods[periodName.profit].period}
                    title={getTitleLabel({time: periods[periodName.profit]})}
                    isClose={loadingAsinProfitReport}
                    anchor={anchor}
                    handleClickDate={handleClickDate}
                    setAnchor={setAnchor}
                  />
                  <div className="d-splash-screen--wrap small-screen">
                    {loadingAsinProfitReport && <DSplashScreen/>}
                    <AsinList data={profit} values={{...profitValues, currency}}/>
                  </div>
                </div>
              </Grid>
              {/*Profit end*/}

              {/*/!*Sales start*!/*/}
              <Grid item xs={3}>
                <div
                  className="metrics-row__inventory-inform metrics-row__inventory-inform--extra metrics-row--border full-height">
                  <AsinDate
                    onCustomPeriod={onCustomPeriod(periodName.sales)}
                    onSelectedPeriod={onSelectedPeriod(periodName.sales)}
                    name={periodName.sales}
                    time={periods[periodName.sales].period}
                    title={getTitleLabel({time: periods[periodName.sales]})}
                    isClose={loading}
                    anchor={anchor}
                    handleClickDate={handleClickDate}
                    setAnchor={setAnchor}
                  />

                  <div className="d-splash-screen--wrap small-screen">
                    {loading && <DSplashScreen/>}
                    {by_marketplaces.filter(({id}) => id !== 'total').map((values, index) => {
                      return (
                        <div
                          key={index}
                          className={clsx(`metrics-row__table-row metrics-col--${asinSales.length}`, {"title": !index})}
                        >
                          <AsinList
                            isVertical
                            data={asinSales}
                            values={{...values, currency}}
                          />
                        </div>
                      )
                    })}
                  </div>
                </div>
              </Grid>
              {/*Sales end*/}
            </Grid>

            <Grid container spacing={4} className="metrics-row__block">
              <Grid item xs={6}>
                <div className="metrics-row__inventory-inform metrics-row__inventory-inform--extra metrics-row--border">
                  <AsinDate
                    onCustomPeriod={onCustomPeriod(periodName.sales_profit)}
                    onSelectedPeriod={onSelectedPeriod(periodName.sales_profit)}
                    name={periodName.sales_profit}
                    childClass="asin__chart-legend"
                    time={periods[periodName.sales_profit].period}
                    title={getTitleLabel({time: periods[periodName.sales_profit]})}
                    childEl={
                      <>
                        {legend.map(({name, color}) => (
                          <Typography key={name} variant="body1">
                            <Typography variant="caption" style={{background: `${color}`}} />
                            {name}
                          </Typography>
                        ))}
                      </>
                    }
                    isClose={loadingChart}
                    anchor={anchor}
                    handleClickDate={handleClickDate}
                    setAnchor={setAnchor}
                  />

                  <div className="d-splash-screen--wrap small-screen">
                    {loadingChart && <DSplashScreen/>}
                    <DashboardChart
                      chartContent={{...chart, ...chartContentAsin}}
                      checked={checked}
                      time="Custom"
                      colors={colors}
                      isDark={isDark}
                      disabled={anchor?.name}
                    />
                    {!!title_changes?.length && <div id={errorId.title} />}
                    {!title_changes?.length && !!picture_changes?.length && <div id={errorId.image} />}
                  </div>
                </div>
              </Grid>

              <Grid item xs={3}>
                <div className="metrics-row__inventory-inform metrics-row__inventory-inform--extra metrics-row--border full-height">
                  <div className="metrics-row__main-header metrics-row__header">
                    <Typography variant="h6" className="title">Inventory Age</Typography>
                  </div>
                  {inventory_age_list.map((item) => contentItem({...item, value: item.data}))}
                </div>
              </Grid>

              <Grid item xs={3}>
                <div className="metrics-row__inventory-inform metrics-row__inventory-inform--extra metrics-row--border full-height">
                  <div className="metrics-row__main-header metrics-row__header">
                    <Typography variant="h6" className="title">Estimated LTSF</Typography>
                  </div>
                  {estimated_ltsf_list.map((item) => contentItem({...item, value: item.data}))}
                </div>
              </Grid>
            </Grid>

            <ChangeInfo
              titleBlock="Title Changes"
              data={title_changes}
              image_url={image_url}
              currentTitle={item_name}
              anchor={errorId.image}
            />

            <ChangeInfo
              titleBlock="Image Changes"
              data={picture_changes}
              image_url={image_url}
              currentTitle={item_name}
            />
          </Grid>
        </Paper>
      </Collapse>
    </TableCell>
  )
};

export default AsinOverview;