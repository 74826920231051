import moment from "moment";
import {round, withCurrency} from "../utils";
import React from "react";
import TableCell from "@material-ui/core/TableCell";
import {momentFormatOptions} from "../../utils";

export const headRowsModal = [
  {
    id: 'date',
    label: 'Date',
    type: 'date',
    isActive: true,
    renderText: (row) => {
      return  moment(row.date, 'YYYY-MM-DDTHH:mm:ss.SSS').format(momentFormatOptions.backend);
    }
  },
  {
    id: 'type',
    label: 'Type',
    type: 'text',
    isActive: true,
    render: (row, { onTypeClick }) => {
      return <TableCell align="left" key={`${row.id}--type`} onClick={onTypeClick(row)} className="table__cell--link">
        <span className="table__cell--link__text">{row.type}</span>
      </TableCell>
    }},
  {
    id: 'description',
    label: 'Description',
    type: 'text',
    isActive: true,
  },
  {
    id: 'debit_amount',
    label: 'Debit Amount',
    type: 'number',
    isActive: true,
    renderText: (row) => {
      return row.type === 'MI'
        ? (row.currency.currency_symbol
          ? withCurrency(row.total_amount, row.currency.currency_symbol)
          : round(row.total_amount,  2))
        : '-';
    }
  },
  {
    id: 'credit_amount',
    label: 'Credit Amount',
    type: 'number',
    isActive: true,
    renderText: (row) => {
      return row.type === 'MO'
        ? ( row.currency.currency_symbol
          ? withCurrency(row.total_amount, row.currency.currency_symbol)
          : round(row.total_amount,  2))
        : '-';
    }
  },
  // {
  //   id: 'balance',
  //   label: 'Balance',
  //   type: 'number',
  //   isActive: true,
  //   renderText: (row) => {
  //     return withCurrency(row.balance, row.currency.currency_symbol);
  //   }
  // },
];

export const transItems = [
  {
    id: 6,
    date: "2020-01-01",
    balance: 489.21,
    "currency": {
      "id": 158,
      "name": "Euro",
      "currency_code": "EUR",
      "currency_symbol": "€",
    },
    description: "test",
    type: "MI",
    "account_type": "BankAccount",
    net_amount: 111.0000000000,
    tax_amount: 123.2100000000,
    total_amount: 234.2100000000,
    conversion_rate: 0.0000000000,
    "is_split": false,
    "confirmed": true,
    "ignored": false,
    "tax_code": 1,
    "category_to": 172,
    "bank_to": 5,
    "bank_entry": null,
    "bank_rule": null
  },
  {
    id: 10,
    date: "2020-01-01",
    balance: 489.21,
    "currency": {
      "id": 158,
      "name": "Euro",
      "currency_code": "EUR",
      "currency_symbol": "€",
    },
    description: "Sales for test",
    type: "MI",
    "account_type": "NominalAccount",
    net_amount: 100.0000000000,
    tax_amount: 20.0000000000,
    total_amount: 120.0000000000,
    conversion_rate: 0.0000000000,
    "is_split": false,
    "confirmed": true,
    "ignored": false,
    "tax_code": 2,
    "category_to": 203,
    "bank_to": null,
    "bank_entry": null,
    "bank_rule": null
  },
  {
    id: 13,
    date: "2020-01-01",
    balance: 489.21,
    "currency": {
      "id": 158,
      "name": "Euro",
      "currency_code": "EUR",
      "currency_symbol": "€",
      "decimal_places": 2,
      "created_at": "2020-01-20T08:57:40.391787Z",
      "updated_at": "2020-01-20T08:57:40.391803Z"
    },
    type: "CR",
    "account_type": "NominalAccount",
    net_amount: 2.0000000000,
    tax_amount: 0.0000000000,
    total_amount: 2.0000000000,
    conversion_rate: 0.0000000000,
  }
];