import api from "../../crud/dashboardTiles.crud.js";
import { call, put, takeLatest } from "redux-saga/effects";

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  params: {
    period: "all",
    start_date: null,
    end_date: null
  },
  expenses: {
    data: {},
    loading: false
  },
  inventoryCost: 0.0,
  inventoryCostMarketplaces: [],
  inventoryCostWarning: false,
  inventoryQuantity: 0,
  resaleValue: 0.0,
  loading: true
};

const common = createSlice({
  name: "dashboardTiles",
  initialState: initialState,
  reducers: {
    stopLoading: (state, actions) => {
      const {type} = actions.payload;
      if (type) {
        state[type].loading = false;
      } else {
        state.loading = false;
      }
      return state;
    },
    getExpensesDash: (state, actions) => {
      state.expenses.loading = true;
      return state;
    },
    getExpensesDashSuccess: (state, actions) => {
      const { expenses } = actions.payload;
      state.expenses.data = expenses;
      state.expenses.loading = false;
      return state;
    },

    getInventoryCost: (state, actions) => {
      state.loading = true;
      return state;
    },
    getInventoryCostSuccess: (state, actions) => {
      const { inventory_cost, inventory_cost_units, by_marketplaces } = actions.payload;
      state.inventoryCost = inventory_cost;
      state.inventoryCostMarketplaces = by_marketplaces;
      state.inventoryCostWarning = false;
      state.inventoryQuantity = inventory_cost_units;
      state.loading = false;
      return state;
    },

    getResaleValue: (state, actions) => {
      state.loading = true;
      return state;
    },

    getResaleValueSuccess: (state, actions) => {
      const { resaleValue } = actions.payload;
      state.resaleValue = resaleValue.value;
      state.loading = false;
      return state;
    }
  }
});

export const actions = common.actions;
export const reducer = common.reducer;

export function* saga() {
  yield takeLatest(actions.getExpensesDash, function* getExpensesDashSaga({payload={}}) {
    try {
      const { ...params } = payload
      const {data} = yield call(api.getExpensesDash, params)
      if (data) {
        yield put(actions.getExpensesDashSuccess({ expenses: data}))
      } else {
        yield put(actions.getExpensesDashSuccess({ expenses: {
            data: {},
            loading: false
          }
        }));
        yield put(actions.stopLoading({type: "expenses"}));
      }
    } catch (err) {
      yield put(actions.stopLoading());
      console.error(err);
    }
  });

  yield takeLatest(actions.getInventoryCost, function* getInventoryCostSaga({
    payload = {}
  }) {
    try {
      const { ...params } = payload;
      const { data } = yield call(api.getInventoryCost, params);
      if (data) {
        yield put(actions.getInventoryCostSuccess(data));
      } else {
        yield put(actions.stopLoading());
      }
    } catch (err) {
      yield put(actions.stopLoading());
      console.error(err);
    }
  });

  yield takeLatest(actions.getResaleValue, function* getResaleValueSaga({
    payload = {}
  }) {
    try {
      const { ...params } = payload;
      const { data } = yield call(api.getResaleValue, params);
      if (data) {
        yield put(actions.getResaleValueSuccess({ resaleValue: data }));
      } else {
        yield put(actions.stopLoading());
      }
    } catch (err) {
      yield put(actions.stopLoading());
      console.error(err);
    }
  });
}
