import { createSelector } from "reselect";

const Base = () => state => state.reports;

export const getSalesReports = createSelector(
  Base(),
  (state) => state.salesReports,
);

export const getProductReport = createSelector(
    Base(),
    (state) => state.productReport,
);

export const getOrderReport = createSelector(
  Base(),
  (state) => state.orderReport,
);

export const getExpenses = createSelector(
  Base(),
  (state) => state.expenses,
);

export const getMainReports = createSelector(
  Base(),
  (state) => state.mainReports,
);

export const getExpensesTransactions = createSelector(
  Base(),
  (state) => state.expensesTransactions,
);

export const addImportsExpenses = createSelector(
  Base(),
  (state) => state.noteImportsExpenses,
);