import React, {useEffect, useState} from "react";
import InputBase from "@material-ui/core/InputBase";
import {alpha, makeStyles} from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import * as PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import "./search.scss";
import OverlayComponent from "../OverlayComponent";

const useStyles = makeStyles(theme => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: 'auto',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pasteIcon: {
    width: theme.spacing(4),
    height: '100%',
    position: 'absolute',
    right: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    border: "1px solid var(--light-grey)"
  },
  inputInput: {
    padding: theme.spacing(1, 4, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
}));

const DSearch = ({setSearchQuery, options, isClear, setClear, initValue = ""}) => {
  const classes = useStyles();
  const [value, setValue] = useState("");


  useEffect(() => {
    if (initValue) {
      setValue(initValue);
    } else {
      isClear && setValue("")
    }
  }, [isClear, initValue]);

  const handlePaste = async () => {
    const clipboard = await navigator?.clipboard?.readText();
    if (clipboard) {
      setClear(false);
      setValue(clipboard);
      setSearchQuery(clipboard);
    }
  }

  return (
    <div className={"table-toolbar__search " + classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon/>
      </div>
      {
        options
          ? <Autocomplete
            freeSolo
            disableClearable
            options={options.map(option => option?.title)}
            onChange={(e, value) => {
              setClear(false);
              setSearchQuery(value);
              setValue(value);
            }}
            value={value}
            renderInput={(params) => {
              return <TextField
                {...params}
                className=" d-search--auto"
                label=""
                margin="normal"
                variant="outlined"
                onChange={event => {
                  setClear(false);
                  setValue(event?.target?.value);
                  setSearchQuery(event?.target?.value);
                }}
                onKeyDown={event => {
                  if (event?.key === 'Enter') {
                    event.preventDefault();
                    // onRefresh();
                  }
                }}
                value={value}
                classes={{root: classes.inputRoot}}
                InputProps={{...params?.InputProps, type: 'search', 'aria-label': 'Search'}}
              />
            }}
          />
          : <InputBase
            className="d-search"
            placeholder="Search…"
            onChange={event => {
              setClear && setClear(false);
              setValue(event?.target?.value);
              setSearchQuery(event?.target?.value)
            }}
            value={value}
            onKeyDown={event => {
              if (event?.key === 'Enter') {
                event.preventDefault();
                // onRefresh();
              }
            }}
            classes={{root: classes.inputRoot, input: classes.inputInput,}}
            inputProps={{'aria-label': 'Search'}}
          />
      }
      <div className={classes.pasteIcon}>
        <OverlayComponent tooltip="Paste copied to clipboard">
          <FilterNoneIcon onClick={handlePaste}/>
        </OverlayComponent>
      </div>
    </div>
  )
};

DSearch.propTypes = {
  setSearchQuery: PropTypes.func,
  options: PropTypes.array,
  isClear: PropTypes.bool,
  setClear: PropTypes.func,
};
export default DSearch;
