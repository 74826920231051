import React from "react";
import { Form as FormikForm, Formik } from "formik";
import FormField from "../../components/controls/FormField";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Form from "react-bootstrap/Form";

export default function TabSettings(props) {
  const { fields, initData, onSubmit, className, successNotification, errorNotification, bottomContent } = props;
  return (
    <Formik
      enableReinitialize
      initialValues={initData}
      onSubmit={onSubmit}
      autoComplete="off"
    >
      {(form) => (
        <FormikForm>
          <div className={className + "-settings__tab content__tab"}>
            {fields.map((item) => (
              <Form.Group key={item.id} controlId={item.id} className={`form-group--${item.id}`}>
                {!item.showLabel && item.label && <Form.Label column={false}>{item.label}</Form.Label>}
                <FormField
                  render={item.render}
                  name={item.id}
                  label={item.label}
                  showLabel={item.showLabel}
                  type={item.type}
                  disabled={item.disabled || form.values.no_registered}
                  customProps={item.customProps}
                  placeholder={item.placeholder}
                  validate={form.values.registered? item.validator : null}
                  extraProps={{ form }}
                  {...form}
                />
              </Form.Group>
            ))}
            {bottomContent}
            <div className={className + "-settings__wrap-btn settings__wrap-btn"}>
              <Button
                className={className + "-settings__btn settings__btn"}
                variant="contained"
                type="submit"
                disabled={form.isSubmitting}
              >
                Update
              </Button>
            </div>
          </div>
          {errorNotification}
          {successNotification}
        </FormikForm>
      )}
    </Formik>
  );
};

TabSettings.propTypes = {
  fields: PropTypes.array,
  updateSetting: PropTypes.func,
  initData: PropTypes.object,
  type: PropTypes.string,
  className: PropTypes.string,
};