import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import ContentSubscribe from "../../../../auth/Onboarding/OnboardingSubscribe/ContentSubscribe";

function Pricing() {
  const [period, setPeriod] = useState("month");
  const onToggle = () => setPeriod(() => period === "month" ? "years" : "month");
  return (
    <>
     <section className="row_am pricing_section service_list_ps" id="pricing" data-aos="fade-in" data-aos-duration="1000">
      
      <div className="container">
        <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
          <h2>Sign Up Now For Grandfathered In Prices!</h2>
          <p>We NEVER increase our prices on current customers. As long as you remain a customer of SKUify you will get today's current price LOCKED in for life! We are ALWAYS adding in new features and then we increase the price, as we do, you'll get those features included for FREE in your plan, no matter what happens to the price!</p>
        </div>
      
        {/*<div className="toggle_block" data-aos="fade-up" data-aos-duration="1500">*/}
          {/*<span className={classNames("month", {'active': period === "month"})}>Monthly</span>*/}
          {/*<div className="tog_block" onClick={onToggle} >*/}
            {/*<span className={classNames("tog_btn", {'month_active': period === "years"})}></span>*/}
          {/*</div>*/}
          {/*<span className={classNames("years", {'active': period === "years"})}>Yearly</span>*/}
          {/*<span className="offer">50% off</span>*/}
        {/*</div>*/}

      
        <div className="pricing_pannel monthly_plan active">
         
          <div className="row">

            <ContentSubscribe isRedirect isPricing />
           
            {/*<div className="col-md-4">*/}
              {/*<div className="pricing_block">*/}
                {/*<div className="icon">*/}
                  {/*<img src="media/codely-theme-files/assets/images/new/Free-Trial-01.svg" alt="image"/>*/}
                  {/*<div className="dot_block">*/}
                    {/*<span className="dot_anim"></span>*/}
                    {/*<span className="dot_anim"></span>*/}
                    {/*<span className="dot_anim"></span>*/}
                  {/*</div>*/}
                {/*</div>*/}
                {/*<div className="pkg_name">*/}
                  {/*<h3>Free Trial</h3>*/}
                  {/*<span>For the basics</span>*/}
                {/*</div>*/}
                {/*<span className="price">$0<span>/Month</span></span>*/}
                {/*<ul className="benifits">*/}
                  {/*<li className="include">*/}
                    {/*<p><i className="icofont-check-circled"></i>7 Days free trial</p>*/}
                  {/*</li>*/}
                  {/*<li className="include">*/}
                    {/*<p><i className="icofont-check-circled"></i>2 platform of your choice</p>*/}
                  {/*</li>*/}
                  {/*<li className="include">*/}
                    {/*<p><i className="icofont-check-circled"></i>10 GB Dedicated Hosting free</p>*/}
                  {/*</li>*/}
                  {/*<li className="exclude">*/}
                    {/*<p><i className="icofont-close-circled"></i>Unlimited updates</p>*/}
                  {/*</li>*/}
                  {/*<li className="exclude">*/}
                    {/*<p><i className="icofont-close-circled"></i>Live support</p>*/}
                  {/*</li>*/}
                {/*</ul>*/}
                {/*<Link to="#" className="btn btn_main">BUY NOW <i className="icofont-arrow-right"></i></Link>*/}
              {/*</div>*/}
            {/*</div>*/}
            
            {/*<div className="col-md-4">*/}
              {/*<div className="pricing_block highlited_block">*/}
                {/*<div className="icon">*/}
                  {/*<img src="media/codely-theme-files/assets/images/new/unlimited.png" alt="image"/>*/}
                  {/*<div className="dot_block">*/}
                    {/*<span className="dot_anim"></span>*/}
                    {/*<span className="dot_anim"></span>*/}
                    {/*<span className="dot_anim"></span>*/}
                  {/*</div>*/}
                {/*</div>*/}
                {/*<div className="pkg_name">*/}
                  {/*<h3>Unlimited</h3>*/}
                  {/*<span>For the professionals</span>*/}
                {/*</div>*/}
                {/*<span className="price">$99<span>/Month</span></span>*/}
                {/*<ul className="benifits">*/}
                  {/*<li className="include">*/}
                    {/*<p><i className="icofont-check-circled"></i>7 Days free trial</p>*/}
                  {/*</li>*/}
                  {/*<li className="include">*/}
                    {/*<p><i className="icofont-check-circled"></i>2 platform of your choice</p>*/}
                  {/*</li>*/}
                  {/*<li className="include">*/}
                    {/*<p><i className="icofont-check-circled"></i>10 GB Dedicated Hosting free</p>*/}
                  {/*</li>*/}
                  {/*<li className="include">*/}
                    {/*<p><i className="icofont-check-circled"></i>Unlimited updates</p>*/}
                  {/*</li>*/}
                  {/*<li className="include">*/}
                    {/*<p><i className="icofont-check-circled"></i>Live support</p>*/}
                  {/*</li>*/}
                {/*</ul>*/}
                {/*<Link to="#" className="btn btn_main">BUY NOW <i className="icofont-arrow-right"></i></Link>*/}
              {/*</div>*/}
            {/*</div>*/}


            {/*<div className="col-md-4">*/}
              {/*<div className="pricing_block">*/}
                {/*<div className="icon">*/}
                  {/*<img src="media/codely-theme-files/assets/images/new/Premium.svg" alt="image"/>*/}
                  {/*<div className="dot_block">*/}
                    {/*<span className="dot_anim"></span>*/}
                    {/*<span className="dot_anim"></span>*/}
                    {/*<span className="dot_anim"></span>*/}
                  {/*</div>*/}
                {/*</div>*/}
                {/*<div className="pkg_name">*/}
                  {/*<h3>Premium</h3>*/}
                  {/*<span>For small team</span>*/}
                {/*</div>*/}
                {/*<span className="price">$55<span>/Month</span></span>*/}
                {/*<ul className="benifits">*/}
                  {/*<li className="include">*/}
                    {/*<p><i className="icofont-check-circled"></i>7 Days free trial</p>*/}
                  {/*</li>*/}
                  {/*<li className="include">*/}
                    {/*<p><i className="icofont-check-circled"></i>2 platform of your choice</p>*/}
                  {/*</li>*/}
                  {/*<li className="include">*/}
                    {/*<p><i className="icofont-check-circled"></i>10 GB Dedicated Hosting free</p>*/}
                  {/*</li>*/}
                  {/*<li className="include">*/}
                    {/*<p><i className="icofont-check-circled"></i>Unlimited updates</p>*/}
                  {/*</li>*/}
                  {/*<li className="exclude">*/}
                    {/*<p><i className="icofont-close-circled"></i>Live support</p>*/}
                  {/*</li>*/}
                {/*</ul>*/}
                {/*<Link to="#" className="btn btn_main">BUY NOW <i className="icofont-arrow-right"></i></Link>*/}
              {/*</div>*/}
            {/*</div>*/}
          </div>
        
        </div>

        {/*<div className="pricing_pannel yearly_plan" data-aos="fade-up" data-aos-duration="1500">*/}
          {/*<div className="row">*/}

        
            {/*<div className="col-md-4">*/}
              {/*<div className="pricing_block">*/}
                {/*<div className="icon">*/}
                  {/*<img src="media/codely-theme-files/assets/images/new/Free-Trial-01.svg" alt="image"/>*/}
                  {/*<div className="dot_block">*/}
                    {/*<span className="dot_anim"></span>*/}
                    {/*<span className="dot_anim"></span>*/}
                    {/*<span className="dot_anim"></span>*/}
                  {/*</div>*/}
                {/*</div>*/}
                {/*<div className="pkg_name">*/}
                  {/*<h3>Free Trial</h3>*/}
                  {/*<span>For the basics</span>*/}
                {/*</div>*/}
                {/*<span className="price">$0<span>/Year</span></span>*/}
                {/*<ul className="benifits">*/}
                  {/*<li className="include">*/}
                    {/*<p><i className="icofont-check-circled"></i>7 Days free trial</p>*/}
                  {/*</li>*/}
                  {/*<li className="include">*/}
                    {/*<p><i className="icofont-check-circled"></i>2 platform of your choice</p>*/}
                  {/*</li>*/}
                  {/*<li className="include">*/}
                    {/*<p><i className="icofont-check-circled"></i>10 GB Dedicated Hosting free</p>*/}
                  {/*</li>*/}
                  {/*<li className="exclude">*/}
                    {/*<p><i className="icofont-close-circled"></i>Unlimited updates</p>*/}
                  {/*</li>*/}
                  {/*<li className="exclude">*/}
                    {/*<p><i className="icofont-close-circled"></i>Live support</p>*/}
                  {/*</li>*/}
                {/*</ul>*/}
                {/*<Link to="#" className="btn btn_main">BUY NOW <i className="icofont-arrow-right"></i></Link>*/}
              {/*</div>*/}
            {/*</div>*/}

         {/**/}
            {/*<div className="col-md-4">*/}
              {/*<div className="pricing_block highlited_block">*/}
                {/*<div className="icon">*/}
                  {/*<img src="media/codely-theme-files/assets/images/new/unlimited.png" alt="image"/>*/}
                  {/*<div className="dot_block">*/}
                    {/*<span className="dot_anim"></span>*/}
                    {/*<span className="dot_anim"></span>*/}
                    {/*<span className="dot_anim"></span>*/}
                  {/*</div>*/}
                {/*</div>*/}
                {/*<div className="pkg_name">*/}
                  {/*<h3>Unlimited</h3>*/}
                  {/*<span>For the professionals</span>*/}
                {/*</div>*/}
                {/*<span className="price">$999<span>/Year</span></span>*/}
                {/*<ul className="benifits">*/}
                  {/*<li className="include">*/}
                    {/*<p><i className="icofont-check-circled"></i>7 Days free trial</p>*/}
                  {/*</li>*/}
                  {/*<li className="include">*/}
                    {/*<p><i className="icofont-check-circled"></i>2 platform of your choice</p>*/}
                  {/*</li>*/}
                  {/*<li className="include">*/}
                    {/*<p><i className="icofont-check-circled"></i>10 GB Dedicated Hosting free</p>*/}
                  {/*</li>*/}
                  {/*<li className="include">*/}
                    {/*<p><i className="icofont-check-circled"></i>Unlimited updates</p>*/}
                  {/*</li>*/}
                  {/*<li className="include">*/}
                    {/*<p><i className="icofont-check-circled"></i>Live support</p>*/}
                  {/*</li>*/}
                {/*</ul>*/}
                {/*<Link to="#" className="btn btn_main">BUY NOW <i className="icofont-arrow-right"></i></Link>*/}
              {/*</div>*/}
            {/*</div>*/}

            {/*<div className="col-md-4">*/}
              {/*<div className="pricing_block">*/}
                {/*<div className="icon">*/}
                  {/*<img src="media/codely-theme-files/assets/images/new/Premium.svg" alt="image"/>*/}
                  {/*<div className="dot_block">*/}
                    {/*<span className="dot_anim"></span>*/}
                    {/*<span className="dot_anim"></span>*/}
                    {/*<span className="dot_anim"></span>*/}
                  {/*</div>*/}
                {/*</div>*/}
                {/*<div className="pkg_name">*/}
                  {/*<h3>Premium</h3>*/}
                  {/*<span>For small team</span>*/}
                {/*</div>*/}
                {/*<span className="price">$555<span>/Year</span></span>*/}
                {/*<ul className="benifits">*/}
                  {/*<li className="include">*/}
                    {/*<p><i className="icofont-check-circled"></i>7 Days free trial</p>*/}
                  {/*</li>*/}
                  {/*<li className="include">*/}
                    {/*<p><i className="icofont-check-circled"></i>2 platform of your choice</p>*/}
                  {/*</li>*/}
                  {/*<li className="include">*/}
                    {/*<p><i className="icofont-check-circled"></i>10 GB Dedicated Hosting free</p>*/}
                  {/*</li>*/}
                  {/*<li className="include">*/}
                    {/*<p><i className="icofont-check-circled"></i>Unlimited updates</p>*/}
                  {/*</li>*/}
                  {/*<li className="exclude">*/}
                    {/*<p><i className="icofont-close-circled"></i>Live support</p>*/}
                  {/*</li>*/}
                {/*</ul>*/}
                {/*<Link to="#" className="btn btn_main">BUY NOW <i className="icofont-arrow-right"></i></Link>*/}
              {/*</div>*/}
            {/*</div>*/}

          {/*</div>*/}
        {/*</div>*/}
      </div>
      <div id="faqBlock-anchor" />
    </section>
    </>
  )
}

export default Pricing
