import React, {useEffect, useState} from "react";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import TableComponent from "../../../components/TableComponent/TableComponent";
import withConfirmHook from "../../../../hooks/withConfirm.hook";
import TableCell from "@material-ui/core/TableCell";
import {UserTableActions} from "./tableActions";
import * as commonDuck from "../../../../store/ducks/common.duck";
import * as GroupDuck from "../../../../store/ducks/group.duck";
import {getPermissions} from "../../../../selectors/common.selectors";
import {GroupType, PermissionType} from "../../../../types";
import GroupCreateEditModal from "./GroupCreateEditModal";
import {useModal} from "../../../../hooks/modal.hook";
import * as Selectors from '../../../../selectors/userSettings.selectors';
import DButton from "../../../components/Button";
import {useHistory} from "react-router-dom";
import {goToEnterTransactionPage} from "../utils";

const getHeadRow = ({ id, name, description }) => {
  return { id: name, numeric: false, disablePadding: true, label: name, isActive:true,
    renderText: (row) => {
      const prms = row.permissions.find((prm) => prm.name === name);
      if (!prms) {
        return '?';
      }
      const getIcon = () => {
        switch (prms.permission_type) {
          case 0: // no access
            return '⛔️';
          case 1: // read only
            return '📖️';
          case 2: // create edit
            return '✅';
          default: return '';
        }
      };
      return <div style={{fontSize: '19px'}}>{getIcon()}</div>
    }
  };
};

const getHeadRows = (permissions) => {
  return permissions.map(getHeadRow)
};

function UserPermissions({
                           getGroups,
                           loading,
                           getSettingsMeta,
                           addGroup,
                           updateGroup,
                           deleteGroup,
                           permissions,
                           groups,
                           confirm,
                         }) {
  const [currentRow, setCurrentRow] = useState(null);
  const [isModalShow, toggleModal] = useModal();
  const headRows = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Group Name', isActive:true  },
    ...getHeadRows(permissions),
    { id: 'actions', label: 'Action', isActive: true, render: (row, { onDelete, onEdit, }) => {
        return <TableCell align="right" key={row.id}>
          <UserTableActions
            key={row.id}
            row={row}
            onDelete={onDelete(row)}
            onEdit={onEdit(row)}
          />
        </TableCell>
      }}
  ];

  useEffect(() => {
    getSettingsMeta();
    getGroups();
  }, []);

  const onEdit = (row) => () => {
    setCurrentRow(row);
    toggleModal();
  };

  const onDelete = (row) => (
    confirm(() => {
        deleteGroup({ id: row.id })
      },
      {
        description: 'This action is permanent!'
      })
  );

  const onClose = () => {
    reset();
    toggleModal();
  };

  const reset = () => {
    setCurrentRow(null);
  };

  let history = useHistory();
  const onCancel = () => goToEnterTransactionPage(history);

  return(
    <div>
      <TableComponent
        className="table--permissions"
        headRows={headRows}
        items={groups}
        rowProps={{
          onDelete,
          onEdit,
        }}
      />
      <div className="container-button container-button--modal">
        <DButton onClickCustom={toggleModal} typeOfButton="add" >Add New  Group</DButton>
        <DButton typeOfButton="exit" onClickCustom={onCancel} >Cancel</DButton>
      </div>
      <GroupCreateEditModal
        reset={reset}
        onClose={onClose}
        addGroup={addGroup}
        showModal={isModalShow}
        initValues={currentRow}
        updateGroup={updateGroup}
        groups={groups}
        permissions={permissions}
      />
    </div>
  )
}

UserPermissions.propTypes = {
  getSettingsMeta: PropTypes.func.isRequired,
  permissions: PropTypes.arrayOf(PermissionType).isRequired,
  groups: PropTypes.arrayOf(GroupType).isRequired,
  addGroup: PropTypes.func.isRequired,
  updateGroup: PropTypes.func.isRequired,
  deleteGroup: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    groups: Selectors.getGroups(state, props),
    loading: Selectors.getLoadingGroups(state, props),
    permissions: getPermissions(state, props),
  }
};

const mapDispatchToProps = {
  getGroups: GroupDuck.actions.getGroups,
  addGroup: GroupDuck.actions.addGroup,
  updateGroup: GroupDuck.actions.updateGroup,
  deleteGroup: GroupDuck.actions.deleteGroup,
  getSettingsMeta: commonDuck.actions.getSettingsMeta,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withConfirmHook(UserPermissions))
