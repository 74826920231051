import React from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { FormControl, FormControlLabel, Input, InputAdornment, InputLabel, Switch, TextField } from "@material-ui/core";
import * as auth from "../../store/ducks/auth.duck";
import { register } from "../../crud/auth.crud";
import Terms2 from "../../media/Terms2.pdf";
import { callingCode } from "../home/utils";
import RSelect from "../components/controls/RSelect";
import RegistrationForm from "./components/RegistrationForm";

export const formatErrors = (e) => e.map((text, index) =>
  <p key={index} className="error__password">
    {text}
  </p>
);

function Registration(props) {
  // const { intl } = props;
  return (
    <div className="kt-login__body">
      <div className="kt-login__form">
        <div className="kt-login__title">
          <h3>
            <FormattedMessage id="AUTH.REGISTER.TITLE" />
          </h3>
        </div>
        <RegistrationForm />
      </div>
    </div>
  );
}

export default injectIntl(
  connect(
    null,
    auth.actions
  )(Registration)
);
