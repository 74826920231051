import api from "../../crud/product.crud";
import {call, put, takeLatest,} from 'redux-saga/effects'
import {createSlice} from "@reduxjs/toolkit";
import {baseListState} from "./utils";

const common = createSlice({
    name: 'product',
    initialState: {
        products: {...baseListState},
        imports: {...baseListState},
        reimbursements: {...baseListState},
        winners: {...baseListState},
        losers: {...baseListState},
        reconciliation: {...baseListState},
        raiseCase: {
            details: {},
            loading: false,
        },
        notifications: {
            details: [],
            loading: false,
        },
        noteImportsCOG: {},
        product: null,
        loading: true,
        fetched: false,
    },
    reducers: {
        stopLoading: (state, actions) => {
            const {type} = actions.payload;
            if (type) {
                state[type].loading = false;
            } else {
                state.loading = false;
            }
            return state;
        },
        getProducts: (state, actions) => {
            state.products.loading = true;
            return state;
        },
        getProductsSuccess: (state, actions) => {
            const {results, count} = actions.payload;
            state.products.details = results.map((item) => {
                if (item.profit === null) {
                    item.roi = null
                }
                return item
            })
            state.products.totalCount = count;
            state.products.loading = false;
            state.fetched = true;
            return state;
        },
        getProduct: (state, actions) => {
            state.loading = true;
            return state;
        },
        getProductSuccess: (state, actions) => {
            const {product} = actions.payload;
            state.product = product;
            state.loading = false;
            return state;
        },
        addProduct: (state, actions) => {
            state.loading = true;
            return state;
        },
        addProductSuccess: (state, actions) => {
            const {product, parent} = actions.payload;
            if (parent) {
                const parentProduct = state.products.details.find((p) => p.id === parent);
                product[0].marketplaces = product.slice(1).map((p) => p.marketplace);
                parentProduct.restocks.push(product)
            } else {
                state.products.details = [...state.products.details, product];
            }
            state.products.loading = false;
            return state;
        },
        deleteProduct: (state, actions) => {
            const {type} = actions.payload;
            state[type].loading = true;
            return state;
        },
        deleteProductSuccess: (state, actions) => {
            const {id, type} = actions.payload;
            state[type].details = state[type].details.filter((product) => {
                // product contains restocks (are parent of them)
                // if we update children product we have to update this product in restocks of parent
                product.restocks = product.restocks.filter((restock_group) => !restock_group.map((product) => product.id).includes(id));
                // TODO delete after adding an extra sku
                product.related = product.related.filter((product) => product.id !== id);
                return product.id !== id
            });
            state[type].loading = false;
            return state;
        },
        updateProduct: (state, actions) => {
            const {type} = actions.payload;
            state[type].loading = true;
            return state;
        },
        updateProductSuccess: (state, actions) => {
            const {type, id, product: payloadProduct} = actions.payload;
            const isProducts = type === "products";
            state[type].details = state[type].details.map((product) => {
                // product contains restocks (are parent of them)
                // if we update children product we have to update this product in related and restocks of parent

                if (isProducts && product.related) {
                    product.related = product.related.map((item) => item.id === id ? payloadProduct : item);
                }

                if (isProducts && product.restocks) {
                    product.restocks = product.restocks.map((restock_group) => restock_group.map((sub_product) => {
                        return sub_product.id === id ? payloadProduct : sub_product;
                    }));
                }

                if (product.id === id) {
                    // update api return product without related and restocks have to reuse values from /inventory request
                    return {
                        ...payloadProduct,
                        restocks: product.restocks,
                        related: product.related
                    };
                }

                return product
            });

            state[type].loading = false;
            return state;
        },

        updateProductError: (state, actions) => {
            const {type} = actions.payload;
            state[type].loading = false;
            return state;
        },

        getWinners: (state, actions) => {
            const {autoRefresh} = actions.payload;
            state.winners.loading = !autoRefresh && true;
            return state;
        },
        getWinnersSuccess: (state, actions) => {
            const {results, count} = actions.payload;
            state.winners.details = results;
            state.winners.totalCount = count;
            state.winners.loading = false;
            return state;
        },
        getLosers: (state, actions) => {
            const {autoRefresh} = actions.payload;
            state.losers.loading = !autoRefresh && true;
            return state;
        },
        getLosersSuccess: (state, actions) => {
            const {results, count} = actions.payload;
            state.losers.details = results;
            state.losers.totalCount = count;
            state.losers.loading = false;
            return state;
        },
        getNotifications: (state, actions) => {
            state.notifications.loading = true;
            return state;
        },
        getNotificationsSuccess: (state, actions) => {
            const {notifications} = actions.payload;
            state.notifications.details = notifications;
            state.notifications.loading = false;
            return state;
        },
        addNotification: (state, actions) => {
            // state.notifications.loading = true;
            return state;
        },
        addNotificationSuccess: (state, actions) => {
            // state.notifications.loading = false;
            return state;
        },
        updateNotification: (state, actions) => {
            state.notifications.loading = true;
            return state;
        },
        updateNotificationSuccess: (state, actions) => {
            state.notifications.details = state.notifications.details.map((notification) => {
                if (notification.id === actions.payload.id) {
                    return actions.payload.notification;
                }
                return notification
            });
            state.notifications.loading = false;
            return state;
        },
        deleteNotification: (state, actions) => {
            state.loading = true;
            return state;
        },
        deleteNotificationSuccess: (state, actions) => {
            const {id} = actions.payload;
            state.notifications.details = state.notifications.details.filter((notification) => (notification.id !== id));
            state.notifications.loading = false;
            return state;
        },

        getImportsProducts: (state, actions) => {
            state.imports.loading = true;
            return state;
        },
        getImportsProductsSuccess: (state, actions) => {
            const {results, count} = actions.payload;
            state.imports.details = results;
            state.imports.totalCount = count;
            state.imports.loading = false;
            state.fetched = true;
            return state;
        },
        addImportsProduct: (state, actions) => {
            state.imports.loading = true;
            return state;
        },
        addImportsProductSuccess: (state, actions) => {
            const {importProduct} = actions.payload;
            if (importProduct.parent) {
                state.imports.details.find((p) => p.id === importProduct.parent).restocks.push(importProduct)
            } else {
                state.imports.details = [...state.imports.details, importProduct];
            }
            state.imports.loading = false;
            return state;
        },
        addImportsCOG: (state, actions) => {
            return state;
        },
        addImportsCOGSuccess: (state, actions) => {
            state.noteImportsCOG = actions.payload;
            return state;
        },

        deleteBBP: (state, actions) => {
            const {type} = actions.payload;
            state[type].loading = true;
            return state;
        },

        deleteBBPSuccess: (state, actions) => {
            const {id, type} = actions.payload;
            state[type].details = state[type].details.filter((product) => product.id !== id);
            state[type].loading = false;
            return state;
        },

        deleteBBPError: (state, actions) => {
            const {type} = actions.payload;
            state[type].loading = false;
            return state;
        },

        updateProductNote: (state, actions) => {
            return state;
        },
        updateProductNoteSuccess: (state, actions) => {
            const {id, values} = actions.payload;
            // we don't know is note was updated on refund or order tab, so have to check both
            // orders and refunds for dash
            [...state.products.details, ...state.imports.details].forEach((product) => {
                if (product.id === id) {
                    product.note = values.note;
                }
            });
            return state;
        },
        skipProductWarning: (state, actions) => state,
        skipProductWarningSuccess: (state, actions) => {
            const {id} = actions.payload;
            state.products.details.forEach(product => {
                if (product.cautions.map(c => c.id).includes(id)) {
                    const deletedCautions = product.cautions.find(c => c.id === id);

                    product.cautions = product.cautions.filter(c => c.id !== id);

                    product.restocks = product.restocks
                        .filter(restock_product => restock_product.length)
                        .map(restock_group => restock_group
                            .map(restock_product => ({
                                ...restock_product,
                                cautions: restock_product.cautions.filter(c => {
                                    return c.type !== deletedCautions.type;
                                })
                            }))
                        );
                } else {
                    product.restocks = product.restocks
                        .filter(restock_product => restock_product.length)
                        .map(restock_group => restock_group
                            .map(restock_product => ({
                                ...restock_product,
                                cautions: restock_product.cautions.filter(c => c.id !== id)
                            }))
                        );
                }
            });

            return state
        },

        getReimbursements: (state, actions) => {
            state.reimbursements.loading = true;
            return state;
        },

        getReimbursementsSuccess: (state, actions) => {
            const {results, count} = actions.payload;
            state.reimbursements.details = results;
            state.reimbursements.totalCount = count;
            state.reimbursements.loading = false;
            state.fetched = true;
            return state;
        },

        updateReimbursement: (state, actions) => state,
        updateReimbursementSuccess: (state, actions) => {
            state.reimbursements.details = state.reimbursements.details
                .map(product => product.id === actions.payload.id ? actions.payload : product);
            return state;
        },

        updateProductBBPImportStrategy: (state, actions) => {
            state.imports.loading = true;
            return state;
        },
        updateProductBBPImportStrategySuccess: (state, actions) => {
            state.imports.details = state.imports.details.map(product => {
                if (product.id === actions.payload.id) product = actions.payload;
                return product
            });
            state.imports.loading = false;
            return state;
        },
        getReconciliation: (state, actions) => {
            state.reconciliation.loading = true;
            return state;
        },
        getReconciliationSuccess: (state, actions) => {
            const {results, count} = actions.payload;
            state.reconciliation.details = results;
            state.reconciliation.totalCount = count;
            state.reconciliation.loading = false;
            state.fetched = true;
            return state;
        },
        getReconciliationError: (state, actions) => {
            state.reconciliation.loading = false;
            return state;
        },

        getRaiseCase: (state, actions) => {
            state.raiseCase.loading = true;
            return state;
        },
        getRaiseCaseSuccess: (state, actions) => {
            state.raiseCase.details = actions.payload;
            state.raiseCase.loading = false;
            state.fetched = true;
            return state;
        },
        getRaiseCaseError: (state, actions) => {
            state.raiseCase.loading = false;
            return state;
        },

        updateRaiseCase: (state, actions) => {
            state.raiseCase.loading = true;
            return state;
        },
        updateRaiseCaseSuccess: (state, actions) => {
            const {id, values, blockName} = actions.payload;
            // update the data in the collapsed row
            state.reconciliation.details = state.reconciliation.details.map(item => {
                if (item.id === id) {
                    let updateCollapse = item.collapse.map(collapsedRow => {
                        if (collapsedRow.name === blockName) {
                            return {...collapsedRow, data: [...collapsedRow.data, values]}
                        }
                        return collapsedRow;
                    });
                    return {...item, collapse: updateCollapse};
                }
                return item;
            });
            state.raiseCase.loading = false;
            return state;
        },
        updateRaiseCaseError: (state, actions) => {
            state.raiseCase.loading = false;
            return state;
        },
        updateReconciliationNote: (state, actions) => {
            return state;
        },
        updateReconciliationNoteSuccess: (state, actions) => {
            const {id, values} = actions.payload;
            [...state.reconciliation.details].forEach((item) => {
                if (item.id === id) {
                    item.note = values.note;
                }
            });
            return state;
        },

        mergeRestocks: (state) => {
            state.products.loading = true;
            return state
        },
        mergeRestocksSuccess: (state, actions) => {
            state.products.details = state.products.details.map(product => {
                if (product.id === actions.payload.id) {

                    product = actions.payload;
                }
                return product
            });
            state.products.loading = false;
        }
    }
});

export const actions = common.actions;
export const reducer = common.reducer;

export function* saga() {
    yield takeLatest(actions.getProducts, function* getProductsSaga({payload}) {
        try {
            const params = payload || {};
            const {data} = yield call(api.getProducts, params);
            data
              ? yield put(actions.getProductsSuccess(data))
              : yield put(actions.stopLoading({type: "products"}));
        } catch (err) {
            yield put(actions.stopLoading({type: "products"}))
            console.log(err);
        }
    });

    yield takeLatest(actions.getProduct, function* getProductSaga() {
        try {
            const {data} = yield call(api.getProduct);
            data
                ? yield put(actions.getProductSuccess({product: data}))
                : yield put(actions.stopLoading({type: "product"}));
        } catch (err) {
            yield put(actions.stopLoading({type: "product"}))
            console.log(err);
        }
    });

    yield takeLatest(actions.addProduct, function* addProductSaga({payload: {parent, values, onDone, onError}}) {
        try {
            const {data, ...props} = yield call(api.addProduct, {...values, parent_id: parent});
            if (data) {
                yield put(actions.addProductSuccess({product: data, parent}));
                onDone();
            } else {
                yield put(actions.stopLoading({type: "products"}));
                onError(props);
            }
        } catch (err) {
            yield put(actions.stopLoading({type: "products"}));
            onError(err);
            console.error(err);
        }
    });

    yield takeLatest(actions.deleteProduct, function* deleteProductSaga({payload: {id, type, onDone, onError}}) {
        try {
            const res = yield call(api.deleteProduct, id);
            if (!res) {
                throw new Error("Not found")
            }
            yield put(actions.deleteProductSuccess({id, type}));
            if (onDone)
                onDone();
        } catch (err) {
            yield put(actions.stopLoading({type}));
            onError(err);
            console.error(err);
        }
    });

    yield takeLatest(actions.updateProduct, function* updateProductSaga({payload}) {
      const {id, values, type = "products", onDone, onError} = payload;
        try {
            const {data, ...props} = yield call(api.updateProduct, id, values);
            if (data) {
                yield put(actions.updateProductSuccess({product: data, id, type}));
                onDone();
            } else {
                yield put(actions.updateProductError({id, type}));
                onError(props);
            }
        } catch (err) {
            yield put(actions.updateProductError({id, type}));
            onError(err);
            console.error(err);
        }
    });

    yield takeLatest(actions.getWinners, function* getWinnersSaga({payload = {}}) {
        try {
            const {autoRefresh, ...params} = payload;
            const {data} = yield call(api.getWinners, params);
            data
                ? yield put(actions.getWinnersSuccess({...data, autoRefresh}))
                : yield put(actions.stopLoading({type: "winners"}));
        } catch (err) {
            yield put(actions.stopLoading({type: "winners"}));
            console.error(err);
        }
    });

    yield takeLatest(actions.getLosers, function* getLosersSaga({payload = {}}) {
        try {
            const {autoRefresh, ...params} = payload;
            const {data} = yield call(api.getLosers, params);
            data
              ? yield put(actions.getLosersSuccess({...data, autoRefresh}))
              : yield put(actions.stopLoading({type: "losers"}))
        } catch (err) {
            yield put(actions.stopLoading({type: "losers"}));
            console.error(err);
        }
    });

    yield takeLatest(actions.getNotifications, function* getNotificationsSaga() {
        try {
            const {data} = yield call(api.getNotifications);
            data
                ? yield put(actions.getNotificationsSuccess({notifications: data}))
                : yield put(actions.stopLoading({type: "notifications"}))
        } catch (err) {
            yield put(actions.stopLoading({type: "notifications"}))
            console.error(err);
        }
    });

    yield takeLatest(actions.addNotification, function* addNotificationSaga({payload: {values, onDone, onError}}) {
        try {
            const {data, ...props} = yield call(api.addNotification, values);
            if (data) {
                yield put(actions.addNotificationSuccess({notification: data}));
                onDone();
            } else {
                yield put(actions.stopLoading({type: "notifications"}));
                onError(props);
            }
        } catch (err) {
            yield put(actions.stopLoading({type: "notifications"}))
            onError(err);
            console.error(err);
        }
    });

    yield takeLatest(actions.updateNotification, function* updateNotificationSaga({payload: {id, values, onDone, onError}}) {
        try {
            const {data, ...props} = yield call(api.updateNotification, id, values);
            if (data) {
                yield put(actions.updateNotificationSuccess({notification: data, id}));
                onDone();
            } else {
                yield put(actions.stopLoading({type: "notifications"}))
                onError(props);
            }
        } catch (err) {
            yield put(actions.stopLoading({type: "notifications"}))
            onError(err);
            console.error(err);
        }
    });

    yield takeLatest(actions.deleteNotification, function* deleteNotificationSaga({payload: {id, onDone}}) {
        try {
            yield call(api.deleteNotification, id);
            yield put(actions.deleteNotificationSuccess({id}));
            if (onDone)
                onDone();
        } catch (err) {
            yield put(actions.stopLoading({type: "notifications"}));
            console.error(err);
        }
    });

    yield takeLatest(actions.getImportsProducts, function* getImportsProductsSaga({payload}) {
        try {
            const params = payload || {};
            const {data} = yield call(api.getImportsProducts, params);
            data
              ? yield put(actions.getImportsProductsSuccess(data))
                : yield put(actions.stopLoading({type: "imports"}));
        } catch (err) {
            yield put(actions.stopLoading({type: "imports"}));
            console.error(err);
        }
    });

    yield takeLatest(actions.deleteBBP, function* deleteBBPSaga({payload: {id, type, onDone, onError}}) {
        try {
            const {data} = yield call(api.deleteBBP, id);
            yield put(actions.deleteBBPSuccess({id, type}));
            onDone();
            yield put(actions.deleteBBPError({type}));
        } catch (err) {
            yield put(actions.deleteBBPError({type}));
            onError(err);
            console.log(err);
        }
    });

    yield takeLatest(actions.addImportsProduct, function* addImportsProductSaga({payload: {values, onDone, onError}}) {
        try {
            const {data, ...props} = yield call(api.addImportsProduct, values);
            if (data) {
                yield put(actions.addImportsProductSuccess({importProduct: data}));
                onDone();
            } else {
                yield put(actions.stopLoading({type: "imports"}));
                onError(props);
            }
        } catch (err) {
            yield put(actions.stopLoading({type: "imports"}));
            onError(err);
            console.error(err);
        }
    });

    yield takeLatest(actions.addImportsCOG, function* addImportsCOGSaga({payload: {onDone, values, onError}}) {
        try {
            const {data, ...props} = yield call(api.addImportsCOG, values.get('file').name, values);
            if (data) {
                yield put(actions.addImportsCOGSuccess(data));
                onDone(data);
            } else {
                onError(props);
            }
        } catch (err) {
            onError(err);
            console.log(err);
        }
    });

    yield takeLatest(actions.updateProductNote, function* updateProductNoteSaga({payload: {id, values, onDone, onError}}) {
        try {
            const {data, ...props} = yield call(api.updateProductNote, id, values);
            if (data) {
                yield put(actions.updateProductNoteSuccess({note: data, id, values}));
                onDone();
            } else {
                onError(props);
            }
        } catch (err) {
            onError(err);
            console.log(err);
        }
    });
    yield takeLatest(actions.skipProductWarning, function* skipProductWarningSaga({payload: {id}}) {
        try {
            yield call(api.skipWarning, id);
            yield put(actions.skipProductWarningSuccess({id}));
        } catch {
        }
    });
    yield takeLatest(actions.getReimbursements, function* getReimbursementsSaga({payload}) {
        try {
            const params = payload || {};
            const {data} = yield call(api.getReimbursements, params);
            data
                ? yield put(actions.getReimbursementsSuccess(data))
                : yield put(actions.stopLoading({type: "reimbursements"}));
        } catch (err) {
            yield put(actions.stopLoading({type: "reimbursements"}))
            console.error(err);
        }
    });
    yield takeLatest(actions.updateReimbursement, function* updateReimbursementSaga({payload: {id, values}}) {
        try {
            const {data} = yield call(api.updateReimbursement, id, values);
            if (data) {
                yield put(actions.updateReimbursementSuccess(data))
            }
        } catch (err) {
            console.error(err);
        }
    });
    yield takeLatest(actions.updateProductBBPImportStrategy, function* updateProductBBPImportStrategySaga({payload}) {
        try {
            const {id, product, bbp_import_strategy} = payload;
            yield call(api.updateProductBBPImportStrategy, id, bbp_import_strategy);
            const {data} = yield call(api.updateProduct, id, product);
            data
                ? yield put(actions.updateProductBBPImportStrategySuccess(data))
                : yield put(actions.stopLoading({type: "imports"}));
        } catch (err) {
            yield put(actions.stopLoading({type: "imports"}));
            console.error(err);
        }
    });

    yield takeLatest(actions.getReconciliation, function* getReconciliationSaga({payload}) {
        try {
            const params = payload || {};
            const res = yield call(api.getReconciliation, params);
            if (res?.data) {
              yield put(actions.getReconciliationSuccess(res.data));
            } else {
              yield put(actions.getReconciliationError());
            }
        } catch (err) {
            yield put(actions.getReconciliationError());
            console.error(err);
        }
    });

    yield takeLatest(actions.getRaiseCase, function* getRaiseCaseSaga({payload = {}}) {
      const {onError, ...params} = payload;
      try {
            const res = yield call(api.getRaiseCase, params);
            if (res?.data) {
              yield put(actions.getRaiseCaseSuccess(res.data));
            } else {
              yield put(actions.getRaiseCaseError());
              !!onError && onError("")
            }
        } catch (err) {
        yield put(actions.getRaiseCaseError());
        !!onError && onError(err)
      }
    });

    yield takeLatest(actions.updateRaiseCase, function* updateRaiseCaseSaga({payload = {}}) {
        const {blockName, id, values, onDone, onError} = payload;
        try {
            const {data} = yield call(api.updateRaiseCase, id, values);
            if (data) {
                yield put(actions.updateRaiseCaseSuccess({id, values, blockName}));
                onDone();
            } else {
                yield put(actions.updateRaiseCaseError());
            }
        } catch (err) {
            yield put(actions.updateRaiseCaseError());
            console.log(err);
            onError(err);
        }
    });
    yield takeLatest(actions.updateReconciliationNote, function* updateReconciliationNoteSaga({payload: {id, values, onDone, onError}}) {
        try {
            const {data, ...props} = yield call(api.updateReconciliationNote, id, values);
            if (data) {
                yield put(actions.updateReconciliationNoteSuccess({note: data, id, values}));
                onDone();
            } else {
                onError(props);
            }
        } catch (err) {
            onError(err);
            console.error(err);
        }
    });

    yield takeLatest(actions.mergeRestocks, function* mergeRestocksSaga({payload: {id1, id2, quantity_from}}) {
        try {
            const {data} = yield call(api.mergeRestocks, id1, id2, quantity_from);
            data
                ? yield put(actions.mergeRestocksSuccess(data))
                : yield put(actions.stopLoading({type: "products"}));
        } catch (err) {
            console.error(err);
        }
    });
}
