import React from "react";
import $ from "jquery";
import {replaceIndex} from "../../../home/utils";
import {FormControl, FormHelperText, TextField} from "@material-ui/core";
import NumberFormat from 'react-number-format';
import classNames from 'classnames'

const CurrencyInput = allProps => {
  const {
    name,
    setFieldValue,
    setFieldTouched,
    placeholder,
    label,
    meta,
    showLabel,
    autofocus,
    customProps = {},
    submitCount,
    ...props
  } = allProps;

  const {
    beforeField,
    onBlur,
    noteField,
    suffixValue,
    prefixValue,
    ...otherProps
  } = customProps;

  let val = props.value;
  $((`#${name}`)).mask("000,000,000,000,000.00", {reverse: !0, placeholder: "0.00"});

  const handleBlur = (e) => {
    let value = e.target.value;
    if (prefixValue || suffixValue) {
      value = replaceIndex(value)
    }
    if (onBlur) {
      onBlur(value);
    } else {
      setFieldValue(name, value);
    }
    setFieldTouched(name, true);
  };

  return (
    <FormControl
      error={!!(meta && (meta.touched || submitCount) && meta.error)}
      fullWidth
      margin="dense"
    >
      <div className="form-group__wrap-number-format">
        {beforeField && <span className="form-group__before-field">{beforeField}</span>}
        <NumberFormat
          {...props}
          prefix={prefixValue}
          suffix={suffixValue}
          allowLeadingZeros
          thousandSeparator
          {...otherProps}
          id={name}
          name={name}
          type="text"
          label={label}
          placeholder={placeholder}
          onBlur={handleBlur}
          value={!!val ? val : ""}
          customInput={TextField}
          className={classNames({"number-input--error": meta && (meta.touched || submitCount) && meta.error})}
        />
        {meta && (meta.touched || submitCount) && meta.error && (
          <FormHelperText error id="component-error-text">
            {meta.error}
          </FormHelperText>
        )}
        {noteField && <span className="form-group__note-field">{noteField}</span>}
      </div>
    </FormControl>
  )
};

export default CurrencyInput;