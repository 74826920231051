import React from "react";
import PropTypes from "prop-types";
import Select, { components } from "react-select";
import classNames from 'classnames';
import {ReactComponent as Currency} from "../../../../_metronic/layout/assets/layout-svg-icons/Euro.svg";

const RSelect = ({
   indicator,
   byId,
   name,
   className,
   value,
   onChange,
   classNamePrefix,
   label,
   placeholder,
   options,
   setFieldValue,
   onBlur,
   isPortal,
   ...props
}) => {
  // TODO test this
  // if (byId && options && !value.label) {
  //   const data = options.find(({ id }) => id === value);
  //   value = {
  //     label: data && data.label ? data.label : 'Not known',
  //     value: value,
  //   }
  // }

  if (value && !value.label && !(typeof value === "object")) {
    // if value is just id not obj {label, value}
    const data = options.find((opt) => opt.value === value);
    value = {
      label: data && data.label ? data.label : 'Not known',
      value: value,
    };
    if (data && data.label) {
      if (setFieldValue && !onChange) {
        try {
          setFieldValue(name, value);
        } catch (e) {
          console.log(e);
        }
      }
    }
  }
  // if (value && !value.label) {
  //   value = {
  //     label: value,
  //     value: value,
  //   }
  // }

  const getTestClassName = () => {
    const str = name || '';
    return 'testId_' + str.toString().replace(/\./g, '_')
  };

  let DropdownIndicator;
  if (indicator) {
    DropdownIndicator = props => {
      return (
        <components.DropdownIndicator {...props}>
          {indicator}
        </components.DropdownIndicator>
      );
    };
  }

  const portalSelectOptions = {
    menuPortalTarget: document.body,
    styles: { menuPortal: base => ({ ...base, width: "200px", textAlign: 'center' }) }
  };

  return (
    <div data-testid={name} className={props.style}>
      <Select
        {...(isPortal ? portalSelectOptions : {})}
        className={classNames('RSelect', className, getTestClassName())}
        classNamePrefix={classNamePrefix || 'Select'}
        inputId={name}
        components={{ DropdownIndicator }}
        TextFieldProps={{
          label: "Which bank account do you want to enter transactions for?",
          InputLabelProps: {
            htmlFor: "react-select-single",
            shrink: true
          },
          placeholder: label,
          name,
        }}
        {...props}
        options={options}
        value={value}
        isDisabled={props.disabled}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  )
};

RSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  classNamePrefix: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  byId: PropTypes.bool,
  indicator: PropTypes.object,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  setFieldValue: PropTypes.func,
  isPortal: PropTypes.bool,
};

export default RSelect;
