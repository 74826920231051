import {copy} from "../utils";
import toast from "../../components/toast/toast";
import moment from "moment";
import {getFlagEmoji, marketplaceIds} from "../../components/Marketplaces/Marketplaces";

export const isNumber = value => typeof value === 'number' && isFinite(value);

export const hasData = fields => fields.some(isNumber);

export const onCopy = (content, name) => () => {
    copy(content);
    toast.success(`${name} copied!`);
};

export const sumArray = array => array.filter(el => isNumber(el)).reduce((a, b) => a + b, 0);

export const getOrdersToDisplay = (orders, withQuantity = true) => (
  //infoHeadRows={['Order Number', 'Date', 'Marketplace']}
  orders.reduce((res, order = {}) => {
    const {amazon_order_id, purchase_date, marketplace, quantity } = order;
    return [...res, {
      ...(withQuantity ? {quantity} : {}),
      amazon_order_id,
      purchase_date: moment(purchase_date).format('D/M/Y'),
      marketplace,
    }]
  }, [])
);

export const isEmptyObject = obj => obj && !Object.keys(obj).length;


export const nullToZeros = arr =>  arr.map(el => (el === null || el === undefined) ? 0 : el );

export const arrayOfObjectNullToZeros = (arr) => arr.map((item) =>
  Object.fromEntries(
    Object.entries(item).map(([key, value]) => [key, !!value ? value : 0])
  )
);

export const getInitialValues = ({formFields, data}) => formFields.map(field => {
  return {[field.id]: data[field.label]}
}).reduce((r, c) => ({...r, ...c}), {});
