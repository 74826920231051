import React from 'react'
import { Button } from '@material-ui/core'
import * as PropTypes from 'prop-types'
import classNames from 'classnames'
import OverlayComponent from '../../../components/OverlayComponent'
import RBDateRangePicker from '../../../components/controls/RBDateRangePicker'
import moment from 'moment'
import {momentFormatOptions} from "../../utils";

const ChartTime = ({ onSelectedPeriod, time, onCustomPeriod, className, dateOptions }) => {
  const initStartDate = moment().subtract(1, 'months').format(momentFormatOptions.backend);
  const initEndDate = moment().format(momentFormatOptions.backend);

  const onClick = (item) => () => onSelectedPeriod(item);

  const onCustomClick = (value) => {
    onCustomPeriod(value)
  };

  return (
    <div className={className}>
    {/*<div className="col-md-9">*/}
      <div className="time__buttons">
        {dateOptions.map(item => {
          const help = dateOptions.find(({ label }) => label === item.label).tooltip || "";
          const isActive = time.label === item.label;
          if (item.label === "Custom") {
            return <OverlayComponent key={item.label} tooltip={help}>
              <div className={classNames("time__button--custom", { "time__button--active": isActive })}>
                <RBDateRangePicker
                  key={item.label}
                  customProps={{
                    singleDatePicker: false,
                    childEl:
                      <Button
                        onClick={onClick(item)}
                        className={classNames("time__button", {
                          "time__button--active": time.label === item.label
                        })}
                      >
                        {item.label}
                      </Button>,
                    onChange: onCustomClick,
                  }}
                  value={{startDate: initStartDate, endDate: initEndDate}}
                />
              </div>
            </OverlayComponent>
          } else {
            return (
              <OverlayComponent key={item.label} tooltip={help}>
                <Button
                  onClick={onClick(item)}
                  className={classNames("time__button", {
                    "time__button--active": isActive
                  })}
                >
                  {item.label}
                </Button>
              </OverlayComponent>
            )
          }
        })}
      </div>
    </div>
  )
};

ChartTime.propTypes = {
  date: PropTypes.array,
  onSelectedPeriod: PropTypes.func,
  onCustomPeriod: PropTypes.func,
  checked: PropTypes.string,
};

export default ChartTime;