import React, { useState } from "react";
import { Fab, Button, MenuItem, Select } from "@material-ui/core";
import * as PropTypes from "prop-types";
import CachedIcon from '@material-ui/icons/Cached';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import FilterListIcon from '@material-ui/icons/FilterList';
import AddIcon from '@material-ui/icons/Add';
import PopperMenu from "../PopperMenu";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PublishIcon from "@material-ui/icons/Publish";

export const DFab = ({ children, onClick, popup, name }) => {
  return (
    <Fab aria-controls={name} aria-haspopup={popup} onClick={onClick} size="small" color="primary" data-action={name}>
      {children}
    </Fab>
  )
};

DFab.propTypes = {
  onClick: PropTypes.func,
  name: PropTypes.string,
  popup: PropTypes.string,
};

export const DMenuItem = ({ children, popup, onClick, name }) => {
  return (
    <MenuItem>
      <Button
        onClick={onClick}
        aria-controls={name}
        variant="contained"
        color="primary"
        aria-haspopup={popup}
        data-action={`mobile-${name}`}
      >
        {children}
      </Button>
    </MenuItem>
  )
};

const TableToolbarActions = (props) => {
  const {
    onExport,
    onExportPDF,
    onFilter,
    onRefresh,
    addMenu,
    viewColumnMenu,
    filterMenu,
    selectMenu,
    onAttachments,
    mobile
  } = props;
  const [value, setValue] = useState('15');
   const handleChange = (event, setFunc) => {
    setValue(event.target.value);
    setFunc && setFunc(event.target.value);
  };
  const select = (setFunc) => (
    <Select
      value={value}
      onChange={(event) => handleChange(event, setFunc)}
      displayEmpty
      className="table-toolbar__select"
    >
      <MenuItem value={15}>15</MenuItem>
      <MenuItem value={25}>25</MenuItem>
      <MenuItem value={50}>50</MenuItem>
    </Select>
  );
  const selectFunc = selectMenu && selectMenu.setFunc;
  const btns = [
    { func: onRefresh, content: <CachedIcon />, name: "refresh" },
    { func: onExport, content: <ImportExportIcon />, name: "export" },
    { func: onExportPDF, content: <PictureAsPdfIcon />, name: "export-pdf" },
    { func: onAttachments, content: <PublishIcon />, name: "export-pdf" },
    { menu: viewColumnMenu, content: <ViewColumnIcon />, name: "view-column" },
    { menu: filterMenu, content: <FilterListIcon />, name: "filter-menu" },
    { select: selectMenu, content: select(selectFunc), name: "select-menu" },
    { func: onFilter, content: <FilterListIcon />, name: "filter" },
    { menu: addMenu, content: <AddIcon />, name: "add-menu" },
  ];

  return btns.map(({func, menu, content, name, select}) => {
    const TypeBtn = () => mobile
      ? <DMenuItem onClick={func} name={name}>{content}</DMenuItem>
      : <DFab onClick={func} name={name}>{content}</DFab>;
    if (func || select) {
      return <TypeBtn key={name}/>;
    }
    if (menu) {
      return <PopperMenu
        key={name}
        contentBtn={<TypeBtn>{content}</TypeBtn>}
        popperProps={menu}
      />
    }
  })
};

TableToolbarActions.propTypes = {
  onFilter: PropTypes.func,
  onExport: PropTypes.func,
  onRefresh: PropTypes.func,
  addMenu: PropTypes.object,
  viewColumnMenu: PropTypes.object,
  filterMenu: PropTypes.object,
  mobile: PropTypes.bool,
};

export default TableToolbarActions;