import React from "react";
import Form from "react-bootstrap/Form";
import moment from 'moment'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormComponent from "../../../components/controls/FormComponent";

export const formFieldsRecur = (type) => [
  ...type
    ? []
    : [{ id: 'type', label: 'Type', type: 'RSelect', disabled: true, customProps: { options: typeOptions }}],
  { id: 'start_date', label: 'Start date', type: 'date' },
  {
    id: 'repeat',
    label: 'Repeat every...',
    type: 'number',
    showLabel: true,
    render: ({ setFieldValue, showLabel, customProps, ...props }) => {
      const onChange = (event) => {
        if (event.target.value < 1) {
          setFieldValue("repeat", 1);
        } else {
          setFieldValue("repeat", event.target.value);
        }
        setFieldValue("end_date", null);
        setFieldValue("no_of_occurrences", "");
      };
      return <>
        <Form.Group key="repeat" controlId="repeat">
          <Form.Label className="repeat">Repeat every...</Form.Label>
          <FormComponent customProps={{ onChange, type_number: "integer" }} {...props} />
        </Form.Group>
      </>
    }
  },
  {
    id: 'repeat_period',
    label: 'Repeat period',
    type: 'RSelect',
    customProps: () => ({ setFieldValue }) => {
      const onChange = (value) => {
        setFieldValue("repeat_period", value);
        setFieldValue("end_date", null);
        setFieldValue("no_of_occurrences", "");
      };
      return { onChange, options: periodOptions }
    }
  },
  {
    id: 'set_end_date',
    label: 'Do you want to set an end date?',
    type: 'checkbox',
    showLabel: true,
    render: ({ setFieldValue, setFieldTouched, label, valueMultiple, customProps, ...props }) => {
      const handleChange = (e, value) => {
        setFieldValue("set_end_date", value);
        if (!value) {
          setFieldValue("end_date", null);
          setFieldValue("no_of_occurrences", "");        }
      };
      const handleBlur = () => setFieldTouched("set_end_date", true);
      return <div>
        <FormControlLabel
          key="set_end_date"
          name="set_end_date"
          control={<Checkbox color="primary" />}
          label="Do you want to set an end date?"
          labelPlacement="start"
          onChange={handleChange}
          onBlur={handleBlur}
          checked={!!props.value}
        />
      </div>
    }
  },
  {
    id: 'end_date',
    label: 'End Date',
    type: 'date',
    showLabel: true,
    render: ({ setFieldValue, showLabel, customProps, ...props }) => {
      const values = props.values;
      const start = moment(values.start_date);
      const onChange = (value) => {
        setFieldValue("end_date", value);
        const end = moment(value);
        const repeatPeriod = values.repeat_period.value;
        const repeat = (typeof values.repeat === "string" ) ? parseInt(values.repeat) : values.repeat;
        const period = end.diff(start, repeatPeriod);
        const noOccurrences = end < start ? 1 : Math.floor(period / repeat);
        setFieldValue("no_of_occurrences", `${noOccurrences}`);
      };
      const visible = values && values.set_end_date;
      return <>
        {visible && (
          <Form.Group key="end_date" controlId="end_date">
            <Form.Label className="no_of_occurrences">End Date</Form.Label>
            <FormComponent customProps={{ onChange }} {...props} />
          </Form.Group>
        )}
      </>
    }
  },
  {
    id: 'no_of_occurrences',
    label: 'No of occurrences',
    type: 'number',
    showLabel: true,
    render: ({ setFieldValue, showLabel, customProps, ...props }) => {
      const values = props.values;
      const onChange = (event) => {
        const start = moment(values.start_date);
        const value = event.target.value;
        if (value < 1) {
          setFieldValue("no_of_occurrences", 1);
        } else {
          setFieldValue("no_of_occurrences", value);
        }
        const repeatPeriod = values.repeat_period.value;
        const repeat = (typeof values.repeat === "string" ) ? parseInt(values.repeat) : values.repeat;

        const _val = value > 1 ? value : 1;
        const endDate = start.add((repeat*_val) - 1, repeatPeriod);
        setFieldValue("end_date", endDate);
      };
      const visible = values && values.set_end_date;
      return <>
        {visible && (
          <Form.Group key="no_of_occurrences" controlId="no_of_occurrences">
            <Form.Label className="no_of_occurrences">No of occurrences</Form.Label>
            <FormComponent customProps={{ onChange, type_number: "integer", empty: true }} {...props} />
          </Form.Group>
        )}
      </>
    }
  },
  { id: 'status', label: 'Status', type: 'RSelect', customProps: { options: statusOptions } },
];

export const fieldsEmailTemplate = [
  { id: 'send_invoice_automatically', label: 'Send invoice automatically', type: 'checkbox' },
  { id: 'customer_email', label: 'Customer Email:', type: 'email' },
  { id: 'optional_cc', label: 'Optional CC:', type: 'text' },
  { id: 'optional_bcc', label: 'Optional BCC:', type: 'text' },
  { id: 'reply_to_address:', label: 'Reply To Address:', type: 'email' },
  { id: 'subject', label: 'Subject: ', type: 'text' },
  { id: 'message:', label: 'Do you want to set an end date?', type: 'checkbox', showLabel: true },
];

export const typeOptions = [
  { label: "Adjustment", "value": "Adjustment" },
  { label: "Customer Invoice", "value": "CI" },
  { label: "Supplier Invoice", "value": "SI" },
];

export const statusOptions = [
  { label: "Active", "value": "Active" },
  { label: "Inactive", "value": "Inactive" },
];

export const periodOptions = [
  { label: "Days", "value": "days" },
  { label: "Weeks", "value": "weeks" },
  { label: "Months", "value": "months" },
  { label: "Years", "value": "years" },
];