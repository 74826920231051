import React from 'react';
import * as PropTypes from "prop-types";
import useOrders from "../../../../hooks/orders.hook";
import NoteTabTable from "../components/NoteTabTable";

const NoteOrdersRefunds = ({ row, target }) => {
  const { updateOrderNote } = useOrders({ fetch: false });
  return <NoteTabTable row={row} update={updateOrderNote} target={target} />;
};

NoteOrdersRefunds.propTypes = {
  row: PropTypes.object,
  target: PropTypes.string,
};

export default NoteOrdersRefunds;