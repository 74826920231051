import React from "react";
import Avatar from "@material-ui/core/Avatar";
import TableCell from "@material-ui/core/TableCell";
import NameCell from "../Dashboard/components/NameCell";
import classNames from "clsx";
import Flag from "../Dashboard/components/Flag";
import { FormControlLabel, Link, Switch, TextField } from "@material-ui/core";
import { numberWithCommas } from "../utils";
import { updateMarketplace, updateNoteMarketplace } from "./utils";
import NoteTabTable from "../Dashboard/components/NoteTabTable";
import NumberFormat from 'react-number-format';
import RSelect from "../../components/controls/RSelect";
import { toAbsoluteUrl } from "../../../../_metronic";
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import { marketplaceNames } from "../../components/Marketplaces/Marketplaces";

const marketplacesProduct = (related) => related.map(({ MarketplaceID }) => MarketplaceID);

const bsrValue = ({ BSR }) => (
    <span className="table__cell--detail__content vat bsr">
      BSR#: {BSR}
    </span>
);

export const headRows = [
  {
    id: "image_url",
    label: "",
    render: (row) => {
      const { ImageURL, AmazonURL, id } = row;
      let image = ImageURL || toAbsoluteUrl("/media/logos/new-logo-icon.svg");
      const protocol = ImageURL ? ImageURL.split(':')[0] : null
      if (protocol === 'http') {
        image = toAbsoluteUrl("/media/logos/new-logo-icon.svg");
      }
      return (
        <TableCell align="left" key={`${id}--photo`} className={classNames("table__cell--photo", {
          "table__cell--child": row.relatedProduct, "table__cell--parent": !row.relatedProduct,
        })}>
          {!row.relatedProduct && (
            <Link target="_blank" href={AmazonURL}>
              <Avatar alt="img-product" variant="square" src={image} />
            </Link>
          )}
        </TableCell>
      )
    },
  },
  {
    id: "item_name",
    label: "Title", align: "center",
    render: (row, { onShowRelated }) => {
      const { AmazonURL, ASIN, SKU, related, Title, id, relatedProduct, BSR } = row;
      return (
        <NameCell
          url={AmazonURL}
          sku={SKU}
          row={row}
          key={`${id}--name`}
          asin={ASIN}
          title={Title}
          marketplaces={related && marketplacesProduct(related)}
          tableName="ppp"
          shouldRenderName={!relatedProduct}
          onShowRelated={onShowRelated(row)}
          extra={{ bsr: bsrValue({ BSR }) }}
        />
      )
    },
  },
  {
    id: "marketplace",
    label: "",
    align: "center",
    disabled: true,
    render: (row) => {
      const { MarketplaceID, RowID, relatedProduct } = row;
      const tooltip = MarketplaceID ? marketplaceNames[row.MarketplaceID] : "";
      return (
        <TableCell
          align="left"
          key={`${RowID}--marketplace`}
          className={classNames("table__cell--marketplace", { "table__cell--child": !relatedProduct })}
        >
          <div className="wrap--marketplace">
            <Flag marketplace={MarketplaceID} tooltip={tooltip} />
          </div>
        </TableCell>
      )
    },
  },
  {
    id: "StockLevel",
    label: "Stock",
    align: "center",
    renderText: ({ StockLevel }) => numberWithCommas(StockLevel, "integer")
  },
  {
    id: "BuyBox",
    label: "Buy Box",
    align: "center",
    renderText: ({ BuyBox, currency }) => <p className="different-currency-color table__cell--min-width-80" >
      <span>{currency}</span>
      {numberWithCommas(BuyBox)}
    </p>
  },
  {
    id: "LowFBAPrice",
    label: "Low FBA Price",
    align: "center",
    renderText: ({ LowFBAPrice, currency }) => <p className="different-currency-color table__cell--min-width-80" >
      <span>{currency}</span>
      {numberWithCommas(LowFBAPrice)}
    </p>
  },
  {
    id: "ToBuyBox",
    label: "To Buy Box",
    //SellerPrice && BuyBox ? (SellerPrice - BuyBox) : null
    align: "center",
    disabled: true,
    renderText: ({ SellerPrice, BuyBox, currency }) =>
      SellerPrice && BuyBox
        ? <p className="different-currency-color table__cell--min-width-50">
          <span>{currency}</span>
          {numberWithCommas(SellerPrice - BuyBox)}
        </p>
        : "-"
  },
  {
    id: "SellerPrice",
    label: "Current Price",
    align: "center",
    renderText: ({ SellerPrice, currency }) => <p className="different-currency-color">
      <span>{currency}</span>
      {numberWithCommas(SellerPrice)}
    </p>
  },
  {
    id: `BreakEvenPrice`,
    label: "Break Even Price",
    align: "center",
    render: (row, { fieldValues, onChange, handleBlur }) => {
      const { id, currency } = row;
      let value = fieldValues[`BreakEvenPrice--${id}`];
      return (
        <TableCell
          align="center"
          key={`${row.id}--BreakEvenPrice`}
          className={classNames("table__cell--BreakEvenPrice form-group table__cell--min-width-100")}>
          <div className="form-group__wrap-number-format">
            <span className="form-group__before-field different-currency-color">{currency}</span>
            <NumberFormat
              thousandSeparator=","
              decimalSeparator="."
              fixedDecimalScale={true}
              name="BreakEvenPrice"
              type="text"
              label=""
              placeholder=""
              onChange={onChange(row, "BreakEvenPrice")}
              onBlur={handleBlur(row, "BreakEvenPrice")}
              value={value || "-"}
              customInput={TextField}
            />
          </div>
        </TableCell>
      )
    }
  },
  {
    id: `MinPrice`,
    label: "Min",
    align: "center",
    render: (row, { fieldValues, onChange, handleBlur, idCurrentRow }) => {
      const { id, currency } = row;
      let value = fieldValues[`MinPrice--${id}`];
      const isShowAfterField = idCurrentRow.id === id && idCurrentRow.param === "MinPrice";
      return (
        <TableCell
          align="center"
          key={`${row.id}--MinPrice`}
          className={classNames("table__cell--MinPrice form-group table__cell--min-width-100")}>
          <div className="form-group__wrap-number-format">
            <span className="form-group__before-field different-currency-color">{currency}</span>
            <NumberFormat
              thousandSeparator=","
              decimalSeparator="."
              fixedDecimalScale={true}
              name="MinPrice"
              type="text"
              label=""
              placeholder=""
              onChange={onChange(row, "MinPrice")}
              onBlur={handleBlur(row, "MinPrice")}
              value={value || "-"}
              customInput={TextField}
            />
            {isShowAfterField && (
              <span className="form-group__after-field">
                <CheckOutlinedIcon htmlColor='var(--d-green)' />
              </span>
            )}
          </div>
        </TableCell>
      )
    }
  },
  {
    id: `MaxPrice`,
    label: "Max",
    align: "center",
    render: (row, { fieldValues, onChange, handleBlur, idCurrentRow }) => {
      const { id, currency } = row;
      let value = fieldValues[`MaxPrice--${id}`];
      const isShowAfterField = idCurrentRow.id === id && idCurrentRow.param === "MaxPrice";
      return (
        <TableCell
          align="center"
          key={`${row.id}--MaxPrice`}
          className={classNames("table__cell--MaxPrice form-group table__cell--min-width-100")}>
          <div className="form-group__wrap-number-format">
            <span className="form-group__before-field different-currency-color">{currency}</span>
            <NumberFormat
              thousandSeparator=","
              decimalSeparator="."
              fixedDecimalScale={true}
              name="MaxPrice"
              type="text"
              label=""
              placeholder=""
              onChange={onChange(row, "MaxPrice")}
              onBlur={handleBlur(row, "MaxPrice")}
              value={value || "-"}
              customInput={TextField}
            />
            {isShowAfterField && (
              <span className="form-group__after-field">
                <CheckOutlinedIcon htmlColor='var(--d-green)' />
              </span>
            )}
          </div>
        </TableCell>
      )
    }
  },
  {
    id: `StrategyID`,
    label: "Strategy",
    align: "center",
    render: (row, { fieldValues, strategiesOptions, setFieldValue, credentials }) => {
      const { MarketplaceID, TurnedOn, MinPrice, MaxPrice, IsFavourite, id } = row;
      let value = strategiesOptions.find(({ value }) => value === fieldValues[`StrategyID--${id}`]);

      const onChange = (value) => {
        setFieldValue({...fieldValues, [`StrategyID--${id}`]: value.value});
      };

      const handleBlur = () => {
        const value = {
          MarketplaceID,
          IsFavourite,
          TurnedOn,
          StrategyID: fieldValues[`StrategyID--${id}`],
          MinPrice: MinPrice || null,
          MaxPrice: MaxPrice || null
        };
        updateMarketplace({ value, ...credentials, row })
      };

      return (
        <TableCell
          align="center"
          key={`${row.id}--StrategyID`}
          className={classNames("table__cell--MaxPrice form-group")}>
          <div className="form-group__wrap-number-format">
            <RSelect
              value={value}
              name={`${row.id}--StrategyID`}
              label=""
              options={strategiesOptions}
              onChange={onChange}
              onBlur={handleBlur}
              isPortal
            />
          </div>
        </TableCell>
      )
    }
  },
  {
    id: "TurnedOn",
    label: "On",
    align: "center",
    disabled: true,
    render: (row, { credentials, onRefresh }) => {
      const { MarketplaceID, TurnedOn, MinPrice, MaxPrice, IsFavourite, StrategyID, id } = row;
      const onChange = (event) => {
        const value = {
          MarketplaceID,
          IsFavourite,
          StrategyID,
          TurnedOn: event.target.checked,
          MinPrice: MinPrice || null,
          MaxPrice: MaxPrice || null
        };
        const updateTable = () => onRefresh(id, value);
        updateMarketplace({ value, ...credentials, row, updateTable })
      };

      return <TableCell
        align="center"
        key={`${row.id}--TurnedOn`}
        className={classNames("table__cell--TurnedOn form-group")}>
        <FormControlLabel
          name={`${row.id}--TurnedOn`}
          label=""
          control={
            <Switch
              className="d-switch d-switch--table"
              checked={!!TurnedOn}
              onChange={onChange}
              inputProps={{'aria-label': `TurnedOn--checkbox`}}
            />
          }
          labelPlacement="bottom"
        />
      </TableCell>
    },
  },
  {
    id: "IsFavourite",
    label: "Favorite",
    align: "center",
    disabled: true,
    render: (row, { credentials, onRefresh }) => {
      const { MarketplaceID, TurnedOn, MinPrice, MaxPrice, IsFavourite, StrategyID, id } = row;
      const onChange = (event) => {
        const value = {
          MarketplaceID,
          TurnedOn,
          StrategyID,
          MinPrice: MinPrice || null,
          MaxPrice: MaxPrice || null,
          IsFavourite: event.target.checked
        };
        const updateTable = () => onRefresh(id, value);
        updateMarketplace({ value, ...credentials, row, updateTable });
      };
      return <TableCell
        align="center"
        key={`${row.RowID}--favorite`}
        className={classNames("table__cell--favorite form-group")}>
        <FormControlLabel
          name={`${row.RowID}--favorite`}
          label=""
          control={
            <Switch
              className="d-switch d-switch--table"
              checked={!!IsFavourite}
              onChange={onChange}
              inputProps={{'aria-label': `favorite--checkbox`}}
            />
          }
          labelPlacement="bottom"
        />
      </TableCell>
    },
  },
  {
    id: "note",
    label: "Note",
    align: "center",
    disabled: true,
    render: (row, { credentials, onRefresh }) => {
      return <TableCell
        align="center"
        key={`${row.RowID}--note`}
        className={classNames("table__cell--note", { "table__cell--child": !row.relatedProduct })}>
        {!row.relatedProduct && (
          <NoteTabTable
            row={{...row, note: row.NoteValue}}
            update={updateNoteMarketplace({ row, credentials, onRefresh })}
            target="ppp"
          />
        )}
      </TableCell>
    },
  },
];
