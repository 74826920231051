import { createSelector } from "reselect";

export const getOfficers = createSelector(
  state => state.company.officers,
  (officers) => officers.officers,
);

export const getLoading = createSelector(
  state => state.company.officers,
  (officers) => officers.loading,
);