import * as React from "react";
import useUsers from "../../hooks/users.hook";

const Hello = () => {
  const {user} = useUsers({fetch: false});
  return (
    <div className="kt-header__topbar-item kt-header__topbar-item--user d-hello">

      <span className="kt-header__topbar-welcome" role="img" aria-label="Waving Hand">👋 Hi,</span>

      <span className="kt-header__topbar-username">
        {user.first_name}
      </span>
    </div>
  )
};

export default Hello;
