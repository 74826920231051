import { Link } from "@material-ui/core";
import React from "react";
import VatRateTrigger from "./VatRateTrigger";
import classNames from "clsx";
import UserVATRate from "./UserVATRate";
import {ReactComponent as AmazonSCIcon} from "../../../../media/icons/amazon-sc-icon-bg.svg";
import {ReactComponent as AmazonIcon} from "../../../../media/icons/amazon-icon.svg";

const AddInfo = props => {
  const {
    listing_edit_url,
    url,
    vat,
    extra = {},
    row,
    tableName,
    isMainReplenish,
    vat_validated,
    replenish,
  } = props;

  const { isExtraLine } = extra;
  return (
    <div className="cell__add-info">
      {/*{extra.bsr}*/}

      {isExtraLine && (!!extra.vat || extra.vat === 0) && (
        <UserVATRate
          parent={(props) => (
            <VatRateTrigger {...props} vat={extra.vat} disabled />
          )}
          row={row}
          tableName={tableName}
          disabled
        />
      )}

      {(!!vat || vat === 0) && (
        <UserVATRate
          parent={(props) => <VatRateTrigger row={row} vat={vat} vat_validated={vat_validated} {...props} />}
          row={row}
          tableName={tableName}
        />
      )}

      {
        extra.url && (
          <Link href={extra.url} target="_blank" className={classNames({"copy-hover": !extra.refundOrderInfo})}>
            <AmazonSCIcon className="amazonSCIcon" />
          </Link>
        )
      }

      <div>
        {
          listing_edit_url && (
            <Link
              href={listing_edit_url}
              target="_blank"
              className={classNames("copy-hover copy-hover--title", {"restocked": replenish})}
            >
              <AmazonSCIcon className="amazonSCIcon"/>
            </Link>
          )
        }
        {url && (
          <Link
            href={url}
            target="_blank"
            className={classNames("copy-hover copy-hover--title", {"restocked": replenish})}
          >
            <AmazonIcon className="amazonIcon"/>
          </Link>
        )}
      </div>
    </div>
  )
};

export default AddInfo;