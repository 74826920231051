import React, { useState } from 'react';
import Form from "react-bootstrap/Form";
import FormField from "../../components/controls/FormField";
import * as PropTypes from "prop-types";
import InfoIcon from '@material-ui/icons/Info';
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { getCurrentCompanyId } from "../../../crud/utils";
import { requiredValidator } from "../../components/controls/validators";
import { nextCode } from "./nextCode";
import CreateEditModal from "../../components/CreateEditModal";
import { validatorValue } from "./validatorValue";
import classNames from "clsx";
import useCategories from "../../../hooks/categories.hook";

const CATEGORY_TYPE = [
  ["Expense", "Expense [500 - 998]"],
  ["Asset", "Asset [0 - 199]"],
  ["Revenue", "Revenue [400 - 499]"],
  ["Liability", "Liability [200 - 299]"],
  ["Equity", "Equity [300 - 399]"],
];

export default function CategoryEditAddModal(props) {
  const {
    taxes,
    initValues,
    showModal,
    onClose,
    className,
    onAddNewCategory,
    onSuccess,
    step,
  } = props;
  const { categories: { details: categories }, addCategory, updateCategory } = useCategories({ fetch: false });

  // array of categories nominal code
  const allNominalCodes = categories && categories.map(category => +category.nominal_code);
  const initData = initValues || {};
  const [processErrors, setProcessErrors] = useState(null);
  const [valueErrors, setValueErrors] = useState([]);

  //TODO change status
  initData.status = !initData.status;
  let history = useHistory();

  // switcher for content in the last form field
  const lastField = initData.id
    ? {
      id: 'status',
      label: 'Inactive ?',
      type: 'checkbox',
      showLabel: true,
    }
    : {
      id: 'create_bank',
      label: "",
      render: () => {
        const onRedirect = () => history.push("/books/enter-transaction");
        return (
          <div className="form-group__text-block">
            <InfoIcon/>
            You can create new bank accounts from
            <Typography color="primary" variant="subtitle1" onClick={onRedirect} className="form-group__link">
              Banking
            </Typography>
          </div>
        )
      }
    };

  const mainFields = [
    {
      id: 'name',
      label: 'Name',
      type: 'string',
      validator: requiredValidator("Name is required"),
    },
    {
      id: 'category_type',
      label: 'Category type',
      type: 'RSelect',
      validator: requiredValidator("Category type is required"),
      // customProps: {
      //   options: CATEGORY_TYPE.map(([value, label]) => ({label: label, value: value})),
      // }
      customProps: ({ index, setFieldValue }) => {
        const onChange = ({ category, ...value }) => {
          setFieldValue('category_type', value);
          // TODO get next code
          nextCode(allNominalCodes.sort((a, b) => a - b), value.value, setFieldValue);
        };
        return {
          onChange,
          options: CATEGORY_TYPE.map(([value, label]) => ({ label: label, value: value })),
        }
      }
    },
    {
      id: 'code',
      label: 'Category code',
      type: 'string',
      validator: requiredValidator("Category code is required"),
    },
    {
      id: 'default_tax_code',
      label: 'Default tax code',
      type: 'RSelect',
      customProps: {
        byId: true,
        options: taxes.map((tax) => {
          return { label: tax.name + ' - ' + tax.tax_rate + "%", value: tax.id }
        }),
      },
    },
    lastField,
    ...step && initData.category_type === "Expense"
      ? [{ id: 'direct_cost', label: 'Is Direct Cost ?', type: 'checkbox', showLabel: true }]
      : [],
  ];

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    const data = {...values};
    const onDone = () => {
      setSubmitting(false);
      resetForm();
      onSuccess();
    };
    const onError = (error) => {
      // set errors in state
      setProcessErrors(Object.values(error.response.data));
      setSubmitting(false);
    };

    const type = data.category_type.value ? data.category_type.value : data.category_type;
    const code = +data.code;

    // invalid category code
    let errorValue = type && code && validatorValue(type, code);

    // checking for the existence of code in the database
    const doubleEnterCode = code && allNominalCodes.find(item => item === code);
    const isShowErrorDoubleCode = !!initData.nominal_code && (doubleEnterCode === initData.nominal_code)
      ? undefined : doubleEnterCode;
    const double = `Category code '${code}' is already in use. Please choose an alternative code`;

    if (errorValue || isShowErrorDoubleCode) {
      setValueErrors([]);
      // set invalid category code
      errorValue && setValueErrors(old => [...old, errorValue]);

      // set error double enter category code
      isShowErrorDoubleCode && setValueErrors(old => [...old, double]);
      setSubmitting(false);
      return;
    }
    console.log('submitting');

    let taxCodeValue = null;
    if (data.default_tax_code) {
      taxCodeValue = data.default_tax_code.value
    } else if (taxes.length !== 0) {
      taxCodeValue = taxes[taxes.length - 1].id
    }

    data.category_type = data.category_type.value ? data.category_type.value : data.category_type;
    data.default_tax_code = taxCodeValue;
    data.nominal_code = +data.code;
    data.code = +data.code;
    data.company = +getCurrentCompanyId();
    if (initData.id) {
      updateCategory({
        id: initData.id,
        values: data,
        onDone,
        onError,
      });
    } else {
      onAddNewCategory && onAddNewCategory(data);
      addCategory({
        values: data,
        onDone,
        onError,
      })
    }

  };

  return (
    <CreateEditModal
      modalTitle={initData.id ? "Edit Category" : "Create Category"}
      onSubmit={onSubmit}
      onClose={onClose}
      show={showModal}
      className={classNames("modal--small ", className)}
      initValues={initValues}
      nameNotification="Category"
      processErrors={processErrors}
      setProcessErrors={setProcessErrors}
      valueErrors={valueErrors}
      setValueErrors={setValueErrors}>
      {(form) => (
        mainFields.map((item) => (
          <Form.Group key={item.id} controlId={item.id} className={`form-group--${item.id}`}>
            {!item.showLabel && <Form.Label>{item.label}</Form.Label>}
            <FormField
              render={item.render}
              name={item.id}
              label={item.label}
              showLabel={item.showLabel}
              type={item.type}
              validate={item.validator}
              customProps={item.customProps}
              {...form}
            />
          </Form.Group>
        ))
      )}
    </CreateEditModal>
  );
}

CategoryEditAddModal.propTypes = {
  onAddNewCategory: PropTypes.func,
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  initValues: PropTypes.object,
  taxes: PropTypes.array.isRequired,
  className: PropTypes.string,
  onSuccess: PropTypes.func,
  step: PropTypes.number,
};