import React from "react";
import {FormControlLabel, RadioGroup} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";

const RadioField = allProps => {
  const {
    name,
    setFieldValue,
    setFieldTouched,
    placeholder,
    label,
    showLabel,
    customProps = {},
    submitCount,
    autofocus,
    ...props
  } = allProps;
  const {onChange, type, onBlur, radio, ...otherProps} = customProps;
  const handleChange = (event) => {
    if (onChange) {
      onChange(event);
    } else {
      const value = type ? type(event.target.value) : event.target.value;
      if (onBlur) {
        onBlur(value);
      } else {
        setFieldValue(name, value);
      }
    }
  };
  const handleBlur = () => {
    setFieldTouched(name, true);
  };

  return <RadioGroup
    {...props}
    key={name}
    aria-label={name}
    name={name}
    value={props.value || radio[0].value}
    onChange={handleChange}
    onBlur={handleBlur}
    row
    {...otherProps}
  >
    {radio.map(({value, labelPlacement = "end", label, color = "secondary", disabled = false, size = "medium"}) => {
      return <FormControlLabel
        key={value}
        value={value}
        control={<Radio color={color} disabled={disabled} size={size}/>}
        label={label}
        labelPlacement={labelPlacement}
      />
    })}
  </RadioGroup>
};

export default RadioField;
