import React, { useState } from 'react';
import Modal from "react-bootstrap/Modal";
import * as PropTypes from "prop-types";
import DButton from "../../../components/Button";
import { Form as FormikForm, Formik } from "formik";
import { processError } from "../../Transaction/EnterTransactionsBanking/EditAddBankingModal";
import { formContent } from "../../../components/controls/formContent";
import { onValidation } from "../../../components/CreateEditModal";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import { formFieldsRecur } from "./fieldsModal";
import Tab from "@material-ui/core/Tab";
import Notification from "../../../components/Notification";
import AppBar from "@material-ui/core/AppBar";
import moment from "moment";

const TabContainer = ({ children }) => <Typography component="div" style={{ padding: 8 }}>{children}</Typography>;

export default function CreateEditRecurTransModal({ show, onClose, initValues, type, reset }) {
  const [value, setValue] = useState("recurring");
  const [errors, setErrors] = useState([]);
  const [isShowErrors, setIsShowErrors] = useState(false);

  const resetValue = () => setErrors([]);
  const onCloseError = () => setIsShowErrors(false);
  const handleChange = (event, newValue) => setValue(newValue);
  const onCloseModal = () => {
    onClose();
    reset();
  };

  let initData = {
    type: "Adjustment",
    start_date: undefined,
    repeat: 1,
    repeat_period: { label: "Months", "value": "months" },
    set_end_date: undefined,
    end_date: undefined,
    no_of_occurrences: undefined,
    status: "Active",
  };
  if (initValues) {
    const { start_date, repeat, repeat_period: { value }, no_of_occurrences } = initValues;

    initData = {
      ...initValues,
      end_date:  no_of_occurrences ? moment(start_date).add((repeat * no_of_occurrences) - 1, value) : null,
      start_date: start_date,
    };
  }

  const initType = initData.type && (initData.type.label ? initData.type.label : initData.type.label);
  const isRecurInvoice = initType === "Customer Invoice" || initType === "Supplier Invoice" || type;

  let recurringLabel = "Recurring Adjustment";
  let title = "New Recurring Adjustment";
  let nameSaveBtn = "Save Recurring Adjustment";

  if (initType === "Customer Invoice" || initType === "Supplier Invoice") {
    recurringLabel = "Recurring Invoice";
    title = "Edit Recurring Invoice";
    nameSaveBtn = "Save Recurring Invoice"
  }
  if (type) {
    recurringLabel = "Recurring Invoice";
    title = "New Recurring Invoice";
    nameSaveBtn = "Save Recurring Invoice"
  }
  if (initType === "Adjustment") {
    title = "Edit Recurring Adjustment";
  }

  return  (
    <Formik
      enableReinitialize
      initialValues={initData}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        const data = {...values};
        const onDone = () => {
          setSubmitting(false);
          resetForm();
          onClose();
        };
        const onError = (error) => {
          processError(error);
          setSubmitting(false);
        };

        // if (initData.id) {
        //   updateData()({
        //     id: initValues.id,
        //     values: data,
        //     onDone,
        //     onError,
        //   });
        // } else {
        //   addData()({
        //     values: data,
        //     onDone,
        //     onError,
        //   });
        // }
      }}
    >
      {(form) => {
        return <Modal show={show} onHide={onCloseModal} className="modal--small">
          <FormikForm>
            <Modal.Header closeButton>
              <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {isShowErrors
              && <Notification errors={errors} onClose={onCloseError}
                // name={valueTab ? "Customer" : "Supplier"}
              />}
              <AppBar position="static" color="default">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                >
                  <Tab value="recurring" label={recurringLabel} />
                  {/*<Tab value="email_template" label="Email Template"/>*/}
                </Tabs>
              </AppBar>
              {value === "recurring" && (
                <TabContainer>
                  {formContent({ interfaces: formFieldsRecur(isRecurInvoice), form })}
                </TabContainer>
              )}
              {/*{value === "email_template"*/}
              {/*&&  <TabContainer>{formContent({interfaces: fieldsEmailTemplate, form})}</TabContainer>}*/}
            </Modal.Body>
            <Modal.Footer>
              <div className="container-button container-button--modal">
                <DButton
                  typeOfButton="doneAllSecondary"
                  type="submit"
                  onClickCustom={onValidation(form.validateForm, setErrors, setIsShowErrors, resetValue)}
                  disabled={form.isSubmitting}
                >
                  {nameSaveBtn}
                </DButton>
                <DButton typeOfButton="close" onClickCustom={onCloseModal}>Close</DButton>
              </div>
            </Modal.Footer>
          </FormikForm>
        </Modal>
      }}
    </Formik>
  );
}

CreateEditRecurTransModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  type: PropTypes.string,
};
