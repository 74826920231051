import React from "react";

export const FfTypes = {
  "AFN": 'fbm',
  "MFN": 'mf',
  "MFN_PRIME": "sfp",
};

export const currency_cogs = [
  'bundling',
  "prep_centre",
  "packaging",
  "postage",
  "vat_free_postage",
  "handling",
  "other",
  "extra_fee",
];

export const percent_cogs = ['extra_fee_perc'];

export const all_cogs = [...currency_cogs, ...percent_cogs];

export const cogLabel = {
  'bundling': 'Bundling',
  "prep_centre": 'Prep Fee',
  "packaging": 'Packaging',
  "postage": 'Postage',
  "vat_free_postage": 'VAT Free Postage',
  "handling": 'Handling',
  "other": 'Other',
  "extra_fee": 'Extra Fee',
  "extra_fee_perc": 'Extra Fee %',
};

export const bbpToCog = {
  prep_centre: 'standardPrepFee',
  bundling: 'bundlePrepFee',
  extra_fee: 'extraFees',
};

export const strategies = {
  sumfully: 'SKUify',
  bbp: 'BBP',
  edit: 'Edit',
};

// FIXTURES
export const generalData = [
  {
    id: "inventory_cost",
    label: "Unit Cost (Inclusive of VAT)",
    type: "RNumber",
    prefixValue: true,
  },
  {
    id: "quantity",
    label: "Quantity",
    type: "number",
    customProps: {variant: "outlined", type_number: "integer"},
  },
  {
    id: "bundled",
    label: "Bundle",
    type: "switch",
  },
];

export const generalData2 = [
  {
    id: "purchased_date",
    label: "Date",
    type: "dateRB",
  },
  {
    id: "purchased_from",
    label: "Purchased from",
    type: "text",
    placeholder: " ",
    customProps: {noLabel: true},
  },
];

export const primaryData = [
  {
    id: "asin1",
    label: "ASIN",
    type: "text",
    disabled: true,
  },
  {
    id: "seller_sku",
    label: "SKU",
    type: "text",
    disabled: false,
  },
  {
    id: "price",
    label: "Price",
    type: "currencyInput",
    disabled: true,
    groupClassName: 'price-readonly',
    prefixValue: true,
  },
  {
    id: "date_added",
    label: "Date Added",
    type: "dateRB",
    disabled: true,
  },
];

export const productApiFields = [
  'seller_sku',

  'bundled',
  'quantity',
  'inventory_cost',
  'purchased_from',
  'purchased_date',

  'bundling',
  'extra_fee',
  'extra_fee_perc',
  'handling',
  'other',
  'packaging',
  'postage',
  'prep_centre',
  'vat_free_postage',
];

export const bbpSettingsApiFields = [
  'id',
  'apply_to_unsold_inventory',
  'strategy',
];

export const getCustomProps = ({currencySymbol, name, other = {}}) => ({values, setFieldValue}) => ({
  prefixValue: currencySymbol, ...other,
});

export const cogInterfaceBase = [
  {
    id: "bundling",
    label: "Bundling",
    type: "RNumber",
    // render: ({extraProps, customProps, values, ...props}) => {
    //   const {name, setFieldValue} = props;
    //   const {costsValues} = extraProps;
    //
    //   const onChange = () => {
    //     setFieldValue(name, numberWithCommas(costsValues.name));
    //   };
    //
    //   return <FormComponent customProps={{onChange}} values={costsValues || values} {...props} />
    // }
  },
  {
    id: "prep_centre",
    label: "Prep Fee",
    type: "RNumber",
  },
  {
    id: "packaging",
    label: "Packaging",
    type: "RNumber",
  },
  {
    id: "extra_fee",
    label: "Extra Fee",
    type: "RNumber",
  },
  {
    id: "extra_fee_perc",
    label: "Extra Fee %",
    type: "RNumber",
    customProps: {beforeField: '', prefixValue: '', suffixValue: '%'},
  },
  {
    id: "handling",
    label: "Handling",
    type: "RNumber",
  },
  {
    id: "other",
    label: "Other",
    type: "RNumber",
  },
  {
    id: 'postage',
    label: 'Postage',
    type: 'RNumber',
  },
  {
    id: "vat_free_postage",
    label: "VAT Free Postage",
    type: "RNumber",
  },
].sort((a, b) => a.label.localeCompare(b.label));

export const cogInterface = [
  ...cogInterfaceBase,
  // {
  //   id: 'total_costs',
  //   label: 'Total costs',
  //   type: 'RNumber',
  //   disabled: true,
  //   render: ({extraProps, customProps, ...props}) => {
  //     const {setFieldValue, values, name} = props;
  //     const {costs, currency} = extraProps;
  //     const totalCogs = costs
  //       ? calculateTotalCogsForPreset({cogs: costs, inventoryCostForm: values.inventory_cost})
  //       : calculateTotalCogsForEdit({cogs: values, currency_cogs: currency});
  //
  //     const onChange = () => {
  //       setFieldValue(name, numberWithCommas(totalCogs));
  //     };
  //
  //     return <FormComponent customProps={{prefixValue: extraProps.currency, onChange}} {...props} />
  //   }
  // },
  // {
  //   id: 'gross_profit',
  //   label: 'Gross Profit',
  //   type: 'RNumber',
  //   disabled: true,
  //   render: ({extraProps, customProps, ...props}) => {
  //     const {setFieldValue, values, name} = props;
  //     const {costs, currency, price} = extraProps;
  //     const totalCogs = costs
  //       ? calculateTotalCogsForPreset({cogs: costs, inventoryCostForm: values.inventory_cost})
  //       : calculateTotalCogsForEdit({cogs: values, currency_cogs: currency});
  //
  //     const onChange = () => {
  //       setFieldValue(name, numberWithCommas(price - totalCogs));
  //     };
  //
  //     return <FormComponent customProps={{prefixValue: extraProps.currency, onChange}} {...props} />
  //   }
  // },
];