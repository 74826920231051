import {copy} from "../../utils";
import toast from "../../../components/toast/toast";
import {FormikHelpers, FormikState, FormikValues} from "formik";

export const integration = [
  // {
  //   id: "buy_bot_pro",
  //   label: "BuyBotPro",
  //   type: "switch",
  //   disabled: false,
  //   showLabel: true,
  // },
  {
    id: "bbp_api_key",
    label: "API key",
    type: "text",
    disabled: true,
    showLabel: false,
  },
];

export const pppInterface = [
  {
    id: "ppp_email",
    label: "Email",
    type: "text",
    disabled: false,
    showLabel: false,
  },
  {
    id: "ppp_api_key",
    label: "API key",
    type: "text",
    disabled: true,
    showLabel: false,
  },
];

export const initData = {
  buy_bot_pro: false,
  bbp_api_key: "",
  ppp_email: "",
  ppp_api_key: "",
};

export type Form = FormikState<FormikValues>

export const onCopy = (form: Form, key: string) => () => {
  copy(form.values[key]);
  toast.success("Copied!", {duration: 3000}, {isClose: false});
};

export interface UpdateAccountSetting {
  id: string;
  values: {};
  onDone: () => void;
  onError: (err: unknown) => void
}

export interface SubmitProps {
  user: { [key: string]: string };
  updateAccountSetting: (props: UpdateAccountSetting) => void;
}


export const onSubmit = ({updateAccountSetting, user}: SubmitProps) =>
  async (values: FormikValues, {setSubmitting, resetForm}: FormikHelpers<FormikValues>) => {
  const data = {...values};
  const onDone = () => {
    setSubmitting(false);
    resetForm();
    toast.success("Updated", {duration: 3000}, {isClose: false});
  };
  const onError = (error: unknown) => {
    setSubmitting(false);
    toast.error(error, {duration: 5000}, {isClose: false});
  };

  updateAccountSetting({
    id: user.id,
    values: data,
    onDone,
    onError
  })
};

export const buttonInterface = [
  {
    name: "Copy API key",
    onClick: (form: Form, name: string) => onCopy(form, name),
  },
  {
    name: "Update",
    type: "submit",
    disabled: (form: Form) => form.isSubmitting
  },
];