import React, {useEffect, useState} from "react";
import TableComponent from "../../../../components/TableComponent/TableComponent";
import {connect} from "react-redux";
import * as transaction from "../../../../../store/ducks/transactions.duck";
import * as Selectors from "../../../../../selectors/transactions.selectors";
import withConfirmHook from "../../../../../hooks/withConfirm.hook";
import * as PropTypes from "prop-types";
import Wrapper from "../../../../components/Wrapper";
import {getCurrentCompanyId} from "../../../../../crud/utils";
import {goToEditTransactionPage} from "../../utils";
import {useHistory} from "react-router-dom";

const title = "Transactions";

function TransactionTable(props) {
  const {
    rowsPerPage,
    activeColumns,
    filters,
    bankEntryId,
    getTransactions,
    transactions,
    deleteTransaction,
    confirm
  } = props;

  // eslint-disable-next-line
  const [currentRow, setCurrentRow] = useState(null);

  useEffect(() => {
    getTransactions({
      companyId: getCurrentCompanyId(),
      filters: {...filters, bank_entry_id: bankEntryId},
    })
  }, []);

  let history = useHistory();
  const onEdit = (row) => () => goToEditTransactionPage(history, row.id, row.bank_account.id);
  const onAttachments = (row) => () => setCurrentRow(row);
  const onLink = (row) => () => setCurrentRow(row);
  const onDelete = (row) => (
    confirm(() => {
        deleteTransaction({
          idCompany: row.company.id,
          idBank: row.bank_account.id,
          id: row.id
        });
      },
      {description: 'This action is permanent!'}
    )
  );

  return <div className="trans-list">
    <Wrapper>
      <TableComponent
        headRows={activeColumns.filter(column => column.isActive)}
        items={transactions}
        title={title}
        rowsPerPageSelected={rowsPerPage}
        rowProps={{
          onEdit,
          onAttachments,
          onLink,
          onDelete,
        }}
      />
    </Wrapper>
  </div>
}

TransactionTable.propTypes = {
  getTransactions: PropTypes.func.isRequired,
  deleteTransaction: PropTypes.func.isRequired,
  updateTransaction: PropTypes.func.isRequired,
  transactions: PropTypes.array,
  confirm: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  bankEntryId: PropTypes.number.isRequired,
  filters: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    transactions: Selectors.selectTransactions(state),
    loading: Selectors.selectLoading(state),
  }
};

export default connect(
  mapStateToProps,
  transaction.actions,
)(withConfirmHook(TransactionTable))