import {requests, URLSearchParamsSkipNull} from './utils'

export default {
  getSettings: () => requests.get(`/settings`),
  addSetting: (values) => requests.post(`/settings/cost_of_goods_item`, values),
  updateSetting: (values) => requests.put(`/settings`, values),

  // VAT Settings
  getVATSettings: () => requests.get(`/settings/vat_settings`),
  addVATSetting: (values) => requests.post(`/settings/vat_settings`, values),
  updateVATSetting: (values) => {
    return requests.put(`/settings/vat_settings`, values)
  },

  // ROI Settings
  getROISettings: () => requests.get(`/settings/roi_settings`),
  updateROISettings: (method) => requests.post('/settings/roi_settings', {roi_method: method}),

  // Shipping Plans
  getShippingPlans: () => requests.get(`/settings/shipping_plan`),
  updateShippingPlans: (values) => requests.put(`/settings/shipping_plan`, values),
  getOptionsShippingPlans: (plan) => requests.get(`/merchant_shipping_group/options/?shipping_plan=${plan}&`),

  // Account Settings
  getAccountSettings: (id) => requests.get(`/users/${id}`),
  updateAccountSetting: (id, values) => requests.patch(`/users/${id}`, values),

  // Amazon Settings
  updateAmazonSetting: (id, values) => requests.patch(`/users/amazon-account/${id}`, values),
  deleteAmazonSetting: (id, values) => requests.del(`/users/amazon-account/${id}`, values),

  // All Marketplaces
  getMarketplaces: () => requests.get(`/users/available_marketplaces`),
  autoAddCog: () => requests.post(`/product/auto_add_cog`),
  updatePassword: (values) => requests.put(`/users/change-password`, values),
};
