import React from "react";
import './contact.scss'
import Header from '../SalesPage/Codely/Header/Main'
import Bredcom from '../SalesPage/Codely/Bredcom/Main'
import ContactList from "../SalesPage/Codely/MenContact/ContactList";
import Need from "../SalesPage/Codely/Menpricing/Need";
import Footer from "../SalesPage/Codely/Footer/Footer";

const Contact = () => {
  return (
    <div className="pricing-page">
      <div className="inner_page_block">
        <Header parent="/" current="Contact" />
        <Bredcom same={"Contact Us"} title={"Home"} subtitle={"Contact Us"} />
      </div>

      <ContactList />

      <Need />

      <Footer parent="/" />
    </div>
  )
};

export default Contact;