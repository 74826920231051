import React from "react";
import { Checkbox, TableCell, TableRow } from "@material-ui/core";
import { TableActions } from "./tableActions";
import get from "lodash/get";
import * as PropTypes from "prop-types";
import classNames from "clsx";
import useUsers from "../../../hooks/users.hook";
const Debug = ({ children }) => {
  return children
};

const ActionCell = ({children}) => {
  return <TableCell align="center" className="table__actions">
    <div className="action-buttons-table">
      {children}
    </div>
  </TableCell>
};

const TableRowComponent = ({ row, isCheckbox, fields, rowProps, handleClick, headRows, items, isSelected }) => {
  const isItemSelected = isSelected(row.id);
  const isThick = row.relatedProduct || row.otherMarketplace;
  const { user } = useUsers({fetch: false});
  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      row={row}
      key={row.id}
      data-row={JSON.stringify(row)}
      selected={isItemSelected}
      className={classNames({'thick-product': isThick})}
    >
      <Debug row={row}>
        {isCheckbox
        && <TableCell row={row} padding="checkbox" onClick={event => handleClick(event, row.id)}>
          <Checkbox checked={isItemSelected} inputProps={{'aria-labelledby': row.id}}/>
        </TableCell>
        }
        {fields.map((key) => {
          const headerRow = headRows.find(({id}) => id === key);
          const alignCell = headerRow.align ? headerRow.align : "left";
          const additionalClass = headerRow.getClassName &&  headerRow.getClassName(row);
          if (headerRow.renderText) {
            return (
              <TableCell row={row} key={key} align={alignCell} className={classNames(`table__cell__${key}`, additionalClass)}>
                <div>{headerRow.renderText(row, rowProps, items)}</div>
              </TableCell>
            )
          }

          if (headerRow.render) {

            // TODO: remove this code when the issue of VAT will be resolved
            if (user?.amazon_settings?.find((e)=> e.location === 'North america')) {
              let updRow = { ...row };
              delete updRow.product__vat_rate
              delete updRow.product_vat_rate
              delete updRow.vat_rate
              return headerRow.render(updRow, rowProps);
            }
            //

            return headerRow.render(row, rowProps);
          }

          if (key === 'actions') {
            return <ActionCell key={key}><TableActions row={row} {...rowProps} /></ActionCell>
          }

          return <TableCell test={1} row={row} key={key} align={alignCell}
                            className={`table__cell--${key}`}>
            <div>{get(row, key, '')}</div>
          </TableCell>
        })}
      </Debug>
    </TableRow>
  )
};

TableRowComponent.propTypes = {
  fields: PropTypes.array.isRequired,
  headRows: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  row: PropTypes.object.isRequired,
  rowProps: PropTypes.object,
  isCheckbox: PropTypes.bool,
  isSelected: PropTypes.func,
  handleClick: PropTypes.func,
};

export default TableRowComponent;