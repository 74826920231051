import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { actions } from "../store/ducks/product.duck";
import * as Selector from "../selectors/product.selectors";

const useDashboardTable = (props = {}) => {
  const {
    fetch = true,
    notifications,
  } = props;

  const dispatch = useDispatch();

  const getWinners = (params) => {
    dispatch(actions.getWinners(params))
  };

  const getLosers = (params) => {
    dispatch(actions.getLosers(params))
  };

  useEffect(() => {
    if (fetch) {
      notifications && dispatch(actions.getNotifications());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch]);

  const addNotification =  (args) => {
    dispatch(actions.addNotification(args));
  };
  const updateNotification =  (args) => {
    dispatch(actions.updateNotification(args));
  };
  const deleteNotification =  (args) => {
    dispatch(actions.deleteNotification(args));
  };

  const product = Selector.getProductsSelector(useSelector(state => state));

  return {
    getWinners,
    getLosers,
    winners: product.winners.details,
    winnersLoading: product.winners.loading,
    losers: product.losers.details,
    losersLoading: product.losers.loading,
    notifications: product.notifications.details,
    addNotification,
    updateNotification,
    deleteNotification
  };
};

export default useDashboardTable;