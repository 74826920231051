import {requests} from "./utils";

export default {
    getCustomImportFormats: () => requests.get(`/custom_file_import_formats`),
    createImportFormats: (values) => requests.post(`/custom_file_import_formats`, values),
    updateImportFormats: (id, values) => requests.patch(`/custom_file_import_formats/${id}`, values),
    deleteImportFormats: (id) => requests.del(`/custom_file_import_formats/${id}`),
    getCustomImport: (id) => requests.get(`/custom_file_import_formats/${id}`),

    getBankEntries: (bankId) => requests.get(`/banks/${bankId}/bank-entries`),
    getBankEntry: (id) => requests.get(`/bank-entries/${id}`),
    createBankEntry: (values) => requests.post(`/bank-entries`, values),
    deleteBankEntry: (id) => requests.del(`/bank-entries/${id}`),
};
