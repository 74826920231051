import React, { useState } from 'react'


const faqItems = [
  {
    id: "1",
    question: "What is SKUify?",
    answer:
      "SKUify is a comprehensive solution designed for Amazon and Online Arbitrage Sellers. It streamlines inventory management, pricing optimization, and real-time analytics in a single, user-friendly interface."  },
  {
    id: "2",
    question: "How does SKUify help with Profit Analytics?",
    answer:
      "SKUify's Profit Analytics feature lets you know precisely how much money you're making—or losing—on your inventory. This gives you invaluable insights into your business performance, down to the last penny."  },
  {
    id: "3",
    question: "What is the Reconciliation Feature?",
    answer:
      "Our Reconciliation Feature tracks your damaged, lost, and missing inventory and calculates amounts reimbursed or owed by Amazon. With one click, you can raise and chase a case, ensuring you get the money you're rightly owed."  },
  {
    id: "4",
    question: "Does SKUify support multiple Amazon Marketplaces?",
    answer:
      "Yes, SKUify supports various Amazon marketplaces, including the UK & EU, Germany, France, Italy, Spain, Netherlands, and Sweden. We're also continually expanding to include more marketplaces."  },
  {
    id: "5",
    question: "How does the ASIN Journey Inventory Management work?",
    answer:
      "SKUify's ASIN Journey Inventory Management provides complete visibility into your stock's lifecycle. From purchasing to sale or return, you'll never lose track of your inventory again."  },
  {
    id: "6",
    question: "Can SKUify help with Tax and VAT?",
    answer:
      "Our Product Tax Code (PTC) Database & AI Notifier simplifies tax and VAT calculations by providing accurate product tax codes. This ensures you're not overpaying or underpaying your taxes."  },
  {
    id: "7",
    question: "What is the Automated Reimbursements feature?",
    answer:
      "This feature tracks your stock within Amazon's system and creates automated messages for potential reimbursements. The Automated Reimbursements feature can easily pay for your SKUify subscription many times over."  },
  {
    id: "8",
    question: "How easy is it to switch to SKUify?",
    answer:
      "Switching to SKUify is a breeze, thanks to our Automated Migration Tools. With just one click, you can import all your data from your current software. We also offer manual bulk import for unsupported software.",
  },
  {
    id: "9",
    question: "Is there a free trial available?",
    answer:
    "Yes, SKUify offers a 30-day free trial. Experience the power and ease of use of SKUify in your own Amazon business for a whole month, free of charge."
  },
  {
    id: "10",
    question: "What kind of support does SKUify offer for currencies and shipping methods?",
    answer:
      "SKUify supports multiple currencies and various Amazon shipping methods, including FBA, FBM, and SFP. Whatever your business model, we've got you covered.",
  },
  {
    id: "11",
    question: "How do I cancel my subscription?",
    answer:
      "You can cancel your subscription at any time by contacting our 24/7 SKUify customer support team at info@skuify.com or by using our live chat feature located in the bottom right-hand corner of our website.",
  },
];


function FaqBlock() {

  const [activeIndex, setActiveIndex] = useState(-1);

  const handleAccordionClick = (index) => {
    setActiveIndex(index === activeIndex ? -1 : index);
  };

  return (
    <>
      <section id="faqBlock" className="row_am faq_section">
      <div className="container">
        <div
          className="section_title"
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-delay="300"
        >
          <h2>
            <span>FAQ</span> - Frequently Asked Questions
          </h2>
          {/*<p>*/}
            {/*Lorem Ipsum is simply dummy text of the printing and typesetting*/}
            {/*industry lorem Ipsum has been the standard dummy.*/}
          {/*</p>*/}
        </div>
        <div className="faq_panel">
          <div className="accordion" id="accordionExample">
            {faqItems.map((item, index) => (
              <div
                className="card"
                data-aos="fade-up"
                data-aos-duration="1500"
                key={item.id}
              >
                <div className="card-header" id={`heading${index}`}>
                  <h2 className="mb-0">
                    <button
                      type="button"
                      className={`btn btn-link ${
                        activeIndex === index ? "active" : ""
                      }`}
                      onClick={() => handleAccordionClick(index)}
                    >
                      {item.question}
                    </button>
                  </h2>
                </div>
                <div
                  id={`collapse${index}`}
                  className={`collapse ${
                    activeIndex === index ? "show" : ""
                  }`}
                  aria-labelledby={`heading${index}`}
                  data-parent="#accordionExample"
                >
                  <div className="card-body">{item.answer}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default FaqBlock
