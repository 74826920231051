import React from "react";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
// import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import { createTheme } from "@material-ui/core/styles";
import {amber} from "@material-ui/core/colors";
import DeleteIcon from '@material-ui/icons/Delete';
import * as PropTypes from "prop-types";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import LinkIcon from '@material-ui/icons/Link';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import SearchIcon from '@material-ui/icons/Search';
import GetAppIcon from '@material-ui/icons/GetApp';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import VisibilityIcon from '@material-ui/icons/Visibility';

const theme = createTheme({
  palette: {
    primary: amber,
  },
});

export const TableActions = ({ row,
                               goToPage,
                               onDownloadPDF,
                               onDelete,
                               onEdit,
                               onAttachments,
                               onLink,
                               goToCompany,
                               onEditTemplate,
                               onSearch,
                               onDownload,
                               onDoneAll,
                               goToExport,
                               onAutoConfirm }) => {
  return (
    <>
      { goToPage && (<IconButton color="primary" onClick={goToPage(row)} > <ExitToAppIcon/> </IconButton>)}
      { onSearch && (<IconButton color="primary" onClick={onSearch(row)} > <SearchIcon/> </IconButton>)}
      { onAutoConfirm && (<IconButton color="primary" onClick={onAutoConfirm(row)} > <VisibilityIcon/> </IconButton>)}
      { onEdit && (<ThemeProvider theme={theme}>
          <IconButton data-action="edit" color="primary" onClick={onEdit(row)}><Icon>edit_icon</Icon></IconButton>
        </ThemeProvider>)}
      { onEditTemplate && (
        <IconButton color="secondary" onClick={onEditTemplate(row)}><Icon>edit_icon</Icon></IconButton>)}
      { onDownloadPDF && (<ThemeProvider theme={theme}>
          <IconButton color="secondary" onClick={onDownloadPDF(row)}><PictureAsPdfIcon/></IconButton>
        </ThemeProvider>)}
      { onAttachments && (<IconButton color="secondary" onClick={onAttachments(row)}><AttachFileIcon/></IconButton>)}
      { onLink && (<IconButton color="primary" onClick={onLink(row)}><LinkIcon/></IconButton>)}
      { onDownload && (<IconButton color="primary" onClick={onDownload(row)}><GetAppIcon/></IconButton>)}
      { onDoneAll && (
        <IconButton color="secondary" onClick={onDoneAll(row)}><DoneAllIcon/></IconButton>)}
      { onDelete && (<ThemeProvider theme={theme}>
          <IconButton data-action='delete' color="secondary" onClick={onDelete(row)}><DeleteIcon /></IconButton>
        </ThemeProvider>)}
      { goToCompany && (<IconButton color="primary" onClick={goToCompany(row)}><ExitToAppIcon/></IconButton>)}
      { goToExport && (<IconButton color="primary" onClick={goToExport(row)}><ImportExportIcon/></IconButton>)}
    </>
  )
};

TableActions.propTypes = {
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  row: PropTypes.object.isRequired,
};