import React, {useEffect, useState} from "react";
import { useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";
import DTabs from "../../components/DTabs/DTabs";
import useSettings from "../../../hooks/settings.hook";
import TabSettings from "./TabSettings";
import { flags } from "../../components/Marketplaces/Marketplaces";
import Avatar from "@material-ui/core/Avatar";
import { useNotification } from "../../../hooks/notification.hook";
import classNames from "clsx";
import OverlayComponent from "../../components/OverlayComponent";
import FormComponent from "../../components/controls/FormComponent";
import DSplashScreen from "../../components/SplashScreen/DSplashScreen";
import Button from "@material-ui/core/Button";
import {ON_AMAZON, SETTINGS} from "../../../router/routesMap";

const radioOptions = [
  { value: "UK", label: "UK Only" },
  { value: "EFN", label: "European Fulfilment Network" },
  { value: "PanEU", label: "Pan-European FBA" },
  { value: "MCI", label: "Multi Country Inventory" }
];

const setDefaultMarketplace = ({ amazonSettings }) => {
  let defMarketplace =  "all_marketplaces";
  // if (amazonSettings.international_fulfillment_options === "UK" ) {
  //   defMarketplace = "UK";
  // }
  localStorage.setItem("defaultMarketplace", defMarketplace);
};

const OptionSelected = ({ marketplace }) => {
  const tooltip = marketplace.participate
    ? <span className="marketplace">{marketplace.country}</span>
    : (
      <Typography>
        You do not sell in <span className="marketplace">{marketplace.country}</span>
      </Typography>
    );

  return (
    <div className={classNames("settings__form-group__m-options", { "disabled": !marketplace.participate })}>
      <OverlayComponent tooltip={tooltip} className="amazon-settings">
        <Avatar src={flags[marketplace.country_code]} />
      </OverlayComponent>
      <span className="m-options__title">{marketplace.country}</span>
    </div>
  )
};

const BottomContent = () => {
  return (
   <div className="amazon-settings__bottom-content">
    {/* <Typography>*/}
    {/*  To find your Seller ID,*/}
    {/*  <Link*/}
    {/*    // title="Visit this Seller Central page"*/}
    {/*    target="_blank"*/}
    {/*    href="https://sellercentral.amazon.co.uk/sw/AccountInfo/MerchantToken/step/MerchantToken "*/}
    {/*    className="products__details__link settings__link"*/}
    {/*  >*/}
    {/*    visit this Seller Central page.*/}
    {/*  </Link>*/}
    {/*</Typography>*/}
    {/*<Typography>*/}
    {/*  To create your MWS Auth Token,*/}
    {/*  <Link*/}
    {/*    // title="Authorise us as a developer using this Seller Central page"*/}
    {/*    target="_blank"*/}
    {/*    href="https://sellercentral.amazon.co.uk/apps/manage"*/}
    {/*    className="products__details__link settings__link"*/}
    {/*  >*/}
    {/*    authorise us as a developer using this Seller Central page.*/}
    {/*  </Link>*/}
    {/*</Typography>*/}
     <Typography>Developer's name: SKUify</Typography>
     {/*<Typography>Developer ID: 028667705981</Typography>*/}
   </div>
  )
};

export default function AmazonSettings() {
  const history = useHistory();
  const [currentTab, setTab] = useState('');
  const [successNotification, setIsShowNotification] = useNotification(
    false,
    "success",
    "Updated",
    { vertical: "bottom", horizontal: "right" }
  );
  const [errorNotification, setShowErrorNotification] = useNotification(
    false,
    "error",
    "Credentials are invalid",
    { vertical: "bottom", horizontal: "right" }
  );

  const {
    amazonSettings,
    accountSettings,
    updateAmazonSetting,
    amazonLoading: loading,
    deleteAmazonSetting
  } = useSettings({ fetch: false });

  useEffect(() => {
    if (amazonSettings?.[0]?.location && !currentTab) {
      setTab(amazonSettings?.[0]?.location);
    }
  }, [amazonSettings]);

  const handleChange = (event, newValue) => setTab(newValue);

  let amazonMarketplaces = accountSettings?.marketplaces || [];
  let initData = amazonSettings.find(item => item.location === currentTab);

  const initMarketplaces = amazonMarketplaces.filter(item => item.location.includes(currentTab))
    .map(({ country_code }) => country_code);

  let init = {};
  if (initData) {
    init = {
      location: initData.location,
      marketplaces: initMarketplaces,
      name: initData.name,
      seller_id: initData.seller_id,
      refresh_token: initData.refresh_token,
      international_fulfillment_options: initData.international_fulfillment_options,
    };
  }

  const optionsSelect = amazonMarketplaces.filter(item => item.location.includes(currentTab)).map(marketplace => ({
    value: marketplace.country_code,
    label: <OptionSelected marketplace={marketplace} />,
  }));

  const amazonFields = [
    {
      id: "marketplaces",
      label: "Marketplaces",
      disabled: true,
      type: "MSelect",
      render: ({ customProps, ...props }) => {
        let options = optionsSelect;
        const { values, } = props;
        const isUK = initData.location === "Europe" && values.international_fulfillment_options === "UK";
        if (isUK) {
          options = options.filter(({ value }) => value === "UK" )
        }
        return <FormComponent customProps={{ options }} {...props} />
      }
    },
    {
      id: "name",
      label: "Store Front Name",
      type: "text",
    },
    {
      id: "disconnect",
      type: "text",
      label: "",
      showLabel: false,
      render: ({...props}) => {
        const { values, extraProps } = props;
        const { form } = extraProps;
        const sendValues = {
          ...values,
          refresh_token: "",
          international_fulfillment_options: amazonSettings?.[0].international_fulfillment_options,
          seller_id: "",
        };

        return (
          <>
            <div className="amazon-settings__wrap-btn settings__wrap-btn">
              <Button
                className="amazon-settings__btn settings__btn btn--field"
                variant="contained"
                disabled={form.isSubmitting}
                onClick={() => onSubmit(sendValues, form, 'del')}
              >
                Disconnect
              </Button>
            </div>
          </>
        )
      }
    },
    ...(
      init.location === "Europe"
        ? (
          [{
            id: "international_fulfillment_options",
            label: "",
            type: "radio",
            customProps: { radio: radioOptions }
          }]
        ) : (
          []
        )
    ),
  ];

  const onSubmit = (values, { setSubmitting, resetForm }, name=null) => {
    const data = {...values};
    delete data["marketplaces"];
    delete data["disconnect"];

    const onDone = () => {
      setDefaultMarketplace({ amazonSettings: data });
      setSubmitting(false);
      resetForm();
      setIsShowNotification(true);

      if (!data.refresh_token) {
        history.push({pathname: ON_AMAZON, state: {from: SETTINGS}})
      }
    };

    const onError = (error) => {
      //TODO add error to toast
      setShowErrorNotification(true);
      // toast.error("Credentials are invalid");
      setSubmitting(false);
    };

    if (name === 'del') {
       return  deleteAmazonSetting({
        id: initData.id,
        values: data,
        onDone,
        onError,
      });
    }

    updateAmazonSetting({
      id: initData.id,
      values: data,
      onDone,
      onError,
    });
  };


  const tabs = amazonSettings.map(({ location }) => {
    return {
      value: location,
      label: location,
      content: <TabSettings
        fields={amazonFields}
        initData={init}
        onSubmit={onSubmit}
        successNotification={successNotification}
        errorNotification={errorNotification}
        type={currentTab}
        className="amazon"
        bottomContent={<BottomContent />}
      />
    }
  });

  return (
    <div className="settings-main__amazon settings-main__default">
      <Typography variant="h5" className="settings__title">
        Amazon Settings
      </Typography>
      <div className="d-splash-screen--wrap">
        {loading && <DSplashScreen />}
        {!!currentTab && (
          <DTabs
            value={currentTab}
            tabsData={tabs}
            onChange={handleChange}
            className="amazon-settings"
          />
        )}
      </div>
    </div>
  );
};