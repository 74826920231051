import React  from "react";
import * as PropTypes from "prop-types";
import { PortletHeaderToolbar } from "../../../../partials/content/Portlet";
import { Switch, Typography } from "@material-ui/core";
import Marketplaces from "../../../components/Marketplaces/Marketplaces";
import OverlayComponent from "../../../components/OverlayComponent";

const help = `Use this toggle to compare the same period previously visually on the chart. SKUify will compare today with yesterday, week with the previous week, month with the previous month etc.`

const ToolbarChart = ({ onCompare, isCompare, onSelectedMarketplace, time, activeMarketplaces }) => {
  const custom = time === "Custom" || time === "All";
  return (
    <PortletHeaderToolbar className="dashboard__chart__toolbar">
        <div className="chart__toolbar__switch">
          <OverlayComponent tooltip={help}>
            <Typography variant="subtitle1">Compare</Typography>
          </OverlayComponent>
          <Switch
            className="note-chart__switch note-chart__switch--p d-switch"
            onChange={onCompare}
            checked={!custom && isCompare}
            disabled={custom}
          />
        </div>
      <Marketplaces onSelectedMarketplace={onSelectedMarketplace} activeMarketplaces={activeMarketplaces}/>
    </PortletHeaderToolbar>
  )
};

ToolbarChart.propTypes = {
  isCompare: PropTypes.bool,
  onCompare: PropTypes.func,
  onSelectedMarketplace: PropTypes.func,
  time: PropTypes.string,
};

export default ToolbarChart;