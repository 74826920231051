import * as React from "react";
import {Typography} from "@material-ui/core";
import {SETTINGS} from "../../../router/routesMap";

export type WizardProps = {
  open: boolean;
  onClose?: () => void;
  list: { [key: string]: WizardListItem };
}

export type StepProps = { active: string; prev?: string };

export type Step = {
  onStep: (step: StepProps) => void
}

export type WizardListItem = {
  title: string;
  text: string;
  content: WizardItemContent[],
  onNext?: (props: Step) => () => void;
  onBack?: (props: Step) => () => void;
};

type ActionProps = Step & {
  history: any;
}

export type WizardItemContent = {
  type: string;
  icon: string;
  adapter?: any;
  actionElement: JSX.Element;
  onAction?: ({history, onStep}: ActionProps) => () => void;
}

type WizardDataProps = {
  uploadComponent: JSX.Element;
  onSubmitImportWizard: () => void;
};

export const wizardData = ({uploadComponent, onSubmitImportWizard}: WizardDataProps) => ({
  user: {
    title: 'Welcome to SKUify',
    text: 'Are you new to accounting software or migrating from another software?',
    content: [
      {
        type: 'button',
        icon: require('./../../../media/wizardIcons/add-user.svg'),
        actionElement: <Typography className="title">New User</Typography>,
        adapter: 'or',
        onAction: ({onStep}: Step) => () => onStep({active: "user1", prev: "user"}),
      },
      {
        type: 'button',
        icon: require('./../../../media/wizardIcons/migration.svg'),
        actionElement: <Typography className="title">Migrating</Typography>,
        onAction: ({onStep}: Step) => () => onStep({active: "migrating1", prev: "user"}),
      },
    ]
  },
  user1: {
    title: 'Welcome to SKUify',
    text:
      'Export all the products as CSV/XLSX to update the COGs and import it back',
    content:
      [
        {
          type: 'button',
          icon: require('./../../../media/wizardIcons/export-csv.svg'),
          actionElement: <Typography className="title">Export CSV/XLSX</Typography>,
          // actionElement: (
          //   <ExportCOG
          //     action={(props: any) => (
          //       <Typography className="title" {...props}>Export CSV/XLSX</Typography>
          //     )}
          //   />
          // ),
        },
      ],
    onNext: ({onStep}: Step) => () => onStep({active: "user2", prev: "user1"}),
  },
  user2: {
    title: 'Welcome to SKUify',
    text:
      'Import CSV file updated with COGs information',
    content:
      [
        {
          type: 'DnD',
          icon: require('./../../../media/wizardIcons/export-csv.svg'),
          actionElement: uploadComponent,
        },
      ],
    onNext: ({onStep}: Step) => () => {
      // onSubmitImportWizard();
      onStep({active: "cog", prev: "user2"})
    },
    onBack: ({onStep}: Step) => () => onStep({active: "user1", prev: "user"}),
  },
  migrating1: {
    title: 'Welcome to SKUify',
    text:
      'Select the software you are migrating from',
    content:
      [
        {
          type: 'button',
          icon: require('./../../../media/wizardIcons/skt.svg'),
          actionElement: <Typography className="title">SKT</Typography>,
          onAction: ({onStep}: Step) => () => onStep({active: "migrating2", prev: "user"}),
        },
        {
          type: 'button',
          icon: require('./../../../media/wizardIcons/sb.svg'),
          actionElement: <Typography className="title">SB</Typography>,
          onAction: ({onStep}: Step) => () => onStep({active: "migrating2", prev: "user"}),
        },
      ],
  },
  migrating2: {
    title: 'Migrating from STK',
    text:
      'Export CSV from STK and import it here',
    content:
      [
        {
          type: 'DnD',
          icon: require('./../../../media/wizardIcons/export-csv.svg'),
          actionElement: uploadComponent,
        },
      ],
    onBack: ({onStep}: Step) => () => {
      // onSubmitImportWizard();
      onStep({active: "migrating1", prev: "user"})
    },
    onNext: ({onStep}: Step) => () => onStep({active: "cog", prev: "migrating2"}),
  },
  cog: {
    title: 'Welcome to SKUify',
    text:
      'You can setup COGs information for future products from the settings page',
    content:
      [
        {
          type: 'button',
          icon: require('./../../../media/wizardIcons/cog-info.svg'),
          onAction: ({onStep, history}: ActionProps) => () => history.push(`${SETTINGS}#tab=cog_settings`),
          actionElement: <Typography className="title">COGs Info</Typography>,
        },
      ],
    onBack: ({onStep}: Step) => () => onStep({active: ''}),
  },
});