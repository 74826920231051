import React from "react";
import Button from "@material-ui/core/Button";
import LockIcon from '@material-ui/icons/Lock';
import { toAbsoluteUrl } from "../../../../../_metronic";
import Typography from "@material-ui/core/Typography";
import EditIcon from '@material-ui/icons/Edit';

export const EmptyRefunds = () => {
  return <div className="table__empty__content">
    <img className="table__empty__icon" src={toAbsoluteUrl("/media/logos/new-logo-icon.svg")} alt="smile"/>
    <Typography className="table__empty__text">woohoo no refunds</Typography>
  </div>
};

export const IconCOG = ({ isUpdate }) => isUpdate
  ? <LockIcon className="cog-cell__icon hover lock" />
  : <img alt="img" className="cog-cell__icon hover edit" src={toAbsoluteUrl("/media/d-icons/pen.svg")}/>;

export const actionsCOG = ({ onClose, onConfirm, edit }) => <>
  <Button color="primary" onClick={onClose}>{edit ? "This sale only" : "No"}</Button>
  <Button color="primary" onClick={onConfirm}>{edit ?"This and all future sales" : "Yes" }</Button>
</>;