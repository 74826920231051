import React, {useEffect, useState} from "react";
import {Table, TableHead, TableBody, TableRow, TableCell, CardHeader, CardContent, Card} from "@material-ui/core";
import {Typography} from '@material-ui/core';
import {requiredValidator} from "../../../../components/controls/validators";
import DButton from "../../../../components/Button";
import FormField from "../../../../components/controls/FormField";
import {FieldArray, Form as FormikForm, Formik} from "formik";
import Wrapper from "../../../../components/Wrapper";
import Form from "react-bootstrap/Form";
import {goToImportCustomPage, goToUploadExampleFile} from "../../utils";
import {useHistory} from "react-router-dom";
import * as BankSelector from "../../../../../selectors/bank.selectors";
import * as importDuck from "../../../../../store/ducks/import.duck";
import {connect} from "react-redux";
import * as PropTypes from "prop-types";
import * as Selector from "../../../../../selectors/imortBankTransactions.selectors";
import {useParseHistory} from "../../../../../hooks/modal.hook";
import {dateOptions, formatOptions, optionsDataName} from './options';
import Spinner from "../../../../components/Spinner";
import toast from "../../../../components/toast/toast";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const EditCustomFile = ({ customImport, updateImport, getCustomImport }) => {
  let history = useHistory();
  let [helpText, setHelpText] = useState(null);
  const idImport = useParseHistory().id;
  useEffect(() => {
    getCustomImport({ id: idImport });
  }, [idImport]);

  const headerField = [
    {
      id: 'name',
      label: 'Name',
      type: 'text',
      validator: requiredValidator('Name is required'),
    },
  ];
  const interfaceField = [

    //autocomplete field
    {
      id: 'id',
      label: '#',
      type: 'text',
      disabled: true
    },

    //autocomplete field
    {
      id: 'heading_text',
      label: 'Heading Text',
      type: 'text',
      disabled: true
    },
    //autocomplete field
    {
      id: 'example_value',
      label: 'Example Value',
      type: 'text',
      disabled: true
    },
    {
      id: 'data_name',
      label: 'What Is This?',
      type: 'RSelect',
      customProps: () => ({ index, setFieldValue, values }) => {
        // values
        return {
          options: optionsDataName,
        }
      },
    },
    {
      id: 'format',
      label: 'Format',
      type: 'RSelect',
      customProps: () => ({ index, setFieldValue, values }) => {
        const onChange = (value) => {
          // show help text or set null
          setHelpText(value.help_text);
          const getFieldName = (index, name) => `transactions.${index}.${name}`;
          setFieldValue(getFieldName(index, 'format'), value);
        };
        let disabled = true;
        let options = [];
        const data_name = values.data_name && values.data_name.value;
        // ignore
        // date
        // description
        // amount
        switch (data_name) {
          case 'ignore':
          case 'description':
            break;
          case 'date':
            options = dateOptions;
            disabled = false;
            break;
          case 'amount':
            options = formatOptions;
            // onChange
            disabled = false;
            break;
          default:

        }
        return {
          disabled,
          options,
          onChange,
        }
      }
    },
  ];

  if (customImport.loading || !customImport.details) {
    return <Spinner className="d-spinner--full-height"/>
  }
  // redirect to set headers page if before not set headers
  if (!customImport.details.header) {
    goToUploadExampleFile(history, idImport);
    return null;
  }

  const data = customImport.details.header.map((header, index) => {
    const mapping = customImport.details.mapping && customImport.details.mapping[header];
    return {
      id: index + 1,
      heading_text: header,
      example_value: customImport.details.first_row[index],
      data_name: mapping && mapping.data_name,
      format: mapping && mapping.format,
    }
  });
  const initialData = {
    name: customImport.details.name,
    transactions: data,
  };

  const onBack = () => goToImportCustomPage(history);

  return (
    <Formik
      initialValues={initialData}
      onSubmit={async (values, {setSubmitting, resetForm}) => {
        const mapping = {};
        // TODO on submit there are a ton of different error messages all we need to handle
        values.transactions.forEach((transaction) => {
          mapping[transaction.heading_text] = {
            data_name: transaction.data_name && transaction.data_name.value,
            format: transaction.format && transaction.format.value,
          }
        });
        updateImport({
          id: idImport,
          values: {
            name: values.name,
            mapping,
          },
          onDone: () => {
            toast.success("Saved");
            setSubmitting(false);
          }
        });
      }}
    >
      {({...form}) => {
        return (
          <FormikForm>
            <Wrapper className="edit-custom-file">
              {/*<div>{form.errors.map((err) => err)}</div>*/}
              {/*title for page*/}
              <Typography gutterBottom variant="h5" component="h2" className="table__header--title">
                Edit Custom File Import Format
              </Typography>
              <Typography gutterBottom variant="h6" component="h2" className="table__header--title">
                General Details
              </Typography>
              <div className="col-sm-3 table__header--input">
                {
                  headerField.map((item) => (
                    <Form.Group key={item.id} controlId={item.id}>
                      <Form.Label>{item.label}</Form.Label>
                      <FormField
                        name={item.id}
                        label={item.label}
                        type={item.type}
                        validate={item.validator}
                        {...form}
                      />
                    </Form.Group>
                  ))
                }
              </div>
              <div className="form__edit-custom-import">
                <Table>
                  <TableHead>
                    <TableRow>
                      {interfaceField.map(item =>
                        (<TableCell key={item.id} className="table__cell">{item.label}</TableCell>))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/*TODO add data to autocomplete fields*/}
                    <FieldArray
                      name="transactions"
                      render={(arrayHelpers) => {
                        return form.values.transactions.map((values, index) => {
                          return (
                            <TableRow key={index}>
                              {
                                interfaceField.map((item) => (
                                  <TableCell key={item.id} aria-label={item.label} className="table__cell">
                                    <FormField
                                      // showLabel={false}
                                      render={item.render}
                                      name={`transactions.${index}.${item.id}`}
                                      label={item.label}
                                      index={index}
                                      type={item.type}
                                      defaultValue={item.defaultValue}
                                      disabled={item.disabled}
                                      validate={item.validator}
                                      customProps={item.customProps && item.customProps()}
                                      {...form}
                                      values={values}
                                    />
                                  </TableCell>
                                ))
                              }
                            </TableRow>
                          )
                        })
                      }}
                    />
                  </TableBody>
                </Table>
              </div>

              {
                helpText
                && <Card className="offset-sm-7 edit-custom-file__help-text">
                  <div className="inform__header help-text__header">
                    <InfoOutlinedIcon/>
                    <CardHeader title={helpText.title} className="inform__title"/>
                  </div>
                  <CardContent className="inform__content help-text__content">
                    <Typography variant="body1" color="textSecondary">
                      {helpText.content}
                      {helpText.list.map((item, index) => <li key={index}>{item}</li>)}
                    </Typography>
                  </CardContent>
                </Card>
              }
              <div className="container-button container-button--modal container-button--page">
                <DButton typeOfButton="doneAllSecondary" type="submit">Save File Import Format</DButton>
                <DButton typeOfButton="close" onClickCustom={onBack}>Back</DButton>
              </div>
            </Wrapper>
          </FormikForm>
        )
      }}
    </Formik>
  )
};

EditCustomFile.propTypes = {
  updateImport: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    bank: BankSelector.getBank(state, props),
    customImport: Selector.selectImport(state, props),
  }
};

const mapDispatchToProps = {
  updateImport: importDuck.actions.updateImport,
  getCustomImport: importDuck.actions.getCustomImport,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditCustomFile);