import React, { Fragment, useState, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import "./withConfirm-style.scss"

const defaultOptions = {
  title: 'Are you sure?',
  description: '',
  confirmationText: 'Ok',
  cancellationText: 'Cancel',
  dialogProps: {},
  onClose: () => {},
  onCancel: () => {},
  style: null,
};

const withConfirmHook = (WrappedComponent) => props => {
  const [onConfirm, setOnConfirm] = useState(null);
  const [options, setOptions] = useState(defaultOptions);
  const {
    title,
    description,
    confirmationText,
    cancellationText,
    dialogProps,
    onClose,
    onCancel,
    style
  } = options;

  const handleClose = useCallback(() => {
    onClose();
    setOnConfirm(null);
  }, [onClose]);
  const handleCancel = useCallback(() => {
    onCancel();
    handleClose();
  }, [onCancel, handleClose]);
  const handleConfirm = useCallback((...args) => {
    onConfirm(...args);
    handleClose();
  }, [onConfirm, handleClose]);

  /* Returns function opening the dialog, passed to the wrapped component. */
  const confirm = useCallback((onConfirm, options = {}) => () => {
    setOnConfirm(() => onConfirm);
    setOptions({ ...defaultOptions, ...options });
  }, []);

  return (
    <Fragment>
      <WrappedComponent {...props} confirm={confirm} />
      <Dialog className={style} fullWidth {...dialogProps} open={!!onConfirm} onClose={handleCancel}>
        {title && (
          <DialogTitle className={style && style + "__title"}>{title}</DialogTitle>
        )}
        {description && (
          <DialogContent className={style && style + "__content"}>
            <DialogContentText>{description}</DialogContentText>
          </DialogContent>
        )}
        <DialogActions className={style && style + "__actions"}>
          <Button onClick={handleCancel} className={style && style + "__btn btn btn--cancel"}>
            {cancellationText}
          </Button>
          <Button onClick={handleConfirm} className={style && style + "__btn btn btn--confirm"} color={style ? "default" : "primary"}>
            {confirmationText}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default withConfirmHook;