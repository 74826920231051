import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { actions } from "../store/ducks/auth.duck";

const useUsers = (props = {}) => {
  const { fetch=true } = props;
  const dispatch = useDispatch();

  const getUsersInfo = () => dispatch(actions.requestUser());

  useEffect(() => {
    if (fetch) {
      getUsersInfo();
      // dispatch(actions.requestUser());
     }
  }, [fetch]);

  const { isAuthorized, user } = useSelector(
    ({ auth, urls }) => ({
      isAuthorized: auth.user != null,
      user: auth.user
    }),
    shallowEqual
  );


  return {
    user,
    getUsersInfo,
  };
};

export default useUsers;