import React from "react";
import "./d-error-page.scss"
import { toAbsoluteUrl } from "../../../../_metronic";

export function DErrorPage() {
  return (
    <>
      <div className="kt-grid kt-grid--ver kt-grid--root">
        <div className="kt-grid__item kt-grid__item--fluid kt-grid  d-error-page">
          <div className="kt-error_container d-error-page__container">
            <div className="kt-error_subtitle kt-font-light d-error-page__subtitle">
              <h1>Sorry</h1>
              <img src={toAbsoluteUrl("/media/logos/new-logo-icon.svg")} alt=""/>
            </div>

            <p className="kt-error_description kt-font-light d-error-page__description">
              Something went wrong
            </p>
          </div>
        </div>
      </div>
    </>
  );
}