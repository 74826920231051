import React from "react";
import toaster from "toasted-notes";
import "toasted-notes/src/styles.css"; // optional styles
import "./toast.scss";
import classNames from "clsx";
// https://github.com/bmcmahen/toasted-notes

const formatError = (error, className) => {
  const container = (content) => <span className={classNames("toast--error", className)}>{content}</span>;
  if (error?.response?.data) {
    // iterate errors and generate one string msg
    // e.g error.response.data = {"name": 'Some text'}
    const msg = Object.entries(error.response.data).map(([key, value]) => {
      return `${key}: ${value}`;
    });
    return container(msg.join(','));
  }
  if (error && typeof error === "string") {
    return container(error);
  }

  if (error && typeof error === "object") {
    return container(error);
  }

  return container("error");
};

const notification = (msg, config) => {
  toaster.closeAll();
  toaster.notify(msg, config);
};

const toast = {
  success: (msg, config, userConfig={}) => {
    const { className } = userConfig;
    notification(<span className={classNames("toast--success", className)}>{msg}</span>, config)
  },
  // process server errors before show message
  error: (msg, config, userConfig={}) => {
    const { className } = userConfig;
    notification(formatError(msg, className), config)
  },
  // warning: notification,
  warning: (msg, config, userConfig={}) => {
    const { className } = userConfig;
    notification(<span className={classNames("toast--warning", className)}>{msg}</span>, config)
  },
  info: (msg, config, userConfig={}) => {
    const { className } = userConfig;
    notification(<span className={classNames("toast--info", className)}>{msg}</span>, config)
  }
};

export default toast;