import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import Marketplaces from '../../components/Marketplaces/Marketplaces'
import DSearch from '../../components/DSearch/DSearch'
import ReportsDialog from './ReportsDialog'
import RangeDatePicker from './RangeDatePicker'
import { styles } from '@material-ui/pickers/views/Calendar/Calendar'
import {FormControlLabel, Switch} from "@material-ui/core";
import OverlayComponent from "../../components/OverlayComponent";

const TabsToolbar = props => {
  const {
    setParams,
    onSelectedMarketplace,
    setSearchQuery,
    tab,
    searchQuery,
    isBottom,
    pageParams = {},
    getParams
  } = props;
  const [isClear, setClear] = useState(false);
  useEffect(() => {
    if (tab) {
      setClear(true)
    }
  }, [tab]);

  const handleChangeInventory = (event) => {
    setClear(false);
    getParams({no_inventory_cost: event.target.checked});
    setParams({no_inventory_cost: event.target.checked, offset: 0})
  };

  const switches = [
    {
      label: 'No COGs',
      tooltip: 'Toggle on to include products that are have no COGs',
    }
  ];

  return (
    <div className="header--search tabs__toolbar">
      {
        isBottom ? (
          <div className="full-width">
            <div className="header--checkboxes">
              {switches.map((el, index) => {
                const {tooltip, label} = el;
                return (
                  <div key={index} className='space_header_checkboxes'>
                    <OverlayComponent  tooltip={tooltip} key={label} >
                      <FormControlLabel
                        className='space_checkboxes_label'
                        labelPlacement="start"
                        control={
                          <Switch
                            className="d-switch space_checkboxes"
                            checked={!!pageParams.no_inventory_cost}
                            onChange={handleChangeInventory}
                            inputProps={{'aria-label': `checkbox`}}
                          />
                        }
                        label={label}
                      />
                    </OverlayComponent>
                  </div>
                )
              })}
            </div>
          </div>
        ) : (
          <>
            <DSearch
              setSearchQuery={setSearchQuery}
              isClear={isClear}
              setClear={setClear}
              initValue={searchQuery}
            />

            <RangeDatePicker className={styles.range_date_picker} setParams={setParams} />
            <ReportsDialog tab={tab} />
            <Marketplaces onSelectedMarketplace={onSelectedMarketplace} />
          </>
        )
      }
    </div>
  )
}

TabsToolbar.propTypes = {
  onSelectedMarketplace: PropTypes.func,
  setSearchQuery: PropTypes.func,
  setParams: PropTypes.func,
  getParams: PropTypes.func,
  tab: PropTypes.string,
  searchQuery: PropTypes.string,
  isBottom: PropTypes.bool,
  pageParams: PropTypes.object,
}

export default TabsToolbar