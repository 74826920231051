import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { actions } from "../store/ducks/chart.duck.js";
import * as ChartSelector from "../selectors/chart.selectors";

const useChart = (props = {}) => {
  const {
    period,
    fetch = true,
    start_date = null,
    end_date = null,
    marketplace = null,
    limit = null,
    page_name = null,
    salesOnly = false,
    table = null,
    offset
  } = props;

  const [cachedParams, setCachedParams] = useState({});

  const dispatch = useDispatch();
  const refresh = params => {
    const mergedParams = { ...cachedParams, ...params };
    setCachedParams(mergedParams);
    salesOnly
      ? dispatch(actions.getSales(mergedParams))
      : dispatch(actions.getChart(mergedParams));
  };

  useEffect(() => {
    if (fetch)
      refresh({
        period,
        start_date,
        end_date,
        marketplace,
        limit,
        page_name,
        table,
        offset
      });
  }, [fetch]);

  const chart = ChartSelector.getChart(useSelector(state => state));
  const paramsWithOffset = { ...chart.params, offset };

  return {
    params: paramsWithOffset,
    loading: chart.loading,
    chart: chart.chart,
    orders: chart.orders,
    refunds: chart.refunds,
    by_marketplaces: chart.chart.by_marketplaces,
    haveInvalidProfit: chart.haveInvalidProfit,
    haveInvalidSales: chart.haveInvalidSales,
    refresh,
    setParams: table => {
      return (key, value) => {
        if (typeof key === "object") {
          let _params = { table };
          Object.entries(key).forEach(item => {
            _params = { ..._params, [item[0]]: item[1] };
            return _params;
          });
          refresh(_params);
          return null;
        }
        refresh({ table, [key]: value });
      };
    }
  };
};

export default useChart;
