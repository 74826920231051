import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import * as CategorySelector from "../selectors/category.selectors";
import * as categoryDuck from "../store/ducks/category.duck";

const useCategories = (props = {}) => {
  const { fetch=true } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    if (fetch)
      dispatch(categoryDuck.actions.getCategories());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch]);

  const getCategories = (args) => dispatch(categoryDuck.actions.getCategories(args));
  const addCategory = (args) => dispatch(categoryDuck.actions.addCategory(args));
  const updateCategory = (args) => dispatch(categoryDuck.actions.updateCategory(args));
  const deleteCategory = (args) => dispatch(categoryDuck.actions.deleteCategory(args));

  const categories = CategorySelector.getCategories(useSelector(state => state));
  const loading = CategorySelector.getLoading(useSelector(state => state));
  const fetched = CategorySelector.selectFetched(useSelector(state => state));

  return {
    categories: {
      details: categories,
      loading,
      fetched,
    },

    getCategories,
    addCategory,
    updateCategory,
    deleteCategory,
  };
};

export default useCategories;