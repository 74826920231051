/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import IconButton from "@material-ui/core/IconButton";
import { ReactComponent as EuroCurrency } from "../../../_metronic/layout/assets/layout-svg-icons/Euro.svg";
import { ReactComponent as DollarCurrency } from "../../../_metronic/layout/assets/layout-svg-icons/USD.svg";
import { ReactComponent as GBPCurrency } from "../../../_metronic/layout/assets/layout-svg-icons/GBP.svg";
import { ReactComponent as Icon } from "../../../_metronic/layout/assets/layout-svg-icons/currency-icon.svg";
import { Form as FormikForm, Formik } from "formik";
import toast from "../../pages/components/toast/toast";
import useUsers from "../../hooks/users.hook";
import useSettings from "../../hooks/settings.hook";
import OverlayComponent from "../../pages/components/OverlayComponent";
import { useGeolocation } from "../../hooks/geolocation.hook";
import PopperMenu from "../../pages/components/PopperMenu";

const currencyOptions = [
  {value: "GBP", label: "GB£", country_code: "UK", indicator: <GBPCurrency />},
  {value: "EUR", label: "EU€ ", indicator: <EuroCurrency />},
  {value: "USD", label: "US$", country_code: "USA", indicator: <DollarCurrency className="currency-icon--dollar" />},
];

const UserCurrency = () => {
  const { accountSettings, updateAccountSetting } = useSettings({ fetch: false });
  const { user } = useUsers();
  const { geolocation } = useGeolocation();

  let initCurrency = currencyOptions.find(({ value }) => value === "EUR");

  let init = {
    currency: currencyOptions.find(({ country_code })=> country_code === geolocation.country_code) || initCurrency
  };

  if (accountSettings?.currency) {
    init = { currency: currencyOptions.find(({value})=> value === accountSettings.currency) }
  }

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    const onDone = () => {
      setSubmitting(false);
      resetForm();
      window.location.reload();
    };
    const onError = (error) => {
      setSubmitting(false);
      toast.error(error);
    };

    if (values.currency !== init.currency.value) {
      updateAccountSetting({
        id: user.id,
        values: values,
        onDone,
        onError
      })
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={init}
      onSubmit={onSubmit}
    >
      {({...form}) => {
        const onChange = (value) => () => {
          const data = {};
          data.currency = value.value;
          form.setFieldValue("currency", value);
          onSubmit(data, form)
        };

        const currencyOptionsList = currencyOptions.map((item) => ({
          func: onChange(item),
          content: item.label,
          selected: init.currency.value === item.value,
        }));

        return (
          <FormikForm className="user-currency__form">
            <PopperMenu
              contentBtn={
                <OverlayComponent className="header__tooltip" tooltip="Please choose your preferred currency">
                  <IconButton className="user-currency__symbol">
                    <Icon /> {init.currency.label}
                  </IconButton>
                </OverlayComponent>
              }
              popperProps={{
                array: currencyOptionsList,
                className: "user-currency__select__list MuiPaper-elevation1",
                placement: "bottom-start",
                isActiveItem: true
              }}
              styleEl="user-currency__select"
            />
          </FormikForm>
        )
      }}
    </Formik>
  )
};
export default UserCurrency;