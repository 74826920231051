import React from "react";
import {Dialog} from "@material-ui/core";
import * as PropTypes from "prop-types";
import CollapsedRow from "./CollapsedRow";

const ReconciliationInfoModal = props => {
  const {handlerRaiseCase, handleChaiseCase, row, handleChange} = props;
  return (
    <Dialog
      open={!!row?.id}
      onClose={(e) => handleChange(e, row)}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      className="reconciliation__modal info"
      maxWidth="md"
      scroll="body"
    >
      <CollapsedRow
        handlerRaiseCase={handlerRaiseCase}
        handleChaiseCase={handleChaiseCase}
        showExtraRow={row?.id}
        row={row}
        key={`${row?.id}--extra-cell`}
        handleChange={handleChange}
      />
    </Dialog>
  )
};

ReconciliationInfoModal.propTypes = {
  row: PropTypes.object,
  closeModal: PropTypes.func,
  handlerRaiseCase: PropTypes.func,
  handleChaiseCase: PropTypes.func,
  handleChange: PropTypes.func,
};

export default ReconciliationInfoModal;