import React, {useEffect, useState} from "react";
import classNames from "clsx";
import {checkOpen, getInterface, limitExpensesTransactions} from "./utils";
import {FieldArray} from "formik";
import {Pagination} from "@material-ui/lab";
import {getCountPage} from "../../components/TableComponent/TableComponent";
import useReports from "../../../hooks/reports.hook";
import DSplashScreen from "../../components/SplashScreen/DSplashScreen";
import FormField from "../../components/controls/FormField";
import Form from 'react-bootstrap/Form'
import EmptyBlock from "../../components/EmptyBlock";
import _ from "lodash";

const TransactionsRows = props => {
  const {opened, id, form, onFormValues, onFormTouched, method} = props;

  const {
    getExpensesTransactions,
    expensesTransactions,
    expensesTransactionsLoading,
    updateExpensesTransaction,
    deleteExpensesTransaction,
  } = useReports();

  const [page, setPage] = useState(1);
  const [offset, setOffset] = useState(0);

  const isOpen = checkOpen({opened, rowId: id});
  const isMonths = method === "BALANCED";

  useEffect(() => {
    const isDownloaded = expensesTransactions?.[id]?.data?.[offset];
    !isDownloaded && isOpen && getExpensesTransactions({
      expense_setting_id: id,
      limit: limitExpensesTransactions,
      offset
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isOpen, offset]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setOffset((newPage - 1) * limitExpensesTransactions);
  };

  useEffect(() => {
    onFormValues(form.values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values]);

  useEffect(() => {
    onFormTouched(form.touched)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.touched]);

  return (<>
      {isOpen &&
      <FieldArray
        name="transactions"
        render={(arrayHelpers) => {
          const rowValues = arrayHelpers?.form?.values?.transactions?.[id];
          const data = rowValues?.data?.[offset] || [];

          const rowTouched = arrayHelpers?.form?.touched?.transactions?.[id];
          const touched = rowTouched?.data?.[offset] || [];

          const isEmpty = expensesTransactionsLoading === id || (!data.length && !_.isEmpty(expensesTransactions));

          return <div className={classNames('cog-settings__wrap-transitions ' +
            'page__table__wrap', {
            'cog-settings__wrap-transitions--opened': isOpen
          })}>
            <div className="d-splash-screen--wrap">
              {isEmpty && <DSplashScreen/>}
              {(!data.length && !_.isEmpty(expensesTransactions)) && <EmptyBlock/>}
              {data?.map((transaction, indexTransaction) => {
                let isDisabled = false;

                if (!transaction.is_credit) {
                  isDisabled =
                    data?.some(el => el.id !== transaction.id && el.created_at === transaction.created_at)
                }

                const onRemove = () =>
                  deleteExpensesTransaction({rowId: id, offsetTransactions: offset, transaction});

                return <div
                  key={`${id}-${indexTransaction}`}
                  className={classNames("cog-settings__transaction-item", {
                    'red-credit': transaction.is_credit
                  })}
                >
                  {getInterface(isMonths).map((item = {}) => {
                    const {label, render, type, id: fieldId, disabled} = item;
                    const path = `transactions.${id}.data.${offset}.${indexTransaction}`;
                    const name = `${path}.${fieldId}`;

                    const updateTransaction = () => {
                      const onDone = () => arrayHelpers.form.setFieldTouched(`${path}.expense_amount`, false);
                      updateExpensesTransaction({transaction, rowId: id, onDone});
                    };

                    return (
                      <Form.Group key={fieldId} controlId={fieldId} className={`form-group--${fieldId}`}>
                        <Form.Label className="form-group__label-item" column={false}>
                          {label}
                        </Form.Label>

                        {type === "button"
                          ? render({
                            transaction,
                            isDisabled,
                            id: fieldId,
                            onRemove,
                            updateTransaction,
                            touched: touched[indexTransaction]
                          })
                          : (
                            <FormField
                              name={name}
                              label={label}
                              type={type}
                              extraProps={{indexTransaction, offset, id}}
                              disabled={disabled || transaction.is_credit || isDisabled}
                              render={render}
                              {...form}
                            />
                          )
                        }
                      </Form.Group>
                    )
                  })}
                </div>
              })}
            </div>
            <div className="table__pagination-block">
              <Pagination
                color="primary"
                size="small"
                page={page}
                defaultPage={1}
                siblingCount={2}
                count={getCountPage({
                  totalCount: rowValues?.totalCount,
                  rowsPerPage: limitExpensesTransactions
                })}
                onChange={handleChangePage}
              />
            </div>
          </div>
        }}
      />}
    </>
  )
};

export default TransactionsRows;