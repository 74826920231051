import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import React from "react";
import * as PropTypes from "prop-types";
import {withCurrency} from "../utils";

const TransactionModalBalance = ({ balance, currency}) => {
  return (
    <TableRow className="bottom-table">
      <TableCell colSpan={3}>
        <Typography variant="subtitle2" align="right">Balance:</Typography>
      </TableCell>
      <TableCell colSpan={2}>
        <Typography variant="subtitle2" align="left">
          {withCurrency(balance, currency.currency_symbol)}
        </Typography>
      </TableCell>
      <TableCell/>
    </TableRow>
  )
};
TransactionModalBalance.propTypes = {
  currency: PropTypes.object,
  balance: PropTypes.number,
};
export default TransactionModalBalance;