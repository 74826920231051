import React from "react";
import {ShippedModalInfo} from "../NameCell";
import {Avatar, Typography} from "@material-ui/core";
import clsx from "clsx";

const tableInterface = ({headRows, data}) => headRows.map(({id, label, render}) => {
  return ({
    name: <Typography variant="subtitle2" className="table__cell--detail__title">{label}</Typography>,
    value: render ? render(data[id]) : <Typography variant="subtitle2">{data[id] || ''}</Typography>
  })
});

export const Info = ({title, product = {},  displayData = [], headRows}) => {
  const {shipment_id, asin1, seller_sku, image_url, item_name, createdDate} = product;

  return <div className="info">
    <p className="info-title">{title}</p>

    {
      displayData.map((displayInfo, ndx) => {
        const infoTable = tableInterface({headRows, data: displayInfo});

        const allInfo = [
          {
            name: <span className="bold table__cell--detail__title copy-hover">Product</span>,
            value: (
              <ShippedModalInfo
                shipmentId={shipment_id}
                asin={asin1}
                sku={seller_sku}
                title={item_name}
                createdDate={createdDate}
              />
            )
          },
          ...infoTable
        ];

        return (
          <div key={ndx} className={clsx("info__content", {hide: ndx})}>
            <Avatar alt="img" variant="square" src={image_url}/>

            <div className={clsx(`info__data info__data__${allInfo.length}`)}>
              {allInfo.map(({name, value}, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className={`title-cell title-cell--${index + 1}`}>
                      {name}
                    </div>

                    <div className={`cell-text row-item--${index + 1}`}>
                      {value}
                    </div>
                  </React.Fragment>
                )
              })
              }
            </div>
          </div>
        )
      })
    }
  </div>
};