import React from "react";
import { toAbsoluteUrl } from "../../../../_metronic";
import Typography from "@material-ui/core/Typography";
import TooltipComponentState from "../../components/TooltipComponentState";
import { withUnits } from "../utils";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import PopupTable from "../../components/PopupTable/PopupTable";

export const EmptyWarning = () => (
  <div className="table__empty__content table__empty__content--warnings">
    <img className="table__empty__icon" src={toAbsoluteUrl("/media/logos/new-logo-icon.svg")} alt="logo"/>
    <Typography className="table__empty__text">Woo Hoo - you have no warnings</Typography>
  </div>
);

export const EmptyBBP = () => (
  <div className="table__empty__content table__empty__content--warnings">
    <img className="table__empty__icon" src={toAbsoluteUrl("/media/logos/new-logo-icon.svg")} alt="logo"/>
    <Typography className="table__empty__text no-text-transform">
      All BuyBotPro imports have been added to your Inventory
    </Typography>
  </div>
);

export const CommingSoon = () => (
  <div className="table__empty__content table__empty__content--warnings">
    <Typography className="table__empty__text">coming soon...</Typography>
  </div>
);

export const EmptyReimbursements = () => (
  <div className="table__empty__content table__empty__content--warnings">
    <img className="table__empty__icon" src={toAbsoluteUrl("/media/logos/new-logo-icon.svg")} alt="logo"/>
    <Typography className="table__empty__text">Woo Hoo - you have no reimbursements</Typography>
  </div>
);

export const marketplacesProduct = (related) => related.map(({ marketplace }) => marketplace);

export const WithTooltip = ({ isWrap, tooltip, children, id }) => {
  if (isWrap) {
    return (
      <TooltipComponentState tooltip={tooltip} id={id}>
        <div className="full-size">{children}</div>
      </TooltipComponentState>
    )
  } else return children;
};

export const BBPDeleteHelp = () => {
  return (
    <div className="bbp-delete-help">
      <Typography className="help__text">
        This delete function will allow you to delete items from the BuyBotPro imports.
      </Typography>
      <Typography className="help__text">
        Please note, deleted items will not sync with Amazon.
      </Typography>
      <Typography className="help__text">
        This function can be used if you accidentally added a BBP import or changed your mind on your purchase.
      </Typography>
    </div>
  )
};