import React from "react";
import classNames from 'classnames';

import './popup-table.scss';

const PopupTable  = ({tableData}) => {
  return (
    <div className="popup-tb__wrap">
      {tableData.map(({name, data}, index) => {
        return (
          <React.Fragment key={`${name}-${index}`}>
            <div className={classNames("popup-tb__cell coll-1", {'header': !index})}>
              {name}
            </div>
            <div className={classNames("popup-tb__cell coll-2", {'header': !index})}>
              {data}
            </div>
          </React.Fragment>
        )
      })}
    </div>
  )
};

export default PopupTable;