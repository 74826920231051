import {Content, Header} from "../components";
import {buttons, initActive} from "./utils";
import React, {useEffect, useState} from "react";
import useOnboarding from "../../../../hooks/onboarding.hook";
import {Redirect} from "react-router";
import {ON_REGISTRATION} from "../../../../router/routesMap";

interface ContentSubscribeProps {
  plan?: string;
  isPricing?: boolean;
  isRedirect?: boolean;
  header?: (props: any) => JSX.Element
}

const ContentSubscribe = ({plan, isRedirect, header, isPricing}: ContentSubscribeProps) => {
  const [active, setActive] = useState(initActive);
  const {subscriptions, getSubscription} = useOnboarding();
  const [subscription, setSubscription] = useState('');
  const [current, setCurrent] = useState('');

  useEffect(() => {
    const period = active.find((item) => item.group === 'period')?.name;
    const location = active.find((item) => item.group === 'location')?.name;
    getSubscription({period, location});
  }, [active]);

  useEffect(() => {
    if (subscriptions.length  && !isRedirect) {
      let activePlanUrl = "";

      if (subscriptions.length > 1) {
        activePlanUrl = subscriptions.find((plan: any) => plan.plan_code === plan)?.url || "";
      } else {
        activePlanUrl = subscriptions[0].url;
      }

      !!activePlanUrl && setCurrent(activePlanUrl);
    }
  },[subscriptions]);

  const onCard = (url: string) => {
    setSubscription(url);
  };

  const onToggle = (name: string, group: string) => {
    const updateData = active.map((btn) => group === btn.group ? {...btn, name} : btn);
    setActive(() => updateData)
  };

  if (!!subscription && !isRedirect) {
    window.open(current, "_self");
  }

  // isRedirect for onboarding and invite
  if (!!subscription && isRedirect) {
    return <Redirect to={{pathname: ON_REGISTRATION, state: {url: subscription}}} />
  }

  return (isPricing
      ? <Content onCard={onCard} subscribe={subscription} subscriptionList={subscriptions} current={current} isPricing={isPricing} />
      : (
        <div className="subscription">
          {/*<Header buttons={buttons} active={active} onToggle={onToggle} />*/}
          {/*<Header buttons={subscriptions.length > 1 ? buttons : null} active={active} onToggle={onToggle} />*/}
          {header?.({
            active,
            onToggle,
            buttons: subscriptions.length > 1 ? buttons : null,
          })}
          <div className="subscription__list">
            <Content onCard={onCard} subscribe={subscription} subscriptionList={subscriptions} current={current} isPricing={isPricing} />
          </div>
        </div>
      )
  )
};

export default ContentSubscribe;