import React, {ChangeEvent, useEffect, useState} from 'react';
// @ts-ignore
import {FixedSizeList} from "react-window";
import renderRow from "./renderRow";
import Wrapper from "../../components/Wrapper";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import {Pagination} from "@material-ui/lab";
import {Button, Typography} from "@material-ui/core";
import './notifications.scss'
import {getCountPage} from "../../components/TableComponent/TableComponent";
import useNotifications from "../../../hooks/notifications.hook";
import EmptyBlock from "../../components/EmptyBlock";
import DSplashScreen from '../../components/SplashScreen/DSplashScreen';
import withConfirmHook from "../../../hooks/withConfirm.hook";
import {useHistory} from "react-router-dom";
import {onUpdateNotifications} from "./utils";

export const countItem = 20;

const Notifications = ({confirm}: any) => {
  const {
    getNotifications,
    deleteNotifications,
    notificationList,
    notificationTotal,
    notificationLoading,
  } = useNotifications({fetch: false});
  const history = useHistory();

  const {height} = useWindowDimensions();
  const [page, setPage] = useState(1);

  useEffect(() => {
    getNotifications({values: {limit: countItem, offset: countItem * (page - 1)}});
  }, [page, history.location.state]);

  const handleChangePage = (event: ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  const onClear = () => {
    confirm(() => {
        console.log("Clear all");
        deleteNotifications({
          onDone: (data: any) => {
            // console.log('Done');
          },
          onError: (error: any) => {
            console.log(error);
          }
        })
      },
      {
        description: 'This Action is Permanent!',
        style: "d-confirm"
      })()
  };

  return (
    <Wrapper elevation={0} classWrapper="page__table__wrap notifications">
      <div className="notifications__header">
        <Typography className="page-title">Notifications</Typography>
        {!!notificationTotal && <Button
          variant="text"
          className="notifications__title-btn"
          onClick={onClear}
        >
          Clear all
        </Button>}
      </div>
      <div className="d-splash-screen--wrap">
        {notificationLoading && <DSplashScreen/>}
        {
          !!notificationTotal
            ? <FixedSizeList
              className="notifications__list"
              height={height - 350}
              itemSize={100}
              itemCount={notificationList.length}
            >
              {renderRow(onUpdateNotifications(notificationList))}
            </FixedSizeList>
            : <EmptyBlock className="notifications">you don't have notifications</EmptyBlock>
        }

        <div className="table__pagination-block">
          <Pagination
            color="primary"
            size="small"
            page={page}
            defaultPage={1}
            siblingCount={2}
            count={getCountPage({totalCount: notificationTotal, rowsPerPage: countItem})}
            onChange={handleChangePage}
          />
        </div>
      </div>

    </Wrapper>
  );
};

export default withConfirmHook(Notifications);
