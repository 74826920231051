import React, { useState } from 'react';
import * as PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { useCheckboxStorage, usePerPage } from "../../../hooks/modal.hook";
import TableComponent from "../../components/TableComponent/TableComponent";
import EmptyBlock from "../../components/EmptyBlock";
import TableToolbarComponent from "../../components/TableComponent/TableToolbarComponent";
import DButton from "../../components/Button";
import withConfirmHook from "../../../hooks/withConfirm.hook";

export const headRowsVATSchemesModal = [
  { id: 'start_date', label: 'Start Date', type: 'date', isActive: true,
    // renderText: (row) => {
    //   return  moment(row.start_date, 'YYYY-MM-DDTHH:mm:ss.SSS').format('YYYY-MM-DD');
    // }
  },
  { id: 'end_date', label: 'End Date', type: 'date', isActive: true,
    // renderText: (row) => {
    //   return  moment(row.end_date, 'YYYY-MM-DDTHH').format('YYYY-MM-DD');
    // }
  },
  { id: 'vat_scheme', label: 'VAT Scheme', type: 'RSelect', isActive: true,},
  { id: 'rate', label: 'Rate', type: 'number', isActive: true },
  { id: 'actions', label: 'Actions', isActive: true, }
];

const items = [
  { id: 1, start_date: "25/2/2019", end_date: "25/12/2020", vat_scheme: "FR-IB", rate: "10 %" },
  { id: 2, start_date: "05/08/2019", end_date: "05/01/2020", vat_scheme: "IB", rate: "5 %" },
];

const title = "VAT Schemes";

function VATSchemesModal(props) {
  const { show, onClose, confirm, data, toggle, deleteVATScheme, onCurrentRow } = props;
  const [activeColumns, setActiveColumns] = useCheckboxStorage("VATSchemesModal", headRowsVATSchemesModal);
  const [rowsPerPage, onPerPage] = usePerPage();
  const [searchQuery, setSearchQuery] = useState(null);

  const onEdit = (row) => () => {
    onCurrentRow(row);
    toggle();
    onClose();

  };

  const onCreate = () => {
    toggle();
    onClose()
  };

  const onDelete = (row) => (
    confirm(() => {
        // deleteVATScheme({
        //   id: row.id,
        // })
      },
      { description: 'This action is permanent!' })
  );

  const content = items.length > 0
    ? <TableComponent
        headRows={activeColumns.filter(column => column.isActive)}
        // use data
        items={items}
        rowsPerPageSelected={rowsPerPage}
        rowProps={{
          onEdit,
          onDelete,
        }}
      />
    : <EmptyBlock/>;

  return (
    <Modal show={show} onHide={onClose} className="trans-modal">
      <Modal.Header closeButton>
        <TableToolbarComponent
          className="trans-modal__table-toolbar"
          title={title}
          setSearchQuery={setSearchQuery}
          actionsHeader={{
            selectMenu: { setFunc: onPerPage, type: "select" },
            viewColumnMenu: { array: activeColumns, type: "filter", setFunc: setActiveColumns, disabled: true },
          }}
        />
      </Modal.Header>
      <Modal.Body>
        {content}
      </Modal.Body>
      <Modal.Footer>
        <div className="container-button container-button--modal">
          <DButton typeOfButton="add" onClickCustom={onCreate} >Add VAT Scheme</DButton>
          <DButton typeOfButton="close" onClickCustom={onClose} >Close</DButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

VATSchemesModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  // data: PropTypes.array,
  deleteVATScheme: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  onCurrentRow: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default withConfirmHook(VATSchemesModal);
