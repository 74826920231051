import React, { useState } from 'react';
import { Paper, Popper, Fade, Typography, IconButton, Toolbar, AppBar } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import MoreIcon from '@material-ui/icons/MoreVert';
import 'date-fns';
import PropTypes from "prop-types";
import DSearch from "../DSearch/DSearch";
import TableToolbarActions from "./TableToolbarActions";

const useStyles = makeStyles(theme => ({
  growFlex: {
    flexGrow: 1,
    marginTop: 15,
  },
  growFlexIndent: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  margin: {
    margin: theme.spacing(.25),
  },
  box: {
    boxShadow: "none",
    paddingBottom: 10
  },
  formControl: {
    margin: theme.spacing(1.5),
  },
}));

export default function TableToolbarComponent(props) {
  const {
    title,
    subtitle,
    bottomHeader,
    isHideSearch,
    className,
    setSearchQuery,
    datePicker,
    isShowElements,
    actionsHeader
  } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => setAnchorEl(anchorEl ? null : event.currentTarget);

  const searchElement = (isHideSearch ? null : <DSearch setSearchQuery={setSearchQuery}/>);

   const id = !!anchorEl ? 'mobile-popper' : undefined;
  return (
    <div className={"table-toolbar " + classes.growFlex}>
      <AppBar position="static" color="inherit" className={classes.box}>
        <Toolbar className={className}>
          <Typography className={`${className}__title ` + classes.title} variant="h6">
            {title}
            <Typography className={`${className}__title ` + classes.title} variant="body1">
              {subtitle}
            </Typography>
          </Typography>
          {!isShowElements && <>
            <div className={classes.growFlexIndent}/>
            {searchElement}
            {datePicker}
            <div className={"table-toolbar__section-desktop " + classes.sectionDesktop}>
              <TableToolbarActions {...actionsHeader} />
            </div>
            {/*mobile section */}
            <div className={classes.sectionMobile}>
              <IconButton aria-controls={`mobile-menu-${title}`} onClick={handleClick}><MoreIcon/></IconButton>
              <Popper id={id} open={!!anchorEl} anchorEl={anchorEl} transition>
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={100}>
                    <Paper>
                      <TableToolbarActions mobile={true} {...actionsHeader} />
                    </Paper>
                  </Fade>
                )}
              </Popper>
            </div>
          </>}
        </Toolbar>
        {bottomHeader}
      </AppBar>
    </div>
  );
}

TableToolbarComponent.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  bottomHeader: PropTypes.element,
  isHideSearch: PropTypes.bool,
  className: PropTypes.string,
  setSearchQuery: PropTypes.func,
  datePicker: PropTypes.object,
  isShowElements: PropTypes.bool,
};