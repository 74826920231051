import {Snackbar} from "@material-ui/core";
import React from "react";
import { MySnackbarContentWrapper2 } from "./SnackbarsExamplesPage";

const NotificationForm = (props) => {
  const { open, onClose, anchorOrigin, variant="success", message="Update", autoHideDuration=3000, ClickAwayListenerProps } = props;
  const anchor = anchorOrigin ? anchorOrigin : {
    vertical: "bottom",
    horizontal: "left",
  };
  return <Snackbar
    anchorOrigin={anchor ? anchor : { vertical: "bottom", horizontal: "left" }}
    open={open}
    autoHideDuration={autoHideDuration}
    onClose={onClose}
    className={"form__notification form__notification--" + variant}
    ClickAwayListenerProps={ClickAwayListenerProps}
  >
    <MySnackbarContentWrapper2
      onClose={onClose}
      variant={variant}
      message={message}
    />
  </Snackbar>
};
export default NotificationForm;