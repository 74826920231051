import React from "react";
import * as PropTypes from "prop-types";
import TableCell from "@material-ui/core/TableCell";
import { withUnits } from "../../utils";
import classNames from "clsx";

export const textColor = (value, param) => {
  if (param && param < 0) {
    return "var(--color-accent-4)";
  } else if (param) {
    return "var(----color-on-surface)";
  } else if (value < 0) {
    return "var(--color-accent-4)";
  } else {
    return "var(----color-on-surface)";
  }
};

const WarningCell = props => {
  const { name, value, param, units, text, nowrap, lowOpacity, highlight } = props;
  const Content = () => <span style={{ color: textColor(value, param) }}>{withUnits(units, value)}</span>;
  return (
    text
      ? <Content />
      : <TableCell
            align="center"
            className={classNames("table__cell--" + name, {
              "nowrap": nowrap, 'low-opacity': lowOpacity, 'highlight': highlight
            })}
        >
        <Content />
      </TableCell>
  )
};

WarningCell.propTypes = {
  name: PropTypes.string,
  value: PropTypes.number,
  param: PropTypes.number,
  units: PropTypes.string,
  nowrap: PropTypes.bool,
  text: PropTypes.bool,
  isShowTooltip: PropTypes.bool,
  tooltipProps: PropTypes.object,
};

export default WarningCell;
