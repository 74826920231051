import React from "react";
import {connect} from "react-redux";
import * as PropTypes from "prop-types";
import withConfirmHook from "../../../../../hooks/withConfirm.hook";
import TransactionTable from "./TransactionTable";

const IgnoredTab = (props) => {
  const {tabProps: { bankEntryId, bank, getBank, rowsPerPage, activeColumns }} = props;
  return <>
    <TransactionTable
      rowsPerPage={rowsPerPage}
      activeColumns={activeColumns}
      bankEntryId={bankEntryId}
      filters={{ignored: true}}
    />
  </>
};

IgnoredTab.propTypes = {
  getBank: PropTypes.func,
  bank: PropTypes.object,
  // createCustomImportFormat: PropTypes.func.isRequired,
  importFormats: PropTypes.object,
  // loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, props) => {
  return {}
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withConfirmHook(IgnoredTab));