import axios from "axios";
import toast from "../pages/components/toast/toast";
import * as Sentry from "@sentry/browser";
import store from "../store/store";
const API_ROOT = process.env.REACT_APP_API_ENDPOINT;
const API_DEV_ROOT =
  process.env.REACT_APP_API_INVENTORY_ENDPOINT ||
  process.env.REACT_APP_API_ENDPOINT;
axios.defaults.timeout = 180000;

axios.interceptors.request.use(config => {
  config.params = config.params || {};
  config.params["debug_user_id"] = localStorage.getItem("debug_user_id");
  return config;
});

// const createCookieInHour = (cookieName, cookieValue, hourToExpire) => {
//   let date = new Date();
//   date.setTime(date.getTime()+(hourToExpire*60*60*1000));
//   document.cookie = cookieName + " = " + cookieValue + "; expires = " +date.toGMTString();
// };
//
// export const catchAjaxErros = (error) => {
//   console.log(error);
// };

export const onInterceptors = () =>
  axios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      let interceptorError = null;
      const err = error.response;
      if (err) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx

        const { status, data, headers, ...props } = err;
        Sentry.captureException(err);
        console.error("onInterceptors response", err);
        interceptorError = { status, headers, ...props };

        if (status === 401) {
          window.location = "/logout";
        } else if (status > 399 && status < 500) {
          if (status === 400 || (status === 403 && typeof err === "object"))
            if (data && status === 400) {
              // for handling 400 errors from backend
              if (err?.data?.length) {
                let error_text = "";

                for (const key in err.data[0]) {
                  error_text = `${error_text}\n${err.data?.[0]?.[key]}`;
                }
                toast.error(error_text, { duration: 5000 });
              } else {
                toast.error(error, { duration: 5000 });
              }
            } else {
              toast.error(err, { duration: 5000 });
            }
        } else if (status > 499) {
          toast.error("Something went wrong. Please try later", {
            duration: 5000
          });
        }
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.error("error.request ===>>> ", error.request);
        toast.error("Something went wrong. Please try later", {
          duration: 5000
        });
        Sentry.captureException(error.request);
        interceptorError = error.request;
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("onInterceptors", error?.message);
        toast.error("Something went wrong. Please try later", {
          duration: 5000
        });
        Sentry.captureException(error);
        interceptorError = error;
      }

      return {
        ...interceptorError,
        errorText:
          interceptorError.statusText ||
          "Something went wrong. Please try later"
      };
    }
  );

const getHeaders = extraHeaders => {
  // const token = localStorage.getItem("authToken");
  const {
    auth: { authToken }
  } = store.getState();
  return {
    headers: {
      Authorization: `Bearer ${authToken}`, //the token is a variable which holds the token,
      ...extraHeaders
    }
  };
};

export const requests = {
  del: url => axios.delete(`${API_ROOT}${url}/`, getHeaders()),
  get: (url, params = {}) =>
    axios.get(`${API_ROOT}${url}/`, getHeaders(params)),
  // TODO: should be replaced with production endpoint
  getDev: (url, params = {}) =>
    axios.get(`${API_DEV_ROOT}${url}/`, getHeaders(params)),
  put: (url, body) => axios.put(`${API_ROOT}${url}/`, body, getHeaders()),
  patch: (url, body) => axios.patch(`${API_ROOT}${url}/`, body, getHeaders()),
  post: (url, body) => axios.post(`${API_ROOT}${url}/`, body, getHeaders()),
  file: (url, body) =>
    axios.post(
      `${API_ROOT}${url}/`,
      body,
      getHeaders({
        "content-type": "multipart/form-data"
      })
    ),
  filePatch: (url, body) =>
    axios.patch(
      `${API_ROOT}${url}/`,
      body,
      getHeaders({
        "content-type": "multipart/form-data"
      })
    ),
  downloadFile: (url, fileName) => {
    return fetch(
      `${API_ROOT}${url}debug_user_id=${localStorage.getItem(
        "debug_user_id"
      )}&/`,
      {
        headers: getHeaders().headers
      }
    )
      .then(resp => {
        if (resp && resp.status && resp.status === 500) {
          toast.error(`File generation error!`);
          return null;
        }
        return resp.blob();
      })
      .then(blob => {
        if (blob) {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          // the filename you want
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        }
      });
  }
};

export const getCurrentCompanyId = () => {
  return localStorage.getItem("company");
};
/**
 * Fetch company id from local store
 * @param fn
 * @returns {function(...[*]): *}
 */
export const withCompany = fn => {
  return (...args) => {
    const companyId = getCurrentCompanyId();
    return fn(companyId, ...args);
  };
};

export const URLSearchParamsSkipNull = params => {
  if (!params) {
    return;
  }
  const newParams = {};
  Object.entries(params).forEach(([key, value]) => {
    if (value) {
      newParams[key] = value;
    }
  });
  //& at the end is hack to process on the develop server issue with / and the end of string
  return new URLSearchParams(newParams) + "&";
};
