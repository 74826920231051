import { useDispatch, useSelector } from "react-redux";
import * as Selector from "../selectors/product.selectors";
import * as productDuck from "../store/ducks/product.duck";

const useProducts = () => {
  const dispatch = useDispatch();

  const getProducts = (params) => {
    dispatch(productDuck.actions.getProducts(params));
  };

  const addProduct = (args) => {
    dispatch(productDuck.actions.addProduct(args));
  };

  const updateProduct = (args) => {
    dispatch(productDuck.actions.updateProduct(args));
  };

  const deleteProduct = (args) => {
    dispatch(productDuck.actions.deleteProduct(args));
  };

  const getImportsProducts = (params) => {
    dispatch(productDuck.actions.getImportsProducts(params));
  };

  const addImportsProduct = (args) => {
    dispatch(productDuck.actions.addImportsProduct(args));
  };

  const deleteBBP = (args) => {
    dispatch(productDuck.actions.deleteBBP(args));
  };

  const addImportsCOG = (args) => {
    dispatch(productDuck.actions.addImportsCOG(args));
  };

  const updateProductNote = (args) => {
    dispatch(productDuck.actions.updateProductNote(args));
  };
  const updateProductBBPImportStrategy = (args) => {
    dispatch(productDuck.actions.updateProductBBPImportStrategy(args));
  };

  const getReimbursements = (args) => dispatch(productDuck.actions.getReimbursements(args));
  const updateReimbursement = (args) => dispatch(productDuck.actions.updateReimbursement(args));

  // const getAsinHisties = (args) => dispatch(productDuck.actions.getAsinHisties(args));

  const skipProductWarning = (args) => dispatch(productDuck.actions.skipProductWarning(args));
  // const updateAsinHistory = (args) => dispatch(productDuck.actions.updateAsinHistory(args));
  const mergeRestocks = (args) => dispatch(productDuck.actions.mergeRestocks(args));

  const getReconciliation = (args) => dispatch(productDuck.actions.getReconciliation(args));
  const getRaiseCase = (args) => dispatch(productDuck.actions.getRaiseCase(args));
  const updateRaiseCase = (args) => dispatch(productDuck.actions.updateRaiseCase(args));
  const updateReconciliationNote = (args) => dispatch(productDuck.actions.updateReconciliationNote(args));

  const products = Selector.getProducts(useSelector(state => state));
  const imports = Selector.getImportsProducts(useSelector(state => state));
  const noteImportsCOG = Selector.addImportsCOG(useSelector(state => state));
  const reimbursements = Selector.getReimbursementProducts(useSelector(state => state));
  // const asinHistories = Selector.getAsinHistories(useSelector(state => state));
  const reconciliation = Selector.getReconciliation(useSelector(state => state));
  const raiseCase = Selector.getRaiseCase(useSelector(state => state));

  return {
    products: products.details,
    productTotalCount: products.totalCount,
    loading: products.loading,
    getProducts,
    addProduct,
    updateProduct,
    deleteProduct,

    imports: imports.details,
    importsTotalCount: imports.totalCount,
    loadingImports: imports.loading,
    getImportsProducts,
    addImportsProduct,
    deleteBBP,
    addImportsCOG,
    noteImportsCOG,

    updateProductNote,

    skipProductWarning,

    reimbursements: reimbursements.details,
    reimbursementsTotalCount: reimbursements.totalCount,
    loadingReimbursements: reimbursements.loading,
    getReimbursements,
    updateReimbursement,
    // getAsinHisties,
    // updateAsinHistory,
    // asinHistories: asinHistories.details,
    // asinHistoriesTotalCount: asinHistories.totalCount,
    // loadingAsinHistories: asinHistories.loading,
    updateProductBBPImportStrategy,
    mergeRestocks,

    getReconciliation,
    reconciliation: reconciliation.details,
    reconciliationLoading: reconciliation.loading,
    reconciliationTotalCount: reconciliation.totalCount,

    getRaiseCase,
    updateRaiseCase,
    updateReconciliationNote,
    raiseCase: raiseCase.details,
    raiseCaseLoading: raiseCase.loading,
  };
};

export default useProducts;