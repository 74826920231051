import React, { useState } from "react";
import useProducts from "../../../../hooks/products.hook";
import { ReactComponent as MergeIcon } from "../../../../media/icons/merge_icon.svg";
import {
  Grid,
  Dialog,
  DialogActions,
  Button,
  DialogTitle,
  DialogContent,
  FormControl,
  FormLabel,
  FormControlLabel,
  FormGroup,
  Checkbox, IconButton,
} from "@material-ui/core";
import moment from "moment";
import CloseIcon from '@material-ui/icons/Close';

import styles from "./styles.module.scss";
import Typography from "@material-ui/core/Typography";


const TypeReplenishCell = ({ row }) => {
  const [checked, setChecked] = useState("");
  const [fromOrigin, setFromOrigin] = useState("manual");

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(() => false);

  const { mergeRestocks, products } = useProducts({ fetch: false });

  const mainProduct = products.filter((p) =>
    p.restocks.flatMap((rp) => rp.map((rpp) => rpp.id)).includes(row.id)
  )[0];
  const availableToMergeWith = mainProduct.restocks
    .flatMap((r) => r)
    .filter((r) => !r.inbound_shipment);
  const mergeTo = mainProduct.restocks
    .flatMap((r) => r)
    .filter((r) => r.inbound_shipment && !r.merged);

  const getTitle = (row) => {
    const {
      date_added,
      restock,
      relatedProduct,
      otherMarketplace,
      item_name,
    } = row;
    const isReplenish = !!restock && !relatedProduct;
    const isMainRestock = isReplenish && !otherMarketplace;

    return isMainRestock
      ? `Replenish ${moment(date_added).format("Do MMMM YYYY")}`
      : item_name;
  };
  if (!availableToMergeWith.length) {
    return (<></>)
  }

  return (
    <>
      <MergeIcon
        className={"table__cell__img--icon pointer merge-icon " + styles.mergeIcon}
        onClick={() => setOpen(() => true)}
      />

      <Dialog
        className={"replenish-modal " + styles.modalBody}
        maxWidth={false}
        onClose={handleClose}
        scroll="body"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" className={styles.modalTitle} onClose={handleClose}>
          <div className={styles.modalHeader}>
            <Typography className={styles.modalTitle}>Merge restocks</Typography>
            <div className="dialog-title__close-btn">
              <IconButton edge="end" color="inherit" size="small" onClick={handleClose} aria-label="Close">
                <CloseIcon/>
              </IconButton>
            </div>
          </div>
        </DialogTitle>

        <DialogContent className={styles.modalContent} dividers>
          <table className={styles.merge_table}>
            <thead>
            <tr>
              <th width="45px"></th>
              <th width="130px">ASIN:</th>
              <th width="150px">Title:</th>
              <th width="100px">SKU:</th>
              <th className="text-center" width="75px">Stok:</th>
              <th width="100px">Type:</th>
              <th width="30px"></th>

            </tr>
            </thead>
            <tbody>
            {mergeTo.map((restock, index) => (
              <tr key={index}>
                <td></td>
                <td>{restock.asin1}</td>
                <td>{getTitle(restock)}</td>
                <td>{restock.seller_sku}</td>
                <td align="center">{restock.quantity}</td>
                <td>automatic</td>
              </tr >
            ))}
            </tbody>
          </table>

          <p className={styles.merge_text}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.5443 8.456C10.7252 7.63723 9.61445 7.17728 8.4563 7.17728C7.29815 7.17728 6.18741 7.63723 5.3683 8.456L2.2793 11.544C1.46018 12.3631 1 13.4741 1 14.6325C1 15.7909 1.46018 16.9019 2.2793 17.721C3.09842 18.5401 4.20939 19.0003 5.3678 19.0003C6.52621 19.0003 7.63718 18.5401 8.4563 17.721L10.0003 16.177" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M8.45605 11.544C9.27516 12.3628 10.3859 12.8227 11.5441 12.8227C12.7022 12.8227 13.8129 12.3628 14.6321 11.544L17.7211 8.456C18.5402 7.63688 19.0004 6.52591 19.0004 5.3675C19.0004 4.20909 18.5402 3.09812 17.7211 2.279C16.9019 1.45988 15.791 0.999702 14.6326 0.999702C13.4741 0.999702 12.3632 1.45988 11.5441 2.279L10.0001 3.823" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <span>Merge with</span>
          </p>
          <table className={styles.merge_table}> <thead>
          <tr>
            <th width="40px"></th>
            <th width="130px"></th>
            <th width="150px"></th>
            <th width="100px"></th>
            <th width="75px"></th>
            <th width="100px"></th>
            <th width="30px"></th>
          </tr>
          </thead>
            <tbody>
            {availableToMergeWith.map((restock, index) => (
              <tr key={index}>
                <td> <Checkbox
                  className="checkbox"
                  size="medium"
                  checked={checked === restock.id}
                  onChange={() => setChecked((old) => old === restock.id ? "" : restock.id)}
                /></td>
                <td>{restock.asin1}</td>
                <td>{getTitle(restock)}</td>
                <td>{restock.seller_sku}</td>
                <td align="center">{restock.quantity}</td>
                <td>manual</td>
              </tr>

            ))}
            </tbody>
          </table>
        </DialogContent>

        <DialogActions>
          <Grid className={styles.modalFooter}>
            {/* checkboxes to choose from where use quantities */}
            <Grid item>
              <FormControl component="fieldset">
                <FormLabel className={styles.footerLabel} component="label">
                  Get restock quantity from:
                </FormLabel>
                <FormGroup row={true}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        className="checkbox"
                        size="medium"
                        checked={fromOrigin === "manual"}
                        onChange={() => setFromOrigin("manual")}
                      />
                    }
                    label="Manual Replenish"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        className="checkbox"
                        checked={fromOrigin === "automatic"}
                        onChange={() => setFromOrigin("automatic")}
                      />
                    }
                    label="Automatic Replenish"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            {/* merge button send id of selected manual,
                        id of origin auto repl and flag - to give a choose,
                        from where take a stock */}
            <Grid className={styles.footerButton}>
              {/* row.id is automatic replenish id */}
              {/* checked is selected manual replenish id */}
              <Button
                variant="contained"
                size="medium"
                color="primary"
                disabled={!checked}
                onClick={() =>
                  mergeRestocks({
                    id1: row.id,
                    id2: checked,
                    quantity_from: fromOrigin,
                  })
                }
                // onClick={()=> console.log(checked)}
              >
                Merge
              </Button>
            </Grid>
          </Grid>

        </DialogActions>
      </Dialog >
    </>
  );
};

export default TypeReplenishCell;
