import {
  all,
} from 'redux-saga/effects'
import * as TransactionsPage from './transactions.duck';
import * as EnterTransactionsCustomers from './customers.duck';
import * as EnterTransactionsSuppliers from './suppliers.duck';
import {combineReducers} from "redux";

export const reducer = combineReducers({
  transactions: TransactionsPage.reducer,
  customers: EnterTransactionsCustomers.reducer,
  suppliers: EnterTransactionsSuppliers.reducer,
});

export function* saga() {
  yield all([
    TransactionsPage.saga(),
    EnterTransactionsCustomers.saga(),
    EnterTransactionsSuppliers.saga(),
  ]);
}

