import React, { useState } from "react";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import classNames from "clsx";
import * as PropTypes from "prop-types";

const TooltipComponent = (props) => {
  const { children, tooltip, className, placement = "bottom", tooltipStyle, id="tooltip-popper", isBlur } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const onMouseEnter = (event) => {
    const oldAnchor = document.getElementsByClassName("d-tooltip--popper");
    const arr = [...oldAnchor];

    arr?.length && arr.forEach(item => {
      item.firstChild && item.firstChild.remove();
    });
    setAnchorEl(event.currentTarget);
  };

  const onMouseLeave = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const _id = open ? id : undefined;
  return (
    <div className="d-tooltip-component">
      <div
        className="d-tooltip-children"
        aria-describedby={_id}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </div>
      <Popper
        onMouseMove={onMouseLeave}
        id={_id}
        open={!!open}
        anchorEl={anchorEl}
        transition
        placement={placement}
        className={classNames("d-tooltip--popper", className)}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={{ enter: 400, exit: 100 }}>
            <div className={classNames(tooltipStyle, "d-tooltip", { "d-tooltip--empty": !tooltip, "blur": isBlur })}>
              {tooltip}
            </div>
          </Fade>
        )}
      </Popper>
    </div>
  )
};

TooltipComponent.propTypes = {
  className: PropTypes.string,
  tooltipStyle: PropTypes.string,
  placement: PropTypes.string,
};

export default TooltipComponent;
