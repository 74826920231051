import { createSelector } from "reselect";

export const getCategories = createSelector(
  state => state.category,
  (category) => category.categories
);

export const getLoading = createSelector(
  state => state.category,
  (category) => category.loading,
);

export const selectFetched = createSelector(
  state => state.category,
  (category) => category.fetched,
);