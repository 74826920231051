import React from "react";
import { TableCell, Typography } from "@material-ui/core";
import { onCopy } from './utils';
import classNames from "clsx";
import TooltipComponent from "../../components/TooltipComponent";

export const JorneyTitle = ({ shipmentId, asin, sku, onCopy, title, url, createdDate }) => (
  <div className="name-cell">
    <Typography variant="body1" className="table__cell--link__text">
      <span className="no-wrap table__cell--detail__content" onClick={onCopy(title, "Title")}>
        <span className="bold table__cell--detail__title copy-hover">Title: &#32;</span>
        <span className="copy-hover">{title}</span>
      </span>
    </Typography>

    <div className="details">
      <span className="no-wrap table__cell--detail__content" onClick={onCopy(sku, "SKU")}>
          <span className="bold table__cell--detail__title copy-hover">SKU:&#32;</span>
          <span className="copy-hover">{sku}</span>
      </span>

      <span className="no-wrap table__cell--detail__content" onClick={onCopy(asin, "ASIN")}>
          <span className="bold table__cell--detail__title copy-hover">ASIN:&#32;</span>
          <span className="copy-hover">{asin}</span>
      </span>

      <span className="no-wrap table__cell--detail__content" onClick={onCopy(shipmentId, "Shipment ID")}>
          <span className="bold table__cell--detail__title copy-hover">Shipment ID:&#32;</span>
          <span className="copy-hover">{shipmentId}</span>
      </span>

      {createdDate && (
         <span className="no-wrap table__cell--detail__content" onClick={onCopy(createdDate, "Shipment Date")}>
          <span className="bold table__cell--detail__title copy-hover">Shipment Date:&#32;</span>
          <span className="copy-hover">{createdDate}</span>
        </span>
      )}
    </div>
  </div>
);

export const ShippedModalInfo = ({sku, asin, shipmentId, title, createdDate}) => {
  return (
    <>
       <span className="no-wrap table__cell--detail__content" onClick={onCopy(title, "Title")}>
          <span className="bold table__cell--detail__title copy-hover">Title:</span>
          <span className="copy-hover">{title}</span>
      </span>
      <span className="no-wrap table__cell--detail__content" onClick={onCopy(sku, "SKU")}>
        <span className="bold table__cell--detail__title copy-hover">SKU:</span>
        <span className="copy-hover">{sku}</span>
      </span>

      <span className="no-wrap table__cell--detail__content" onClick={onCopy(asin, "ASIN")}>
        <span className="bold table__cell--detail__title copy-hover">ASIN:</span>
        <span className="copy-hover">{asin}</span>
      </span>

      <span className="no-wrap table__cell--detail__content" onClick={onCopy(shipmentId, "Shipment ID")}>
        <span className="bold table__cell--detail__title copy-hover">Shipment ID:</span>
        <span className="copy-hover">{shipmentId}</span>
      </span>

      <span className="no-wrap table__cell--detail__content" onClick={onCopy(createdDate, "Shipment Date")}>
        <span className="bold table__cell--detail__title copy-hover">Shipment Date:</span>
        <span className="copy-hover">{createdDate}</span>
      </span>
    </>
  )
}


const ShipmentTitle = ({ shipmentId,  onCopy, createdDate, title }) => {
  return (
    <div className="name-cell">
    <Typography variant="body1" className="table__cell--link__text">
        <span className="no-wrap table__cell--detail__content" onClick={onCopy(title, "Title")}>
            <span className="bold table__cell--detail__title copy-hover">Title: &#32;</span>
            <span className="copy-hover">{title}</span>
        </span>
    </Typography>
      <Typography variant="body1" className="table__cell--link__text">
            <span className="no-wrap table__cell--detail__content" onClick={onCopy(shipmentId, "Shipment ID")}>
                <span className="bold table__cell--detail__title copy-hover">Shipment ID:&#32;</span>
                <span className="copy-hover">{shipmentId}</span>
            </span>
      </Typography>
      <span className="no-wrap table__cell--detail__content" onClick={onCopy(createdDate, "Shipment Date")}>
            <span className="bold table__cell--detail__title copy-hover">Shipment Date:&#32;</span>
            <span className="copy-hover">{createdDate}</span>
        </span>
        <TooltipComponent tooltipStyle="tab-table__number__btn">
          <span className="table__cell--detail__content vat">
            automatic replenish
          </span>
        </TooltipComponent>
    </div>
  )
};


const NameCell = ({ asin, sku, url, shipmentId, title, inner, isPrimary, createdDate }) => {
  return (
    <TableCell className={classNames({ 'no-border': inner })}>
        {
          isPrimary ?  (
            <JorneyTitle shipmentId={shipmentId} asin={asin} sku={sku} url={url} title={title} onCopy={onCopy} createdDate={createdDate} />
          ) : (
            <ShipmentTitle shipmentId={shipmentId} title={title} onCopy={onCopy} createdDate={createdDate} />
          )
        }
    </TableCell>
  )
};

export default NameCell;
