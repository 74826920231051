import React from "react";
import IconButton from "@material-ui/core/IconButton";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import * as PropTypes from "prop-types";
// import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import { createTheme } from "@material-ui/core/styles";
import {amber} from "@material-ui/core/colors";

const theme = createTheme({
  palette: {
    primary: amber,
  },
});


export const ActionButtonsNewBankModal = ({ addNewRow, deleteTransaction }) => (
  <div className="action-buttons-table new-bank">

    <IconButton color="primary" onClick={addNewRow}>
      <AddIcon/>
    </IconButton>

    <ThemeProvider theme={theme}>
      <IconButton
        color="secondary"
        onClick={deleteTransaction}
      >
        <RemoveIcon/>
      </IconButton>
    </ThemeProvider>
  </div>
);

ActionButtonsNewBankModal.propTypes = {
  addNewRow: PropTypes.func.isRequired,
  deleteTransaction: PropTypes.func.isRequired,
};
