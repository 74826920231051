import React, { useState } from "react";
import * as PropTypes from "prop-types";
import { getChartLegend } from "./chartElement/elements";
import {
  Portlet,
  PortletBody,
  PortletHeader
} from "../../../../partials/content/Portlet";
import ToolbarChart from "./ToolbarChart";
import DashboardChartNote from "./DashboardChartNote";
import DashboardChart from "./DashboardChart";
import ChartTime from "./ChartTime";
import useUsers from "../../../../hooks/users.hook";
import { currencySymbols } from "../../utils";
import Typography from "@material-ui/core/Typography";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { Themes } from "../../../../types/enums";
import {
  chartContentDash,
  colorsDark,
  colorsLight,
  dateOptions
} from "./dataChart";

const emails = ["emma@blue-glow.co.uk"];

const ChartSection = ({
  chart,
  time,
  onSelectedPeriod,
  onCustomPeriod,
  onSelectedMarketplace,
  isShowCalendar,
  haveInvalidProfit,
  haveInvalidSales,
  activeMarketplaces,
  dashboardTilesData,
  expenses
}) => {
  const { currentTheme } = useThemeSwitcher();
  const [checked, setChecked] = useState(["Sales", "Units Sold"]);
  const [isCompare, setCompare] = useState(true);
  const { user } = useUsers({ fetch: false });

  const isDark = currentTheme === Themes.dark;
  const colors = isDark ? colorsDark : colorsLight;
  const colorDash = isDark ? "#1B1F21" : "#ffffff";

  const onCompare = value => {
    setCompare(value.target.checked);
  };

  const onSelectedOverlay = (value, remove) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      const removeItemIndex = checked.indexOf(remove);
      if (removeItemIndex !== -1) {
        newChecked.splice(removeItemIndex, 1);
      }
      newChecked.push(value);
    } else {
      if (newChecked.length > 1) {
        newChecked.splice(currentIndex, 1);
      }
    }
    setChecked(newChecked);
  };

  const chartLegend = getChartLegend({
    ...expenses,
    ...chart,
    chart_data: {...chart.chart_data, ...expenses.chart_data},
    ...dashboardTilesData
  }, currencySymbols[user.currency], colors, !emails?.includes(user.email));

  return (
    <>
      <div className="row mb-3 align-items-center">
        <Typography variant="h4" className="col col-xs-12 col-lg-3 page-title">
          Dashboard
        </Typography>
        <ChartTime
          className="col-xs-12 col-lg-9 d-flex justify-content-end"
          onSelectedPeriod={onSelectedPeriod}
          time={time}
          onCustomPeriod={onCustomPeriod}
          isShowCalendar={isShowCalendar}
          dateOptions={dateOptions}
        />
      </div>
      {/*<div className="row">*/}
      <div className="row row-full-height dash__chart-note">
        <DashboardChartNote
          chartLegend={chartLegend}
          onSelectedOverlay={onSelectedOverlay}
          checked={checked}
          subtitle={time.subtitle}
          haveInvalidProfit={haveInvalidProfit}
          haveInvalidSales={haveInvalidSales}
          hasInventoryCostWarning={dashboardTilesData.inventory_cost_warning}
          inventoryQuantity={dashboardTilesData.inventory_quantity}
          time={time}
        />
      </div>
      {/*</div>*/}

      <div className="row">
        <Portlet fluidHeight className="dashboard__chart">
          <PortletHeader
            title={
              <Typography variant="h6" className="block-title">
                Overview
              </Typography>
            }
            toolbar={
              <ToolbarChart
                isCompare={isCompare}
                onCompare={onCompare}
                onSelectedMarketplace={onSelectedMarketplace}
                time={time.label}
                activeMarketplaces={activeMarketplaces}
              />
            }
          />
          <PortletBody>
            <DashboardChart
              chartContent={{ ...chart, ...chartContentDash }}
              checked={checked}
              isCompare={isCompare}
              time={time.label}
              colors={colors}
              colorDash={colorDash}
              isDark={isDark}
            />
          </PortletBody>
        </Portlet>
      </div>
    </>
  );
};

ChartSection.propTypes = {
  onSelectedPeriod: PropTypes.func,
  time: PropTypes.object,
  chart: PropTypes.object,
  onCustomPeriod: PropTypes.func,
  onSelectedMarketplace: PropTypes.func,
  isShowCalendar: PropTypes.bool,
  haveInvalidProfit: PropTypes.bool,
  haveInvalidSales: PropTypes.bool,
  dashboardTilesData: PropTypes.object,
  expenses: PropTypes.object,
};

export default ChartSection;
