import React from "react";
import IconButton from "@material-ui/core/IconButton";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import DeleteIcon from '@material-ui/icons/Delete';
// import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import { createTheme } from "@material-ui/core/styles";
import { amber } from "@material-ui/core/colors";
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Tooltip from "@material-ui/core/Tooltip";
import LineWeightIcon from '@material-ui/icons/LineWeight';

const theme = createTheme({
  palette: {
    primary: amber,
  },
});

export const ActionButtonsImportTransactions = ({ rowIndex, onDelete, onSplit, onReceiptUploads, addNewBankRule, editBankRule, row }) => {

  return (
    <div className="action-buttons-table new-bank">

      <ThemeProvider theme={theme}>
        <IconButton color="secondary" onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </ThemeProvider>

      <ThemeProvider theme={theme}>
        <IconButton color="primary" onClick={onSplit}>
          <CallSplitIcon/>
        </IconButton>
      </ThemeProvider>

      <IconButton color="secondary" onClick={onReceiptUploads}>
        <AttachFileIcon />
      </IconButton>

      {
        row.bank_rule && (
          <Tooltip title="Matching bank rule" aria-label="Matching bank rule">
            <ThemeProvider theme={theme}>
              <IconButton color="primary" onClick={editBankRule}>
                <LineWeightIcon/>
              </IconButton>
            </ThemeProvider>
          </Tooltip>
        )
      }

      <Tooltip title="Add bank rule" aria-label="Add bank rule">
        <IconButton color="primary" onClick={addNewBankRule}>
          <AccountBalanceIcon />
        </IconButton>
      </Tooltip>
    </div>
  )
};

ActionButtonsImportTransactions.propTypes = {
  // addNewRow: PropTypes.func.isRequired,
  // deleteTransaction: PropTypes.func.isRequired,
  // onSplit: PropTypes.func.isRequired,
  // onReceiptUploads: PropTypes.func.isRequired,
};

export default ActionButtonsImportTransactions;