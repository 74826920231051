import React, { useEffect, useRef, useState } from "react";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import { Typography, Switch } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import * as PropTypes from "prop-types";
import { round } from "../../Transaction/utils";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import RemoveIcon from "@material-ui/icons/Remove";
import OverlayComponent from "../../../components/OverlayComponent";
import { withUnits } from "../../utils";
import { useHistory } from "react-router-dom";
import { ReactComponent as WarningIcon } from "../../../../media/icons/warning.svg";
import InfoIcon from "@material-ui/icons/Info";
import moment from "moment";
import classNames from "classnames";
import QuickStatsChart from "../../../../widgets/QuickStatsChart";
import Flag from "../components/Flag";

const GeneralText = () => (
  <>
    <Typography>
      Choose which period to view by selecting from the options below (Today,
      Yesterday etc…) and use the toggle to view visually on the chart.
    </Typography>
    <Typography>
      As well as displaying a comparison for the same period previously, SKUify
      will also show you the increase or decrease by displaying an arrow and a
      %.
    </Typography>
  </>
);

const varTooltip = {
  help: <GeneralText />,
  roiHelp: (
    <>
      <Typography>ROI = Return On Investment</Typography>
      <GeneralText />
    </>
  ),
  marginHelp: (
    <>
      <Typography>Margin = Profit Margin</Typography>
      <GeneralText />
    </>
  ),
  inventoryCostHelp: (
    <>
      <Typography>
        Total Inventory Cost - This value is the current cost of all the inventory
        within SKUify that has a COG attached
      </Typography>
    </>
  ),
  resaleValueHelp: (
    <>
      <Typography>
        Resale Value - This is the value you could achieve at the current sell
        price of all your inventory
      </Typography>
    </>
  ),
  completeCogs: (
    <Typography>Please complete COGs to enable accurate calculation</Typography>
  ),
  pendingOrder: (
    <Typography>
      The Sales figure may be incorrect due to pending sales. Please check back
      later.
    </Typography>
  ),
  inventoryCostWarning: (
    <Typography>
      There are some Unit Costs missing, please click the Icon to go to the
      correct tab to enter the missing costs.
    </Typography>
  )
};

withUnits.propTypes = {
  units: PropTypes.string,
  number: PropTypes.number
};

const Trend = ({ trend, arrow }) => {
  if (trend) {
    return (
      <Typography
        variant="subtitle2"
        className="note-chart__footer--text footer__trend"
      >
        {arrow}
        {trend}%
      </Typography>
    );
  } else {
    return (
      <Typography
        variant="subtitle2"
        className="note-chart__footer--text footer__trend"
      >
        <RemoveIcon className="footer__trend--stably" />
        0%
      </Typography>
    );
  }
};

Trend.propTypes = {
  arrow: PropTypes.element,
  trend: PropTypes.number
};

const Subtitle = ({ subtitle, arrow, trend, prevWrap }) => {
  let trimPrevWrap = null;
  if (prevWrap) trimPrevWrap = prevWrap.split(" ").join("");
  if (subtitle) {
    return (
      <>
        <div className="footer__wrap--trend">
          <Trend arrow={arrow} trend={trend} />
        </div>
        <Typography
          variant="subtitle2"
          className="note-chart__footer--text footer__subtitle"
        >
          <span className="note-chart__footer--text note__chart__prev thin">
            {subtitle}
          </span>
          <span className="note-chart__footer--text note__chart__prev">
            {trimPrevWrap || prevWrap}
          </span>
        </Typography>
      </>
    );
  }
  return null;
};

Subtitle.propTypes = {
  subtitle: PropTypes.string,
  prevWrap: PropTypes.string,
  arrow: PropTypes.element,
  trend: PropTypes.number
};

const handleResize = ({ refContainer, refText, setFontIsBigger }) => () => {
  if (refContainer.current?.offsetWidth < refText.current?.offsetWidth) {
    setFontIsBigger(true);
  }
};

const DashboardChartCard = props => {
  const {
    name,
    style,
    balance,
    prev,
    type_number,
    recurring,
    units,
    tooltip,
    url,
    url_line,
    width,
    checked,
    onSelectedOverlay,
    subtitle,
    haveInvalidProfit,
    haveInvalidSales,
    showFilteredProductsByInventoryCost,
    fontIsBigger,
    setFontIsBigger,
    inventoryCostWarningClick,
    hasInventoryCostWarning,
    inventoryQuantity,
    chart_info,
    labels,
    time
  } = props;
  const labelId = `checkbox-list-label-${name}`;
  let trend;

  if (prev === recurring) {
    trend = null;
  } else if (prev && recurring) {
    trend = Math.abs(round(((recurring - prev) / prev) * 100));
  } else if (prev || recurring) {
    trend = 100;
  } else {
    trend = null;
  }
  // trend = isFinite(1000/trend) ? 0 : trend;

  let arrow = null;
  if (recurring - prev > 0) {
    arrow = <ArrowUpwardIcon color="secondary" className="up" />;
  } else if (prev - recurring > 0) {
    arrow = <ArrowDownwardIcon color="error" className="down" />;
  }

  let prevWrap = withUnits(units, prev, type_number);

  const empty = !subtitle && "note-chart__footer--empty";
  const value = units
    ? withUnits(units, balance)
    : withUnits(undefined, balance, "integer");
  const shouldShowGoToProducts =
    ["Profit", "ROI", "Margin"].includes(name) && haveInvalidProfit;
  const shouldShowGoToOrder = ["Sales"].includes(name) && haveInvalidSales;
  const goToFilteredInventoryProps = shouldShowGoToProducts
    ? {
        onClick: showFilteredProductsByInventoryCost,
        className: "cursor-pointer"
      }
    : {};

  const isInventory = name === "Total Inventory Cost";
  const isExpenses = name === "Expenses";
  let calculationInProgress = false;
  const REFRESH_TIMEOUT = 3000;

  const WaitingClock = () => (<Flag
    marketplaceCode={""}
    className="table__cell__flag"
    classNameTooltip="cell__flag--city"
    tooltip={"Awaiting refreshing data"}
  />)

  if (isExpenses) {
    calculationInProgress = props?.calculationInProgress;
    calculationInProgress = !!localStorage.getItem('isUpdatingExpenses')
  }

  useEffect(() => {
    let timeoutId;
  
    if (calculationInProgress) {
      timeoutId = setTimeout(() => {
        localStorage.setItem('isUpdatingExpenses', '');
        }, REFRESH_TIMEOUT);
    }
      return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
        if (typeof window !== undefined) {
          window.location.reload()
        }
      }
    };
  }, [calculationInProgress]);
  

  let showCheckbox = true;
  if (isInventory || name === "Resale Value") {
    showCheckbox = false;
  }

  let currentDate = `As of ${moment().format("Do MMMM HH:mm")}`;
  let resaleValueExtraSpan;

  useEffect(() => {
    if (!showCheckbox) {
      window.addEventListener(
        "load",
        handleResize({ refContainer, refText, setFontIsBigger })
      );
      return _ => {
        window.removeEventListener(
          "load",
          handleResize({ refContainer, refText, setFontIsBigger })
        );
      };
    }
  });
  useEffect(() => {
    if (!showCheckbox) {
      window.addEventListener(
        "resize",
        handleResize({ refContainer, refText, setFontIsBigger })
      );
      return _ => {
        window.removeEventListener(
          "resize",
          handleResize({ refContainer, refText, setFontIsBigger })
        );
      };
    }
  });

  const refContainer = useRef(null);
  const refText = useRef(null);
  let showInventoryCostWarning = false;
  if (isInventory) {
    showInventoryCostWarning = hasInventoryCostWarning;
  }

  if (name === "Resale Value") {
    resaleValueExtraSpan = "At your current sell price";
  }

  let tooltipValue = '';
  if (shouldShowGoToOrder === true) {
    tooltipValue = varTooltip.pendingOrder;
  } else if (showInventoryCostWarning === true) {
    tooltipValue = varTooltip.inventoryCostWarning;
  } else {
    tooltipValue = varTooltip[tooltip];
  }
  // nicks reminder: this was the original code
  //shouldShowGoToOrder ? varTooltip.pendingOrder : varTooltip[tooltip]

  return (
    <div className="col-sm-6 col-md-4 col-lg-3">
      {/*<div className={width === 1 ? `col-12 col-sm-4 col-md-4 col-lg-6 col-xl-6` : `col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4`}>*/}
      <Portlet
        ref={refContainer}
        className={classNames("kt-portlet__coll--full-height")}
      >
        <PortletBody
          className="kt-portlet__body--note"
          onClick={onSelectedOverlay(name)}
        >
          <div className="note-chart__header">
            <img
              alt="img"
              src={url}
              // className={classNames({'hide-opacity': !chart_info.length})}
            />
            <div
              style={{
                gap: "8px"
              }}
              className="flex"
            >
              {calculationInProgress && <WaitingClock />}
              {!!chart_info.length && (
              <div>
                {(isInventory || isExpenses) && <p className="note-chart__label">beta</p>}
                <Switch
                  className="d-switch note-chart__switch"
                  // onChange={onSelectedOverlay(name)}
                  checked={checked.indexOf(name) !== -1}
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </div>
              )}
            </div>
          </div>

          <QuickStatsChart
            data={chart_info}
            color={style.palette.primary.main}
            border={3}
            labels={labels}
          />

          <div className="note-chart__content">
            <div
              className={`item__text--wrapper ${goToFilteredInventoryProps.className}`}
              onClick={goToFilteredInventoryProps.onClick}
            >
              <Typography
                variant="h5"
                // color="primary"
                className={`item__text--header dashboard-item__text-header`}
                style={{ whiteSpace: "nowrap" }}
              >
                {name}
                {/*{(shouldShowGoToOrder || showInventoryCostWarning) && (*/}
                {/*<OverlayComponent tooltip={shouldShowGoToProducts*/}
                {/*? varTooltip.completeCogs*/}
                {/*: varTooltip[tooltip]} className="note-chart__tooltip">*/}
                {/*<InfoIcon*/}
                {/*className="cursor-pointer tooltip-info"*/}
                {/*fontSize="small"*/}
                {/*onClick={showInventoryCostWarning ? inventoryCostWarningClick : null}*/}
                {/*/>*/}
                {/*</OverlayComponent>*/}
                {/*)}*/}
              </Typography>
              {shouldShowGoToProducts && (
                <OverlayComponent
                  tooltip="No COGs"
                  className="note-chart__tooltip"
                >
                  <WarningIcon className="item__text--warning" />
                </OverlayComponent>
              )}
            </div>
            <OverlayComponent
              tooltip={tooltipValue}
              className="note-chart__tooltip"
            >
              <div className="sales-value--wrapper">
                <Typography
                  ref={refText}
                  variant="h4"
                  className={classNames("item__text--body", {
                    "small-text-item__text--body": fontIsBigger
                  })}
                >
                  {calculationInProgress ? "-" : value}
                </Typography>
              </div>
            </OverlayComponent>

            {showCheckbox ? (
              <div className={classNames("note-chart__footer", empty)}>
                {/*<div className={"note-chart__footer " + empty}>*/}
                {!!chart_info?.length &&
                  <Subtitle
                    subtitle={subtitle}
                    trend={calculationInProgress ? null : trend}
                    arrow={calculationInProgress ? "-" : arrow}
                    prevWrap={calculationInProgress ? "-" : prevWrap}
                  />
                }
              </div>
            ) : (
              <div className="dashboard-card_date_block note-chart__footer">
                <Grid
                  container
                  spacing={1}
                  align="center"
                  justifyContent="center"
                >
                  {isInventory ? (
                    !!inventoryQuantity && (
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle2"
                          className="dashboard-item__text-subtitle"
                        >
                          Inventory in Stock:{" "}
                          <span className="bold">{inventoryQuantity}</span> units
                        </Typography>
                      </Grid>
                    )
                  ) : (
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle2"
                        className="dashboard-item__text-subtitle"
                      >
                        {resaleValueExtraSpan}
                      </Typography>
                    </Grid>
                  )}
                  {time?.period === "today" && (
                    <Grid item xs={12}>
                      <span className="bold">{currentDate}</span>
                    </Grid>
                  )}
                </Grid>
              </div>
            )}
          </div>
        </PortletBody>
      </Portlet>
    </div>
  );
};

const DashboardChartNote = props => {
  const {
    chartLegend,
    checked,
    onSelectedOverlay,
    subtitle,
    haveInvalidProfit,
    haveInvalidSales,
    hasInventoryCostWarning,
    inventoryQuantity,
    time
  } = props;
  const history = useHistory();
  const showFilteredProductsByInventoryCost = () =>
    history.push("/products", {
      no_inventory_cost: true,
      stock_or_inactive: true
    });
  const [fontIsBigger, setFontIsBigger] = useState(false);
  const inventoryCostWarningClick = () => {
    history.push("/products", {
      no_inventory_cost: true,
      stock_or_inactive: false
    });
  };

  return chartLegend.map(legend => {
    let props = {
      ...legend,
      key: legend.name,
      checked,
      onSelectedOverlay,
      subtitle,
      haveInvalidProfit,
      haveInvalidSales,
      showFilteredProductsByInventoryCost,
      fontIsBigger,
      setFontIsBigger,
      inventoryCostWarningClick,
      hasInventoryCostWarning,
      inventoryQuantity,
      time,
    };
    return <DashboardChartCard {...props} />;
  });
};

DashboardChartNote.propTypes = {
  chartLegend: PropTypes.array,
  checked: PropTypes.array,
  onSelectedOverlay: PropTypes.func,
  subtitle: PropTypes.string,
  chart: PropTypes.element,
  time: PropTypes.object,
};

export default DashboardChartNote;
