import React from "react";
import {FormControl, FormHelperText, Input, InputLabel} from "@material-ui/core";

const DefaultField = allProps => {
  const {
    name,
    setFieldValue,
    setFieldTouched,
    placeholder,
    label,
    meta,
    showLabel,
    customProps = {},
    submitCount,
    autofocus,
    thousandSeparator,
    decimalSeparator,
    fixedDecimalScale,
    customInput,
    ...props
  } = allProps;

  const {placeholder: _placeholder, noLabel, ...otherProps} = customProps;

  return (
    <FormControl
      error={!!(meta && (meta.touched || submitCount) && meta.error)}
      fullWidth
      margin="dense"
    >
      {!noLabel && <InputLabel htmlFor="component-error" margin="dense">{label}</InputLabel>}
      <Input
        id={name}
        name={name}
        type="text"
        label={label}
        placeholder={_placeholder ? _placeholder : placeholder}
        autoFocus={autofocus}
        aria-describedby="component-error-text"
        {...props}
        value={props.value || ''}
        {...otherProps}
      />
      {meta && (meta.touched || submitCount) && meta.error && (
        <FormHelperText error id="component-error-text">
          {meta.error}
        </FormHelperText>
      )}
    </FormControl>
  )
};

export default DefaultField;