import React from "react";
import moment from "moment";
import {IconButton} from "@material-ui/core";
import Form from "react-bootstrap/Form";
import {floatNumber, momentFormatOptions, numberWithCommas} from "../../utils";
import toast from "../../../components/toast/toast";
import FormComponent from "../../../components/controls/FormComponent";
import {generalGOGSInterface} from "../../Products/utils"
import OverlayComponent from "../../../components/OverlayComponent";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {ReactComponent as RemoveIcon} from "../../../../media/icons/remove-without-border.svg";
import {ReactComponent as AddIcon} from "../../../../media/icons/add-without-border.svg";

export const types = {
  "fbm": "fba",
  "mf": "fbm",
  "sfp": "sfp",
};

const keysRefactoring = generalGOGSInterface.map(({id}) => id);

export const dataRefactoring = (data, funcRefactoring, formatItems) => {
  let dataItems = [];
  if (data) {
    dataItems = Object.entries(data);
  }
  let _keysRefactoring = keysRefactoring;
  if (formatItems) {
    _keysRefactoring = formatItems;
  }
  const filterData = dataItems.filter(item => _keysRefactoring.includes(item[0]));
  const restData = dataItems.filter(item => !_keysRefactoring.includes(item[0]));

  const refItems = filterData
    .map(filterItem => filterItem
      .map((item, index) => {
        if (index === 1) {
          return funcRefactoring(item)
        }
        return item
      })
    );
  return Object.fromEntries([...refItems, ...restData]);
};

export const noteField = "Exclusive of VAT";

export const interfaceArray = (currency, dataName) => [
  {
    id: "title_line",
    label: "Item",
    render: props => {
      const {extraProps, values} = props;
      const value = values[dataName]?.[extraProps.index];
      const infoIcon = lineTooltip[value.name] && <InfoOutlinedIcon fontSize="small" className="info-icon"/>;

      return (
        <OverlayComponent
          tooltip={lineTooltip[value.name]}
        >
          <Form.Label className="form-group__label-item name-field" column={false}>
            {value.label}
            {infoIcon}
          </Form.Label>
        </OverlayComponent>
      )
    }
  },
  {
    id: "move",
    label: "Move",
    render: props => {
      const {extraProps, values} = props;
      const value = values[dataName]?.[extraProps.index];
      return extraProps.actions({index: value.name, collapsed: value.parent})
    }
  },
  {id: "start_date", label: "Start Date", type: "dateRB"},
  {
    id: "end_date",
    label: "End Date",
    type: "dateRB",
    render: ({ customProps, ...props }) => {
      const { setFieldValue, name, extraProps, values } = props;
      const onChange = (date, picker) => {
        const startDate = values[dataName]?.[extraProps.index]?.start_date;
        if (startDate && startDate < date) {
          setFieldValue(name, date);
          picker.element.val(moment(date).format(momentFormatOptions.display));
        } else {
          toast.error("Wrong End Date!", { duration: 3000 });
          setFieldValue(name, null);
          picker.element.val(null);
        }
      };

      return (
        <FormComponent customProps={{ onChange }} {...props} />
      )
    }
  },
  { id: "value", label: "Amount", type: "RNumber",
    customProps: (other) => ({ values, extraProps }) => {
      const isNote = values[dataName][extraProps.index].is_vat_charged;
      return { noteField: isNote && noteField, ...other}
    }
  },
  { id: "is_vat_charged", label: "VAT", type: "switch" },
  {
    id: "actions",
    label: "Add/Delete",
    render: props => {
      const {extraProps, values} = props;
      const {index, disabled, arrayHelpers, onAddDelete} = extraProps;
      const value = values[dataName]?.[index];
      return disabled
        ? (
          <div className="wrap-btn">
            <IconButton className="settings__btn empty"/>
          </div>
        )
        : !!value.parent
          ? (
            <div className="wrap-btn">
              <IconButton
                className="cog-settings__btn settings__btn btn--delete"
                onClick={onAddDelete({arrayHelpers, index})}
                size="medium"
              >
                <RemoveIcon/>
              </IconButton>
            </div>
          )
          : (
            <div className="wrap-btn">
              <IconButton
                className="cog-settings__btn settings__btn btn--add"
                onClick={onAddDelete({arrayHelpers, index, values: value})}
                size="medium"
              >
                <AddIcon/>
              </IconButton>
            </div>
          )
    }
  },
];

export const init = {
  label: null,
  id: null,
  name: null,
  is_vat_charged: false,
  start_date: null,
  end_date: null,
  value: null,
};


export const onSubmit = (props) => (values, { setSubmitting, resetForm }) => {
  const { dataName, setIsShowNotification, updateSetting, currentTab } = props;
  const data = values[dataName];
  const formatData = data.map(({ collapsed, value, ...line }) => ({
    ...line,
    value: floatNumber(value),
  }));
  const filterOptions = ["id", "start_date", "end_date", "value", "is_vat_charged"];
  const dataToSend = formatData.filter(line => filterOptions.some(option => !!line[option]));

  const onDone = () => {
    setSubmitting(false);
    resetForm();
    setIsShowNotification(true)
  };
  const onError = (error) => {
    toast.error(error);
    setSubmitting(false);
  };

  updateSetting({
    values: {
      settings_type: currentTab,
      items: dataToSend,
    },
    type: currentTab,
    onDone,
    onError,
  });
};

export const addNewLine = (arrayHelpers, index, values) => {
  const { created_at, end_date, ...props } = values;
  const endDate = end_date ? end_date : moment().format('YYYY-MM-DD');
  const prevLine = {...init, ...props, end_date: endDate, parent: props.label,  };
  const newLine = {
    ...init,
    start_date: moment(prevLine.end_date).add(1, "day").format('YYYY-MM-DD'),
    label: values.label,
    name: values.name,
    id: null,
  };
  arrayHelpers.insert(index, prevLine);
  arrayHelpers.replace(index + 1, newLine);
};

const onDeleteLine = (arrayHelpers, index) => {
  arrayHelpers.remove(index);
};

export const onAddDelete = (props) => () => {
  const {
    arrayHelpers,
    index,
    values,
    expandId,
    setExpandId,
    setWithHistory,
    withHistory,
    withNewLine,
    setWithNewLine
  } = props;
  let addValue = values;

  if (values) {
    if (!addValue.end_date) {
      toast.error("End Date is required!");
      return;
    }
    // expand the line to which the extra line is added
    !expandId.some(item => item === values.name) && setExpandId(old => [...old, values.name]);

    // write the label of the line to which the extra line is added (to activate the expand button)
    !withHistory.some(item => item === values.name) && setWithHistory(old => [...old, values.name]);

    if (!withNewLine.some(item => item === values.name)) {
      setWithNewLine(old => [...old, values.name]);
    } else {
      // remove parent line id if parent line is new
      addValue = {...addValue, id: null}
    }
    addNewLine(arrayHelpers, index, addValue);
  } else {
    const typeData = arrayHelpers.form.values[arrayHelpers.name];
    const deletedLine = typeData[index];
    const nameDeletedLine = deletedLine.name;
    const sameNameLines = typeData.filter(({ name }) => name === nameDeletedLine);

    if (sameNameLines.length < 3) {
      // disabled icon
      const historyItems = withHistory.filter(item => item !== nameDeletedLine);
      // display collapsed icon
      const expandItems = expandId.filter(item => item !== nameDeletedLine);
      setWithHistory(historyItems);
      setExpandId(expandItems);
    }
    onDeleteLine(arrayHelpers, index);
  }
};

export const refactorTabData = ({ initCOG, withHistory, setWithHistory, setTabData, dataName, items }) => {
  const sort = initCOG.reduce((result, { label, name }) =>
    [...result, ...items.filter(item => item.name === name).map(item => ({...item, label}))],
    []
  );

  const names = sort.map(({ name }) => name);
  const lastItemIndex = initCOG.map(cogInit => (
    {
      name: cogInit.name,
      lastIndex: names.lastIndexOf(cogInit.name),
    }
  ));

  const data = sort.map((item, index) => {
    const itemFormatValue = {...item, value: numberWithCommas(item.value) };
    delete itemFormatValue.parent;
    const isLast = lastItemIndex.some(({ lastIndex, name }) => lastIndex === index && item.name === name);
    if (isLast) {
      return itemFormatValue
    } else {
      !withHistory.some(name => name === item.name) && setWithHistory(old => [...old, item.name]);
      return { ...itemFormatValue, parent: item.name }
    }
  });
  // form with initial data
  setTabData({ [`${dataName}`]: data});
};

export const fbaDisabledFields = ['vat_free_postage'];

export const mfDisabledFields = ['inbound_shipping'];

export const lineTooltip = {
  vat_free_postage: 'Some domestic and international postage costs are VAT Free. Please check with your distribution partner',
  postage: 'Please enter any associated postage fees you want to be calculated',
};

export const notRenderCogs = ['inbound_shipping_units'];
