import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects'
import api from "../../crud/company.crud";
import {createSlice} from "@reduxjs/toolkit";

const company = createSlice({
    name: 'company__officers',
    initialState: {
      loading: true,
      officers: [],
    },
    reducers: {
      stopLoading: (state, actions) => {
        state.loading = false;
        return state;
      },
      getOfficers: (state, actions) => {
        state.loading = true;
        return state;
      },
      getOfficersSuccess: (state, actions) => {
        const {officers} = actions.payload;
        state.officers = officers;
        state.loading = false;
        return state;
      },
      addOfficer: (state, actions) => {
        state.loading = true;
        return state;
      },
      addOfficerSuccess: (state, actions) => {
        const {officer} = actions.payload;
        state.officers = [...state.officers, officer];
        state.loading = false;
        return state;
      },
      deleteOfficer: (state, actions) => {
        state.loading = true;
        return state;
      },
      deleteOfficerSuccess: (state, actions) => {
        const {id} = actions.payload;
        state.officers = state.officers.filter((officer) => (officer.id !== id));
        state.loading = false;
        return state;
      },
      updateOfficer: (state, actions) => {
        state.loading = true;
        return state;
      },
      updateOfficerSuccess: (state, actions) => {
        state.officers = state.officers.map((officer) => {
          if (officer.id === actions.payload.id) {
            return actions.payload.officer;
          }
          return officer
        });
        state.loading = false;
        return state;
      },
    },
  }
);

export const actions = company.actions;
export const reducer = company.reducer;

export function* saga() {
  yield takeLatest(actions.getOfficers, function* getOfficersSaga() {
    try {
      const { data } = yield call(api.getOfficers);
      data
        ? yield put(actions.getOfficersSuccess({ officers: data }))
        : yield put(actions.stopLoading());
    } catch (err) {
      yield put(actions.stopLoading());
      console.log(err);
    }
  });

  yield takeLatest(actions.addOfficer, function* addOfficerSaga({ payload: { values, onDone } }) {
    try {
      const { data } = yield call(api.addOfficer, values);
      if (data) {
        yield put(actions.addOfficerSuccess({ officer: data }));
        onDone();
      } else {
        yield put(actions.stopLoading())
      }
    } catch (err) {
      yield put(actions.stopLoading());
      console.log(err);
    }
  });

  yield takeLatest(actions.deleteOfficer, function* deleteOfficersSaga({ payload: { id, onDone } }) {
    try {
      yield call(api.deleteOfficer, id);
      yield put(actions.deleteOfficerSuccess({ id }));
      if (onDone)
        onDone();
      yield put(actions.stopLoading());
    } catch (err) {
      yield put(actions.stopLoading());
      console.log(err);
    }
  });

  yield takeLatest(actions.updateOfficer, function* updateOfficerSaga({ payload: { id, values, onDone } }) {
    try {
      const { data } = yield call(api.updateOfficer, id, values);
      if (data) {
        yield put(actions.updateOfficerSuccess({ officer: data, id }));
        onDone();
      } else {
        yield put(actions.stopLoading())
      }
    } catch (err) {
      yield put(actions.stopLoading());
      console.log(err);
    }
  });
}
