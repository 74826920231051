import * as React from "react";
import {Redirect, useHistory} from "react-router";
import { connect } from "react-redux";
import {Container, Typography, Button} from "@material-ui/core";
import {sellerLink, sellerLinkUSA} from "../../ConnectSeller/dataStep";
import * as auth from "../../../../store/ducks/auth.duck";
import {OnboardingLayout} from "../components";
import CloseIcon from '@material-ui/icons/Close';
import useUsers from "../../../../hooks/users.hook";
import {isAmazonSetUp} from "../../../../router/Routes";
import {FINISH, SETTINGS} from "../../../../router/routesMap";
import {useThemeSwitcher} from "react-css-theme-switcher";
import {Themes} from "../../../../types/enums";

type THistory = {
  location: {
    state: {
      from?: string
    }
  },
  push(location:string): void;
}

const OnboardingAmazon = () => {
  const {currentTheme} = useThemeSwitcher();
  const isDark = currentTheme === Themes.dark;

  const history: THistory = useHistory();
  const { user } = useUsers({fetch: false});

  const onClick = () => {
    window.open(sellerLink, "_self");
  };

  const onClickUSA = () => {
    window.open(sellerLinkUSA, "_self");
  };


  if ((history.location.state?.from !== SETTINGS) && isAmazonSetUp(user)) {
    return <Redirect to={{pathname: FINISH}} />
  }

  return (
    <OnboardingLayout
      currentStep={3}
      title="Connect your Amazon Account."
      button={
        <Button
          size="large"
          className="connect-doddled__logout"
          endIcon={<CloseIcon/>}
          onClick={() => {history.push('/logout');}}
        >
          Logout
        </Button>
      }
    >
      <div className="connect-doddled onboarding__amazon">
        <Container className="connect-doddled__container">
          {isDark
            ? <img src={require("../../../../../images/onboarding/amazon_fullsize_anim.png")} alt="" className="amazon__icon light-logo"/>
            : <img src={require("../../../../../images/onboarding/Amazon-Logo 1.png")} alt="" className="amazon__icon"/>
          }
          <Typography variant="h4" className="title connect-doddled__subtitle">
            We need to connect your account with SKUify to perform personalized analysis specially for your Amazon account.
          </Typography>
          <Button variant="contained" size="large" color="primary" onClick={onClick}>
            Connect UK / EU Amazon Account
          </Button>
          <Button variant="contained" size="large" color="primary" onClick={onClickUSA}>
            Connect USA Amazon Account
          </Button>
        </Container>
      </div>
    </OnboardingLayout>
  );
};

export default connect(
  null,
  auth.actions
)(OnboardingAmazon);