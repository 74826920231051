import React, {useState} from "react";
import ErrorIcon from '@material-ui/icons/Error';
import TooltipComponentState from "../../../components/TooltipComponentState";
import Popper from "@material-ui/core/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import DButton from "../../../components/Button";
import useProducts from "../../../../hooks/products.hook";
import Link from "@material-ui/core/Link";
import {errorId} from "../../Products/AsinOverview/utils";

const contentPopup = {
  title_changed: {
    name: "title",
    content:  ({ onTitle, row }) => {
      return !!row?.title_changes?.length && (
        <DButton className="note__err-button" onClickCustom={(e) => onTitle(e)}>
          <Link href={`#${errorId.title}`} underline="none">Show Title Changes</Link>
        </DButton>
      )
    }
  },
  duplicate_listing: {
    name: "duplicate",
  },
  image_changed: {
    name: "picture",
    content:  ({ onTitle, row }) => {
      return !!row?.picture_changes?.length && (
        <DButton className="note__err-button" onClickCustom={(e) => onTitle(e)}>
          <Link href={`#${errorId.image}`} underline="none">Show Image Changes</Link>
        </DButton>
      )
    }
  },
  incorrect_ptc: {
    name: "ptc issue"
  }
};

const ErrorsLabel = ({ error, onClickSkip, content, handleDisplayMetrics, row, showMetricsRow }) => {
  const { skipProductWarning } = useProducts({ fetch: false });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const idPopper = open ? `tab-table__error__form${error.id}` : undefined;
  const placement = open ? 'top' : undefined;
  const close = () => setAnchorEl(null);
  const handleClick = (event) => setAnchorEl(anchorEl ? null : event.currentTarget);
  const handleEditClick = (event) => handleClick(event);

  const handleSkip = () => {
    skipProductWarning({id: error.id});
    close();
  };

  const onTitle = (e) => {
    showMetricsRow === -1 && handleDisplayMetrics(e);
    handleEditClick(e);
  };

   return (
     <>
       <TooltipComponentState tooltip={error.tooltip} key={error.type}>
          <span className="table__cell--detail__content vat  warning" onClick={handleEditClick}>
            <ErrorIcon className="warning-icon" htmlColor="white" />{contentPopup[error.type].name}
          </span>
       </TooltipComponentState>
       <Popper
           id={idPopper}
           open={open}
           anchorEl={anchorEl}
           transition
           className="tab-table__note__root"
           placement={placement}
       >
         {({ TransitionProps }) => (
             <ClickAwayListener onClickAway={close}>
               <Fade {...TransitionProps} timeout={350}>
                 <Paper className="tab-table__note__paper name-cell-error">
                   {contentPopup[error.type]?.content && contentPopup[error.type].content({ onTitle, row, showMetricsRow })}
                   <DButton variant="outlined" className="note__err-button" onClickCustom={handleSkip}>
                     Don't show again
                   </DButton>
                 </Paper>
               </Fade>
             </ClickAwayListener>
         )}
       </Popper>
     </>
   )
}

export default ErrorsLabel;