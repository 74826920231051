import React, { useEffect, useState } from 'react';
import * as PropTypes from "prop-types";
import DButton from "../../../components/Button";
import Modal from "react-bootstrap/Modal";
import TableComponent from "../../../components/TableComponent/TableComponent";
import * as Selectors from "../../../../selectors/transactions.selectors";
import { connect } from "react-redux";
import * as transaction from "../../../../store/ducks/transactions.duck";
import withConfirmHook from "../../../../hooks/withConfirm.hook";
import TableToolbarComponent from "../../../components/TableComponent/TableToolbarComponent";
import { useCheckboxStorage, usePerPage } from "../../../../hooks/modal.hook";
import { getCurrentCompanyId } from "../../../../crud/utils";
import EmptyBlock from "../../../components/EmptyBlock";
import TransactionModalBalance from "./TransactionModalBalance";
import { messages } from "./messagesNotification";
import { useNotification }  from "../../../../hooks/notification.hook";
import { goToEditInvoicesPage, goToEditTransactionPage } from "../utils";
import { useHistory } from "react-router-dom";
import { transItems } from "../EnterTransactionsBanking/headRowsModal";
import Spinner from "../../../components/Spinner";
import { goToEditAdjustmentPage } from "../../Category/utils";

export const valuesTitle = {
  CUSTOMERS: 'customers',
  BANKING: 'banking',
  SUPPLIERS: 'suppliers',
  CATEGORY: 'category',
};

function TransactionModal(props) {
  const {
    transactions,
    loading,
    getTransactionsByBank,
    // getTransactionsByCategory,
    getTransactions,
    data,
    show,
    onClose,
    valueTab,
    onExportPDF,
    periodOfTime,
    headRowsModal,
    reset,
  } = props;
  const [activeColumns, setActiveColumns] = useCheckboxStorage("transactionModal", headRowsModal);
  const [rowsPerPage, onPerPage] = usePerPage();
  const getMessage = (name) => messages.find(item => item.label === name);
  const messageNotification = getMessage(data.name) && getMessage(data.name).message;
  const infoNotification = useNotification(true, "info", messageNotification);
  const [searchQuery, setSearchQuery] = useState(null);

  let history = useHistory();

  useEffect(() => {
    if (data.entry_method) {
      getTransactionsByBank({ companyId: getCurrentCompanyId(), bankId: data.id});
    } else {
      // getTransactionsByCategory({companyId:  getCurrentCompanyId(), categoryId: data.id})
      getTransactions({companyId:  getCurrentCompanyId(), q: searchQuery})
    }
  }, [data.id]);

  const filterTransactions = () => {
    switch (valueTab) {
      case valuesTitle.CUSTOMERS:
        return transItems;
        // return transactions.filter((transaction) => transaction.type ==="CR");
      case valuesTitle.BANKING:
        return transactions;
      case valuesTitle.SUPPLIERS:
        return transItems;
        // return transactions.filter((transaction) => transaction.type ==="SP");
      case valuesTitle.CATEGORY:
        return transactions;
        // return transactions.filter((transaction) => transaction.type ==="SP");
      default:
        return transactions.filter(transaction => transaction.type);
    }
  };

  const getTitle = () => {
    switch (valueTab) {
      case valuesTitle.CUSTOMERS:
        return "Customer:  ";
      case valuesTitle.BANKING:
        return "Bank Account:  ";
      case valuesTitle.SUPPLIERS:
        return "Supplier:  ";
      case valuesTitle.CATEGORY:
        return "Category:  ";
      default:
        return "";
    }
  };

  const onCloseModal = () => {
    onClose();
    reset()
  };

  const handleExportPDF = () => {
    onExportPDF();
    onCloseModal();
  };
  const handleExport = () => console.log("onExport");

  const onTypeClick = (row) => () => {
    switch (valueTab) {
      case valuesTitle.CUSTOMERS:
        goToEditInvoicesPage(history, row.id, valueTab);
        break;
      case valuesTitle.BANKING:
        row.bank_account && goToEditTransactionPage(history, row.id, row.bank_account.id);
        break;
      case valuesTitle.CATEGORY:
        if (row.type.label === "Adjustment") {
          goToEditAdjustmentPage(history, row.id)
        } else if (row.bank_account) {
          goToEditTransactionPage(history, row.id, row.bank_account.id);
        }
        // else {
        //   goToEditTransactionPage(history, row.id, row.bank_account.id);
        // }
        break;
      case valuesTitle.SUPPLIERS:
        goToEditInvoicesPage(history, row.id, valueTab);
        break;
      default:
        return;
    }
  };

  const onRefresh = () => getTransactionsByBank({ companyId: getCurrentCompanyId(), bankId: data.id});

  const itemsTable = filterTransactions();

  // balance row
  const bottomTable = data.currency && <TransactionModalBalance balance={data.balance} currency={data.currency} />;

  const content = itemsTable.length !== 0
    ? <TableComponent
      className="transactions-modal__table"
      headRows={activeColumns.filter(column => column.isActive)}
      items={itemsTable}
      rowsPerPageSelected={rowsPerPage}
      rowProps={{
        onTypeClick
      }}
      bottomTable={bottomTable}
    />
    : <EmptyBlock/>;

  // hide right part of header
  const isOnlyName = !(data.category_type || data.entry_method || data.supplier_ref || data.customer_ref);

  const subtitle = itemsTable.length !== 0 ? periodOfTime : null;

  return (
    <Modal show={show} onHide={onCloseModal} className="trans-modal">
      <Modal.Header> {/* closeButton --- add "x" button*/}
        <TableToolbarComponent
          className="trans-modal__table-toolbar"
          title={getTitle() + data.name}
          subtitle={subtitle}
          isShowElements={isOnlyName}
          setSearchQuery={setSearchQuery}
          actionsHeader={{
            onRefresh,
            onExport: handleExport,
            onExportPDF: valueTab === valuesTitle.CUSTOMERS ? handleExportPDF : null,
            selectMenu: { setFunc: onPerPage, type: "select" },
            viewColumnMenu: { array: activeColumns, type: "filter", setFunc: setActiveColumns, disabled: true },
          }}
        />
      </Modal.Header>
      <Modal.Body>
        {messageNotification && infoNotification}
        {loading ? <Spinner/> : content}
      </Modal.Body>
      <Modal.Footer>
        <div className="container-button container-button--modal">
          <DButton typeOfButton="close" onClickCustom={onCloseModal}>Close</DButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

TransactionModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  valueTab: PropTypes.oneOf([valuesTitle.BANKING, valuesTitle.CUSTOMERS, valuesTitle.SUPPLIERS, valuesTitle.CATEGORY]),
  transactions: PropTypes.array.isRequired,
  getTransactionsByBank: PropTypes.func,
  onExportPDF: PropTypes.func,
  show: PropTypes.bool,
  periodOfTime: PropTypes.string,
  loading: PropTypes.bool,
  reset: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    transactions: Selectors.selectTransactions(state),
    loading: Selectors.selectLoading(state),
  }
};

export default connect(
  mapStateToProps,
  transaction.actions,
)(withConfirmHook(TransactionModal))
