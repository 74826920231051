import {
  EDIT_ADJUSTMENT,
  EDIT_RECURRING_ADJUSTMENT,
  NEW_ADJUSTMENT,
  NEW_RECURRING_ADJUSTMENT,
} from "../../../router/routesMap";

export const goToNewAdjustmentPage = (history) => {
  history.push(NEW_ADJUSTMENT);
};

export const goToNewRecurringAdjustmentPage = (history) => {
  history.push(NEW_RECURRING_ADJUSTMENT);
};

export const goToEditAdjustmentPage = (history, adjustmentId) => {
  history.push(EDIT_ADJUSTMENT + `?adjustment_id=${adjustmentId}`);
};

export const goToEditRecurAdjustmentPage = (history, adjustmentId, type) => {
  history.push(EDIT_RECURRING_ADJUSTMENT + `?adjustment_id=${adjustmentId}&type=${type}`);
};

export const goToCategoryPage = (history) => {
  history.push('/books/categories');
};