import {useDispatch, useSelector} from 'react-redux'
import * as OnboardingDuckSelector from '../selectors/onboarding.selectors'
import * as settingsDuck from '../store/ducks/onboarding.duck'

const useOnboarding = () => {
  const dispatch = useDispatch();

  const getSubscription = (args) => {
    dispatch(settingsDuck.actions.getSubscription(args))
  };

  const sendContact = (args) => {
    dispatch(settingsDuck.actions.sendContact(args))
  };

  const addContactGetResponse = (args) => {
    dispatch(settingsDuck.actions.addContactGetResponse(args))
  };

  const subscriptions = OnboardingDuckSelector.getSubscription(useSelector(state => state));

  return {
    subscriptions: subscriptions.details,
    getSubscription,
    sendContact,
    addContactGetResponse
  }
};

export default useOnboarding;
