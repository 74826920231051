import React, { useEffect } from 'react'
import "./style-pricing.scss";
import Pricing from "./Codely/Menpricing/Pricing";
import Faq from "./Codely/Menpricing/Faq";
import Header from './Codely/Header/Main'
import Footer from './Codely/Footer/Footer'
import Banner from "./Codely/Menhome/Banner";
import FreeTrial from "./Codely/Menhome/FreeTrial";
import AdvanceFeature from "./Codely/Menhome/AdvanceFeature";
import Communication from "./Codely/Menhome/Communication";
import Collaborate from "./Codely/Menhome/Collaborate";
import Analyze from "./Codely/Menhome/Analyze";
import Solution from "./Codely/Menhome/Solution";
import Trusted from "./Codely/Menhome/Trusted";
import Need from "./Codely/Menpricing/Need";
import Features from "./Codely/Menhome/Features";
import { INTERCOM_ID } from "../../home/utils";

const SalesPage = () => {
  useEffect(() => {
    //chat bot
    window.Intercom('boot', {
      app_id: INTERCOM_ID,
    });

  }, []);

  return (
    <div className="pricing-page">

      <div className="top_home_wraper">
        <Header parent="" />
        <Banner />
      </div>

      <Solution />
      <Analyze />
      <Collaborate />
      <Communication />
      <Features />
      <AdvanceFeature />
      <Trusted />
      <Pricing />
      <Faq />
      <FreeTrial />
      <Need />
      <Footer parent="" />
    </div>
  )
};
export default SalesPage;