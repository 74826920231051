import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import * as bank from "../store/ducks/bunk.duck";
import * as Selector from "../selectors/bank.selectors";

const useBankFilesCrud = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(bank.actions.getBankFiles());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const banks = Selector.selectBankFiles(useSelector(state => state));
  const updateBankFile = (args) => {
    dispatch(bank.actions.updateBankFile(args));
  };

  const addBankFile = (args) => {
    dispatch(bank.actions.addBankFile(args));
  };

  return {
    bankFiles: banks.details,
    bankFilesLoading: banks.loading,
    updateBankFile: updateBankFile,
    addBankFile: addBankFile,
  };
};

export default useBankFilesCrud;