import React from "react";
import { Avatar, Link, TableCell } from "@material-ui/core";
import NameCell from "../components/NameCell";
import WarningCell from "../components/WarningCell";
import { icon as notesIcons } from "../components/NoteTabTable";
import { Tooltip, IconButton } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import classNames from "clsx";
import { getNumber, numberWithCommas, withUnits } from "../../utils";
import Help from "../components/Help";
import Flag from "../components/Flag";
import FulfillmentCell from "../components/FulfilmentCell";
import NoteOrdersRefunds from "./NoteOrdersRefunds";
import TooltipComponent from "../../../components/TooltipComponent";
import { marketplaceIds, marketplaceNames } from "../../../components/Marketplaces/Marketplaces";
import { IconCOG } from "./components";
import Typography from "@material-ui/core/Typography";
import TooltipComponentState from "../../../components/TooltipComponentState";
import ContentCell from "../../Products/ContentCell";
import { getName } from 'country-list';

import OrdersHeadPending from "./ordersHeadPending";

import {
  priceTooltip,
  formatDate,
  fulfilmentIcon,
  getOrderStatus,
  statusIcon,
  styleIcons,
  tooltipProps,
  typeReports
} from "../components/utils";
import AddInfo from "../components/AddInfo";

const TABLES = {
  orders: 'orders',
  refund: 'refund',
};

const baseHeaders = ({ tableName, pageName, currency, location, isExtraDataCell }) => (
  [
    {
      id: "image_url",
      label: "Product",
      disabled: true,
      render: (row) => {
        const { image_url, amazon_product_url, id, full_image_url } = row;
        return (
          <TableCell
            align="left"
            key={`${id}--photo`}
            className={classNames("table__cell--photo", {
              'highlight': row.order__order_status === 'Pending'
            })}
          >
            <TooltipComponentState
              placement="right-start"
              className="cell--photo__tooltip"
              isBlur
              tooltip={
                <Avatar alt="img" variant="square" src={full_image_url} className="cell__tooltip__photo" />
              }
            >
              <Link target="_blank" href={amazon_product_url}>
                <Avatar alt="img" variant="square" src={image_url} />
              </Link>
            </TooltipComponentState>
          </TableCell>
        )
      }
    },
    {
      id: "title",
      label: "",
      fewLabel: [
        { id: "title", label: "", align: "center" },
        { id: "product_vat_rate", label: "", tooltipPlacement: "bottom-start" },
        { id: "warnings", label: "", tooltipPlacement: "bottom-start" },
      ],
      render: (row, { contentCellProps, isExtraDataCell, onEdit, getInfoRefund }) => {
        const extraData = (
          <>
            <span className="bold table__cell--detail__title">ORDER:</span>
            {row.order__amazon_order_id}
          </>
        );


        const { warnings, deemed_reseller } = row;
        const warningTooltips = warnings
          .filter(w => [
            'postage_mf_warning', 'price_inaccurate_and_sold_price_is_not_confirmed'].includes(w.type))
          .map(w => w.tooltip);

        const warningMessage = warningTooltips.length ? {
          ...contentCellProps,
          row,
          isTooltip: false,
          isEdit: true,
          onUpdate: onEdit(row),
          className: "message-warning",
          valueIcon:
            <TooltipComponentState
              className="cell__detail__message-warning__tooltip"
              tooltip={warningTooltips}
            >
              {/*<Avatar*/}
              {/*    alt="img"*/}
              {/*    src={toAbsoluteUrl("/media/d-icons/name-cell-icon/pending_warning.svg")}*/}
              {/*    className="table__cell--detail__message-warning"*/}
              {/*/>*/}
            </TooltipComponentState>

        } : null;
        const countryName = deemed_reseller?.country_code && getName(deemed_reseller.country_code);

        return <NameCell
          // lowOpacity={row.order__order_status === 'Pending'}
          lowOpacity={false}
          highlight={row.order__order_status === 'Pending'}
          key={`${row.id}--title`}
          asin={row.asin}
          url={row.amazon_product_url}
          sku={row.seller_sku}
          vat={row.vat_rate}
          title={row.title}
          colSpan={3}
          tableName="sales"
          row={row}
          extra={{
            rowData: row.order__amazon_order_id,
            data: extraData,
            url: `https://sellercentral.amazon.co.uk/orders-v3/order/${row.order__amazon_order_id}`,
            isExtraLine: isExtraDataCell,
            vat: row.product_vat_rate,
            warningMessage,
            refundOrderInfo: (pageName === "sales" && TABLES.refund === tableName) ? getInfoRefund : "",
            deemedReseller: deemed_reseller && {
              name: deemed_reseller.name,
              tooltip: `${countryName}, ${deemed_reseller.vat_rate}%`
            }
          }}
        />
      }
    },
    {
      id: "order__marketplace",
      label: "Marketplace",
      align: "center",
      helpText: "The flags below represent which marketplace your buyer purchased the product from." +
        " You can sort by clicking on the arrow to the right.",
      render: (row) => {
        const { marketplace } = row;
        const tooltip = marketplace ? marketplaceNames[marketplace] : "";
        return (
          <TableCell
            align="center"
            key={`${row.id}--buyer`}
            className={classNames("table__cell--buyer", {
              'highlight': row.order__order_status === 'Pending'
            })}
          >
            <Flag marketplace={marketplace} className="table__cell__flag" tooltip={tooltip} />
          </TableCell>
        )
      }
    },
    {
      ...function () {
        if (TABLES.orders === tableName) {
          return {
            id: 'order__purchase_date',
            label: "Time and Date",
            align: "center",
            getClassName: (row) => row.order__order_status === 'Pending' && 'highlight',
            renderText: (row) => {
              const { order__purchase_date } = row;
              return order__purchase_date ? formatDate(order__purchase_date, location) : "-";
            },
            helpText: "This represents the date and time of the order",
          }
        } else if (TABLES.refund === tableName) {
          return {
            id: 'refund_date',
            label: "Time and Date",
            align: "center",
            renderText: (row) => {
              const { refund_date } = row;
              return refund_date ? formatDate(refund_date, location) : "-";
            },
            helpText: "This represents the date and time of the order refund date",
          }
        }
      }(),
    },
    {
      ...function () {
        if (TABLES.orders === tableName) {
          return {
            id: 'quantity_ordered',
            label: "Units",
            align: "center",
            getClassName: (row) => row.order__order_status === 'Pending' && 'highlight',
            helpText: "The number of units purchased by this buyer",
            renderText: (row) => numberWithCommas(getNumber(row.quantity_ordered), "integer"),
          }
        } else if (TABLES.refund === tableName) {
          return {
            id: 'refund_quantity',
            label: "Units",
            align: "center",
            helpText: "The number of units refuned by this buyer",
            renderText: (row) => numberWithCommas(getNumber(row.refund_quantity), "integer"),
          }
        }
      }(),
    },
    {
      id: "item_price_amount",
      label: "Unit Price",
      align: "center",
      helpText: "The total sales price paid by the customer",
      render: (row) => {
        const { marketplace, original_price, order__order_status, warnings } = row;
        let differentColorCell = false;
        let tooltip = "";
        const [price_inaccurate, price_inaccurate_and_sold_price_is_not_confirmed, sold_price_is_not_confirmed] = warnings
          .filter(w => [
            'price_inaccurate', 'sold_price_is_not_confirmed', 'price_inaccurate_and_sold_price_is_not_confirmed'
          ].includes(w.type))
          .sort((a, b) => a.type.localeCompare(b.type));

        if (!["Unshipped", "Shipped"].includes(order__order_status)) {
          const isDifferentColor = row.price_inaccurate;
          if (price_inaccurate) {
            tooltip = price_inaccurate.tooltip;
            differentColorCell = isDifferentColor;
          }
          if (sold_price_is_not_confirmed) {
            tooltip = sold_price_is_not_confirmed.tooltip;
            differentColorCell = isDifferentColor;
          }
          if (price_inaccurate_and_sold_price_is_not_confirmed) {
            tooltip = price_inaccurate_and_sold_price_is_not_confirmed.tooltip;
            differentColorCell = isDifferentColor;
          } else if (marketplaceIds[marketplace] !== "UK") {
            if (original_price) {
              if (price_inaccurate) {
                tooltip = <div>
                  <Typography className="price-inaccurate">{priceTooltip}</Typography>
                  <Typography className="price-inaccurate">
                    Original price: {withUnits(original_price.currency, original_price.price)}
                  </Typography>
                </div>;
              } else {
                tooltip = <Typography>
                  Original price: {withUnits(original_price.currency, original_price.price)}
                </Typography>;
              }
            } else {
              tooltip = ""
            }
          }
        }
        const isPending = row.order__order_status === "Pending";

        return (
          <TableCell
            align="center"
            key={`${row.id}--item_price_amount`}
            className={classNames("table__cell--item_price_amount nowrap", {
              "different-color-cell": isPending, 'highlight': isPending
            })}
          >
            <TooltipComponent tooltip={tooltip}>
              <div className={classNames({ "different-color": isPending })}>
                {row.order__order_status === 'Pending'
                  ? <OrdersHeadPending
                    withUnits={withUnits}
                    currency={currency}
                    rowItemPriceAmount={row.item_price_amount}
                  />
                  : withUnits(currency, row.item_price_amount)
                }
              </div>
            </TooltipComponent>
          </TableCell>
        )
      }
    },
    {
      id: "order__order_status",
      label: "Status",
      align: "center",
      help: () => <Help data={statusIcon[currency]} className="help--status" />,
      render: (row) => (
        <TableCell
          align="center"
          key={`${row.id}--status`}
          className={classNames("table__cell--status", {
            'highlight': row.order__order_status === 'Pending'
          })}
        >
          <TooltipComponent tooltip={getOrderStatus(row, "name", currency)}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <img
                alt="img"
                className={"table__cell__img--icon " + styleIcons[row.order__order_status]}
                src={getOrderStatus(row, "url", currency)}
              />
              {/*{ row.order__order_status === 'Pending' && (*/}
              {/*  <span className="table_cell__status-text--warning-eleven">Order Pending</span>*/}
              {/*)}*/}
            </div>
          </TooltipComponent>

        </TableCell>
      )
    },
    {
      id: "order__fulfillment_channel",
      label: "Fulfilment",
      align: "center",
      help: () => <Help data={fulfilmentIcon} className="help--fulfillment" />,
      render: (row) => {
        const fulfillment = row.order__fulfillment_channel;
        return (
          <TableCell
            align="center"
            key={`${row.id}--fulfillment`}
            className={classNames("table__cell--fulfilment", {
              'highlight': row.order__order_status === 'Pending'
            })}
          >
            <FulfillmentCell row={row} fulfillment={fulfillment} />
          </TableCell>
        )
      }
    },
    ...pageName === "sales" && TABLES.orders === tableName
      ? [{
        id: "shipping_to",
        label: "Shipped To",
        align: "center",
        render: (row) => {
          const { shipping_to, id, order__order_status } = row;
          const isCanceled = order__order_status === "Canceled";
          const codes = ["UK", "GB"];
          const marketplaceCode = isCanceled ? order__order_status : (shipping_to?.country_code || "");
          const country = codes.includes(marketplaceCode) ? "United Kingdom" : getName(marketplaceCode);
          const tooltip = marketplaceCode && (isCanceled ? "Canceled" : `${country}`);
          return (
            <TableCell
              align="center"
              key={`${id}--shipping_to`}
              className={classNames("table__cell--shipping_to", {
                'highlight': row.order__order_status === 'Pending'
              })}
            >
              <Flag
                marketplaceCode={marketplaceCode}
                className="table__cell__flag"
                classNameTooltip="cell__flag--city"
                tooltip={tooltip || ""}
              />
            </TableCell>
          )
        }
      }]
      : [],
    {
      id: "total_cog",
      label: "COG",
      align: "center",
      helpText: "Your costs of goods are calculated by adding together your unit cost, " +
        "prep centre, bundle cost, packaging, postage, handling and others as appropriate",
      render: (row, { contentCellProps, onDialog, isShowPopup, onEdit, currentRow }) => {
        const { id, total_cog, cogsData, inventory_cost, order__order_status } = row;
        const value = withUnits(currency, total_cog);
        const isUpdate = !!inventory_cost || inventory_cost === 0;
        const popupData = cogsData
          ? [{ name: "COG Breakdown", data: cogsData, currency_symbol: currency }]
          : [{ data: [] }];


        function getUnitCost(data) {
          for (let item of data[0].data) {
            if (item.name === "Unit Cost") {
              return item?.value;
            }
          }
          return null;
        }

        const isShowMessage = getUnitCost(popupData) === 0

        const contentCell = <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={onDialog(row)}
        >
          {isShowMessage ? (
            <div style={{marginLeft: "-20px", marginRight: "-6px"}}>
            <Tooltip title="Please enter your Unit Cost to see Total COGs.">
              <IconButton>
                <ErrorIcon color="error" />
              </IconButton>
            </Tooltip>
            </div>
          ) : <div style={{minWidth: "24px" }}></div>}
          <ContentCell
            {...contentCellProps}
            row={row}
            value={value}
            isTooltip={false}
            isEdit
            onUpdate={onEdit(row)}
            isShowPopup={isShowPopup && currentRow.id === id}
            valueIcon={<IconCOG isUpdate={isUpdate} />}
          />
        </div>;

        return (
          <TableCell
            align="center"
            key={`${id}--costs`}
            className={classNames("table__cell--cog", {
              'highlight': order__order_status === 'Pending'
            })}
          >
            {cogsData
              ? <TooltipComponentState {...tooltipProps({ report: popupData })}>
                {contentCell}
              </TooltipComponentState>
              : contentCell
            }
          </TableCell>
        )
      }
    },
    {
      id: "amz_fees",
      label: "Amazon Fees",
      align: "center",
      helpText: "The Amazon costs associated with this order",
      render: (row, { onHoverRow, currentRow, orderReport, isLoading, reportType }) => {
        const isShowTooltip = !!row.amz_fees || row.amz_fees === 0;
        const tooltipData = isShowTooltip && currentRow.id === row.id && reportType === typeReports.AMAZON
          ? orderReport
          : null;
        const reportTooltipProps = tooltipData?.length
          ? tooltipProps({ report: tooltipData, type: typeReports.AMAZON, isLoading })
          : null;
        const isPending = row.order__order_status === "Pending";

        return (

          <TableCell
            align="center"
            key={`${row.id}--amz_fees`}
            className={classNames("table__cell--amz_fees nowrap", {
              'highlight': row.order__order_status === 'Pending'
            })}
          >
            <div
              onMouseEnter={onHoverRow(row, typeReports.AMAZON, isShowTooltip)}
              className={classNames({ "different-color": isPending })}
            >
              {
                !!tooltipData ? (
                  <TooltipComponentState {...reportTooltipProps}>
                    {withUnits(currency, row.amz_fees)}
                  </TooltipComponentState>
                ) : (
                  withUnits(currency, row.amz_fees)
                )
              }
            </div>
          </TableCell>
        )
      }
    },
  ]
);

export const headRows = ({ pageName, currency, location, isExtraDataCell }) => [
  ...baseHeaders({ tableName: TABLES.orders, pageName, currency, location, isExtraDataCell }),
  {
    id: "profit",
    label: "Gross Profit",
    align: "center",
    helpText: "Your profit for each sale",
    render: (row, { onHoverRow, currentRow, orderReport, isLoading, reportType }) => {
      // show tooltip when there is a value
      const isShowReport = !!row.profit || row.profit === 0;
      const tooltipData = isShowReport && currentRow.id === row.id && reportType === typeReports.SALES
        ? orderReport
        : null;
      const reportTooltipProps = tooltipData?.length
        ? tooltipProps({ report: tooltipData, type: typeReports.SALES, isLoading })
        : null;

      const value = <div
        onMouseEnter={onHoverRow(row, typeReports.SALES, isShowReport)}
        className={classNames("full-size placement--center", { "report__tooltip": isShowReport })}
      >
        {
          !!tooltipData ? (
            <TooltipComponentState {...reportTooltipProps}>
              <WarningCell units={currency} value={row.profit} nowrap text />
            </TooltipComponentState>
          ) : (
            <WarningCell units={currency} value={row.profit} nowrap text />
          )
        }
      </div>;

      return (
        <TableCell
          align="center"
          key={`${row.id}--profit`}
          className={classNames("table__cell--profit table__cell--with-tooltip", {
            'highlight': row.order__order_status === 'Pending'
          })}
        >
          {value}
        </TableCell>
      )
    },
  },
  {
    id: "roi",
    label: "ROI",
    align: "center",
    helpText: "The Return on Investment achieved for each sale",
    render: (row) => (
      <WarningCell
        key={`${row.id}--roi`}
        id={row.id}
        name="roi"
        value={row.roi}
        param={row.profit}
        units={"%"}
        nowrap
        // lowOpacity={row.order__order_status==='Pending'}
        lowOpacity={false}
        highlight={row.order__order_status === 'Pending'}
      />
    ),
  },
  {
    id: "margin",
    label: "Margin",
    align: "center",
    helpText: "The Profit Margin achieved for each sale",
    render: (row) => (
      <WarningCell
        key={`${row.id}--margin`}
        id={row.id}
        name="margin"
        value={row.margin}
        param={row.profit}
        units="%"
        nowrap
        // lowOpacity={row.order__order_status==='Pending'}
        lowOpacity={false}
        highlight={row.order__order_status === 'Pending'}
      />
    ),
  },
  {
    id: "add_info",
    label: "Add. Info",
    align: "center",
    disabled: true,
    // help: () => <Help data={notesIcons} />,
    render: (row, { contentCellProps, isExtraDataCell, onEdit, getInfoRefund }) => {
      const extraData = (
        <>
          <span className="bold table__cell--detail__title">ORDER:</span>
          {row.order__amazon_order_id}
        </>
      );


      const { warnings, deemed_reseller } = row;
      const warningTooltips = warnings
        .filter(w => [
          'postage_mf_warning', 'price_inaccurate_and_sold_price_is_not_confirmed'].includes(w.type))
        .map(w => w.tooltip);

      const warningMessage = warningTooltips.length ? {
        ...contentCellProps,
        row,
        isTooltip: false,
        isEdit: true,
        onUpdate: onEdit(row),
        className: "message-warning",
        valueIcon:
          <TooltipComponentState
            className="cell__detail__message-warning__tooltip"
            tooltip={warningTooltips}
          >
            {/*<Avatar*/}
            {/*    alt="img"*/}
            {/*    src={toAbsoluteUrl("/media/d-icons/name-cell-icon/pending_warning.svg")}*/}
            {/*    className="table__cell--detail__message-warning"*/}
            {/*/>*/}
          </TooltipComponentState>

      } : null;
      const countryName = deemed_reseller?.country_code && getName(deemed_reseller.country_code);

      return <TableCell align="center" key={`${row.id}--add-info`} className="table__cell--add-info">
        <AddInfo
          key={`${row.id}--add-info`}
          url={row.amazon_product_url}
          vat={row.vat_rate}
          title={row.title}
          tableName="sales"
          row={row}
          extra={{
            rowData: row.order__amazon_order_id,
            data: extraData,
            url: `https://sellercentral.amazon.co.uk/orders-v3/order/${row.order__amazon_order_id}`,
            isExtraLine: isExtraDataCell,
            vat: row.product_vat_rate,
            warningMessage,
            // refundOrderInfo: pageName === "sales" ? getInfoRefund : "",
            deemedReseller: deemed_reseller && {
              name: deemed_reseller.name,
              tooltip: `${countryName}, ${deemed_reseller.vat_rate}%`
            }
          }}
        />
      </TableCell>
    },
  },
  {
    id: "note",
    label: "Note",
    align: "center",
    disabled: true,
    help: () => <Help data={notesIcons} />,
    render: (row) => {
      return <TableCell
        align="center"
        key={`${row.id}--note`}
        className={classNames("table__cell--note", { 'highlight': row.order__order_status === 'Pending' })}
      >
        <NoteOrdersRefunds row={row} target="order" />
      </TableCell>
    },
  },
];

export const headRowsRefunds = ({ pageName, currency, location, isExtraDataCell }) => [
  ...baseHeaders({ tableName: TABLES.refund, pageName, currency, location, isExtraDataCell }),
  {
    id: "refund_cost",
    label: "Refund Cost",
    align: "center",
    helpText: "Refund Cost",
    render: (row, { onHoverRow, currentRow, orderReport, isLoading, reportType }) => {
      const isShowReport = !!row.refund_cost || row.refund_cost === 0;
      const tooltipData = isShowReport && currentRow.id === row.id && reportType === typeReports.SALES
        ? orderReport
        : null;
      const isReturn = row?.order__order_status === "Refund";
      const reportTooltipProps = tooltipData
        ? tooltipProps({ report: tooltipData, type: typeReports.SALES, isLoading, isReturn })
        : null;

      return (
        <TableCell
          align="center"
          key={`${row.id}--profit`}
          className={classNames(
            " table__cell--profit nowrap report__tooltip",
            isLoading ? "loading_cursor" : "default_cursor"
          )}
          onMouseEnter={onHoverRow(row, typeReports.SALES, isShowReport)}
        >
          <TooltipComponentState {...reportTooltipProps}>
            <WarningCell value={row.refund_cost} units={currency} nowrap text param={-1} />
          </TooltipComponentState>
        </TableCell>
      )
    },
  },
  {
    id: "total_amount_refunded",
    label: "Total Amount Refunded",
    align: "center",
    helpText: "Total Amount Refunded",
    render: (row, { onHoverRow, currentRow, orderReport, isLoading, reportType }) => {
      // const isShowReport = !!row.refund_cost || row.refund_cost === 0;
      const data = orderReport.length
        ? orderReport.slice(0, 1).map((item) => ({ ...item, total_label: "Total Amount Refunded" }))
        : null;

      const tooltipData = currentRow.id === row.id && reportType === typeReports.SALES
        ? data
        : null;

      const reportTooltipProps = tooltipData
        ? tooltipProps({ report: tooltipData, type: typeReports.SALES, isLoading })
        : null;

      return (
        <TableCell
          align="center"
          key={`${row.id}--total_amount_refunded`}
          className={classNames(
            " table__cell--total_amount_refunded nowrap report__tooltip",
            isLoading ? "loading_cursor" : "default_cursor"
          )}
          onMouseEnter={onHoverRow(row, typeReports.SALES, true)}
        >
          <TooltipComponentState {...reportTooltipProps}>
            <WarningCell
              value={row.total_amount_refunded}
              units={currency}
              nowrap
              text
            />
          </TooltipComponentState>
        </TableCell>
      )
    },
  },
  {
    id: "return_status",
    label: "Return Status",
    align: "center",
    helpText: "Customer return reason",
    render: (row) => (
      <TableCell align="center" key={`${row.id}--return_status`} className="table__cell--return_status">
        <TooltipComponent tooltip={row.return_reason || ""}>
          <span>{row.return_status || "-"}</span>
        </TooltipComponent>
      </TableCell>
    ),
  },
  {
    id: "add_info",
    label: "Add. Info",
    align: "center",
    disabled: true,
    // help: () => <Help data={notesIcons} />,
    render: (row, { contentCellProps, isExtraDataCell, onEdit, getInfoRefund }) => {
      const extraData = (
        <>
          <span className="bold table__cell--detail__title">ORDER:</span>
          {row.order__amazon_order_id}
        </>
      );


      const { warnings, deemed_reseller } = row;
      const warningTooltips = warnings
        .filter(w => [
          'postage_mf_warning', 'price_inaccurate_and_sold_price_is_not_confirmed'].includes(w.type))
        .map(w => w.tooltip);

      const warningMessage = warningTooltips.length ? {
        ...contentCellProps,
        row,
        isTooltip: false,
        isEdit: true,
        onUpdate: onEdit(row),
        className: "message-warning",
        valueIcon:
          <TooltipComponentState
            className="cell__detail__message-warning__tooltip"
            tooltip={warningTooltips}
          >
            {/*<Avatar*/}
            {/*    alt="img"*/}
            {/*    src={toAbsoluteUrl("/media/d-icons/name-cell-icon/pending_warning.svg")}*/}
            {/*    className="table__cell--detail__message-warning"*/}
            {/*/>*/}
          </TooltipComponentState>

      } : null;
      const countryName = deemed_reseller?.country_code && getName(deemed_reseller.country_code);

      return <TableCell align="center" key={`${row.id}--add-info`} className="table__cell--add-info">
        <AddInfo
          key={`${row.id}--add-info`}
          url={row.amazon_product_url}
          title={row.title}
          tableName="sales"
          row={row}
          extra={{
            rowData: row.order__amazon_order_id,
            data: extraData,
            url: `https://sellercentral.amazon.co.uk/orders-v3/order/${row.order__amazon_order_id}`,
            isExtraLine: isExtraDataCell,
            vat: row.product_vat_rate,
            warningMessage,
            refundOrderInfo: pageName === "sales" ? getInfoRefund : "",
            deemedReseller: deemed_reseller && {
              name: deemed_reseller.name,
              tooltip: `${countryName}, ${deemed_reseller.vat_rate}%`
            }
          }}
        />
      </TableCell>
    },
  },
  {
    id: "note",
    label: "Note",
    align: "center",
    disabled: true,
    help: () => <Help data={notesIcons} />,
    render: (row) => {
      return <TableCell align="center" key={`${row.id}--note`} className="table__cell--note">
        <NoteOrdersRefunds row={row} target="refund" />
      </TableCell>
    },
  },
];
