import React, { useState, useEffect } from "react";
import TableComponent from "../../../components/TableComponent/TableComponent";
import { useModal } from "../../../../hooks/modal.hook";
import ReceiptUploadsModal from "../../../components/ReceiptUploadsModal/ReceiptUploadsModal";
import TransactionModal, { valuesTitle } from "../TransactionModal/TransactionModal";
import { headRowsModal } from "../EnterTransactionsBanking/headRowsModal";
import withConfirmHook from "../../../../hooks/withConfirm.hook";
import EditAddTransactionModal from "../CustomersSuppliers/EditAddTransactionModal";
import * as PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { goToImportDataPage, goToSuppliersInvoicesPage } from "../utils";
import { useNotification } from "../../../../hooks/notification.hook";
import useCompany from "../../../../hooks/company.hook";
import DButton from "../../../components/Button";
import PopperMenu from "../../../components/PopperMenu";
import useSuppliersCustomers from "../../../../hooks/suppliersCustomers.hook";
import DSplashScreen from "../../../components/SplashScreen/DSplashScreen";

function EnterTransactionsSuppliers({ confirm, tabProps }) {
  const { headRows, rowsPerPage, reload, items, searchQuery, currentTab } = tabProps;
  const [isEditModalShow, toggleEditModal] = useModal();
  const [isAddSupplierModalShow, toggleAddSupplierModal] = useModal();
  const [isReceiptUploadsModalShow, toggleReceiptUploadsModalShow] = useModal();
  const [currentRow, setCurrentRow] = useState(null);
  const [isTransactionModalShow, toggleTransactionModal] = useModal();
  const [successNotification, setIsShowNotification] = useNotification(false, "success", "Updated");
  const history = useHistory();
  const { company } = useCompany();
  const { suppliers, suppliersLoading, getSuppliers, deleteSupplier } = useSuppliersCustomers({ fetch: false });

  useEffect(() => {
    getSuppliers({ q: searchQuery });
  }, [reload, searchQuery]);

  const reset = () => setCurrentRow(null);
  const onEdit = (row) => () => {
    setCurrentRow(row);
    toggleEditModal(!isEditModalShow);
  };

  const onAttachments = (row) => () => {
    setCurrentRow(row);
    toggleReceiptUploadsModalShow(!isReceiptUploadsModalShow);
  };

  const goToPage = (row) => () => {
    goToSuppliersInvoicesPage(history, row.id);
  };

 const onDelete = (row) => (
    confirm(() => {
        deleteSupplier({
          id: row.id,
        })
      },
      { description: 'This action is permanent!' })
  );

  const onNameClick = (row) => () => {
    toggleTransactionModal(!isTransactionModalShow);
    setCurrentRow(row);
  };

  const onNewInvoice = () => {
    goToSuppliersInvoicesPage(history);
  };

  const onNewImports = () => {
    goToImportDataPage(history, "Supplier");
  };

  const onSuccessNew = () => {
    toggleAddSupplierModal();
    setIsShowNotification(true);
  };

  const onSuccessEdit = () => {
    toggleEditModal();
    setIsShowNotification(true);
  };

   const actionsBottom = (<>
    <PopperMenu
        contentBtn={<DButton typeOfButton="addArrow">New</DButton>}
        popperProps={{ array: [
            { func: onNewInvoice, content:  "Invoice" },
            { func: toggleAddSupplierModal, content: "Supplier"  },
            { func: onNewImports, content: "Imports"  },
          ]}}
        styleEl="transactions"
      />
      <PopperMenu
        contentBtn={<DButton typeOfButton="searchArrow">View</DButton>}
        popperProps={{ array: [
            { func: () => {}, content:  "Invoice" },
            { func: () => {}, content: "Payables"  },
            { func: () => {}, content: "Imports"  },
          ]}}
        styleEl="transactions"
      />
  </>);

  return (
    <div>
      <div className="d-splash-screen--wrap">
        {suppliersLoading && <DSplashScreen />}
        <TableComponent
          headRows={headRows}
          items={items(suppliers)}
          rowsPerPageSelected={rowsPerPage}
          loading={suppliersLoading}
          rowProps={{
            goToPage,
            onEdit,
            onAttachments: (company && company.uses_receipt_upload) && onAttachments,
            onDelete,
            onNameClick
          }}
        />
      </div>
      <div className="container-button">{actionsBottom}</div>
      <EditAddTransactionModal
        show={isAddSupplierModalShow}
        onClose={toggleAddSupplierModal}
        onSuccess={onSuccessNew}
        currentTab={currentTab}
        reset={reset}
      />
      {currentRow &&
      <EditAddTransactionModal
        show={isEditModalShow}
        onClose={toggleEditModal}
        initValues={currentRow}
        onSuccess={onSuccessEdit}
        currentTab={currentTab}
        reset={reset}
      />}
      {currentRow && <ReceiptUploadsModal show={isReceiptUploadsModalShow} onClose={toggleReceiptUploadsModalShow} />}
      {currentRow &&
      <TransactionModal
        data={currentRow}
        show={isTransactionModalShow}
        onClose={toggleTransactionModal}
        headRowsModal={headRowsModal}
        valueTab={valuesTitle.SUPPLIERS}
        reset={reset}
      />}
      {successNotification}
    </div>
  )
}

EnterTransactionsSuppliers.propTypes = {
  headRows: PropTypes.array,
  confirm: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number,
  reload: PropTypes.bool,
  searchQuery: PropTypes.string,
  items: PropTypes.func,
};

export default withConfirmHook(EnterTransactionsSuppliers);
