import React from "react";
import moment from 'moment'
import toast from '../../components/toast/toast'
import {floatNumber, floatNumberFixed, formatNumber, replaceIndex} from '../utils'
import IconButton from "@material-ui/core/IconButton";
import {toAbsoluteUrl} from "../../../../_metronic";
import CloseIcon from '@material-ui/icons/Close'
import DoneIcon from '@material-ui/icons/Done'
import {Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormComponent from "../../components/controls/FormComponent";
import {ReactComponent as MoreIcon} from "../../../media/icons/expand-arrows.svg";
import {ReactComponent as MoreOpenIcon} from "../../../media/icons/expand-arrows-opened.svg";
import {requiredValidator} from "../../components/controls/validators";

export const limitExpenses = 8;
export const limitExpensesTransactions = 8;

const dateFormatBack = "DD/MM/YYYY";
export const expensesDateFormatFront = (date) => moment(date, dateFormatBack).format('MM-DD-YYYY');
export const expensesDateFormatBack = (date) => moment(date, 'MM-DD-YYYY').format(dateFormatBack);

export const Periods = {
  Once: "ONCE",
  Daily: "DAILY",
  Weekly: "WEEKLY",
  Bimonthly: "BIMONTHLY",
  Monthly: "MONTHLY",
  Quarterly: "QUARTERLY",
  Biannual: "BIANNUAL",
  Yearly: "YEARLY",
};

export const frequencyOptions = (isCalculation) =>
  Object.entries(Periods).reduce((res, [label, value]) =>
    (isCalculation && value === Periods.Daily)
      ? res
      : [...res, {label, value}],
    []);

export const Methods = {
  Accrual: "ACCRUAL",
  "Cash Accounting": "CASH_ACCOUNTING",
  Balanced: "BALANCED",
};

export const methodOptions = Object.entries(Methods).reduce((res, [label, value]) => [...res, {label, value}], []);

export const interfaceExp = (categories) => {
  let categoriesOptions = categories.map((item) => ({label: item.name, value: item.id}));

  return [
    {
      id: "name",
      type: "text",
      label: "Name",
      validator: requiredValidator('Name is required'),
    },
    {
      id: "category_id",
      type: "RSelect",
      label: "Category",
      validator: requiredValidator('Category is required'),
      customProps: {
        options: categoriesOptions
      }
    },
    {
      id: "calculation_method",
      type: "RSelect",
      label: "Method",
      customProps: {options: methodOptions}
    },
    {
      id: "interval",
      type: "RSelect",
      label: "Frequency",
      render: ({customProps, extraProps, ...props}) => {
        const {values, name, setFieldValue} = props;
        const {index, offset} = extraProps;
        const {calculation_method} = values.expenses[offset][index];
        const method = calculation_method?.value || calculation_method;
        const options = frequencyOptions(method === Methods.Balanced);

        const nameEndDate = `expenses.${offset}.[${index}].date_end`;
        const onChange = (value) => {
          setFieldValue(name, value);
          setFieldValue(nameEndDate, null);
        };

        return (
          <FormComponent customProps={{options, onChange}} {...props} />
        )
      }
    },
    {
      id: "date_start",
      type: "dateSecondRB",
      label: "Start Date",
      customProps: {autoUpdateInput: true},
      render: ({customProps, extraProps, ...props}) => {
        const {values, setFieldValue, name} = props;
        const {index, offset} = extraProps;
        const {calculation_method, id} = values.expenses[offset][index];
        const method = calculation_method?.value || calculation_method;
        const isPayDate = method === Methods["Cash Accounting"];
        const nameEndDate = `expenses.${offset}.[${index}].date_end`;

        const onChange = (value) => {
          setFieldValue(name, value);
          setFieldValue(nameEndDate, null);
        };

        return (
          <FormComponent
            disabled={id > -1}
            customProps={{
              autoUpdateInput: true,
              isPayDate,
              onChange
            }}
            {...props}
          />
        )
      }
    },
    {
      id: "date_end",
      type: "dateSecondRB",
      label: "End Date",
      render: ({extraProps, customProps, ...props}) => {
        const {values} = props;
        const {index, offset} = extraProps;
        const {date_start: minDate, interval} = values.expenses[offset][index];
        return (
          <FormComponent {...props} customProps={{autoUpdateInput: true, minDate, interval}} />
        )
      }
    },
    {
      id: "amount", type: "RNumber", label: "Amount (£)",
      validator: requiredValidator('Amount is required'),
      customProps: ({values, extraProps}) => {
        const {index, offset} = extraProps;
        const isNote = values.expenses[offset][index].vat_charged
        return {noteField: isNote && "Exclusive of VAT"}
      }
    },
    {id: "vat", type: "switch", label: "Vat"},
    {
      id: "product_vat",
      type: "RNumberPercent",
      label: "VAT %",
      render: ({extraProps, customProps, ...props}) => {
        const {values} = props;
        const {index, offset} = extraProps;
        const {vat} = values.expenses[offset][index];
        return (
          <FormComponent
            disabled={!vat}
            customProps={{
              NonVatable: !vat,
              options: [
                {label: '0.00%', value: "0.00"},
                {label: '5.00%', value: "5.00%"},
                {label: '20.00%', value: "20.00%"},
                {label: 'Other', value: ""}
              ],
            }}
            {...props}
          />
        )
      }
    },
    {
      id: "more",
      type: "button",
      label: "More",
      render: ({index, offset, onExpand, values, opened}) => {
        const valueRow = values.expenses[offset]?.[index] || {};
        const isOpened = opened.includes(valueRow?.id || null);
        const disabled = !valueRow?.count_transactions || !valueRow.id;
        return (
          <IconButton className="expenses__btn" onClick={onExpand} disabled={disabled}>
            {isOpened ? <MoreOpenIcon/> : <MoreIcon/>}
          </IconButton>
        )
      }
    },
    {
      id: "delete",
      type: "button",
      label: "Delete",
      render: ({onAction}) => {
        return (
          <IconButton className="expenses__btn" onClick={onAction}>
            <img
              alt="img"
              src={toAbsoluteUrl("/media/d-icons/delete-expenses.svg")}
            />
          </IconButton>
        )
      }
    },
  ]
};

export let newExpense = {
  calculation_method: "ACCRUAL",
  count_transactions: 0,
  date_start: moment().format('MM-DD-YYYY'),
  interval: "MONTHLY"
};

export const interfaceTrans = [
  {
    id: "created_at",
    type: "dateRB",
    label: "Created",
    disabled: true,
  },
  {
    id: "expense_amount",
    type: "RNumber",
    label: "Amount (£)",
    disabled: false,
  },
  {
    id: "save",
    type: "button",
    label: "Save",
    render: (props) => {
      const {updateTransaction, touched = {}} = props;
      return (
        <IconButton
          className="cog-settings__btn--save expenses__btn"
          disabled={!touched?.expense_amount}
          onClick={updateTransaction}
        >
          <DoneIcon/>
        </IconButton>
      )
    }
  },
  {
    id: "action",
    type: "button",
    label: "Action",
    render: (props) => {
      const {transaction, isDisabled, onRemove} = props;
      return (
        transaction.is_credit
          ? <IconButton
            className="cog-settings__btn settings__btn"
            onClick={onRemove}
          >
            <CloseIcon className="btn--delete__icon"/>
            <Typography variant="body1" className="btn--delete__text">Delete</Typography>
          </IconButton>

          : <Button
            variant="text"
            disabled={isDisabled}
            className="expenses-credit-button"
            onClick={() => isDisabled ? undefined : onRemove()}
          >
            Credit
          </Button>
      )
    }
  },
];
export const interfaceTransMonth = [
  {
    id: "month",
    type: "text",
    label: "Month",
    disabled: true,
  },
  {
    id: "month_amount",
    type: "RNumber",
    label: "Amount (£)",
    disabled: true,
  },
  {
    id: "action",
    type: "button",
    label: "Action",
    render: (props) => {
      return (
        <Button
          variant="contained"
          size="small"
          color="primary"
          className="transaction-daily-button transparent-label"
          onClick={props.onDaily}
        >
          Daily Breakdown
        </Button>
      )
    }
  },
];

export const getInterface = (isMonths) => isMonths
  ? interfaceTrans.reduce((res, item) => item.id === 'expense_amount'
    ? [
      ...res,
      {
        id: "expense_amount",
        type: "RNumber",
        label: "Amount (£)",
        disabled: false,
        render: ({ customProps, extraProps, ...props }) => {
          const { values, setFieldValue, name } = props;
          const {indexTransaction, offset, id} = extraProps;
          const nameDaily = `transactions.${id}.data.${offset}.${indexTransaction}.daily_amount`;
          const prev = values.transactions[id].data[offset][indexTransaction].expense_amount;
          const date = values.transactions[id].data[offset][indexTransaction].created_at;

          const onChange = (value) => {
            const newAmount = value?.target?.value || "0";
            const daysInMonth = moment(date).daysInMonth();
            const newValue = formatNumber(replaceIndex(newAmount), prev);

            const enterAmount = typeof newValue === "number" ? newValue : +replaceIndex(newValue);
            const newValueDaily = floatNumberFixed(enterAmount / daysInMonth);

            setFieldValue(name, newValue);
            setFieldValue(nameDaily, newValueDaily);
          };

          return (
            <FormComponent customProps={{ onChange }} {...props} />
          )
        }
      },
      {
        id: "daily_amount",
        label: "Daily amount (£)",
        type: "RNumber",
        disabled: true,
      }
    ]
    : [...res, item], [])
  : interfaceTrans;

export const onSubmit = ({updateExpenses}) => (values, {setSubmitting, resetForm, setTouched, ...props}) => {
  localStorage.setItem('isUpdatingExpenses', "true")
  const expensesValues = Object.values(values.expenses).flat();
  const data = expensesValues.map(({expense_transactions, ...item}) => {
    const {
      amount,
      date_start,
      date_end,
      product_vat,
      vat,
      interval,
      calculation_method,
      category_id,
    } = item;

    let _product_vat = vat ? product_vat || 0 : 0;
    if (typeof product_vat === 'string') {
      _product_vat = +product_vat.replace('%', '')
    }

    // let expenseTransactions = [];
    // if (item?.id > -1) {
    //   const rowTransactions = values.transactions?.[item.id]?.data;
    //   if (!_.isEmpty(rowTransactions)) {
    //     expenseTransactions =
    //       Object.values(rowTransactions).flat().map((transaction) => ({
    //         ...transaction,
    //         expense_amount: +transaction.expense_amount,
    //       }))
    //   }
    // }

    return ({
      ...item,
      amount: floatNumber(amount),
      date_start: expensesDateFormatBack(date_start),
      // date_start,
      date_end: (!!date_end && date_end !== "Invalid date")
        ? expensesDateFormatBack(date_end)
        : null,
      // date_end: (!!date_end && date_end !== "Invalid date")
      //   ? date_end
      //   : null,
      product_vat: _product_vat,
      interval: interval?.value || interval,
      calculation_method: calculation_method?.value || calculation_method,
      category_id: category_id?.value || category_id,
      billing_date: null,
      pay_date: null,
    })
  });

  const onDone = () => {
    setSubmitting(false);
    setTouched({});
    resetForm();
    toast.success("Updated!", {duration: 3000}, {isClose: false});
    localStorage.setItem('isUpdatingExpenses', "")
  };

  const onError = (error) => {
    toast.error(error);
    setSubmitting(false)
    setTouched({});
  };

  // console.log("data", data)

  updateExpenses({
    values: data,
    onDone,
    onError,
  })
};

export const checkOpen = ({opened = [], rowId}) => opened?.some((id) => id === rowId);

export const onExpand = ({idExpense, onMore, opened}) => () => {
  const duplicate = opened.some((id) => id === idExpense);
  onMore((old) => duplicate ? old.filter((id) => id !== idExpense) : [...old, idExpense])
};

export const onAction = ({idExpense, confirm, index, onRemoveLine, deleteExpenses}) => () => {
  // if have id, need delete from server
  if (idExpense) {
    confirm(() => {
      onRemoveLine({index});
      deleteExpenses({id: idExpense});
    }, {
      description: 'If you click YES all transactions entered in this line from the beginning date until now will be permanently deleted, this action is irreversible, proceed with caution!',
      confirmationText: "Yes",
      style: "d-confirm"
    })();
  } else {
    onRemoveLine({index});
  }
};