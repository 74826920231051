import React from 'react'
import { Link } from 'react-router-dom'

function Need() {
  return (
    <>
     <section className="need_section" data-aos="fade-in" data-aos-duration="1500" data-aos-delay="100">
      <div className="container">
        <div className="need_block">
          <div className="need_text">
            <div className="section_title">
              <h2>Need support ? Contact our team</h2>
              <p> Mon - Sun: 24/7</p>
            </div>
          </div>
          <div className="need_action">
            <a href="mailto:info@skuify.com" className="btn">info@skuify.com</a>
            <a href="#faqBlock-anchor" className="faq_btn">Read The FAQ </a>
          </div>
        </div>
      </div>
    </section> 
    </>
  )
}

export default Need
