import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import TableComponent from "../../../components/TableComponent/TableComponent";
import TableToolbarComponent from "../../../components/TableComponent/TableToolbarComponent";
import { connect } from "react-redux";
import * as Selectors from '../../../../selectors/company.selectors';
import * as companyDuck from "../../../../store/ducks/company.duck";
import withConfirmHook from "../../../../hooks/withConfirm.hook";
import PropTypes from 'prop-types';
import { useCheckboxStorage, usePerPage } from "../../../../hooks/modal.hook";
import Wrapper from "../../../components/Wrapper";

const headRows = [
  { id: 'company_name', numeric: false, disablePadding: true, label: 'Company Name', isActive:true  },
  { id: 'company_type_name', numeric: false, disablePadding: true, label: 'Business Type', isActive:true  },
  { id: 'email.', numeric: false, disablePadding: true, label: 'Email', isActive:true },
  { id: 'partner_accountant', numeric: false, disablePadding: true, label: 'Partner Accountant', isActive:true, },
  { id: 'date_incorporated', numeric: false, disablePadding: true, label: 'Created on', isActive:true, align: "center", },
  { id: 'of_trans.', numeric: false, disablePadding: true, label: '# Of Trans.', isActive:true, align: "center", },
  {id: 'actions', numeric: false, disablePadding: true, label: 'Actions', isActive:true, align: "center"},
];

const title = "All Companies";

function Companies({confirm, loading, getCompanies, companies, deleteCompany}) {
  const [companiesActiveColumns, setCompaniesActiveColumns] = useCheckboxStorage("companies", headRows);
  const [rowsPerPage, onPerPage] = usePerPage();

  useEffect(() => {
    getCompanies()
  }, []);

let history = useHistory();

  const goToCompany = (row) => () => {
    const rowCopy = {...row};
    localStorage.setItem("company", JSON.stringify(rowCopy.id));
    history.push("/dashboard");
  };

  const onDelete = (row) => (
    confirm(() =>
      { deleteCompany({ id: row.id }) },
      { description: 'This action is permanent!' }
    )
  );
  const onRefresh = () => getCompanies();
  const onExport = () => console.log("onExport");
  const selectMenu = {setFunc: onPerPage, type: "select",};
  const viewColumnMenu = {
    array: companiesActiveColumns,
    type: "filter",
    setFunc: setCompaniesActiveColumns,
    disabled: true,
  };
  const actionsHeader = { onRefresh, selectMenu, onExport, viewColumnMenu };

  return (
    <Wrapper>
      <TableToolbarComponent actionsHeader={actionsHeader} title={title} />
      <TableComponent
        headRows={companiesActiveColumns.filter(column => column.isActive)}
        items={companies}
        rowsPerPageSelected={rowsPerPage}
        rowProps={{
          onDelete,
          goToCompany,
        }} />
    </Wrapper>
  );
}

Companies.propTypes = {
  deleteCompany: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  getCompanies: PropTypes.func.isRequired,
  companies: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    companies: Selectors.getCompanies(state),
    loading: Selectors.getLoading(state),
  }
};

const mapDispatchToProps = {
  getCompanies: companyDuck.actions.getCompanies,
  updateCompany: companyDuck.actions.updateCompany,
  deleteCompany: companyDuck.actions.deleteCompany,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withConfirmHook(Companies))
