import React from "react";
import { entryMethods } from "../bankOptions";
import LinkIcon from '@material-ui/icons/Link';
import DButton from "../../../components/Button";
import { PLAID_ACCESS_TOKEN_FIEL_NAME } from "./EditAddBankingModal";
import * as PropTypes from "prop-types";
import { createPlaid } from "../PlaidImport";

const MButton = ({ onClick, children }) =>
  <div className="container-button container-button--modal container-button--bottom">
    <DButton onClickCustom={onClick}>{children}</DButton>
  </div>;

// buttons for options Stripe Feed and Bank Feed at the entry_method
const BtnEntryMethod = ({ value, values, setFieldValue }) => {
  // if we edit bank account value would be string, when create new bank, value object
  const _name = typeof value === 'string' ? value : value && value.label;

  if (_name === entryMethods.STRIPE_FEED) {
    return <MButton>Integrate with Stripe</MButton>
  }
  if (_name === entryMethods.BANK_FEED) {
    const plaidToken = values[PLAID_ACCESS_TOKEN_FIEL_NAME];
    if (plaidToken) {
      return <MButton disabled><LinkIcon/>Connected</MButton>
    } else {
      const setPlaidToken = (value) => setFieldValue(PLAID_ACCESS_TOKEN_FIEL_NAME, value);
      return <MButton onClick={createPlaid(setPlaidToken)}><LinkIcon/> Connect Bank Account</MButton>
    }
  }
  return null;
};

BtnEntryMethod.propTypes = {
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default BtnEntryMethod;