import React, {useState} from "react";
import {Button, IconButton, Typography} from "@material-ui/core";
import {StepProps, WizardProps} from "./utils";
import './wizard.scss';
import {useHistory} from "react-router-dom";

const Wizard = ({open, onClose, list}: WizardProps) => {
  let history = useHistory();
  const allStepsName = Object.keys(list);
  const [activeStep, setActiveStep] = useState<StepProps>({active: allStepsName[0], prev: ''});
  const step = list[activeStep.active];
  const {title, text, content, onBack, onNext} = step;

  const onStep = (step: StepProps) => setActiveStep(() => step.active ? step : {
    ...step,
    active: activeStep.prev || ''
  });

  return open && (
    <div className="d-splash-screen wizard">
      <div className="list-item">
        {onBack && (
          <IconButton className="close back" onClick={onBack({onStep})}>
            <img src={require('./../../../media/wizardIcons/back.svg')} className="back-icon" alt="back-icon"/>
          </IconButton>
        )}
        <IconButton className="close" onClick={onClose}>
          <img src={require('./../../../media/wizardIcons/close.svg')} className="close-icon" alt="close-icon"/>
        </IconButton>
        <Typography className="title">{title}</Typography>
        <Typography className="subtitle">{text}</Typography>

        <div className="content">
          {content.map(({icon, actionElement, adapter, onAction, type}, index) => {
            return (
              <React.Fragment key={index}>
                {type === "DnD"
                  ? actionElement
                  : <>
                    <div className="list-item__content" onClick={onAction?.({history, onStep})}>
                      <img src={icon} alt="icon"/>
                      {actionElement}
                    </div>
                    <Typography className="title adapter">{adapter}</Typography></>
                }
              </React.Fragment>
            )
          })}
        </div>
        {onNext && (
          <Button
            variant="contained"
            size="large"
            color="primary"
            className="wizard-btn"
            onClick={onNext({onStep})}
          >
            Next Step
          </Button>
        )}
      </div>
    </div>
  )
};

export default Wizard;