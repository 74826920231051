export const initLayoutConfig = {
  demo: "demo3",
  // == Page Splash Screen loading
  loader: {
    enabled: true,
    type: "brand",
    // type: "spinner-logo",
  // logo: toAbsoluteUrl("/media/logos/logo-mini-md.png"),
    // message: "Please wait..."
  },
  // == Colors for javascript
  colors: {
    state: {
      brand: "#104D82",
      light: "#ffffff",
      dark: "#282a3c",
      primary: "#104D82",
      success: "#5ebd3e",
      info: "#009cdf",
      warning: "#f78200",
      danger: "#f694d6"
    },
    base: {
      label: ["#c5cbe3", "#a1a8c3", "#3d4465", "#3e4466"],
      shape: ["#f0f3ff", "#d9dffa", "#afb4d4", "#646c9a"]
    }
  },
  // page toolbar
  toolbar: {
    display: true
  },
  header: {
    self: {
      width: "fluid", // fixed|fluid
      skin: "light",
      fixed: {
        desktop: true,
        mobile: true
      }
    },
    // menu: {
    //   self: {
    //     display: true,
    //     layout: "default",
    //     "root-arrow": false,
    //     "icon-style": "duotone"
    //   },
    //   desktop: {
    //     arrow: true,
    //     toggle: "click",
    //     submenu: {
    //       skin: "light",
    //       arrow: true
    //     }
    //   },
    //   mobile: {
    //     submenu: {
    //       skin: "dark",
    //       accordion: true
    //     }
    //   }
    // }
  },
  subheader: {
    display: false,
    displayDesc: false,
    displayDaterangepicker: true,
    layout: "subheader-v1",
    fixed: true,
    width: 'fluid', // fixed|fluid,
    clear: false,
    style: 'solid'
  },
  content: {
    width: "fluid"
  },
  brand: {
    self: {
      skin: "dark"
    }
  },
  aside: {
    self: {
      skin: "dark",
      display: true,
      fixed: true,
      minimize: {
        toggle: true,
        default: false
      }
    },
    footer: {
      self: {
        display: true
      }
    },
    menu: {
      dropdown: "false",
      scroll: false,
      "icon-style": "duotone",
      submenu: {
        accordion: true,
        dropdown: {
          arrow: true,
          "hover-timeout": 100
        }
      }
    }
  },
  footer: {
    self: {
      width: 'fluid',
      fixed: false
    }
  }
};

let LayoutConfig = JSON.parse(JSON.stringify(initLayoutConfig)); // deep object copy
export default LayoutConfig;