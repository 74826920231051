import React from "react";
import * as PropTypes from "prop-types";
import TableComponent from "../../components/TableComponent/TableComponent";
import { Typography } from "@material-ui/core";
import useCategories from "../../../hooks/categories.hook";
import TotalTable from "./TotalTable";
import { dataTotalTable } from "./dataTotalTable";

const ReportsTable = ({ headRows, onNameClick, data }) => {
  const {categories} = useCategories();
  return <div className="offset-sm-3 col-sm-6 reports__table">
    {data.name && <Typography variant="h6" className="reports__table__title">
      {data.name}
    </Typography>}
    <TableComponent
      className="reports__table-component"
      headRows={headRows}
      items={data.data ? data.data : []}
      isPagination={false}
      loading={categories.loading}
      rowProps={{ onNameClick }}
      bottomTable={(<TotalTable total={dataTotalTable(data.name)} nameBalance={data.name} />)}
    />
  </div>
};

ReportsTable.propTypes = {
  onNameClick: PropTypes.func,
  headRows: PropTypes.array,
  data: PropTypes.object,
};

export default ReportsTable;