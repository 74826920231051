import React, { useEffect, useState, useRef } from "react";
import { TableRow, TableCell, TableBody, Table } from "@material-ui/core";
import * as PropTypes from "prop-types";
import TableHeadComponent from './TableHeadComponent';
import "./styles.scss";
import EmptyBlock from "../EmptyBlock";
import classNames from "clsx";
import TableRowComponent from "./TableRowComponent";
import { Pagination } from "@material-ui/lab";
import {Select, MenuItem, FormControl, InputLabel} from "@material-ui/core";

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const DEFAULT_TABLE_ITEM_PER_PAGE = 15;

const ActionCell = ({ children }) => {
  return <TableCell align="center" className="table__actions">
    <div className="action-buttons-table">
      {children}
    </div>
  </TableCell>
};

export const getCountPage = ({ totalCount, rowsPerPage }) => Math.ceil(totalCount / rowsPerPage);

function TableComponent(props) {
  const {
    items = [],
    headRows,
    rowProps,
    onSelectedItems,
    isCheckbox,
    bottomTable,
    rowsPerPageSelected,
    isPagination = true,
    isHeader = true,
    className,
    defaultPerPage,
    defaultOrderBy,
    loading,
    count,
    setParams,
    forEmpty,
    disabled,
    isResetPagination,
    setResetPagination,
    getParams,
    emptyWording,
    extraPagination,
  } = props;

  const totalCount = count ? count : items ? items.length : 0;

  const tabOrder = totalCount && headRows[1]?.title || '';

  const [order, setOrder] = useState('asc');
  const tableOrderBy = defaultOrderBy ? defaultOrderBy : tabOrder;
  const [orderBy, setOrderBy] = useState(tableOrderBy);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(1);
  const defaultTablePerPage = defaultPerPage ? defaultPerPage : DEFAULT_TABLE_ITEM_PER_PAGE;
  const [rowsPerPage, setRowsPerPage] = useState(defaultTablePerPage);
  const [countPage, setCountPage] = useState(0);
  const tableRef = useRef();
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  useEffect(() => {
    rowsPerPageSelected && setRowsPerPage(rowsPerPageSelected);
    if (isResetPagination) {
      setResetPagination(false);
      setPage(1);
      setOrder('asc');
      setOrderBy('');
    }
  }, [rowsPerPageSelected, isResetPagination]);

  useEffect(() => {
    setCountPage(getCountPage({ totalCount, rowsPerPage }))
  }, [count, items, defaultPerPage]);

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
    const params = {
      offset: 0,
      ordering: `${isDesc ? '' : '-'}${property}`,
      limit: rowsPerPage,
    };
    setParams && setParams(params);
    setPage(1);
    getParams && getParams(params);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelected = items.map(n => n.id);
      setSelected(newSelected);
      onSelectedItems(newSelected);
      return;
    }
    setSelected([]);
  }

  function handleClick(event, name) {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);

    if (onSelectedItems) {
      onSelectedItems(newSelected)
    }
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
    if (tableRef.current) {
      tableRef.current.scrollIntoView();
    }
    // tableRef?.current.scrollIntoView();
    const offset = (newPage - 1) * rowsPerPage;
    setParams && setParams({offset});
    getParams && getParams({
      offset,
      limit: rowsPerPage,
      ordering: `${order === "asc" ? '' : '-'}${orderBy}`,
    });
  }

  function handleChangeRowsPerPage(event) {
    setPage(1);
    setRowsPerPage(+event.target.value);
    setParams && setParams({ limit: +event.target.value, offset: 0 });
    getParams && getParams({
      limit: +event.target.value,
      offset: 0,
      ordering: `${order === "asc" ? '' : '-'}${orderBy}`
    });
  }

  const onSliceData = (data) => {
    if (setParams || getParams) { // means we use ajax pagination not fake one
      return items
    }
    return isPagination ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : data
  };

  const isSelected = name => selected.indexOf(name) !== -1;
  // const emptyRows = rowsPerPage - Math.min(rowsPerPage, items.length - page * rowsPerPage);
  const fields = headRows.map(({ id }) => id);

  const rowWithExtra = headRows.filter(({ fewLabel }) => fewLabel);
  let countFields = isCheckbox ? fields.length + 1 : fields.length;

  if (rowWithExtra.length !== 0) {
    const countExtra = rowWithExtra.map(({ fewLabel }) => fewLabel.length).reduce((a, b) => a + b);
    countFields = countFields + countExtra - rowWithExtra.length;
  }

  const rowComponentProps = { isSelected, fields, items, isCheckbox, rowProps, handleClick, headRows };

  const slicedItems = (data) => {
    return onSliceData(stableSort((data || []), getSorting(order, orderBy)))
  };
  const getRow = (data) => slicedItems(data).map(row =>
    <TableRowComponent key={row.id} row={row} {...rowComponentProps} />
  );

  const headerProps = { order, orderBy, headRows, isCheckbox, rowProps };
  const oldData = [...items];

  return (
    <>
      <div className={classNames("table__wrapper", className)}>
        <Table ref={tableRef} className={classNames("table", className)} aria-labelledby="tableTitle" size='medium'>
          {isHeader && (
            <TableHeadComponent
              onSelectAllClick={handleSelectAllClick}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              rowCount={items.length !==0 ? items.length : 0}
              disabled={items.length === 0 || disabled}
              {...headerProps}
            />
          )}
          <TableBody>
            {!loading && items.length === 0 && (
              <TableRow>
                <TableCell colSpan={countFields} className="empty-cell">
                  <EmptyBlock className={className}>{forEmpty ? forEmpty : emptyWording || "Not Found"}</EmptyBlock>
                </TableCell>
              </TableRow>
            )}

            {loading && oldData.length === 0 && (
              <TableRow>
                <TableCell colSpan={countFields}>
                  <EmptyBlock className={className} />
                </TableCell>
              </TableRow>
            )}

            {loading && getRow(oldData)}

            {!loading && items.length !== 0 && getRow(items)}

            {bottomTable}
          </TableBody>
        </Table>
      </div>
      {isPagination && (
        <div className="table__pagination-block">
          {extraPagination}
          <FormControl className="rows-per-page">
            <InputLabel className="rows-per-page__label" id="rows-per-page__label">Rows per page:</InputLabel>
            <Select
              labelId="rows-per-page__label"
              id="controlled-open-select"
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              value={rowsPerPage}
              onChange={handleChangeRowsPerPage}
            >
              <MenuItem value={15}>15</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </FormControl>
          <Pagination
            color="primary"
            size="small"
            page={page}
            defaultPage={1}
            siblingCount={2}
            count={countPage}
            onChange={handleChangePage}
          />
        </div>
        )}
    </>
  );
}

export const RowType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  renderText: PropTypes.func,
  render: PropTypes.func,
  onTransactionItems: PropTypes.func,
  label: PropTypes.string,
});

TableComponent.propTypes = {
  items: PropTypes.array.isRequired,
  headRows: PropTypes.arrayOf(RowType),
  rowProps: PropTypes.object,
  isCheckbox: PropTypes.bool,
  bottomTable: PropTypes.element,
  isPagination: PropTypes.bool,
  isHeader: PropTypes.bool,
  defaultPerPage: PropTypes.number,
  rowsPerPageSelected: PropTypes.number,
  className: PropTypes.string,
  tableOrderBy: PropTypes.string,
  loading: PropTypes.bool,
  forEmpty: PropTypes.element,
  disabled: PropTypes.bool,
  isResetPagination: PropTypes.bool,
  setResetPagination: PropTypes.func,
};

TableComponent.ActionCell = ActionCell;

export default TableComponent;
