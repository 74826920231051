import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import moment from 'moment';
import _ from 'lodash';
import * as categoryDuck from "../../../../../store/ducks/category.duck";
import * as BankDuck from "../../../../../store/ducks/bunk.duck";
import * as TransactionsDuck from "../../../../../store/ducks/transactions.duck";
import * as CategorySelector from "../../../../../selectors/category.selectors";
import * as TransactionsSelector from "../../../../../selectors/transactions.selectors";
import * as PropTypes from "prop-types";
import {Table, TableHead, TableBody, TableRow, TableCell} from "@material-ui/core";
import {Input, IconButton} from '@material-ui/core';
import FunctionsIcon from '@material-ui/icons/Functions';
import {ActionButtonsNewTransaction} from "./ActionButtonsNewTransaction";
import {FieldArray, Form as FormikForm, Formik} from "formik";
import QuickConversionTransactionModal from "../QuickConversionTransactionModal";
import {typeOptions} from "../../bankOptions";
import ReceiptUploadsModal from "../../../../components/ReceiptUploadsModal/ReceiptUploadsModal";
import DButton from "../../../../components/Button";
import {round} from "../../utils";
import * as commonDuck from "../../../../../store/ducks/common.duck";
import * as commonSelectors from "../../../../../selectors/common.selectors";
import {requiredValidator} from "../../../../components/controls/validators";
import {BankType} from "../../../../../types";
import Spinner from "../../../../components/Spinner";
import {getCurrentCompanyId} from "../../../../../crud/utils";
import Notification from "../../../../components/Notification";
import AutocompleteWithTooltip from "../AutocompleteWithTooltip";
import SplitBankTransactionModal from "../SplitBankTransactionModal";
import {useSubmit} from "../../../../../hooks/submit.hook";
import NotificationForm from "../../../../components/NotificationForm";
import TransactionCell from "./TransactionCell";
import useCurrencies from "../../../../../hooks/currencies.hook";
import useCategories from "../../../../../hooks/categories.hook";
import useCompany from "../../../../../hooks/company.hook";
import {useParseHistory} from "../../../../../hooks/modal.hook";

const getTaxValue = (value) => value ? value : 0;

const getFieldName = (index, name) => `transactions.${index}.${name}`;

const TransactionForm = (props) => {
  const {
    taxes,
    getTaxes,
    bank,
    getConvRates,
    saveTransaction,
    getDescriptions,
    onSubmit,
    isShowSuccess,
    setIsShowSuccess,
    actionComponent,
    extraColumns=[],
    renderRowInfo,
  } = props;
  let {initialData} = props;
  const [isSplitModalShow, setIsSplitModalShow] = useState(false);
  const [conversionRateDisable, setConversionRateDisable] = useState(false);
  const [isQuickConvModalShow, setIsQuickConvModalShow] = useState(false);
  const [isReceiptUploadsModalShow, setIsReceiptUploadsModalShow] = useState(false);
  const toggleReceiptUploadsModalShow = () => setIsReceiptUploadsModalShow(!isReceiptUploadsModalShow);
  const toggleQuickConvModalShow = () => setIsQuickConvModalShow(!isQuickConvModalShow);
  const [errors, isErrorsShow, toggleErrorsShow, onSubmitFormClick] = useSubmit("transactions");
  const [currRow, setCurrRow] = useState(null);
  const bankEntryId = useParseHistory().bank_entry_id;

  const {company} = useCompany();

  const toggleSplitModalShow = () => setIsSplitModalShow(!isSplitModalShow);

  const { currencies } = useCurrencies();
  const { categories } = useCategories();

  const onSplit = (index, values) => () => {
    setCurrRow(values[index]);
    toggleSplitModalShow();
  };

  useEffect(() => {
    getTaxes();
    getDescriptions({companyId: getCurrentCompanyId()});
  }, []);

  if (!bank || !currencies.fetched || !categories.fetched) {
    return <Spinner className="d-spinner--full-height" />;
  }

  const initialTransaction = {
    date: '2020-01-01',
    // type: 'MI',
    currency: bank.currency.id,
    type: undefined,
    category: undefined,
    description: undefined,
    net_amount: undefined,
    tax_code: undefined,
    conversion_rate: undefined,
    tax_amount: undefined,
  };

  if (!initialData) {
    initialData = {};
    initialData.transactions = [
      initialTransaction
    ]
  } else {
    // const oldData = initialData;
    // initialData = {};
    // initialData.transactions = [
    //   {
    //     ...oldData,
    //     category: {
    //       label: oldData.bank_account && oldData.bank_account.name,
    //       value: oldData.bank_account && oldData.bank_account.nominal_code
    //     }
    //   },
    // ]
  }

  const addNewRow = (arrayHelpers) => () => {
    arrayHelpers.push(initialTransaction)
  };

  const removeRow = (arrayHelpers, index) => () => {
    arrayHelpers.remove(index);
  };

  const Actions = actionComponent || ActionButtonsNewTransaction;

  const transactionsInterface = [
    {
      id: 'date',
      label: 'Date',
      type: 'date',
      validator: requiredValidator("Date is required"),
    },
    {
      id: 'type',
      label: 'Type',
      type: 'RSelect',
      validator: requiredValidator("Type is required"),
      customProps: () => {
        return {
          options: typeOptions.map(({label, value}) => (
            {
              label: `${label} | ${value}`,
              value
            }
          ))
        }
      },
    },
    {
      id: 'category',
      label: 'Category',
      type: 'RSelect',
      validator: requiredValidator('Category is required'),
      customProps: () => ({index, setFieldValue, values}) => {
        const onChange = ({category, ...value}) => {
          setFieldValue(getFieldName(index, 'category'), {...value, category});

          // if category doesn't have default_tax_code set NV
          const nvTax = taxes.find(({taxcode}) => taxcode === "NV");
          setFieldValue(getFieldName(index, 'tax_code'), category.default_tax_code || nvTax.id);

          if (value) {
            if (bank.currency !== category.currency) {
              // getConvRates(bank.currency, category.currency).then(() => {
              //   // setFieldValue(getFieldName(index, 'conversion_rate'), e.target.value);
              // });
            }
          }
        };

        console.log('categories', categories);
        console.log(bank);
        return {
          onChange,
          options: categories.details.filter(
            (item) => {
              if (item.account_type === "BankAccount" && item.id === bank.id) { // ignore current bank account
                return false
              }
              return true;
            }
          ).map((category) => ({label: category.name, value: category.id, category}))
        }
      }
    },
    {
      id: 'invoices',
      label: 'Invoices',
      render: () => <DButton typeOfButton="defaultSecondary">Invoices</DButton>,
    },
    {
      id: 'description',
      label: 'Description',
      type: 'text',
      validator: requiredValidator("Description is required"),
      render: (props) => {
        const onChange = (e, value) => {
          props.setFieldValue(props.name, value);
        };
        return <div className="form-group__text--auto">
          <AutocompleteWithTooltip
            onChange={onChange}
            value={props.value}/>
        </div>
      }
    },
    {
      id: 'currency',
      label: 'Currency',
      validator: requiredValidator("Currency is required"),
      // defaultValue: bank.currency,
      type: 'RSelect',
      customProps: () => () => {
        return {
          options: currencies.details.map((i) => ({
            label: i.name,
            value: i.id,
          })),
        }
      },
    },
    {
      id: 'net_amount',
      label: 'Net',
      type: 'number',
      validator: requiredValidator("Net is required"),
      customProps: () => ({index, setFieldValue, values}) => {
        const onChange = (e) => {
          let tax = 0;
          if (values.tax_code && values.tax_code.value) {
            tax = getTaxValue(values.tax_code.tax);
          }
          const taxAmount = e.target.value / 100 * tax;
          setFieldValue(getFieldName(index, 'tax_amount'), round(taxAmount));
          setFieldValue(getFieldName(index, 'total_amount'), round(taxAmount + parseFloat(e.target.value)));
          setFieldValue(getFieldName(index, 'net_amount'), e.target.value);
        };

        return {
          onChange,
        }
      },
    },
    {
      id: 'tax_code',
      validator: requiredValidator("Tax Code is required"),
      label: 'Tax Code',
      type: 'RSelect',
      customProps: () => ({index, setFieldValue, values}) => {
        const onChange = (value) => {
          let tax = getTaxValue(value.tax);
          const netAmount = parseFloat(values.net_amount);
          const taxAmount = round((netAmount / 100) * tax);
          setFieldValue(getFieldName(index, 'tax_amount'), taxAmount);
          setFieldValue(getFieldName(index, 'tax_code'), value);
          setFieldValue(getFieldName(index, 'total_amount'), round(netAmount + taxAmount));
        };
        return {
          onChange,
          options: taxes.map((tax) => {
              return {label: tax.name, value: tax.id, tax: parseFloat(tax.tax_rate)}
            }
          )
        }
      },
    },
    {
      id: 'tax_amount',
      validator: requiredValidator("Tax is required"),
      label: 'Tax',
      type: 'number',
    },
    {
      id: 'total_amount',
      label: 'Total',
      type: 'number',
      validator: requiredValidator("Total is required"),
      customProps: () => ({index, setFieldValue, values}) => {
        const onChange = (e) => {
          let taxPercent = 0;
          if (values.tax_code && values.tax_code.value) {
            taxPercent = getTaxValue(values.tax_code.tax);
          }
          const total = parseFloat(e.target.value);
          const netAmount = round(total * 100 / (taxPercent + 100));
          const taxAmount = round((netAmount / 100) * taxPercent);
          setFieldValue(getFieldName(index, 'total_amount'), total);
          setFieldValue(getFieldName(index, 'tax_amount'), taxAmount);
          setFieldValue(getFieldName(index, 'net_amount'), netAmount);
        };
        return {
          onChange,
        }
      },
    },
    {
      id: 'conversion_rate',
      label: 'Conv. Rate',
      disable: conversionRateDisable,
      render: ({showLabel, setFieldValue, setFieldTouched, customProps, ...props}) => (
        <div className="form-group__text--conversion">
          <Input
            {...props}
            inputProps={{'aria-label': 'conversion_rate',}}
          />
          {!conversionRateDisable &&
          <IconButton color="primary" onClick={toggleQuickConvModalShow}>
            <FunctionsIcon/>
          </IconButton>
          }
        </div>
      )
    },
  ];

  const formatTransactionValue = (transaction) => {
    transaction.company = getCurrentCompanyId();
    transaction.bank_account = bank.id;
    // transaction.account_type = "BankAccount";
    transaction.account_type = initialData && initialData.account_type
      ? transaction.account_type
      : transaction.category.category.account_type;
    transaction.date = moment(transaction.date).format('YYYY-MM-DDThh:mm');
    transaction.type = initialData && initialData.type ? transaction.type : transaction.type.value;
    transaction.category = transaction.category && transaction.category.value;
    transaction.tax_code = initialData && initialData.tax_code ? transaction.tax_code : transaction.tax_code && transaction.tax_code.value;
    transaction.currency = bank.currency.id;
    transaction.conversion_rate = bank.conversion_rate || 0;
    return transaction;
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initialData}
      onSubmit={async (data, actions) => {
        const values = _.clone(data);
        values.transactions.forEach((transaction) => formatTransactionValue(transaction));
        await onSubmit(values, actions);
      }}
    >
      {({...form}) => {

        const setConvRate = (index) => (value) => {
          form.setFieldValue(getFieldName(index, 'conversion_rate'), value);
        };

        const errorTransaction = errors && isErrorsShow &&
          <Notification onClose={toggleErrorsShow} errors={errors} name="transaction"/>;

        const columnsLength = transactionsInterface.length + extraColumns.length + 1;
        return (
          <FormikForm>
            <div className="new-bank-transaction">
              {errorTransaction}
              <div className="wrap-new-bank-transaction-list">
                <Table className="form__table">
                  <TableHead>
                    <TableRow className="form__row">
                      {transactionsInterface.map(item =>
                        (<TableCell key={item.id} className="form__cell">{item.label}</TableCell>)
                      )}
                      <TableCell className="form__cell">Actions</TableCell>
                      {extraColumns.map((item) =>
                        (<TableCell key={item.id} className="form__cell">{item.label}</TableCell>)
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <FieldArray
                      name="transactions"
                      render={(arrayHelpers) => {
                        return form.values.transactions.map((values, index) => {
                          const row = form.values.transactions[index];
                          return (
                            <React.Fragment key={index}>
                              <TableRow key={index} className="form__row form__row--body">
                                {
                                  transactionsInterface.map((item) => (
                                    <TransactionCell key={item.id} form={form} values={values} item={item} index={index} />
                                  ))
                                }
                                <TableCell aria-label="Actions" className="form__cell">
                                  <Actions
                                    addNewRow={addNewRow(arrayHelpers)}
                                    deleteTransaction={removeRow(arrayHelpers, index)}
                                    onSplit={onSplit(index, form.values.transactions)}
                                    onReceiptUploads={company.uses_receipt_upload ? toggleReceiptUploadsModalShow : null}
                                    rowIndex={index}
                                    row={row}
                                  />
                                </TableCell>

                                {extraColumns.map((item) => (
                                  <TableCell className="form__cell" aria-label={item.label} key={row.id}>
                                    {item.render({
                                      id: row.id,
                                      values: formatTransactionValue({...row}),
                                      index
                                    })}
                                  </TableCell>
                                ))}

                                <QuickConversionTransactionModal
                                  submit={setConvRate(index)}
                                  show={isQuickConvModalShow}
                                  onClose={toggleQuickConvModalShow}
                                />
                              </TableRow>

                              {renderRowInfo && renderRowInfo({ index, columnsLength })}
                            </React.Fragment>
                          )
                        })
                      }}
                    />
                  </TableBody>
                </Table>
              </div>
              {!bankEntryId && <DButton onClickCustom={onSubmitFormClick(form)} typeOfButton="doneAll" disabled={form.isSubmitting}>
                Save All
              </DButton>}
              <SplitBankTransactionModal
                show={isSplitModalShow}
                onClose={toggleSplitModalShow}
                initValues={currRow}
                taxes={taxes}
                currencies={currencies}
              />
              <ReceiptUploadsModal show={isReceiptUploadsModalShow} onClose={toggleReceiptUploadsModalShow}/>
              <NotificationForm
                open={isShowSuccess}
                onClose={() => setIsShowSuccess(false)}
                anchorOrigin={{vertical: "top", horizontal: "right",}}/>
            </div>
          </FormikForm>
        )
      }}
    </Formik>
  )
};

TransactionForm.propTypes = {
  getDescriptions: PropTypes.func.isRequired,
  saveTransaction: PropTypes.func.isRequired,
  getConvRates: PropTypes.func.isRequired,
  bank: BankType,
  // title: PropTypes.number.isRequired,
  initialData: PropTypes.object,
  // bankId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  actionComponent: PropTypes.elementType,
  extraTransactionsInterface: PropTypes.array,
  renderRowInfo: PropTypes.func,
};


const mapStateToProps = (state, props) => {
  return {
    descriptions: TransactionsSelector.getDescriptions(state, props),
    taxes: commonSelectors.getTaxes(state),
  }
};

const mapDispatchToProps = {
  saveTransaction: BankDuck.actions.saveTransaction,
  getDescriptions: TransactionsDuck.actions.getDescriptions,
  getTaxes: commonDuck.actions.getTaxes,
  getConvRates: commonDuck.actions.getConvRates,
};


export default connect(mapStateToProps, mapDispatchToProps)(TransactionForm);
