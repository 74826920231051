import React, { useEffect, useState } from 'react'
import useTab from '../../../../hooks/tab.hook'
import TabWithTable from './TabWithTable'
import DTabs from '../../../components/DTabs/DTabs'
import * as PropTypes from 'prop-types'
import OverlayComponent from '../../../components/OverlayComponent'
import { headRows, headRowsRefunds } from './ordersHead'
import TabsToolbar from '../../Sales/TabsToolbar'
import { useHistory } from 'react-router-dom'
import { useModal } from '../../../../hooks/modal.hook'
import AlertDialog from '../../../components/AlertDialog/AlertDialog'
import { actionsCOG, EmptyRefunds } from './components'
import useOrders from '../../../../hooks/orders.hook'
import debounce from 'lodash/debounce'
import useReports from '../../../../hooks/reports.hook'
import ContentCell from '../../Products/ContentCell'
import {getDataWithCOGSPopup} from "../../Products/utils";

const OrdersRefunds = (props) => {
  const {
    setParams,
    loading,
    orders,
    refunds,
    disabled,
    isPagination,
    onSelectedMarketplace,
    isResetPagination,
    setResetPagination,
    refreshPage,
    location
  } = props;

  let {push} = useHistory();
  const {pathname, state} = location;

  const [currentTab, setTab] = useTab('orders')
  const [currentRow, setCurrentRow] = useState({})
  const [searchQuery, setSearchQuery] = useState()
  const [pageParams, setPageParams] = useState({...state})
  const [openDialog1, toggleDialog1] = useModal()
  const [openDialog2, toggleDialog2] = useModal()
  const [isShowPopup, setShowPopup] = useState(false)
  const [anchor, setAnchor] = useState(null)
  const [isAsinUpdate, setAsinUpdate] = useState(false)
  const { updateOrderCOG, notification } = useOrders({ fetch: false })
  const { getOrderReport, orderReport, reportId, reportType, orderReportLoading: isLoading } = useReports()
  const [availableRefresh, setAvailableRefresh ] = useState(false)

  const isDash = pathname === "/dashboard";

  useEffect(() => {
    state?.q && push({...location, state: {...state, q: ""}})
  }, [state?.q]);

  const onHoverRow = (row, type, isShowReport) => () => {
    if (row.id !== currentRow.id) {
      setCurrentRow(row)
    }

    isShowReport && (reportId !== row.id || reportType !== type) && getOrderReport({ id: row.id , type, table: currentTab });
  };

  const getParams = (p) => {
    (!p.offset && !p.ordering) && !!setResetPagination && setResetPagination(true);
    setPageParams(old => ({...old, ...p}));
  }

  const search = debounce((q) => {
    const newParams = {q, offset: 0};
    setSearchQuery(q);
    !!refreshPage && refreshPage(newParams);
    !!setResetPagination && setResetPagination(true);
    getParams(newParams)
  }, 500);

  useEffect(() => {
    if (notification.new_orders && !loading && availableRefresh)  {
      if (isDash) {
        refreshPage({})
      }
      else {
        refreshPage && refreshPage({...pageParams, autoRefresh: true})
      }
    } else {
      setAvailableRefresh(true);
    }
  }, [notification])

  const onDialog = (row) => (e) => {
    setCurrentRow(row)
    isShowPopup ? setShowPopup(false) : toggleDialog1()
    setAnchor(e.currentTarget)
  }

  const onConfirmDialog1 = () => {
    toggleDialog1()
    toggleDialog2()
  }

  const onCloseDialog2 = () => {
    toggleDialog2();
    setShowPopup(true)
    setAsinUpdate(false)
  }

  const onAsin = () => {
    toggleDialog2()
    setShowPopup(true)
    setAsinUpdate(true)
  }

  const pageName = isDash ? "dash" : "sales"

  const onEdit = (row) => async (values) => {
    return new Promise((resolve, reject) => {
      updateOrderCOG({
        id: row.id,
        values,
        pageName: pageName,
        table: currentTab,
        onDone: (data) => {
          resolve(true);
          !isDash && isAsinUpdate && refreshPage(pageParams)
          setAnchor(null)
        },
        onError: (error) => {
          reject(error)
        }
      })
      setShowPopup(false)
    })
  }

  const onChangeTab = (event, newValue) => {
    setTab(event, newValue)
    setParams && setResetPagination(true);
    setParams && setParams(newValue)({ ordering: "", offset: 0, q: ""})
    setPageParams({})
  }

  const getInfoRefund = (data) => {
    setTab(null, "orders")
    setResetPagination(true)
    setParams("orders")({ q: data, offset: 0 })
  }

  const style = isDash ? "dashboard__tab-table" : null

  const commonProps = {
    getParams,
    loading,
    disabled,
    isPagination,
    className: style,
    isDisplay: true,
    setResetPagination,
    isResetPagination,
    searchQuery,
    pageName,
    rowProps: {
    ContentCell: (
      ({ ...props }) => (
        <ContentCell
          asin_update={isAsinUpdate}
          isShowPopup={isShowPopup && currentRow.id === props.row.id}
          customAnchor={anchor}
          onUpdate={onEdit(props.row)}
          pageName={pageName}
          bepType="order_item"
          {...props}
        />
      )
    ),
      currentRow,
      onDialog,
      onEdit,
      isShowPopup,
      onHoverRow,
      isExtraDataCell: true,
    }
  };
  const tabsData = [
    {
      value: "orders",
      label: <OverlayComponent tooltip="Use this tab to view your latest orders">
        <span>Orders</span>
      </OverlayComponent>,
      toolbar: (!isDash && (
        <TabsToolbar
          setParams={setParams && setParams("orders")}
          setSearchQuery={search}
          // setParams={setParams}
          onSelectedMarketplace={onSelectedMarketplace}
          tab={currentTab}
          searchQuery={pageParams.q}
        />
      )),
      toolbarBottom: (!isDash && (
        <TabsToolbar
          pageParams={pageParams}
          getParams={getParams}
          setParams={setParams && setParams("orders")}
          isBottom
        />
      )),
      content: orders && (
        <TabWithTable
          {...commonProps}
          rowProps={{
            contentCellProps: {
            asin_update: isAsinUpdate,
            customAnchor: anchor,
            pageName,
            bepType: "order_item",
          },
            currentRow,
            onDialog, // for alert dialog
            onEdit,
            isShowPopup, // for alert dialog
            onHoverRow,
            isExtraDataCell: true,
            orderReport,
            isLoading,
            reportType
          }}
          headRows={headRows}
          data={getDataWithCOGSPopup(orders.results)}
          count={orders.count}
          setParams={setParams && setParams("orders")}
          emptyWording="No orders in this marketplace in the last 3 months."
        />
      )
    },
    {
      value: "refunds",
      label: <OverlayComponent tooltip="Use this tab to view your latest refunds">
        <span>Refunds</span>
      </OverlayComponent>,
      toolbar: (!isDash && (
        <TabsToolbar
          setParams={setParams && setParams("refunds")}
          setSearchQuery={search}
          onSelectedMarketplace={onSelectedMarketplace}
          tab={currentTab}
          searchQuery={pageParams.q}
        />
      )),
      toolbarBottom: (!isDash && (
        <TabsToolbar
          pageParams={pageParams}
          getParams={getParams}
          setParams={setParams && setParams("refunds")}
          isBottom
        />
      )),
      content: refunds && (
        <TabWithTable
          {...commonProps}
          rowProps={{
            contentCellProps: {
              asin_update: isAsinUpdate,
              customAnchor: anchor,
              pageName,
              bepType: "order_item",
            },
            currentRow,
            onDialog, // for alert dialog
            onEdit,
            isShowPopup, // for alert dialog
            onHoverRow,
            isExtraDataCell: true,
            orderReport,
            isLoading,
            reportType,
            getInfoRefund
          }}
          headRows={headRowsRefunds}
          data={refunds.results}
          forEmpty={!searchQuery ? <EmptyRefunds /> : null}
          count={refunds.count}
          setParams={setParams && setParams("refunds")}
        />
      )
    },
  ]

  return (
    <>
      <DTabs value={currentTab} tabsData={tabsData} onChange={onChangeTab} className="dashboard" />
      
      <AlertDialog
        className="cog-edit__dialog"
        open={openDialog1}
        onClose={toggleDialog1}
        actions={actionsCOG({ onClose: toggleDialog1, onConfirm: onConfirmDialog1 })}
        content="Are you sure you want to edit the COG as this order has already been placed?"
      />
      <AlertDialog
        className="cog-edit__dialog"
        open={openDialog2}
        onClose={toggleDialog2}
        actions={actionsCOG({ onClose: onCloseDialog2, onConfirm: onAsin, edit: true })}
        content='Do you wish to edit the COG for just this sale or for this ASIN?'
      />
    </>
  )
}

OrdersRefunds.propTypes = {
  loading: PropTypes.bool,
  orders: PropTypes.object,
  refunds: PropTypes.object,
  isPagination: PropTypes.bool,
  disabled: PropTypes.bool,
  isResetPagination: PropTypes.bool,
  setResetPagination: PropTypes.func,
  setParams: PropTypes.func,
  onSelectedMarketplace: PropTypes.func,
  refreshPage: PropTypes.func,
}

export default OrdersRefunds
