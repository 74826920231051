import React, { useEffect, useRef } from "react";
import Chart from 'chart.js/auto';
import { useSelector } from "react-redux";
import { metronic } from "../../_metronic";

export default function QuickStatsChart({
  value,
  desc,

  // array of numbers
  data,
  // chart line color
  color,
  // chart line size
  border,
  // array of strings
  labels
}) {
  const canvasRef = useRef();
  const { pointHoverBackgroundColor } = useSelector(state => ({
    pointHoverBackgroundColor: metronic.builder.selectors.getConfig(
      state,
      "colors.state.danger"
    )
  }));

  useEffect(() => {
    const config = {
      type: "line",
      data: {
        labels,
        datasets: [
          {
            label: "",
            backgroundColor: color,
            borderColor: color,
            borderWidth: border,
            pointHoverBackgroundColor: color,
            pointHoverRadius: 0,
            pointHoverBorderWidth: 0,
            pointBackgroundColor: Chart.helpers
              ?.color(color)
              ?.alpha(1)
              ?.rgbString(),
            pointBorderColor: Chart.helpers
              ?.color(color)
              ?.alpha(1)
              ?.rgbString(),

            pointHoverBorderColor: Chart.helpers
              ?.color(color)
              ?.alpha(1)
              ?.rgbString(),
            fill: false,
            data: data
          }
        ]
      },
      options: {
        plugins: {
          title: {
            display: false
          },
          tooltip: {
            enabled: false,
            intersect: false,
            mode: "nearest",
            xPadding: 10,
            yPadding: 10,
            caretPadding: 10
          },
          legend: {
            display: false,
            labels: {
              usePointStyle: false
            }
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          hover: {
            mode: "ErrorsPage.js"
          },
        },
        scales: {
          x: {
            display: false,
            grid: false,
            title: {
              // display: true,
              // labelString: "Month"
            }
          },
          y: {
            display: false,
            grid: false,
            beginAtZero: true,
            title: {
              display: true,
              labelString: "Value"
            },
          }
        },

        elements: {
          point: {
            radius: 0,
            borderWidth: 1
          },
          line: {
            borderWidth: 1,
            borderColor: color,
            showLine: true,
            spanGaps: true,
            cubicInterpolationMode: "monotone"
          },
        },

        layout: {
          fullSize: true,
          autoPadding: true,
          padding: {
            left: 0,
            right:0,
            top: 15,
            bottom: 30,
          }
        }
      }
    };

    const chart = new Chart(canvasRef.current, config);

    return () => {
      chart.destroy();
    };
  }, [data, color, border, pointHoverBackgroundColor]);

  return (
    <div className="kt-widget26">
      <div className="kt-widget26__chart d-chart__note">
        <canvas ref={canvasRef} id="kt_chart_quick_stats_1"  style={{width:"100%", height: '100px'}} />
      </div>
    </div>
  );
}
