import React from "react";
import {FormHelperText} from "@material-ui/core";
import RSelect from "../RSelect";

const RSelect_custom = allProps => {
  const {
    name,
    setFieldValue,
    setFieldTouched,
    placeholder,
    label,
    meta,
    showLabel,
    customProps = {},
    submitCount,
    ...props
  } = allProps;
  const {options, onBlur, byId, onChange, disabled, indicator} = customProps;
  let value = props.value ? props.value : 'all';
  const handleChange = (value) => {
    if (onChange) {
      onChange(value);
    } else {
      setFieldValue(name, value);
    }
  };
  const handleBlur = () => {
    setFieldTouched(name, true);
    onBlur && onBlur(props.values)
  };
  const errorRSelect = (meta.touched || submitCount) && meta.error && !meta.value ? "RSelect-error" : null;
  return <>
    <RSelect
      {...props}
      disabled={disabled || props.disabled}
      value={value}
      byId={byId}
      label={label}
      name={name}
      options={options}
      setFieldValue={setFieldValue}
      onChange={handleChange}
      onBlur={handleBlur}
      className={errorRSelect}
      indicator={indicator}
    />
    {(meta.touched || submitCount) && meta.error && !meta.value && (
      <FormHelperText error id="component-error-text">
        {meta.error}
      </FormHelperText>
    )}
  </>
};

export default RSelect_custom;