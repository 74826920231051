import {Field, Form as FormikForm, Formik} from "formik";
import React from "react";
import * as PropTypes from "prop-types";
import DButton from "../../components/Button";
import {getCurrentCompanyId} from "../../../crud/utils";
import FormField from "../../components/controls/FormField";
import {useHistory} from "react-router-dom";
import {goToEnterTransactionPage} from "./utils";

const SettingForm = ({updateCompany, data, fields, formButtons, onNotification }) => {

  let history = useHistory();
  const onCancel = () => goToEnterTransactionPage(history);

  return (
    <Formik
      enableReinitialize
      initialValues={data}
      onSubmit={(values, {setSubmitting}) => {
        const data = {...values};

        data.vat_registration_attributes_vat_reporting_period =
          data.vat_registration_attributes_vat_reporting_period.value
            ? data.vat_registration_attributes_vat_reporting_period.value
            : data.vat_registration_attributes_vat_reporting_period;

        data.vat_registration_attributes_ec_sales_reporting_period =
          data.vat_registration_attributes_ec_sales_reporting_period.value
            ? data.vat_registration_attributes_ec_sales_reporting_period.value
            : data.vat_registration_attributes_ec_sales_reporting_period;

        updateCompany({
          id: getCurrentCompanyId(),
          values: data,
          onDone: () => {
            setSubmitting(false);
            onNotification();
          },
        });
      }}
    >
      {(form) => (
        <FormikForm>
          <div className="offset-sm-3 col-sm-6">
            {fields.map(({name, label, type, customProps, render}) => {
                return <Field name={name} key={label}>
                  {({
                      field,
                      form: {touched, errors},
                      meta,
                    }) => (
                    <div className="books__field">
                      <FormField
                        render={render}
                        setFieldValue={form.setFieldValue}
                        name={name}
                        type={type}
                        label={label}
                        placeholder={label}
                        meta={meta} {...field}
                        showLabel={true}
                        customProps={customProps}
                        {...form}
                      />
                    </div>
                  )}
                </Field>
              }
            )}
            {formButtons}
            <div className="container-button container-button--modal container-button--settings-books">
              <DButton typeOfButton="doneAllSecondary" type="submit" disabled={form.isSubmitting}>Update Company</DButton>
              <DButton typeOfButton="exit" onClickCustom={onCancel}>Cancel</DButton>
            </div>
          </div>
        </FormikForm>
      )}
    </Formik>
  )
};

SettingForm.propTypes = {
  data: PropTypes.any,
  fields: PropTypes.any.isRequired,
  updateCompany: PropTypes.func.isRequired,
};

export default SettingForm;
