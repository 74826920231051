import React from "react";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import Icon from "@material-ui/core/Icon";
// import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import { createTheme } from "@material-ui/core/styles";
import { amber } from "@material-ui/core/colors";

const theme = createTheme({
  palette: {
    primary: amber,
  },
});


export const UserTableActions = ({ row, onDelete, onEdit, }) => {
  return (
    <div className="action-buttons-table">
      <ThemeProvider theme={theme}>
        <IconButton
          color="primary"
          onClick={onEdit}
          disabled={row.build_in}
          data-action="edit"
        >
          <Icon>edit_icon</Icon>
        </IconButton>
      </ThemeProvider>

      <ThemeProvider theme={theme}>
        <IconButton
          color="secondary"
          onClick={onDelete}
          disabled={row.build_in}
          data-action="delete"
        >
          <DeleteIcon />
        </IconButton>
      </ThemeProvider>
    </div>
  )
};
