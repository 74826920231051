import React, {useState} from "react";
import {Button, Typography} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import classNames from "clsx";
import * as PropTypes from "prop-types";
import FormField from "../../../components/controls/FormField";
import Form from "react-bootstrap/Form";
import {fbaDisabledFields, notRenderCogs, mfDisabledFields} from "./utils";
import {floatNumber} from "../../utils";
import {ReactComponent as ExpandIcon} from "../../../../media/settings/Expand Line.svg";
import {ReactComponent as CollapseIcon} from "../../../../media/settings/Collapse Line.svg";

export const Headers = () => (
  <div className="forms__headers forms__headers--hide">
    <div className="forms__headers__field-header">
      <Typography variant="h6" className="forms__headers--item">Item</Typography>
      <Typography variant="h6" className="forms__headers--move">Move</Typography>
      <Typography variant="h6" className="forms__headers--date">Start Date</Typography>
      <Typography variant="h6" className="forms__headers--end_date">End Date</Typography>
      <Typography variant="h6" className="forms__headers--amount">Amount</Typography>
      <Typography variant="h6" className="forms__headers--vat">VAT</Typography>
      <Typography variant="h6" className="forms__headers--add">Add/Delete</Typography>
    </div>
  </div>
);

export const Total = ({total, currency}) => (
  <div className="total-cog__container">
    <Typography variant="body1" className="total_cog__title">current total</Typography>
    <div className="total_cog__value-wrap">
      <span className="total_cog__value">
        {/*<span className="total_cog__currency">{currencySymbols[currency]}</span>*/}
        <span className="total_cog__total">{total}</span>
      </span>
    </div>
  </div>
);

Total.propTypes = {
  total: PropTypes.string,
  currency: PropTypes.string,
};

export const Buttons = ({ form }) => (
  <div className="cog-settings__wrap-btn settings__wrap-btn">
    <div className="cog-settings__container-btn-description">
      <Typography variant="subtitle1" className="btn__description">
        Click "UPDATE" to save your changes and to apply these COGS to new ASINs
        arriving in your inventory from this point forward.
      </Typography>
      <div className="wrap">
        <Button
          className="cog-settings__btn settings__btn"
          variant="contained"
          type="submit"
          disabled={form.isSubmitting}
        >
          Update
        </Button>
      </div>
    </div>
  </div>
);

export const LineActions = ({ index, expandId=[], setExpandId, collapsed, withHistory }) => {
  const handleExpand = (index) => () => setExpandId(old => [...old, index]);
  const onCollapse = (index) => () => setExpandId(expandId.filter(i => i !== index));

  const isExpand = expandId.find(id => id === index);
  const disabled = !withHistory.some(id => index === id);

  if (!!collapsed) {
    return <IconButton className="settings__btn empty" />
  }
  if (!!isExpand) {
    return (
      <IconButton className="settings__btn btn--open" onClick={onCollapse(index)}>
        <CollapseIcon />
      </IconButton>
    )
  } else {
    return (
      <IconButton
        className={classNames("settings__btn btn--close", { "disabled": disabled })}
        onClick={handleExpand(index)}
        disabled={disabled}
      >
        <ExpandIcon />
      </IconButton>
    )
  }
};

LineActions.propTypes = {
  index: PropTypes.string,
  expandId: PropTypes.array,
  setExpandId: PropTypes.func,
  collapsed: PropTypes.string,
  withHistory: PropTypes.array,
};


const KgLbSelector = ({ disabled, form, dataName }) => {
  let values = form.values[dataName];
  const postageUnitsIdx = values.map(({name}) => name).indexOf('inbound_shipping_units');
  const value = floatNumber(values[postageUnitsIdx]?.value);

  const [kgActive, setKgActive] = useState(value === 1);
  const [lbActive, setLbActive] = useState(value === 2);

  const handleSetActive = val => () => {
    values[postageUnitsIdx].value = val;
    disabled || form.setFieldValue(dataName, form.values[dataName]);

    if (val === 1) {
      setKgActive(true);
      setLbActive(false);
    } else {
      setKgActive(false);
      setLbActive(true);
    }
  };
  return (
      <div className="kg-lb-selector">
        <span onClick={handleSetActive(1)} className={classNames({'active': kgActive})}>kg</span>
        <span onClick={handleSetActive(2)} className={classNames({'active': lbActive})}>lb</span>
      </div>
  )
};

export const LineArray = (props) => {
  const {
    interfaceArray,
    dataName,
    arrayHelpers,
    index,
    values,
    form,
    onAddDelete,
    actions,
    inboundShippingUnits,
    inboundShipmentUnitsArrayData,
  } = props;
  const disabled = (
      (mfDisabledFields.includes(values.name) && ['mf_cog', 'sfp_cog'].includes(dataName)) ||
      (fbaDisabledFields.includes(values.name) && dataName === "fbm_cog")
  );


  return !notRenderCogs.includes(values.name) && (
    <div key={index} className={classNames("forms__line", {"greyed-out": disabled, "first-line": !index})}>
      {interfaceArray.map((item) => {
        const name = `${dataName}.${index}.${item.id}`;
        const disable_vat_free_postage = item.id === 'is_vat_charged' && values.name === 'vat_free_postage';
        const endStyle = disabled || disable_vat_free_postage || (item.id === "end_date" && !values.parent && !values.end_date);
        const renderUnits = values.name === 'inbound_shipping' && item.id === 'value';
        const showPercent = values.name === 'extra_fee_perc' && item.id === 'value';

        return (
          <Form.Group
            key={name}
            controlId={name}
            className={classNames(`form-group--${name}`, {"empty": endStyle})}
          >
            {(
                <>
                  <Form.Label
                    column={false}
                    className={classNames("form-group__label-item", {"forms__headers--item": !index})}
                  >
                    {item.label}
                  </Form.Label>
                  <FormField
                    name={name}
                    label={item.label}
                    type={item.type}
                    customProps={item.customProps && item.customProps({suffix: showPercent ? ' %' : ''})}
                    extraProps={{index, actions, disabled, arrayHelpers, onAddDelete}}
                    disabled={disabled || disable_vat_free_postage || !!values.parent}
                    render={item.render}
                    {...form}
                  />
                  {
                    renderUnits && (
                      <KgLbSelector
                        disabled={disabled} form={form} dataName={dataName}
                        inboundShippingUnits={inboundShippingUnits}
                        inboundShipmentUnitsArrayData={inboundShipmentUnitsArrayData}
                      />
                    )
                  }
                </>
              )
            }
          </Form.Group>
        )
      })}
    </div>
  )
};

LineArray.propTypes = {
  interfaceArray: PropTypes.array,
  dataName: PropTypes.string,
  arrayHelpers: PropTypes.object,
  index: PropTypes.number,
  values: PropTypes.object,
  form: PropTypes.object,
  setExpandId: PropTypes.func,
  expandId: PropTypes.array,
  withHistory: PropTypes.array,
  setWithHistory: PropTypes.func,
  onAdd: PropTypes.func,
};
