import React from 'react';
import { toAbsoluteUrl } from "../../../../../../_metronic";

import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import {Link} from "react-router-dom";

SwiperCore.use([Pagination]);

function Trusted() {
  const satting = {
    responsive: {
      0: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
  };

  const companyLogos = [
    { id: 1, src: 'media/codely-theme-files/assets/images/skuify/BBP-LOGOORGINAL.png', alt: 'bbp', link: '/products?#tab=imports' },
    { id: 2, src: 'media/codely-theme-files/assets/images/skuify/DarkerPPP-Logo.png', alt: 'ppp', link: '/repricer' },
  ];

  return (
    <section className="row_am trusted_section">
      <div className="container">
        <div
          className="section_title"
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-delay="100"
        >
          <h2>
            Integrated with Amazon Sellers Favourite Ecosytem
          </h2>
          <p>
            Fully Integrated with BuyBotPro the world's best fully automated deal analysis tool and is the #1 resellers deal analysis tool in the world. And it's fully integrated with SKUify meaning as you source products you can add them to your SKUify from the BBP chrome extension. This one is a huge time saver and makes adding stock extremely easy and fast!
          </p>
          <p>
            Integrated Repricing with ProfitProtectorPro users can control their mins, maxes and repricing strategies straight from within SKUify meaning your profit analytics and your repricing can be done within the same platform. Invaluable time saver and groundbreaking AI based, upwards focused repricing power. PPP sold separately
          </p>
        </div>

        <div className="company_logos" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100" >
          {/*{companyLogos.map((logo) => (*/}
            {/*<div key={logo.id} className="logo">*/}
              {/*<img src={toAbsoluteUrl(logo.src)} alt={logo.alt} />*/}
            {/*</div>*/}
          {/*))}*/}
          <Swiper
            id="company_slider"
            className="mySwiper"
            data-aos="fade-in"
            data-aos-duration="1000"
            {...satting}
            pagination
            speed={1500}
            // autoplay
            slidesPerView={companyLogos.length}
          >
            {companyLogos.map((logo) => (
              <SwiperSlide key={logo.id} className="item">
                <div className="logo">
                  <a href={logo.link} target="_blank">
                    <img src={logo.src} alt={logo.alt} />
                  </a>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div id="pricing-anchor" />
    </section>
  );
}

export default Trusted;