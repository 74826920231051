import React, {useEffect} from "react";
import useReports from "../../../hooks/reports.hook";
import {checkOpen, interfaceExp} from "./utils";
import FormField from "../../components/controls/FormField";
import {ExpensesTooltips} from "./ExpensesTooltips";
import OverlayComponent from "../../components/OverlayComponent";
import Form from 'react-bootstrap/Form'
import classNames from "clsx";

const ExpensesRows = props => {
  const {form, index, values, opened, onFormValues, onFormTouched, offset} = props;
  const {expensesCategories} = useReports();

  useEffect(() => {
    onFormValues(form.values);
  }, [form.values]);

  useEffect(() => {
    onFormTouched(form.touched)
  }, [form.touched]);


  return (
    <div className={classNames('forms__line', {
      'forms__line--opened': checkOpen({opened, rowId: values.id}),
      title: !index
    })}
    >
    {interfaceExp(expensesCategories).map((item) => {
        const {customProps, id, label, type, render, validator} = item;
        const name = `expenses.${offset}.[${index}].${id}`;
        return (
          <Form.Group key={id} controlId={id} className={`form-group--${id}`}>
            <OverlayComponent tooltip={ExpensesTooltips[id]} className="expenses__tooltip">
              <Form.Label className="form-group__label-item" column={false}>
                {label}
              </Form.Label>
            </OverlayComponent>
            {type === 'button'
              ? render({...props, extraProps: {index, offset}, values: form.values})
              : <FormField
                  name={name}
                  label={label}
                  type={type}
                  customProps={customProps}
                  extraProps={{index, offset}}
                  disabled={id === 'date_start' && values.id}
                  render={render}
                  validate={validator}
                  {...form}
                />
            }
          </Form.Group>
        )
      })}
    </div>
  )
};

export default ExpensesRows;