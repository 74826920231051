import React from 'react'
import {toAbsoluteUrl} from "../../../../../../_metronic";

function Solution() {
  const solutions = [
    {
      id: 1,
      img: "media/codely-theme-files/assets/images/new/soution_icon1.png",
      title: "Profit Analytics ",
      description: "Know exactly how much money you are making, or how much you are losing on your inventory giving you valuable insight into how your stock (and your business) are doing."
    },
    {
      id: 2,
      img: "media/codely-theme-files/assets/images/new/soution_icon2.png",
      title: "Reconciliation Feature ",
      description: "Tracks your damaged, lost & missing inventory with amounts reimbursed, owed by Amazon and a one click raise a case and chase a case feature. Means more money in your pocket, that you are rightly owed, quickly and easily!"
    },
    {
      id: 3,
      img: "media/codely-theme-files/assets/images/new/soution_icon3.png",
      title: "Period Comparison Tools",
      description: "You can compare time periods & results together. Want to see how you are doing this quarter versus last quarter? Easy! Or maybe this year versus last year? No problem!"
    }
  ];

  return (
    <>
     <section className="solution_section">
      <div className="solution_inner">
        <div className="container">
          <div className="section_title" data-aos="fade-up" data-aos-duration="1500">
            <h2>Solution made for</h2>
            <p>Amazon and Online Arbitrage Sellers, designed to simplify inventory management, optimize pricing,
              <br/>and provide real-time analytics, all in one user-friendly platform.</p>
          </div>
          <div className="row">
            {solutions.map((solution) => (
              <div className="col-md-4" key={solution.id}>
                <div className="solution_cards" data-aos="fade-up" data-aos-duration="1500">
                  <div className="icon">
                    <img src={toAbsoluteUrl(solution.img)} alt="image"/>
                  </div>
                  <div className="text">
                    <h3>{solution.title}</h3>
                    <p>{solution.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section> 
    </>
  )
}

export default Solution
