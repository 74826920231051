import React from "react";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import Login from "./Login";
import { Helmet } from "react-helmet";
import { toAbsoluteUrl } from "../../../_metronic";
import "../../../_metronic/_assets/sass/pages/login/login-1.scss";
import ResetPassword from "./ResetPassword";
import moment from "moment";

export default function AuthPage({userLastLocation}) {
  const year = moment().format("YYYY");
  return (
      <>
        {/* https://github.com/nfl/react-helmet */}
        <Helmet>
          {/* <link
            type="text/css"
            rel="stylesheet"
            href={toAbsoluteUrl(
                "/assets/css/demo1/style.bundle.css"
            )}
        />
        <link
          type="text/css"
          rel="stylesheet"
          href={toAbsoluteUrl(
            "/assets/css/demo1/pages/login/login-1.css"
          )}
        /> */}
        </Helmet>

        <div className="kt-grid kt-grid--ver kt-grid--root">
          <div
              id="kt_login"
              className="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v1"
          >
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
              <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1 kt-login__wrapper onboarding">
                <img alt="Logo" src={toAbsoluteUrl("/media/logos/new-logo.svg")}/>
                <Switch>
                  <Redirect exact from="/auth" to="/auth/login" />

                  <Route path="/auth/login" component={Login}>
                    <Login userLastLocation={userLastLocation} />
                  </Route>
                  <Route path="/auth/registration" component={Registration} />
                  <Route exact path="/auth/forgot-password" component={ForgotPassword} />
                  <Route exact path="/auth/forgot-password/confirm" component={ResetPassword} />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </>
  );
}
