import React, {useState} from 'react';
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import {Form as FormikForm, Formik} from "formik";
import {requiredValidator} from "../../../components/controls/validators";
import FormField from "../../../components/controls/FormField";
import * as PropTypes from "prop-types";
import DButton from "../../../components/Button";
import {getCurrentCompanyId} from "../../../../crud/utils";
import Notification from "../../../components/Notification";
import {onValidation} from "../../../components/CreateEditModal";

export default function OfficersCreateEditModal({ initValues, updateOfficer, addOfficer, showModal, onClose, onNotification }) {
  const [errors, setErrors] = useState([]);
  const [isShowErrors, setIsShowErrors] = useState(false);
  const toggleShowErrors = () => setIsShowErrors(false);

  initValues = initValues || {};

  const resetValue = () => setErrors( []);

  const onCloseModal = () => {
    // reset value state with errors
    setErrors([]);
    // hide notification with errors
    setIsShowErrors(false);
    // close modal
    onClose();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initValues}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        const onDone = () => {
          setSubmitting(false);
          onClose();
          resetForm();
          onNotification();
        };
        if (initValues.id) {
          updateOfficer({
            id: initValues.id,
            values,
            onDone,
          });
        } else {
          addOfficer({
            values: {...values, "company": +getCurrentCompanyId()},
            onDone,
          });
        }
      }}
    >
      {({validateForm, ...form}) => (
        <Modal show={showModal} onHide={onCloseModal} className="modal--small" >
          <FormikForm>
            <Modal.Header closeButton>
              <Modal.Title>Edit Officer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {isShowErrors && <Notification errors={errors} onClose={toggleShowErrors} name={"Officer"}/>}
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Name</Form.Label>
                <FormField
                  label="Name"
                  name="name"
                  type={"string"}
                  validate={requiredValidator("Name is required") }
                  {...form}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <div className="container-button container-button--modal">
                <DButton
                  typeOfButton="doneAllSecondary"
                  type="submit"
                  disabled={form.isSubmitting}
                  onClickCustom={onValidation(form.validateForm, setErrors, setIsShowErrors, resetValue)}>
                  Save Changes
                </DButton>
                <DButton
                  typeOfButton="close"
                  onClickCustom={onCloseModal}>
                  Close
                </DButton>
              </div>
            </Modal.Footer>
          </FormikForm>
        </Modal>
      )}
    </Formik>
  );
}

OfficersCreateEditModal.propTypes = {
  addOfficer: PropTypes.func.isRequired,
  updateOfficer: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  initValues: PropTypes.object,
};
