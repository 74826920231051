import {useState} from "react";
import _ from "lodash";
import logger from "../pages/logger";
import {validatorValue} from "../pages/home/Category/validatorValue";

export const useSubmit = (
  title = "",
  data = [],
  initData = {},
  err = [],
  errVal = [],
  errorsShow = false,
) => {
  const [errors, setErrors] = useState(err);
  const [isErrorsShow, setIsErrorsShow] = useState(errorsShow);

  // toggle for hide error message
  const toggleErrorsShow = () => setIsErrorsShow(false);

  const onSubmitFormClick = (form) => () => {
    setErrors([]);
    //TODO not sure what is going on here, need to check
    form.validateForm().then((errs) => {
      const type = form.values.category_type && form.values.category_type.value;
      const code = form.values.code;

      // invalid category code
      let errorValue = type && code && validatorValue(type, code);

      // checking for the existence of code in the database
      const doubleEnterCode = code && data.find(item => item == code);
      const isShowErrorDoubleCode = initData && initData.nominal_code && doubleEnterCode === initData.nominal_code
      ? undefined : doubleEnterCode;
      const double = `Category code '${code}' is already in use. Please choose an alternative code`;

      setTimeout(() => {
        // THAT IS HACK, TODO FIX LATER
        form.setSubmitting(false);
      }, 1000);
      if (!_.isEmpty(errorValue) || !_.isEmpty(isShowErrorDoubleCode) || !_.isEmpty(errs) ) {
        // set required errors
        !_.isEmpty(errs) && errs[title]
          ? setErrors(Object.values(errs[title][0]))
          : setErrors(Object.values(errs).map(err => {
            if (Array.isArray(err)) {
              return Object.values(err[0]).join("\n");
            } else {
              return err;
            }
          }));

        // set invalid category code
        errorValue && setErrors(old => [...old, errorValue]);

        // set error double enter category code
        isShowErrorDoubleCode && setErrors(old => [...old, double]);

        // show error notification
        setIsErrorsShow(true);
      } else {
        // toggleSuccess();
        setIsErrorsShow(false);
        return form.submitForm();
      }
    }).catch((e) => {
      logger.error(e);
      alert('Please contact support')
    });
  };

  return [errors, isErrorsShow, toggleErrorsShow, onSubmitFormClick];
};
