import React, { useEffect, useState } from "react";
import { headRows } from "./headRows";
import TableComponent from "../../components/TableComponent/TableComponent";
import Wrapper from "../../components/Wrapper";
import useProducts from "../../../hooks/products.hook";
import useTableParams from "../../../hooks/useTableParams";
import { useModal } from "../../../hooks/modal.hook";
import ReconciliationModal from "./ReconciliationModal";
import {
  interfaceRaiseCase,
  supportAmazon,
  onSubmit,
  getInitValuesRaiseCase,
  getInitValuesChaseCase,
  CASE_BUTTON,
  onError,
} from "./utils";
import "./modal.scss";
import Toolbar from "./Toolbar";
import debounce from "lodash/debounce";
import useUsers from "../../../hooks/users.hook";
import {currencySymbols} from "../utils";
import ReconciliationInfoModal from "./ReconciliationInfoModal";
import _ from "lodash";

const Reconciliation = () => {
  const {
    getRaiseCase,
    updateRaiseCase,
    raiseCase,
    getReconciliation,
    reconciliation,
    reconciliationLoading: loading,
    reconciliationTotalCount: count
  } = useProducts();
  const { setParams } = useTableParams(getReconciliation);
  const [openRaiseCase, toggleOpenRaiseCase] = useModal();
  const [openChaiseCase, toggleOpenChaiseCase] = useModal();
  const [showExtraRow, setChecked] = useState({});
  const [data, setData] = useState([]);
  const [isResetPagination, setResetPagination] = useState(false);
  const { user } = useUsers({ fetch: false });

  const currency = currencySymbols[user.currency];

  const handleChange = (e, row) => {
    setChecked((prev) => prev?.id === row?.id ? {} : row);
  };

  useEffect(() => {
    setData(reconciliation);
  }, [reconciliation]);


  const handlerRaiseCase = () => {
    getRaiseCase({ id: showExtraRow.id, button: CASE_BUTTON.raise_a_case, onError});
    toggleOpenRaiseCase();
  };

  const handleChaiseCase = () => {
    getRaiseCase({ id: showExtraRow.id, button: CASE_BUTTON.chase_a_case, onError});
    toggleOpenChaiseCase();
  };

  const decoratorDebounce = (param, setParams, ms) => debounce((q) => {
    const params = { offset: 0, ordering: "" };
    params.q = q;
    setParams(params);
    setResetPagination(true);
  }, ms || 500);

  return (
    <Wrapper elevation={0}>
      <div
        id="reconciliation-page"
        className="sales page__table__wrap kt-portlet reconciliation"
      >
        <Toolbar setSearchQueryParam={decoratorDebounce('q', setParams)} title="Reconciliation" />
        <TableComponent
          className=""
          headRows={headRows}
          items={data}
          count={count}
          loading={loading}
          setParams={setParams}
          isResetPagination={isResetPagination}
          setResetPagination={setResetPagination}
          rowProps={{
            currency_symbol: currency,
            handleChange
          }}
          emptyWording="No reconciliation to show"
        />
      </div>
      <ReconciliationModal
        closeModal={toggleOpenRaiseCase}
        openModal={openRaiseCase}
        interfaceForm={interfaceRaiseCase}
        initValues={getInitValuesRaiseCase({ data: raiseCase, user})}
        onSubmit={onSubmit({ toggleOpenModal: toggleOpenRaiseCase, updateRaiseCase, id: showExtraRow.id, raiseCase })}
        supportAmazon={supportAmazon}
        submitButtonText="Confirm Case Raise"
        disabled={_.isEmpty(raiseCase)}
      />
      <ReconciliationModal
        closeModal={toggleOpenChaiseCase}
        openModal={openChaiseCase}
        interfaceForm={interfaceRaiseCase}
        initValues={getInitValuesChaseCase({ data: raiseCase, user })}
        onSubmit={onSubmit({ toggleOpenModal: toggleOpenChaiseCase, updateRaiseCase, id: showExtraRow.id, raiseCase })}
        supportAmazon={supportAmazon}
        submitButtonText="Confirm Case Chaise"
        disabled={_.isEmpty(raiseCase)}
      />
      <ReconciliationInfoModal
        handlerRaiseCase={handlerRaiseCase}
        handleChaiseCase ={handleChaiseCase}
        row={showExtraRow}
        handleChange={handleChange}
      />
    </Wrapper>
  )
};

export default Reconciliation;