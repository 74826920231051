import { createSelector } from "reselect";

export const  getSuppliers = createSelector(
  state => state.transaction.suppliers,
  suppliers => suppliers.suppliers,
);

export const getCustomers = createSelector(
  state => state.transaction.customers,
  customers => customers.customers,
);