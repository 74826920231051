import React from "react";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import TooltipComponent from "../../../components/TooltipComponent";
import * as PropTypes from "prop-types";
import clsx from "clsx";

const LabelNameCell = props => {
  const { content, handleEditClick = undefined, validated, tooltip, disabled } = props;
  return (
    <TooltipComponent tooltip={tooltip} tooltipStyle="tab-table__number__btn">
    <span className={clsx("table__cell--detail__content vat", {disabled})} onClick={handleEditClick}>
      {validated && <CheckCircleIcon className="validated-icon" htmlColor="var(--color-active)" />}
      {content}
    </span>
    </TooltipComponent>
  );
};

LabelNameCell.propTypes = {
  content: PropTypes.string,
  validated: PropTypes.bool,
  tooltip: PropTypes.string,
  // tooltip:  PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  handleEditClick: PropTypes.func,
};

export default LabelNameCell;