import React from "react";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import {Typography} from "@material-ui/core";
import clsx from "clsx";
import {withUnits} from "../../utils";
import PopupTable from "../../../components/PopupTable/PopupTable";
import TooltipComponentState from "../../../components/TooltipComponentState";

export const contentItem = props => {
  const { id, name, unit, render, values,  isVertical, extraData, align, value: initValue} = props;

  let value = id ? values[id] : initValue;
 const _value = typeof value === "boolean"
    ? !!value
      ? <CheckIcon htmlColor="var(--d-green)"/>
      : <ClearIcon htmlColor="var(--d-red)"/>
    : unit
      ? withUnits(unit(values?.currency), value) || "-"
      : (value || value === 0)
        ? value
        : "-";
  return (
    <div key={name} className={clsx("block", {vertical: isVertical, "cursor--help": extraData, [`align-${align}`]: align})}>
      <Typography className="block__name">{name}</Typography>
      {render ? render(values) : <Typography className="block__value">{_value} </Typography>}
    </div>
  )
}

const AsinList = props => {
  const {data, values} = props;
  return (
    data.map((interfaceItem) => {
      const { name, extraData } = interfaceItem;
      const contentProps = {...props, ...interfaceItem};
      if (extraData?.id) {
        return (
          <TooltipComponentState
            key={name}
            tooltip={(
              <PopupTable tableData={extraData.content({extra: values[extraData.id] || [] })} />
            )}
            className="metric-row-popup"
          >
            {contentItem(contentProps)}
          </TooltipComponentState>
        )
      }
      return <React.Fragment key={name}>{contentItem(contentProps)}</React.Fragment>
    })
  )
};

export default AsinList;