export const fieldsRule = [
  {label: "", value: "default"},
  {label: "Money In", value: "MI"},
  {label: "Money Out", value: "MO"},
  {label: "Description", value: "description"},
];

// IMPORTANT - NEVER CHANGES `value` !!!!!! NEVER
export const conditions = [
  {label: "Greater than", value: "gt", for_fields: ["MI", "MO"] },
  {label: "Less than", value: "lt", for_fields: ["MI", "MO",]},
  {label: "Equal to", value: "eq", for_fields: ["MI", "MO", "description"]},
  {label: "No equal to", value: "neq", for_fields: ["MI", "MO", "description"]},
  {label: "Includes", value: "includes", for_fields: ["description"]},
  {label: "Excludes", value: "excludes", for_fields: ["description"]},
];

