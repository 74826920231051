import React, {useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {Form as FormikForm, Formik} from "formik";
import * as PropTypes from "prop-types";
import DButton from "./Button";
import Notification from "./Notification";
import logger from "../logger";
import _ from "lodash";

export const onValidation = (validateForm, setErrors, setIsShowErrors, resetValueFunc) => () => {
  // reset value state with errors
  resetValueFunc();

  validateForm()
    .then((errs) => {
      if (!_.isEmpty(errs)) {
        // set errors in state
        const updatedErrors = Object.values(errs).reduce((res, err) => {
            if (Array.isArray(err)) {
              err.map(e => {
                const errorArray = Object.values(e).join("\n");
                return [...res, errorArray];
              });
            }
            return [...res, err];
        }, []);
        setErrors(updatedErrors);

        //show notification with errors
        setIsShowErrors(true);
      } else {
        //hide notification with errors
        setIsShowErrors(false);
      }
    })
    .catch((e) => {
      logger.error(e);
      alert('Please contact support')
    });
};

export default function CreateEditModal(props) {
  const {
    nameNotification,
    onSubmit,
    show,
    onClose,
    modalTitle,
    children,
    className,
    closeButton,
    processErrors,
    setProcessErrors,
    setValueErrors,
  } = props;

  let {initValues, valueErrors} = props;
  initValues = initValues || {};
  valueErrors = valueErrors && valueErrors.length !==0 ? valueErrors : null;

  const [errors, setErrors] = useState([]);
  const [isShowErrors, setIsShowErrors] = useState(false);

  useEffect(() => {
    setErrors([]);
    if (processErrors && valueErrors || processErrors || valueErrors) {
      processErrors && setErrors(old => [...old, processErrors]);
      valueErrors && valueErrors.map(error => setErrors(old => [...old, error]));
      setIsShowErrors(true)
    }
  }, [processErrors, valueErrors]);

  const toggleShowErrors = () => setIsShowErrors(false);

  const resetValue = () => {
    setErrors([]);
    if (processErrors && valueErrors || processErrors || valueErrors) {
      processErrors && setErrors(old => [...old, processErrors]);
      valueErrors && valueErrors.map(error => setErrors(old => [...old, error]));
    }
  };

  const onCloseModal = (reset) => () => {
    // reset value state with errors
    setErrors([]);
    reset();
    setValueErrors && setValueErrors([]);
    setProcessErrors && setProcessErrors(null);
    // hide notification with errors
    setIsShowErrors(false);
    // close modal
    onClose();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initValues}
      onSubmit={onSubmit}
    >
      {({validateForm, ...form}) => (
        <Modal show={show} onHide={onCloseModal(form.resetForm)} className={className}>
          <FormikForm>
            <Modal.Header closeButton>
              <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {isShowErrors && <Notification errors={errors} onClose={toggleShowErrors} name={nameNotification}/>}
              {children(form)}
            </Modal.Body>
            <Modal.Footer>
              <div className="container-button container-button--modal">
                <DButton
                  typeOfButton="doneAllSecondary"
                  disabled={form.isSubmitting}
                  type="submit"
                  onClickCustom={onValidation(validateForm, setErrors, setIsShowErrors, resetValue)}>
                  Save Changes
                </DButton>
                {closeButton
                  ? closeButton
                  : <DButton typeOfButton="close" onClickCustom={onCloseModal(form.resetForm)}>Close</DButton>}
              </div>
            </Modal.Footer>
          </FormikForm>
        </Modal>
      )}
    </Formik>
  );
}

CreateEditModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initValues: PropTypes.object,
  modalTitle: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
  setValueErrors: PropTypes.func,
  setProcessErrors: PropTypes.func,
  nameNotification: PropTypes.string,
  show: PropTypes.bool,
  className: PropTypes.string,
  processErrors: PropTypes.array,
  valueErrors: PropTypes.array,
};
