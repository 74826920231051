import React, { useState } from 'react';
import { Popper, Paper, Fade, Button } from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import * as PropTypes from "prop-types";
import { Form as FormikForm, Formik } from "formik";
import FormField from "../../../components/controls/FormField";
import toast from "../../../components/toast/toast";
import { toAbsoluteUrl } from "../../../../../_metronic";
import TooltipComponent from "../../../components/TooltipComponent";
import Form from "react-bootstrap/Form";
import { TARGET } from "./utils";

export const icon = {
  add: {
    url: toAbsoluteUrl("/media/d-icons/add_note.svg"),
    name: "Add Note",
  },
  added: {
    url: toAbsoluteUrl("/media/d-icons/Note Added.svg"),
    name: "Note Added",
  },
};

const NoteTabTable = ({ row, update, target, link }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => setAnchorEl(anchorEl ? null : event.currentTarget);
  const handleEditClick = (event) => handleClick(event);
  const close = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  const placement = open ? 'left-start' : undefined;

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    const data = {...values};
    const onDone = () => {
      setSubmitting(false);
      resetForm();
      close();
    };
    const onError = (error) => {
      setSubmitting(false);
      toast.error(error, { duration: 5000 });
    };

    target === "ppp"
      ? update({ values, onDone })
      : update({
        id: row.id,
        values: data,
        onDone,
        onError,
      })
  };

  const textFieldLabel = TARGET[target];

  return (
    <>
      <TooltipComponent tooltip={row.note ? "Note Added" : "Add a Note"}>
        <img
          alt="img"
          className="table__cell__img--icon"
          src={row.note ? icon.added.url : icon.add.url}
          onClick={handleEditClick}
        />
      </TooltipComponent>

      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        transition
        className="tab-table__note__root"
        placement={placement}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={close}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper className="tab-table__note__paper ">
                <Formik
                  enableReinitialize
                  initialValues={{ note: row.note }}
                  onSubmit={onSubmit}
                >
                  {({...form}) => (
                    <FormikForm>
                      <div className="tab-table__note__body">
                        {
                          row.product_note && (
                            <>
                              <Form.Label>ASIN Note</Form.Label>
                              <Form.Text>{row.product_note}</Form.Text>
                              <br/>
                            </>
                          )
                        }
                        <Form.Label column={false}>{textFieldLabel}</Form.Label>
                        <FormField
                          name="note"
                          type="textareaAutosize"
                          label=""
                          customProps={{ link }}
                          {...form}
                        />
                        <div className="tab-table__note__wrap-btn">
                          <Button variant="contained" className="tab-table__note__btn" type="submit">Save</Button>
                          <Button variant="contained" onClick={close} className="tab-table__note__btn">Cancel</Button>
                        </div>
                      </div>
                    </FormikForm>
                  )}
                </Formik>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

NoteTabTable.propTypes = {
  row: PropTypes.object,
  update: PropTypes.func,
  target: PropTypes.string,
  link: PropTypes.string,
};

export default NoteTabTable;