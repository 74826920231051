import React from "react";
import { Link } from "react-router-dom";
import objectPath from "object-path";
import { connect } from "react-redux";
import { toAbsoluteUrl } from "../../utils/utils";
import * as builder from "../../ducks/builder";
import {ReactComponent as RegisterIcon} from "../../../app/media/icons/register-icon.svg";

class Footer extends React.Component {
  render() {
    const today = new Date().getFullYear();
    const { footerSelfLayoutIsExtended, footerClasses, footerContainerClasses } = this.props;

    return (
      <div id="kt_footer" className='kt-footer kt-footer--extended kt-grid__item'>
      {/*<div id="kt_footer" className={`kt-footer ${footerClasses} kt-grid__item`}>*/}
        {footerSelfLayoutIsExtended && (
          <div className="kt-footer__top" style={{display: 'none'}}>
            <div className={`kt-container ${footerContainerClasses}`}>
              <div className="row">
                <div className="col-lg-4">
                  <div className="kt-footer__section">
                    <h3 className="kt-footer__title">About</h3>
                    <div className="kt-footer__content">
                      Whatever method you chosse,these nine will
                      make you re-think theway you craft healines
                      Why? Because they all have points in that are
                      worth from a point of view.
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="kt-footer__section">
                    <h3 className="kt-footer__title">Quick Links</h3>
                    <div className="kt-footer__content">
                      <div className="kt-footer__nav">
                        <div className="kt-footer__nav-section">
                          <a href="#">General Reports</a>
                          <a href="#">Dashboart Widgets</a>
                          <a href="#">Custom Pages</a>
                        </div>
                        <div className="kt-footer__nav-section">
                          <a href="#">User Setting</a>
                          <a href="#">Custom Pages</a>
                          <a href="#">Intranet Settings</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="kt-footer__section">
                    <h3 className="kt-footer__title">Get In Touch</h3>
                    <div className="kt-footer__content">
                      <form action="" className="kt-footer__subscribe">
                        <div className="input-group">
                          <input type="text" className="form-control" placeholder="Enter Your Email" />
                            <div className="input-group-append">
                              <button className="btn btn-brand" type="button">Join</button>
                            </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="kt-footer__bottom">
          <div className={`kt-container`}>
            <div className="kt-footer__wrapper">
              <div className="kt-footer__logo">
                <Link to={"/dashboard"}>
                  <img alt="Logo" src={toAbsoluteUrl("/media/logos/new-logo-icon.svg")} />
                </Link>
                <div className="kt-footer__copyright">
                  <span className="kt-footer__copyright__date">
                    {today}
                  </span>
                  <RegisterIcon className="kt-footer__copyright__icon" />
                  <a className="copyright-link" href={`${process.env.REACT_APP_ENDPOINT}contact`} target="_blank">SKUify</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  fluid: objectPath.get(store.builder.layoutConfig, "footer.self.width") === "fluid",
  footerClasses: builder.selectors.getClasses(store, { path: "footer", toString: true}),
  footerContainerClasses: builder.selectors.getClasses(store, { path: "footer_container", toString: true})
});

export default connect(mapStateToProps)(Footer);
