import React from "react";
import * as PropTypes from "prop-types";
import classNames from "clsx";

const Line = ({ color, colorDash, compare }) => {
  let styles = {};
  if (compare) {
    styles.backgroundImage = `linear-gradient(to right, 
     ${color} 20%, ${colorDash} 20% 40%, ${color} 40% 60%, ${colorDash} 60% 80%, ${color} 80% 100%)`;
  } else {
    styles.backgroundColor = color;
  }
  return <div className={classNames("chart-legend--color", {"compare": compare })} style={styles} />
};


Line.propTypes = {
  color: PropTypes.string,
  compare: PropTypes.bool,
};

export default Line;