import {useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux'
import * as NotificationsDuckSelector from '../selectors/notifications.selectors'
import * as notificationsDuck from '../store/ducks/notifications.duck'
import * as ChartSelector from '../selectors/chart.selectors'
import * as chartDuck from "../store/ducks/chart.duck";

const period = 60;
const intervalTime = period * 1000;

const useNotifications = props => {
  const { fetch } = props;
  const dispatch = useDispatch();

  const getOrderNotification = (args) => dispatch(chartDuck.actions.getOrderNotification(args));

  useEffect(() => {
    if (fetch) {
      getOrderNotification({interval: period});
      const interval = setInterval(() => {
        getOrderNotification({interval: period});
      }, intervalTime);
      return () => clearInterval(interval);
    }
  }, [fetch]);

  const deleteOrderNotification = (args) => dispatch(chartDuck.actions.deleteOrderNotification(args));
  const getNotifications = (args) => dispatch(notificationsDuck.actions.getNotifications(args));
  const updateNotifications = (args) => dispatch(notificationsDuck.actions.updateNotifications(args));
  const deleteNotifications = (args) => dispatch(notificationsDuck.actions.deleteNotifications(args));

  const notificationList = NotificationsDuckSelector.getNotifications(useSelector(state => state));
  const notificationOrder = ChartSelector.getOrderNotification(useSelector(state => state));

  return {
    notificationList: notificationList.details,
    notificationLoading: notificationList.loading,
    notificationTotal: notificationList.total,
    getNotifications,
    updateNotifications,
    deleteNotifications,

    notificationOrder: notificationOrder.notification,
    getOrderNotification,
    deleteOrderNotification,
  }
};

export default useNotifications;
