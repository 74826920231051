export const requiredValidator = (errorMsg) => (value) => {
  let error;
  if (!value && value !== 0) {
    error = errorMsg || 'Required';
  }
  return error;
};

export const requiredUpdateValidator = (errorMsgReq, errorMsgValue, initData) => (value) => {
  let error;
  if (!value && value !== 0) {
    error = errorMsgReq || 'Required';
  } else if (value && initData && value === initData ) {
    error = errorMsgValue;
  }
  return error;
};

