import React, {useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import Typography from "@material-ui/core/Typography";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const Notification = ({children, onClose, errors, name,}) => {

  const [isDetailShow, setIsDetailShow] = useState(true);
  const toggleDetailShow = () => setIsDetailShow(!isDetailShow);
  // style notification
  const variant = errors ? "danger" : "success";
  // quantity errors
  let errorsCount;

  const includeArray = errors?.filter(err => {
    if (!Array.isArray(err)) {
      return err.match(/\n/)
    }
    return err
  });

  if (includeArray) {
    errorsCount = errors.length + includeArray.length;
  } else {
    errorsCount = errors && errors.length;
  }

  const errCountMsg = errorsCount === 1 ? "error" : "errors";
  let msg = `${errorsCount} ${errCountMsg} prevented this ${name} from being saved or updated. 
  Please see below for more details.`;

  const errText = errors && errors.map((err, index) =>
    <li key={index}>{err}</li>
  );

  return <div className={`notification notification--${variant}`}>
    {
      errors && <div className="notification__close" onClick={toggleDetailShow}>
        <Typography color="inherit" variant="h6" gutterBottom className="notification__text--title">
         {isDetailShow ? <RemoveIcon/> : <AddIcon/>} {errorsCount} {errCountMsg} detected
        </Typography>
        <IconButton aria-label="close" className="notification__btn" onClick={toggleDetailShow}>
          <CloseIcon fontSize="small"/>
        </IconButton>
      </div>
    }
    {
      isDetailShow && <div className="notification__content">
        {errors
        && <>
          <Typography color="inherit" variant="body1" gutterBottom className="notification__text">
            {msg}
          </Typography>
          <ul className="notification_text--content">
            {errText}
          </ul>
        </>}
        {children
        && <Typography color="inherit" variant="h6" gutterBottom className={`notification_text--${variant}`}>
          {children}
        </Typography>}
      </div>
    }
  </div>
};

export default Notification;