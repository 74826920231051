import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import useSettings from "../../../../hooks/settings.hook";
import { useNotification } from "../../../../hooks/notification.hook";
import useUsers from "../../../../hooks/users.hook";
import DTabs from "../../../components/DTabs/DTabs";
import FormArray from "../COGSettings/FormArray";
import { init, onSubmit, types, interfaceArray } from "./utils";
import { Buttons, Headers, LineArray } from "./components";
import DSplashScreen from "../../../components/SplashScreen/DSplashScreen";
import withConfirmHook from "../../../../hooks/withConfirm.hook";
import { currencySymbols } from "../../utils";

const tabsNames = Object.keys(types);

const ShippingPlans = ({ confirm }) => {
  const {
    shippingPlans,
    shippingPlansLoading: loading,
    updateShippingPlans,
    getOptionsShippingPlans,
    options
  } = useSettings({ shipping: true });
  const [currentTab, setTab] = useState(tabsNames[0]);
  const handleChange = (event, newValue) => setTab(newValue);

  const [successNotification, setIsShowNotification] = useNotification(
    false,
    "success",
    "Updated",
    { vertical: "bottom", horizontal: "right" }
  );

  const { user } = useUsers({ fetch: false });

  useEffect(() => {
    getOptionsShippingPlans(currentTab);
  }, [currentTab]);

  let initData = { [currentTab]: []};

  const initPlane = [{...init}];

  const items  = shippingPlans[types[currentTab]] || [];

  // empty form
  if (!loading && items && items.length === 0) {
    initData = { [currentTab]: [...initPlane]};
  }

  if (items && items.length !== 0) {
    // form with initial data
    initData = { [currentTab]: [...items, ...initPlane] };
  }

  const onAction = ({ arrayHelpers, index, lastIndex }) => () => {
    if (index === lastIndex) {
      arrayHelpers.push(init);
    } else {
      confirm(() => {
        arrayHelpers.remove(index);
      }, {
        description: 'This Action is Permanent!',
        style: "d-confirm"
      })();
    }
  };
  const submitProps = { shippingPlans, currentTab, setIsShowNotification, updateShippingPlans };
  const tabs = tabsNames.map((name) => (
    {
      value: name,
      label: name,
      content: (
        <FormArray
          successNotification={successNotification}
          mainTabName='shipping-plan'
          initData={initData}
          onSubmit={onSubmit(submitProps)}
          dataName={currentTab}
          // header={<Headers currentTab={currentTab} currency={currencySymbols[user.currency]} />}
          footerButtons={(props) => <Buttons {...props} />}
          line={(props) => (
            <LineArray
              interfaceArray={interfaceArray(currencySymbols[user.currency], currentTab, options)}
              dataName={currentTab}
              onAction={(props) => onAction({...props })}
              {...props}
            />
          )}
        />
      )
    }
  ));

  return (
    <div className="settings-main__cog settings-main__shipping-plan settings-main__default">
      <Typography variant="h5" className="settings__title">Merchant Shipping Plans</Typography>
      <div className="settings__subtitle">
        <Typography variant="subtitle1" className="settings__subtitle__item">
          This Page controls the shipping plans you have created on Amazon
        </Typography>
        <Typography variant="subtitle1" className="settings__subtitle__item">
          SKUify will automatically detect your shipping plan so please type this exactly as in your Seller Central Account
        </Typography>
      </div>
      <div className="d-splash-screen--wrap">
        {loading && <DSplashScreen />}
        <DTabs
          value={currentTab}
          tabsData={tabs}
          onChange={handleChange}
          className="shipping-plan-settings"
        />
      </div>
    </div>
  )
};

export default withConfirmHook(ShippingPlans);
