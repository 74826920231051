import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import * as commonDuck from "../store/ducks/common.duck";
import * as Selector from "../selectors/common.selectors";

const useCurrencies = (props) => {
  const fetch = props && props.fetch || true;

  const dispatch = useDispatch();

  useEffect(() => {
    if (fetch)
      dispatch(commonDuck.actions.getCurrencies());
  }, [fetch]);

  const currencies = Selector.getCurrencies(useSelector(state => state));

  return {
    currencies,
  };
};

export default useCurrencies;