import React, {useEffect} from "react";
import {useHistory} from "react-router";
import {Button} from "@material-ui/core";
import {BottomButtons, OnboardingLayout} from "./components";
import useUsers from "../../../hooks/users.hook";
import {ON_AMAZON} from "../../../router/routesMap";

const EmailRegistrationLogin = (props: any) => {
  const {redirectLink, redirectText, content, title} = props;
  const {user} = useUsers({fetch: false});
  let history = useHistory();
  const {location} = history;
  // @ts-ignore
  const url = location?.state?.url || '';

  const onBack = () => history.goBack();

  useEffect(() => {
    if (url && !!user?.email && !user?.subscription_paid) {
      // open zoho
      window.open(url, "_self");
      // if (process.env.NODE_ENV === 'development') {
      //   window.open(url, "_self");
      // } else {
      //   window.open(url, "_self");
      // }
    }

    if (user?.subscription_paid) {
      history.push(ON_AMAZON)
    }
  }, [user]);

  return (
    <OnboardingLayout
      currentStep={1}
      title={title}
      button={
        <Button
          size="large"
          className="connect-doddled__logout"
          onClick={() => {history.push(redirectLink, location.state)}}
        >
          {redirectText}
        </Button>
      }
    >
      <div className="email-login">
        {content({
          // onAuth,
          bottom: (props: any) => <BottomButtons onBack={onBack} {...props} />
        })}
      </div>
    </OnboardingLayout>
  )
};

export default EmailRegistrationLogin;