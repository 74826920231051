import { useDispatch, useSelector } from "react-redux";
import * as Selector from "../selectors/asin.selectors";
import * as asinDuck from "../store/ducks/asin.duck";

const useAsin = () => {
  const dispatch = useDispatch();

  const getAsinHisties = (args) => dispatch(asinDuck.actions.getAsinHisties(args));
  const updateAsinHistory = (args) => dispatch(asinDuck.actions.updateAsinHistory(args));

  const asinHistories = Selector.getAsinHistories(useSelector(state => state));

  const getAsinProfitReport = (args) => dispatch(asinDuck.actions.getAsinProfitReport(args));
  const asinProfitReport = Selector.getAsinProfitReport(useSelector(state => state));


  return {
    getAsinHisties,
    updateAsinHistory,
    asinHistories: asinHistories.details,
    asinHistoriesTotalCount: asinHistories.totalCount,
    loadingAsinHistories: asinHistories.loading,

    getAsinProfitReport,
    asinProfitReport: asinProfitReport.details,
    loadingAsinProfitReport: asinProfitReport.loading,
  };
};

export default useAsin;