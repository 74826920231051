import React, {useState} from "react";
import { Form as FormikForm, Formik } from "formik";
import {Form} from "react-bootstrap"
import {useNotification} from "../../../../hooks/notification.hook";
import useSettings from "../../../../hooks/settings.hook";
import toast from "../../../components/toast/toast";
import DSplashScreen from "../../../components/SplashScreen/DSplashScreen";
import FormField from "../../../components/controls/FormField";
import Button from "@material-ui/core/Button";
import useUsers from "../../../../hooks/users.hook";
import { callingCode } from "../utils";
import { account } from "./utils";
import HeaderInformation from "./../components/HeaderInformation";
import {toAbsoluteUrl} from "../../../../../_metronic";

const CompanySettings = () => {
  const { accountSettings, accountLoading: loading, updateAccountSetting } = useSettings({ fetch: false });
  const { user } = useUsers({ fetch: false });
  const [images, setImages] = useState([]);
  const [successNotification, setIsShowNotification] = useNotification(
    false,
    "success",
    "Success",
    { vertical: "bottom", horizontal: "right" }
  );

  let initData = {};
  if (accountSettings) {
    initData = {...accountSettings}
  }

  const {company_avatar_image} = initData;


  if (accountSettings.phone_code) {
    callingCode.find(({ country }) => country === accountSettings.phone_code);
  }

  const onDeleteImage = () => setImages([]);
  const onImage = (files) => setImages(files);

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    let data = {...values};
    const formdata = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      const field = account.find(({id}) => id === key);
      if (value !== null && !!field?.id) {
        formdata.append(key, value);
      }
    });

    if (images?.length) {
      const file = images[0]?.file;
      formdata.append("company_avatar_image", file, file?.name);
    } else {
      formdata.append("company_avatar_image", "");
    }

    const onDone = () => {
      setSubmitting(false);
      resetForm();
      setIsShowNotification(true);
    };
    const onError = (error) => {
      setSubmitting(false);
      toast.error(error);
    };
    updateAccountSetting({
      id: user.id,
      values: formdata,
      onDone,
      onError
    })
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initData}
      onSubmit={onSubmit}
    >
      {({...form}) => (
        <FormikForm>
          <div className="settings-main__account settings-main__company settings-main__default">
            <HeaderInformation
              name="Company information"
              isImage
              imgUrl={company_avatar_image}
              defaultImg={toAbsoluteUrl("./media/logos/new-logo-icon.svg")}
              onImage={onImage}
              onDeleteImage={onDeleteImage}
            />
            <div className="d-splash-screen--wrap">
              {loading && <DSplashScreen />}
              <div className="settings-main__account__form">
              <div className="form__body">
                {account.map((item) => (
                  <Form.Group key={item.id} controlId={item.id} className={`form-group--${item.id}`}>
                    <Form.Label column={false}>{item.label}</Form.Label>
                    <FormField
                      name={item.id}
                      label={item.label}
                      type={item.type}
                      {...form}
                      customProps={item.customProps}
                      render={item.render}
                    />
                  </Form.Group>
                ))}
              </div>
              <div className="settings__wrap-btn">
                <Button
                  variant="contained"
                  className="settings-main__vat__btn settings__btn"
                  type="submit"
                  disabled={form.isSubmitting}
                >
                  {/*{user ? "Update" : "Save"}*/}
                  Update
                </Button>
              </div>
            </div>
            </div>
            {successNotification}
          </div>
        </FormikForm>
      )}
    </Formik>
  )
};

export default CompanySettings;