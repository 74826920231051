export const tabs = {
    "company_info": {
        name: "Business Info",
    }
};

const fieldsBusinessInfo = [
    [
        {
            "name": "company_reg_no",
            "label": "Company registration number",
            "type": "text",
            "help": "<p>Every registered company has a number which can be found by visiting the <a href='https://www.gov.uk/government/organisations/companies-house' title='Companies House website' target='_blank'>Companies House website</a>. <i class=\"zmdi zmdi-open-in-new zmdi-hc-fw \" title=\"External link\"></i></p>\n        <p>If you enter this number correctly we can check that your data matches and reduce the potential for error.</p>\n        <p>Additionally, we will autofill the rest of your blank fields to match Companies House, where possible.</p>",
            "tab": "financial_tab"
        },
        {
            "name": "company_name",
            "label": "Business name",
            "type": "text",
            "help": "<p>The business name will appear on the invoices you create. You can populate this automatically by entering the company registration number.</p>",
            "tab": "financial_tab"
        },
        {
            "name": "company_type_name",
            "label": "Business type",
            "type": "text",
            "help": null,
            "tab": "financial_tab"
        },
        {
            "name": "trading_name",
            "label": "Trading name",
            "type": "text",
            "help": "<p>A trading name can be different from the registered business name. This will also show on the invoices you create.</p>",
            "tab": "financial_tab"
        },
        {
            "name": "date_incorporated",
            "label": "Date incorporated",
            "type": "date",
            "help": "<p>This is the date the business was created. This information can be found on the Companies House website.</p>",
            "tab": "financial_tab"
        },
        {
            "name": "prog_date_start",
            "label": "Financial year start date",
            "type": "date",
            "help": "<p>The financial period is the period the business makes its accounts up to. If the business is a sole trader this is usually from 6th April one year to 5th April the next.</p>\n      <p>A Company's financial periods can vary, but can be checked with Companies House.</p>",
            "tab": "financial_tab"
        },
        {
            "name": "prog_date_end",
            "label": "Financial year end date",
            "type": "date",
            "help": "<p>The financial period is the period the business makes its accounts up to. If the business is a sole trader this is usually from 6th April one year to 5th April the next.</p>\n      <p>A Company's financial periods can vary, but can be checked with Companies House.</p>",
            "tab": "financial_tab"
        },
        {
            "name": "locked_period_date_end",
            "label": "Lock transactions up to",
            "type": "date",
            "help": "<p>Entering a date here will lock all prior transactions. This is useful for accountants, or if you wish to prevent other users from making changes</p>",
            "tab": "financial_tab"
        }
    ]
];
export const companyFieldsBusinessInfo = fieldsBusinessInfo.flat();