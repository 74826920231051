import { requests, URLSearchParamsSkipNull } from "./utils";

export default {
  // sales reports
  getSalesReports: (params) => {
    return requests.get(`/report/?${URLSearchParamsSkipNull(params)}`)
  },

  // product report
  getProductReport: (id) => requests.get(`/product/report/${id}`),

  // order report
  getOrderReport: (id, params) => requests.get(`/order_item/report/${id}/?${URLSearchParamsSkipNull(params)}`),

  // amazon fees report
  getAmazonFeesReport: (id) => requests.get(`/order_item/fees-report/${id}`),

  // product amazon fees report
  getAmazonFeesReportProduct: (id) => requests.get(`/product/fees-report/${id}`),

  //Export products
  getExportReports: (url, fileName) => requests.downloadFile(url, fileName),

  // Expenses report/settings
  updateExpenses: (values) => requests.post(`/expense`, values),
  getExpenses: (params) => requests.get(`/expense/?${URLSearchParamsSkipNull(params)}`),
  getExpensesTransactions: (params) => requests.get(`/expense_transaction/?${URLSearchParamsSkipNull(params)}`),
  getExpens: (id) => requests.get(`/expense/${id}`),
  deleteExpenses: (id) => requests.del(`/expense/${id}`),
  updateExpensesTransaction: (transaction) =>
    requests.patch(`/expense_transaction/${transaction.id}`, transaction),
  deleteExpensesTransaction: (id) => requests.del(`/expense_transaction/${id}`),
  getExpensesCategories: () => requests.get(`/expense_categories`),
  // updateFewOldExpenses: (values) => requests.post('/expense_bulk_update', values),

  //main reports
  getMainReports: (params) => requests.get(`/reports/?${URLSearchParamsSkipNull(params)}`),
  addNewReport: (values) => requests.post(`/reports`, values),
  updateReports: (values) => requests.put(`/reports`, values),
  // getMainReports: (params) => new Promise((res) => res({ data: testMainReports })),

  getAsinProfitReport: (params) => {
    return requests.get(`/report/?${URLSearchParamsSkipNull(params)}`)
  },

  //Import Expenses
  addImportsExpenses: (filename, values) => requests.file(`/expense/import/${filename}`, values),
};
