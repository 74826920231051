import React, { Suspense, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Dashboard from "./Dashboard/Dashboard";
import BooksSettings from "./Company/BooksSettings";
import Categories from "./Category/Categories";
import TransactionList from "./Transaction/TransactionsPage/TransactionList/TransactionList";
import EnterTransactions from "./Transaction/EnterTransactions";
import { LayoutSplashScreen } from "../../../_metronic";
import * as RoutesMap from "../../router/routesMap";
import NewBankTransaction from "./Transaction/NewBankTransaction/NewBankTransaction";
import { Helmet } from "react-helmet";
import Reports from "./Reports/Reports";
import MainReports from "./MainReports/Reports";
import BankRules from "./Tools/BankRules/BankRules";
import EditBankRule from "./Tools/BankRules/EditBankRule";
import RecurringTransactions from "./Tools/RecurringTransactions/RecurringTransactions";
import ReceiptUploads from "./Tools/ReceiptUploads/ReceiptUploads";
import Companies from "./Company/Companies/Companies";
import ImportBankTransactions from "./Transaction/NewBankTransaction/ImportBankTransactions/ImportBankTransactions";
import ImportCustom from "./Transaction/NewBankTransaction/ImportBankTransactions/ImportCustom";
import AddCustomImportUploadBank from "./Transaction/NewBankTransaction/ImportBankTransactions/AddCustomImportUploadBank";
import UploadExampleFile from "./Transaction/NewBankTransaction/ImportBankTransactions/UploadExampleFile";
import EditCustomFile from "./Transaction/NewBankTransaction/ImportBankTransactions/EditCustomFile";
import ImportedBankTransactions from "./Transaction/NewBankTransaction/ImportBankTransactions/ImportedBankTransactions";
import BulkTransactionEditor from "./Transaction/TransactionsPage/BulkTransactionEditor";
import CreateEditAdjustment from "./Category/CreateEditAdjustment";
import OpeningBalancesWizard from "./Tools/OpeningBalancesWizard/OpeningBalancesWizard";
import ReportsGeneral from "./Reports/ReportsGeneral";
import { onInterceptors } from "../../crud/utils";
import Products from "./Products/Products";
import Sales from "./Sales/Sales";
import Settings from "./Settings";
import Invoices from "./Invoices/Invoices";
import useUsers from "../../hooks/users.hook";
import toast from "../components/toast/toast";
import ProfitProtectorPro from "./ProfitProtectorPro";
import Jorney from "./Jorney/Jorney";
import Reconciliation from "./Reconciliation/Reconciliation";
import Tax from "./Tax/Tax";
import Books from "./Books/Books";
import ExpensesContainer from "./Expenses/ExpensesContainer";
import {helpTabs} from "./Settings/tabsInterfaces/help";
import {settingsTabs} from "./Settings/tabsInterfaces/settings";
import Notifications from "./Notifications";

const infoMsg =
  "SKUify is currently syncing all your data," +
  " please check back later as incomplete data will not provide the full picture. " +
  "In the meantime, please add your Cost of Goods Information";

export default function HomePage() {
  const { user } = useUsers({ fetch: false });
  useEffect(() => {
    if (user && !user.synced) {
      toast.info(infoMsg, { position: "top-right", duration: 5000 });
    }
  }, []);

  useEffect(() => {
    if (user && user.mws_error)
      return toast.error(
        user.mws_error,
        { duration: null },
        { className: "home-page" }
      );
  });

  // useEffect(() => {
  //   if (user && user.mws_disabled) {
  //     const text = `
  //     Welcome back!  You haven't logged in recently so we need a little while to collate
  //     all your new data.  We are busy doing that for you now so please check back later.
  //     `;
  //     return toast.info(
  //       text,
  //       { duration: null, position: "top-right" },
  //       { className: "home-page" }
  //     );
  //   }
  // });

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>SKUify - Your Amazon Sales Simplified</title>
      </Helmet>
      <Switch>
        {onInterceptors()}
        <Route path={RoutesMap.SETTINGS}>
          <Settings tabs={settingsTabs} />
        </Route>
        <Route path="/help">
          <Settings tabs={helpTabs} />
        </Route>

        <Route path="/dashboard" component={Dashboard} />

        <Route path={RoutesMap.PRODUCTS} component={Products} />

        <Route path={RoutesMap.SALES} component={Sales} />

        <Route path="/reports" component={MainReports} />

        <Route path="/repricer" component={ProfitProtectorPro} />

        <Route path="/reconciliation" component={Reconciliation} />
        <Route path={RoutesMap.EXPENSES} component={ExpensesContainer} />
        <Route path={RoutesMap.BOOKS} component={Books} />
        <Route path={RoutesMap.TAX} component={Tax} />

        <Route path="/journey" component={Jorney} />
        <Route path={RoutesMap.NOTIFICATIONS} component={Notifications} />

        {user.subscription !== "base" && <Redirect to="/" />}

        <Route path="/companies" component={Companies} />
        {/* File import */}
        <Route exact path={RoutesMap.CUSTOM_IMPORT} component={ImportCustom} />
        <Route
          exact
          path={RoutesMap.IMPORTED_BANK_TRANSACTIONS}
          component={ImportedBankTransactions}
        />
        <Route
          path={RoutesMap.NEW_CUSTOM_IMPORT}
          component={AddCustomImportUploadBank}
        />
        <Route
          path={RoutesMap.NEW_FILE_IMPORTS}
          component={AddCustomImportUploadBank}
        />
        <Route
          exact
          path={RoutesMap.UPLOAD_EXAMPLE_CUSTOM_IMPORT}
          component={UploadExampleFile}
        />
        <Route
          exact
          path={RoutesMap.EDIT_CUSTOM_IMPORT}
          component={EditCustomFile}
        />
        {/* File import end */}

        {/* books */}
        <Route path="/books/categories" component={Categories} />
        <Route exact path="/books/transactions" component={TransactionList} />
        <Route
          exact
          path={RoutesMap.BULK_TRANSACTION_EDITOR}
          component={BulkTransactionEditor}
        />
        <Route path="/books/enter-transaction" component={EnterTransactions} />
        <Route
          exact
          path={RoutesMap.NEW_BANK_TRANSACTION}
          component={NewBankTransaction}
        />
        <Route
          exact
          path={RoutesMap.EDIT_TRANSACTION}
          component={NewBankTransaction}
        />
        <Route
          exact
          path={RoutesMap.IMPORT_BANK_TRANSACTION}
          component={ImportBankTransactions}
        />
        <Route
          exact
          path={RoutesMap.AUTO_CONFIRMED_BANK_TRANSACTIONS}
          component={TransactionList}
        />

        {/*Reports*/}
        <Route exact path={RoutesMap.REPORTS} component={Reports} />
        <Route
          exact
          path={RoutesMap.PROFIT_AND_LOSS}
          component={ReportsGeneral}
        />
        <Route
          exact
          path={RoutesMap.BALANCE_SHEET}
          component={ReportsGeneral}
        />
        <Route
          exact
          path={RoutesMap.TRIAL_BALANCE}
          component={ReportsGeneral}
        />

        {/*Reports end*/}

        {/*Tools*/}
        <Route
          exact
          path={RoutesMap.RECURRING_TRANSACTIONS}
          component={RecurringTransactions}
        />
        <Route
          exact
          path={RoutesMap.DETAILS_RECURRING_TRANSACTIONS}
          component={RecurringTransactions}
        />
        <Route
          exact
          path="/books/tools/receipt-uploads"
          component={ReceiptUploads}
        />
        <Route
          exact
          path="/books/tools/opening-balances"
          component={OpeningBalancesWizard}
        />

        {/*Bank Rules*/}
        <Route exact path={RoutesMap.BANK_RULES} component={BankRules} />
        <Route exact path={RoutesMap.EDIT_BANK_RULE} component={EditBankRule} />
        <Route exact path={RoutesMap.NEW_BANK_RULE} component={EditBankRule} />
        {/*Tools end*/}

        {/*Adjustment*/}
        <Route
          exact
          path={RoutesMap.NEW_ADJUSTMENT}
          component={CreateEditAdjustment}
        />
        <Route
          exact
          path={RoutesMap.EDIT_ADJUSTMENT}
          component={CreateEditAdjustment}
        />
        <Route
          exact
          path={RoutesMap.NEW_RECURRING_ADJUSTMENT}
          component={CreateEditAdjustment}
        />
        <Route
          exact
          path={RoutesMap.EDIT_RECURRING_ADJUSTMENT}
          component={CreateEditAdjustment}
        />

        {/*Invoices*/}
        <Route
          exact
          path={RoutesMap.NEW_CUSTOMER_INVOICES}
          component={Invoices}
        />
        <Route
          exact
          path={RoutesMap.EDIT_CUSTOMER_INVOICES}
          component={Invoices}
        />
        <Route
          exact
          path={RoutesMap.NEW_SUPPLIER_INVOICES}
          component={Invoices}
        />
        <Route
          exact
          path={RoutesMap.EDIT_SUPPLIER_INVOICES}
          component={Invoices}
        />
        <Route
          exact
          path={RoutesMap.NEW_RECURRING_INVOICES}
          component={Invoices}
        />
        <Route
          exact
          path={RoutesMap.EDIT_RECURRING_INVOICES}
          component={Invoices}
        />

        <Route exact path="/books/settings" component={BooksSettings} />

        {/* end books */}

        {/*<IsBaseSubscriptionUser>*/}
        {/*{*/}
        {/*() => (*/}
        {/*<>*/}
        {/*<Route path="/companies" component={Companies} />*/}
        {/*/!* File import *!/*/}
        {/*<Route exact path={RoutesMap.CUSTOM_IMPORT} component={ImportCustom} />*/}
        {/*<Route exact path={RoutesMap.IMPORTED_BANK_TRANSACTIONS} component={ImportedBankTransactions} />*/}
        {/*<Route path={RoutesMap.NEW_CUSTOM_IMPORT} component={AddCustomImportUploadBank} />*/}
        {/*<Route path={RoutesMap.NEW_FILE_IMPORTS} component={AddCustomImportUploadBank} />*/}
        {/*<Route exact path={RoutesMap.UPLOAD_EXAMPLE_CUSTOM_IMPORT} component={UploadExampleFile} />*/}
        {/*<Route exact path={RoutesMap.EDIT_CUSTOM_IMPORT} component={EditCustomFile} />*/}
        {/*/!* File import end *!/*/}

        {/*/!* books *!/*/}
        {/*<Route path="/books/categories" component={Categories} />*/}
        {/*<Route exact path="/books/transactions" component={TransactionList} />*/}
        {/*<Route exact path={RoutesMap.BULK_TRANSACTION_EDITOR} component={BulkTransactionEditor} />*/}
        {/*<Route path="/books/enter-transaction" component={EnterTransactions} />*/}
        {/*<Route exact path={RoutesMap.NEW_BANK_TRANSACTION} component={NewBankTransaction} />*/}
        {/*<Route exact path={RoutesMap.EDIT_TRANSACTION} component={NewBankTransaction} />*/}
        {/*<Route exact path={RoutesMap.IMPORT_BANK_TRANSACTION} component={ImportBankTransactions} />*/}
        {/*<Route exact path={RoutesMap.AUTO_CONFIRMED_BANK_TRANSACTIONS} component={TransactionList} />*/}

        {/*/!*Reports*!/*/}
        {/*<Route exact path={RoutesMap.REPORTS} component={Reports} />*/}
        {/*<Route exact path={RoutesMap.PROFIT_AND_LOSS} component={ReportsGeneral} />*/}
        {/*<Route exact path={RoutesMap.BALANCE_SHEET} component={ReportsGeneral} />*/}
        {/*<Route exact path={RoutesMap.TRIAL_BALANCE} component={ReportsGeneral} />*/}

        {/*/!*Reports end*!/*/}

        {/*/!*Tools*!/*/}
        {/*<Route exact path={RoutesMap.RECURRING_TRANSACTIONS} component={RecurringTransactions} />*/}
        {/*<Route exact path={RoutesMap.DETAILS_RECURRING_TRANSACTIONS} component={RecurringTransactions} />*/}
        {/*<Route exact path="/books/tools/receipt-uploads" component={ReceiptUploads} />*/}
        {/*<Route exact path="/books/tools/opening-balances" component={OpeningBalancesWizard} />*/}

        {/*/!*Bank Rules*!/*/}
        {/*<Route exact path={RoutesMap.BANK_RULES} component={BankRules} />*/}
        {/*<Route exact path={RoutesMap.EDIT_BANK_RULE} component={EditBankRule} />*/}
        {/*<Route exact path={RoutesMap.NEW_BANK_RULE} component={EditBankRule} />*/}
        {/*/!*Tools end*!/*/}

        {/*/!*Adjustment*!/*/}
        {/*<Route exact path={RoutesMap.NEW_ADJUSTMENT} component={CreateEditAdjustment} />*/}
        {/*<Route exact path={RoutesMap.EDIT_ADJUSTMENT} component={CreateEditAdjustment} />*/}
        {/*<Route exact path={RoutesMap.NEW_RECURRING_ADJUSTMENT} component={CreateEditAdjustment} />*/}
        {/*<Route exact path={RoutesMap.EDIT_RECURRING_ADJUSTMENT} component={CreateEditAdjustment} />*/}

        {/*/!*Invoices*!/*/}
        {/*<Route exact path={RoutesMap.NEW_CUSTOMER_INVOICES} component={Invoices} />*/}
        {/*<Route exact path={RoutesMap.EDIT_CUSTOMER_INVOICES} component={Invoices} />*/}
        {/*<Route exact path={RoutesMap.NEW_SUPPLIER_INVOICES} component={Invoices} />*/}
        {/*<Route exact path={RoutesMap.EDIT_SUPPLIER_INVOICES} component={Invoices} />*/}
        {/*<Route exact path={RoutesMap.NEW_RECURRING_INVOICES} component={Invoices} />*/}
        {/*<Route exact path={RoutesMap.EDIT_RECURRING_INVOICES} component={Invoices} />*/}

        {/*<Route exact path="/books/settings" component={BooksSettings} />*/}

        {/*/!* end books *!/*/}
        {/*</>*/}
        {/*)}*/}
        {/*</IsBaseSubscriptionUser>*/}
        <Redirect to="/dashboard" />
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
