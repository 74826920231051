import { createSlice } from "@reduxjs/toolkit";
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import api from "../../crud/common.crud";
import logger from "../../pages/logger";
import {shortBaseState} from "./utils";


const common = createSlice({
  name: 'common',
  initialState: {
    currencies: {
      ...shortBaseState,
      fetched: false,
    },
    loading: true,
    groups: [],
    permissions: [],
    taxes: [],
    convRate: {
      loading: true,
      value: null,
    },
  },
  reducers: {
    stopLoading: (state, actions) => {
      const {type} = actions.payload;
      if (type) {
        state[type].loading = false;
      } else {
        state.loading = false;
      }
      return state;
    },

    getCurrencies: (state, actions) => {
      state.currencies.loading = true;
      return state;
    },
    getCurrenciesSuccess: (state, actions) => {
      const { currencies } = actions.payload;
      state.currencies.details = currencies;
      state.currencies.loading = false;
      state.currencies.fetched = true;
      return state;
    },
    getSettingsMeta: (state, actions) => {
      state.loading = true;
    },
    getSettingsMetaSuccess: (state, actions) => {
      const { groups, permissions } = actions.payload;
      state.groups = groups;
      state.permissions = permissions;
      state.loading = false;
    },
    getTaxes: (state, actions) => {
      state.loading = true;
    },
    getTaxesSuccess: (state, actions) => {
      const { taxes } = actions.payload;
      state.taxes = taxes;
      state.loading = false;
    },
    
    getConvRates: (state, actions) => {
      state.convRate = {
        ...state.convRate,
        loading: true,
      };
    },
    getConvRatesSuccess: (state, actions) => {
      const { convRate } = actions.payload;
      state.convRate = {
        ...state.convRate,
        value: convRate,
        loading: false,
      };
    },
    getTaskStatus: (state, actions) => {
    },
    getTaskStatusSuccess: (state, actions) => {
    },
  }
});

export const actions = common.actions;
export const reducer = common.reducer;

export function* saga() {
  yield takeLatest(actions.getCurrencies, function* getCompanySaga() {
    try {
      const { data } = yield call(api.getCurrencies);
      data
        ? yield put(actions.getCurrenciesSuccess({ currencies : data }))
        : yield put(actions.stopLoading({type: "currencies"}));
    } catch (err) {
      yield put(actions.stopLoading({type: "currencies"}));
      logger.error(err);
    }
  });

  yield takeLatest(actions.getSettingsMeta, function* getCompanySaga() {
    try {
      const { data } = yield call(api.getSettingsMeta);
      if (data) {
        yield put(actions.getSettingsMetaSuccess({
          groups: data.groups,
          permissions: data.permissions,
        }));
      }
      yield put(actions.stopLoading())
    } catch (err) {
      yield put(actions.stopLoading())
      logger.error(err);
    }
  });

  yield takeLatest(actions.getTaxes, function* getTaxesSaga() {
    try {
      const { data } = yield call(api.getTaxes);
      if (data) {
        yield put(actions.getTaxesSuccess({ taxes: data }));
      }
      yield put(actions.stopLoading())

    } catch (err) {
      yield put(actions.stopLoading());
      logger.error(err);
    }
  });
  
  yield takeLatest(actions.getConvRates, function* getConvRatesSaga({ payload: { fromCurrency, toCurrency, onDone } }) {
    try {
      const { data } = yield call(api.getConvRates(fromCurrency, toCurrency));
      if (data) {
        yield put(actions.getConvRates({ convRate: data }));
        onDone(data);
      } else {
        yield put(actions.stopLoading({type: "convRate"}));
      }
    } catch (err) {
      yield put(actions.stopLoading({type: "convRate"}));
      logger.error(err);
    }
  });

  yield takeLatest(actions.getTaskStatus, function* getTaskStatusSaga({ payload: { taskId, onDone, onError } }) {
    try {
      const { data } = yield call(api.getTaskStatus, taskId);
      yield put(actions.getTaskStatusSuccess());
      onDone(data);
    } catch (err) {
      if (onError) {
        onError(err);
      }
      logger.error(err);
    }
  });
}


