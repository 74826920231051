export const tabs = {
    "company_info": {
        name: "Business Info",
    }
};

const fieldsBusinessAddress = [
    [
        {
            "name": "address_line_1",
            "label": "Address line 1",
            "type": "text",
            "help": "Your address details will be shown on the invoices you create.",
            "tab": "financial_tab"
        },
        {
            "name": "address_line_2",
            "label": "Address line 2",
            "type": "text",
            "help": "Your address details will be shown on the invoices you create.",
            "tab": "financial_tab"
        },
        {
            "name": "address_line_3",
            "label": "Address line 3",
            "type": "text",
            "help": "Your address details will be shown on the invoices you create.",
            "tab": "financial_tab"
        },
        {
            "name": "town_city",
            "label": "Town/City",
            "type": "text",
            "help": "Your address details will be shown on the invoices you create.",
            "tab": "financial_tab"
        },
        {
            "name": "postcode",
            "label": "Post/Zip Code",
            "type": "text",
            "help": "Your address details will be shown on the invoices you create",
            "tab": "financial_tab"
        },
        {
            "name": "county",
            "label": "County",
            "type": "text",
            "help": "Your address details will be shown on the invoices you create.",
            "tab": "financial_tab"
        },
        {
            "name": "country",
            "label": "Country",
            "type": "text",
            "help": "Your address details will be shown on the invoices you create.",
            "tab": "financial_tab"
        },
        {
            "name": "telephone_number",
            "label": "Telephone number",
            "type": "text",
            "help": "<p>Please keep your telephone number updated so that we can contact you if we need to speak to you about your account.</p>",
            "tab": "financial_tab"
        }
    ]
];
export const companyFieldsBusinessAddress = fieldsBusinessAddress.flat();