import React, {useState} from "react";
import {Dialog, DialogContent} from "@material-ui/core";
import useUsers from "../../../../hooks/users.hook";
import useProducts from "../../../../hooks/products.hook";
import {currencySymbols} from "../../utils";
import {getBBPCosts, getStrategyName, getSumfullyCosts, onSubmit} from './utils';
import {cogInterface, strategies, FfTypes} from "./interfaces";
import {toAbsoluteUrl} from "../../../../../_metronic";
import {renderForm} from "./renderForm";

const BBPSettingsCell = ({row, settings = {}}) => {
  const {updateProductBBPImportStrategy} = useProducts({fetch: false});
  const {user} = useUsers({fetch: false});
  const [anchorEl, setAnchorEl] = useState(null);

  const handleEditClick = (event) => setAnchorEl(anchorEl ? null : event.currentTarget);
  const close = () => setAnchorEl(null);
  const currencySymbol = currencySymbols[user.currency];

    const OPTIONS = [
        {
            label: 'SKUify',
            costs: getSumfullyCosts(settings.find(x => x.settings_type === FfTypes[row.fulfillment])?.items)
        },
        {label: 'BBP', costs: getBBPCosts(row.raw_bbp)},
        {label: 'Edit'},
    ];

  const handleChangeStrategy = async (name, value, form) => form.setValues({
    ...form.values,
    ...Object.fromEntries(
      Object.values(strategies).map(strategy => [getStrategyName(strategy), strategy === name ? value : false])),
  });

  const initialValues = {
    apply_to_future: row.bbp_import_strategy?.apply_to_future,
    apply_to_unsold_inventory: row.bbp_import_strategy?.apply_to_unsold_inventory,
    strategy: row.bbp_import_strategy?.strategy,
    // стремная конструкция, соглашусь, но здесь мы из объекта strategies делаем init значения для стратегий в форме
    // например {sumfully: 'Sumfully'} -> { strategy_Sumfully : true }, если у нас у продукта стратегия = sumfully
    ...Object.fromEntries(
      Object.entries(strategies).map(([k, v]) => [getStrategyName(v), k === row.bbp_import_strategy?.strategy])),
    asin1: row.asin1,
    seller_sku: row.seller_sku,
    date_added: row.date_added,
    price: row.price,
    inventory_cost: row.inventory_cost,
    quantity: row.quantity,
    purchased_from: row.purchased_from,
    purchased_date: row.purchased_date,
    bundled: row.bundled,
    bundling: row.bundling,
    prep_centre: row.prep_centre,
    packaging: row.packaging,
    extra_fee: row.extra_fee,
    extra_fee_perc: row.extra_fee_perc,
    handling: row.handling,
    other: row.other,
    postage: row.postage,
    vat_free_postage: row.vat_free_postage,
  };

  return (
    <>
      <img
        alt="img"
        className="table__cell__img--icon pointer"
        src={toAbsoluteUrl("/media/d-icons/TaxIcon.svg")}
        onClick={handleEditClick}
      />
      <Dialog
        open={!!anchorEl}
        onClose={handleEditClick}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        className="bbp__cogs__modal"
        maxWidth="md"
        scroll="body"
      >
        <DialogContent>
          {renderForm({
            row,
            cogInterface,
            initialValues,
            currencySymbol,
            cogsVariants: OPTIONS,
            handleChangeStrategy,
            onSubmit: onSubmit({updateProductBBPImportStrategy, row, close})
          })}
        </DialogContent>
      </Dialog>
    </>
  )
};

export default BBPSettingsCell;
