import React, {useEffect, useState} from "react";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import * as Selector from "../../../../selectors/userSettings.selectors";
import TableComponent from "../../../components/TableComponent/TableComponent";
import withConfirmHook from "../../../../hooks/withConfirm.hook";
import * as userDuck from "../../../../store/ducks/user.duck";
import * as commonApi from "../../../../store/ducks/common.duck";
import {getGroups} from "../../../../selectors/common.selectors";
import UserCreateEditModal from "./UserCreateEditModal";
import {useModal} from "../../../../hooks/modal.hook";
import * as Duck from "../../../../store/ducks/user.duck";
import DButton from "../../../components/Button";
import {useHistory} from "react-router-dom";
import {goToEnterTransactionPage} from "../utils";

function UserSettings({
                        groups,
                        confirm,
                        getUsers,
                        users,
                        updateUser,
                        deleteUser,
                        addUser,
                        getSettingsMeta,
                        loading,
                      }) {
  const [currentRow, setCurrentRow] = useState(null);
  const [isModalShow, toggleModal] = useModal();

  let history = useHistory();
  const onCancel = () => goToEnterTransactionPage(history);

  useEffect(() => {
    getUsers();
    getSettingsMeta();
  }, []);


  const headRows = [
    { id: 'fullname', numeric: false, disablePadding: true, label: 'Name', isActive:true  },
    { id: 'email', numeric: false, disablePadding: true, label: 'Email', isActive:true  },
    { id: 'permission',
      numeric: false,
      disablePadding: true,
      label: 'Permission',
      isActive:true,
      renderText: (row) => {
        return row.doddle_group && row.doddle_group.name;
      }},
    {id: 'actions', numeric: false, disablePadding: true, label: 'Actions', align: 'right', isActive:true },
  ];

  const onEdit = (row) => () => {
    const rowCopy = {...row};
    if (row.doddle_group) {
      rowCopy.doddle_group = {
        label: row.doddle_group.name,
        value: row.doddle_group.id,
      }
    }

    setCurrentRow(rowCopy);
    toggleModal();
  };

  const onDelete = (row) => (
    confirm(() => {
        deleteUser({ id: row.id })
      },
      {
        description: 'This action is permanent!'
      })
  );

  const reset = () => {
    setCurrentRow(null);
  };

  const onClose = () => {
    reset();
    toggleModal();
  };

  return (
    <div>
      <TableComponent
        headRows={headRows}
        items={users}
        rowProps={{
          onDelete,
          onEdit,
        }}
      />
      <div className="container-button container-button--modal ">
        <DButton onClickCustom={toggleModal} typeOfButton="add">Add New User</DButton>
        <DButton typeOfButton="exit" onClickCustom={onCancel}>Cancel</DButton>
      </div>

      <UserCreateEditModal
        reset={reset}
        addUser={addUser}
        onClose={onClose}
        showModal={isModalShow}
        initValues={currentRow}
        updateUser={updateUser}
        groups={groups}
        users={users}
      />
    </div>
  )
}

UserSettings.propTypes = {
  getUsers: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired,
  getSettingsMeta: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

const mapStateToProps = (state, props) => {
  return {
    loading: Selector.getLoading(state, props),
    users: Selector.getUsers(state, props),
    groups: getGroups(state, props),
  }
};

const mapDispatchToProps = {
  getUsers: userDuck.actions.getUsers,
  getSettingsMeta: commonApi.actions.getSettingsMeta,
  addUser: Duck.actions.addUser,
  deleteUser: Duck.actions.deleteUser,
  updateUser: Duck.actions.updateUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withConfirmHook(UserSettings))
