import React, {useEffect, useRef, useState} from "react";
import { renderToString } from 'react-dom/server'
import Chart from 'chart.js/auto';
import 'chartjs-plugin-annotation';
import 'chartjs-plugin-draggable';
import { useSelector } from "react-redux";
import {metronic} from "../../../../../_metronic";
import {getChartOptions, updateChartValues} from "./dataChart"
import * as PropTypes from "prop-types";
import {ChartLegendPortal, LegendContainer} from "./chartElement/Legend";
import useChart from "../../../../hooks/chart.hook";

function getPosition(element) {
  let clientRect = element.getBoundingClientRect();
  return {left: clientRect.left + document.body.scrollLeft,
    top: clientRect.top + document.body.scrollTop
  };
}

function mutateTicks(loading, time, x){
  return loading
    ? {...x?.ticks || {}, callback: () => ''}
    : time === '90 days' || time === 'All' || time === "Custom"
      ? {
        ...x?.ticks || {},
          callback: function(label, index, labels) {
            if (time === 'Custom') {
              if (labels.length < 30) {
                return this.getLabelForValue(label);
              } else {
                const qtyTicks = 10;
                const separator = Math.floor(labels.length / qtyTicks);
                const maxCheckIndex = labels.length - Math.ceil(separator / 2);

                if ((index < maxCheckIndex && !(index % separator)) || index === labels.length - 1) {
                  return this.getLabelForValue(label);
                } else {
                  return '';
                }
              }
            } else {
              if (index === 0 || index === labels.length - 1) {
                return this.getLabelForValue(label);
              }
              return '';
            }
          },
      }
      : {...x?.ticks || {}}
}

export default function DashboardChart(props) {
  const { chartContent = {}, checked, isCompare, time, colors, colorDash, isDark, disabled } = props;
  const {x, y, ...chartValues} = chartContent;
  const {chart_data = {}} = chartValues;
  const canvasRef = useRef();
  const [gchart, setgchart] = useState();
  const {pointHoverBackgroundColor} = useSelector(state => ({
    pointHoverBackgroundColor: metronic.builder.selectors.getConfig(state, "colors.state.danger")
  }));
  const { loading } = useChart({ fetch: false });

  // data labels for chart config
  const chartOptions = getChartOptions({chart: chartValues, isCompare, colors});
  // const dateData = date[time];
  const labels = chart_data.labels;
  const compare_dates = chart_data.compare_dates;

  // filter data for hide / show the charts using the overlay switches
  const chartData = Object.values(chartOptions);
  const filterChartData = chartData.filter(({ name }) => checked.includes(name));

  const chartLines = filterChartData.reduce((res, { charts, name }) => {
    const chartsData = time === "Custom" ? charts.filter(({ name }) => name === "Current") : [...charts];
    return [...res, ...chartsData.map(item => ({...item,  parent: name}))]
  }, []);

  // datasets fot chart config
  const dataSets = chartLines.map(({ yAxisID, label, color, type, data, ...extra }) => {
    let compareSettings = {};

    if (extra.compare) {
      compareSettings = {
        borderDash: [4, 4],
        borderDashOffset: 5,
      };
    }

    return {
      ...extra,
      label: label || "",
      borderColor: color,
      backgroundColor: color,
      pointHoverBackgroundColor: color,
      radius: 3,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 1,
      pointBackgroundColor: Chart.helpers
        ?.color(color)
        ?.alpha(1)
        ?.rgbString(),
      pointBorderColor: Chart.helpers
        ?.color(color)
        ?.alpha(1)
        ?.rgbString(),

      pointHoverBorderColor:Chart.helpers
        ?.color(color)
        ?.alpha(1)
        ?.rgbString(),
      fill: false,
      data: data,
      type: type || 'line',
      ...compareSettings,
    }
  });

  useEffect(() => {
    const config = {
      type: chartContent.type || 'line',
      data: {
        labels: labels ? labels.map(el => el.split(' ')) : [],
        datasets: dataSets,
      },
      options: {
        categoryPercentage: 0.35,
        elements: {
          line: {
            borderWidth: 3,
            cubicInterpolationMode: "monotone"
          },
          bar: {
            borderWidth: 0,
            borderRadius: 10,
          },
          point: {
            borderWidth: 3,
          },
        },
        plugins: {
          title: {
            display: false
          },
          tooltip: {
            enabled: false,
            // external: externalTooltipHandler
            // intersect: false,
            // mode: "index",
            // callbacks: {},
          },
          legend: {
            display: false,
            labels: {
              usePointStyle: false,
              fontColor: isDark ? "fff" : "#000",
            }
          },
        },
        animation: false,

        onHover: function(e) {
          const event = e.native;
          let d = document.getElementById('chart-legend');
          const hidePopover = () => {
            d.style.display = 'none';
            chart.update();
          };

          let yTop = chart.chartArea.top;
          let yBottom = chart.chartArea.bottom;
          let yMin = chart.scales.y.min;
          let yMax = chart.scales.y.max;
          let newY = 0;
          const yView = event.offsetY <= yBottom && event.offsetY >= yTop;
          if (yView) {
            newY = Math.abs((event.offsetY - yTop) / (yBottom - yTop));
            newY = (newY - 1) * -1;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            newY = newY * (Math.abs(yMax - yMin)) + yMin;
          }

          let xTop = chart.chartArea.left;
          let xBottom = chart.chartArea.right;
          try {
            let xMin = 0;

            const xTicks = chart.scales.x.ticks;
            let xMax = xTicks.length - 1;
            let newX = 0;
            let xIndex = 0;
            const xView = event.offsetX <= xBottom && event.offsetX >= xTop;
            if (xView && yView) {
              newX = Math.abs((event.offsetX - xTop) / (xBottom - xTop));
              newX = newX * (Math.abs(xMax - xMin)) + xMin;
              xIndex = Math.round(newX);
              if (xTicks[xIndex]) {
                newX = xTicks[xIndex].value;
              }
            } else {
              hidePopover();
              return;
            }

            const centerChart = (xBottom - xTop) / 2;

            chart.update();
            let canvasPositions = getPosition(chart.canvas);
            d.style.position = "absolute";
            if (d.clientWidth) {
              if (event.offsetX >= centerChart) {
                d.style.left = event.clientX - d.clientWidth - 20 + 'px';
              } else {
                d.style.left = event.clientX + 20 + 'px';
              }
            }

            d.style.top = canvasPositions.top + document.documentElement.scrollTop - 10 +'px';
            if (!disabled) {
              d.style.display = 'block';
            }
            const data = filterChartData.reduce((res, values) => {
              const {charts} = values;
              let lines = time === "Custom" || time === "All"
                ? charts.filter(({ name }) => name === "Current")
                : charts;

              return [
                ...res,
                {
                  ...values,
                  charts: updateChartValues({
                    lines,
                    chart,
                    xIndex,
                    chartContent,
                    compare_dates
                  })
                }
              ];
            }, []);

            d.innerHTML = renderToString(<LegendContainer data={data} colorDash={colorDash} />);
          } catch (e) {
            console.log(e);
            return
          }
        },

        onClick: function(evt) {},

        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          hover: {
            mode: "nearest",
            intersect: true
          },
        },
        barThickness: x?.barThickness,
        scales: {
          x: {
            ...y,
            display: true,
            grid: {
              color: "transparent",
              display: false,
              drawBorder: false,
              zeroLineColor: "#ccc",
              zeroLineWidth: 1,
              ...x?.grid || {}
            },
            title: {
              display: false,
              ...x?.title || {}
            },
            ticks: mutateTicks(loading, time, x)
          },
          y: {
            ...y,
            display: true,
            beginAtZero: true,
            title: {
              display: false,
              // labelString: label
              ...y?.title || {}
            },
            grid: {
              drawBorder: false,
              zeroLineColor: 'transparent',
              ...y?.grid || {}
            },
            ticks: {
              ...y?.ticks || {}
            },
            position: 'left',
            id: 'y-axis-1',
          }
        },
        layout: {
          padding: {
            left: 0,
            right:0,
            top: 0,
            bottom: 0
          }
        }
      }
    };

    const chart = new Chart(canvasRef.current, config);
    if (!gchart)
      setgchart(chart);
    return () => {
      chart.destroy();
    };
  }, [chartLines, pointHoverBackgroundColor]);

  return (
    <div className="kt-widget26 dash__widget">
      <div className="kt-widget26__chart dash__chart">
        <ChartLegendPortal />
        <canvas
          ref={canvasRef}
          id="kt_chart_quick_stats_1"
          width="800"
          height="275"
          onMouseLeave={() => {
            let d = document.getElementById('chart-legend');
            d.style.display = 'none';
          }}
        />
      </div>
    </div>
  );
}

DashboardChart.propTypes = {
  chart: PropTypes.object,
  checked: PropTypes.array,
  isCompare: PropTypes.bool,
  time: PropTypes.string,
};