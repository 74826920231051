import React from "react";
import Wrapper from "../../components/Wrapper";
import DTabs from "../../components/DTabs/DTabs";
import useTab from "../../../hooks/tab.hook";
import {Typography} from "@material-ui/core";

export default function Settings({tabs}) {
  const init = tabs[0].value;
  const [currentTab, setTab] = useTab(init);

  return (
    <>
      <Typography id="walk-settings" className="page-title settings-main">Settings</Typography>

      <Wrapper elevation={0} className="settings-main">
      <DTabs
        orientation="vertical"
        value={currentTab}
        tabsData={tabs}
        onChange={setTab}
        className="settings"
        textColor="inherit"
      />
    </Wrapper>
      </>
  );
};