import React, {useState} from 'react';
import Modal from "react-bootstrap/Modal";
import { makeStyles } from "@material-ui/core";
import Form from "react-bootstrap/Form";
import * as PropTypes from "prop-types";
import {requiredValidator} from "../../../components/controls/validators";
import FormField from "../../../components/controls/FormField";
import {Form as FormikForm, Formik} from "formik";
import DButton from "../../../components/Button";
import {onValidation} from "../../../components/CreateEditModal";
import Notification from "../../../components/Notification";
import {processError} from "../EnterTransactionsBanking/EditAddBankingModal";

const useStyles = makeStyles(theme => ({
  date: {
    margin: theme.spacing(2),
  }
}));

export default function DownloadCustomerModal({ show, onClose, data }) {
  const classes = useStyles();
  const [errors, setErrors] = useState([]);
  const [isShowErrors, setIsShowErrors] = useState(false);
  const resetValue = () => setErrors([]);

  const formFields = [
    {
      id: 'start_date',
      label: 'Start Date',
      type: 'date',
    },
    { id: 'end_date',
      label: 'End Date',
      type: 'date',
      validator: requiredValidator("End date can't be blank"),
    },
  ];

  return (
    <Formik
      enableReinitialize
      initialValues={{}}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        const onDone = () => {
          setSubmitting(false);
          resetForm();
          onClose();
        };
        const onError = (error) => {
          processError(error);
          setSubmitting(false);
        };
        // getStatements({
        //   startData,
        //   endData,
        //   onDone,
        //   onError
        // })
      }}
    >
      {(form) => (
        <Modal show={show} onHide={onClose} className="modal--small" >
          <FormikForm>
            <Modal.Header closeButton>
              <Modal.Title>Download Customer Statement</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {isShowErrors
              && <Notification
                errors={errors}
                onClose={() => setIsShowErrors(false)}
                name={"statement"}/>
              }
              <div className="row">
                <div className="col-sm-12">
                  Enter the dates you would like the statement to cover.
                  You can leave the start date blank to show all invoices and payments up to the end date
                </div>
              </div>
              <div className="row">
                {
                  formFields.map((item) => (
                    <Form.Group key={item.id} controlId={item.id} className={"col-sm-5 mr-auto " + classes.date }>
                      <FormField
                        name={item.id}
                        label={item.label}
                        type={item.type}
                        validate={item.validator}
                        {...form}
                      />
                    </Form.Group>
                  ))
                }
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="container-button container-button--modal">
                <DButton
                  typeOfButton="doneAllSecondary"
                  type="submit"
                  disabled={form.isSubmitting}
                  onClickCustom={onValidation(form.validateForm, setErrors, setIsShowErrors, resetValue )}>
                  Generate Statement
                </DButton>
                <DButton typeOfButton="close" onClickCustom={onClose} >Close</DButton>
              </div>
            </Modal.Footer>
          </FormikForm>
        </Modal>
      )}
    </Formik>
  );
}

DownloadCustomerModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object,
};
