import React from "react";
import {IconButton} from "@material-ui/core";
import toast from "../../components/toast/toast";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import TooltipComponent from "../../components/TooltipComponent";
import useSettings from "../../../hooks/settings.hook";
import {useHistory} from "react-router";
import useUsers from "../../../hooks/users.hook";

const LogoutPPPButton = ({button}) => {
  const {user} = useUsers({fetch: false});
  const {updateAccountSetting} = useSettings({fetch: false});
  const logoutMsg = "Successfully logged out of PPP";
  const history = useHistory();

  const handleLogoutPPP = () => {
    updateAccountSetting({
      id: user.id,
      values: {
        ppp_api_key: "",
      }
    });
    toast.info(logoutMsg, {position: "top-right", duration: 5000});
    // show PPP login page
    history.push('/');
  };

  if (button) {
    return button(handleLogoutPPP)
  }

  return (
    <TooltipComponent tooltip="Log out of PPP">
      <IconButton
        aria-label="Logout"
        onClick={handleLogoutPPP}
      >
        <ExitToAppIcon/>
      </IconButton>
    </TooltipComponent>
  )
};

export default LogoutPPPButton