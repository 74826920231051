export const extraTitles = {
  value: 'table titles',
  data: "",
};

export const changedOptions = ["title_changed", "image_changed"];

export const errorId = {
  image: "image-changes",
  title: "title-changes"
};
