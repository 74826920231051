import React from "react";
import {TableCell, Avatar} from "@material-ui/core";
import * as PropTypes from "prop-types";
import classNames from "clsx";
import Flag from "./Flag";
import {copy} from "../../utils";
import toast from "../../../components/toast/toast";
import TooltipComponent from "../../../components/TooltipComponent";
import {toAbsoluteUrl} from "../../../../../_metronic";
import ContentCell from "../../Products/ContentCell";
import ErrorsLabel from "./ErrorsLabel";
import TooltipComponentState from "../../../components/TooltipComponentState";
import Title from "./Title";
import LabelNameCell from "./LabelNameCell";
import {isToShowBBPIcon, fulfilmentIcon} from "./utils";
import {marketplaceNames} from "../../../components/Marketplaces/Marketplaces";
import OverlayComponent from "../../../components/OverlayComponent";
import TooltipComponentTitile from "../../../components/TooltipComponentTitle";
import TooltipComponentTitle from "../../../components/TooltipComponentTitle";

const NameCell = (props) => {
  const {
    asin,
    url,
    sku,
    vat,
    title,
    marketplaces = [],
    onShowRelated,
    extra = {},
    colSpan,
    row,
    tableName,
    restockMarketplaces,
    onShowRestock,
    replenish,
    shouldRenderName,
    isMainReplenish,
    fulfillment,
    errors,
    lowOpacity,
    vat_validated,
    inbound_shipment,
    highlight,
    handleDisplayMetrics,
    showMetricsRow
  } = props;

  const onCopy = (content, name) => () => {
    copy(content);
    toast.success(`${name} copied!`);
  };

  const isTooltipTitle = tableName === "ppp";
  const {warningMessage, isExtraLine} = extra;
  const boldText = isMainReplenish ? title : "Title: ";
  const lightText = !isMainReplenish && title;
  const isShowExtra = extra && extra.isExtraLine;

  const titleProps = {
    replenish,
    onCopy,
    boldText,
    lightText,
    url,
    title,
  };

  return (
    <TableCell
      colSpan={colSpan}
      align="left"
      className={classNames("table__cell--name", {
        "table__cell--child": !shouldRenderName,
        "table__cell--parent": shouldRenderName,
        "low-opacity": lowOpacity,
        "highlight": highlight,
      })}
    >
      {isTooltipTitle ? (
        <TooltipComponentState tooltip={title} placement={"top"}>
          <Title {...titleProps} />
        </TooltipComponentState>
      ) : (
        <TooltipComponentTitle tooltip={titleProps.title} placement={"right"}>
          <Title {...titleProps} />
        </TooltipComponentTitle>

      )}

      {(shouldRenderName || isShowExtra) && (
        <>
          <div className="table__cell--detail">
            {sku && (
              <div className="table__cell--detail__content sku">
                <div className="sku__inner" onClick={onCopy(sku, "SKU")}>
                  <span className="bold table__cell--detail__title">SKU:</span>
                  <TooltipComponentTitile tooltip={sku} placement={"right"}/>
                </div>
                {extra.fnsku && (
                  <div className="sku__inner" onClick={onCopy(extra.fnsku, "FNSKU")}>
                    <span className="bold table__cell--detail__title">FNSKU:</span>
                    <span className="copy-hover">{extra.fnsku}</span>
                  </div>
                )}
              </div>
            )}
            {/*{sku && (*/}
              {/*<span*/}
                {/*className="table__cell--detail__content sku"*/}
                {/*onClick={onCopy(sku, "SKU")}*/}
              {/*>*/}
                {/*<span className="bold table__cell--detail__title">SKU:</span>*/}
                {/*<span className="copy-hover">{sku}</span>*/}
                {/*{extra.fnsku && <>*/}
                  {/*<span className="bold table__cell--detail__title">FNSKU:</span>*/}
                  {/*<span className="copy-hover">{extra.fnsku}</span>*/}
                {/*</>}*/}

              {/*</span>*/}
              {/**/}
              {/**/}
            {/*)}*/}

            <div className="table__cell--detail__content--wrap">
              <span
                className="table__cell--detail__content asin"
                onClick={onCopy(asin, "ASIN")}
              >
                <span className="bold table__cell--detail__title">ASIN:</span>
                <TooltipComponentTitile tooltip={asin} placement={"right"}/>
              </span>

              {extra.bsr}

              {/*{isExtraLine && (!!extra.vat || extra.vat === 0) && (*/}
                {/*<UserVATRate*/}
                  {/*parent={(props) => (*/}
                    {/*<VatRateTrigger {...props} vat={extra.vat} disabled/>*/}
                  {/*)}*/}
                  {/*row={row}*/}
                  {/*tableName={tableName}*/}
                  {/*disabled*/}
                {/*/>*/}
              {/*)}*/}

              {/*{(!!vat || vat === 0) && !isMainReplenish && (*/}
                {/*<UserVATRate*/}
                  {/*parent={(props) => <VatRateTrigger row={row} vat={vat} vat_validated={vat_validated} {...props} />}*/}
                  {/*row={row}*/}
                  {/*tableName={tableName}*/}
                {/*/>*/}
              {/*)}*/}

              {extra.deemedReseller && (
                <LabelNameCell tooltip={extra.deemedReseller.tooltip || ""} content={extra.deemedReseller.name}/>
              )}

              {isMainReplenish && inbound_shipment && (
                <TooltipComponent tooltipStyle="tab-table__number__btn">
                  <span className="table__cell--detail__content vat">
                    automatic replenish
                  </span>
                </TooltipComponent>
              )}

              {isMainReplenish && !inbound_shipment && (
                <TooltipComponent tooltipStyle="tab-table__number__btn">
                  <span className="table__cell--detail__content vat">
                    manual replenish
                  </span>
                </TooltipComponent>
              )}

              {warningMessage && <ContentCell {...warningMessage} />}

              {isToShowBBPIcon(row, tableName) && (
                <Avatar
                  alt="img"
                  variant="square"
                  src={toAbsoluteUrl("/media/d-icons/name-cell-icon/BBP-B.png")}
                  className="table__cell--detail__bbp-import"
                />
              )}
              {!!errors?.length && errors.map((error) => (
                <ErrorsLabel
                  key={error.id}
                  error={error}
                  handleDisplayMetrics={handleDisplayMetrics}
                  row={row}
                  showMetricsRow={showMetricsRow}
                />
              ))}
              {marketplaces && (
                <div
                  className="table__cell--detail__marketplaces"
                  onClick={onShowRelated}
                >
                  {marketplaces.map((item, index) => {
                    return (
                      <Flag
                        key={index}
                        marketplace={item}
                        className="marketplace"
                        placement="top"
                        tooltip={marketplaceNames[item]}
                      />
                    );
                  })}
                </div>
              )}
              {fulfillment && (
                <img
                  alt="img"
                  className={classNames("table__cell__img--icon icon-ff-small")}
                  src={fulfilmentIcon[fulfillment].url}
                />
              )}
              {restockMarketplaces && (
                <div
                  className="table__cell--detail__marketplaces"
                  onClick={onShowRestock}
                >
                  {restockMarketplaces.map((item, index) => {
                    return (
                      <Flag
                        key={index}
                        marketplace={item}
                        className="marketplace"
                        placement="top"
                      />
                    );
                  })}
                </div>
              )}
            </div>
            {isExtraLine && extra.data && (
              <div className="detail__content__item-wrap">
                <span
                  className="table__cell--detail__content order"
                  onClick={onCopy(extra.rowData, "Order ID")}
                >
                  {extra.data}
                </span>
                {/*<Link href={extra.url} target="_blank" className={classNames({"copy-hover": !extra.refundOrderInfo})}>*/}
                  {/*<AmazonSCIcon/>*/}
                {/*</Link>*/}
                {extra.refundOrderInfo && (
                  <OverlayComponent tooltip="Open sale">
                    <img
                      src={toAbsoluteUrl(
                        "/media/logos/new-logo-icon.svg"
                      )}
                      alt="SKUify logo"
                      className="logo-icon"
                      onClick={() =>
                        extra.refundOrderInfo(row.order__amazon_order_id)
                      }
                    />
                  </OverlayComponent>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </TableCell>
  );
};

NameCell.propTypes = {
  asin: PropTypes.string,
  url: PropTypes.string,
  sku: PropTypes.string,
  vat: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string,
  parent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  marketplaces: PropTypes.array,
  onShowRelated: PropTypes.func,
  relatedProduct: PropTypes.bool,
  withChild: PropTypes.bool,
  extra: PropTypes.object,
  colSpan: PropTypes.number,
  row: PropTypes.object,
  tableName: PropTypes.string,
  handleDisplayMetrics: PropTypes.func,
  showMetricsRow: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default NameCell;
