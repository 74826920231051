import React from "react";
import moment from "moment";
import {Typography} from "@material-ui/core";
import {settingsTabs} from "./Settings/tabsInterfaces/settings";

export const INTERCOM_ID = 'ffpjc7go';

export const pageViewEvent = () => {
  window.fbq('track', 'PageView')
};

/**
 * Added thousands separators
 * @param x: number
 * @param type_number: string
 * @returns string
 */
export const numberWithCommas = (x = 0, type_number) => {
  const fractionDigits = type_number === "integer" ? 0 : 2;
  const options = {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
    minimumIntegerDigits: 1,
    useGrouping: true,
  };

  const result = Number(x).toLocaleString('en', options);
  return result === "NaN" ? "" : result;
};

export const currencySymbols = {GBP: "£", EUR: "€", USD: "$"};

export const getNumber = (value) => {
  if (value !== null) {
    return parseFloat(value);
  } else {
    return null;
  }
};

/**
 * Currency or percent symbol added
 * @param units: string
 * @param number: number
 * @param type_number: string
 * @returns string
 */

export const withUnits = (units, number, type_number) => {
  try {
    let res;
    if (number === null) {
      return '-';
    } else if (units === "%") {
      res = ` ${numberWithCommas(number)}${units} `;
    } else if (!units) {
      res = ` ${numberWithCommas(number, type_number)} `
    } else {
      res = ` ${units}${numberWithCommas(number)} `
    }
    return res;
  } catch (e) {
    return ` ${units} 0 `;
  }
};

export const floatNumber = (number) => {
  if (!!number) {
    return typeof number === "string" ? parseFloat(number.split(",").join("")) : number;
  }
  return 0;
};
export const floatNumberFixed = (string) => floatNumber(string).toFixed(2);
export const replaceIndex = (val) => val.replace(/[£$€%]/g, '');

export const formatNumber = (str, prevValue) => {
  const prev = `${prevValue}`;
  const float = floatNumber(str);
  const index = str.indexOf('.');
  const thousand = str.match(/[,]/g);
  const countThousand = thousand && thousand.length;
  const isAdd = prev === "0.00" || prev.length + countThousand < str.length;

  if (str === "") {
    return "";
  }

  if (float === 0) {
    return "0.00";
  }

  if (index !== -1) {
    if (index === 0) {
      return str.length < 4 ? float.toFixed(2) : (float * 10).toFixed(2);
    }
    if (float % 1 === 0) {
      if (isAdd || ((prev.length + countThousand === str.length) && (str[str.length - 2] === "0"))) {

        if (prev === "0.00") {
          if (float > 9) {
            return float / 1000;
          } else {
            return float / 100
          }
        }
        // if (prev === "0.00" && float > 9) {
        //   return (float / 10).toFixed(2)
        // }
        if (str[str.length - 2] === ".") {
          return (float / 10).toFixed(2)
        }

        if (str.length > 4 && str[0] === "0") {
          return floatNumber(str.replace(/[0.]/g, ''));
        }

        if (str[str.length - 1] === ".") {
          return str;
        }

        if (str.slice(-3) === "000") {
          return (float * 10).toFixed(2)
        }

        return float.toFixed(2);
      } else {
        return (float / 10).toFixed(2)
      }
    }
    if (float % 1 !== 0) {
      if (str.length > 4 && str[str.length - 3] !== "." && str[str.length - 1] === "0") {
        if (prev === "0.00") {
          return str.replace(/[0.]/g, '')
        }
        return (float * 10).toFixed(2)
      }

      if (str[str.length - 1] === "0" && str[1] === ".") {
        return (float).toFixed(2);
      }

      if (prev.includes("0.") && str[1] === "." && str.length < 5) {
        return str
      }

      if (prev[prev.length - 1] === ".") {
        return str;
      }

      if (str.indexOf(".") === str.length - 3) {
        return str;
      }

      if (isAdd) {
        return (float * 10).toFixed(2)
      } else {
        return (float / 10).toFixed(2)
      }
    }
  } else {
    if (float % 1 === 0 && str.length > 2) {
      return isAdd ? (float / 100).toFixed(2) : (float / 100).toFixed(2);
    }
    if (float % 1 === 0) {
      return parseFloat(float) / 100;
    }
    return str
  }
};

export const getResource = async (url) => {
  const res = await fetch(url);
  return await res.json();
};

export const copy = require('clipboard-copy');

export const filterItems = [
  {id: 'balance', label: 'No Balance', isActive: true},
  {id: 'inactive', label: 'Inactive', isActive: true}
];

export const getFieldNameArray = (arrayName, index, name) => `${arrayName}.${index}.${name}`;

export const getDefaultMarketplace = () => {
  const defaultMarketplace = localStorage.getItem('defaultMarketplace');
  if (!!defaultMarketplace) {
    return defaultMarketplace;
  } else {
    return "all_marketplaces";
  }
};

export const fulfillmentFullNames = {
  fba: 'Fulfillment by Amazon',
  mf: 'Merchant Fulfilling',
  sfp: 'Seller fulfilled Prime',
};


export const momentFormats = {
  display: "DD MMM YYYY",
  backend: "YYYY-MM-DD",
};

export const momentFormatOptions = {
  display: momentFormats.display,
  backend: momentFormats.backend,
  placeholder: moment().format(momentFormats.display)
};

export const getKeyByValue = (obj, value) =>  Object.keys(obj).find(key => obj[key] === value);

export const uniqByKey = (arr, key) => [...new Map(arr.map(item => [item[key], item])).values()];

// export const getFromArrayByKey = (arr, key, value) => arr.filter(obj => obj[key] === value)[0];

export const filterObject = (obj, func) => Object.fromEntries(Object.entries(obj).filter(func));

export const onSplitDate = (date) => {
  const partsDate = date.split(" ");
  const [time, ...other] = partsDate;
  const dateWithoutTime = other.join(" ");
  return <>
    <span className="table__cell--date time">{time}</span>
    <span className="table__cell--date date">{dateWithoutTime}</span>
  </>;
};

export const withoutSpace = (string) => string.replace(/\s+/g, '\u00A0');

export const currentDate = moment();
export const formatDisplayDate = (date) => moment(date).format(momentFormatOptions.display);
export const nextDay = (date) => moment(date).add(1, 'day').format(momentFormatOptions.display);
export const prevDay = (date) => moment(date).subtract(1, 'day').format(momentFormatOptions.display);
export const currentDay = moment().format(momentFormatOptions.display);

export const ContentStep = ({title, text}) => <Typography variant="subtitle1" className="walk-step">
  <span>{title}</span>
  {text}
</Typography>;

export const settingsWalk = settingsTabs.filter(({walk}) => walk).map(({value, walk, walkTitle}) => {
  return ({
    placement: "right",
    placementBeacon: "right",
    target: `#${value}`,
    content: <ContentStep title={walkTitle} text={walk} />,
  })
});

export const walkThroughSteps = [
  {
    placement: "right",
    placementBeacon: "right-end",
    target: "[href='/dashboard']",
    content: <ContentStep
      title="Dashboard"
      text=" - Here you’ll find a summary of activity in your account.
       You can view present and historical information for your sales and orders,
        and find out which of your products are excelling in the Winners/Losers section."
    />,
    disableBeacon: true
    },
  {
    placement: "right",
    placementBeacon: "right",
    target: "[href='/products']",
    content: <ContentStep
      title="Inventory"
      text=" - See all of your products and their cost of goods information.
      From this, you can get estimates for the ROI and profit per item.
      You’ll also be able to replenish products when the time comes too!"
    />,
  },
  {
    placement: "right",
    placementBeacon: "right",
    target: "[href='/sales']",
    content: <ContentStep
      title="Sales"
      text=" - View your current sales and shipment statuses for all of your orders and refunds."
    />,
  },
  {
    placement: "right",
    placementBeacon: "right",
    target: "[href='/repricer']",
    content: <ContentStep
      title="Repricing"
      text=" - Using Profit Protector Pro? Simplify your workflow and cut down on numerous tabs being open by setting up repricing information for your products all from within SKUify!"
    />,
  },
  {
    placement: "right",
    placementBeacon: "right",
    target: "[href='/journey']",
    content: <ContentStep
      title="Journey"
      text=" - No more being left in the dark about anything happening with your inventory,
      as this section is going to help you keep a track of the full journey
      all of your products make. See when they’ve arrived at Amazon,
      find out if any are stranded, and much more!"
    />,
  },
  {
    placement: "right",
    placementBeacon: "right",
    target: "[href='/reconciliation']",
    content: <ContentStep
      title="Reconciliation"
      text=" - In the unfortunate event your products get lost or damaged,
      you’ll be notified here. Don’t let it go amiss, get reimbursed!
      Check back in here for the status of any products you need to or have reconciled."
    />,
  },
  {
    placement: "right",
    placementBeacon: "right",
    target: "[href='/reports']",
    content: <ContentStep
      title="Reports"
      text=" - This is where you’ll come when you need to summarise an element of your business.
      How many sales did you make this month? Need a stock report? Want to know if there are any missing
      COGs for a period? Select the type of report you want and get it here!"
    />,
  },
  {
    placement: "right",
    placementBeacon: "right",
    target: "[href='/expenses']",
    content: <ContentStep
      title="Expenses"
      text=" - Let SKUify know of any additional costs there
      are in your business in order to help keep a track of all of your finances."
    />,
  },
  {
    placement: "right",
    placementBeacon: "right",
    target: "[href='/settings']",
    content: <ContentStep
      title="Settings"
      text=" - Please work through and configure each of your section within the settings
       to get SKUify exactly the way you want it. Link up your seller central account,
       enter in Cost Of Goods, upgrade your subscription and more!"
    />,

  },
  {
    placement: "right",
    placementBeacon: "right",
    target: "#walk-settings",
    title: "",
    content: <ContentStep title="Settings" text=" - Welcome! We know how excited you are to get started with SKUify,
     so let’s get you set up and ready to roll! - Firstly, you’ll need to configure your settings before anything else."
    />
  },
  ...settingsWalk,
];

export const formatDate = (date, format) => moment(date, format || "DD-MM-YYYY").format(momentFormatOptions.display);