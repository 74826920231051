import api from "../../crud/bank.crud";
import { call, put, takeLatest } from 'redux-saga/effects'
import { createSlice } from "@reduxjs/toolkit";
import logger from "../../pages/logger";
import {shortBaseState} from "./utils";

const initialState = {
  banks: [],
  bank: null,
  bankRules: [],
  bankRule: null,
  bankRuleLoading: false,
  loading: true,
  saving: false,
  errors: [],
  bankFiles: shortBaseState,
};


const common = createSlice({
  name: 'bank',
  initialState,
  reducers: {
    getBanks: (state) => {
      state.loading = true;
      return state;
    },
    getBanksSuccess: (state, action) => {
      const { banks } = action.payload;
      state.banks = banks;
      state.loading = false;
      return state;
    },
    getBanksError: (state, action) => {
      state.loading = false;
      return state;
    },
    addBank: (state, actions) => {
      // state.loading = true;
      return state;
    },
    addBankSuccess: (state, actions) => {
      const {bank} = actions.payload;
      state.banks = [...state.banks, bank];
      // state.loading = false;
      return state;
    },
    updateBank: (state, actions) => {
      // state.loading = true;
      return state;
    },
    updateBankSuccess: (state, actions) => {
      state.banks = state.banks.map((bank) => {
        if (bank.id === actions.payload.id) {
          return actions.payload.bank;
        }
        return bank;
      });
      return state;
    },
    deleteBank: (state, actions) => {
      // state.loading = true;
      return state;
    },

    deleteBankSuccess: (state, actions) => {
      const {id} = actions.payload;
      state.banks = state.banks.filter((bank) => (bank.id !== id));
      return state;
    },

    deleteBankError: (state, actions) => {

    },

    getBank: (state, actions) => {
      // state.loading = true;
      return state;
    },
    getBankSuccess: (state, actions) => {
      const { bank } = actions.payload;
      state.bank = bank;
      state.loading = false;
      return state;
    },

    saveTransaction: () => {
      // TODO implement
    },
    getBankRules: (state) => {
      state.loading = true;
    },
    getBankRulesSuccess: (state, action) => {
      const { bankRules } = action.payload;
      state.bankRules = bankRules;
      state.loading = false;
    },
    addBankRule: (state, actions) => {
      // state.loading = true;
      return state;
    },
    addBankRuleSuccess: (state, actions) => {
      const {bankRule} = actions.payload;
      state.bankRules = [...state.bankRules, bankRule];
      // state.loading = false;
      return state;
    },
    updateBankRule: (state, actions) => {
      // state.loading = true;
      return state;
    },
    updateBankRuleSuccess: (state, actions) => {
      state.bankRules = state.bankRules.map((bankRule) => {
        if (bankRule.id === actions.payload.id) {
          return actions.payload.bankRule;
        }
        return bankRule;
      });
      return state;
    },
    deleteBankRule: (state, actions) => {
      // state.loading = true;
      return state;
    },
    deleteBankRuleSuccess: (state, actions) => {
      const {id} = actions.payload;
      state.bankRules = state.bankRules.filter((bankRule) => (bankRule.id !== id));
      return state;
    },
    getBankRule: (state, actions) => {
      state.bankRuleLoading = true;
      return state;
    },
    getBankRuleSuccess: (state, actions) => {
      const { bankRule } = actions.payload;
      state.bankRule = bankRule;
      state.bankRuleLoading = false;
      return state;
    },
    getBankRuleError: (state, actions) => {
      state.bankRuleLoading = false;
      return state;
    },

    getBankFiles: (state, actions) => {
      state.bankFiles.loading = true;
      return state;
    },

    getBankFilesSuccess: (state, actions) => {
      const { bankFiles } = actions.payload;
      state.bankFiles.details = bankFiles;
      state.bankFiles.loading = false;
    },

    getBankFilesError: (state, actions) => {
      state.bankFiles.loading = false;
      return state;
    },
    updateBankFile: (state, actions) => {
      // state.bankFiles.loading = true;
      return state;
    },

    updateBankFileSuccess: (state, actions) => {
      state.bankFiles.details = state.bankFiles.details.map((item) => {
        if (item.id === actions.payload.id) {
          return actions.payload.bankFile;
        }
        return item;
      });
      return state;
    },

    addBankFile: (state, actions) => {
      // state.loading = true;
      return state;
    },
    addBankFileSuccess: (state, actions) => {
      const {bankFile} = actions.payload;
      state.bankFiles.details = [...state.bankFiles.details, bankFile];
      // state.loading = false;
      return state;
    },
  }
});

export const actions = common.actions;
export const reducer = common.reducer;

export function* saga() {
  yield takeLatest(actions.getBanks, function* getBanksSaga({ payload }) {
    try {
      let q = payload && payload.q;
      const { data } = yield call(api.getBanks, q);
      if (data) {
        yield put(actions.getBanksSuccess({ banks: data }));
      }
      yield put(actions.getBanksError());
    } catch (err) {
      yield put(actions.getBanksError());
      console.error(err);
    }
  });

  yield takeLatest(actions.addBank, function* addBankSaga({ payload: { values, onDone, onError } }) {
    try {
      const { data, ...props } = yield call(api.addBank, values);
      if (data) {
        yield put(actions.addBankSuccess({ bank: data }));
        onDone();
      } else {
        onError(props);
      }
    } catch (err) {
      onError(err);
      logger.error(err);
    }
  });

  yield takeLatest(actions.updateBank, function* updateBankSaga({ payload: { id, values, onDone, onError } }) {
    try {
      const { data, ...props } = yield call(api.updateBank, id, values);
      if (data) {
        yield put(actions.updateBankSuccess({ bank: data, id }));
        onDone();
      } else {
        onError(props);
      }
    } catch (err) {
      onError(err);
      logger.error(err);
    }
  });

  yield takeLatest(actions.getBank, function* getBankSaga({ payload: { id } }) {
    try {
      const { data } = yield call(api.getBank, id);
      if (data) {
        yield put(actions.getBankSuccess({ bank: data }));
      }
    } catch (err) {
      logger.error(err);
    }
  });

  yield takeLatest(actions.deleteBank, function* deleteBankSaga({ payload: { id, onDone } }) {
    try {
      yield call(api.deleteBank, id);
      yield put(actions.deleteBankSuccess({ id }));
      if (onDone)
        onDone();
    } catch (err) {
      logger.error(err);
    }
  });

  yield takeLatest(actions.getBankRules, function* getBankRulesSaga() {
    try {
      const { data } = yield call(api.getBankRules);
      if (data) {
        yield put(actions.getBankRulesSuccess({ bankRules: data }));
      }
    } catch (err) {
      console.error(err);
    }
  });

  yield takeLatest(actions.addBankRule, function* addBankRuleSaga({ payload: { values, onDone, onError } }) {
    try {
      const { data, ...props } = yield call(api.addBankRule, values);
      if (data) {
        yield put(actions.addBankRuleSuccess({ bankRule: data }));
        onDone();
      } else {
        onError(props);
      }
    } catch (err) {
      onError(err);
      logger.error(err);
    }
  });

  yield takeLatest(actions.updateBankRule, function* updateBankRuleSaga({ payload: { id, values, onDone, onError } }) {
    try {
      const { data, props } = yield call(api.updateBankRule, id, values);
      if (data) {
        yield put(actions.updateBankRuleSuccess({ bankRule: data, id }));
        onDone();
      } else {
        onError(props);
      }
    } catch (err) {
      onError(err);
      logger.error(err);
    }
  });

  yield takeLatest(actions.getBankRule, function* getBankSaga({ payload: { id } }) {
    try {
      const { data } = yield call(api.getBankRule, id);
      if (data) {
        yield put(actions.getBankRuleSuccess({ bankRule: data }));
      }
      yield put(actions.getBankRuleError());
    } catch (err) {
      yield put(actions.getBankRuleError());
      logger.error(err);
    }
  });

  yield takeLatest(actions.deleteBankRule, function* deleteBankRuleSaga({ payload: { id, onDone } }) {
    try {
      yield call(api.deleteBankRule, id);
      yield put(actions.deleteBankRuleSuccess({ id }));
      if (onDone)
        onDone();
    } catch (err) {
      logger.error(err);
    }
  });

  yield takeLatest(actions.getBankFiles, function* getBankFilesSaga() {
    try {
      const { data } = yield call(api.getBankFiles);
      if (data) {
        yield put(actions.getBankFilesSuccess({ bankFiles: data }));
      }
      yield put(actions.getBankFilesError());
    } catch (err) {
      yield put(actions.getBankFilesError());
      logger.error(err);
    }
  });

  yield takeLatest(actions.updateBankFile, function* updateBankFileSaga({ payload: { id, values, onDone, onError } }) {
    try {
      const { data, ...props } = yield call(api.updateBankFile, id, values);
      if (data) {
        yield put(actions.updateBankFileSuccess({ bankFile: data, id }));
        if (onDone) {
          onDone();
        }
      } else {
        if (onError) {
          onError(props);
        }
      }
    } catch (err) {
      if (onError) {
        onError(err);
      }
      logger.error(err);
    }
  });

  yield takeLatest(actions.addBankFile, function* addBankSaga({ payload: { values, onDone, onError } }) {
    try {
      const { data, ...props } = yield call(api.uploadBankFile, values);
      if (data) {
        yield put(actions.addBankFileSuccess({ bankFile: data }));
        if (onDone)
          onDone();
      } else {
        if (onError)
          onError(props);
      }
    } catch (err) {
      if (onError)
        onError(err);
      logger.error(err);
    }
  });
}
