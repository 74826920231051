import { headerRowsInvoices } from "./headerRowsInvoices";
import React from "react";
import Wrapper from "../../components/Wrapper";

export const WrapContainer = ({ children, step }) => step ? <>{children}</> : <Wrapper>{children}</Wrapper>;

export const namesSaveBtn = {
  "new recurring invoice": "Save and Recur",
  "edit recurring invoice": "Save Template",
};

export const titleOptions = {
  "new customers": "New Customer Invoice",
  "edit customers": "Edit Customer Invoice",
  "new suppliers": "New Supplier Invoice",
  "edit suppliers": "Edit Supplier Invoice",
  "new recurring invoice": "New Recurring Invoice",
  "edit recurring invoice": "Edit Recurring Invoice",
};

export const getTaxValue = (value) => value ? value : 0;

export const getFieldName = (index, name) => `invoices.${index}.${name}`;

export const fields = headerRowsInvoices.map(({ id }) => id);

export const items = [
  {
    id: 0,
    date: "01/01/2020",
    type: "Money In | MI",
    account: "New",
    category: "Sales",
    inv_ref: "1",
    description: "test",
    currency: "GBP",
    net_amount: 12,
    tax_code: "Standard VAT",
    tax_amount: 12,
    total_amount: 123,
    conversion_rate: undefined,
  },
  {
    id: 1,
    date: "01/10/2019",
    type: "Money Out | MO",
    account: "Edit",
    category: "UK Sales",
    inv_ref: 2,
    description: "test 2",
    currency: "EUR",
    net_amount: 1,
    tax_code: "Reduced Rate",
    tax_amount: 2,
    total_amount: 3,
    conversion_rate: undefined,
  },
];