import React, {useState} from "react";
import TableComponent from "../../../../components/TableComponent/TableComponent";
import TableToolbarComponent from "../../../../components/TableComponent/TableToolbarComponent";
import {useCheckboxStorage, usePerPage} from "../../../../../hooks/modal.hook";
import DButton from "../../../../components/Button";
import withConfirmHook from "../../../../../hooks/withConfirm.hook";
import * as PropTypes from "prop-types";
import {useHistory} from "react-router-dom";
import {goToImportCustomPage, goToImportedBankTransactions, goToNewImportPage} from "../../utils";
import queryString from 'query-string';
import Wrapper from "../../../../components/Wrapper";
import * as Selector from "../../../../../selectors/imortBankTransactions.selectors";
import withHelmet from "../../../../../hocs/withHelmet";
import {connect} from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import DoneAllIcon from '@material-ui/icons/DoneAll';
import {ReactComponent as ImportExportIcon} from '../../../../../media/icons/microsoft-excel.svg';
import DeleteIcon from '@material-ui/icons/Delete';
import Spinner from "../../../../components/Spinner";
import useBankEntry from "../../../../../hooks/bankEntry.hook";

const title = "Bank Entries";

const headRows = [
  {id: 'description', numeric: false, disablePadding: true, label: 'Description', isActive: true},
  {
    id: 'name', numeric: false, disablePadding: true, label: 'Bank Account', isActive: true,
    renderText: (row) => {
      return row.bank_account.name
    }
  },
  {id: 'type', numeric: false, disablePadding: true, label: 'Type', isActive: true},
  {id: 'start_date', numeric: false, disablePadding: true, label: 'Start Date', isActive: true},
  {id: 'end_date', numeric: false, disablePadding: true, label: 'End Date', isActive: true},
  {id: 'uploaded', numeric: false, disablePadding: true, label: 'Uploaded', isActive: true},
  {id: 'drafts', numeric: false, disablePadding: true, label: 'Drafts', isActive: true},
  {id: 'confirmed', numeric: false, disablePadding: true, label: 'Confirmed', isActive: true},
  {
    id: 'actions', numeric: false, disablePadding: true, label: 'Actions', isActive: true,
    render: (row, {onDoneAll, onDelete, goToExport}) => {
      const isEmpty = !row.rows || row.rows.length === 0;
      return <TableComponent.ActionCell key={row.id}>
        <IconButton disabled={isEmpty} onClick={onDoneAll(row)}><DoneAllIcon color="primary"/></IconButton>
        <IconButton onClick={onDelete(row)}><DeleteIcon color="error"/></IconButton>
        <IconButton onClick={goToExport(row)}><ImportExportIcon/></IconButton>
      </TableComponent.ActionCell>
    }
  }
];


function ImportBankTransactions({confirm}) {
  const [active, setActive] = useCheckboxStorage("importBankTrans", headRows);
  const [currentRow, setCurrentRow] = useState(null);
  const [rowsPerPage, onPerPage] = usePerPage();


  let history = useHistory();
  // TODO when returning to the page should show entries for all banks
  const bankAcountId = queryString.parse(history.location.search).bank_account_id;
  const bankId = bankAcountId ? bankAcountId : 1;
  const {bankEntries, deleteBankEntry} = useBankEntry({bankId});

  if (bankEntries.loading) {
    return <Spinner className="d-spinner--full-height"/>
  }

  const onImportCustom = () => goToImportCustomPage(history);
  const onUploadBank = () => goToNewImportPage(history, bankId);

  const onDoneAll = (row) => () => {
    setCurrentRow(row);
    goToImportedBankTransactions(history, row.id, bankId);
  };

  const goToExport = (row) => () => {
    setCurrentRow(row);
    // TODO check if export csv is working
    window.open(`/api/v1/csv-export/${row.id}/`, "_blank") //to open new page
  };

  const onDelete = (row) => (
    confirm(() => {
        deleteBankEntry({id: row.id,})
      },
      {description: 'This action is permanent!'})
  );

  return <Wrapper>
    <TableToolbarComponent
      title={title}
      actionsHeader={{
        selectMenu: {setFunc: onPerPage, type: "select"},
        viewColumnMenu: {array: active, type: "filter", setFunc: setActive, disabled: true},
      }}
    />
    <TableComponent
      headRows={active.filter(column => column.isActive)}
      items={bankEntries.details}
      rowsPerPageSelected={rowsPerPage}
      rowProps={{onDoneAll, onDelete, goToExport}}
    />
    <div className="container-button">
      <DButton typeOfButton="import" onClickCustom={onUploadBank}>File Import</DButton>
      <DButton typeOfButton="setting" onClickCustom={onImportCustom}>Custom Import Formats</DButton>
    </div>
  </Wrapper>
}

ImportBankTransactions.propTypes = {
  confirm: PropTypes.func.isRequired,
};


const mapStateToProps = (state, props) => {
  return {
    bankEntries: Selector.selectBankEntries(state, props),
  }
};

export default withHelmet(
  connect(
    mapStateToProps,
  )(withConfirmHook(ImportBankTransactions)),
  {title: "SKUify"}
)

