export enum Themes {
  dark = 'DARK',
  light = 'LIGHT',
}

export enum NotificationsType {
  Product = 'Product',
  Order = 'Order',
  Refunded = 'Refunded',
}
