/* eslint-disable no-unused-vars */
import React from "react";
import UserProfile from "../../../../app/partials/layout/UserProfile";
import UserCurrency from "../../../../app/partials/layout/UserCurrency";
import Hello from "../../../../app/partials/layout/Hello";
import SKUNotifications from "../../../../app/partials/layout/SKUNotifications";
import UserThemes from "../../../../app/partials/layout/UserThemes";

export default class Topbar extends React.Component {
  render() {
    return (
        <div className="kt-header__topbar">
          <Hello />

        {/*<SearchHeader*/}
          {/*bgImage={toAbsoluteUrl("/media/misc/bg-1.jpg")}*/}
          {/*pulse*/}
          {/*pulseLight="false"*/}
          {/*skin="light"*/}
          {/*icon={toAbsoluteUrl("/media/icons/svg/General/Notification1.svg")}*/}
          {/*iconType=""*/}
          {/*type="success"*/}
          {/*useSVG*/}
          {/*dot="false"*/}
        {/*/>*/}

        {/*<UserNotifications*/}
          {/*bgImage={toAbsoluteUrl("/media/misc/bg-1.jpg")}*/}
          {/*pulse="true"*/}
          {/*pulseLight="false"*/}
          {/*skin="dark"*/}
          {/*icon={toAbsoluteUrl("/media/icons/svg/General/Notification2.svg")}*/}
          {/*iconType=""*/}
          {/*type="success"*/}
          {/*useSVG="true"*/}
          {/*dot="false"*/}
        {/*/>*/}
        {/*<UserReports />*/}
          <UserThemes />
          <UserCurrency />
        {/*<DUserNotifications/>*/}
        {/*<UserSettings />*/}

        {/*<QuickPanelToggler />*/}

        {/*<LanguageSelector iconType="" />*/}
        <SKUNotifications />
        <UserProfile showAvatar showHi showBadge={false} />
      </div>
    );
  }
}
