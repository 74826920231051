import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {descriptions} from "./optionnsDescription";
import TextField from "@material-ui/core/TextField";
import React, {useState} from "react";
import * as PropTypes from "prop-types";

const AutocompleteWithTooltip = ({/* descriptions,*/ onChange, value, className, }) => {
  const [isActive, setIsActive] = useState(false);
  const onOpen = () => setIsActive(true);
  const onClose = () => setIsActive(false);
  return <OverlayTrigger
    placement="top"
    overlay={
      <Popover id='popover-positioned-top'>
        {/*tooltip's text*/}
        {(!isActive && value) ? <Popover.Content>{value}</Popover.Content> : null}
      </Popover>}>
    <Autocomplete
      freeSolo
      className={"form-group__input-text--auto " + className}
      options={descriptions ? descriptions.map(option => option.title) : null}
      // hide tooltip
      onOpen={onOpen}
      onChange={onChange}
      onInputChange={onChange}
      // show tooltip
      onClose={onClose}
      value={value}
      renderInput={params => {
        return <TextField {...params} variant="standard" fullWidth/>
      }}
    />
  </OverlayTrigger>
};

AutocompleteWithTooltip.propTypes = {
  descriptions: PropTypes.array,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default AutocompleteWithTooltip;