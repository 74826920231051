import React from 'react';
import Modal from "react-bootstrap/Modal";
import * as PropTypes from "prop-types";
import EditBankRule from "./EditBankRule";
import {useModal} from "../../../../hooks/modal.hook";


function BankRuleModal({ isAddNewBank, bankId, transaction, show, onClose }) {
  let bankRuleId;
  const conditions = [];
  let initValues = null;

  // if we want to add new bank, we autocomplete some fields from transaction
  if (isAddNewBank) {
    initValues = {
      "auto_confirm_transactions": false,
      "auto_ignore_transactions": false,
      "bank_accounts": [transaction.bank_account.id],
    };
    if (transaction.description) {
      conditions.push(
        {
          "field": "description",
          "condition": "includes",
          "subscription": transaction.description
        })
    }
    if (transaction.type && transaction.type.value) {
      conditions.push(
        {
          "field": transaction.type.value,
          "condition": "eq",
          "subscription": transaction.total_amount,
        }
      )
    }
    initValues.conditions = conditions;
  } else {

    // passing bank rule id for editing bank rule
    bankRuleId = transaction.bank_rule;
  }

  return (
    <Modal size="lg" show={show} onHide={onClose}>
      <EditBankRule inModal bankRuleId={bankRuleId} initialData={initValues} />
    </Modal>
  );
}

BankRuleModal.propTypes = {
  // row for which one we want to create bank rule
  transaction: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isAddNewBank: PropTypes.bool,
};

export default BankRuleModal;