import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Form from "react-bootstrap/Form";
import FormField from "../../components/controls/FormField";

export const tabs = {
  "company_info": {
    name: "Business Info",
  }
};

const optionReportEU = [
  {label: "Monthly", value: "Monthly"},
  {label: "Quarterly", value: "Quarterly"}
];

export const optionReportVAT = [
  {label: "Monthly", value: "Monthly"},
  {label: "Quarterly", value: "Quarterly"},
  {label: "Annually", value: "Annually"},
  {label: "Other", value: "Other"},
];

const fieldsFinancialInfo = [
  [
    {
      "name": "supplier_credit",
      "label": "Do suppliers invoice the business?",
      "type": "checkbox",
      "help": null,
      "tab": "financial_tab"
    },
    {
      "name": "customer_credit",
      "label": "Does the business invoice customers?",
      "type": "checkbox",
      "help": null,
      "tab": "financial_tab",
      "render": ({name, setFieldValue, setFieldTouched, label, valueMultiple, customProps, ...props}) => {
        const handleChange = (e, value) => {
          setFieldValue(name, value);
        };
        const handleBlur = () => {
          setFieldTouched(name, true);
        };
        return <>
          <FormControlLabel
            key="customer_credit"
            name="customer_credit"
            control={<Checkbox color="primary"/>}
            label="Does the business invoice customers?"
            labelPlacement="start"
            onChange={handleChange}
            onBlur={handleBlur}
            checked={!!props.value}
          />
          {
            !!props.value &&
            <div className="form-group__text-block">
              <ErrorOutlineIcon fontSize="small" className="form-group__icon"/>
              After you click update company, you can change your document settings from the options menu at
              the top right.
            </div>
          }
        </>
      }
    },
    {
      "name": "employees",
      "label": "Does the business have employees?",
      "type": "checkbox",
      "help": null,
      "tab": "financial_tab"
    },
    {
      "name": "multiple_currencies",
      "label": "Does the business use multiple currencies?",
      "type": "checkbox",
      "help": null,
      "tab": "financial_tab"
    },
    {
      "name": "base_currency",
      "label": "Base currency",
      "type": "text",
      "help": "<p>The currency selected here will determine the conversion rates for invoices and category balances.</p>",
      "tab": "financial_tab"
    },
    {
      "name": "vat_registration_attributes_vat_registered",
      "label": "Is the business VAT Registered?",
      "type": "checkbox",
      "help": null,
      "tab": "financial_tab"
    },
    {
      "name": "has_motor_vehicles",
      "label": "Does the business own motor vehicles?",
      "type": "checkbox",
      "help": null,
      "tab": "financial_tab"
    },
    {
      "name": "materials_goods",
      "label": "Does the business use materials or sell goods?",
      "type": "checkbox",
      "help": null,
      "tab": "financial_tab"
    },
    {
      "name": "uses_subcontractors",
      "label": "Does the business use subcontractors?",
      "type": "checkbox",
      "help": null,
      "tab": "financial_tab"
    },
    {
      "name": "has_merchant_account",
      "label": "Does the business use a merchant account for processing payments?",
      "type": "checkbox",
      "help": null,
      "tab": "financial_tab"
    },
    {
      "name": "uses_projects",
      "label": "Does the business use projects?",
      "type": "checkbox",
      "help": null,
      "tab": "financial_tab"
    },
    {
      "name": "uses_receipt_upload",
      "label": "Does the business use receipt uploads?",
      "type": "checkbox",
      "help": null,
      "tab": "financial_tab"
    },
    {
      "name": "vat_registration_attributes_vat_number",
      "label": "VAT Registration Number",
      "type": "text",
      "help": "<p>This number can be found on the business' " +
        "VAT certificate issued by HMRC or by contacting HMRC directly.</p>" +
        "<p>Entering the VAT registration number here will display it on the business' invoices.</p>",
      "tab": "financial_tab"
    },
    {
      "name": "vat_registration_attributes_vat_registration_date",
      "label": "VAT Registration Date",
      "type": "date",
      "help": "<p>Otherwise known as the effective date of registration." +
        " This date can be found on the business' VAT certificate or by contacting HMRC</p>",
      "tab": "financial_tab"
    },
    {
      "name": "vat_registration_attributes_vat_reporting_period",
      "label": "How often do you report your VAT?",
      "type": "RSelect",
      "help": null,
      "tab": "financial_tab",
      "render": ({setFieldValue, showLabel, setFieldTouched, valueMultiple, customProps, values, ...props}) => {

        const name = "vat_registration_attributes_vat_reporting_period";
        const handleChange = (event) => {
          setFieldValue(name, event.target.value);
        };
        const handleBlur = () => {
          setFieldTouched(name, true);
        };
        const value = values && values.vat_registration_attributes_vat_reporting_period;
        return <>
          <Form.Group key={name} controlId={name}>
            <Form.Label>How often do you report your VAT?</Form.Label>
            <FormField
              id={name}
              name={name}
              onChange={handleChange}
              onBlur={handleBlur}
              type="RSelect"
              customProps={{options: optionReportVAT}}
              {...props}
              value={value || ''}
            />
          </Form.Group>
        </>
      },
    },
    {
      "name": "vat_registration_attributes_uses_eu_vat",
      "label": "Does the business sell to or buy from other EU VAT registered businesses?",
      "type": "checkbox",
      "help": null,
      "tab": "financial_tab",
    },
    // TODO should hidden when value false of checkbox vat_registration_attributes_uses_eu_vat
    {
      "name": "vat_registration_attributes_ec_sales_reporting_period",
      "label": "How often do you report your EC Sales?",
      "type": "RSelect",
      "help": "<p>EC sales lists can be submitted quarterly or monthly</p> " +
        "<span>It is compulsory to send monthly lists to HMRC if your business " +
        "has supplied goods totalling more than 35,000 to VAT-registered customers" +
        " in other EU countries in any of the last 4 quarters</span>",
      "tab": "financial_tab",
      "render": ({setFieldValue, showLabel, setFieldTouched, valueMultiple, customProps, values, ...props}) => {
        const name = "vat_registration_attributes_ec_sales_reporting_period";
        const handleChange = (event) => {
          setFieldValue(name, event.target.value);
        };
        const handleBlur = () => {
          setFieldTouched(name, true);
        };
        const visible = values && values.vat_registration_attributes_uses_eu_vat;
        const value = values && values.vat_registration_attributes_ec_sales_reporting_period;
        return <>
          {visible && <Form.Group key={name} controlId={name}>
            <Form.Label>How often do you report your EC Sales?</Form.Label>
            <FormField
              id={name}
              name={name}
              onChange={handleChange}
              onBlur={handleBlur}
              type="RSelect"
              customProps={{options: optionReportEU}}
              {...props}
              value={value || ''}
            />
          </Form.Group>}
        </>
      }
    }
  ]
];
export const companyFieldsFinancialInfo = fieldsFinancialInfo.flat();