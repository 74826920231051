import React from "react";
import {Button} from "@material-ui/core";
import {Form as FormikForm, Formik} from "formik";
import Form from "react-bootstrap/Form";
import FormField from "../../../components/controls/FormField";

export const EditForm = ({initialValues, onSubmit, formFields, close}) => {
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({...form}) => (
        <FormikForm>
          <div className="form-items-wrapper">
            {formFields.map(field => (
              <Form.Group key={field.id} controlId={field.id} className="form-item-wrapper">
                <Form.Label column={false}>
                  {field.label}
                </Form.Label>
                <FormField
                  name={field.id}
                  label={field.label}
                  type={field.type}
                  customProps={field.customProps}
                  {...form}
                />
              </Form.Group>
            ))}
            <div className="tab-table__note__wrap-btn">
              <Button onClick={close} className="tab-table__note__btn">Close</Button>
              <Button className="tab-table__note__btn" type="submit">Save</Button>
            </div>
          </div>
        </FormikForm>
      )}
    </Formik>
  )
};
