import React from 'react';
import Modal from "react-bootstrap/Modal";
import * as PropTypes from "prop-types";
import {Table, TableHead, TableRow, TableCell, TableBody} from "@material-ui/core";
import FormField from "../../../components/controls/FormField";
import {requiredValidator} from "../../../components/controls/validators";
import {ActionButtonsNewBankModal} from "./ActionButtonsNewBankModal";
import DButton from "../../../components/Button";
import SplitsCategory from "./SplitsCategory";

const SplitBankTransactionModal = ({ show, onClose, initValues, taxes, currencies }) => {

  const currency = [
    {
      id: 'currency',
      label: 'Currency For Splits',
      type: 'RSelect',
      customProps: () => () => {
        return {
          options: currencies.details.map((i) => ({
            label: i.name,
            value: i.id,
          })),
        }
      },
    },
  ];

  const transactionsInterface = [
    {
      id: 'category',
      label: 'Category',
      type: 'RSelect',
      render: (props) => <SplitsCategory props={props} />,
    },
    {
      id: 'description',
      label: 'Description',
      type: "text",
    },
    {
      id: 'net_amount',
      label: 'Net',
      type: 'text',
    },
    {
      id: 'tax_code',
      label: 'Tax Code',
      type: 'RSelect',
      customProps: () => {
        return {
          byId: true,
          options: taxes.map((tax) => {
            return {
              label: tax.taxcode + " | " + tax.name + ' - ' + tax.tax_rate + "%",
              value: tax.id
            }
          }),
        }
      },
    },
    {
      id: 'tax_amount',
      label: 'Tax',
      type: 'number',
    },
    {
      id: 'total ',
      label: 'Total',
      type: 'number'
    },
  ];

  return <Modal show={show} onHide={onClose}>
    <Modal.Header closeButton>
      <Modal.Title>Splits</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="row wrap-new-bank-transaction-list">
        <Table className="form__table">
          <TableHead>
            <TableRow className="form__row">{currency.map(item =>
              (<TableCell key={item.id} className={"form__cell cell__" + item.id}>{item.label}</TableCell>))}</TableRow>
          </TableHead>
          <TableBody>
            <TableRow className="form__row form__row--body">
              {currency.map((item) => (
                <TableCell key={item.id} aria-label={item.label} className={"form__cell cell__" + item.id}>
                  <FormField
                    showLabel={false}
                    name={item.id}
                    label={item.label}
                    type={item.type}
                    validate={requiredValidator()}
                    customProps={item.customProps && item.customProps()}
                  />
                </TableCell>
              ))
              }
            </TableRow>
          </TableBody>
        </Table>
      </div>
      <div className="row wrap-new-bank-transaction-list">
        <Table className=" table--splits form__table">
          <TableHead>
            <TableRow className="form__row">
              {transactionsInterface.map(item =>
                (<TableCell key={item.id} className={"form__cell cell__" + item.id}>{item.label}</TableCell>))}
              <TableCell className="form__cell">Actions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow className="form__row form__row--body">
              {transactionsInterface.map((item) => (
                <TableCell key={item.id} aria-label={item.label} className={"form__cell cell__" + item.id}>
                  <FormField
                    showLabel={false}
                    render={item.render}
                    name={item.id}
                    label={item.label}
                    type={item.type}
                    validate={requiredValidator()}
                    customProps={item.customProps && item.customProps()}
                  />
                </TableCell>
              ))
              }
              <TableCell aria-label="Actions" className="form__cell">
                <ActionButtonsNewBankModal addNewRow={() => {}} deleteTransaction={() => {}}/>
              </TableCell>
            </TableRow>
            <TableRow className="form__row">
              <TableCell colSpan={2} aria-label="Balance" className="form__cell">Balance</TableCell>
              <TableCell colSpan={2} className="form__cell">0.00</TableCell>
              <TableCell className="form__cell">0.00</TableCell>
              <TableCell colSpan={2} className="form__cell">0.00</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <div className="container-button container-button--modal">
        <DButton typeOfButton="doneAllSecondary" onClickCustom={onClose}>Save</DButton>
        <DButton typeOfButton="close" onClickCustom={onClose}>Unsplit</DButton>
      </div>
    </Modal.Footer>
  </Modal>
};


SplitBankTransactionModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  currencies: PropTypes.object.isRequired,
  taxes: PropTypes.array,
  initValues: PropTypes.object,
};

export default SplitBankTransactionModal;
