import integrationCrud from '../../../../crud/integration.crud';

export const PLAID_ACCESS_TOKEN = 'plaid_access_token';

// export const getPlaidToken = () => {
//   return localStorage.getItem(PLAID_ACCESS_TOKEN);
// };
//
// export const setPlaidToken = () => {
//   return localStorage.getItem(PLAID_ACCESS_TOKEN);
// };


export const createPlaid = (setPlaidToken) => async () => {
  var products = 'transactions'.split(',');
  const handler = window.Plaid.create({
    apiVersion: 'v2',
    clientName: 'Doddled',
    env: process.env.REACT_APP_PLAID_ENV,
    key: process.env.REACT_APP_PLAID_PUBLIC_KEY,
    product: products,
    countryCodes: 'US,CA,GB,FR,ES'.split(','),
    // paymentToken: paymentToken,
    language: 'en',
    onSuccess: async function(public_token) {
      const data = await integrationCrud.getAccessToken({ public_token });
      if (data.data.access_token) {
        setPlaidToken(data.data.access_token);
      } else {
        alert('Try later');
      }
    },
  });
  handler.open();
};

