import React, {useEffect} from "react";
import Wrapper from "../../components/Wrapper";
import FirstVisit from "./FirstVisit";
import PPPContent from "./PPPContent";
import useSettings from "../../../hooks/settings.hook";
import useUsers from "../../../hooks/users.hook";
import DSplashScreen from "../../components/SplashScreen/DSplashScreen";
// SKUify is currently importing your Unit Cost from an PPP - please check back later

const ProfitProtectorPro = () => {
  const {user} = useUsers({fetch: false});
  const {getAccountSettings, accountSettings, accountLoading} = useSettings({fetch: false});

  useEffect( () => {
    getAccountSettings({ id: user.id })
  }, []);

  return (
      <Wrapper className="ppp-page__wrap" elevation={0}>
      <div className="ppp-page page__table__wrap kt-portlet">
        <div className="d-splash-screen--wrap">
          {accountLoading && <DSplashScreen/>}
          {accountSettings?.ppp_api_key
            ? <PPPContent />
            : (
              <Wrapper className="ppp-page__first-visit" elevation={0}>
                <FirstVisit />
              </Wrapper>
            )
        }
        </div>
    </div>
    </Wrapper>
  )
};

export default ProfitProtectorPro;
