import React, { useState } from "react";
import "./style-transactions.scss"
import EnterTransactionsBanking from "./EnterTransactionsBanking/EnterTransactionsBanking";
import EnterTransactionsCustomers from "./EnterTransactionsCustomers/EnterTransactionsCustomers";
import EnterTransactionsSuppliers from "./EnterTransactionsSuppliers/EnterTransactionsSuppliers";
import { headRows as bankingItems } from "./EnterTransactionsBanking/banking"
import { headRows as customersItems } from "./EnterTransactionsCustomers/customers"
import { headRows as suppliersItems } from "./EnterTransactionsSuppliers/suppliers"
import withHelmet from "../../../hocs/withHelmet";
import { useCheckboxStorage, usePerPage } from "../../../hooks/modal.hook";
import Wrapper from "../../components/Wrapper";
import useCompany from "../../../hooks/company.hook";
import useTab from "../../../hooks/tab.hook";
import DTabs from "../../components/DTabs/DTabs";
import { filterItems } from "../utils";

const title = "Enter Transactions";

function EnterTransactions() {
  const [currentTab, setTab] = useTab('banking');
  const [customersTabColumns, setCustomersTabColumns] = useCheckboxStorage("customers_tab", customersItems);
  const [bankingTabColumns, setBankingTabColumns] = useCheckboxStorage("banking_tab", bankingItems);
  const [suppliersTabColumns, setSuppliersTabColumns] = useCheckboxStorage("suppliers_tab", suppliersItems);
  const [filterCheckboxes, setFilterCheckboxes] = useCheckboxStorage("categories_filter", filterItems);
  const [rowsPerPage, onPerPage] = usePerPage();
  const [reload, setReload] = useState(false);
  const [searchQuery, setSearchQuery] = useState(null);
  const { company } = useCompany();

  const items = (tabData) => {
    const active = filterCheckboxes.find(checkbox => checkbox.id === "inactive");
    const balance = filterCheckboxes.find(checkbox => checkbox.id === "balance");
    if (active.isActive === false && balance.isActive === false) {
      return tabData.filter(item => item.account_currency_balance > 0 && item.inactive === false);
    }
    if (active.isActive === false) {
      return tabData.filter(item => item.inactive === false);
    }
    if (balance.isActive === false) {
      return tabData.filter(item => item.account_currency_balance > 0);
    } else {
      return tabData;
    }
  };

  const onRefresh = () => setReload((reload) => !reload);
  const onExport = () => console.log("onExport");

  let tabColumns;
  let setTabColumns;
  let filterData = items;
  switch (currentTab) {
    case "customers":
      tabColumns = customersTabColumns;
      setTabColumns = setCustomersTabColumns;
      break;
    case "banking":
      tabColumns = bankingTabColumns;
      setTabColumns = setBankingTabColumns;
      filterData = undefined;
      break;
    case "suppliers":
      tabColumns = suppliersTabColumns;
      setTabColumns = setSuppliersTabColumns;
      break;
  }

  const actionsHeader = {
    onRefresh,
    onExport,
    selectMenu: { setFunc: onPerPage, type: "select" },
    viewColumnMenu: { array: tabColumns, type: "filter", setFunc: setTabColumns, disabled: true },
    filterMenu: currentTab !== "banking"
      ? { array: filterCheckboxes, setFunc: setFilterCheckboxes, type: "filter" }
      : null,
  };
  const toolbarProps = { title, setSearchQuery, actionsHeader };
  const tabProps = {
    headRows: tabColumns.filter(col => col.isActive),
    rowsPerPage,
    reload,
    items: filterData,
    searchQuery,
    currentTab
  };
  const isHide = (name) => company && !company[`${name}_credit`];
  const tabsData = [
    {
      value: "customers",
      label: "Customers",
      hideTab: isHide("customer"),
      content: <EnterTransactionsCustomers tabProps={tabProps} />
    },
    { value: "banking", label: "Banking", content: <EnterTransactionsBanking tabProps={tabProps} /> },
    {
      value: "suppliers",
      label: "Suppliers",
      hideTab: isHide("supplier"),
      content: <EnterTransactionsSuppliers tabProps={tabProps} />,
    }
  ];

  const onChangeTab = (event, newValue) => {
     setTab(event, newValue);
  };

  return (
    <div className="wrap-component-transaction">
      <Wrapper>
        <DTabs
          toolbarProps={toolbarProps}
          tabsData={tabsData}
          value={currentTab}
          onChange={onChangeTab}
          className="enter-transactions"
        />
      </Wrapper>
    </div>
  )
}

export default withHelmet(EnterTransactions, { title: 'Enter Transaction' });