import React, { useEffect, useState } from "react";
import TableComponent from "../../../../components/TableComponent/TableComponent";
import { connect } from "react-redux";
import TableToolbarComponent from "../../../../components/TableComponent/TableToolbarComponent";
import BottomHeaderFilterForm from "./BottomHeaderFilterForm";
import * as Selectors from "../../../../../selectors/transactions.selectors";
import DButton from "../../../../components/Button";
import withConfirmHook from "../../../../../hooks/withConfirm.hook";
import { useCheckboxStorage, useModal, useParseHistory, usePerPage } from "../../../../../hooks/modal.hook";
import * as PropTypes from "prop-types";
import Wrapper from "../../../../components/Wrapper";
import { getCurrentCompanyId } from "../../../../../crud/utils";
import { getLinkTransaction, goToBulkEditorPage, goToEditTransactionPage } from "../../utils";
import { useHistory } from "react-router-dom";
import ReceiptUploadsModal from "../../../../components/ReceiptUploadsModal/ReceiptUploadsModal";
import { headRows } from "./utils";
import * as transactionsDuck from "../../../../../store/ducks/transactions.duck";
import useCompany from "../../../../../hooks/company.hook";
import { copy } from "../../../utils";

function TransactionList(props) {
  const {
    getTransactions,
    transactions,
    deleteTransaction,
    confirm,
    deleteTransactions
  } = props;
  const [currentRow, setCurrentRow] = useState(null);
  const [transactionItems, setTransactionItems] = useState([]);
  const [isFilterFormShow, setIsFilterFormShow] = useState(false);
  const [isReceiptUploadsModalShow, toggleReceiptUploadsModal] = useModal();
  const [activeColumns, setActiveColumns] = useCheckboxStorage("transactionList", headRows);
  const [rowsPerPage, onPerPage] = usePerPage();
  const [searchQuery, setSearchQuery] = useState(null);

  const bankRuleId = useParseHistory().bank_rule_id;

  //uncomment when will bank rule transactions
  // const nameBankRule = bankRuleId
  // && transactions.find(transaction => transaction.bank_rule.id === +bankRuleId).bank_rule.name;

  // use `Transactions automatically confirmed by ${nameBankRule}` when will bank rule transactions
  const title = bankRuleId ? `Transactions automatically confirmed ` : "Transactions";

  useEffect(() => {
    getTransactions({ companyId: getCurrentCompanyId(), q: searchQuery })
  }, [searchQuery]);
  const {company} = useCompany();

  let history = useHistory();

  const onRefresh = () => getTransactions({ companyId: getCurrentCompanyId() });
  const onFilterForm = () => setIsFilterFormShow(!isFilterFormShow);
  const onTransactionItemsSelected = (items) => setTransactionItems(items);
  const onEdit = (row) => () => goToEditTransactionPage(history, row.id, row.bank_account.id);
  const onAttachments = (row) => () => {
    toggleReceiptUploadsModal();
    setCurrentRow(row);
  };

  const onLink = (row) => () => copy(getLinkTransaction(row));

  const onDelete = (row) => (
    confirm(() => {
        deleteTransaction({
          idCompany: row.company.id,
          idBank: row.bank_account.id,
          id: row.id
        });
      },
      {description: 'This action is permanent!'}
    )
  );

  const onDeleteMultiple = () =>
    confirm(() => {
        deleteTransactions({
          idCompany: getCurrentCompanyId(),
          ids: transactionItems,
        })
      },
      { description: 'This action is permanent!' }
    );

  const onBulkEditor = () => goToBulkEditorPage(history);

  // TODO check transactions filter
  const bankRuleTransactions = bankRuleId && transactions.filter(transaction =>
    transaction.bank_rule && transaction.bank_rule.id === +bankRuleId);

  let itemsTable = bankRuleId ? bankRuleTransactions : transactions;
  const onExport = () => console.log("onExport");
  const filterForm = (isFilterFormShow ? <BottomHeaderFilterForm title={title} /> : null);

  return <div className="trans-list">
    <Wrapper>
      <TableToolbarComponent
        title={title}
        bottomHeader={filterForm}
        setSearchQuery={setSearchQuery}
        actionsHeader={{
          onRefresh,
          onFilter: onFilterForm,
          onExport,
          selectMenu: { setFunc: onPerPage, type: "select" },
          viewColumnMenu: { array: activeColumns, type: "filter", setFunc: setActiveColumns, disabled: true },
        }}
      />
      <TableComponent
        headRows={activeColumns.filter(column => column.isActive)}
        items={itemsTable}
        title={title}
        onSelectedItems={onTransactionItemsSelected}
        isCheckbox={true}
        rowsPerPageSelected={rowsPerPage}
        rowProps={{
          onEdit,
          onAttachments: (company && company.uses_receipt_upload) && onAttachments,
          onLink,
          onDelete,
        }}
      />
      <div className="container-button">
        <DButton typeOfButton="edit" onClickCustom={onBulkEditor} disabled={transactionItems.length === 0}>
          Edit Checked
        </DButton>
        <DButton typeOfButton="delete" disabled={transactionItems.length === 0} onClickCustom={onDeleteMultiple()}>
          Delete Checked
        </DButton>
      </div>
    </Wrapper>

    {currentRow && <ReceiptUploadsModal show={isReceiptUploadsModalShow} onClose={toggleReceiptUploadsModal} />}
  </div>
}

TransactionList.propTypes = {
  getTransactions: PropTypes.func.isRequired,
  deleteTransaction: PropTypes.func.isRequired,
  deleteTransactions: PropTypes.func.isRequired,
  updateTransaction: PropTypes.func.isRequired,
  transactions: PropTypes.array,
  confirm: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    transactions: Selectors.selectTransactions(state),
    loading: Selectors.selectLoading(state),
  }
};

const mapDispatchToProps = {
  getTransactions: transactionsDuck.actions.getTransactions,
  deleteTransaction: transactionsDuck.actions.deleteTransaction,
  updateTransaction: transactionsDuck.actions.updateTransaction,
  deleteTransactions: transactionsDuck.actions.deleteTransactions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withConfirmHook(TransactionList))