import React from 'react'
import {toAbsoluteUrl} from "../../../../../../_metronic";

const  list = [
  {title: 'Title Changes'},
  {title: 'Inventory Age'},
  {title: 'Long Term Storage Fees'},
  {title: 'Sales and Profit'},
  {title: 'Marketplace Sales Breakdown'},
]


function Communication() {
  return (
    <>
      <section className="row_am communication_section">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="communication_text" data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100">
              <span className="icon"><img src={toAbsoluteUrl("media/codely-theme-files/assets/images/new/comunication.png")} alt="image"/></span>
              <div className="section_title">
                <h2>ASIN Overview</h2>
                <p>This tells you tonnes of useful information about your inventory on an ASIN level. How many are available, inbound, reserved, unfulfillable, reimbursed, title changes, average buy price and sell price and much more. Invaluable!.</p>
              </div>
              <ul>
                {
                  list.map(({title}, index) => {
                    return (
                      <li key={index} data-aos="fade-up" data-aos-duration="2000">
                        <h3>{title}</h3>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="communication_image" data-aos="fade-in" data-aos-duration="1000">
              <img data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100" className=""
                   src={toAbsoluteUrl("media/codely-theme-files/assets/images/skuify/banner-3.png")} alt="image"/>
            </div>
          </div>
        </div>
      </div>
        <div id="features-section-anchor" />
    </section>
    </>
  )
}

export default Communication
