import createSagaMiddleware from 'redux-saga'
import { persistStore } from 'redux-persist'
import { configureStore } from '@reduxjs/toolkit'
import { rootReducer, rootSaga } from './rootDuck'
import Reactotron from '../../ReactotronConfig'

let sagaMiddlewareSettings = {}

if (process.env.NODE_ENV !== 'production') {
  sagaMiddlewareSettings = { sagaMonitor: Reactotron.createSagaMonitor() }
}

const sagaMiddleware = createSagaMiddleware(sagaMiddlewareSettings)
let storeSettings = {
    reducer: rootReducer,
    middleware: [sagaMiddleware],
}

if (process.env.NODE_ENV !== 'production') {
  storeSettings.enhancers = [Reactotron.createEnhancer()]
  const sagaMonitor = Reactotron.createSagaMonitor()
  sagaMiddlewareSettings = { sagaMonitor }
}

const store = configureStore(storeSettings)
/**
 * @see https://github.com/rt2zz/redux-persist#persiststorestore-config-callback
 * @see https://github.com/rt2zz/redux-persist#persistor-object
 */
export const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)

// only for dev
window.store = store
export default store
