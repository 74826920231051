import api from "../../crud/chart.crud.js";
import { call, put, takeLatest } from "redux-saga/effects";

import { createSlice } from "@reduxjs/toolkit";
import { periodName } from "../../pages/home/Products/AsinOverview/asinIntarfaces";

const initialState = {
  params: {
    period: "all",
    start_date: null,
    end_date: null,
    marketplace: null,
    page_name: null,
    offset: 0,
    salesOnly: false,
    table: null
  },
  details: null,
  loading: false,
  fetched: false,
  chart: {
    by_marketplaces: [],
    chart_data: {},
    payouts: null,
    units_sold: null,
    profit: null,
    sales: null,
    currency: null,
    margin: null,
    margin_compared: null,
    profit_compared: null,
    refunds: null,
    refunds_compared: null,
    roi: null,
    roi_compared: null,
    sales_compared: null,
    units_sold_compared: null
  },
  orders: {
    results: [],
    count: 0
  },
  refunds: {
    results: [],
    count: 0
  },
  ordersRefunds: {
    orders: {
      results: [],
      count: 0
    },
    refunds: {
      results: [],
      count: 0
    },
    loading: false
  },
  orderNotification: {
    notification: {}
  }
};

const common = createSlice({
  name: "chart",
  initialState: initialState,
  reducers: {
    reset: state => {
      state = initialState;
      return state;
    },
    getChart: (state, actions) => {
      const { autoRefresh } = actions.payload;
      state.loading = !autoRefresh;
      return state;
    },
    getChartSuccess: (state, actions) => {
      const { data, product_id, names } = actions.payload;
      state.loading = false;
      state.fetched = true;
      if (data) {
        if (data.chart) {
          // that is hack to fix issue when we go from sales to dash page
          if (product_id) {
            // state.chart = data.chart
            const { chart_data, by_marketplaces, ...profitValues } = data.chart;
            state.chart = {
              ...state.chart,
              ...(names.includes(periodName.sales_profit) && { chart_data }),
              ...(names.includes(periodName.sales) && { by_marketplaces }),
              ...(names.includes(periodName.profit) && { ...profitValues })
            };
          } else {
            state.chart = data.chart;
          }
        }

        if (data?.inventory) {
          state.chart = {
            ...state.chart,
            chart_data: {
              ...state.chart.chart_data,
              inventory_cost: data.inventory.chart_data.inventory_cost,
              inventory_cost_labels: data.inventory.chart_data.labels
            },
            inventory_cost: data.inventory.inventory_cost,
            inventory_cost_compared: data.inventory.inventory_cost_compared,
            inventory_cost_units: data.inventory.inventory_cost_units,
            inventory_cost_warning: false,
            inventoryCostMarketplaces: data.inventory.by_marketplaces,
          }
        }

        state.orders = data.orders;
        state.refunds = data.refunds;
        state.haveInvalidProfit = data.have_invalid_profit;
        state.haveInvalidSales = data.have_invalid_sales;
      }

      return state;
    },
    getChartError: (state, actions) => {
      state.loading = false;
      return state;
    },
    getSales: (state, actions) => {
      state.loading = true;
      state.params = actions.payload;
      return state;
    },
    getSalesSuccess: (state, actions) => {
      const {
        data: { orders, refunds }
      } = actions.payload;
      state.loading = false;
      state.fetched = true;

      if (orders) state.orders = orders;
      if (refunds) state.refunds = refunds;
      return state;
    },
    getSalesError: (state, actions) => {
      state.loading = false;
      return state;
    },

    getOrders: (state, actions) => {
      const { autoRefresh } = actions.payload;
      state.ordersRefunds.loading = !autoRefresh && true;
      return state;
    },

    getOrdersSuccess: (state, actions) => {
      const { data } = actions.payload;
      state.ordersRefunds.orders = data.orders;
      state.ordersRefunds.refunds = data.refunds;
      state.ordersRefunds.loading = false;
      state.fetched = true;
      return state;
    },
    getOrdersError: (state, actions) => {
      state.ordersRefunds.loading = false;
      return state;
    },

    updateOrderCOG: (state, actions) => {
      const { pageName } = actions.payload;
      if (pageName === "dash") {
        state.ordersRefunds.loading = true;
      } else {
        state.loading = true;
      }
      return state;
    },

    updateOrderCOGSuccess: (state, actions) => {
      const { id, order, pageName, table } = actions.payload;
      if (pageName === "dash") {
        state.ordersRefunds[table].results = state.ordersRefunds[
          table
        ].results.map(item => (item.id === id ? order : item));
        state.ordersRefunds.loading = false;
      } else {
        state[table].results = state[table].results.map(item =>
          item.id === id ? order : item
        );
        state.loading = false;
      }
      state.fetched = true;
      return state;
    },
    updateOrderCOGError: (state, actions) => {
      const { pageName } = actions.payload;
      if (pageName === "dash") {
        state.ordersRefunds.loading = true;
      } else {
        state.loading = true;
      }
      return state;
    },

    updateOrderNote: (state, actions) => {
      // state.orderNote.loading = true;
      return state;
    },
    updateOrderNoteSuccess: (state, actions) => {
      const { id, values } = actions.payload;
      // we don't know is note was updated on refund or order tab, so have to check both
      // orders and refunds for dash
      [
        ...state.ordersRefunds.orders.results,
        ...state.ordersRefunds.refunds.results
      ].forEach(order => {
        if (order.id === id) {
          order.note = values.note;
        }
      });
      // orders and refunds for sales
      [...state.orders.results, ...state.refunds.results].forEach(order => {
        if (order.id === id) {
          order.note = values.note;
        }
      });
      return state;
    },
    getOrderNotification: (state, actions) => {
      return state;
    },
    // getReportNotification: (state, actions) => {
    //   return state
    // },

    getOrderNotificationSuccess: (state, actions) => {
      const { data } = actions.payload;
      if (state.orderNotification.notification !== data) {
        state.orderNotification.notification = data;
      }
      return state;
    },

    deleteOrderNotification: (state, actions) => {
      state.orderNotification.notification = {};
      return state;
    }
  }
});

export const actions = common.actions;
export const reducer = common.reducer;

export function* saga() {
  yield takeLatest(actions.getChart, function* getChartSaga({ payload = {} }) {
    try {
      const { autoRefresh, names, ...params } = payload;
      const { data } = yield call(api.getChart, params);
      if (data) {
        yield put(actions.getChartSuccess({ data, ...payload }));
      } else {
        yield put(actions.getChartError());
      }
    } catch (err) {
      yield put(actions.getChartError());
      console.log(err);
    }
  });
  yield takeLatest(actions.getSales, function* getSalesSaga({ payload = {} }) {
    try {
      const { ...params } = payload;
      const { data } = yield call(api.getSales, params);
      if (data) {
        yield put(actions.getSalesSuccess({ data }));
      } else {
        yield put(actions.getSalesError());
      }
    } catch (err) {
      yield put(actions.getSalesError());
      console.log(err);
    }
  });
  yield takeLatest(actions.getOrders, function* getOrdersSaga({
    payload: { autoRefresh }
  }) {
    try {
      const { data } = yield call(api.getOrders);
      if (data) {
        yield put(actions.getOrdersSuccess({ data, autoRefresh }));
      } else {
        yield put(actions.getOrdersError());
      }
    } catch (err) {
      yield put(actions.getOrdersError());
      console.log(err);
    }
  });

  yield takeLatest(actions.updateOrderNote, function* updateOrderNoteSaga({
    payload: { id, values, onDone, onError }
  }) {
    try {
      const { data } = yield call(api.updateOrderNote, id, values);
      if (data) {
        yield put(
          actions.updateOrderNoteSuccess({ orderNote: data, id, values })
        );
        onDone();
      }
    } catch (err) {
      onError(err);
      console.log(err);
    }
  });

  yield takeLatest(actions.updateOrderCOG, function* updateOrderCOGSaga({
    payload: { id, pageName, table, values, onDone, onError }
  }) {
    try {
      const { data } = yield call(api.updateOrderCOG, id, values);
      if (data) {
        yield put(
          actions.updateOrderCOGSuccess({ order: data, id, pageName, table })
        );
        onDone();
      } else {
        yield put(actions.updateOrderCOGError({ pageName }));
      }
    } catch (err) {
      yield put(actions.updateOrderCOGError({ pageName }));
      onError(err);
      console.log(err);
    }
  });

  yield takeLatest(
    actions.getOrderNotification,
    function* getOrderNotificationSaga({ payload: { interval } }) {
      try {
        const { data } = yield call(api.getOrderNotification, interval);
        if (data) {
          yield put(actions.getOrderNotificationSuccess({ data }));
        }
      } catch (err) {
        console.log(err);
      }
    }
  );
}
