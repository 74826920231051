import React from "react";
import classNames from "clsx";
import { Typography } from "@material-ui/core";
import VATSettingsContent from "./VAT/VATSettingsContent";

// export const tooltipWL = "On the Dash page you have the option to see Sales that were a Winner" +
//   " (i.e. made you a profit) and Sales that were a Loser (i.e lost you money). " +
//   "The default is set to anything in profit as a Winner and anything that loses you money as a Loser." +
//   " You may adjust those figures here if you wish to personalize the feature.";

export const TableTooltip = ({ name, text1, text2 }) => {
  return (
    <div className={classNames("wrap ", {"wrap--min" : !text2})}>
      <div className="name">{name}</div>
      <div className="text1">{text1}</div>
      {text2 && <>
        <div className="text2">{text2}</div>
        <div className="number">X 100</div>
      </>}
    </div>
  )
};


export const optionHeaderVAT = [
  { id: "vat_exempt_on_amazon", className: "vat-exempt", label: "NON VAT registered and VAT exempt on your Amazon Fees" },
  { id: "is_vat_registered", className: "vat-registered", label: "Registered for VAT" },
  { id: "vat_number", className: "vat-reg-number", label: "Registration number" },
  { id: "registration_date", className: "registration-date", label: "Star date" },
  { id: "deregistration_date", className: "deregistration-date", label: "End date" },
  { id: "vat_info", className: "vat-info", label: "" },
];

export const optionHeaderSchemes = [
  { id: "scheme_name", className: "vat", label: "VAT scheme" },
  { id: "scheme_registration_date", className: "date", label: "Date you registered" },
  { id: "flat_rate_percentage", className: "flat-rate", label: "Percentage rate" },
  { id: "report_vat", className: "report", label: "Report your VAT" },
];

const typeOptions = {
 vat: optionHeaderVAT,
 schemes: optionHeaderSchemes,
};

export const Headers = ({ type }) => (
  <div className="forms__headers forms__headers--hide">
    <div className="forms__headers__field-header">
      {typeOptions[type].map(({ className, label }, index) => {
        return (
          <Typography key={index} variant="h6" className={classNames(`forms__headers--scheme-${className}`)}>{label}</Typography>
        )
      })}
      </div>
  </div>
);

export const tabs = ({ tabOptions, type, ...props }) =>
  Object.entries(tabOptions).map(([key, name]) => (
    {
      value: key,
      label: name,
      content: (
        <VATSettingsContent
          header={<Headers type={type} />}
          type={type}
          {...props}
        />
      )
    }
  ));

export const TabsLabel = ({ name, icon }) => (
  <span className="tab-icon-or-text">
    <span className="tab-text">{name}</span>
    {/*{icon({ className: "tab-icon" })}*/}
  </span>
);