import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import classNames from 'clsx';

const Spinner = ({ className, size, style }) => {
  return (
    <div className={classNames('d-spinner', className)} style={style} >
      <CircularProgress className={`${className}-component`} size={size} />
    </div>
  )
};

export default Spinner;