import React, { useState } from 'react';
import TableCell from "@material-ui/core/TableCell";
import TableToolbarComponent from "../../components/TableComponent/TableToolbarComponent";
import Wrapper from "../../components/Wrapper";
import withConfirmHook from "../../../hooks/withConfirm.hook";
import useCategories from "../../../hooks/categories.hook";
import BottomHeaderCategories from "../Category/BottomHeaderCategories";
import { useHistory } from "react-router-dom";
import { getReportsPageName } from "./utils";
import TransactionModal from "../Transaction/TransactionModal/TransactionModal";
import ReportsTable from "./ReportsTable";
import { useModal } from "../../../hooks/modal.hook";
import useSuppliersCustomers from "../../../hooks/suppliersCustomers.hook";

const headRows = (table) => {
  return [
    {id: 'nominal_code', numeric: false, disablePadding: true, label: 'CC',},
    {
      id: 'name', numeric: false, disablePadding: true, label: 'Category',
      render: (row, {onNameClick}) => {
        return <TableCell align="left" key={`${row.id}--name`} onClick={onNameClick(row)} className="table__cell--link">
          <span className="table__cell--link__text">{row.name}</span>
        </TableCell>
      }
    },
    ...table.id === "trial_balance"
      ? [{id: 'debit', numeric: false, disablePadding: true, label: 'Debit',},
        {id: 'credit', numeric: false, disablePadding: true, label: 'Credit',}]
      : [{id: table.id, numeric: false, disablePadding: true, label: table.name,}],
  ]
};

export const periodOfTime = (start, end) => {
  if (start && end) {
    return `Amounts from ${start} - ${end}`
  } else if (start) {
    return `Amounts from ${start}`
  } else if (end) {
    return `Amounts up to ${end}`
  } else {
    return "Amounts from all time"
  }
};

const ReportsGeneral = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [reportDate, setReportDate] = useState(null);
  const [errorDate, setErrorDate] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [isTransactionModalShow, toggleTransactionModal] = useModal();

  const { getCategories } = useCategories();
  const { customers } = useSuppliersCustomers({ customers: true });
  const history = useHistory();

  const onNameClick = (row) => () => {
    toggleTransactionModal();
    setCurrentRow(row);
  };

  const onCloseTransactionModal = () => {
    toggleTransactionModal();
    setCurrentRow(null);
  };

  const getStartDate = (name, date) => setStartDate(date);
  const getEndDate = (name, date) => setEndDate(date);
  const getReportDate = (name, date) => setReportDate(date);
  const onRefresh = () => {
    if (startDate && endDate && startDate > endDate) {
      setErrorDate(true);
    } else {
      setErrorDate(false);
      getCategories({ start_data: startDate, end_data: endDate });
    }
  };

  const onReportDate = () =>{
    getCategories({ report_date: reportDate })
  };

  let fieldsDate;
  fieldsDate = [
    {
      id: "date_start",
      label: "Start Date",
      function: getStartDate,
      value: startDate,
    },
    {
      id: "date_end",
      label: "End Date",
      function: getEndDate,
      value: endDate,
    },
  ];
  let title;
  let tables;
  let onDateFilter = onRefresh;
  switch (getReportsPageName(history.location.pathname)) {
    case "profit and loss":
      title = "Profit & Loss Report";
      tables = [
        { id: "sales", name: "Sales" },
        { id: "direct_costs", name: "Direct Costs" },
        { id: "less_expenses", name: "Less Expenses", data: customers }
      ];
      break;
    case "balance sheet":
      title = "Balance Sheet Report";
      tables = [
        { id: "assets", name: "Assets" },
        { id: "liabilities", name: "Liabilities" },
        { id: "capital", name: "Capital" }
      ];
      fieldsDate =[
        {
          id: "report_date",
          label: "Report Date",
          function: getReportDate,
          value: reportDate,
        },
      ];
      onDateFilter = onReportDate;
      break;
    case "trial balance":
      title = "Trial Balance Report";
      tables = [{ id: "trial_balance", name: "",}];
      break;
    default:
      return "";
  }
  const onExport = () => console.log("onExport");
  const onExportPDF = () => console.log("onExportPDF");

  const datePicker =
    <BottomHeaderCategories
      className="reports__date-picker"
      fieldsDate={fieldsDate}
      onDateFilter={onDateFilter}
      getStartDate={getStartDate}
      getEndDate={getEndDate}
      startDate={startDate}
      endDate={endDate}
      errorDate={errorDate}
    />;

  return (
    <Wrapper>
      <TableToolbarComponent
        title={title}
        datePicker={datePicker}
        isHideSearch
        actionsHeader={{onExport, onExportPDF}}
      />
      {tables.map(table => {
        return <ReportsTable
          key={table.id}
          headRows={headRows(table)}
          onNameClick={onNameClick}
          data={table}
        />
      })}
      {currentRow &&
      <TransactionModal
        data={currentRow}
        show={isTransactionModalShow}
        onClose={onCloseTransactionModal}
        periodOfTime={periodOfTime(startDate, endDate)}
      />}
    </Wrapper>
  );
};

export default withConfirmHook(ReportsGeneral);