import {
  call,
  put,
  takeLatest,
  all,
} from 'redux-saga/effects'
import {createSlice} from "@reduxjs/toolkit";
import * as OfficersDuck from './officersSettings.duck';
import * as UsersSettingsDuck from './user.duck';
import * as GroupsSettingsDuck from './group.duck';
import companyApi from '../../crud/company.crud';
import { combineReducers } from "redux";

const company = createSlice({
    name: 'company',
    initialState: {
      company: undefined,
      loading: true,
      officers: [],
      companies: [],
    },
    reducers: {
      stopLoading: (state, actions) => {
        state.loading = false;
        return state;
      },
      getCompanies: (state, actions) => {
        state.loading = true;
        return state;
      },
      getCompaniesSuccess: (state, actions) => {
        const { companies } = actions.payload;
        state.companies = companies;
        state.loading = false;
        return state;
      },
      getCompany: (state, actions) => {
        state.loading = true;
        return state;
      },
      getCompanySuccess: (state, actions) => {
        const {company} = actions.payload;
        state.company = company;
        state.loading = false;
        return state;
      },
      updateCompany: (state, actions) => {
        state.loading = true;
        return state;
      },
      updateCompanySuccess: (state, actions) => {
        const {company} = actions.payload;
        state.company = company;
        state.loading = false;
        return state;
      },
      deleteCompany: (state, actions) => {
        state.loading = true;
        return state;
      },
      deleteCompanySuccess: (state, actions) => {
        const {id} = actions.payload;
        state.companies = state.companies.filter((company) => (company.id !== id));
        state.loading = false;
        return state;
      },
      getVATSchemes: (state, actions) => {
        state.loading = true;
        return state;
      },
      getVATSchemesSuccess: (state, actions) => {
        const { VATSchemes } = actions.payload;
        state.VATSchemes = VATSchemes;
        state.loading = false;
        return state;
      },
      addVATScheme: (state, actions) => {
        state.loading = true;
        return state;
      },
      addVATSchemeSuccess: (state, actions) => {
        const {VATScheme} = actions.payload;
        state.VATSchemes = [...state.VATSchemes, VATScheme];
        state.loading = false;
        return state;
      },
      updateVATScheme: (state, actions) => {
        state.loading = true;
        return state;
      },
      updateVATSchemeSuccess: (state, actions) => {
        const {VATScheme} = actions.payload;
        state.VATScheme = VATScheme;
        state.loading = false;
        return state;
      },
      deleteVATScheme: (state, actions) => {
        state.loading = true;
        return state;
      },
      deleteVATSchemeSuccess: (state, actions) => {
        const {id} = actions.payload;
        state.VATSchemes = state.VATSchemes.filter((VATScheme) => (VATScheme.id !== id));
        state.loading = false;
        return state;
      },
    },
  }
);

export const actions = company.actions;

export const reducer = combineReducers({
  company: company.reducer,
  officers: OfficersDuck.reducer,
  users: UsersSettingsDuck.reducer,
  groups: GroupsSettingsDuck.reducer,
});

export function* saga() {
  yield takeLatest(actions.getCompanies, function* getCompaniesSaga() {
    try {
      const { data } = yield call(companyApi.all);
      data
        ? yield put(actions.getCompaniesSuccess({ companies: data }))
        : yield put(actions.stopLoading());
    } catch (err) {
      yield put(actions.stopLoading());
      console.error(err);
    }
  });

  yield takeLatest(actions.getCompany, function* getCompanySaga({ payload: { id } }) {
    try {
      // use company id
      const { data } = yield call(companyApi.getUserCompany, id);
      data
        ? yield put(actions.getCompanySuccess({ company: data }))
        : yield put(actions.stopLoading());
    } catch (err) {
      yield put(actions.stopLoading());
      console.error(err);
    }
  });

  yield takeLatest(actions.updateCompany, function* updateCompanySaga({ payload: { id, values, onDone } }) {
    try {
      const { data } = yield call(companyApi.updateCompany, id, values);
      if (data) {
        yield put(actions.updateCompanySuccess({ company: data }));
        onDone();
      }
      yield put(actions.stopLoading());
    } catch (err) {
      yield put(actions.stopLoading());
      console.log(err);
    }
  });

  yield takeLatest(actions.deleteCompany, function* deleteCompanySaga({ payload: { id, onDone } }) {
    try {
      yield call(companyApi.deleteCompany, id);
      yield put(actions.deleteCompanySuccess({ id }));
      if (onDone)
        onDone();
      yield put(actions.stopLoading())
    } catch (err) {
      yield put(actions.stopLoading())
      console.log(err);
    }

  });

  yield takeLatest(actions.getVATSchemes, function* getVATSchemesSaga() {
    try {
      const { data } = yield call(companyApi.getVATSchemes);
      data
        ? yield put(actions.getVATSchemesSuccess({ VATSchemes: data }))
        : yield put(actions.stopLoading());
    } catch (err) {
      yield put(actions.stopLoading());
      console.log(err);
    }
  });

  yield takeLatest(actions.addVATScheme, function* addVATSchemeSaga({ payload: { values, onDone } }) {
    try {
      const { data } = yield call(companyApi.addVATScheme, values);
      if (data) {
        yield put(actions.addVATSchemeSuccess({ VATScheme: data }));
        onDone();
      }
      yield put(actions.stopLoading());
    } catch (err) {
      yield put(actions.stopLoading());
      console.log(err);
    }
  });

  yield takeLatest(actions.updateVATScheme, function* updateVATSchemeSaga({ payload: { id, values, onDone } }) {
    try {
      const { data } = yield call(companyApi.updateVATScheme, id, values);
      if (data) {
        yield put(actions.updateVATSchemeSuccess({ VATScheme: data }));
        onDone();
      }
      yield put(actions.stopLoading());
    } catch (err) {
      yield put(actions.stopLoading());
      console.log(err);
    }
  });

  yield takeLatest(actions.deleteVATScheme, function* deleteVATSchemeSaga({ payload: { id, onDone } }) {
    try {
      yield call(companyApi.deleteVATScheme, id);
      yield put(actions.deleteVATSchemeSuccess({ id }));
      if (onDone)
        onDone();
      yield put(actions.stopLoading());
    } catch (err) {
      yield put(actions.stopLoading());
      console.log(err);
    }
  });

  yield all([
    UsersSettingsDuck.saga(),
    GroupsSettingsDuck.saga(),
    OfficersDuck.saga(),
  ]);
}

