import React from "react";
import {IconButton} from "@material-ui/core";
import { floatNumber } from "../../utils";
import toast from "../../../components/toast/toast";
import { requiredValidator } from "../../../components/controls/validators";
import {ReactComponent as RemoveIcon} from "../../../../media/icons/remove-without-border.svg";
import {ReactComponent as AddIcon} from "../../../../media/icons/add-without-border.svg";

export const types = {
  "fbm": "mf_plan",
  "sfp": "sfp_plan",
};

export const init = {
  id: undefined,
  name: undefined,
  description: undefined,
  height: "",
  length: "",
  width: "",
  weight: "",
  amount: "",
};

export const testOptions = [
  "name option 1",
  "name option 2",
  "name option 3",
];

export const interfaceArray = (currency, dataName, options) => [
  {
    id: "name",
    label: "Name",
    type: "autocomplete",
    validator: requiredValidator('Name is required'),
    customProps: { options: options }
  },
  ...dataName === "sfp"
  ? [{ id: "description", label: "Description", type: "text" },]
  :[{ id: "height", label: "Height(cm)", type: "RNumber" },
    { id: "length", label: "Length(cm)", type: "RNumber" },
    { id: "width", label: "Width(cm)", type: "RNumber" },
    { id: "weight", label: "Weight(kg)", type: "RNumber" }],
  { id: "amount", label: `Amount(${currency})`, type: "RNumber" },
  {
    id: "actions",
    label: "Add/Delete",
    render: props => {
      const {extraProps, values} = props;
      const {index, arrayHelpers, onAction, lastIndex} = extraProps;
      const value = values[dataName]?.[index];
      return  <div className="forms__line__action">
        <IconButton onClick={onAction({ arrayHelpers, index, values: value, lastIndex })}>
          {index === lastIndex ? <AddIcon /> : <RemoveIcon />}
        </IconButton>
      </div>
    }
  },
];

export const onSubmit = (props) => (values, { setSubmitting, resetForm }) => {
  const { setIsShowNotification, updateShippingPlans, currentTab, shippingPlans } = props;
  const data = values[currentTab];
  const formatData = data.map(({ id, name, description, ...line }) => {
    const update = Object.fromEntries(Object.entries(line).map(([key, val]) => [key, floatNumber(val)]));
    return currentTab === "sfp" ? { id, name, description, ...update } : { id, name, ...update };
  });

  const keys = Object.keys(init);
  const [id, ...filterOptions] = keys;

  const dataToSend = formatData.filter(line => filterOptions.some(option => !!line[option]));

  const onDone = () => {
    setSubmitting(false);
    resetForm();
    setIsShowNotification(true)
  };
  const onError = (error) => {
    toast.error(error, { duration: 5000 });
    setSubmitting(false);
  };

  updateShippingPlans({
    values: {...shippingPlans, [types[currentTab]]: dataToSend},
    onDone,
    onError,
  });
};