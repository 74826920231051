import React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import { Formik } from "formik";
import { FormControl, FormControlLabel, Input, InputAdornment, InputLabel, TextField } from "@material-ui/core";
import {formatErrors} from "../Registration";
import RSelect from "../../components/controls/RSelect";
import Terms3 from "../../../media/Terms3.pdf";
import Privatpolicy from "../../../media/Privatpolicy.pdf";
import * as auth from "../../../store/ducks/auth.duck";
import Link from "@material-ui/core/Link";
import Checkbox from "@material-ui/core/Checkbox";
import { register } from "../../../crud/auth.crud";
import {callingCode} from "../../home/Settings/utils";
import {useHistory, useLocation} from "react-router-dom";
import {Redirect} from "react-router";
import BodyScript from "../../../BodyScript";


const defaultCode = {
  label: <><span className="name-country">United Kingdom</span><span>+44</span></>,
  value: "United Kingdom +44",
  country: "United Kingdom"
};

const RegistrationForm = props => {
  const {intl, bottom, onAuth} = props;

  let location = useLocation();
  if (!location?.state?.url) {
    return <Redirect to={{pathname: "/on/subscription", state: {from: 'registration'}}} />
  }

  const waitForAffaim = (callback) => {
    const checkAffaim = () => {
      if (window.affaim) {
        callback();
      } else {
        setTimeout(checkAffaim, 100); // Check again after 100ms
      }
    };
    checkAffaim();
  };

  return (
      <Formik
      initialValues={{
        company_name: "",
        last_name: "",
        first_name: "",
        email: "",
        phone_code: defaultCode.country,
        contact_number: "",
        password: "",
        acceptTerms: false,
        // confirmPassword: ""
      }}
      validate={values => {
        const errors = {};

        if (!values.email) {
          errors.email = intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD"
          });
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = intl.formatMessage({
            id: "AUTH.VALIDATION.INVALID_FIELD"
          });
        }

        if (!values.company_name) {
          errors.company_name = intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD"
          });
        }

        if (!values.first_name) {
          errors.first_name = intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD"
          });
        }

        if (!values.last_name) {
          errors.last_name = intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD"
          });
        }

        if (!values.password) {
          errors.password = intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD"
          });
        }

        // if (!values.confirmPassword) {
        //   errors.confirmPassword = intl.formatMessage({
        //     id: "AUTH.VALIDATION.REQUIRED_FIELD"
        //   });
        // } else if (values.password !== values.confirmPassword) {
        //   errors.confirmPassword =
        //     "Password and Confirm Password didn't match.";
        // }

        if (!values.acceptTerms) {
          errors.acceptTerms = "Accept Terms";
        }

        return errors;
      }}
      onSubmit={(values, { setStatus, setSubmitting }) => {
          console.log(values, { setStatus, setSubmitting })
        const formData = {...values};
        register(formData)
          .then(({ data }) => {
              waitForAffaim(() => {
                window.affaim.sendCustomer({
                  email: values.email,
                  fullname: `${values.first_name} ${values.last_name}`
                  ,
                });
              });
            if (data && data.password) {
              setStatus(formatErrors(data.password));
              setSubmitting(false);
              return
            }
            data.token && props.register(data.token.access);
            onAuth && onAuth();
          })
          .catch(({ response={} }) => {
              console.log(response)
            const { data } = response;
            if (data && data.password) {
              setStatus(formatErrors(data.password));
            } else {
              setStatus('User already exists');
            }
            setSubmitting(false);
          });
      }}
    >
      {({
          values,
          status,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
        //TODO need to fix autocomplete user field
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          {status && (
            <div role="alert" className="alert alert-danger">
              <div className="alert-text">{status}</div>
            </div>
          )}

          <div className="form-group mb-0">
            <TextField
              margin="normal"
              type="text"
              label="Company Name"
              InputLabelProps={{
                shrink: true,
              }}
              className="kt-width-full"
              name="company_name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.company_name}
              helperText={touched.company_name && errors.company_name}
              error={Boolean(touched.company_name && errors.company_name)}
            />
          </div>
          <div className="form-group mb-0">
            <TextField
              type="text"
              margin="normal"
              label="First name"
              InputLabelProps={{
                shrink: true,
              }}
              className="kt-width-full"
              name="first_name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.first_name}
              helperText={touched.first_name && errors.first_name}
              error={Boolean(touched.first_name && errors.first_name)}
            />

          </div>
          <div className="form-group mb-0">
            <TextField
              type="text"
              margin="normal"
              label="Last name"
              InputLabelProps={{
                shrink: true,
              }}
              className="kt-width-full"
              name="last_name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.last_name}
              helperText={touched.last_name && errors.last_name}
              error={Boolean(touched.last_name && errors.last_name)}
            />

          </div>

          <div className="form-group mb-0">
            <TextField
              label="Email"
              InputLabelProps={{
                shrink: true,
              }}
              type="email"
              margin="normal"
              className="kt-width-full"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email}
              helperText={touched.email && errors.email}
              error={Boolean(touched.email && errors.email)}
            />
          </div>
          <BodyScript/>
          <div className="form-group mb-0 form-group--contact_number">
            <FormControl>
              <InputLabel htmlFor="contact_number">Contact Number</InputLabel>
              <Input
                name="contact_number"
                label="Contact Number"
                className="kt-width-full"
                startAdornment={
                  <InputAdornment position="start">
                    <RSelect
                      defaultValue={defaultCode}
                      options={callingCode}
                      name="phone_code"
                      label=""
                      onChange={(value) => setFieldValue("phone_code", value.country)}
                      style="form-group__phone_code"
                    />
                  </InputAdornment>
                }
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.contact_number || ''}
              />
            </FormControl>
          </div>

          <div className="form-group mb-0">
            <TextField
              type="password"
              margin="normal"
              label="Password"
              InputLabelProps={{
                shrink: true,
              }}
              className="kt-width-full"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.password}
              helperText={touched.password && errors.password}
              error={Boolean(touched.password && errors.password)}
            />
          </div>

          <div className="form-group d-switch--registration mb-0">
            <FormControlLabel
              key="acceptTerms"
              name="acceptTerms"
              checked={values.acceptTerms}
              onChange={handleChange}
              onBlur={handleBlur}
              control={<Checkbox
                name="acceptTerms"
                color="primary"
              />}
              label={
                <>
                  I agree to SKUify’s{" "}
                  <a href={Terms3} target="_blank">Terms & Conditions</a>
                  {" "}and{" "}
                  <a href={Privatpolicy} target="_blank">Privacy Policy</a>
                </>
              }
              labelPlacement="end"
            />
            {/*<FormControlLabel*/}
              {/*label={*/}
                {/*<>*/}
                  {/*I agree to SKUify’s{" "}*/}
                  {/*<a href={Terms2} target="_blank">Terms & Conditions</a>*/}
                  {/*{" "}and{" "}*/}
                  {/*<a href={Terms2} target="_blank">Privacy Policy</a>*/}
                {/*</>*/}
              {/*}*/}
              {/*control={*/}
                {/*<Switch*/}
                  {/*name="acceptTerms"*/}
                  {/*className="d-switch"*/}
                  {/*checked={values.acceptTerms}*/}
                  {/*onChange={handleChange}*/}
                  {/*onBlur={handleBlur}*/}
                  {/*inputProps={{'aria-label': `acceptTerms--checkbox`}}*/}
                {/*/>*/}
              {/*}*/}
            {/*/>*/}
          </div>

          <div className="kt-login__actions">
            <Link
              to="/auth/forgot-password"
              className="kt-link kt-login__link-forgot"
            >
              <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
            </Link>

            {/*<Link to="/auth">*/}
              {/*<button type="button" className="btn btn-secondary btn-elevate kt-login__btn-secondary">*/}
                {/*Back*/}
              {/*</button>*/}
            {/*</Link>*/}

            <button
              type="submit"
              disabled={isSubmitting || !values.acceptTerms}
              className="btn btn-primary btn-elevate kt-login__btn-primary"
            >
              Submit
            </button>
          </div>
          {bottom && bottom({disabled: isSubmitting || !values.acceptTerms})}
        </form>
      )}
    </Formik>
      )
};

export default injectIntl(
  connect(
    null,
    auth.actions
  )(RegistrationForm)
);


