import React, {useEffect} from "react";
import {connect} from "react-redux";
import * as PropTypes from "prop-types";
import {Form as FormikForm, Formik} from "formik";
import BankApi from "../../../../../crud/bank.crud";
import FormField from "../../../../components/controls/FormField";
import {typeOptions} from "../options";
import {typeOptionsReceipt} from "../../../Tools/ReceiptUploads/options";
import * as category from "../../../../../store/ducks/category.duck";
import Form from "react-bootstrap/Form";
import DButton from "../../../../components/Button";
import * as CategorySelector from "../../../../../selectors/category.selectors";
import * as commonSelectors from "../../../../../selectors/common.selectors";
import * as commonDuck from "../../../../../store/ducks/common.duck";

const initialData = {
  transactions: [
    {}
  ],
};

const BottomHeaderFilterForm = ({ getCategories, categories, formErrors, getTaxes, taxes, title }) => {

  useEffect(() => {
    getCategories();
    getTaxes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const type = title
    // type for transactions
    ? { options: typeOptions.map(({ label, value }) => ({ label: `${label}`, value })) }
    // type for receipt upload
    : { options: typeOptionsReceipt.map(({ label, value }) => ({ label: `${label}`, value })) };

  const transactionsInterface = [
    {
      id: 'date_from',
      label: 'Date From',
      type: 'date',
    },
    {
      id: 'date_to',
      label: 'Date To',
      type: 'date',
    },
    //TODO check options for receipt uploads
    {
      id: 'type',
      label: 'Type',
      type: 'RSelect',
      customProps: () => type,
    },
    //TODO check options. Need add Suppliers and Customers
    {
      id: 'category',
      label: 'Category',
      type: 'RSelect',
      customProps: () => ({ options: categories.map(({ name, id }) => ({label: name, value: id})) })
    },
    //TODO check options
    {
      id: 'account',
      label: 'Account',
      type: 'RSelect',
      customProps: () => ({ options: categories.map(({ name, id }) => ({ label: name, value: id })) })
    },
    {
      id: 'tax_code',
      label: 'Tax Code',
      type: 'RSelect',
      customProps: () => {
        return {
          byId: true,
          options: taxes.map((tax) => {
            return {
              label: tax.taxcode + " | " + tax.name + ' - ' + tax.tax_rate + "%",
              value: tax.id
            }
          }),
        }
      },
    },
  ];

  return (
    <Formik
      initialValues={initialData}
      onSubmit={async (values, { setSubmitting }) => {
        BankApi.batchCreateTransaction([values]).then(() => {
          setSubmitting(false)
        });
      }}
    >
      {({...form}) => {
        return (
          <FormikForm>
            <div>
              <div>{formErrors}</div>
              <div className="row">
                <div className="offset-sm-2 col-sm-8 trans-list__list">
                  {
                    transactionsInterface.map((item) => (
                      <Form.Group key={item.id} controlId={item.id} className="trans-list__item">
                        <Form.Label>{item.label}</Form.Label>
                        <FormField
                          name={item.id}
                          label={item.label}
                          type={item.type}
                          // validate={requiredValidator()}
                          customProps={item.customProps && item.customProps()}
                          {...form}
                        />
                      </Form.Group>
                    ))
                  }
                  <div className="container-button container-button--modal">
                    <DButton typeOfButton="reset">Reset</DButton>
                  </div>
                </div>
              </div>
            </div>
          </FormikForm>
        )
      }}
    </Formik>
  )
};

BottomHeaderFilterForm.propTypes = {
  getCategories: PropTypes.func.isRequired,
  getTaxes: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  taxes: PropTypes.array.isRequired,
  title: PropTypes.string,
};

const mapStateToProps = (state, props) => {
  return {
    categories: CategorySelector.getCategories(state, props),
    taxes: commonSelectors.getTaxes(state),
  }
};

const mapDispatchToProps = {
  getCategories: category.actions.getCategories,
  getTaxes: commonDuck.actions.getTaxes,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BottomHeaderFilterForm)
