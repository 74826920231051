import React from "react";
import Button from "@material-ui/core/Button";
import AddIcon from '@material-ui/icons/Add';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import * as PropTypes from "prop-types";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import RestoreIcon from '@material-ui/icons/Restore';
import PublishIcon from '@material-ui/icons/Publish';
import SettingsIcon from '@material-ui/icons/Settings';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CachedIcon from "@material-ui/icons/Cached";
import FilterNoneIcon from '@material-ui/icons/FilterNone';

const DButton = (props) => {
  const { onClickCustom, type, disabled, typeOfButton, className, children, variant='contained', size="medium" } = props;
  const getColor = () => {
    switch (typeOfButton) {
      case "defaultSecondary":
      case "add":
      case "addArrow":
      case "arrow":
      case "doneAllSecondary":
      case "exitSecondary":
      case "edit":
      case "back":
      case "import":
      case "forward":
      case "filter":
        return "secondary";
      default:
        return "primary"
    }
  };

  const getContent = (nameButton) => {
    switch (typeOfButton) {
      case "searchArrow":
        return (<><SearchIcon/>{nameButton}<ArrowDropDownIcon/></>);
      case "search":
        return (<><SearchIcon/>{nameButton}</>);
      case "addArrow":
        return (<><AddIcon/>{nameButton}<ArrowDropDownIcon/> </>);
      case "arrow":
        return (<>{nameButton}<ArrowDropDownIcon/> </>);
      case "add":
        return (<><AddIcon/>{nameButton}</>);
      case "doneAll":
        return (<><DoneAllIcon/>{nameButton}</>);
      case "doneAllSecondary":
        return (<><DoneAllIcon/>{nameButton}</>);
      case "close":
        return (<><CloseIcon/>{nameButton}</>);
      case "backPrimary":
        return (<><ArrowBackIcon/>{nameButton}</>);
      case "back":
        return (<><ArrowBackIcon/>{nameButton}</>);
      case "forward":
        return (<><ArrowForwardIcon/>{nameButton}</>);
      case "exit":
        return (<><ExitToAppIcon/>{nameButton}</>);
      case "exitSecondary":
        return (<><ExitToAppIcon/>{nameButton}</>);
      case "edit":
        return (<><EditIcon/>{nameButton}</>);
      case "delete":
        return (<><DeleteIcon/>{nameButton}</>);
      case "reset":
        return (<><RestoreIcon/>{nameButton}</>);
      case "import":
        return (<><PublishIcon/>{nameButton}</>);
      case "setting":
        return (<><SettingsIcon/>{nameButton}</>);
      case "refresh":
        return (<><CachedIcon/>{nameButton}</>);
      case "filter":
        return (<><FilterNoneIcon/>{nameButton}</>);
      case "defaultSecondary":
        return (<>{nameButton}</>);
      default:
        return (<>{nameButton}</>)
    }
  };

  return (
    <Button
      name={typeOfButton}
      type={type}
      variant={variant}
      size={size}
      color={getColor()}
      onClick={onClickCustom}
      disabled={disabled}
      className={className}
    >
      {getContent(children)}
    </Button>
  )
};

DButton.propTypes = {
  typeOfButton: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  onClickCustom: PropTypes.func,
};

export default DButton;
