import React, { useState } from 'react';
import * as PropTypes from "prop-types";
import { headRowsVATSchemesModal } from "../../Company/VATSchemesModal";
import { useCheckboxStorage, useModal, usePerPage } from "../../../../hooks/modal.hook";
import TableComponent from "../../../components/TableComponent/TableComponent";
import EmptyBlock from "../../../components/EmptyBlock";
import TableToolbarComponent from "../../../components/TableComponent/TableToolbarComponent";
import DButton from "../../../components/Button";
import withConfirmHook from "../../../../hooks/withConfirm.hook";
import CreateEditVATScheme from "../../Company/CreateEditVATScheme";

const items = [{ id: 1, start_date: "25/2/2019", end_date: "25/12/2020", vat_scheme: "FR-IB", rate: "10 %" },];

const title = "VAT Schemes";
const VATSchemesWizard = ({ confirm }) => {
  const [activeColumns, setActiveColumns] = useCheckboxStorage("VATSchemesWizard", headRowsVATSchemesModal);
  const [rowsPerPage, onPerPage] = usePerPage();
  const [isShowCreateEditModal, toggleShowCreateEditModal] = useModal();
  const [currentRow, setCurrentRow] = useState(null);
  // eslint-disable-next-line
  const [searchQuery, setSearchQuery] = useState(null);

  const onEdit = (row) => () => {
    setCurrentRow(row);
    toggleShowCreateEditModal();
  };

  const onCloseCreateEditModal = () => {
    toggleShowCreateEditModal();
    setCurrentRow(null);
    // getVATSchemes();
  };

  const onDelete = (row) => (
    confirm(() => {
        // deleteVATScheme({
        //   id: row.id,
        // })
      },
      { description: 'This action is permanent!' })
  );

  const content = items.length > 0
    ? <TableComponent
      headRows={activeColumns.filter(column => column.isActive)}
      // use VATSchemes
      items={items}
      rowsPerPageSelected={rowsPerPage}
      rowProps={{
        onEdit,
        onDelete,
      }}
    />
    : <EmptyBlock/>;

  return <>
    <TableToolbarComponent
      title={title}
      setSearchQuery={setSearchQuery}
      actionsHeader={{
        selectMenu: { setFunc: onPerPage, type: "select" },
        viewColumnMenu: { array: activeColumns, type: "filter", setFunc: setActiveColumns, disabled: true },
      }}
    />
    {content}
    <div className="container-button container-button--modal">
      <DButton typeOfButton="add" onClickCustom={toggleShowCreateEditModal}>Add VAT Scheme</DButton>
    </div>
    <CreateEditVATScheme
      show={isShowCreateEditModal}
      onClose={onCloseCreateEditModal}
      onCreateEditModal={onCloseCreateEditModal}
      // updateVATScheme={updateVATScheme}
      // addVATScheme={addVATScheme}
      initValues={currentRow}/>
  </>
};

VATSchemesWizard.propTypes = {
  VATSchemes: PropTypes.array,
  deleteVATScheme: PropTypes.func,
  getVATSchemes: PropTypes.func,
  updateVATScheme: PropTypes.func,
  addVATScheme: PropTypes.func,
  confirm: PropTypes.func,
};

export default withConfirmHook(VATSchemesWizard);