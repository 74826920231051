import React, {useEffect, useState} from "react";
import {Avatar, Button, Typography} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ImageUploading, {ImageListType} from 'react-images-uploading';

interface HeaderInformationProps {
  name: string;
  isImage?: boolean;
  imgUrl?: string;
  defaultImg?: string;
  images?: ImageListType;
  onImage?: (value: ImageListType) => void;
  onDeleteImage?: () => void;
}

const HeaderInformation = (props: HeaderInformationProps) => {
  const {name, defaultImg, isImage, imgUrl, images, onImage, onDeleteImage} = props;
  // const [images, setImages] = useState<ImageListType>([]);
  const initImage = imgUrl || defaultImg || "";
  const [currentImage, setCurrentImage] = useState<string>(initImage);

  useEffect(() => {
    imgUrl && setCurrentImage(imgUrl)
  }, [imgUrl]);

  const onChange = (value: ImageListType, addUpdatedIndex?: number[] | undefined) => {
    // data for submit
    onImage?.(value);
    setCurrentImage(value[0]['data_url'] || "");
  };

  const onDelete = () => {
    onDeleteImage?.();
    setCurrentImage(defaultImg || '');
  };

  return (
    <>
      <Typography variant="h5" color="primary" className="settings__title">
        {name}
      </Typography>

      {isImage && <ImageUploading
        acceptType={['png', 'jpeg', 'jpg']}
        value={images || []}
        onChange={onChange}
        dataURLKey="data_url"
      >
        {({onImageUpload}) => (
          <div className="upload__image-wrapper">
            <div className="settings__header">
              <Avatar alt="img" variant="circular" src={currentImage} className="header__image"/>

              <Button variant="contained" className="header__btn" onClick={onImageUpload}>
                <AddIcon />
                Upload new picture
              </Button>

              <Button variant="outlined" className="header__btn" onClick={onDelete}>
                Remove
              </Button>
            </div>
          </div>
        )}
      </ImageUploading>}

    </>
  )
};

export default HeaderInformation;