import React from "react";
import { Link, Typography } from "@material-ui/core";
import { ReactComponent as AmazonIcon } from '../../../../media/icons/amazon-icon.svg';
import classNames from 'classnames';

const Title = ({replenish, onCopy, boldText, lightText, url, title}) => (
  <Typography variant="body1" className={classNames("table__cell--link__text", {"table__cell--update": replenish})}>
    <span className="table__cell--detail__content" onClick={onCopy(title, "Title")}>
      <span className="bold table__cell--detail__title">{boldText}</span>
      {lightText}
    </span>
    {/*<Link*/}
      {/*href={url}*/}
      {/*target="_blank"*/}
      {/*className={classNames("copy-hover copy-hover--title", { "restocked": replenish })}*/}
    {/*>*/}
      {/*<AmazonIcon />*/}
    {/*</Link>*/}
  </Typography>
);

export default Title;