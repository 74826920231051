import React from "react";
import { ReactComponent as Dashboard } from "./../../_metronic/layout/assets/layout-svg-icons/menu-icon/Dashboard.svg";
import { ReactComponent as Help } from "./../../_metronic/layout/assets/layout-svg-icons/menu-icon/Help.svg";
import { ReactComponent as Inventory } from "./../../_metronic/layout/assets/layout-svg-icons/menu-icon/Inventory.svg";
import { ReactComponent as Journey } from "./../../_metronic/layout/assets/layout-svg-icons/menu-icon/Journey.svg";
import { ReactComponent as Reconciliation } from "./../../_metronic/layout/assets/layout-svg-icons/menu-icon/Reconciliation.svg";
import { ReactComponent as Reports } from "./../../_metronic/layout/assets/layout-svg-icons/menu-icon/Reports.svg";
import { ReactComponent as Repricing } from "./../../_metronic/layout/assets/layout-svg-icons/menu-icon/Repricing.svg";
import { ReactComponent as Sales } from "./../../_metronic/layout/assets/layout-svg-icons/menu-icon/Sales.svg";
import { ReactComponent as Settings } from "./../../_metronic/layout/assets/layout-svg-icons/menu-icon/Settings.svg";
import { ReactComponent as Expenses } from "./../../_metronic/layout/assets/layout-svg-icons/menu-icon/Expenses.svg";

export default {
  header: {
    self: {},
    items: [
      {
        title: "Dash",
        root: true,
        alignment: "left",
        page: "dashboard",
        translate: "MENU.DASHBOARD",
        subscription: ['free', 'base'],
        no_breadcrumbs: true,
        // width: "fluid",
      },
      {
        title: "Inventory",
        root: true,
        alignment: "left",
        page: "products",
        translate: "MENU.PRODUCTS",
        subscription: ['free', 'base'],
        no_breadcrumbs: true,
      },
      {
        title: "Sales",
        root: true,
        alignment: "left",
        page: "sales",
        translate: "MENU.SALES",
        subscription: ['free', 'base'],
        no_breadcrumbs: true,
      },
      // {
      //   title: "Reports",
      //   root: true,
      //   alignment: "left",
      //   page: "reports",
      //   translate: "MENU.REPORTS",
      //   subscription: [],
      //   no_breadcrumbs: true,
      //   email: ['office@hamleyretail.co.uk', 'admin@doddled.com'],
      // },
      {
        title: "Repricing",
        root: true,
        alignment: "left",
        page: "repricer",
        translate: "MENU.REPRICER",
        no_breadcrumbs: true,
        // email: ['stevewright151@yahoo.com', 'admin@doddled.com'],
        subscription: ['free', 'base'],
      },
      {
        title: "Journey",
        root: true,
        alignment: "left",
        page: "journey",
        translate: "MENU.JOURNEY",
        no_breadcrumbs: true,
        // email: ['stevewright151@yahoo.com', 'admin@doddled.com', 'sam@theodore-charles.co.uk', 'office@hamleyretail.co.uk'],
        subscription: ['free', 'base'],
      },
      {
        title: "Reconciliation",
        root: true,
        alignment: "left",
        page: "reconciliation",
        translate: "MENU.RECONCILIATION",
        subscription: ['free', 'base'],
        // subscription: [],
        // email: ['lucabecciu15@gmail.com', 'admin@doddled.com'],
        no_breadcrumbs: true,
      },
      {
        title: "Expenses",
        root: true,
        alignment: "left",
        page: "expenses",
        translate: "MENU.EXPENSES",
        subscription: ['free', 'base'],
        // subscription: [],
        // email: ['lucabecciu15@gmail.com', 'admin@doddled.com'],
        no_breadcrumbs: true,
      },
      {
        title: "Books",
        root: true,
        alignment: "left",
        page: "books",
        translate: "MENU.BOOKS",
        subscription: ['free', 'base'],
        // subscription: [],
        // email: ['lucabecciu15@gmail.com', 'admin@doddled.com'],
        no_breadcrumbs: true,
      },

      {
        title: "Tax",
        root: true,
        alignment: "left",
        page: "tax",
        translate: "MENU.TAX",
        subscription: ['free', 'base'],
        // subscription: [],
        // email: ['lucabecciu15@gmail.com', 'admin@doddled.com'],
        no_breadcrumbs: true,
      },
      // {
      //   title: "Books",
      //   root: true,
      //   alignment: "left",
      //   page: "books/categories",
      //   translate: "MENU.BOOKS",
      //   subscription: [],
      //   submenu: [
      //     {
      //       title: "Enter Transaction",
      //       page: "books/enter-transaction",
      //     },
      //
      //     {
      //       title: "Enter Transaction",
      //       page: "books/enter-transaction",
      //       submenu: [
      //         {
      //           title: "New Bank Transaction",
      //           page: "books/bank_transactions/new",
      //         },
      //       ]
      //     },
      //
      //     // Invoices
      //     {
      //       title: "Enter Transaction",
      //       page: "books/enter-transaction",
      //       submenu: [
      //         {
      //           title: "New Customer Invoice",
      //           page: "books/sales_invoices/batches/new",
      //         },
      //         {
      //           title: "New Supplier Invoice",
      //           page: "books/purchase_invoices/new",
      //         },
      //         {
      //           title: "Edit Customer Invoice",
      //           page: "books/sales_invoices/batches/edit",
      //         },
      //         {
      //           title: "Edit Supplier Invoice",
      //           page: "books/purchase_invoices/edit",
      //         },
      //       ]
      //     },
      //     // Invoices end
      //
      //     // Imports
      //     {
      //       title: "Enter Transaction",
      //       page: "books/enter-transaction",
      //       submenu: [
      //         {
      //           title: "Imports and Feeds",
      //           page: "imports",
      //         },
      //         {
      //           title: "Imports and Feeds",
      //           page: "imports",
      //           submenu: [
      //             {
      //               title: "Custom File Import Formats",
      //               page: "custom_file_import_formats",
      //             },
      //             {
      //               title: "Check and Confirm",
      //               page: "imported_bank_transactions/",
      //             },
      //             {
      //               title: "Custom File Import Formats",
      //               page: "custom_file_import_formats",
      //               submenu: [
      //                 {
      //                   title: "New Custom File Import Format",
      //                   page: "custom_file_import_formats/new",
      //                 }
      //               ]
      //             },
      //           ]
      //         },
      //         {
      //           title: "Imports",
      //           page: "imports",
      //           submenu: [
      //             {
      //               title: "Upload file",
      //               page: "file_imports/new",
      //             },
      //           ]
      //         },
      //       ]
      //     },
      //     //Imports end
      //
      //     {
      //       title: "Categories",
      //       page: "books/categories",
      //     },
      //
      //     // Adjustment
      //     {
      //       title: "Categories",
      //       page: "books/categories",
      //       submenu: [
      //         {
      //           title: "New Adjustment",
      //           page: "books/journal_entries/new",
      //         },
      //         {
      //           title: "New Adjustment",
      //           page: "books/journal_entries/new",
      //           submenu: [
      //             {
      //               title: "Edit Adjustment",
      //               page: "books/journal_entries/edit",
      //             },
      //           ]
      //         },
      //       ]
      //     },
      //     // Adjustment end
      //
      //     // Transactions
      //     {
      //       title: "View Transaction",
      //       page: "books/transactions",
      //     },
      //     {
      //       title: "View Transaction",
      //       page: "books/transactions",
      //       submenu: [
      //         {
      //           title: "Bulk Transaction Editor",
      //           page: "books/bank_transactions/bulk-editor",
      //         },
      //         {
      //           title: "`Edit Bank Transaction",
      //           page: "books/transactions/edit",
      //         },
      //       ]
      //     },
      //     // Transactions end
      //
      //     //Reports
      //     {
      //       title: "Profit & Loss",
      //       page: "books/reports/profit_and_loss",
      //     },
      //     {
      //       title: "Balance Sheet",
      //       page: "books/reports/balance_sheet",
      //     },
      //     {
      //       title: "Trial Balance",
      //       page: "books/reports/trial_balance",
      //     },
      //     {
      //       title: "All Reports",
      //       page: "books/reports",
      //     },
      //     //Reports end
      //
      //     // Tools
      //     // Recurring Transactions
      //     {
      //       title: "Recurring Transactions",
      //       page: "books/tools/recurring-transactions",
      //     },
      //     {
      //       title: "Recurring Transactions",
      //       page: "books/tools/recurring-transactions",
      //       submenu: [
      //         {
      //           title: "New Recurring Invoice",
      //           page: "books/invoice/as_templates/new",
      //         },
      //         {
      //           title: "New Recurring Adjustment",
      //           page: "books/journal_entry/as_templates/new",
      //         },
      //         {
      //           title: "Edit Recurring Invoice",
      //           page: "books/invoice/as_templates/edit",
      //         },
      //         {
      //           title: "Edit Recurring Adjustment",
      //           page: "books/journal_entry/as_templates/edit",
      //         },
      //         {
      //           title: "View Details",
      //           page: "books/tools/recurring-transactions/details",
      //         },
      //       ]
      //     },
      //     // Recurring Transactions end
      //
      //     {
      //       title: "Opening Balances Wizard",
      //       page: "books/tools/opening-balances",
      //     },
      //     {
      //       title: "Receipt Uploads",
      //       page: "books/tools/receipt-uploads",
      //     },
      //
      //     // Bank Rules
      //     {
      //       title: "Bank Rules",
      //       page: "books/tools/bank_rules",
      //     },
      //     {
      //       title: "Bank Rules",
      //       page: "books/tools/bank_rules",
      //       submenu: [
      //         {
      //           title: "New Bank Rule",
      //           page: "books/tools/bank_rules/new",
      //         },
      //         {
      //           title: "Edit Bank Rule",
      //           page: "books/tools/bank_rules/edit",
      //         },
      //       ]
      //     },
      //         // Bank Rules end
      //     //Tools end
      //
      //     {
      //       title: "Settings",
      //       page: "books/settings",
      //     },
      //   ]
      // },
      {
        title: "Notifications",
        root: true,
        alignment: "left",
        page: `${process.env.REACT_APP_ENDPOINT}dashboard/report-notification/`,
        translate: "MENU.AMAZON",
        subscription: [],
      },
    ]
  },
  aside: {
    self: {},
    items: [
      {
        title: "Dash",
        root: true,
        alignment: "left",
        page: "dashboard",
        translate: "MENU.DASHBOARD",
        subscription: ['free', 'base'],
        no_breadcrumbs: true,
        icon: props => <Dashboard {...props} />,
        // width: "fluid",
      },
      {
        title: "Inventory",
        root: true,
        alignment: "left",
        page: "products",
        translate: "MENU.PRODUCTS",
        subscription: ['free', 'base'],
        no_breadcrumbs: true,
        icon: props => <Inventory {...props} />,
      },
      {
        title: "Sales",
        root: true,
        alignment: "left",
        page: "sales",
        translate: "MENU.SALES",
        subscription: ['free', 'base'],
        no_breadcrumbs: true,
        icon: props => <Sales {...props} />,
      },
      {
        title: "Repricing",
        root: true,
        alignment: "left",
        page: "repricer",
        translate: "MENU.REPRICER",
        no_breadcrumbs: true,
        // email: ['stevewright151@yahoo.com', 'admin@doddled.com'],
        subscription: ['free', 'base'],
        icon: props => <Repricing {...props} />,
      },
      {
        title: "Journey",
        root: true,
        alignment: "left",
        page: "journey",
        translate: "MENU.JOURNEY",
        no_breadcrumbs: true,
        // email: ['stevewright151@yahoo.com', 'admin@doddled.com', 'sam@theodore-charles.co.uk', 'office@hamleyretail.co.uk'],
        subscription: ['free', 'base'],
        icon: props => <Journey {...props} />,
      },
      {
        title: "Reconciliation",
        root: true,
        alignment: "left",
        page: "reconciliation",
        translate: "MENU.RECONCILIATION",
        subscription: ['free', 'base'],
        // subscription: [],
        // email: ['lucabecciu15@gmail.com', 'admin@doddled.com'],
        no_breadcrumbs: true,
        icon: props => <Reconciliation {...props} />,
      },
      {
        title: "Reports",
        root: true,
        alignment: "left",
        page: "reports",
        translate: "MENU.REPORTS",
        subscription: ['free', 'base'],
        no_breadcrumbs: true,
        icon: props => <Reports {...props} />,
      },
      // {
      //   title: "Reports",
      //   root: true,
      //   alignment: "left",
      //   page: "reports",
      //   translate: "MENU.REPORTS",
      //   subscription: [],
      //   no_breadcrumbs: true,
      //   email: ['office@hamleyretail.co.uk', 'admin@doddled.com'],
      // },
      {
        title: "Expenses",
        root: true,
        alignment: "left",
        page: "expenses",
        translate: "MENU.EXPENSES",
        subscription: ['free', 'base'],
        // subscription: [],
        // email: ['lucabecciu15@gmail.com', 'admin@doddled.com'],
        no_breadcrumbs: true,
        icon: props => <Expenses {...props} />,
      },
      // {
      //   title: "Books",
      //   root: true,
      //   alignment: "left",
      //   page: "books",
      //   translate: "MENU.BOOKS",
      //   subscription: ['free', 'base'],
      //   // subscription: [],
      //   // email: ['lucabecciu15@gmail.com', 'admin@doddled.com'],
      //   no_breadcrumbs: true,
      // },

      // {
      //   title: "Tax",
      //   root: true,
      //   alignment: "left",
      //   page: "tax",
      //   translate: "MENU.TAX",
      //   subscription: ['free', 'base'],
      //   // subscription: [],
      //   // email: ['lucabecciu15@gmail.com', 'admin@doddled.com'],
      //   no_breadcrumbs: true,
      // },
      // {
      //   title: "Notifications",
      //   root: true,
      //   alignment: "left",
      //   page: `${process.env.REACT_APP_ENDPOINT}dashboard/report-notification/`,
      //   translate: "MENU.AMAZON",
      //   subscription: [],
      // },
      // {
      //   title: "Settings",
      //   root: true,
      //   alignment: "left",
      //   page: "settings",
      //   translate: "MENU.SETTINGS",
      //   subscription: ['free', 'base'],
      //   no_breadcrumbs: true,
      //   icon: props => <Settings {...props} />,
      // },
      // {
      //   title: "Help",
      //   root: true,
      //   alignment: "left",
      //   page: "help",
      //   translate: "MENU.HELP",
      //   subscription: ['free', 'base'],
      //   no_breadcrumbs: true,
      //   icon: props => <Help {...props} />,
      // },
    ]
  }
};
