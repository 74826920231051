import React from "react";
import {Form as FormikForm, Formik} from "formik";
import {Link} from "@material-ui/core";
import {
  primaryData,
  generalData,
  generalData2,
  strategies,
  getCustomProps,
} from "./interfaces";
import Button from "@material-ui/core/Button";
import FormField from "../../../components/controls/FormField";
import {GeneralData, Option} from "./components";
import {getStrategy} from "./utils";

export const renderForm = props => {
  const {initialValues, row, currencySymbol, cogsVariants, handleChangeStrategy, cogInterface, onSubmit} = props;
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {form => (
        <FormikForm>
          <div className="bbp-strategy-wrapper">
            <div className="bbp-settings-import-primary-data-wrapper">
              <div className="title-wrapper">
                <span className="title-label">Title:</span>
                <Link target="_blank" href={row.amazon_product_url} className="detail-link">
                  {row.item_name}
                </Link>
              </div>

              <div className="primary-data-wrapper">
                {primaryData.map(({prefixValue, ...item}) => (
                  <GeneralData
                    key={item.label}
                    form={form}
                    item={{...item, ...(prefixValue && {customProps: {prefixValue: currencySymbol}})}}
                  />
                ))}
              </div>
            </div>


            <div className="bbp-settings-import-inventory-costs-wrapper">
              {generalData.map(({prefixValue, ...item}) => (
                <GeneralData
                  key={item.label}
                  form={form}
                  item={{...item, ...(prefixValue && {customProps: {prefixValue: currencySymbol}})}}
                />
              ))}
            </div>
            <div className="bbp-settings-import-inventory-costs-wrapper">
              {generalData2.map(item => <GeneralData item={item} key={item.label} form={form} />)}
            </div>
          </div>

          <div className="bbp-strategy-wrapper">
            <p className="title">Please select the correct COGs to apply to ASIN</p>

            <div className="options-wrapper">
              {cogsVariants.map(option => (
                <Option
                  key={option.label}
                  row={row}
                  form={form}
                  option={option}
                  cogInterface={cogInterface.map(cog => ({
                    ...cog,
                    // id: cog.id,
                    id: option.label !== "Edit" ? `${option.label}-${cog.id}` : cog.id,
                    customProps: getCustomProps({
                      currencySymbol,
                      name: cog.id,
                      other: cog.customProps || {}
                    })
                  }))}
                  // cogInterface={cogInterface}
                  currency={currencySymbol}
                  handleChangeStrategy={handleChangeStrategy}
                />
              ))}
            </div>

            <div className="footer">
              <div className="apply-option-wrapper">
                <span className="apply-option">Apply to ALL unsold inventory on this ASIN</span>
                <FormField
                  name="apply_to_unsold_inventory"
                  type="switch"
                  label="apply_to_unsold_inventory"
                  {...form}
                />
              </div>

              <Button
                className="submit-button"
                type="submit"
                disabled={!getStrategy({values: form.values, strategies})}
              >
                SAVE
              </Button>
            </div>
          </div>
        </FormikForm>
      )}
    </Formik>
  );
};