import React from "react";
import { Avatar, Link, TableCell } from "@material-ui/core";
import moment from 'moment';
import WarningCell from "../Dashboard/components/WarningCell";
import NameCell from "../Dashboard/components/NameCell";
import { fulfilmentIcon, tooltipProps, typeReports } from "../Dashboard/components/utils";
import {getNumber, momentFormatOptions, withUnits} from "../utils";
import Help from "../Dashboard/components/Help";
import FulfillmentCell from "../Dashboard/components/FulfilmentCell";
import { icon as notesIcons } from "../Dashboard/components/NoteTabTable";
import NoteProducts from "./NoteProducts";
import TooltipComponentState from "../../components/TooltipComponentState";
import classNames from "clsx";
import BBPSettingsCell from "./BBPSettingsCell/BBPSettingsCell";
import { bbpImportCOGS } from "./utils";
import { BBPDeleteHelp } from "./elements";
import DeleteBBP from "./BBPSettingsCell/DeleteBBP";

const help = "Use the add button when you have restocked this ASIN and are using a different SKU. " +
  "This option allows you to add a different COG figure too.";

export const IMPORTS_TABLE_NAME = 'imports';

export const headRowsImports = [
  {
    id: "image_url",
    label: "",
    render: (row) => {
      const { image_url, amazon_product_url, id, parent, full_image_url } = row;
      return (
        <TableCell align="left" key={`${id}--photo`} className="table__cell--photo">
          {!parent &&  (
            <TooltipComponentState
              placement="right-start"
              className="cell--photo__tooltip"
              isBlur
              tooltip={
                <Avatar alt="img" variant="square" src={full_image_url} className="cell__tooltip__photo" />
              }
            >
              <Link target="_blank" href={amazon_product_url}>
                <Avatar alt="img" variant="square" src={image_url} />
              </Link>
            </TooltipComponentState>
          )}
        </TableCell>
      )
    },
  },
  {
    id: "item_name",
    fewLabel: [
      {id: "item_name", label: "Title"},
      {id: "vat_rate", label: "VAT Rate", tooltipPlacement: "bottom-start"},
    ],
    render: (row) => {
      const parent = row.parent;
      let title = row.item_name;
      if (parent) {
        let dateAdded = moment(row.date_added).format(momentFormatOptions.display);
        title = `Restocked ${dateAdded}`;
      }
      return (
        <NameCell
          key={`${row.id}--name`}
          asin={row.asin1}
          url={row.amazon_product_url}
          sku={row.seller_sku}
          vat={row.vat_rate}
          title={title}
          parent={parent}
          colSpan={2}
          row={row}
          tableName={IMPORTS_TABLE_NAME}
        />
      )
    },
  },
  {
    id: "price",
    label: "Price",
    align: "center",
    helpText: "The total sales price paid by the customer",
    render: (row, { currency }) => {
      const value = withUnits(currency,row.price);
      return <TableCell align="center" key={`${row.id}--price`} className="table__cell--price">{value}</TableCell>
    }
  },
  {
    id: "quantity",
    label: "Stock",
    align: "center",
    helpText: "The number you have in stock for this ASIN",
    render: (row) => {
      const value = withUnits(undefined, getNumber(row.quantity), "integer") || "0";
      return (
        <TableCell align="center" key={`${row.id}--quantity`} className="table__cell--quantity">
          {value}
        </TableCell>
      )
    }
  },
  {
    id: "cog",
    align: "center",
    label: "COG",
    helpText: "Your costs of goods are calculated by adding together your unit cost, " +
      "prep centre, bundle cost, packaging, postage, handling and others as appropriate",
    render: (row, { currency }) => {
      let tooltipCogs = {...bbpImportCOGS};

      const data = bbpImportCOGS.data.map(cog => ({...cog, value: row[cog.key]})).filter(cog => cog.value || cog.value === 0);
      const total = data.reduce((res, cog) => res + cog.value, 0);

      tooltipCogs = {...tooltipCogs, data, total};

      const value = data.length !== 0 || !!total ? (
        <TooltipComponentState {...tooltipProps({ report: [tooltipCogs] })}>
          {withUnits(currency, row.cog)}
        </TooltipComponentState>
      ) : (
        withUnits(currency, row.cog)
      );

      return <TableCell align="center" key={`${row.id}--cog`} className="table__cell--cog">{value}</TableCell>
    }
  },
  {
    id: "fulfillment",
    label: "Fulfilment",
    align: "center",
    help: () => <Help data={fulfilmentIcon} className="help--fulfillment" />,
    render: (row) => {
      const fulfillment = row.fulfillment;
      const value = <FulfillmentCell row={row} fulfillment={fulfillment} />;
      return (
        <TableCell align="center" key={`${row.id}--fulfillment`} className="table__cell--fulfilment">
          {value}
        </TableCell>
      )
    }
  },
  {
    id: "amazon_fees ",
    align: "center",
    label: "Amazon Fees",
    helpText: "The total Amazon fees",
    render: (row, { currency }) => {
      const value = withUnits(currency,row.amazon_fees);
      return (
        <TableCell align="center" key={`${row.id}--amazon_fees`} className="table__cell--amazon_fees">
          {value}
        </TableCell>
      )
    }
  },
  {
    id: "profit",
    label: "Profit",
    align: "center",
    render: (row, { currency, onHoverRow, currentRow, orderReport, isLoading }) => {
      const isShowReport = !!row.profit || row.profit === 0;
      const tooltipData = isShowReport && currentRow.id === row.id ? orderReport : null;
      const reportTooltipProps = tooltipData ? tooltipProps({ report: tooltipData, type: typeReports.PRODUCTS, isLoading }) : null;

      const value = <div
        onMouseEnter={onHoverRow(row, typeReports.PRODUCTS, isShowReport)}
        className={classNames("full-size placement--center", {"report__tooltip": isShowReport})}
      >
        {reportTooltipProps ? (
            <TooltipComponentState {...reportTooltipProps}>
              <WarningCell
                key={`${row.id}--profit`}
                name="profit"
                value={row.profit}
                units={currency}
                text
              />
            </TooltipComponentState>
        ) : (
          <WarningCell
            key={`${row.id}--profit`}
            name="profit"
            value={row.profit}
            units={currency}
            text
          />
        )}
      </div>;
      return <TableCell align="center" key={`${row.id}--profit`} className="table__cell--profit">{value}</TableCell>
    },
    helpText: "Your estimated profit assuming your product sells for the price in the ‘price’ column",
  },
  {
    id: "roi",
    label: "ROI",
    align: "center",
    helpText: "Your estimated Return On Investment assuming your product sells for the price in the ‘price’ column",
    render: (row) => {
      const value =
        <WarningCell
          key={`${row.id}--roi`}
          id={row.id}
          name="roi"
          value={row.roi}
          units="%"
          text
        />;
      return <TableCell align="center" key={`${row.id}--roi`} className="table__cell--roi">{value}</TableCell>
    },
  },
  {
    id: "margin",
    label: "Margin",
    align: "center",
    helpText: "Your estimated Profit Margin assuming your product sells for the price in the ‘price’ column",
    render: (row) => {
      const value = <WarningCell
        key={`${row.id}--margin`}
        id={row.id}
        name="margin"
        value={row.margin}
        units="%"
        text
      />;
      return <TableCell align="center" key={`${row.id}--margin`} className="table__cell--margin">{value}</TableCell>
    },
  },
  {
    id: "cog_strategy",
    label: "Settings",
    align: "center",
    disabled: true,
    helpText: help,
    render: (row, { settings }) => {
      return (
          <TableCell align="center" key={`${row.id}--add`} className="table__cell--add">
            <BBPSettingsCell row={row} settings={settings} />
          </TableCell>
      )
    }
  },
  {
    id: "note",
    label: "Note",
    align: "center",
    disabled: true,
    help: () => <Help data={notesIcons} />,
    render: (row) => {
      const {raw_bbp, note, id} = row;
      const link = raw_bbp?.sourceUrl ? raw_bbp.sourceUrl : '';
      const text = (!!link && note) ? `${note}\n` : note;
      return (
        <TableCell align="center" key={`${id}--note`} className="table__cell--note">
          <NoteProducts row={{...row, note: text}} link={link} />
        </TableCell>
      )
    },
  },
  {
    id: "delete",
    label: "Delete",
    align: "center",
    disabled: true,
    help: BBPDeleteHelp,
    render: (row, { deleteBBP }) => <DeleteBBP key={`${row.id}--delete`} row={row} deleteBBP={deleteBBP} />
  },
];
