import { Field } from "formik";
import * as PropTypes from "prop-types";
import React from "react";
import FormComponent from "./FormComponent";

const FormField = (props) => {
  let {
    name,
    label,
    validate,
    setFieldValue,
    setFieldTouched,
    type,
    render,
    placeholder,
    showLabel,
    customProps,
    disabled,
    values,
    index,
    defaultValue,
    autofocus,
    extraProps,
    submitCount
  } = props;

  return <Field name={name} key={label} validate={validate}>
    {({
        field,
        form: { touched, errors },
        meta,
      }) => {
      if (typeof customProps === "function") {
        customProps = customProps({
          setFieldValue,
          setFieldTouched,
          field,
          values,
          index,
          extraProps,
          submitCount
        });
      }

      const defProps = {
        setFieldValue,
        setFieldTouched,
        name,
        type,
        label,
        placeholder: placeholder || label ,
        meta,
        showLabel,
        disabled,
        defaultValue,
        autofocus,
        ...field,
        customProps,
        values,
        submitCount,
      };

      if(render) {
          if (extraProps) {
              defProps.extraProps = extraProps
          }
          return render(defProps);
      }
      return <FormComponent {...defProps} customProps={customProps} />
    }}
  </Field>
};

const requiredPropsCheck = (props, propName, componentName) => {
  if (!props.type && !props.render) {
    return new Error(`One of 'type' or 'render' is required by '${componentName}' component.`)
  }
};

FormField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  placeholder: PropTypes.string,
  // render or type is required
  type: requiredPropsCheck,
  render: requiredPropsCheck,

  validate: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  showLabel: PropTypes.bool,
  customProps: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  values: PropTypes.object,
  extraProps: PropTypes.object,
  index: PropTypes.number,
  //  input props
};

export default FormField;
