import React from "react";
import AccountSettings from "../AccountSettings";
// import SubscriptionSettings from "../SubscriptionSettings";
import COGSettings from "../COGSettings";
import Integration from "../Integration";
import AmazonSettings from "../AmazonSettings";
import CompanySettings from "../CompanySettings";
import Migration from "../Migration";
import ShippingPlans from "../ShippingPlans";
import FinancialSettings from "../VATSettings";
import SecuritySettings from "../SecuritySettings";
import LabelTab from "../../../components/DTabs/LabelTab";
// import NotificationsSettings from "../NotificationsSettings";

export const settingsTabs = [
  {
    value: "account_settings",
    label: <LabelTab title="Profile"/>,
    content: <AccountSettings/>,
    walk: " - Want to hear more about exciting SKUify updates and features? Fill in your info here to let us know how best to contact you!",
    walkTitle: "Profile",
  },
  {
    value: "company_settings",
    label: <LabelTab title="Company"/>,
    content: <CompanySettings/>,
    walk: " - Want to hear more about exciting SKUify updates and features? Fill in your info here to let us know how best to contact you!",
    walkTitle: "Company",
  },
  {
    value: "security_settings",
    label: <LabelTab title="Security"/>,
    content: <SecuritySettings/>,
    walk: " - Change your password here",
    walkTitle: "Security",
  },
  // {
  //   value: "notifications_settings",
  //   label: <LabelTab title="Notifications"/>,
  //   content: <NotificationsSettings/>,
  //   walk: "",
  //   walkTitle: "",
  // },
  {
    value: "amazon_settings",
    label: <LabelTab title="Amazon Settings" />,
    content: <AmazonSettings/>,
    walk: " - This is where you’ll link up your seller central account by authorising us as a developer and entering your store name, seller ID, and LWA Refresh Token.",
    walkTitle: "Amazon Settings",
  },
  {
    value: "cog_settings",
    label: <LabelTab title="Cost of Goods Sold Information"/>,
    content: <COGSettings/>,
    walk: " - Enter in all of your cost of goods here (for FBA, FBM and SFP) so SKUify can apply these to new inventory!",
    walkTitle: "Cost Of Goods Sold Information"
  },
  {
    value: "shipping_plans",
    label: <LabelTab title="Merchant Shipping Plans"/>,
    content: <ShippingPlans/>,
    walk: " - FBM/SFP seller? Pop in the name(s) and specifications of your shipping plan(s) so SKUify can detect the plan(s) in your Seller Central Account",
    walkTitle: "Merchant Shipping Plans",
  },
  {
    value: "vat_settings",
    label: <LabelTab title="Financial Settings"/>,
    content: <FinancialSettings/>,
    walk: " - Let SKUify know your VAT status, preferred ROI method, and give specifications as to what products will qualify as a ‘winner’ or a ‘loser’",
    walkTitle: "Financial Settings",
  },
  // {
  //   value: "subscription_settings",
  //   label: <LabelTab title="Subscription"/>,
  //   content: <SubscriptionSettings/>,
  //   walk: " - View or upgrade your subscription here!",
  //   walkTitle: "Subscription",
  // },
  {
    value: "integration_notifications",
    label: <LabelTab title="Integrations" // & Notifications
    />,
    content: <Integration/>,
    walk: " - BuyBotPro or Profit Protector Pro user? Use the information here to link up your accounts to use the integrated functionalities and streamline your workflow!",
    walkTitle: "Integrations",
  },
  {
    value: "migration",
    label: <LabelTab title="Migration"/>,
    content: <Migration/>,
    walk: " - Joining us from SellerToolKit or SellerBoard and want to draft over your COG information? This can be easily done with a click of either button and uploading your CSV.",
    walkTitle: "Migration",
  },
];

