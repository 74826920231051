import React from "react";
import {FormControlLabel, FormHelperText, Switch} from "@material-ui/core";

const SwitchField = allProps => {
  const {
    name,
    setFieldValue,
    setFieldTouched,
    placeholder,
    label,
    meta,
    showLabel,
    customProps = {},
    submitCount,
    autofocus,
    ...props
  } = allProps;
  const {onChange, mark, labelPlacement = "start", ...otherProps} = customProps;

  const handleChange = (event) => {
    if (onChange) {
      onChange(event);
    } else {
      setFieldValue(name, event.target.checked);
    }
  };

  let labelSwitch = label;
  if (mark) {
    labelSwitch = <>
      {label}
      <span className="form-control__mark">{mark}</span>
    </>
  }

  return (
    <>
      <FormControlLabel
        {...props}
        {...otherProps}
        key={name}
        name={name}
        label={labelSwitch}
        control={
          <Switch
            className="d-switch"
            checked={!!props.value}
            onChange={handleChange}
            inputProps={{'aria-label': `${name}--checkbox`}}
          />
        }
        labelPlacement={labelPlacement}
      />
      {(meta.touched || submitCount) && meta.error && (
        <FormHelperText error id="component-error-text">
          {meta.error}
        </FormHelperText>
      )}
    </>
  )
};
export default SwitchField;