import React from "react";
import PropTypes from "prop-types";
import { TableHead, TableRow, TableCell, Checkbox } from "@material-ui/core";
import HeaderTableCell from "./HeaderTableCell"

function TableHeadComponent(props) {
  const { isCheckbox, order, orderBy, onRequestSort, headRows, rowCount, onSelectAllClick, numSelected, disabled } = props;
  const createSortHandler = property => event => onRequestSort(event, property);

  return (
    <TableHead>
      <TableRow>
        {isCheckbox &&
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'Select all' }}
          />
        </TableCell>
        }
        {headRows.map(row => {
          if (row.colspan !== 0) {
            if (row.fewLabel) {
              return row.fewLabel.map(item => (
                <HeaderTableCell
                  key={item.id}
                  row={item}
                  orderBy={orderBy}
                  createSortHandler={createSortHandler}
                  disabled={disabled}
                  order={order}
                  rowCount={rowCount}
                  isHideLabel={!!row.fewLabel && disabled} // hide 2 or more labels in a cell if labels disabled
                />
              ))
            }
            return <HeaderTableCell
              key={row.id}
              row={row}
              orderBy={orderBy}
              createSortHandler={createSortHandler}
              disabled={disabled}
              order={order}
            />
          }
          return null;
        })}
      </TableRow>
    </TableHead>
  );
}

TableHeadComponent.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number,
  numSelected: PropTypes.number,
  headRows: PropTypes.array.isRequired,
  checkbox: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default TableHeadComponent;
