import React from "react";
import InfoIcon from '@material-ui/icons/Info';
import Button from "@material-ui/core/Button";
import {ExpensesTooltips} from "./ExpensesTooltips";
import OverlayComponent from "../../components/OverlayComponent";

export const ExpensesActions = ({addNewLine, form, disabled}) => {
  return (
    <div className="cog-settings__wrap-btn settings__wrap-btn">
      <Button
        className="cog-settings__btn settings__btn"
        variant="contained"
        onClick={addNewLine}
        style={{marginRight: '5px'}}
      >
        Add new line
      </Button>
      <OverlayComponent tooltip={ExpensesTooltips.addNewLine}>
        <InfoIcon className="tooltip-info" fontSize="small"/>
      </OverlayComponent>
      <Button
        className="cog-settings__btn settings__btn"
        variant="contained"
        type="submit"
        disabled={form.isSubmitting || disabled}
        style={{marginRight: '5px', marginLeft: '20px'}}
      >
        Save Expenses
      </Button>
      <OverlayComponent tooltip={ExpensesTooltips.save}>
        <InfoIcon className="tooltip-info" fontSize="small"/>
      </OverlayComponent>
    </div>
  )
};