import React, { useState } from "react";
import TableToolbarComponent from "../../../components/TableComponent/TableToolbarComponent";
import { useCheckboxStorage, usePerPage } from "../../../../hooks/modal.hook";
import Wrapper from "../../../components/Wrapper";
import { getRecurringPageName } from "../../Transaction/utils";
import RecurringTransactionsTable from "./RecurringTransactionsTable";
import TableComponent from "../../../components/TableComponent/TableComponent";
import { headRowsRecurred, headRowsRecurring, items, itemsDetails, itemsTemplate } from "./recurringElements";
import DetailsTemplateTable from "./DetailsTemplateTable";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";

function RecurringTransactions() {
  const history = useHistory();
  const pathName = history.location.pathname;
  const isDetails = getRecurringPageName(pathName) === "details";
  const [activeColumns, setActiveColumns] = useCheckboxStorage("recurringTrans", headRowsRecurring);
  const [detailsActiveColumns, setDetailsActiveColumns] = useCheckboxStorage("recurredTrans", headRowsRecurred);
  const [rowsPerPage, onPerPage] = usePerPage();
  // eslint-disable-next-line
  const [searchQuery, setSearchQuery] = useState(null);

  let initialData = {};

  const onRefresh = () => console.log("refresh");
  const onExport = () => console.log("export");

  const title = isDetails ? "Recurred Transactions" : "Recurring Transactions";

  return <Wrapper className="recurring">
    {isDetails &&
    <>
      <Typography variant="h6" className="recurring__header">Recurring Transaction</Typography>
      <div className="recurring__details">
        <DetailsTemplateTable title="Details" initialData={initialData} items={itemsDetails}/>
        <DetailsTemplateTable title="Template" initialData={initialData} items={itemsTemplate}/>
      </div>
    </>
    }
    <TableToolbarComponent
      title={title}
      setSearchQuery={setSearchQuery}
      actionsHeader = {{
        onRefresh,
        onExport,
        selectMenu: { setFunc: onPerPage, type: "select" },
        viewColumnMenu: {
          array:  isDetails ? detailsActiveColumns : activeColumns,
          type: "filter",
          setFunc: isDetails ? setDetailsActiveColumns : setActiveColumns,
          disabled: true
        },
      }}
    />
    {isDetails
      ? <TableComponent
        headRows={detailsActiveColumns.filter(column => column.isActive)}
        items={items}
        rowsPerPageSelected={rowsPerPage}
      />
      : <RecurringTransactionsTable rowsPerPage={rowsPerPage} activeColumns={activeColumns} items={items} />
    }
  </Wrapper>
}

// RecurringTransactions.propTypes = {
//   confirm: PropTypes.func.isRequired,
// };

export default RecurringTransactions;
