import React from 'react';
import {Typography} from '@material-ui/core';

export const ExpensesTooltips = {
  name: <Typography>Here you can enter the name of the expense or the supplier</Typography>,
  category_id:
    <Typography>
      We have pre-populated a list of categories that match the most popular online bookkeeping softwares.
    </Typography>,
  calculation_method: <>
    <Typography variant="body1">
      <Typography variant="caption" className="under-line">Accrual</Typography>
      This means the transaction is accounted for from the date on the invoice
      and not the date you actually pay the invoice. For example you purchase
      a new printer and the invoice states 30th March and you pay when you
      collect on the 2nd April this would be accounted for in the March
      figures with a date of 30th March.
    </Typography>
    <Typography variant="body1">
      <Typography variant="caption" className="under-line">Cash Accounting</Typography>
      This means the transaction is accounted for when you actually pay
      and not the date on the invoice, so in the example above this would
      be accounted for in April with a date of 2nd April and not in March.
    </Typography>
    <Typography variant="body1">
      <Typography variant="caption" className="under-line">Balanced</Typography>
      We have added a balanced section into the expenses in case you want
      to ‘even out’ an expense over each day. For example you may buy 10,000
      envelopes in one purchase but you use these over the whole year, therefore
      you can add the expense at (lets say) £730.00 and SKUify would add £2.00 every day.
    </Typography>
  </>,
  interval:
    <Typography>
      You can set this to how often you want the expense to be automatically
      entered into your figures. Most people would just use monthly here
      if you are paying for a subscription monthly.
    </Typography>,
  date_start:
    <Typography>
      This is the first date you want the transaction to start from.
      This can be any date in the past, but remember it is always
      best practice to start this from when your first Amazon transaction
      shows in SKUify. We allow you to go back 2 years for Amazon Transactions.
    </Typography>,
  date_end:
    <Typography>
      You can enter an end date here so if you are paying an expense over
      6 months you do not need to remember to come back and end the expense,
      SKUify will automatically stop the recurring expense on your selected date.
    </Typography>,
  amount: <Typography>This is the amount you paid for the expense.</Typography>,
  vat:
    <Typography>
      If you did not pay VAT (the company issuing the invoice is not registered for VAT)
      then you simply add the total amount paid, like £130.00 If you did pay VAT
      you click Yes to VAT Paid and enter the amount net of VAT so this would be
      like entering £130.00 if the total you paid was £156.00
    </Typography>,
  product_vat:
    <Typography>
      In the UK we have 4 main VAT rates (generally) 20%, 5% 0% and Exempt.
      We mainly deal with 20%, 5% & 0% You need to select the correct VAT rate
      that is shown on the invoice/receipt issued by your supplier.
      You do have the ability to choose “other” and enter your own rate here.
      This would not happen very often but we have seen VAT rates adjusted
      in times just recently to help the hospitality industry.
      If you are unsure of the VAT charged by your supplier
      please ask them to clarify this for you.
    </Typography>,
  more:
    <Typography>
      Within this tab you can click through to see a full list of all transactions
      that SKUify has added based on your settings for each expense.
      You can also create a credit against the expense, for example if a service
      provider/supplier wanted to give you a free month because for one reason
      or another you can credit the whole amount from your list here.
      Also if for one month you pay less than the ‘normal amount’ you
      can edit the actual entry. Over time this section will expand so
      you can keep track of the supplier and your history with them.
    </Typography>,
  delete:
    <Typography>
      In Accounting we do not like delete buttons, but we have added one here if you
      need to delete the whole transaction line and history. Please be warned this
      deletes everything associated with the expense line. There is a ‘Are You Sure’
      button after you click delete just to stop errors.
    </Typography>,
  addNewLine: <Typography>Use this button when you want to add a new expense line.</Typography>,
  save:
    <Typography>
      This is to save the expense lines, we recommend you save them quite often
      and do not try to create a list of expenses before saving as the processing
      time by the servers and your internet connection may delay feedback
      on successful creation.
    </Typography>,
}
