import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import api from "../../crud/user.crud";
import {createSlice} from "@reduxjs/toolkit";

const company = createSlice({
    name: 'group',
    initialState: {
      loading: true,
      groups: [],
    },
    reducers: {
      stopLoading: (state, actions) => {
        state.loading = false;
        return state;
      },
      getGroups: (state, actions) => {
        state.loading = true;
        return state;
      },
      getGroupsSuccess: (state, actions) => {
        const {groups} = actions.payload;
        state.groups = groups;
        state.loading = false;
        return state;
      },
      addGroup: (state, actions) => {
        state.loading = true;
        return state;
      },
      addGroupSuccess: (state, actions) => {
        const {group} = actions.payload;
        state.groups = [...state.groups, group];
        state.loading = false;
        return state;
      },
      deleteGroup: (state, actions) => {
        state.loading = true;
        return state;
      },
      deleteGroupSuccess: (state, actions) => {
        const {id} = actions.payload;
        state.groups = state.groups.filter((group) => (group.id !== id));
        state.loading = false;
        return state;
      },
      updateGroup: (state, actions) => {
        state.loading = true;
        return state;
      },
      updateGroupSuccess: (state, actions) => {
        state.groups = state.groups.map((group) => {
          if (group.id === actions.payload.id) {
            return actions.payload.group;
          }
          return group
        });
        state.loading = false;
        return state;
      },
    },
  }
);

export const actions = company.actions;
export const reducer = company.reducer;

export function* saga() {
  yield takeLatest(actions.getGroups, function* getGroupSaga() {
    try {
      const { data } = yield call(api.getGroups);
      data
        ? yield put(actions.getGroupsSuccess({ groups: data }))
        : yield put(actions.stopLoading());
    } catch (err) {
      yield put(actions.stopLoading())
      console.log(err);
    }
  });

  yield takeLatest(actions.addGroup, function* addGroupSaga({ payload: { values, onDone } }) {
    try {
      const { data } = yield call(api.addGroup, values);
      if (data) {
        yield put(actions.addGroupSuccess({ group: data }));
        onDone();
      } else {
        yield put(actions.stopLoading());
      }
    } catch (err) {
      yield put(actions.stopLoading())
      console.log(err);
    }
  });

  yield takeLatest(actions.deleteGroup, function* deleteGroupSaga({ payload: { id, onDone } }) {
    try {
      const { data } = yield call(api.deleteGroup, id);
      yield put(actions.deleteGroupSuccess({ id }));
      if (onDone)
        onDone();
      yield put(actions.stopLoading())
    } catch (err) {
      yield put(actions.stopLoading())
      console.log(err);
    }
  });

  yield takeLatest(actions.updateGroup, function* updateGroupSaga({ payload: { id, values, onDone } }) {
    try {
      const { data } = yield call(api.updateGroup, id, values);
      yield put(actions.updateGroupSuccess({ group: data, id }));
      onDone();
      yield put(actions.stopLoading())
    } catch (err) {
      yield put(actions.stopLoading())
      console.log(err);
    }
  });
}
