import React, {useEffect, useState} from 'react'
import { Form as FormikForm, Formik } from 'formik'
import {Typography} from '@material-ui/core'
import useSettings from '../../../../hooks/settings.hook'
import useUsers from '../../../../hooks/users.hook'
import { onSubmit } from './utils'
import { numberWithCommas } from '../../utils'
import WLSettings from './WLSettings'
import ROISettings from './ROI/ROISettings'
import VATSettings from './VAT/VATSettings'

const FinancialSettings = () => {
  const { 
      accountSettings,
      updateAccountSetting,
      updateVATSetting,
      roiLoading,
      roiSettings,
      getROISetting,
      updateROISetting,
  } = useSettings({ fetch: false });
  const { user } = useUsers({ fetch: false })
  const [initData, setInitData] = useState({});
  const [submitCustomProps, setSubmitCustomProps] = useState({});

    useEffect(()=>{
        getROISetting()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

  useEffect(() => {
    setInitData({
      ...initData,
      profit_winner: numberWithCommas(accountSettings.profit_winner),
      profit_looser: numberWithCommas(accountSettings.profit_looser),
      roi_method: roiSettings.roi_method || 'roi1',
    });
  }, [roiSettings.roi_method, accountSettings.profit_winner, accountSettings.profit_looser]);

  const submitProps = {
    initData,
    updateAccountSetting,
    updateVATSetting,
    updateROISetting,
    userId: user.id,
    ...submitCustomProps
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initData}
      onSubmit={onSubmit(submitProps)}
    >
      {({ ...form }) => {
        return (
          <div className="settings-main__vat settings-main__default">
            <Typography variant="h5" color="primary" className="settings__title">Financial Settings</Typography>

            <VATSettings
              form={form}
              initData={initData}
              setInitData={setInitData}
              setSubmitCustomProps={setSubmitCustomProps}
            />

            <FormikForm>

              {/*ROI Section*/}
               <ROISettings
                  loading={roiLoading}
                  roiSettings={roiSettings}
                  form={form}
                  setSubmitCustomProps={setSubmitCustomProps}
                  initData={initData}
               />

              <WLSettings
                currency={user.currency}
                form={form}
                setSubmitCustomProps={setSubmitCustomProps}
              />
            </FormikForm>
          </div>
        )
      }}
    </Formik>
  )
}

export default FinancialSettings
