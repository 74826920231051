import axios from "axios";
import { requests } from "./utils";
export const LOGIN_URL = "/users/token";
export const ME_URL = "/users/me";

export const REGISTER_URL = "/users/register";
export const REQUEST_PASSWORD_URL = "/password_reset/";
export const RESET_PASSWORD_URL = "/password_reset/confirm/";

const API_ROOT = process.env.REACT_APP_API_ENDPOINT;
export function login(email, password) {
  return requests.post(LOGIN_URL, { username: email, password });
}

export function register(data) {
  return requests.post(REGISTER_URL, data);
}

export function requestPassword(email) {
  return axios.post(`${API_ROOT}${REQUEST_PASSWORD_URL}`, { email });
}

export function resetPassword(data) {
  return axios.post(`${API_ROOT}${RESET_PASSWORD_URL}`, data);
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return requests.get(ME_URL);
}

export function saveSettings(data) {
  // Authorization head should be fulfilled in interceptor.
  return requests.post('/users/settings', data);
}

export function finishSetup(data) {
  return requests.post('/users/finish_setup', data);
}
