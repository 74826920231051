import { useDispatch, useSelector } from 'react-redux'
import { actions } from '../store/ducks/reports.duck.js'
import * as ReportsSelector from '../selectors/reports.selectors'
import toastr from "../pages/components/toast/toast";

const onErrorReport = (e) => toastr.error(e, {duration: 5000}, {isClose: false})

const useReports = () => {
  const dispatch = useDispatch()

  const getSalesReports = (params) => dispatch(actions.getSalesReports(params))
  const salesReports = ReportsSelector.getSalesReports(useSelector(state => state))

  const getOrderReport = (params) => dispatch(actions.getOrderReport({...params, onError: onErrorReport}))
  const orderReport = ReportsSelector.getOrderReport(useSelector(state => state))

  const getExpensesTransactions = (params) => dispatch(actions.getExpensesTransactions(params));
  const getExpenses = (params) => dispatch(actions.getExpenses(params))
  const expenses = ReportsSelector.getExpenses(useSelector(state => state))
  const updateExpenses = (args) => dispatch(actions.updateExpenses(args))
  const deleteExpenses = (param) => dispatch(actions.deleteExpenses(param))
  const deleteExpensesTransaction = (params) => dispatch(actions.deleteExpensesTransaction(params))
  const updateExpensesTransaction = (params) => dispatch(actions.updateExpensesTransaction(params))
  const getExpensesCategories = () => dispatch(actions.getExpensesCategories())
  const addImportsExpenses = (args) => dispatch(actions.addImportsExpenses(args))
  // const updateFewOldExpenses = (params) => dispatch(actions.updateFewOldExpenses(params))
  // const updateOldWithNewExpenses = (params) => dispatch(actions.updateOldWithNewExpenses(params))
  
  const getMainReports = (args) => dispatch(actions.getMainReports(args))
  const addNewReport = (args) => dispatch(actions.addNewReport(args))
  const deleteMainReports = (args) => dispatch(actions.deleteMainReports(args))

  const mainReports = ReportsSelector.getMainReports(useSelector(state => state))
  const expensesTransactions = ReportsSelector.getExpensesTransactions(useSelector(state => state))
  const noteImportsExpenses = ReportsSelector.addImportsExpenses(useSelector(state => state))

  return {
    salesReportsLoading: salesReports.loading,
    salesReports: salesReports.results,
    getSalesReports,

    orderReportLoading: orderReport.loading,
    orderReport: orderReport.results,
    reportId: orderReport.id,
    reportType: orderReport.type,
    getOrderReport,

    getExpenses,
    expenses: expenses.results,
    expensesLoading: expenses.loading,
    expensesCount: expenses.totalCount,
    expensesCategories: expenses.categories,
    updateExpenses,
    deleteExpenses,
    deleteExpensesTransaction,
    updateExpensesTransaction,
    getExpensesCategories,
    addImportsExpenses,
    noteImportsExpenses,
    // updateFewOldExpenses,
    // updateOldWithNewExpenses,

    getExpensesTransactions,
    expensesTransactions: expensesTransactions.results,
    expensesTransactionsLoading: expensesTransactions.loadingId,

    getMainReports,
    addNewReport,
    deleteMainReports,
    mainReports: mainReports.results,
    mainReportsLoading: mainReports.loading,
    mainReportsCount: mainReports.totalCount,
  }
}

export default useReports