import React from "react";
import {callingCode} from "../utils";
import FormComponent from "../../../components/controls/FormComponent";

export const defaultCode = {
  label: <><span className="name-country">United Kingdom</span><span>+44</span></>,
  value: "United Kingdom +44",
  country: "United Kingdom"
};


export const account = [
  {
    id: "company_name",
    label: "Company Name",
    type: "text",
  },
  {
    id: "company_email",
    label: "Company Email",
    type: "email",
  },
  {
    id: "company_website",
    label: "Website",
    type: "text",
  },
  {
    id: "address_1",
    label: "Address line 1",
    type: "text",
  },
  {
    id: "address_2",
    label: "Address line 2",
    type: "text",
  },
  {
    id: "town_city",
    label: "Town/City",
    type: "text",
  },
  {
    id: "post_code",
    label: "Post/Zip Code",
    type: "text",
  },
  // {
  //   id: "post_code",
  //   label: "Post/Zip Code",
  //   type: "text",
  // },
  {
    id: "company_phone",
    label: "Company Phone",
    type: "phone",
    render: ({customProps, ...props}: any) => {
      const {values} = props;
      let phoneCode = defaultCode;
      if (values.phone_code) {
        phoneCode = callingCode.find(({ country }) => country === values.phone_code) || defaultCode;
      }
      return (
        <FormComponent customProps={{ defaultCode: phoneCode }} {...props} />
      )
    }
  },
];