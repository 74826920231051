import React, {useEffect, useState} from "react";
import TableComponent from "../../components/TableComponent/TableComponent";
import { headRows } from "./headRows";
import getSymbolFromCurrency from "currency-symbol-map";
import { floatNumber, formatNumber } from "../utils";
import { onShowNotifications, updateMarketplace } from "./utils";
import toast from "../../components/toast/toast";
import {toAbsoluteUrl} from "../../../../_metronic";

const PPPTable = (props) => {
  const {
    pppData,
    strategies,
    credentials,
    enabledMarketplaces,
    getParams,
    isResetPagination,
    setResetPagination
  } = props;
  const { totalSKUs, Products = [], defaultMarketplace } = pppData;

  const [related, setRelated] = useState(null);

  const [dataTable, setDataTable] = useState([]);
  const [fieldValues, setFieldValue] = useState({});
  const [idCurrentRow, setIdCurrentRow] = useState({});

  let strategiesList = strategies.Strategies;

  let strategiesOptions = [];

  if (strategiesList) {
    strategiesOptions = strategiesList.map(({ StrategyName, StrategyID}) => ({ label: StrategyName, value: StrategyID }));
  }

  const items = Products.filter(product => product.Marketplaces).map(({ Marketplaces, ...product }) => {
    const _Marketplaces = Marketplaces.map(marketplace => {
      let currency;
      // marketplace.TurnedOn = !!(Number(marketplace.TurnedOn)) // "0" => false
      if (enabledMarketplaces.length) {
        const marketplaceDetails = enabledMarketplaces &&
          enabledMarketplaces.find(item => item.MarketplaceID === marketplace.MarketplaceID);
          currency = marketplaceDetails && getSymbolFromCurrency(marketplaceDetails.CurrencyCode);
      }
      return {...marketplace, currency}
    });

    let parentRow = {};
    const defProduct = _Marketplaces.find(({ MarketplaceID }) => MarketplaceID === defaultMarketplace);
    if (defProduct) {
      parentRow = defProduct;
    } else {
      parentRow = _Marketplaces[0];
    }

    const related = _Marketplaces.filter(({ MarketplaceID }) => MarketplaceID !== parentRow.MarketplaceID)
      .map(product => ({...product, id: product.RowID, marketplaces: Marketplaces}));

    return {...parentRow, ...product, marketplaces: Marketplaces, id: parentRow.RowID, related: related || []}
  });

  useEffect(() => {
    if (related) {
      setRelated(items.find(product => product.id === related.id ))
    }
  }, []);

  const onShowRelated = (row) => () => {
    setRelated(related && row.id === related.id ? null : row);
  };

  const keys = ["BreakEvenPrice", "MinPrice", "MaxPrice", "TurnedOn", "IsFavourite", "StrategyID", "IsFavourite"];

  useEffect(() => {
    let data = [];
    items.forEach((product) => {
      data.push(product);
      if (related?.id === product.id) {
        related.related.forEach((item) => {
          const marketplaceData = {...item};
          marketplaceData.relatedProduct = true;
          data.push(marketplaceData);
        })
      }
    });
    setDataTable(data);

    let fields = {};
    data.forEach(product => {
      let { id } = product;
      Object.entries(product).forEach(([key, value]) => {
        if (keys.includes(key)) {
          fields = {...fields, [`${key}--${id}`]: value}
        }
      })
    });
    setFieldValue(fields);
  }, [pppData, related]);

  const onRefresh = (id, value) => {
    const updateData = dataTable.map((product) => {
      if (product.id === id) {
        return { ...product, ...value }
      }
      return product;
    });
    setDataTable(updateData);
  };

  const onChange = (row, name) => (event) => {
    const { id } = row;
    let value = event.target.value;
    let prev = fieldValues[`${name}--${id}`];
    let result = formatNumber(value, prev);
    setFieldValue({...fieldValues, [`${name}--${id}`]: result});
  };

  const handleBlur = (row, name) => (e) => {
    const { MarketplaceID, id } = row;

    let value = e.target.value;
    if ((value.indexOf(".") > value.length - 3 && value % 1 !== 0) || value === "0") {
      value = parseFloat(value).toFixed(2);
    }

    setFieldValue({...fieldValues, [`${name}--${id}`]: value});

    let data = {...fieldValues, [`${name}--${id}`]: value};

    const currentRowValues = Object.entries(data).filter(([name]) => name.includes(id));

    const rowData = currentRowValues.map(([name, value]) =>
      [name.slice(0, name.indexOf(id) - 2), value]
    );
    const dataRow = Object.fromEntries(rowData);
    const { TurnedOn, IsFavourite, StrategyID, MinPrice, MaxPrice, BreakEvenPrice } = dataRow;

    let marketplaceValues = {
      MarketplaceID,
      IsFavourite,
      StrategyID,
      TurnedOn,
      BreakEvenPrice,
      MinPrice,
      MaxPrice
    };

    let minN = typeof MinPrice === "number" ? MinPrice : floatNumber(MinPrice);
    let maxN = typeof MaxPrice === "number" ? MaxPrice : floatNumber(MaxPrice);

    if (name === "MinPrice" || name === "MaxPrice") {
      if (name === "MinPrice" ) {
        if (!!minN && minN > 0) {
          if (minN >= maxN ) {
            toast.warning(`Please, update Max Price!`);
            setFieldValue({...fieldValues, [`MaxPrice--${id}`]: "0.00"});
            const { MaxPrice, ...values } = marketplaceValues;
            marketplaceValues = { ...values, MinPrice: minN }
          } else {
            marketplaceValues = { ...marketplaceValues, MinPrice: minN, MaxPrice: maxN }
          }
        } else {
          setFieldValue({...fieldValues, [`MinPrice--${id}`]: "0.00" }); // floatNumber(row.MaxPrice)
          toast.error(`Wrong ${name.split(/(?=[A-Z])/)} value`);
        }
      }

      if (name === "MaxPrice") {
        if (!!maxN && !!minN && maxN > minN) {
          marketplaceValues = { ...marketplaceValues, MinPrice: minN, MaxPrice: maxN }
        } else {
          const { MaxPrice, MinPrice, ...values } = marketplaceValues;
          setFieldValue({...fieldValues, [`MaxPrice--${id}`]: 0 }); //floatNumber(row.MinPrice)
          marketplaceValues = { ...values };
          toast.error(`Wrong ${name.split(/(?=[A-Z])/).join(' ')} value`);
        }
      }
    }

    let isUpdate = (!!marketplaceValues.MinPrice && !!marketplaceValues.MaxPrice) ||
      (!marketplaceValues.MinPrice && !marketplaceValues.MaxPrice);

    if (isUpdate) {
      updateMarketplace({
        value: {...marketplaceValues },
        ...credentials,
        row,
        updateTable: onShowNotifications({ setIdCurrentRow, marketplaceValues, id, name })
      })
    }
  };

  return (
    <TableComponent
      className="products__table ppp__table dashboard__tab__content"
      headRows={headRows}
      items={dataTable}
      count={totalSKUs}
      loading={pppData?.isSuccess}
      rowProps={{
        onShowRelated,
        credentials,
        defaultMarketplace,
        strategiesOptions,
        onRefresh,
        fieldValues,
        setFieldValue,
        onChange,
        handleBlur,
        idCurrentRow
      }}
      isResetPagination={isResetPagination}
      setResetPagination={setResetPagination}
      getParams={getParams}
      defaultOrderBy="item_name"
      extraPagination={(
        <div className="powered">
          <p className="text">Powered by</p>
          <img
            className="logo"
            alt="Maximizing Seller Profits logo"
            src={toAbsoluteUrl("/media/logos/PPPLogoBLUETransparent.png")}
          />
        </div>
      )}    />
  )
};

export default PPPTable;