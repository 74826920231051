import React from "react";
import { getTooltip } from "./utils";
import LabelNameCell from "./LabelNameCell";

const VatRateTrigger = (props) => {
  const { disabled, vat_validated, row, vat } = props;
  const validated = row?.vat_validated || vat_validated;
  return (
    <LabelNameCell
      {...props}
      tooltip={!disabled ? getTooltip(validated) : ""}
      content={`${vat}% VAT`}
      validated={validated}
    />
  );
};

export default VatRateTrigger;