import React from 'react'
import classNames from 'clsx'
import {toAbsoluteUrl} from "../../../../../../_metronic";

const lines = new Array(8).fill({
    src: "media/codely-theme-files/assets/images/anim_line.png",
    alt: "anim_line"
});

function Main({className, img}) {
    return (
        <>
            <div className={classNames(className ? className : "anim_line dark_bg")}>
                {lines.map(({ src, alt }, index) => (
                  <span key={index}>
                      <img src={toAbsoluteUrl(img ? img : src)} alt={alt} />
                  </span>
                ))}
            </div>

        </>
    )
}

export default Main
