import React, {} from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment'
import {ReactComponent as CalendarIcon} from "../../../media/icons/calendar-2.svg";
import {momentFormatOptions} from "../utils";

const RangeDatePicker = ({setParams}) => {
    
    
    const sendData = (event, picker) => {
        
        let date = {
            start_date: picker.startDate.format('L'),
            end_date: picker.endDate.format('L')
        }
        setParams(date)
    }
    
    const resetData = (event, picker) => {
        picker.setStartDate(moment())
        picker.setEndDate(moment())
        let date = {
            start_date: null,
            end_date: null
        }
        setParams(date)
    }
    

    return (
        <>
        <DateRangePicker
            onApply={sendData}
            onCancel={resetData}
            initialSettings={{
                locale: { format: momentFormatOptions.display},
            }}
        >
            <div className='date_picker_container sales'>
                <CalendarIcon className='date_picker_arrow' />
            </div>
        </DateRangePicker>

        </>
    )
}

export default RangeDatePicker