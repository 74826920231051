import React, {useState} from 'react';
import TableCell from "@material-ui/core/TableCell";
import classNames from "clsx";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {Fade, Paper, Popper} from "@material-ui/core";
import {floatNumber} from '../utils';
import "react-bootstrap/cjs/divWithClassName";
import {toAbsoluteUrl} from "../../../../_metronic";
import {EditForm} from "./conponents/EditForm";
import {Info} from "./conponents/Info";
import {getInitialValues} from "./utils";
import useAsin from "../../../hooks/asin.hook";

const Cell = ({handleClick, active, additionalIcon, quantity, warning, icon, requireQuantity}) => {
  const displayQuantity = quantity || (requireQuantity ? '?' : '-')
  const disableQuantity = (!quantity || quantity.length) && additionalIcon !== "requireAction";
  return (
    <div className={disableQuantity ? "quantity-cell cell-disable" : "quantity-cell"} onClick={handleClick}>
      <div style={{position: 'relative'}}>
        <img alt="img" src={toAbsoluteUrl(icon)}/>
      </div>
      <div className={classNames({warning: warning})}>
        {displayQuantity}
      </div>
    </div>
  );
};

const ContentPopper = props => {
  const {
    additionalIcon,
    initialValues,
    formFields,
    close,
    infoHeadRows,
    infoTitle,
    onSubmit,
    product,
    displayData
  } = props;

  if (additionalIcon === 'requireAction') {
    return (
      <EditForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        formFields={formFields}
        close={close}
      />
    )
  }
  return <Info
    product={product}
    headRows={infoHeadRows}
    title={infoTitle}
    displayData={displayData}
  />
};

const renderPopper = (props) => {
  const {poppedId, open, close, anchorEl, placement, content} = props;
  return (
    <Popper
      id={poppedId}
      open={open}
      anchorEl={anchorEl}
      transition
      className="tab-table__note__root journey"
      placement={placement}
    >
      {({ TransitionProps }) => (
        <ClickAwayListener onClickAway={close}>
          <Fade {...TransitionProps} timeout={350}>
            <Paper className="tab-table__note__paper">
              {content}
            </Paper>
          </Fade>
        </ClickAwayListener>
      )}
    </Popper>
  );
}

const QuantityCell = (props) => {
  const {
    active,
    icon,
    warning,
    quantity,
    additionalIcon,
    formFields = [],
    id,
    jorneyId,
    isShipment,
    requireQuantity = false,
    disableTooltip = false,
    buttons = [],
    stage,
    product,
    info = [],
    infoHeadRows,
    infoTitle,
    displayData
  } = props;

  const {updateAsinHistory} = useAsin();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => setAnchorEl(anchorEl ? null : !disableTooltip && event.currentTarget);
  const close = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const poppedId = open ? 'simple-popper' : undefined;
  const placement = open ? 'bottom' : undefined;


  const onSubmit = values => {
    const data = {};
    Object.entries(values).forEach(([k, v]) => data[k] = floatNumber(v));

    const final = {[stage]: data,}

    updateAsinHistory({jorneyId, shipmentId: id, values: final});
    close();
  };
  const contentProps = {
    additionalIcon,
    initialValues: getInitialValues({formFields, data: displayData || info}),
    onSubmit,
    formFields,
    close,
    product,
    infoHeadRows,
    infoTitle,
    buttons,
    displayData
  };

  return (
    <TableCell align="justify" padding="none">
      {
        !isShipment && (
          <>
            <Cell
              handleClick={handleClick}
              active={active}
              additionalIcon={additionalIcon}
              quantity={quantity}
              warning={warning}
              icon={icon}
              requireQuantity={requireQuantity}
            />
            {renderPopper({
              poppedId,
              open,
              close,
              anchorEl,
              placement,
              content: <ContentPopper {...contentProps} />
            })}
          </>
        )
      }
    </TableCell>
  );
}

export default QuantityCell;