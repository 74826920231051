import React from "react";
import * as PropTypes from "prop-types";
import classNames from "clsx";

export const Help = ({ data, className }) => {
  let value = [];
  if (typeof data === "object") {
    value = Object.values(data);
  } else {
    value = data;
  }

  return (
    <div className={"table__cell__help " + className}>
      {value.map(({ url, name, icon, small }) => {
        // const small = ["FBA", "MF Prime"].find(item => item === name);
        return (
          <div key={name} className={"help__item " + name}>
            <div className="help__item__wrap-icon">
              {url
                ? <img
                  alt="img"
                  className={classNames("table__cell__img--icon", {"icon--small": small})}
                  src={url}
                />
                : icon
              }
            </div>
            <span>{name}</span>
          </div>
        )
      })}
    </div>
  )
};

Help.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default Help;