import { useDispatch } from "react-redux";
import * as thankDuck from "../store/ducks/thank.duck";

const useThank = () => {
  const dispatch = useDispatch();

  const addThankData = (params) => {
    dispatch(thankDuck.actions.addThankData(params));
  };

  return {
    addThankData,
  };
};

export default useThank;