import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import "./style-books.scss"
import {connect} from "react-redux";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import {companyFieldsBusinessInfo} from "./fields-business-info";
import {companyFieldsBusinessAddress} from "./fields-business-address";
import {companyFieldsFinancialInfo} from "./fields-financial-info";
import OfficersTable from "./OfficersSettings/OfficersTable";
import * as companyDuck from "../../../store/ducks/company.duck";
import UserSettings from './UsersSettings/UsersSettings';
import UsersPermissions from './UsersSettings/UsersPermissions';
import useTab from "../../../hooks/tab.hook";
import SettingForm from "./SettingsForm";
import * as Selector from '../../../selectors/company.selectors';
import {getCurrentCompanyId} from "../../../crud/utils";
import DButton from "../../components/Button";
import {useModal} from "../../../hooks/modal.hook";
import VATSchemesModal from "./VATSchemesModal";
import Wrapper from "../../components/Wrapper";
import CreateEditVATScheme from "./CreateEditVATScheme";
import NotificationForm from "../../components/NotificationForm";
import VATDeRegistrationModal from "./VATDeRegistrationModal";

function TabContainer(props) {
  return (
    <Typography component="div" className="books__tab">
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

function BooksSettings(props) {
  const {
    updateCompany,
    getCompany,
    company,
    getVATSchemes,
    VATSchemes,
    deleteVATScheme,
    updateVATScheme,
    addVATScheme,
  } = props;

  const [isShowModal, toggleShowModal] = useModal();
  const [isShowCreateEditModal, toggleShowCreateEditModal] = useModal();
  const [currentRow, setCurrentRow] = useState(null);
  const [showNotification, onShowNotification] = useState(false);
  const [isShowDeRegisterModal, toggleDeRegisterModal] = useModal(false);

  useEffect(() => {
    getCompany({id: getCurrentCompanyId()});
    getVATSchemes();
  }, []);

  const onBackCreateEditModal = () => {
    toggleShowCreateEditModal();
    setCurrentRow(null);
    getVATSchemes();
    toggleShowModal();
  };

  const onNotification = () => onShowNotification(true);

  const [currentTab, setTab] = useTab('business_info');
  const onCurrentRow = (row) => {
    setCurrentRow(row)
  };

  const formProps = {
    updateCompany: updateCompany,
    data: company,
  };

  const formButtons = <div className="container-button container-button--modal container-button--settings-books">
    <DButton onClickCustom={toggleShowModal}>VAT Schemes</DButton>
    <DButton onClickCustom={toggleDeRegisterModal}>De-register for VAT</DButton>
  </div>;

  return (
    <Wrapper className="books__wrap">
      <AppBar position="static">
        <Tabs
          value={currentTab}
          onChange={setTab}
          variant="scrollable"
          scrollButtons="auto">

          <Tab value="business_info" label="Business Info" wrapped/>
          <Tab value="business_address" label="Business Address"/>
          <Tab value="financial_info" label="Financial Info"/>
          <Tab value="officers" label="Officers"/>
          <Tab value="users" label="Users"/>
          <Tab value="user_permissions" label="User Permissions"/>

        </Tabs>
      </AppBar>

      {currentTab === "business_info" && <TabContainer>
        {/*TODO add validation of entered data (start date can't be after end date, and so on )*/}
        <SettingForm fields={companyFieldsBusinessInfo} {...formProps} onNotification={onNotification} /></TabContainer>}
      {currentTab === "business_address" && <TabContainer>
        <SettingForm fields={companyFieldsBusinessAddress} {...formProps} onNotification={onNotification} /></TabContainer>}
      {currentTab === "financial_info" && <TabContainer>
        <SettingForm
          fields={companyFieldsFinancialInfo}
          formButtons={formButtons}
          {...formProps}
          onNotification={onNotification} />
      </TabContainer>}

      {currentTab === "officers" && <TabContainer> <OfficersTable/> </TabContainer>}
      {currentTab === "users" && <TabContainer><UserSettings/></TabContainer>}
      {currentTab === "user_permissions" && <TabContainer><UsersPermissions/></TabContainer>}

      <NotificationForm open={showNotification} onClose={() => onShowNotification(false)}/>

      <VATSchemesModal
        show={isShowModal}
        onClose={toggleShowModal}
        deleteVATScheme={deleteVATScheme}
        data={VATSchemes}
        toggle={toggleShowCreateEditModal}
        onCurrentRow={onCurrentRow} />

      <CreateEditVATScheme
        show={isShowCreateEditModal}
        onClose={onBackCreateEditModal}
        updateVATScheme={updateVATScheme}
        addVATScheme={addVATScheme}
        initValues={currentRow} />

        <VATDeRegistrationModal
        show={isShowDeRegisterModal}
        onClose={toggleDeRegisterModal} />
    </Wrapper>
  );
}

BooksSettings.propTypes = {
  updateCompany: PropTypes.func.isRequired,
  getCompany: PropTypes.func.isRequired,
  getVATSchemes: PropTypes.func,
  addVATScheme: PropTypes.func,
  updateVATScheme: PropTypes.func,
  deleteVATScheme: PropTypes.func,
  company: PropTypes.object,
  VATScheme: PropTypes.object,
  // VATSchemes: PropTypes.array,
};

const mapStateToProps = (state, actions) => {
  return {
    loading: Selector.getLoading(state),
    company: Selector.getCompany(state),
    VATSchemes: Selector.getVATSchemes(state),
  }
};

const mapDispatchToProps = {
  updateCompany: companyDuck.actions.updateCompany,
  getCompany: companyDuck.actions.getCompany,
  getVATSchemes: companyDuck.actions.getVATSchemes,
  addVATScheme: companyDuck.actions.addVATScheme,
  updateVATScheme: companyDuck.actions.updateVATScheme,
  deleteVATScheme: companyDuck.actions.deleteVATScheme,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BooksSettings)
