import React, {useEffect, useState} from 'react';
import TableComponent from "../../../components/TableComponent/TableComponent";
import AssignmentIcon from '@material-ui/icons/Assignment';
import EditIcon from '@material-ui/icons/Edit';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from "@material-ui/core/Typography";
import {useHistory} from "react-router-dom";
import withConfirmHook from "../../../../hooks/withConfirm.hook";
import {goToAutoConfirmPage, goToEditBankRulePage, goToNewRulesPage} from "./utils";
import TableCell from "@material-ui/core/TableCell";
import Wrapper from "../../../components/Wrapper";
import DButton from "../../../components/Button";
import {connect} from "react-redux";
import * as Selector from "../../../../selectors/bank.selectors";
import * as categoryDuck from "../../../../store/ducks/category.duck";
import * as BankDuck from "../../../../store/ducks/bunk.duck";
import * as PropTypes from "prop-types";
import NotificationForm from "../../../components/NotificationForm";
import {goToEnterTransactionPage} from "../../Company/utils";

const titleCol = [
  {
    id: 'name', numeric: false,
    disablePadding: true,
    label: "Name",
    img: <EditIcon className='header-icon'/>,
    render: (row, {onNameClick}) => {
      return <TableCell align="left" key={`${row.id}--name`} onClick={onNameClick(row)} className="table__cell--link">
        <span className="table__cell--link__text">{row.name}</span>
      </TableCell>
    }
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: true,
    label: "Description",
    img: <AssignmentIcon className='header-icon'/>
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: true,
    label: "Actions",
  },
];

const BankRules = ({ confirm, bankRules, getBankRules, deleteBankRule }) => {
  const [showNotification, onShowNotification] = useState(false);

  useEffect(() => {
    getBankRules()
  }, []);

  let history = useHistory();
  const onEdit = (row) => () => {
    goToEditBankRulePage(history, row.id);
  };

  const onNewRules = () => {
    goToNewRulesPage(history);
  };

  const onAutoConfirm = (row) => ()=> {
    goToAutoConfirmPage(history, row.id)
  };

  const onDelete = (row) => (
    confirm(() => {
        deleteBankRule({
          id: row.id,
        })
      },
      {
        description: 'This action is permanent!'
      })
  );
  const onNameClick = (row) => () => {
    goToEditBankRulePage(history, row.id);
  };
  const onBack = () => {
    goToEnterTransactionPage(history);
  };

  const onNotification = () => onShowNotification(true);

  return (
    <Wrapper>
      <div className="bank-rules">
        <Typography gutterBottom variant="h5" component="h2" className="bank-rules__header">
          <MenuIcon/> Bank Rules
          <Typography component="p">Here you can create and manage your bank rules</Typography>
        </Typography>
        <TableComponent
          headRows={titleCol}
          items={bankRules}
          rowProps={{
            onEdit,
            onDelete,
            onNameClick,
            // onAutoConfirm
          }}
        />
        <div className="container-button container-button--modal">
          <DButton typeOfButton="backPrimary" onClickCustom={onBack}>Back</DButton>
          <DButton typeOfButton="add" onClickCustom={onNewRules}>New Rule</DButton>
        </div>
      </div>
      {/*TODO show success notification */}
      <NotificationForm open={showNotification} onClose={() => onShowNotification(false)}/>
    </Wrapper>
  )
};

BankRules.propTypes = {
  getBankRules: PropTypes.func.isRequired,
  deleteBankRule: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  bankRules: PropTypes.array.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    bankRules: Selector.getBankRules(state, props),
  }
};

const mapDispatchToProps = {
  getCategories: categoryDuck.actions.getCategories,
  getBankRules: BankDuck.actions.getBankRules,
  deleteBankRule: BankDuck.actions.deleteBankRule,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withConfirmHook(BankRules));
