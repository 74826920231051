import {
  BALANCE_SHEET,
  PROFIT_AND_LOSS, TRIAL_BALANCE,
} from "../../../router/routesMap";

export const goToProfitLossPage = (history) => {
  history.push(PROFIT_AND_LOSS);
};

export const goToBalanceSheetPage = (history) => {
  history.push(BALANCE_SHEET);
};

export const goToTrialBalancePage = (history) => {
  history.push(TRIAL_BALANCE);
};

export const getReportsPageName = (pathname) => {
  switch (pathname) {
    case PROFIT_AND_LOSS:
      return "profit and loss";
    case BALANCE_SHEET:
      return "balance sheet";
    case TRIAL_BALANCE:
      return "trial balance";
    default:
          return "";
  }
};