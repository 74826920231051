/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {connect} from "react-redux";
import {withRouter, Link} from "react-router-dom";
import objectPath from "object-path";
import {LayoutContextConsumer} from "../LayoutContext";
import {ReactComponent as ExpandIcon} from "../../../_metronic/layout/assets/layout-svg-icons/Expand.svg";
import * as builder from "../../ducks/builder";
import Dropdown from "react-bootstrap/Dropdown";
import {BALANCE_SHEET, BANK_RULES, PROFIT_AND_LOSS, TRIAL_BALANCE} from "../../../app/router/routesMap";
import * as companySelectors from "../../../app/selectors/company.selectors";

// import BreadCrumbs from "./components/BreadCrumbs";

class SubHeader extends React.Component {
  render() {
    const {subheaderCssClasses, subheaderContainerCssClasses, subheaderMobileToggle, toggleWidth, company} = this.props;
    return (
      <>
        <div className={`kt-subheader kt-grid__item ${subheaderCssClasses}`} id="kt_subheader">
          <div className={`kt-container ${subheaderContainerCssClasses}`}>
            {/*Subheader Main*/}
            <div className="kt-subheader__main">
              {subheaderMobileToggle && (
                <button
                  className="kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left"
                  id="kt_subheader_mobile_toggle"
                >
                  <span />
                </button>
              )}

              <LayoutContextConsumer>
                {/*{({ subheader: { title, breadcrumb } }) => (*/}
                {({subheader: {title}}) => (
                  <>
                    <h3 className="kt-subheader__title">{title}</h3>
                    {/*<BreadCrumbs items={breadcrumb} />*/}
                  </>
                )}
              </LayoutContextConsumer>
              <span className="kt-subheader__separator kt-subheader__separator--v"/>
              <Link to="/books/enter-transaction" className="btn btn-label-primary btn-bold btn-icon-h">
                Enter Transaction
              </Link>
              <Link to="/books/categories" className="btn btn-label-primary btn-bold btn-icon-h">
                Categories
              </Link>

              <Link to="/books/transactions" className="btn btn-label-primary btn-bold btn-icon-h">
                View Transaction
              </Link>

              <Dropdown>
                <Dropdown.Toggle id="Tools" className="btn btn-label-primary btn-bold btn-icon-h dropdown--border">
                  Reports
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Link to={PROFIT_AND_LOSS} className="dropdown-item">Profit & Loss</Link>
                  <Link to={BALANCE_SHEET} className="dropdown-item">Balance Sheet</Link>
                  <Link to={TRIAL_BALANCE} className="dropdown-item">Trial Balance</Link>
                  <Link to="/books/reports" className="dropdown-item">All Reports</Link>
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown>
                <Dropdown.Toggle id="Tools" className="btn btn-label-primary btn-bold btn-icon-h dropdown--border">
                  Tools
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Link to="/books/tools/recurring-transactions" className="dropdown-item">Recurring Transactions</Link>
                  <Link to="/books/tools/opening-balances" className="dropdown-item">Opening Balances Wizard</Link>
                  {company && company.uses_receipt_upload
                  && <Link to="/books/tools/receipt-uploads" className="dropdown-item">Receipt Uploads</Link>}
                  <Link to={BANK_RULES} className="dropdown-item">Bank Rules</Link>
                </Dropdown.Menu>
              </Dropdown>

              <Link to="/books/settings" className="btn btn-label-primary btn-bold btn-icon-h">
                Settings
              </Link>
            </div>

            {/*Subheader Toolbar*/}
            <div className="kt-subheader__toolbar">
              <div className="kt-subheader__wrapper">
                {/*<button type="button" className="btn kt-subheader__btn-primary">*/}
                {/*Actions &nbsp;*/}
                {/*<SortNum1Icon className="kt-svg-icon kt-svg-icon--sm" />*/}
                {/*</button>*/}

                {/*<a className="btn kt-subheader__btn-primary btn-icon" to="">*/}
                {/*<i className="flaticon2-file" />*/}
                {/*</a>*/}

                {/*<a className="btn kt-subheader__btn-primary btn-icon" to="#">*/}
                {/*<i className="flaticon-download-1" />*/}
                {/*</a>*/}

                {/*<Link className="btn kt-subheader__btn-primary btn-icon" to="#">*/}
                {/*<i className="flaticon2-fax" />*/}
                {/*</Link>*/}

                {/*<a className="btn kt-subheader__btn-primary btn-icon" to="#">*/}
                {/*<i className="flaticon2-settings" />*/}
                {/*</a>*/}
                {/*<QuickActions />*/}
                <a className="btn kt-subheader__btn-primary btn-icon" onClick={toggleWidth}>
                  <ExpandIcon/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = store => ({
  config: store.builder.layoutConfig,
  menuConfig: store.builder.menuConfig,
  subheaderMobileToggle: objectPath.get(
    store.builder.layoutConfig,
    "subheader.mobile-toggle"
  ),
  subheaderCssClasses: builder.selectors.getClasses(store, {
    path: "subheader",
    toString: true
  }),
  subheaderContainerCssClasses: builder.selectors.getClasses(store, {
    path: "subheader_container",
    toString: true
  }),
  company: companySelectors.getCompany(store),
});

export default withRouter(connect(mapStateToProps)(SubHeader));
