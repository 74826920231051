import React from "react";
import { Avatar, Link, TableCell } from "@material-ui/core";
import NameCell from "../components/NameCell";
import WarningCell, {textColor} from "../components/WarningCell";
import { getNumber, withUnits } from "../../utils";
import TooltipComponentState from "../../../components/TooltipComponentState";


export const headRowsWL = [
  {
    id: "image_url",
    label: "Product",
    render: (row) => {
      const { image_url, amazon_product_url, id, full_image_url } = row;
      return (
        <TableCell align="left" key={`${id}--image_url`} className="table__cell--photo">
          <TooltipComponentState
            placement="right-start"
            className="cell--photo__tooltip"
            isBlur
            tooltip={
              <Avatar alt="img" variant="square" src={full_image_url} className="cell__tooltip__photo" />
            }
          >
            <Link target="_blank" href={amazon_product_url}>
              <Avatar alt="img" variant="square" src={image_url} />
            </Link>
          </TooltipComponentState>
        </TableCell>
      )
    }
  },
  {
    id: "item_name",
    label: undefined,
    render: (row) => (
      <NameCell
        key={`${row.id}--item_name`}
        asin={row.asin1}
        url={row.amazon_product_url}
        sku={row.seller_sku}
        vat={row.vat_code}
        title={row.item_name}
        marketplaces={[row.marketplace]}
        row={row}
        fulfillment={row.fulfillment}
        extra={{ isExtraLine: true }}
      />
    )
  },
  {
    id: "average_price",
    label: "Average Price",
    align: "center",
    helpText: "The average sales price paid by your customers",
    render: (row, { currency }) =>
      <WarningCell
        key={`${row.id}--price`}
        id={row.id}
        name="price"
        value={row.average_units.average_price}
        units={currency}
        nowrap={true}
      />
    ,
  },
  {
    id: "costs",
    label: "Average Cost",
    align: "center",
    helpText: "Your costs of goods are calculated by adding together your unit cost," +
        " prep centre, bundle cost, packaging, postage, handling and others as appropriate. " +
        "These costs are averaged over the quantity sold",
    render: (row, { currency }) =>
        <WarningCell
            key={`${row.id}--costs`}
            id={row.id}
            name="costs"
            value={row.average_units.average_costs}
            units={currency}
            nowrap={true}
        />
    ,
  },
  {
    id: "roi",
    label: "Average ROI",
    align: "center",
    helpText: "This is the average ROI returned on all sales.",
    render: (row) => (
      <WarningCell
        key={`${row.id}--roi`}
        id={row.id}
        name="roi"
        value={row.average_units.average_roi}
        units={"%"}
        nowrap={true}
      />
    ),
  },
  {
    id: "margin",
    label: "Average Margin",
    align: "center",
    helpText: "This is the average Margin returned on all sales",
    render: (row) => (
      <WarningCell
        key={`${row.id}--margin`}
        id={row.id}
        name="margin"
        value={row.average_units.average_margin}
        units={"%"}
        nowrap={true}
      />
    ),
  },
  {
    id: "qty",
    label: "Quantity Sold",
    align: "center",
    helpText: "This is the total quantity sold with this ASIN/SKU/Fulfilment/Marketplace combination",
    render: (row) => (
      <TableCell key={`${row.id}--quantity`} align="center" className="table__cell--quantity">
        <span style={{color: textColor(row.average_units.quantity_sold)}}>
          {withUnits(undefined, getNumber(row.average_units.quantity_sold), "integer")}
        </span>
      </TableCell>
    ),
  },

  {
    id: "profit",
    label: "Overall Profit",
    align: "center",
    helpText: "This is the overall profit made on these sales",
    render: (row, { currency }) => (
      <WarningCell
        key={`${row.id}--profit`}
        id={row.id}
        name="profit"
        value={row.average_units.overall_profit}
        units={currency}
        nowrap={true}
      />
    ),
  },
  {
    id: "total",
    label: "Total Sales",
    align: "center",
    helpText: "This is the total sales from this ASIN/SKU/Fulfilment/Marketplace combination",
    render: (row, { currency }) => (
      <WarningCell
        key={`${row.id}--total`}
        id={row.id}
        name="total"
        value={row.average_units.total_sales}
        units={currency}
        nowrap={true}
      />
    ),
  },
];
