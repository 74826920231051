import React from "react";
import Flag from "../Dashboard/components/Flag";

const baseInterface = [
  {label: "Quantity", id: "quantity"},
  {label: "Order Number", id: "amazon_order_id"},
  {label: "Date", id: "purchase_date"},
  {
    label: "Marketplace",
    id: "marketplace",
    render: (marketplace) => {
      return (
        <div className="marketplace">
          <Flag marketplace={marketplace} placement="left" />
        </div>
      )
    }
  },
];

export const infoHeaders = {
  products_ordered: [
    {id: 'quantity', label: 'Quantity', type: 'number', customProps: {type_number: 'integer'}},
    {id: 'buy_price', label: 'Buy Price', type: 'RNumber', productField: true}
  ],
  received_at_prep_center: [
    {id: 'quantity', label: 'Quantity', type: 'number', customProps: {type_number: 'integer'}},
    {id: 'damaged', label: 'Damaged', type: 'number', customProps: {type_number: 'integer'}},
    {id: 'sellable', label: 'Sellable', type: 'number', customProps: {type_number: 'integer'}}
  ],
  shipped_to_amazon: [
    // {label: "SKU", id: "sku"},
    {label: "Quantity", id: "quantity"},
    {label: "Receiving Fulfillment Center", id: "receiving_fulfilment_centre"},
  ],
  received_at_amazon: [
    // {label: "SKU", id: "sku"},
    {label: "Quantity", id: "quantity"},
    {label: "Damaged by Carrier", id: "carrier_damaged_quantity"},
    {label: "Damaged by Warehouse", id: "warehouse_damaged_quantity"},
    {label: "Damaged by Customer", id: "customer_damaged_quantity"},
    {label: "Damaged by Distributor", id: "distributor_damaged_quantity"},
    {label: "Experied", id: "expired_quantity"},
    {label: "Defective", id: "defective_quantity"},
    {label: "Sellable", id: "sellableQuantity"},
    {label: "Receiving Fulfilment Centre", id: "receiving_fulfilment_centre"},
  ],
  products_sold: baseInterface,
  products_refunded: baseInterface,
  stranded_inventory: [
    {label: "Quantity", id: "quantity"},
    {label: "Reasons for stranded", id: "reasons_for_stranded"},
  ],
  return_received: baseInterface,
  return_back_to_inventory: baseInterface,
  asin_inventory_overview: [
    {label: "Total inventory", id: "total_inventory"},
    {label: "Missed inventory", id: "missing_inventory"},
    {label: "Damaged inventory", id: "damaged_inventory"},
    {label: "Reimbursed inventory", id: "reimbursed_inventory"},
  ]
};