import React from "react";
import { toAbsoluteUrl } from "../../../../_metronic";
import './splash-screen.scss';
import * as PropTypes from "prop-types";

// you need to set "position: relative" for the container to properly display the SplashScreen component
const SplashScreen = ({ color }) => {
  const logo = color === "white" ? "/media/logos/new-logo-icon.svg" : "/media/logos/new-logo-icon.svg";
  return (
   <div className="d-splash-screen">
     <div className="loader">
       <img src={toAbsoluteUrl(logo)} alt="SKUify logo" className="d-splash-screen__logo" />
     </div>
    </div>
  )
};

SplashScreen.propTypes = {
  color: PropTypes.string,
};

export default SplashScreen;