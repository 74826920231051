import React from 'react'
import Animation from "./../Animation/Main";
import {toAbsoluteUrl} from "../../../../../../_metronic";

const features = [
  {
    icon: 'media/codely-theme-files/assets/images/new/Secure-data.png',
    title: 'Winners and Losers',
    text: 'Quickly identify your winning products and your losing ones & see their average price, cost, ROI, margin, quantity sold, overall profit & total sales so you know what to replenish and what to dump.'
  },
  {
    icon: 'media/codely-theme-files/assets/images/new/Fully-functional.png',
    title: 'Automated Reimbursements',
    text: 'SKUify tracks your stock within the Amazon system and anything that can be claimed for it creates an AUTOMATED message you can send to Amazon with all the details in it you need, to potentially get reimbursed by Amazon.'
  },
  {
    icon: 'media/codely-theme-files/assets/images/new/Live-chat.png',
    title: 'Automated Migration Tools',
    text: 'Switching to SKUify from your current software can be as easy as clicking one button. We also offer a manual bulk import feature for those software tools we don\'t support so moving to SKUify is easy.'
  },
  {
    icon: 'media/codely-theme-files/assets/images/new/24-7-Support.png',
    title: 'Expenses',
    text: 'This feature allows you to add non-cost of goods expenses that you might have in your business such as software subscriptions or accountancy fees to include all necessary expenses meaning accurate profit figures!'
  }
];

function AdvanceFeature() {
  return (
    <section className="row_am advance_feature_section" id="getstarted">
      <div className="container">
        <div className="advance_feature_inner" data-aos="fade-in" data-aos-duration="1500" data-aos-delay="100">
          <Animation />

          <div className="section_title" data-aos="fade-in" data-aos-duration="1500" data-aos-delay="100">
            <h2>Advanced features</h2>
            <p>SKUify's advanced features empower sellers to effortlessly manage inventory, optimize pricing, and access real-time analytics,
              turning the complex world of Amazon selling into a streamlined, profit-generating machine.</p>
          </div>
          <div className="row">
            {features.map((feature, index) => (
              <div className="col-lg-6 col-md-12" data-aos="fade-up" data-aos-duration="1500" key={index}>
                <div className="feature_block">
                  <div className="icon">
                    <img src={toAbsoluteUrl(feature.icon)} alt="image"/>
                  </div>
                  <div className="text_info">
                    <h3>{feature.title}</h3>
                    <p>{feature.text}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default AdvanceFeature
