import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import * as importDuck from "../store/ducks/import.duck";
import * as Selector from "../selectors/imortBankTransactions.selectors";

const useBankEntry = ({ bankId, ...props}) => {
  const fetch = props && props.fetch || true;

  const dispatch = useDispatch();

  useEffect(() => {
    if (fetch)
      dispatch(importDuck.actions.getBankEntries({ id: bankId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankId]);

  const bankEntries = Selector.selectBankEntries(useSelector(state => state));

  const _delete = ({ id }) => {
    dispatch(importDuck.actions.deleteBankEntry({
      id: id,
    }));
  };

  return {
    bankEntries,
    deleteBankEntry: _delete,
  };
};

export default useBankEntry;