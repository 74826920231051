export const headerRowsInvoices = [
  {
    id: 'date',
    label: 'Date',
  },
  {
    id: 'account',
    label: 'Account',
  },
  {
    id: 'category',
    label: 'Category',
  },
  {
    id: 'inv_ref',
    label: 'Inv. Ref',
  },
  {
    id: 'description',
    label: 'Description',
  },
  {
    id: 'currency',
    label: 'Currency',
  },
  {
    id: 'net_amount',
    label: 'Net',
  },
  {
    id: 'tax_code',
    label: 'Tax Code',
  },
  {
    id: 'tax_amount',
    label: 'Tax',
  },
  {
    id: 'total_amount',
    label: 'Total',
  },
  {
    id: 'conversion_rate',
    label: 'Conv. Rate',
  },
  {
    id: 'actions',
    label: 'Actions',
  },
];
