import React from "react";
import { Helmet } from "react-helmet";

export default function withHelmet(WrappedComponent, { title }) {
  return class extends React.Component {
    render() {

      // ... and renders the wrapped component with the fresh data!
      // Notice that we pass through any additional props
      return <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{title}</title>
            {/*<link rel="canonical" href="http://mysite.com/example" />*/}
        </Helmet>
        <WrappedComponent {...this.props} />
      </>
    }
  };
}