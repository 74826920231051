import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import useReports from "../../../hooks/reports.hook";
import reportApi from "../../../crud/reports.crud";
import moment from 'moment';
import RBDateRangePicker from "../../components/controls/RBDateRangePicker";
import DSplashScreen from "../../components/SplashScreen/DSplashScreen";
import CloseIcon from '@material-ui/icons/Close';
import ReportComponent from "../../components/ReportComponent/ReportComponent";
import * as PropTypes from "prop-types";
import TooltipComponent from "../../components/TooltipComponent";
import { ReactComponent as ImportIcon } from "../../../media/icons/import.svg";
import { ReactComponent as ReportIcon } from "../../../media/sales/report-pdf.svg";
import OverlayComponent from "../../components/OverlayComponent";
import {momentFormatOptions} from "../utils";

export const smallSpinner = <div className="d-splash-screen--wrap button-spinner">
  <DSplashScreen color="white" />
</div>;

const getURL = (start, end, tab) => {
  if (tab === "refunds") {
    return `/report/?start_date=${start}&end_date=${end}&doc_format=pdf&refunds=true&`
  } else {
    return `/report/?start_date=${start}&end_date=${end}&doc_format=pdf&`
  }
};

const ReportsDialog = ({ tab }) => {
  const [open, setOpen] = React.useState(false);
  const initStartDate = moment().subtract(1, 'months').format(momentFormatOptions.backend);
  const initEndDate = moment().format(momentFormatOptions.backend);
  const [loading, setLoading] = useState('');
  const [url, setUrl] = useState( `/report/?start_date=${initStartDate}&end_date=${initEndDate}&doc_format=pdf&`);
  const { getSalesReports, salesReports, salesReportsLoading } = useReports();

  useEffect(() => {
    setUrl(getURL(initStartDate, initEndDate, tab));
  }, [tab]);

  const onChange = (value) => {
    setUrl(getURL(value.startDate, value.endDate, tab));
    getSalesReports({ start_date: value.startDate, end_date: value.endDate, refunds: tab === "refunds" });
  };

  const downloadFileName = `SKUify PDF Report.pdf`;
  const getExportPDF = () => {
    setLoading('pdf');
    reportApi.getExportReports(url, downloadFileName)
      .then(response => {
          setLoading('');
        }
      );
  };

  const handleClose = () => setOpen(false);
  const handleClickOpen = () => {
    getSalesReports({ start_date: initStartDate, end_date: initEndDate, refunds: tab === "refunds" });
    setOpen(true);
  };

  return (
    <div className="wrap__sales__reports">
      <TooltipComponent tooltip="Please click to download a Profit and Loss Report">
        <Button
          variant="contained"
          size="medium"
          color="primary"
          startIcon={<ImportIcon className="report-icon" />}
          onClick={handleClickOpen}
        >
          Report
        </Button>
      </TooltipComponent>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        className="sales__reports__modal"
        maxWidth="sm"
        scroll="body"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="modal__header">
            Report
            {/*<div className="wrap__modal__header__logo">*/}
              {/*TODO add new logo*/}
              {/*<img*/}
                {/*src={toAbsoluteUrl("/media/logos/DoddledWatermark.svg")}*/}
                {/*className="modal__header__logo"*/}
                {/*alt="logo"*/}
              {/*/>*/}
            {/*</div>*/}
            <div className="dialog-title__close-btn">
              <IconButton edge="end" color="inherit" size="small" onClick={handleClose} aria-label="Close">
                <CloseIcon/>
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="sales__reports__header">
            <OverlayComponent
              tooltip="Please click here to convert your report to PDF format"
              className="sales__report-pdf__tooltip"
            >
              <Button
                variant="contained"
                size="medium"
                color="primary"
                className="sales__reports__btn export-btn"
                startIcon={ <ReportIcon className="report-icon" />}
                onClick={getExportPDF}
              >
                {loading === "pdf" ? smallSpinner : "Download PDF Report"}
              </Button>
            </OverlayComponent>
            <RBDateRangePicker
              value={{ startDate: initStartDate, endDate: initEndDate }}
              customProps={{
                onChange,
                singleDatePicker: false,
                opens: "left"
              }}
            />
          </div>
          <ReportComponent
            loading={salesReportsLoading}
            reportData={salesReports}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

ReportsDialog.propTypes = {
  tab: PropTypes.string,
};

export default ReportsDialog;