import React, { useState } from 'react';
import Modal from "react-bootstrap/Modal";
import * as PropTypes from "prop-types";
import { Form as FormikForm, Formik } from "formik";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import { useModal } from "../../../../hooks/modal.hook";
import DButton from "../../../components/Button";
import { processError } from "../EnterTransactionsBanking/EditAddBankingModal";
import { formContent } from "../../../components/controls/formContent";
import InactiveModal from "../EnterTransactionsCustomers/InactiveModal";
import { getCurrentCompanyId } from "../../../../crud/utils";
import { useHistory } from "react-router-dom";
import useCurrencies from "../../../../hooks/currencies.hook";
import { formFieldsDetails } from "./fieldsDetails";
import { fields } from "./fieldsBasic";
import { onValidation } from "../../../components/CreateEditModal";
import Notification from "../../../components/Notification";
import toast from "../../../components/toast/toast";
import useSuppliersCustomers from "../../../../hooks/suppliersCustomers.hook";
import useCategories from "../../../../hooks/categories.hook";

const TabContainer = ({ children }) => <Typography component="div" style={{ padding: 8 }}>{children}</Typography>;

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

function EditAddTransactionModal({ initValues, show, onClose, onSuccess, currentTab }) {
  const { currencies } = useCurrencies();
  const { categories } = useCategories();
  const {
    customers,
    suppliers,
    updateCustomer,
    addCustomer,
    updateSupplier,
    addSupplier
  } = useSuppliersCustomers({ customers: true, suppliers: true });

  const [isInactiveModalShow, toggleInactiveModal] = useModal();
  const [value, setValue] = useState("basic_details");
  const [errors, setErrors] = useState([]);
  const [isShowErrors, setIsShowErrors] = useState(false);

  const options = {
    "customers": { name: "Customer", update: updateCustomer, add: addCustomer, data: customers },
    "suppliers": { name: "Supplier", update: updateSupplier, add: addSupplier, data: suppliers },
  };
  const title = initValues ? `Edit ${options[currentTab].name}` : `New ${options[currentTab].name}`;

  const resetValue = () => setErrors([]);
  const handleChange = (event, newValue) => setValue(newValue);
  const onCloseModal = (resetForm) => () => {
    onClose();
    resetForm();
  };

  let propsFormBasic = {
    categories: categories.details,
    currencies: currencies.details,
    valueTab: currentTab === "customers",
    initValues,
    history: useHistory(),
    toggleInactiveModal
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initValues ? initValues : {}}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        const data = {...values};
        const onDone = () => {
          setSubmitting(false);
          resetForm();
          onSuccess();
        };
        const onError = (error) => {
          processError(error);
          setSubmitting(false);
        };
        data.credit_limit = data.credit_limit ? data.credit_limit : "1";
        data.currency = data.currency.value ? data.currency.value : data.currency;

        const allData = options[currentTab].data;
        const name = options[currentTab].name;
        if (initValues && initValues.id) {
          options[currentTab].update({
            id: initValues.id,
            values: data,
            onDone,
            onError,
          });
        } else {
          const duplicateName = allData.find(item => item.name === data.name);
          if (duplicateName) {
            setSubmitting(false);
            toast.error(`${name} with name ${duplicateName.name} already exists`);
            return;
          }
          data.account_currency_balance = 0;
          data.company = getCurrentCompanyId();
          data.logo_url = "logo_url";
          data.account_class = "account_class";
          data.vat_number = "vat_number";
          options[currentTab].add({
            values: data,
            onDone,
            onError,
          });
        }
      }}
    >
      {form => {
        return <Modal show={show} onHide={onCloseModal(form.resetForm)} className="modal--small">
          <FormikForm>
            <Modal.Header closeButton>
              <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {isShowErrors && (
                <Notification errors={errors} onClose={() => setIsShowErrors(false)} name={options[currentTab].name} />
              )}
              <AppBar position="static" color="default">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                >
                  <Tab value="basic_details" label="Basic Details" />
                  <Tab value="contact_details" label="Contact Details" />
                </Tabs>
              </AppBar>
              {value === "basic_details" && (
                <TabContainer>{formContent({ interfaces: fields(propsFormBasic), form })}</TabContainer>
              )}
              {value === "contact_details" && (
                <TabContainer>{formContent({ interfaces: formFieldsDetails, form })}</TabContainer>
              )}
            </Modal.Body>
            <Modal.Footer>
              <div className="container-button container-button--modal">
                <DButton
                  typeOfButton="doneAllSecondary"
                  type="submit"
                  onClickCustom={onValidation(form.validateForm, setErrors, setIsShowErrors, resetValue)}
                  disabled={form.isSubmitting}
                >
                  {`Save ${options[currentTab].name}`}
                </DButton>
                <DButton typeOfButton="close" onClickCustom={onCloseModal(form.resetForm)}>Close</DButton>
              </div>
              {/* TODO should be displayed after clicking on the checkbox if there are recurring transactions for the row being edited */}
              {// recurringTrans &&
                <InactiveModal show={isInactiveModalShow} onClose={toggleInactiveModal} />}
            </Modal.Footer>
          </FormikForm>
        </Modal>
      }}
    </Formik>
  );
}

EditAddTransactionModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  currentTab: PropTypes.string,
  initValues: PropTypes.object,
  onSuccess: PropTypes.func,
};

export default EditAddTransactionModal;
