import React, {useState} from 'react';
import {useHistory} from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import * as PropTypes from "prop-types";
import {goToImportTransactionPage, goToNewTransactionPage} from "../utils";
import DButton from "../../../components/Button";
import RSelect from "../../../components/controls/RSelect";
import {entryMethods as entryMethod} from "../bankOptions";

export default function EnterTransactionModal({ banks, show, onClose }) {
  const [selectedBankAccount, setBankAccount] = useState(null);
  const bankOptions = banks.map(({ name, id, entry_method }) => ({ label: name, value: id, entry_method }));

  let history = useHistory();

  const openNewTransactionPage = () => {
    const bankId = selectedBankAccount.value;
    if (selectedBankAccount.entry_method === entryMethod.FILE_IMPORT) {
      goToImportTransactionPage(history, bankId)
    } else {
      goToNewTransactionPage(history, bankId);
    }
  };

  function handleChangeBankAccount(data) {
    setBankAccount(data);
  }

  return (
    <Modal show={show} onHide={onClose} className="modal--small">
      <Modal.Header closeButton>
        <Modal.Title>Select a Bank Account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Which bank account do you want to enter transactions for?</Form.Label>
            <RSelect
              label={"Select a Bank Account"}
              name={"enter-transaction"}
              options={bankOptions}
              setFieldValue={selectedBankAccount}
              onChange={handleChangeBankAccount}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <div className="container-button container-button--modal">
          <DButton typeOfButton="exitSecondary"
            onClickCustom={openNewTransactionPage}
            disabled={!selectedBankAccount} >
            Enter Transaction
          </DButton>
          <DButton typeOfButton="close" onClickCustom={onClose} >Close</DButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

EnterTransactionModal.propTypes = {
  banks: PropTypes.array.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,

};
