import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import api from "../../crud/transaction.crud";
import {createSlice} from "@reduxjs/toolkit";
import logger from "../../pages/logger";

const company = createSlice({
    name: 'transaction',
    initialState: {
      transactionFetched: false,
      transactions: [],
      transactionsLoading: true,
    },
    reducers: {
      stopLoading: (state, actions) => {
        state.transactionsLoading = false;
        return state;
      },
      getTransactions: (state, actions) => {
        state.transactionsLoading = true;
        return state;
      },
      getTransactionsSuccess: (state, actions) => {
        const {transactions} = actions.payload;
        state.transactions = transactions;
        state.transactionsLoading = false;
        state.transactionFetched = true;
        return state;
      },
      getTransactionsByBank: (state, actions) => {
        state.loading = true;
        return state;
      },
      getTransactionsByBankSuccess: (state, actions) => {
        const {transactions} = actions.payload;
        state.transactions = transactions;
        state.loading = false;
        return state;
      },
      getTransactionsByCategory: (state, actions) => {
        state.loading = true;
        return state;
      },
      getTransactionsByCategorySuccess: (state, actions) => {
        const {transactions} = actions.payload;
        state.transactions = transactions;
        state.loading = false;
        return state;
      },
      addTransaction: (state, actions) => {
        state.loading = true;
        return state;
      },
      addTransactionSuccess: (state, actions) => {
        const {transaction} = actions.payload;
        state.transactions = [...state.transactions, transaction];
        state.loading = false;
        return state;
      },
      deleteTransaction: (state, actions) => {
        state.loading = true;
        return state;
      },

      ignoreTransaction: (state, actions) => {
        state.loading = true;
        return state;
      },
      ignoreTransactionSuccess: (state, actions) => {
        const {id} = actions.payload;
        state.transactions = state.transactions.filter((transaction) => (transaction.id !== id));
        state.loading = false;
        return state;
      },
      deleteTransactionSuccess: (state, actions) => {
        const {id} = actions.payload;
        state.transactions = state.transactions.filter((transaction) => (transaction.id !== id));
        state.loading = false;
        return state;
      },
      deleteTransactions: (state, actions) => {
        state.loading = true;
        return state;
      },
      deleteTransactionsSuccess: (state, actions) => {
        const {ids} = actions.payload;
        state.transactions = state.transactions.filter(transaction => !ids.includes(transaction.id));
        state.loading = false;
        return state;
      },
      updateTransaction: (state, actions) => {
        state.loading = true;
        return state;
      },
      updateTransactionSuccess: (state, actions) => {
        state.transactions = state.transactions.map((transaction) => {
          if (transaction.id === actions.payload.id) {
            return actions.payload.transaction;
          }
          return transaction
        });
        state.loading = false;
        return state;
      },
      confirmTransaction: (state, actions) => {
        state.loading = true;
        return state;
      },
      confirmTransactionSuccess: (state, actions) => {
        state.transactions = state.transactions.map((transaction) => {
          if (transaction.id === actions.payload.id) {
            return actions.payload.transaction;
          }
          return transaction
        });
        state.loading = false;
        return state;
      },
      getDescriptions: (state, actions) => {
        state.loading = true;
        return state;
      },
      getDescriptionsSuccess: (state, actions) => {
        const { descriptions } = actions.payload;
        state.descriptions = descriptions;
        state.loading = false;
        return state;
      },
    },
  }
);

export const actions = company.actions;
export const reducer = company.reducer;
export function* saga() {
  yield takeLatest(actions.getTransactions, function* getTransactionsSaga({payload: { companyId, filters, q }}) {
    try {
      const { data } = yield call(api.getTransactions, companyId, filters, q);
      data
        ? yield put(actions.getTransactionsSuccess({ transactions: data }))
        : yield put(actions.stopLoading());
    } catch (err) {
      yield put(actions.stopLoading());
      console.log(err);
    }
  });

  yield takeLatest(actions.getTransactionsByBank, function* getTransactionsByBankSaga(action) {
    try {
      const bankId = action && action.payload ? action.payload.bankId : 1;
      const companyId = action.payload.companyId;
      const { data } = yield call(api.getTransactionsByBank, companyId , bankId);
      data
        ? yield put(actions.getTransactionsByBankSuccess({ transactions: data }))
        : yield put(actions.stopLoading());
    } catch (err) {
      yield put(actions.stopLoading());
      console.log(err);
    }
  });

  yield takeLatest(actions.getTransactionsByCategory, function* getTransactionsByCategorySaga(action) {
    try {
      const categoryId = action && action.payload ? action.payload.categoryId : 1;
      const companyId = action.payload.companyId;
      const { data } = yield call(api.getTransactionsByCategory, companyId , categoryId);
      data
        ? yield put(actions.getTransactionsByCategorySuccess({ transactions: data }))
        : yield put(actions.stopLoading());
    } catch (err) {
      yield put(actions.stopLoading());
      console.log(err);
    }
  });

  yield takeLatest(actions.addTransaction, function* addTransactionSaga({ payload: { values, onDone } }) {
    try {
      const { data } = yield call(api.addTransaction, values);
      if (data) {
        yield put(actions.addTransactionSuccess({ transaction: data }));
        onDone();
      } else {
       yield put(actions.stopLoading());
      }
    } catch (err) {
      yield put(actions.stopLoading());
      console.log(err);
    }
  });

  yield takeLatest(actions.deleteTransaction, function* deleteTransactionSaga({ payload: { id, idCompany, idBank, onDone } }) {
    try {
      yield call(api.deleteTransaction, idCompany, idBank,  id );
      yield put(actions.deleteTransactionSuccess({ id }));
      if (onDone)
        onDone();
    } catch (err) {
      yield put(actions.stopLoading());
      console.log(err);
    }
  });

  yield takeLatest(actions.deleteTransactions, function* deleteTransactionsSaga({ payload: { ids, idCompany, onDone } }) {
    try {
      yield call(api.deleteTransactions, idCompany,  ids );
      yield put(actions.deleteTransactionsSuccess({ ids }));
      if (onDone)
        onDone();
    } catch (err) {
      yield put(actions.stopLoading());
      console.log(err);
    }
  });

  yield takeLatest(actions.updateTransaction, function* updateTransactionSaga({ payload: { idCompany, id, values, onDone } }) {
    try {
      const { data } = yield call(api.updateTransaction, idCompany, id, values);
      if (data) {
        yield put(actions.updateTransactionSuccess({ transaction: data, id }));
        onDone();
      } else {
        yield put(actions.stopLoading());
      }
    } catch (err) {
      yield put(actions.stopLoading());
      console.log(err);
    }
  });

  yield takeLatest(actions.getDescriptions, function* getDescriptionsSaga({ payload }) {
    try {
      let q = payload && payload.q;
      let companyId = payload && payload.companyId;
      const { data } = yield call(api.descriptionAutocomplete, companyId, q);
      data
        ? yield put(actions.getDescriptionsSuccess({ descriptions: data }))
        : yield put(actions.stopLoading());
    } catch (err) {
      yield put(actions.stopLoading());
      logger.error(err);
    }
  });

  yield takeLatest(actions.confirmTransaction, function* confirmTransactionSaga({ payload: { idCompany, id, values, onDone, onError } }) {
    try {
      const { data, ...props } = yield call(api.confirmTransaction, idCompany, id, values);
      if (data) {
        yield put(actions.confirmTransactionSuccess({ transaction: data, id }));
        onDone();
      } else {
        yield put(actions.stopLoading());
        onError(props);
      }
    } catch (err) {
      yield put(actions.stopLoading());
      console.log(err);
      if (err) {
        onError(err);
      }
    }
  });

  yield takeLatest(actions.ignoreTransaction, function* ignoreTransactionSaga({ payload: { idCompany, id, onDone, onError } }) {
    try {
      const { data, ...props } = yield call(api.deleteTransaction, idCompany, id);
      if (data) {
        yield put(actions.ignoreTransactionSuccess({ transaction: data, id }));
        if (onDone)
          onDone();
      } else {
        yield put(actions.stopLoading())
        onError(props);
      }
    } catch (err) {
      yield put(actions.stopLoading())
      console.log(err);
      if (onError) {
        onError(err);
      }
    }
  });
}
