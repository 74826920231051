import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects'
import {createSlice} from "@reduxjs/toolkit";
import api from "../../crud/import.crud";
import logger from "../../pages/logger";
import {shortBaseState} from "./utils";

const initialState = {
  importFormats: shortBaseState,
  bankEntries:shortBaseState,
  bankEntry: {
    loading: false,
    details: null,
  },
  loading: true,
  saving: false,
  errors: [],
  customImport: {
    details: null,
    loading: false,
    updating: false,
  },
};


const common = createSlice({
  name: 'importFormats',
  initialState,
  reducers: {
    stopLoading: (state, actions) => {
      const {type} = actions.payload;
      if (type) {
        state[type].loading = false;
      } else {
        state.loading = false;
      }
      return state;
    },

    getBankEntry: (state) => {
      state.bankEntry.loading = true;
    },
    getBankEntrySuccess: (state, action) => {
      const { bankEntry } = action.payload;
      state.bankEntry.details = bankEntry;
      state.bankEntry.loading = false;
    },
    
    getCustomImport: (state) => {
      state.customImport.loading = true;
    },
    getCustomImportSuccess: (state, action) => {
      const { customImport } = action.payload;
      state.customImport.details = customImport;
      state.customImport.loading = false;
      return state;
    },
    getImportFormats: (state) => {
      state.importFormats.loading = true;
    },
    getImportFormatsSuccess: (state, action) => {
      const { importFormats } = action.payload;
      state.importFormats.details = importFormats;
      state.importFormats.loading = false;
    },
    createImportFormatsSuccess: (state, actions) => {
      const {format} = actions.payload;
      state.importFormats.details = [...state.importFormats.details, format];
      return state;
    },
    createImportFormat: (state, actions) => {
      return state;
    },
    createBankEntry: (state, actions) => {
      state.bankEntries.loading = true;
      return state;
    },
    createBankEntrySuccess: (state, actions) => {
      state.bankEntries.loading = false;
      const { rows } = actions.payload;
      state.bankEntries.details = [...state.bankEntries.details, rows];
      return state;
    },
    deleteImport: (state, actions) => {
      state.loading = true;
      return state;
    },
    deleteImportSuccess: (state, actions) => {
      const {id} = actions.payload;
      state.importFormats.details = state.importFormats.details.filter((item) => (item.id !== id));
      state.loading = false;
      return state;
    },
    updateImport: (state, actions) => {
      state.customImport.updating = true;
      return state;
    },
    updateImportSuccess: (state, actions) => {
      state.customImport.details = actions.payload.import;
      state.customImport.updating = false;
      if (state.importFormats.details)
      state.importFormats.details = state.importFormats.details.map((item) => {
        if (item.id === actions.payload.id) {
          return actions.payload.import;
        }
        return item
      });
      return state;
    },
    updateImportStopLoading: (state, actions) => {
      state.customImport.updating = false;
      return state;
    },
    getBankEntries: (state) => {
      state.bankEntries.loading = true;
    },
    getBankEntriesSuccess: (state, action) => {
      const { bankEntries } = action.payload;
      state.bankEntries.details = bankEntries;
      state.bankEntries.loading = false;
    },
    deleteBankEntry: (state, actions) => {
      state.loading = true;
      return state;
    },
    deleteBankEntrySuccess: (state, actions) => {
      const {id} = actions.payload;
      state.bankEntries.details = state.bankEntries.details.filter((item) => (item.id !== id));
      state.bankEntries.loading = false;
      return state;
    },

  }
});

export const actions = common.actions;
export const reducer = common.reducer;

export function* saga() {
  yield takeLatest(actions.getCustomImport, function* getImportFormatsSaga({ payload: { id }}) {
    try {
      const { data } = yield call(api.getCustomImport, id);
      data
        ? yield put(actions.getCustomImportSuccess({ customImport: data }))
        : yield put(actions.stopLoading({type: "customImport"}));
    } catch (err) {
      yield put(actions.stopLoading({type: "customImport"}));
      console.error(err);
    }
  });

  yield takeLatest(actions.getImportFormats, function* getImportFormatsSaga() {
    try {
      const { data } = yield call(api.getCustomImportFormats);
      data
        ? yield put(actions.getImportFormatsSuccess({ importFormats: data }))
        : yield put(actions.stopLoading({type: "importFormats"}));
    } catch (err) {
      yield put(actions.stopLoading({type: "importFormats"}));
      console.error(err);
    }
  });

  yield takeLatest(actions.createImportFormat, function* createImportFormatsSaga({ payload: { values, onDone, onError } }) {
    try {
      const { data, ...props } = yield call(api.createImportFormats, values);
      if (data) {
        yield put(actions.createImportFormatsSuccess({ format: data }));
        onDone(data);
      } else {
        yield put(actions.stopLoading({type: "importFormats"}));
        onError && onError(props);
      }
    } catch (err) {
      yield put(actions.stopLoading({type: "importFormats"}));
      if (onError)
        onError(err);
      logger.error(err);
    }
  });

  yield takeLatest(actions.deleteImport, function* deleteImportSaga({ payload: { id, onDone, onError } }) {
    try {
      yield call(api.deleteImportFormats, id);
      yield put(actions.deleteImportSuccess({ id }));
      if (onDone)
        onDone();
      yield put(actions.stopLoading());
    } catch (err) {
      yield put(actions.stopLoading());
      if (onError)
        onError(err);
      console.error(err);
    }
  });

  yield takeLatest(actions.updateImport, function* updateImportSaga({ payload: { id, values, onDone, onError } }) {
    try {
      const { data } = yield call(api.updateImportFormats, id, values);
      if (data) {
        yield put(actions.updateImportSuccess({ import: data, id }));
        if (onDone)
          onDone();
      } else {
        yield put(actions.updateImportStopLoading());
      }
    } catch (err) {
      yield put(actions.updateImportStopLoading());
      if (onError)
        onError(err);
      console.error(err);
    }
  });

  yield takeLatest(actions.createBankEntry, function* createBankEntrySaga({ payload: { values, onDone, onError } }) {
    try {
      const { data, ...props } = yield call(api.createBankEntry, values);
      if (data) {
        yield put(actions.createBankEntrySuccess({ rows: data.rows }));
        if (onDone)
          onDone(data);
      } else {
        yield put(actions.stopLoading({type: "bankEntries"}));
        if (onError)
          onError(props);
      }
    } catch (err) {
      yield put(actions.stopLoading({type: "bankEntries"}));
      if (onError)
        onError(err);
      console.error(err);
    }
  });

  yield takeLatest(actions.deleteBankEntry, function* deleteBankEntrySaga({ payload: { id, onDone, onError } }) {
    try {
      yield call(api.deleteBankEntry, id);
      yield put(actions.deleteBankEntrySuccess({ id }));
      if (onDone)
        onDone();
      yield put(actions.stopLoading({type: "bankEntries"}));
    } catch (err) {
      yield put(actions.stopLoading({type: "bankEntries"}));
      if (onError)
        onError(err);
      console.error(err);
    }
  });

  yield takeLatest(actions.getBankEntries, function* getBankEntriesSaga({ payload: { id, onDone, onError } }) {
    try {
      const { data, ...props } = yield call(api.getBankEntries, id);
      if (data) {
        yield put(actions.getBankEntriesSuccess({ bankEntries: data }));
        if (onDone)
          onDone();
      } else {
        yield put(actions.stopLoading({type: "bankEntries"}));
        if (onError)
          onError(props);
      }
    } catch (err) {
      yield put(actions.stopLoading({type: "bankEntries"}));
      if (onError)
        onError(err);
      console.error(err);
    }
  });

  yield takeLatest(actions.getBankEntry, function* getBankEntrySaga({ payload: { id, onDone, onError } }) {
    try {
      const { data, ...props } = yield call(api.getBankEntry, id);
      if (data) {
        yield put(actions.getBankEntrySuccess({ bankEntry: data }));
        if (onDone)
          onDone();
      } else {
        yield put(actions.stopLoading({type: "bankEntry"}));
        if (onError)
          onError(props);
      }
    } catch (err) {
      yield put(actions.stopLoading({type: "bankEntry"}));
      if (onError)
        onError(err);
      console.error(err);
    }
  });
    
}
