import * as React from "react";
import {Form as FormikForm, Formik} from "formik";
import {Typography} from "@material-ui/core";
import clsx from "clsx";

const Container = ({children, title, className, initData, onSubmit}) => {
  return (
    <Formik
      enableReinitialize
      initialValues={initData}
      onSubmit={onSubmit}
    >
      {({...form}) => (
        <FormikForm>
          <div className={clsx("settings-main__default", className)}>
            <Typography variant="h5" color="primary" className="settings__title">{title}</Typography>
            {children(form)}
          </div>
        </FormikForm>
      )}
    </Formik>
  )
};

export default Container;