/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Dropdown} from "react-bootstrap";
import {NOTIFICATIONS} from "../../router/routesMap";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import {ReactComponent as BellIcon} from "../../media/icons/light-notifi.svg";
import {NotificationsItem} from "../../pages/home/Notifications/renderRow";
import useNotifications from "../../hooks/notifications.hook";
import moment from 'moment'
import _ from 'lodash'

const SKUNotifications = () => {
  const {notificationOrder} = useNotifications({fetch: true});
  const [notifi, setNotifi] = useState({});
  const [bellBadge, setBellBadge] = useState(false);
  const [isOpenModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (!_.isEmpty(notificationOrder)) {
      const content = Object.values(notificationOrder).join("\n");
      const newNotifi = {
        link: NOTIFICATIONS,
        type: "Order",
        skipped: false,
        order_data: {name: content},
        created_at: moment(),
      };

      !isOpenModal && setBellBadge(true);
      setNotifi(newNotifi);
    }
  }, [notificationOrder]);

  const onToggle = (state) => {
    setOpenModal(state);
    state && setBellBadge(false);
  };

  const onNotification = () => setNotifi({...notifi, skipped: true});

  const to = {
    pathname: NOTIFICATIONS,
    state: {fromModal: moment().format()}
  };

  return (
    <Dropdown
      className={"kt-header__topbar-item kt-header__topbar-item--user d-header__topbar-item--dropdown notification"}
      drop="down"
      alignRight
      onToggle={onToggle}
    >
      <Dropdown.Toggle as={HeaderDropdownToggle} id="dropdown-autoclose-true">
        <span className="kt-header__topbar-icon">
          <BellIcon/>
          <span className="notifications__new" hidden={!bellBadge}/>
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="dropdown-menu dropdown-menu-fit dropdown-menu-center dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg dropdown-menu--user w-360">
        {/** ClassName should be 'dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
        <div className="dropdown__content">
          <div className="kt-user-card kt-user-card--skin-dark d-user-card ">
            <h3 className="kt-head__title">Notifications</h3>
          </div>
          <div className="kt-notification notifications">

            {notifi.type && (
              <Dropdown.Item
                as="button"
                className="notifications__link-item MuiButtonBase-root"
                onClick={onNotification}
              >
                <NotificationsItem {...notifi} />
              </Dropdown.Item>
            )}

            <div className="kt-notification__custom">
              <Link to={to}>
                <Dropdown.Item
                  as="button"
                  className="kt-notification__custom__btn MuiButtonBase-root"
                  onClick={onNotification}
                >
                  <span className="MuiButton-label">See all notifications</span>
                </Dropdown.Item>
              </Link>
            </div>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SKUNotifications;
