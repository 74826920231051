import React from "react";
import {FormControlLabel, FormHelperText} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";

const CheckboxField = allProps => {
  const {
    name,
    setFieldValue,
    setFieldTouched,
    placeholder,
    label,
    meta,
    showLabel,
    customProps = {},
    submitCount,
    autofocus,
    ...props
  } = allProps;
  const {mark, onChange} = customProps;
  const handleChange = (event) => {
    if (onChange) {
      onChange(event);
    } else {
      setFieldValue(name, event.target.checked);
    }
  };
  const handleBlur = () => {
    setFieldTouched(name, true);
  };

  let labelCheckbox = label;
  if (mark) {
    labelCheckbox = <>
      {label}
      <span className="form-control__mark">{mark}</span>
    </>
  }

  return (
    <>
      <FormControlLabel
        {...props}
        key={name}
        name={name}
        checked={!!props.value}
        onChange={handleChange}
        onBlur={handleBlur}
        control={<Checkbox
          name={name}
          color="primary"
        />}
        label={labelCheckbox}
        labelPlacement="start"
      />
      {(meta.touched || submitCount) && meta.error && (
        <FormHelperText error id="component-error-text">
          {meta.error}
        </FormHelperText>
      )}
    </>
  )
};

export default CheckboxField;