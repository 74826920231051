import { useDispatch, useSelector } from "react-redux";
import * as Selector from "../selectors/suppliersCustomers.selectors";
import * as suppliersDuck from "../store/ducks/suppliers.duck";
import * as customersDuck from "../store/ducks/customers.duck";
import { useEffect } from "react";

const useSuppliersCustomers = (props={}) => {
   const { fetch=true, suppliers, customers } = props;

  const dispatch = useDispatch();
  useEffect(() => {
    if (fetch)
      suppliers && suppliersDuck.actions.getSuppliers();
      customers && customersDuck.actions.getCustomers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch]);

  const getSuppliers = (param) => dispatch(suppliersDuck.actions.getSuppliers(param));
  const addSupplier = (param) => dispatch(suppliersDuck.actions.addSupplier(param));
  const updateSupplier = (param) => dispatch(suppliersDuck.actions.updateSupplier(param));
  const deleteSupplier = (param) => dispatch(suppliersDuck.actions.deleteSupplier(param));

  const getCustomers = (param) => dispatch(customersDuck.actions.getCustomers(param));
  const addCustomer = (param) => dispatch(customersDuck.actions.addCustomer(param));
  const updateCustomer = (param) => dispatch(customersDuck.actions.updateCustomer(param));
  const deleteCustomer = (param) => dispatch(customersDuck.actions.deleteCustomer(param));

  const _suppliers = Selector.getSuppliers(useSelector(state => state));
  const _customers = Selector.getCustomers(useSelector(state => state));

  return {
    customers: _customers.details,
    customersLoading: _customers.loading,
    getCustomers,
    addCustomer,
    updateCustomer,
    deleteCustomer,

    suppliers: _suppliers.details,
    suppliersLoading: _suppliers.loading,
    getSuppliers,
    addSupplier,
    updateSupplier,
    deleteSupplier,
  };
};

export default useSuppliersCustomers;