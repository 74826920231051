import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";

const LabelTab = ({ title }) => {
  return (
    <div className="settings__tabs__nav">
      <div className="settings__tabs__nav__label">
        <Typography variant="subtitle1" className="nav__label__title">{title}</Typography>
      </div>
    </div>
  )
};

LabelTab.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  icon: PropTypes.string,
};

export default LabelTab;