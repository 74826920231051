import React, {useEffect, useState} from 'react'
import * as PropTypes from 'prop-types'
import Marketplaces from '../../components/Marketplaces/Marketplaces'
import DSearch from '../../components/DSearch/DSearch'
import RSelect from '../../components/controls/RSelect'
import { FormControl } from '@material-ui/core'
import Form from 'react-bootstrap/Form'
import { filtersOptions } from './utils'
import debounce from 'lodash/debounce'
import FormAddReport from './FormAddReport'
import Button from '@material-ui/core/Button'
import {useModal} from '../../../hooks/modal.hook'
import useReports from '../../../hooks/reports.hook'
import Typography from "@material-ui/core/Typography";
import { ReactComponent as GenerateIcon } from "../../../media/icons/note 1.svg";

const TabsToolbar = ({ setParams, setFilterQuery, params }) => {


  const {
    expensesCategories,
    getExpensesCategories,
  } = useReports()

  useEffect(() => {
    getExpensesCategories()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  
  const { filter } = params;
  const [isClear, setClear] = useState(false);
  const [isModalShow, toggleModalShow] = useModal();

  const decoratorDebounce = (param, setParams, ms) => debounce((q) => {
    let params = { offset: 0, ordering: "" };
    params = {...params, [param]: q };
    setParams(params);
  }, ms || 500);

  const handleChangeFilter = (e) => decoratorDebounce("filter", setParams)(e.value);

  return (
    <div className="header--search tabs__toolbar">
      <Typography className="page-title">Reports</Typography>
      <div className="toolbar__right">
        <FormControl className="form-control--multiple-select">
          <Form.Label className="form-group__label-item" column={false}>Filter</Form.Label>
          <RSelect
            value={filtersOptions.find(({ value }) => value === filter)}
            label=""
            name='select filter'
            options={filtersOptions}
            onChange={handleChangeFilter}
            className='switch-select'
          />
        </FormControl>
        <DSearch
          setSearchQuery={decoratorDebounce('q', setParams)}
          isClear={isClear}
          setClear={setClear}
        />
        {/*<div className="toolbar__left">*/}
          {/*<div className="products__details__wrap details__wrap--ppp main-report__new-report">*/}
           {/**/}
          {/*</div>*/}
        {/*</div>*/}
        <>
          <Button variant="contained" className="toolbar__btn" onClick={toggleModalShow}>
            {/*<img alt="img" src={toAbsoluteUrl('./media/d-icons/note 1.svg')} />*/}
            <GenerateIcon className="generate-icon"/>
            Generate Report
          </Button>
          <FormAddReport
            expensesCategories={expensesCategories}
            filtersOptions={filtersOptions}
            onClose={toggleModalShow}
            show={isModalShow}
          />
        </>
        <Marketplaces
          onSelectedMarketplace={decoratorDebounce("marketplace", setParams)}
        />
      </div>
    </div>
  )
};

TabsToolbar.propTypes = {
  onSelectedMarketplace: PropTypes.func,
  setSearchQuery: PropTypes.func,
};

export default TabsToolbar;