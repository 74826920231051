import React, { useEffect, useState } from "react";
import { headRowsModal } from "../pages/home/Transaction/EnterTransactionsBanking/headRowsModal";
import { useHistory } from "react-router-dom";
import queryString from 'query-string';

export const useModal = (show = false) => {
  const [isModalShow, setIsModalShow] = useState(show);
  const toggleSplitModalShow = () => setIsModalShow(!isModalShow);
  return [isModalShow, toggleSplitModalShow];
};

export const usePerPage = (quantity = 15) => {
  const [rowsPerPage, setRowsPerPage] = useState(quantity);
  const onPerPage = (quantity) => {
    setRowsPerPage(quantity)
  };
  return [rowsPerPage, onPerPage];
};

export const useParseHistory = () => {
  let history = useHistory();
  return queryString.parse(history.location.search);
};

export const useCheckboxStorage = (title = "", initialData = {headRowsModal}) => {
  const [columnsList, setColumnsList] = React.useState(() => {
    if (localStorage.getItem(title)) {
      const savedData = JSON.parse(localStorage.getItem(title));
      initialData.forEach((item) => {
        const savedItem = savedData.find((savedDataItem) => savedDataItem.id === item.id);
        if (savedItem) {
          item.isActive = savedItem.isActive;
        }
      });
    }
    return [...initialData];
  });
  useEffect(() => {
    const toSave = columnsList.map(item => {
      return {
        id: item.id,
        isActive: item.isActive
      }
    });
    localStorage.setItem(title, JSON.stringify(toSave));
  }, [columnsList]);
  return [columnsList, setColumnsList];
};
