import {call, put, takeLatest,} from 'redux-saga/effects'

import {createSlice} from '@reduxjs/toolkit'
import api from '../../crud/reports.crud'
import {typeReports} from '../../pages/home/Dashboard/components/utils'
import {baseListStateRes, fullBaseListStateRes} from "./utils";
import {expensesDateFormatFront} from "../../pages/home/Expenses/utils";

const API = {
  [typeReports.PRODUCTS]: api.getProductReport,
  [typeReports.AMAZON]: api.getAmazonFeesReport,
  [typeReports.AMAZON_PRODUCT]: api.getAmazonFeesReportProduct,
  [typeReports.SALES]: api.getOrderReport,
}

const initialState = {
  loading: false,
  fetched: false,
  salesReports: {
    // results: {},   // comment out for test
    results: [], // uncomment for test
    loading: false,
  },
  orderReport: {
    // results: {     // comment out for test
    //   expenses: []
    // },
    results: [], // uncomment for test
    id: null,
    type: null,
    loading: false,
  },
  expenses: {
    ...baseListStateRes,
    categories: [],
  },

  expensesTransactions: {
    results: {
      // 0: {
      //   data: {
      //     //offset: details
      //     0: [],
      //   },
      //   totalCount: 0,
      // }
    },
    loadingId: null,
  },
  noteImportsExpenses: {},
  mainReports: fullBaseListStateRes
}

const common = createSlice({
  name: 'reports',
  initialState: initialState,
  reducers: {
    stopLoading: (state, actions) => {
      const {type} = actions.payload;
      if (type) {
        state[type].loading = false;
      } else {
        state.loading = false;
      }
      return state;
    },
    getSalesReports: (state, actions) => {
      state.salesReports.loading = true
      return state
    },
    getSalesReportsSuccess: (state, actions) => {
      state.salesReports.loading = false
      state.salesReports.results = actions.payload
      state.fetched = true
      return state
    },
    getOrderReport: (state, actions) => {
      state.orderReport.loading = true
      state.orderReport.type = null
      return state
    },
    getOrderReportSuccess: (state, actions) => {
      const {report, id, type} = actions.payload
      state.orderReport.loading = false
      state.orderReport.results = report
      state.orderReport.id = id
      state.orderReport.type = type
      state.fetched = true
      return state
    },
    getOrderReportError: (state, actions) => {
      const {type} = actions.payload
      state.orderReport.loading = false
      state.orderReport.type = type
      state.orderReport.results = []
      return state
    },
    getExpenses: (state) => {
      state.expenses.loading = true
      return state
    },
    getExpensesSuccess: (state, actions) => {
      const {results, count} = actions.payload;
      state.expenses.results = actions.payload.status
        ? []
        : results.map((item) => {
          const {date_start, date_end, amount} = item;
          return {
            ...item,
            date_start: expensesDateFormatFront(date_start),
            date_end: date_end ?  expensesDateFormatFront(date_end) : null,
            vat: !!item.product_vat,
            amount: amount.toFixed(2),
          }
        });
      state.expenses.totalCount = count;
      state.fetched = true
      state.expenses.loading = false
      return state
    },

    getExpensesError: (state) => {
      state.expenses.loading = false
      return state
    },

    getExpensesTransactions: (state, actions) => {
      const {expense_setting_id} = actions.payload;
      state.expensesTransactions.loadingId = expense_setting_id;
      return state
    },
    getExpensesTransactionsSuccess: (state, actions) => {
      const {results, count, expense_setting_id: id, offset} = actions.payload;
      const expensesTransactions = state.expensesTransactions.results;
      const repeatRow = expensesTransactions[id] || {data: {}};

      state.expensesTransactions.results = {
        ...state.expensesTransactions.results,
        [id]: {
          data: {
            ...repeatRow.data,
            [offset]: results.map((transaction) => ({
              ...transaction,
              expense_amount: transaction.expense_amount.toFixed(2)
            })),
          },
          totalCount: count
        }
      };

      state.expensesTransactions.loadingId = null;
      return state
    },
    getExpensesTransactionsError: (state) => {
      state.expensesTransactions.loadingId = null;
      return state
    },

    updateExpenses: (state, actions) => {
      state.expenses.loading = true
      return state
    },
    updateExpensesSuccess: (state, actions) => {
      state.expenses.loading = false
      return state
    },

    deleteExpenses: (state, actions) => {
      state.expenses.loading = true;
      return state
    },
    deleteExpensesSuccess: (state, actions) => {
      // const {id} = actions.payload;
      // state.expenses.results = state.expenses.results.filter(expense => expense.id !== id);
      state.expenses.totalCount = state.expenses.totalCount - 1;
      state.expenses.loading = false;
      return state
    },
    deleteExpensesError: (state, actions) => {
      state.expenses.loading = false;
      return state
    },

    //todo update deleteExpensesTransaction

    deleteExpensesTransaction: (state, actions) => {
      const {rowId} = actions.payload;
      state.expensesTransactions.loadingId = rowId;
      return state
    },

    deleteExpensesTransactionSuccess: (state, actions) => {
      const {rowId, offsetTransactions, transaction} = actions.payload;

      const {results} = state.expensesTransactions
      state.expensesTransactions.results = {
        ...results,
        [rowId]: {
          ...results[rowId],
          data: {
            ...results[rowId].data,
            [offsetTransactions]: results[rowId].data[offsetTransactions].filter((item) => item.id !== transaction.id)
          }
        }
      };
      state.expensesTransactions.loadingId = null;
      return state
    },
    creditExpensesTransactionSuccess: (state, actions) => {
      const {rowId, offsetTransactions, transaction, newTransactions} = actions.payload;
      const {results} = state.expensesTransactions;

      const update = newTransactions.filter(({created_at}) => created_at === transaction.created_at);
      state.expensesTransactions.results = {
        ...results,
        [rowId]: {
          ...results[rowId],
          data: {
            ...results[rowId].data,
            [offsetTransactions]:
              results[rowId].data[offsetTransactions].reduce((res, item) => {
                const {created_at} = item;
                if (created_at === transaction.created_at) {
                  return [
                    ...res,
                    ...update.map((item) => ({
                      ...item,
                      expense_amount: item.expense_amount.toFixed(2)
                    }))
                  ]
                }
                return [...res, item]
              }, [])
          }
        }
      };

      state.expensesTransactions.loadingId = null;
      return state
    },

    getExpensesCategories: (state, actions) => {
      state.expenses.loading = true
      return state
    },
    getExpensesCategoriesSuccess: (state, actions) => {
      state.expenses.categories = actions.payload
      state.expenses.loading = false
      return state
    },

    updateExpensesTransaction: (state, actions) => {
      const {rowId} = actions.payload;
      state.expensesTransactions.loadingId = rowId;
      return state
    },
    updateExpensesTransactionSuccess: (state, actions) => {
      state.expensesTransactions.loadingId = null;
      return state;
    },
    updateExpensesTransactionError: (state, actions) => {
      state.expensesTransactions.loadingId = null;
      return state;
    },

    getMainReports: (state, actions) => {
      state.mainReports.loading = true
      return state
    },
    getMainReportsSuccess: (state, actions) => {
      const {reports} = actions.payload
      state.mainReports.results = !!reports ? reports.results : []
      state.fetched = true
      state.mainReports.totalCount = !!reports ? reports.count : 0
      state.mainReports.loading = false
      return state
    },

    addNewReport: (state, actions) => {
      state.mainReports.loading = true
      return state
    },

    addNewReportSuccess: (state, actions) => {
      const {newReport} = actions.payload
      state.mainReports.results = [newReport, ...state.mainReports.results]
      state.mainReports.totalCount = state.mainReports.totalCount + 1
      state.mainReports.loading = false
      return state
    },

    addNewReportError: (state, actions) => {
      state.mainReports.loading = false
      return state
    },

    deleteMainReports: (state, actions) => {
      state.mainReports.loading = true
      return state
    },
    deleteMainReportsSuccess: (state, actions) => {
      const {ids} = actions.payload
      state.mainReports.results = state.mainReports.results.filter(({id}) => !ids.includes(id))
      state.mainReports.totalCount = state.mainReports.totalCount - 1
      state.fetched = true
      state.mainReports.loading = false
      return state
    },

    addImportsExpenses: (state, actions) => {
      return state;
    },

    addImportsExpensesSuccess: (state, actions) => {
      state.noteImportsExpenses = actions.payload;
      return state;
    },
  }
})

export const actions = common.actions
export const reducer = common.reducer

export function* saga() {
  yield takeLatest(actions.getSalesReports, function* getSalesReportsSaga({payload = {}}) {
    try {
      const {data} = yield call(api.getSalesReports, payload)
      data
        ? yield put(actions.getSalesReportsSuccess(data))
        : yield put(actions.stopLoading({type: "salesReports"}))
    } catch (err) {
      yield put(actions.stopLoading({type: "salesReports"}))
      console.error(err)
    }
  })
  yield takeLatest(actions.getOrderReport, function* getOrderReportSaga({payload: {id, type, table, onError}}) {
    try {
      const {data} = yield call(API[type], id, {table});
      if (data) {
        yield put(actions.getOrderReportSuccess({report: data, id, type}))
      } else {
        yield put(actions.getOrderReportError({type}))
        onError("Report error. Something went wrong.")
      }
    } catch (err) {
      yield put(actions.getOrderReportError({type}))
      onError(err)
      console.error(err);
    }
  })

  yield takeLatest(actions.updateExpenses, function* updateExpensesSaga({payload}) {
    const {values, onDone, onError} = payload;
    try {
      const {data, ...props} = yield call(api.updateExpenses, values);
      if (data) {
        yield put(actions.updateExpensesSuccess(data));
        onDone && onDone();
      } else {
        yield put(actions.stopLoading({type: "expenses"}))
        onError && onError(props);
      }
    } catch (err) {
      yield put(actions.stopLoading({type: "expenses"}))
      onError && onError();
      console.error(err);
    }
  });

  yield takeLatest(actions.getExpenses, function* getExpensesSaga({payload}) {
    try {
      const {data} = yield call(api.getExpenses, payload)
      data
        ? yield put(actions.getExpensesSuccess(data))
        : yield put(actions.stopLoading({type: "expenses"}))
    } catch (err) {
      yield put(actions.stopLoading({type: "expenses"}))
      console.error(err)
    }
  })

  yield takeLatest(actions.getExpensesTransactions, function* getExpensesTransactionsSaga({payload}) {
    try {
      const {data} = yield call(api.getExpensesTransactions, payload)
      if (data) {
        yield put(actions.getExpensesTransactionsSuccess({...data, ...payload}))
      } else {
        yield put(actions.getExpensesTransactionsError())
      }
    } catch (err) {
      yield put(actions.getExpensesTransactionsError())
      console.error(err)
    }
  })

  yield takeLatest(actions.deleteExpenses, function* deleteExpensesSaga({payload: {id}}) {
    try {
      yield call(api.deleteExpenses, id);
      yield put(actions.deleteExpensesSuccess({id}))
    } catch (err) {
      yield put(actions.deleteExpensesError());
      console.error(err)
    }
  })

  yield takeLatest(actions.deleteExpensesTransaction, function* deleteExpensesTransactionSaga({payload}) {
    const {transaction, offsetTransactions, rowId} = payload;
    try {
      yield call(api.deleteExpensesTransaction, transaction.id);
      if (transaction.is_credit) {
        yield put(actions.deleteExpensesTransactionSuccess(payload))
      } else {
        const {data} = yield call(api.getExpens, rowId);
        if (data) {
          yield put(actions.creditExpensesTransactionSuccess({
            offsetTransactions,
            rowId,
            transaction,
            newTransactions: data.expense_transactions
          }))
        } else {
          yield put(actions.updateExpensesTransactionError())
        }
      }
    } catch (err) {
      yield put(actions.updateExpensesTransactionError())
      console.error(err)
    }
  })

  yield takeLatest(actions.updateExpensesTransaction, function* updateExpensesTransactionSaga({payload}) {
    const {transaction, onDone} = payload;
    try {
      const { data } = yield call(api.updateExpensesTransaction, transaction);
      if (data) {
        yield put(actions.updateExpensesTransactionSuccess(payload))
        onDone();
      } else {
        yield put(actions.getExpensesTransactionsError())
      }
    } catch (err) {
      yield put(actions.updateExpensesTransactionError())
      console.error(err)
    }
  })
  yield takeLatest(actions.getExpensesCategories, function* getExpensesCategoriesSaga() {
    try {
      const { data } = yield call(api.getExpensesCategories)
      data
        ? yield put(actions.getExpensesCategoriesSuccess(data))
        : yield put(actions.stopLoading({type: "expenses"}))
    } catch (err) {
      yield put(actions.stopLoading({type: "expenses"}));
      console.error(err)
    }
  })

  yield takeLatest(actions.getMainReports, function* getMainReportsSaga({payload = {}}) {
    try {
      const {data} = yield call(api.getMainReports, payload)
      if (data) {
        yield put(actions.getMainReportsSuccess(data))
      } else {
        yield put(actions.stopLoading({type: "mainReports"}));
      }
    } catch (err) {
      yield put(actions.stopLoading({type: "mainReports"}));
      console.error(err)
    }
  });

  yield takeLatest(actions.addNewReport, function* addNewReportSaga({payload: { values, onDone, onError }}) {
    try {
      const { data, ...props } = yield call(api.addNewReport, values)
      if (data) {
        yield put(actions.addNewReportSuccess({ newReport: data }))
        onDone()
      } else {
        yield put(actions.addNewReportError())
        onError(props)
      }
    } catch (err) {
      yield put(actions.addNewReportError())
      onError(err)
      console.error(err)
    }
  })

  yield takeLatest(actions.deleteMainReports, function* deleteMainReportsSaga({ payload: { values, onDone, onError, ids }}) {
    try {
      const { data, ...props } = yield call(api.updateReports, values)
      if (data) {
        yield put(actions.deleteMainReportsSuccess({ ids }))
        onDone(data.success)
      } else {
        yield put(actions.stopLoading({type: "mainReports"}));
        onError(props)
      }
    } catch (err) {
      yield put(actions.stopLoading({type: "mainReports"}));
      onError(err)
      console.error(err)
    }
  })

  yield takeLatest(actions.addImportsExpenses, function* addImportsExpensesSaga({payload: {onDone, values, onError}}) {
    try {
      const {data, ...props} = yield call(api.addImportsExpenses, values.get('file').name, values);
      if (data) {
        yield put(actions.addImportsExpensesSuccess(data));
        onDone(data);
      } else {
        onError(props);
      }
    } catch (err) {
      onError(err);
      console.log(err);
    }
  });
}