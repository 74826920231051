import { entryMethodOptions } from "../bankOptions";

export const formFieldsDetails = [
  { id: 'contact_name', label: 'Contact name', type: 'text' },
  { id: 'email', label: 'Email', type: 'email' },
  { id: 'telephone_number', label: 'Telephone number', type: 'text' },
  { id: 'address_line_1', label: 'Address line 1', type: 'text' },
  { id: 'address_line_2', label: 'Address line 2', type: 'text' },
  { id: 'address_line_3', label: 'Address line 3', type: 'text' },
  { id: 'town/city', label: 'Town/City', type: 'text' },
  { id: 'post/zip_code', label: 'Post/Zip Code', type: 'text' },
  {id: 'county', label: 'County', type: 'RSelect', customProps: { options: entryMethodOptions } },
  { id: 'country', label: 'Country', type: 'RSelect',  customProps: { options: entryMethodOptions } },
];
