import React, { useState } from 'react';
import { Stepper, Step, Typography } from '@material-ui/core';
import Wrapper from "../../../components/Wrapper";
import DButton from "../../../components/Button";
import DateWizard from "./DateWizard";
import CustomersSuppliersWizard from "./CustomersSuppliersWizard";
import StepButton from "@material-ui/core/StepButton";
import VATSchemesWizard from "./VATSchemesWizard";
import "./wizard.style.scss"
import Categories from "../../Category/Categories";
import { useHistory } from "react-router-dom";
import { goToCategoryPage } from "../../Category/utils";
import Invoices from "../../Invoices/Invoices";
import { useNotification } from "../../../../hooks/notification.hook";
import useCompany from "../../../../hooks/company.hook";

const getSteps = (company) => {
  return [
    'Date',
    ...company && company.customer_credit ? ['Customers'] : [],
    ...company && company.supplier_credit ? ['Suppliers'] : [],
    ...company && company.vat_registration_attributes_vat_registered ? ['VAT Schemes'] : [],
    ...company && (company.customer_credit || company.supplier_credit) ? ['Outstanding Invoices'] : [],
    'Opening Balances',
  ];
};

const getStepContent = (stepIndex, company) => {
  const nameStepIndex = getSteps(company).find((step, index) => index === stepIndex);

  switch (nameStepIndex) {
    case "Date":
      return <DateWizard/>;
    case "Customers":
      if (company.customer_credit) {
        return <CustomersSuppliersWizard step={stepIndex} />
      }
      break;
    case "Suppliers":
      if (company.supplier_credit) {
        return <CustomersSuppliersWizard />
      }
      break;
    case "VAT Schemes":
      if (company.vat_registration_attributes_vat_registered) {
        return <VATSchemesWizard />
      }
      break;
    case "Outstanding Invoices":
      if (company.customer_credit || company.supplier_credit) {
        return <Invoices step={stepIndex} />
      }
      break;
    case "Opening Balances":
      return <Categories step={stepIndex} />;
    default:
      return 'Uknown stepIndex';
  }
};

const OpeningBalancesWizard = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const {company} = useCompany();
  const steps = getSteps(company);
  const { infoNotification } = useNotification(true, "info", "Information message");
  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
        steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleStep = step => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  let history = useHistory();
  const onFinish = () => goToCategoryPage(history);

  return <Wrapper className="wizard">
    <Typography variant="h6" className="wizard__title">Opening Balance Wizard</Typography>
    <Stepper nonLinear activeStep={activeStep} alternativeLabel>
      {steps.map((label, index) => (
        <Step key={label}>
          <StepButton onClick={handleStep(index)} completed={completed[index]}>
            {label}
          </StepButton>
        </Step>
      ))}
    </Stepper>
    <div className="wizard__content__wrap">
      <div className="wizard__content">
        {infoNotification}
        {getStepContent(activeStep, company, company)}
        <div className="container-button container-button--modal wizard__btns">
          <DButton
            typeOfButton="backPrimary"
            disabled={activeStep === 0}
            onClickCustom={handleBack}>
            Back
          </DButton>
          <DButton typeOfButton="forward" onClickCustom={handleNext}>Next</DButton>
          {activeStep !== steps.length &&
          (completed[activeStep] ? (
            <Typography variant="subtitle2">
              {steps[activeStep]} already completed
            </Typography>
          ) : (
            <DButton onClickCustom={completedSteps() === totalSteps() - 1 ? onFinish : handleComplete}>
              {completedSteps() === totalSteps() - 1 ? 'Finish' : 'Complete Step'}
            </DButton>
          ))}
        </div>
      </div>
    </div>
  </Wrapper>
};

export default OpeningBalancesWizard;
