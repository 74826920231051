import React from "react";
import {formatNumber, replaceIndex} from "../../../home/utils";
import {FormControl, FormHelperText, TextField} from "@material-ui/core";
import NumberFormat from 'react-number-format';
import classNames from 'classnames'

const RNumber = allProps => {
  const {
    name,
    setFieldValue,
    setFieldTouched,
    // placeholder,
    label,
    meta,
    showLabel,
    customProps = {},
    submitCount,
    autofocus,
    ...props
  } = allProps;

  const {
    beforeField,
    onChange,
    onBlur,
    type_number,
    noteField,
    suffixValue,
    prefixValue,
    placeholder = "0.00",
    ...otherProps
  } = customProps;

  let val = props.value;
  if (!val && val !== "") {
    if (type_number === "integer") {
      val = "0"
    } else {
      val = "0.00"
    }
  }

  const handleChange = (event) => {
    let value = event.target.value;
    if (onChange) {
      onChange(event, val);
    } else {
      if (prefixValue || suffixValue) {
        value = replaceIndex(value)
      }
      setFieldValue(name, formatNumber(value, val));
    }
    setFieldTouched(name, true);
  };

  const handleBlur = (e) => {
    let value = e.target.value;
    if (prefixValue || suffixValue) {
      value = replaceIndex(value)
    }

    if ((value.indexOf(".") > value.length - 3 && value % 1 !== 0) || value === "0") {
      value = parseFloat(value).toFixed(2);
    }

    if (onBlur) {
      onBlur(value);
    }
    setFieldValue(name, value);
    setFieldTouched(name, true);
  };

  return (
    <FormControl
      error={!!(meta && (meta.touched || submitCount) && meta.error)}
      fullWidth
      margin="dense"
    >
      <div className="form-group__wrap-number-format">
        {beforeField && <span className="form-group__before-field">{beforeField}</span>}
        <NumberFormat
          {...props}
          prefix={prefixValue}
          suffix={suffixValue}
          autoFocus={autofocus}
          thousandSeparator=","
          decimalSeparator="."
          fixedDecimalScale={true}
          {...otherProps}
          id={name}
          name={name}
          type="text"
          label={label}
          placeholder={placeholder}
          onChange={handleChange}
          onBlur={handleBlur}
          value={val}
          customInput={TextField}
          className={classNames({"number-input--error": meta && (meta.touched || submitCount) && meta.error})}
        />
        {meta && (meta.touched || submitCount) && meta.error && (
          <FormHelperText error id="component-error-text">
            {meta.error}
          </FormHelperText>
        )}
        {noteField && <span className="form-group__note-field">{noteField}</span>}
      </div>
    </FormControl>
  )
};

export default RNumber;