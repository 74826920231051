import React from 'react';
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import * as PropTypes from "prop-types";
import {requiredValidator} from "../../../components/controls/validators";
import FormField from "../../../components/controls/FormField";
import {Form as FormikForm, Formik} from "formik";
import DButton from "../../../components/Button";

export default function NewCustomerGroupModal ({ show, onClose, }) {

  const formFields = [
    {
      id: 'name',
      label: 'Display Name',
      type: 'string',
      validate: requiredValidator('Display Name is required'),
    },
  ];

  return (
    <Formik
      enableReinitialize
      initialValues={{}}
      // onSubmit={(values, { setSubmitting }) => {
      //   BankApi.updateBank([values]).then(() => {
      //     setSubmitting(false);
      //     onClose();
      //   });
      // }}
    >
      {(form) => (
        <Modal show={show} onHide={onClose} className="modal--small" >
          <FormikForm>
            <Modal.Header closeButton>
              <Modal.Title>New Customer Group</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {
                formFields.map((item) => (
                  <Form.Group key={item.id} controlId={item.id}>
                    <Form.Label>{item.label}</Form.Label>
                    <FormField
                      name={item.id}
                      label={item.label}
                      type={item.type}
                      validate={item.validate}
                      {...form}
                    />
                  </Form.Group>
                ))
              }
            </Modal.Body>
            <Modal.Footer>
              <div className="container-button container-button--modal">
                <DButton typeOfButton="doneAllSecondary" type="submit" disabled={form.isSubmitting} >
                  Save Customer Group
                </DButton>
                <DButton typeOfButton="close" onClickCustom={onClose} >Close</DButton>
              </div>
            </Modal.Footer>
          </FormikForm>
        </Modal>
      )}
    </Formik>
  );
}

NewCustomerGroupModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

