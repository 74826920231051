import React from "react";
import * as PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import GetAppIcon from "@material-ui/icons/GetApp";
import EditIcon from "@material-ui/icons/Edit";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import {useModal} from "../../../hooks/modal.hook";
import EditUploadModal from "./EditUploadModal";
import {momentFormatOptions} from "../../home/utils";

const FileComponent = ({file, updateBankFile, bankId}) => {
  const [isShowEditModal, toggleShowEditModal] = useModal();
  const detachFile = () => {
    updateBankFile({
      id: file.id,
      values: {
        bank_account: null,
      }
    })
  };

  const attachToBank = () => {
    updateBankFile({
      id: file.id,
      values: {
        bank_account: bankId,
      }
    })
  };

  const onEdit = () => {
    toggleShowEditModal();
  };

  return (
    <div className="upload-file__item">
      <Card className="card-list__item">
        <CardContent className="card-list__content card-list__content--upload">
          {file.file
          && <Typography component="div" className="card-list__media">
            <img src={file.file} alt="" />
          </Typography>
          }
          <div className="card-list__text card-list__bold">
            <Typography variant="subtitle1" className="card-list__text--name">
              {file.name}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary"  className="card-list__text--date">
              {moment(file.upload_date).format(momentFormatOptions.backend)}
            </Typography>
          </div>
          <CardActions className="card-list__actions">
            {file.bank_account
            && <>
              <IconButton className="card-list__button card-list__button--small"> {/*Download*/}
                <GetAppIcon color="primary"/>
              </IconButton>
              <IconButton onClick={onEdit} className="card-list__button card-list__button--small">{/*Edit*/}
                <EditIcon color="secondary"/>
              </IconButton>
              <IconButton onClick={detachFile} className="card-list__button card-list__button--small">{/*Detach*/}
                <i className="fas fa-cut card-list__button--danger"/>
              </IconButton>
            </>
            }
            {!file.bank_account
            && <IconButton onClick={attachToBank} className="card-list__button card-list__button--small">{/*Attach*/}
              <AttachFileIcon color="secondary"/>
            </IconButton>
            }
          </CardActions>
        </CardContent>
      </Card>
       <EditUploadModal
        onClose={toggleShowEditModal}
        show={isShowEditModal}
        updateBankFile={updateBankFile}
        file={file}
      />
    </div>
  )
};

FileComponent.prototypes = {
  bankId: PropTypes.number,
  show: PropTypes.bool.isRequired,
  updateBankFile: PropTypes.func,
  file: PropTypes.object
};

export default FileComponent;