import React from 'react';
import Popper from '@material-ui/core/Popper';
import PopupState, { bindHover, bindPopper } from 'material-ui-popup-state';
import Fade from '@material-ui/core/Fade';
import classNames from "clsx";

const TooltipComponentState = (props) => {
  const {
    children,
    tooltip,
    className,
    tooltipStyle,
    placement="bottom",
    id="tooltip-popper",
    isHoverTooltip,
    isBlur,
  } = props;

  const renderArray = () => (
      <div>
        {tooltip
            .map(t => <span>{t}</span>)
            .reduce((prev, curr) => [prev, <div className="tooltip-line-separator"/>, curr])}
      </div>
  );

  const onClosePopper = ({popupState, time}) => () => {
    if (!isHoverTooltip) {
      setTimeout(() => popupState.close(), time);
    }
  };

  return (
    <PopupState variant="popper" popupId={`${id}`}>
      {popupState => {
        return (
          <div className="tooltip-component-state">
            <div {...bindHover(popupState)} className="tooltip-component-state__children">{children}</div>
            <Popper
              {...bindPopper(popupState)}
              transition
              placement={placement}
              className={classNames("d-tooltip--popper d-tooltip--popper--state", className)}
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={{ enter: 300, exit: 100 }}>
                  <div
                    onMouseMove={onClosePopper({popupState, time: 300})}
                    // onMouseEnter={onClosePopper({popupState, time: 3000})}
                    className={classNames(tooltipStyle, "d-tooltip", {"d-tooltip--empty": !tooltip, "blur": isBlur })}
                  >
                    {Array.isArray(tooltip) ? renderArray() : tooltip}
                  </div>
                </Fade>
              )}
            </Popper>
          </div>
        )
      }}
    </PopupState>
  );
};

export default TooltipComponentState