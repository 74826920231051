
export const general = ({ onProfitLoss, onBalanceSheet, onTrialBalance }) => {
  return [
    {
      id: "profit_loss",
      title: "Profit & Loss",
      text: "The amount of profit or loss your business has made for the period you specify.",
      function: onProfitLoss,
    },
    {
      id: "balance_sheet",
      title: "Balance Sheet",
      function: onBalanceSheet,
      text: "A snapshot of your assets, liabilities and worth of your business as at the date you specify.",
    },
    {
      id: "trial_balance",
      title: "Trial Balance",
      function: onTrialBalance,
      text: "A snapshot of all your category balances as at the date you specify." +
        " Categories include assets, liabilities, sales, expenses and equity.",
    },
    // {
    //   id: "cash_flow",
    //   title: "Cash Flow",
    //   text: "An overview of the money coming in and money going out of your accounts.",
    // },
  ]
};

export const agedReports = () => {
  return [
    {
      id: "receivables",
      title: "Receivables",
      text: "A breakdown of the amounts you are owed from customers.",
    },
    {
      id: "payables",
      title: "Payables",
      text: "A breakdown of the amounts you owe to suppliers.",
    },
  ];
};

export const vat = () => {
  return [
    {
      id: "vat_returns",
      title: "VAT Returns",
      text: "A quarterly report to calculate the amount of VAT you owe to or are owed from HMRC.",
    },
    {
      id: "ec_sales_lists",
      title: "EC Sales Lists",
      text: "A quarterly report listing the invoices raised to your EU customers (zero rated).",
    },
  ];
};

export const exportReports = () => {
  return [
    {
      id: "customers",
      title: "Customers",
      text: "A complete list of your customers including contact details and current balance owed to you.",
    },
    {
      id: "customer_invoices",
      title: "Customer Invoices",
      text: "A list of all of the customer invoices you have raised including the amounts and amounts still outstanding.",
    },
    {
      id: "suppliers",
      title: "Suppliers",
      text: "A complete list of your suppliers including contact details and current balance that you owe.",
    },
    {
      id: "supplier_invoices",
      title: "Supplier Invoices",
      text: "A list of all supplier invoices you have ever entered, " +
        "including the amounts and amounts still outstanding.",
    },
    {
      id: "transactions",
      title: "Transactions",
      text: "A transactions report lists all transactions for the date range selected. " +
        "The report shows dates, accounts, categories and a VAT breakdown (if applicable).",
    },
    {
      id: "general_ledger",
      title: "General Ledger",
      text: "The general ledger report lists all transactions for the date range selected, grouped by category.",
    },
    {
      id: "request_export",
      title: "Request Export",
      text: "Can’t find the export you need? Request a custom export from our team.",
    },
  ];
};

// const exportReports = [
//   {
//     id: "request_export",
//     title: "Request Export",
//     text: "Can’t find the export you need? Request a custom export from our team.",
//   },
// ];