import React from "react";
import { Form as FormikForm, Formik } from "formik";
import toast from "../../../components/toast/toast";
import FormField from "../../../components/controls/FormField";
import Form from "react-bootstrap/Form"
import { Button } from "@material-ui/core";
import useSettings from "../../../../hooks/settings.hook";
import { useNotification } from "../../../../hooks/notification.hook";
import { security } from "./../utils";
import DSplashScreen from "../../../components/SplashScreen/DSplashScreen";
import HeaderInformation from "./../components/HeaderInformation";

const SecuritySettings = () => {
  const { updatePassword, updatePasswordLoading: loading } = useSettings({ fetch: false });
  const [successNotification, setIsShowNotification] = useNotification(
    false,
    "success",
    "Success",
    { vertical: "bottom", horizontal: "right" }
  );

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    const {old_password, new_password, confirm_password } = values;

    if (old_password === new_password) {
      setSubmitting(false);
      toast.error("Invalid password. Old Password and New Password match.", {duration: null}, {isClose: false});
      return;
    }

    if (confirm_password !== new_password) {
      setSubmitting(false);
      toast.error("New Password and Confirm Password didn't match.", {duration: null}, {isClose: false});
      return;
    }

    const onDone = () => {
      setSubmitting(false);
      resetForm();
      setIsShowNotification(true);
    };

    const onError = (error) => {
      setSubmitting(false);
      toast.error(error, {duration: null}, {isClose: false});
    };

    updatePassword({
      values: {old_password, new_password, confirm_password},
      onDone,
      onError
    })
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{}}
      onSubmit={onSubmit}
    >
      {({...form}) => (
        <FormikForm>
          <div className="settings-main__account settings-main__security settings-main__default">
            <HeaderInformation name="Security" />
            <div className="d-splash-screen--wrap">
              {loading && <DSplashScreen />}
              <div className="settings-main__account__form">
              <div className="form__body">
                {security.map((item) => (
                  <Form.Group key={item.id} controlId={item.id} className={`form-group--${item.id}`}>
                    <Form.Label column={false}>{item.label}</Form.Label>
                    <FormField
                      name={item.id}
                      label={item.label}
                      type={item.type}
                      {...form}
                    />
                  </Form.Group>
                ))}
              </div>
              <div className="settings__wrap-btn">
                <Button
                  variant="contained"
                  className="settings-main__vat__btn settings__btn"
                  type="submit"
                  disabled={form.isSubmitting}
                >
                  Update Password
                </Button>
              </div>
            </div>
            </div>
            {successNotification}
          </div>
        </FormikForm>
      )}
    </Formik>
  )
};

export default SecuritySettings;