import React from "react";
import TableCell from "@material-ui/core/TableCell";
import { withCurrency } from "../utils";
import WarningIcon from '@material-ui/icons/Warning';
import { entryMethods } from "../bankOptions";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { createTheme } from "@material-ui/core/styles";
import { amber } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import EditIcon from '@material-ui/icons/Edit';

const theme = createTheme({
  palette: {
    primary: amber,
  },
});

export const headRows = [
  { id: 'nominal_code', numeric: false, disablePadding: true, label: 'Category Code', isActive:true  },
  { id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
    isActive:true,
    render: (row, { onNameClick }) => {
      return <TableCell align="left" key={`${row.id}--name`} onClick={onNameClick(row)} className="table__cell--link">
        <span className="table__cell--link__text" >
          {row.name}
          {row.entry_method === entryMethods.BANK_FEED
          && <WarningIcon color="error" fontSize="small" className="table__cell--link__icon"/>}
          {/*icon for correct balance*/}
          {/*{row.entry_method === entryMethods.BANK_FEED */}
          {/*&& <CheckCircleIcon color="secondary" fontSize="small" className="table__cell--link__icon"/>}*/}

        </span>
      </TableCell>
    }
  },
  { id: 'entry_method', numeric: false, disablePadding: true, label: 'Entry Method', isActive:true,
    render: (row, { onMethodClick }) => {
      return (
        <TableCell
          align="left"
          key={`${row.id}--entry_method`}
          onClick={onMethodClick(row)}
          className="table__cell--link"
        >
          <span className="table__cell--link__text">{row.entry_method}</span>
        </TableCell>
      )
    }
  },
  { id: 'balance', numeric: false, disablePadding: true, label: 'Balance', isActive:true,
    renderText: (row) => `${withCurrency(row.balance, row.currency.currency_symbol)}`,
  },
  {
    id: 'actions', label: 'Actions', isActive: true,
    render: (row, { goToPage, onDoneAll, onEdit, onDelete, onAttachments }) => {
      return <TableCell align="center" key={`${row.id}--action`} className="table__actions">
        <div className="action-buttons-table">
          {row.entry_method === entryMethods.BANK_FEED
            ? <IconButton color="secondary" onClick={onDoneAll(row)}><DoneAllIcon/></IconButton>
            : <IconButton color="primary" onClick={goToPage(row)}><ExitToAppIcon/></IconButton>
          }

          <ThemeProvider theme={theme}>
            <IconButton data-action="edit" color="primary" onClick={onEdit(row)}><EditIcon/></IconButton>
          </ThemeProvider>

          {onAttachments && <IconButton color="secondary" onClick={onAttachments(row)}><AttachFileIcon/></IconButton>}

          <ThemeProvider theme={theme}>
            <IconButton data-action='delete' color="secondary" onClick={onDelete(row)}><DeleteIcon/></IconButton>
          </ThemeProvider>
        </div>
      </TableCell>
    }
  }
];
