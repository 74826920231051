import React, { useState } from "react";
import toast from "../../components/toast/toast";
import { Form as FormikForm, Formik } from "formik";
import Typography from "@material-ui/core/Typography";
import UploadFileScreen from "../../components/UploadFileScreen";
import DButton from "../../components/Button";
import Modal from "react-bootstrap/Modal";
import * as PropTypes from "prop-types";
import useProducts from "../../../hooks/products.hook";

const ImportCOG = ({ onClose, show, showNotifications, format="CSV/XLSX/TSV/TXT", acceptedFiles, addImports, title, short }) => {
  const [rows, setRows] = useState([]);
  const { addImportsCOG } = useProducts();

  const modalText = `
  Please upload a ${format} file containing the data you wish to import.
  Your ${format} file MUST have been exported from SKUify first so the formatting
  of the ${format} is correct.
  ` 
  const additionalModalText = `
  If you have been using another dashboard similar to SKUify you can directly upload their 
  exported COG's file here and we can map the COG's directly for you. Please check with us to 
  see if your previous Dash is supported.
  `
  return (
    <Formik
      enableReinitialize
      initialValues={{}}
      onSubmit={(values, { resetForm, setSubmitting }) => {
        const formdata = new FormData();
        const file = rows[0];
        formdata.append("file", file, file.name);

        const onError = (error) => {
          toast.error(error, { duration: 5000 });
          setSubmitting(false);
        };
        const onDone = (data) => {
          setSubmitting(false);
          showNotifications && showNotifications(data);
          resetForm();
          onClose();
        };
        if (addImports) {
          addImports({
            values: formdata,
            onDone,
            onError,
          })
        } else {
          addImportsCOG({
            values: formdata,
            onDone,
            onError,
          })
        }

      }}>
      {(form) => {
        return (
          <Modal show={show} onHide={onClose} className="import-cog">
            <FormikForm>
              <Modal.Header closeButton>
                <Modal.Title>{title || "Import COG"}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="offset-sm-1 col-sm-10">
                    <UploadFileScreen setRows={setRows} noParse={true} acceptedFiles={acceptedFiles} />
                    <Typography variant="body1" className="import-upload__content__text">
                      {modalText}
                    </Typography>
                    {!short && <Typography
                      variant="body1"
                      className="import-upload__content__text"
                    >
                      {additionalModalText}
                    </Typography>}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="container-button container-button--modal container-button--page">
                  <DButton typeOfButton="doneAllSecondary" disabled={form.isSubmitting || !rows[0]} type="submit">
                    Save and upload
                  </DButton>
                  <DButton typeOfButton="close" onClickCustom={onClose}>Cancel</DButton>
                </div>
              </Modal.Footer>
            </FormikForm>
          </Modal>
        )
      }}
    </Formik>
  )
};

ImportCOG.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  addImportsCOG: PropTypes.func,
  showNotifications: PropTypes.func,
  format: PropTypes.string,
  acceptedFiles: PropTypes.array,
};

export default ImportCOG;