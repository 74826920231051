import React from "react";
import { generalVAT, vat } from "../interfacesForms";
import classNames from "clsx";
import { FieldArray } from "formik";
import { formContent } from "../../../../components/controls/formContent";
import * as PropTypes from "prop-types";
import { TYPES, CURRENT_TAB_DATA, styleList } from "../utils";

const VATSettingsContent = ({ initData, form, tab, header, type, dataName, extraContent }) => {
  const isHistoric = tab === "historic";

  let currentTabFormValues = form.values?.global_vat_current?.[CURRENT_TAB_DATA];
  let isRegistered = currentTabFormValues?.is_vat_registered;
  let registration_date = initData.registration_date;
  let registrationDateForm = currentTabFormValues?.registration_date;
  let isExempt = currentTabFormValues?.vat_exempt_on_amazon;
  let regDate = registrationDateForm || registration_date;
  let firstReg = !initData.is_vat_registered && isRegistered;
  let firstExempt = !initData.vat_exempt_on_amazon && isExempt;

  const contentSchemes = (index) => formContent(
    {
      form,
      interfaces: vat({ initData, tab, dataName }),
      disabled: isHistoric || !isRegistered,
      validator: isRegistered,
      extraProps: { index, dataName },
      className: !isRegistered && !isHistoric && "form__body--inactive",
      classNameLabel: "form-group__label-item"
    }
  );

    const contentVAT = (index) => formContent(
    {
      form,
      interfaces: generalVAT({
        initData,
        tab,
        dataName,
        isRegistered,
        isExempt,
        values: currentTabFormValues
      }),
      disabled: isHistoric || isExempt || !isRegistered,
      extraProps: { index, dataName },
      className: (id) =>
        // for exempt
        // (id === "vat_exempt_on_amazon" && (!(isExempt || isExemptInit) || isRegistered) ) ||
        (id === "vat_exempt_on_amazon" && isRegistered) ||

        // for deregistration_date
        (id === "deregistration_date" && (!regDate || firstReg || firstExempt) )
        ||

        //for registered
        (id === "is_vat_registered" && isExempt)
        ||

        // for body
        (!styleList.includes(id) && (!isRegistered || isExempt))

        || isHistoric
          ? "form__body--inactive"
          : ""
      ,
      classNameLabel: "form-group__label-item"
    }
  );

  return (
    <FieldArray
      name={dataName}
      render={(arrayHelpers) => {
        let data = arrayHelpers.form.values[dataName];
        return <>
          {data && data.length !== 0 && data.map((values, index) => type === TYPES.schemes
            ? (
              <div
                key={index}
                className={classNames("forms__line", {
                  "multiline": isHistoric,
                  "single": !isHistoric,
                  "first-line": !index,
                  [dataName]: dataName,
                })}
              >
                {contentSchemes(index)}
              </div>
            )
            : (
              <div
                key={index}
                className={classNames("forms__line", {
                  "multiline": isHistoric,
                  "single": !isHistoric,
                  "first-line": !index,
                  [dataName]: dataName,
                })}
              >
                {contentVAT(index)}
                {isHistoric && extraContent({ index, values })}
              </div>
            ))}
        </>
      }}
    />
  )
};

VATSettingsContent.propTypes = {
  initData: PropTypes.object,
  form: PropTypes.object,
  tab: PropTypes.string,
  header: PropTypes.node,
  dataName: PropTypes.string,
  type: PropTypes.string,
  extraContent: PropTypes.func,
};

export default VATSettingsContent;