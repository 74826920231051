export const nextCode = (allCodes, value, setFieldValue) => {
  const error = "There are no empty seats in this range. Please choose another type";
  const calcNextCode = (min, max,) => {
    // filter the codes in a given range
    const range = allCodes.filter(nominalCode => nominalCode > min - 1 && nominalCode < max);

    // find the first free seat in this range
    const next = range
      ? range.filter((nominalCode, prevCode) => nominalCode === prevCode + min).length + min
      : min;

    // range overflow check
    if (next > max) {
      // TODO add error handler
      return alert(error);
    } else {
      setFieldValue('code', next);
    }
  };

  switch (value) {
    case "Asset":
      calcNextCode(2, 200);
      break;
    case "Liability":
      calcNextCode(200, 300);
      break;
    case "Equity":
      calcNextCode(300, 400);
      break;
    case "Revenue":
      calcNextCode(400, 500);
      break;
    default:
      calcNextCode(500, 998);
  }
};