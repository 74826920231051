export const typeOptions = [
  { label: "Money In", "value": "MI"},
  { label: "Customer Receipt", "value": "CR"},
  { label: "Supplier Payment", "value": "SP"},
  { label: "Money Out", "value": "MO"},
];

export const typeEditorOptions = [
  {label: "Money In", "value": "MI"},
  {label: "Money Out", "value": "MO"},
];

export const taxCodeOptions = [
  { label: "Standard VAT - 20%", "value": "ST"},
  { label: "Reduced Rate - 5%", "value": "RR"},
  { label: "Non VATable - 0%", "value": "NV"},
  { label: "VAT Exempt or Zero-Rated - 0%", "value": "EX"},
  { label: "EU Goods - 0%", "value": "EG"},
];



export const entryMethods = {
  FILE_IMPORT: "File Import",
  BANK_FEED: "Bank Feed",
  STRIPE_FEED: "Stripe Feed",
  MANUAL: "Manual",
  PAYPAL_FEED: "PayPal Feed",
};

export const entryMethodOptions = [
  { label: entryMethods.BANK_FEED, "value": entryMethods.BANK_FEED},
  { label: entryMethods.FILE_IMPORT, "value": entryMethods.FILE_IMPORT},
  { label: entryMethods.MANUAL, "value": entryMethods.MANUAL},
  { label: entryMethods.STRIPE_FEED, "value": entryMethods.STRIPE_FEED},
  { label: entryMethods.PAYPAL_FEED, "value": entryMethods.PAYPAL_FEED},
];