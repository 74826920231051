import { useEffect, useState } from "react";
import { getResource } from "../pages/home/utils";

export const useGeolocation = (props = {}) => {
  const { fetch = true } = props;
  const [geolocation, setGeolocation] = useState({});
  useEffect(() => {
    if (fetch) {
      getResource("https://geolocation-db.com/json/")
        .then(body => {
          setGeolocation(body)
        })
        .catch((error) => {
            console.log(error);
        });
    }
  }, [fetch]);
  return { geolocation };
};
