import React from "react";
import TableCell from "@material-ui/core/TableCell";
import classNames from "clsx";
import Flag from "../Dashboard/components/Flag";
import moment from 'moment';
import {momentFormatOptions, onSplitDate} from "../utils";
import { IconButton } from "@material-ui/core";
import { marketplaceNames } from "../../components/Marketplaces/Marketplaces";
import { toAbsoluteUrl } from "../../../../_metronic";
import Avatar from "@material-ui/core/Avatar";

export const headRows = [
  // {
  //   id: "report-icon",
  //   label: "Report",
  //   align: "center",
  //   disabled: true,
  //   render: (row) => {
  //     return (
  //       <TableCell
  //         align="center"
  //         key={`${row.id}--report-image`}
  //         className="table__cell--report-image"
  //       >
  //         <div className="block">
  //           <img className="table__cell__img--icon" src={toAbsoluteUrl("/media/logos/new-logo-icon.svg")} alt="img"/>
  //         </div>
  //       </TableCell>
  //     )
  //   },
  // },
  {
    id: "report",
    label: "Report Type",
    align: "center",
    render: (row) => {
      return (
        <TableCell
          align="center"
          key={`${row.id}--report`}
          className="table__cell--name"
        >
          <span>{row.report}</span>
        </TableCell>
      )
    },
  },
  {
    id: "from_date",
    label: "From",
    align: "center",
    renderText: ({ from_date }) => from_date ? moment(from_date).format(momentFormatOptions.display) : "-",
  },
  {
    id: "to_date",
    label: "To",
    align: "center",
    renderText: ({ to_date }) => to_date ? moment(to_date).format(momentFormatOptions.display) : "-",
  },
  {
    id: "marketplace",
    label: "Market",
    align: "center",
    disabled: true,
    render: (row) => {
      return (
        <TableCell
          align="center"
          key={`${row.id}--marketplace`}
          className={classNames("table__cell--marketplace", { "table__cell--child": row.parent })}
        >
          <div className="wrap--marketplace">
            {row?.marketplace.map((item) => (
              <Flag
                key={`${item}--marketplace`}
                marketplace={item}
                placement="top"
                tooltip={marketplaceNames[item]}
              />
            ))}
          </div>
        </TableCell>
      )
    },
  },
  {
    id: "request_date",
    label: "Request Date",
    align: "center",
    renderText: ({ request_date }) =>
      request_date ? onSplitDate(moment(request_date).format(momentFormatOptions.display)) : "-"
  },
  {
    id: "report_type",
    label: "Report Type",
    align: "center",
    renderText: ({ report_type }) => report_type
  },
  // {
  //   id: "view",
  //   label: "View",
  //   align: "center",
  //   disabled: true,
  //   render: (row) => {
  //     return (
  //       <TableCell
  //         align="center"
  //         key={`${row.id}--view`}
  //         className={classNames("table__cell--view")}
  //       >
  //         <IconButton
  //           className="main-reports__view-btn"
  //           aria-controls="view-button"
  //         >
  //           <VisibilityIcon fontSize="large" htmlColor="var(--main)" />
  //         </IconButton>
  //       </TableCell>
  //     )
  //   },
  // },
  {
    id: "download",
    label: "Download",
    align: "center",
    disabled: true,
    render: (row) => {
      const disabled = !row.file;
      return (
        <TableCell
          align="center"
          key={`${row.id}--download`}
          className={classNames("table__cell--download")}

        >
          <IconButton
            className="main-reports__download-btn"
            aria-controls="download-button"
            disabled={disabled}
          >
            <a href={`${process.env.REACT_APP_ENDPOINT}${row.file}`} download target="_blank" rel="noopener noreferrer">
              <img className="table__cell__img--icon" src={toAbsoluteUrl("/media/d-icons/report_download.svg")} alt="img"/>
            </a>
          </IconButton>
        </TableCell>
      )
    },
  },
  {
    id: "delete",
    label: "Delete",
    align: "center",
    disabled: true,
    render: (row, { onDeleteReport }) => {
      return (
        <TableCell
          align="center"
          key={`${row.id}--delete`}
          className={classNames("table__cell--delete")}
        >
          <IconButton
            className="main-reports__delete-btn"
            aria-controls="delete-button"
            onClick={onDeleteReport(row.id)}
          >
            <img className="table__cell__img--icon" src={toAbsoluteUrl("/media/d-icons/report_delete.svg")} alt="img"/>
          </IconButton>
        </TableCell>
      )
    },
  },
];
