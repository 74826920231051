import React, { useState } from 'react';
import * as PropTypes from "prop-types";
import { useCheckboxStorage, usePerPage } from "../../../hooks/modal.hook";
import TableComponent from "../../components/TableComponent/TableComponent";
import EmptyBlock from "../../components/EmptyBlock";
import TableToolbarComponent from "../../components/TableComponent/TableToolbarComponent";
import moment from "moment";
import withConfirmHook from "../../../hooks/withConfirm.hook";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";
import { goToEditAdjustmentPage } from "./utils";
import TableCell from "@material-ui/core/TableCell";
import {momentFormatOptions} from "../utils";

const headRowsModal = [
  {
    id: 'date', label: 'Date', type: 'date', isActive: true,
    renderText: (row) => moment(row.date, 'YYYY-MM-DDTHH:mm:ss.SSS').format(momentFormatOptions.backend),
  },
  {
    id: 'description', label: 'Description', type: 'text', isActive: true,
    render: (row, { onEdit }) => (
      <TableCell align="left" key={`${row.id}--description`} onClick={onEdit(row)} className="table__cell--link">
        <span className="table__cell--link__text" >{row.description}</span>
      </TableCell>
    )
  },
  { id: 'total', label: 'Total', type: 'number', isActive: true,
    // renderText: (row) => {
    //   return withCurrency(row.total, row.currency.currency_symbol);
    // }
  },
  { id: 'actions', label: 'Actions', isActive: true },
];

export const adjustmentsItems = [
  {
    id: 0,
    date: "02/01/2020",
    description: "Adjustment",
    total_credit_amount: 1111.00,
    total_debit_amount: 1111.00,
    total: 1111.00,
    currency: {label: "Euro", value: "158" },
    conv_rate: 20,
    amounts: [
      {
        category: {label: "Sales", value: "205"},
        debit_amount: undefined,
        credit_amount: 1111.00,
      },
      {
        category: {label: "Sales", value: "205"},
        debit_amount: 1111.00,
        credit_amount: undefined,
      }
    ]
  },
  {
    id: 1,
    date: "02/01/2020",
    description: "Description Adjustment",
    total_credit_amount: 411.00,
    total_debit_amount: 411.00,
    total: 411.00,
    currency: {label: "British Pound", value: "156" },
    conv_rate: 10,
    amounts: [
      {
        category: {label: "Amazon Payment Holding", value: "204"},
        debit_amount: undefined,
        credit_amount: 411.00,
      },
      {
        category: {label: "UK Amazon Account", value: "9"},
        debit_amount: 411.00,
        credit_amount: undefined,
      }
    ]
  },
];

function AdjustmentsModal(props) {
  const { show, onClose, confirm,
    // loading,  deleteAdjustment, getAdjustments, adjustments
  } = props;
  const [activeColumns, setActiveColumns] = useCheckboxStorage("adjustmentsModal", headRowsModal);
  const [rowsPerPage, onPerPage] = usePerPage();
  const title = "Adjustments";
  let history = useHistory();
  const [searchQuery, setSearchQuery] = useState(null);

  // useEffect(() => {
  //   getAdjustments({ companyId: getCurrentCompanyId(), q: searchQuery });
  // }, [searchQuery]);

  const onRefresh = () => {
    // getAdjustments({ companyId: getCurrentCompanyId() });
  };

  const onExport = () => console.log("onExport");

  const onEdit = (row) => () => {
    goToEditAdjustmentPage(history, row.id);
    onClose();
  };

  const onDelete = (row) => (
    confirm(() => {
        // deleteAdjustment({id: row.id})
      },
      {description: 'This action is permanent!'})
  );

  // when will be api need replace adjustmentsItems with adjustments
  const content = adjustmentsItems.length > 0
    ? <TableComponent
        headRows={activeColumns.filter(column => column.isActive)}
      // when will be api need replace adjustmentsItems with adjustments
        items={adjustmentsItems}
        rowsPerPageSelected={rowsPerPage}
        rowProps={{ onEdit, onDelete }}
      />
    : <EmptyBlock/>;

  return (
    <Modal show={show} onHide={onClose} className="trans-modal">
      <Modal.Header closeButton>
        <TableToolbarComponent
          className="trans-modal__table-toolbar"
          title={title}
          setSearchQuery={setSearchQuery}
          actionsHeader={{
            onRefresh,
            onExport,
            selectMenu: { setFunc: onPerPage, type: "select" },
            viewColumnMenu: {
              array: activeColumns,
              type: "filter",
              setFunc: setActiveColumns,
              disabled: true,
              className: "adjustment-modal__menu"},
          }}
        />
      </Modal.Header>
      <Modal.Body>
        {content}
      </Modal.Body>
    </Modal>
  );
}

AdjustmentsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  // adjustments: PropTypes.array.isRequired,
  // getAdjustments: PropTypes.func,
  // deleteAdjustment: PropTypes.func,
  show: PropTypes.bool,
};

export default withConfirmHook(AdjustmentsModal);
