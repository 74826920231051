import React, { useEffect, useRef, useState } from "react";
import Wrapper from "../../components/Wrapper";
import TableComponent from "../../components/TableComponent/TableComponent";
import { headRows } from "./headRows"
import DSplashScreen from "../../components/SplashScreen/DSplashScreen";
import useTableParams from "../../../hooks/useTableParams";
import useUsers from "../../../hooks/users.hook";
import { currencySymbols, fulfillmentFullNames } from "../utils";
import useProducts from "../../../hooks/products.hook";
import useTab from "../../../hooks/tab.hook";
import OverlayComponent from "../../components/OverlayComponent";
import DTabs from "../../components/DTabs/DTabs";
import { headRowsImports } from "./headRowsImports";
import TabsToolbar from "./TabsToolbar";
import debounce from "lodash/debounce";
import classNames from "clsx";
import useReports from "../../../hooks/reports.hook";
import { EmptyBBP, EmptyWarning } from "./elements";
import { options } from "./utils";
import AsinOverview from "./AsinOverview";
import './AsinOverview/metrics-style.scss';
import withConfirmHook from "../../../hooks/withConfirm.hook";
import useSettings from "../../../hooks/settings.hook";


const Products = ({ location, history }) => {
  const {
    getProducts,
    addProduct,
    updateProduct,
    deleteProduct,
    products,
    loading,
    productTotalCount,
    imports,
    importsTotalCount,
    loadingImports,
    getImportsProducts,
    deleteBBP,
    getReimbursements,
  } = useProducts();
  const fromDashboard = !!location.state?.no_inventory_cost;

  let initialInventoryCostToggleValue = false;
  let initialNoCogsToggleValue = false;

  if (location.state?.stock_or_inactive === true){
    initialInventoryCostToggleValue = true;
  }

  if (location.state?.no_inventory_cost === true){
    initialNoCogsToggleValue = true;
  }
  // let initParams;
  let initParams = {roi_method: 4, ...location.state};
  if (fromDashboard) {
    history.replace();
    initParams = { ...initParams, no_inventory_cost: true, stock_or_inactive: initialInventoryCostToggleValue}
  }


  // const {setParams, params} = useTableParams(getProducts);
  const {setParams, params} = useTableParams(getProducts, initParams);
  const {setParams: setImportsParams} = useTableParams(getImportsProducts, { stock_or_inactive: true });
  const {setParams: setReimbursementParams} = useTableParams(getReimbursements);
  const {user} = useUsers({fetch: false});
  const [currentTab, setTab] = useTab('inventory');
  const [isResetPagination, setResetPagination] = useState(false);
  const [related, setRelated] = useState(null);
  const [restock, setRestock] = useState(null);
  const [isWarning, setWarning] = useState(false);
  const [noInventoryCost, setNoInventoryCost] = useState(initialNoCogsToggleValue);
  const [outOfStock, setOutOfStock] = useState(initialInventoryCostToggleValue);
  const [usedParams, setUsedParams] = useState([]);
  const [currentRow, setCurrentRow] = useState({});
  const [showMetricsRow, setChecked] = useState(-1);

  const [height, setHeight] = useState(0);

  const refElem = useRef(null);
  const {getOrderReport, orderReport, orderReportLoading: isLoading, reportId, reportType} = useReports();
  const { settings } = useSettings({ cog: true });

  const onHoverRow = (row, type, isShowReport) => () => {
    isShowReport && (reportId !== row.id || reportType !== type) && getOrderReport({id: row.id, type});
    if (row.id !== currentRow.id) {
      setCurrentRow(row)
    }
  };

  useEffect(() => {
    if (currentTab === "imports") {
      setOutOfStock(true);
    }
  }, [currentTab]);

  useEffect(() => {
    location?.state?.q && history.push({...location, state: {...location.state, q: ""}});
  }, [history, location]);

  useEffect(() => {
    if (related) {
      setRelated(products.find(product => product.id === related.id))
    }
    setHeight(refElem.current.clientHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab, products, imports]);

  const onShowRelated = (row) => () => {
    setRelated(related && row.id === related.id ? null : row);
  };

  const onShowRestock = (row) => () => {
    setRestock(restock && row.restock === restock ? null : row.restock)
  };

  const data = [];
  products.forEach((product) => {
    data.push(product);
    if (related?.id === product.id) {
      related.related.forEach((item) => {
        const marketplaceData = {...item};
        marketplaceData.relatedProduct = true;
        data.push(marketplaceData);
      })
    }

    if (currentTab === "inventory") {
      const { id } = product;
      const metricsData = {
        ...product,
        id: `${id}--metrics-row`,
        isMetricsRow: true,
        product_id: id
      };
      data.push(metricsData);
    }

    // product.restocks.forEach((restock_group) => {
    // {
    //   return restock_group.forEach((restock_product, idx) => {
    //     if (idx === 0 || restock === restock_product.restock) {
    //       const restockData = {...restock_product};
    //       restockData.otherMarketplace = idx !== 0;
    //       data.push(restockData);
    //     }
      // })

    product.restocks.filter(restock_product => restock_product.length)
    .forEach(restock_group=> {
      restock_group.forEach(restock_product => {
        data.push(restock_product)
      })
    })
  });

  const handleDisplayMetrics = (e, row) => {
    setChecked((prev) => prev === row.id ? -1 : row.id);
  };

  const getEmptyInventory = () => {
    if (isWarning) {
      return <EmptyWarning />;
    }
  };
  const injectReloadOnDone = (fn) => (args) => fn({...args, onDone: () => getProducts(params)});

  const decoratorDebounce = (param, setParams, ms) => debounce((q) => {
    const params = {offset: 0, ordering: ""};
    params[options[param]] = q;
    if (!usedParams.includes(param)) {
      setUsedParams(usedParams => [...usedParams, param]);
      if (param === "q" && !usedParams.includes("stock_or_inactive")) {
        params.stock_or_inactive = true;
      }
    }
    setParams(params);
    setRelated(null);
    setResetPagination(true);
  }, ms || 500);

  const clientHeight = window.innerHeight;
  const remainingHeight = (clientHeight - height - 250) > 0;
  const emptyWording = params.q in fulfillmentFullNames && `You don't have any ${fulfillmentFullNames[params.q]} Inventory`
  const tabsData = [
    {
      value: "inventory",
      label: <OverlayComponent tooltip=""><span>Inventory</span></OverlayComponent>,
      toolbar: <TabsToolbar
          initSearch={location?.state?.q}
          setSearchQueryParam={decoratorDebounce('q', setParams)}
          tab={currentTab}
          setStockParam={decoratorDebounce('stock_or_inactive', setParams, 1)}
          setWarningSearchParam={decoratorDebounce('only_warning', setParams, 1)}
          setNoInventoryCostParam={decoratorDebounce('no_inventory_cost', setParams, 1)}
          setNoInventoryCost={setNoInventoryCost}
          setRoiMethod={decoratorDebounce('roi_method', setParams, 1)}
          noInventoryCost={noInventoryCost}
          setOutOfStock={setOutOfStock}
          outOfStock={outOfStock}
          isWarning={isWarning}
          setWarning={setWarning}
          usedParams={usedParams}
          importAction
          exportAction
      />,
      toolbarBottom: <TabsToolbar
        initSearch={location?.state?.q}
        setSearchQueryParam={decoratorDebounce('q', setParams)}
        tab={currentTab}
        setStockParam={decoratorDebounce('stock_or_inactive', setParams, 1)}
        setWarningSearchParam={decoratorDebounce('only_warning', setParams, 1)}
        setNoInventoryCostParam={decoratorDebounce('no_inventory_cost', setParams, 1)}
        setNoInventoryCost={setNoInventoryCost}
        setRoiMethod={decoratorDebounce('roi_method', setParams, 1)}
        noInventoryCost={noInventoryCost}
        setOutOfStock={setOutOfStock}
        outOfStock={outOfStock}
        isWarning={isWarning}
        setWarning={setWarning}
        usedParams={usedParams}
        importAction
        exportAction
        isBottom
      />,
      content: <div className={classNames("d-splash-screen--wrap", {"small-screen": remainingHeight})} ref={refElem}>
        {loading && <DSplashScreen/>}
        <TableComponent
            emptyWording={emptyWording || (!data.length && params.q) ? 'Not Found' : "Congratulations! All of your ASINs have COGs 👍"}
            className="products__table"
            headRows={headRows}
            items={data}
            count={productTotalCount}
            loading={loading}
            rowProps={{
              currentRow,
              onHoverRow,
              orderReport,
              isLoading,
              reportType,
              onShowRelated,
              onShowRestock,
              contentCellProps: {
                add: injectReloadOnDone(addProduct),
                update: injectReloadOnDone(updateProduct),
                deleteItem: deleteProduct,
                isTooltip: true,
                type: "products",
                bepType: "product",
              },
              currency: currencySymbols[user.currency],
              handleDisplayMetrics,
              showMetricsRow,
              metricsRow: (row) => (
                <AsinOverview
                  showMetricsRow={showMetricsRow}
                  row={row}
                  key={`${row.id}--metric-cell`}
                  handleDisplayMetrics={handleDisplayMetrics}
                  colSpan={headRows.length + 1}
                  currency={currencySymbols[user.currency]}
                />
              )
            }}
            isResetPagination={isResetPagination}
            setResetPagination={setResetPagination}
            setParams={setParams}
            forEmpty={getEmptyInventory()}
        />
      </div>
    },
    {
      value: "imports",
      label: <OverlayComponent
          tooltip="Use this tab to view the data imported
          from BuyBotPro which is currently awaiting sync with Amazon"
      >
        <span>BuyBotPro Imports</span>
      </OverlayComponent>,
      toolbar: <TabsToolbar
          setSearchQueryParam={decoratorDebounce('q', setImportsParams)}
          tab={currentTab}
          setStockParam={decoratorDebounce('stock_or_inactive', setImportsParams, setRelated, 1)}
          setWarningSearchParam={decoratorDebounce('only_warning', setImportsParams, setRelated, 1)}
          setNoInventoryCostParam={decoratorDebounce('no_inventory_cost', setImportsParams, setRelated, 1)}
          setNoInventoryCost={setNoInventoryCost}
          noInventoryCost={noInventoryCost}
          setOutOfStock={setOutOfStock}
          outOfStock={outOfStock}
          isWarning={isWarning}
          setWarning={setWarning}
          usedParams={usedParams}
      />,
      toolbarBottom: <TabsToolbar
          setSearchQueryParam={decoratorDebounce('q', setImportsParams)}
          tab={currentTab}
          setStockParam={decoratorDebounce('stock_or_inactive', setImportsParams, setRelated, 1)}
          setWarningSearchParam={decoratorDebounce('only_warning', setImportsParams, setRelated, 1)}
          setNoInventoryCostParam={decoratorDebounce('no_inventory_cost', setImportsParams, setRelated, 1)}
          setNoInventoryCost={setNoInventoryCost}
          noInventoryCost={noInventoryCost}
          setOutOfStock={setOutOfStock}
          outOfStock={outOfStock}
          isWarning={isWarning}
          setWarning={setWarning}
          usedParams={usedParams}
          isBottom
      />,
      content: (
          <div className={classNames("d-splash-screen--wrap", {"small-screen": remainingHeight})} ref={refElem}>
            {loadingImports && <DSplashScreen/>}
            <TableComponent
                className="products__table"
                headRows={headRowsImports}
                items={imports}
                count={importsTotalCount}
                loading={loadingImports}
                rowProps={{
                  currentRow,
                  onHoverRow,
                  orderReport,
                  isLoading,
                  reportType,
                  contentCellProps: {
                    update: updateProduct,
                    deleteItem: deleteProduct,
                    type: "imports",
                    bepType: "product",
                  },
                  settings,
                  currency: currencySymbols[user.currency],
                  deleteBBP
                }}
                isResetPagination={isResetPagination}
                setResetPagination={setResetPagination}
                setParams={setImportsParams}
                forEmpty={<EmptyBBP/>}
            />
          </div>
      )
    }
  ];
  const onChangeTab = (event, newValue) => {
    setTab(event, newValue);
    setResetPagination(true);
    setUsedParams([]);
    const emptyParams = {
      offset: 0,
      ordering: "",
      q: "",
      stock_or_inactive: newValue === "imports",
      only_warning: "",
      no_inventory_cost: ""
    };
    setParams(emptyParams);
    setImportsParams(emptyParams);
    setReimbursementParams(emptyParams);
  };

  return (
      <Wrapper elevation={0}>
        <div className="products page__table__wrap kt-portlet">
          <DTabs value={currentTab} tabsData={tabsData} onChange={onChangeTab} className="products__tabs dashboard"/>
        </div>
      </Wrapper>
  )
};

export default withConfirmHook(Products);
