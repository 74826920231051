import React from "react";
import {CardContentProps, CardItemProps, CardProps, ContentProps, HeaderProps, ToggleProps} from "./types";
import {Button, Divider, Step, StepButton, Stepper, Typography} from "@material-ui/core";
// @ts-ignore
import classNames from 'clsx';
import {toAbsoluteUrl} from "../../../../_metronic";
import {images} from "./OnboardingSubscribe/utils";
import {ReactComponent as CheckIcon} from "../../../../images/onboarding/list-icon.svg";
import './onboarding.scss'

interface OnboardingLayoutProps {
  children: JSX.Element;
  button?: JSX.Element;
  title?: string;
  currentStep?: number;
}

export const OnboardingLayout = ({children, button, title, currentStep = 0}: OnboardingLayoutProps) => {
  return (
    <div className="onboarding">
      {button}
      <img alt="Logo" src={toAbsoluteUrl("/media/logos/new-logo.svg")}/>
      {!!title && <Typography className="onboarding__title">{title}</Typography>}
      <Stepper alternativeLabel nonLinear activeStep={currentStep}>
        {[0, 1, 2, 3].map((item) => {
          return (
            <Step key={item}>
              <StepButton />
            </Step>
          );
        })}
      </Stepper>
      {children}
    </div>
  )
};

export const Toggle = ({buttons, active, onToggle, group}: ToggleProps) => {
  const activeBtns = active.map(({name}) => name);
  return (
    <div className="toggles__items">
      {
        // @ts-ignore
        buttons[group].map(({value, name, symbol}) => {
            const nameBtn = symbol ? `${value}${symbol}` : value;
            return (
              <Button
                key={name}
                variant="contained"
                size="medium"
                color="primary"
                className={classNames({'active': activeBtns.includes(name)})}
                onClick={() => onToggle(name, group)}
              >
                {nameBtn}
              </Button>
            )
          }
        )}
    </div>
  )
};

const CardContent = (props: CardContentProps) => {
  const {title, subtitle, url, list, onCard, icon_url, priceDisplay, isActive} = props;
  return (
    <div className="subscription__card">
      {icon_url}
      {/*<img src={toAbsoluteUrl(icon_url)} alt="" className="card__icon"/>*/}
      {/*<img src={"./../../../../../images/onboarding/037---Waypoint-Flag.svg"} alt="" className="card__icon"/>*/}
      <Typography className="card__title">{title}</Typography>
      <Typography className="card__subtitle">{subtitle}</Typography>
      <Typography className="card__price">{priceDisplay}<span>/month</span></Typography>
      <Divider className="card__divider" />
      <ul className="card__list">
        {
          list.map((text) => (
            <li key={text} className="list__item">
              <CheckIcon/>
              {/*<img src={toAbsoluteUrl('./media/d-icons/onboarding/list-icon.svg')} alt="" className="list__icon"/>*/}
              <p className="list__text">{text}</p>
            </li>
          ))
        }
      </ul>
      <Button
        variant="contained"
        size="medium"
        color="primary"
        className={classNames({active: isActive})}
        onClick={() => onCard(url)}
        disabled={isActive}
      >
        {isActive ? 'Your current plan' : 'Subscribe'}
      </Button>
    </div>
  )
};

const imgCardContentPrice: {[key: number]: string} = {
  0:"media/codely-theme-files/assets/images/new/unlimited.png",
  1:"media/codely-theme-files/assets/images/new/Free-Trial-01.svg",
  // 1: "media/codely-theme-files/assets/images/new/unlimited.png",
  // 2: "media/codely-theme-files/assets/images/new/Premium.svg",
  2: "media/codely-theme-files/assets/images/new/unlimited.png",
};

const CardContentPrice = (props: CardContentProps) => {
  const {index, title, subtitle, url, list, onCard, icon_url, priceDisplay, isActive, qty} = props;
  const odd = index % 2;
  return (
    <div className={classNames("col-md-4", {
      "offset-md-4": qty === 1,
      "offset-md-1": qty === 2 && !odd,
      "offset-md-2": qty === 2 && odd
    })}>
      <div className={classNames("pricing_block my-3", {'highlited_block': (index % 3) === 0 || (index % 3) === 2 })}>
        <div className="icon">
          <img src={imgCardContentPrice[`${index % 3}`]} alt="image"/>
          <div className="dot_block">
            <span className="dot_anim"></span>
            <span className="dot_anim"></span>
            <span className="dot_anim"></span>
          </div>
        </div>
        <div className="pkg_name">
          <h3>{title}</h3>
          <span>{subtitle}</span>
        </div>
        <span className="price">{priceDisplay}<span>/Month</span></span>
        <ul className="benifits">
          {
            list.map((text, index) => (
              <li key={`${title}-${index}`} className="include">
                <p><i className="icofont-check-circled"></i>{text}</p>
              </li>
            ))
          }
        </ul>
        <Button
          variant="outlined"
          size="medium"
          color="primary"
          className="btn btn_main"
          onClick={() => onCard(url)}
          disabled={isActive}
        >
          BUY NOW
        </Button>
      </div>
    </div>
  )
};

export const Card = (props: CardProps) => {
  const { url, price, currency, current, isPricing } = props;

  const isActive = current === url;
  const priceDisplay = `${currency.symbol}${price}`;

  return (
    isPricing
      ? <CardContentPrice isActive={isActive} priceDisplay={priceDisplay} {...props} />
      : <CardContent isActive={isActive} priceDisplay={priceDisplay} {...props} />
  )
};

export const Header = ({buttons, active, onToggle}: HeaderProps) => {
  return (
    <div className="subscription__header">
      <Typography className="text subscription__title">Pricing Plans</Typography>
      <Typography className="text subscription__subtitle">
        All plans include our incredible search tool and other features for boosting your sales.
      </Typography>

      {!!buttons && <div className="toggles">
        <Toggle buttons={buttons} active={active} onToggle={onToggle} group="period"/>
        <Divider className="subscription__divider" orientation="vertical" flexItem/>
        <Toggle buttons={buttons} active={active} onToggle={onToggle} group="location"/>
      </div>}
    </div>
  )
};

export const Content = ({subscriptionList, subscribe, onCard, current, isPricing}: ContentProps) => {
  return (
    <>
      {subscriptionList.map((subscription, index) => {
        // @ts-ignore
        const subscriptionUpdate: CardItemProps = {...subscription, icon_url: images[index] || images[0]};
        return (
          <Card
            key={index}
            index={index}
            subscribe={subscribe}
            onCard={onCard}
            current={current}
            isPricing={isPricing}
            qty={subscriptionList.length}
            {...subscriptionUpdate}
          />
        )
      })}
    </>
  )
};

export const BottomButtons = ({disabled, onBack}: any) => {
  return (
    <div className="bottom-buttons">
      {!!onBack && (
        <Button
          className="back"
          variant="outlined"
          size="medium"
          onClick={onBack}
        >
          Back
        </Button>
      )}
      {/*{!!onNext && (*/}
        <Button
          type="submit"
          variant="contained"
          size="medium"
          color="primary"
          disabled={disabled}
        >
          Next
        </Button>
       {/*)}*/}
    </div>
  )
};