import {Btns} from "../types";
import { ReactComponent as Standart } from "../../../../../images/onboarding/037---Waypoint-Flag.svg";
import { ReactComponent as Prof } from "../../../../../images/onboarding/prof-icon.svg";
import { ReactComponent as Enterprise } from "../../../../../images/onboarding/enterprise.svg";
import * as React from "react";

export const buttons: Btns = {
  period: [
    {value: 'Monthly', name: 'months'},
    {value: 'Yearly', name: 'years'},
  ],
  location: [
    {value: 'GBP', symbol: '£', name: 'UK'},
    {value: 'US', symbol: '$', name: 'US'},
  ]
};

export const initActive = [
  {name: buttons.period[0].name, group: 'period'},
  {name: buttons.location[0].name, group: 'location'}
];

export const images = {
  0: <Standart />,
  1: <Prof />,
  2: <Enterprise />,
};