import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Animation from '../Animation/Main'
import { toAbsoluteUrl } from "../../../../../../_metronic";
import Terms3 from "../../../../../media/Terms_2024.pdf";
import Privatpolicy from "../../../../../media/Privatpolicy.pdf";
import moment from "moment";
import Form from "react-bootstrap/Form";
import { Form as FormikForm, Formik } from "formik";
import FormField from "../../../../components/controls/FormField";
import { sendMail } from "../../utils";
import useOnboarding from "../../../../../hooks/onboarding.hook";
import toast from "../../../../components/toast/toast";

export const usefulMenu = [
  { name: 'Home', link: '/', anchor: '#', global: false },
  { name: 'Features', link: '', anchor: '#features-section-anchor', global: false },
  { name: 'Pricing', link: '', anchor: '#pricing-anchor', global: false },
  { name: 'Free Course ', link: 'https://www.arbiversity.com/', anchor: '', global: true },
];

export const helpMenu = [
  { name: 'Contact us', link: '/contact', anchor: '', global: false },
  { name: 'FAQs', link: '', anchor: '#faqBlock-anchor', global: false },
  { name: 'Terms & Conditions', link: Terms3, anchor: '', global: true },
  { name: 'Privacy Policy', link: Privatpolicy, anchor: '', global: true },
];

const formInterface = [
  {
    id: 'email',
    label: 'Email',
    type: 'email',
    placeholder: 'Enter your email'
  }
];

function Main({ parent }) {
  const { pathname, hash, key } = useLocation();
  const { addContactGetResponse } = useOnboarding();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change

  const onAnchor = (anchor) => {
    window.location.href = anchor;
  };

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    const onDone = (data) => {
      setSubmitting(false);
      resetForm();

      if (data?.email) {
        toast.success(`Successfully!`, { duration: 3000 })
      }

      if (data?.httpStatus) {
        toast.error(data.message || 'Something went wrong!', { duration: 5000 })
      }
    };

    const onError = (error) => {
      setSubmitting(false);
      // toast.error(error, {duration: 5000}, {isClose: false});
      toast.error(`Something went wrong!`, { duration: 5000 })
    };

    addContactGetResponse({ values, onDone, onError })
  };

  return (
    <>
      <footer>
        <div className="top_footer" id="contact">
          <Animation />
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="abt_side">
                  <div className="logo"> <img src={toAbsoluteUrl("media/logos/new-logo-w-2.svg")} alt="image"/></div>
                  <p>Want to get regular updates about new features? Sign up to our news letter!</p>
                  <div className="news_letter_block">
                    <Formik
                      enableReinitialize
                      initialValues={{ email: '' }}
                      onSubmit={onSubmit}
                    >
                      {({ ...form }) => {
                        return <FormikForm>
                            {formInterface.map(item => (
                              <Form.Group key={item.id} controlId={item.id} className={`form-group--${item.id}`}>
                                <FormField
                                  name={item.id}
                                  label={item.label}
                                  placeholder={item.placeholder}
                                  type={item.type}
                                  {...form}
                                />
                                <button className="btn" disabled={form.isSubmitting || !form.values.email } />
                              </Form.Group>
                            ))}
                        </FormikForm>
                      }}
                    </Formik>
                    {/*<form action="submit">*/}
                      {/*<div className="form-group">*/}
                        {/*<input type="email" placeholder="Enter your email" className="form-control" />*/}
                        {/*<button className="btn"></button>*/}
                      {/*</div>*/}
                    {/*</form>*/}
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-6 col-12">
                <div className="links">
                  <h3>Useful Links</h3>
                  <ul>
                    {usefulMenu.map(({ name, link, anchor, global }) => (
                        <li key={name}>
                          {global ? (
                              <a href={link} target="_blank">
                                {name}
                              </a>
                            ) : (
                              <Link
                                to={`${parent ? parent : link}${anchor}`}
                                onClick={() => anchor ? onAnchor(anchor) : undefined}
                              >
                                {name}
                              </Link>
                            )}
                        </li>
                      ))
                    }
                  </ul>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-12">
                <div className="links">
                  <h3>Help & Suport</h3>
                  <ul>
                    {helpMenu.map(({ name, link, anchor, global }) => (
                      <li key={name}>
                        {global ? (
                            <a href={link} target="_blank">
                              {name}
                            </a>
                          ) : (
                            <Link
                              to={`${parent ? parent : link}${anchor}`}
                              onClick={() => anchor ? onAnchor(anchor) : undefined}
                            >
                              {name}
                            </Link>
                          )}
                      </li>
                    ))
                    }
                  </ul>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-12">
                <div className="try_out">
                  <h3>Contact us</h3>
                  <ul>
                    <li>
                      <span className="icon">
                        <img src={toAbsoluteUrl("media/codely-theme-files/assets/images/skuify/tg.png")} alt="support"/>
                      </span>
                      <div className="text">
                        <p>Email our support team <a href="mailto:info@skuify.com">info@skuify.com</a></p>
                      </div>
                    </li>
                    <li>
                      <span className="icon">
                        <img src={toAbsoluteUrl("media/codely-theme-files/assets/images/skuify/live-chat.png")} alt="live-chat"/>
                      </span>
                      <div className="text">
                        <p>Reach out via live chat <Link to="/contact">24/7</Link></p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom_footer">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <p>{`© Copyrights ${moment().year()}. All rights reserved.`}</p>
              </div>
              <div className="col-md-4">
                <ul className="social_media">
                  <li>
                    <a href="https://www.instagram.com/skuifyapp">
                      <img src={toAbsoluteUrl("media/codely-theme-files/assets/images/skuify/instagram.png")} alt="instagram"/>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/groups/skuify">
                      <img src={toAbsoluteUrl("media/codely-theme-files/assets/images/skuify/facebook.png")} alt="facebook"/>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/@skuifyapp">
                      <img src={toAbsoluteUrl("media/codely-theme-files/assets/images/skuify/youtube.png")} alt="youtube"/>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.tiktok.com/@skuifyapp">
                      <img src={toAbsoluteUrl("media/codely-theme-files/assets/images/skuify/tiktok.png")} alt="tiktok"/>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-4">
              </div>
            </div>
          </div>
        </div>

        <div className="go_top" id="Gotop">
          <span><i className="icofont-arrow-up"></i></span>
        </div>
       </footer>
    </>
  )
}

export default Main
