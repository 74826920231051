import React from "react";
import * as PropTypes from "prop-types";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import classNames from "clsx";

const OverlayComponent = ({ children, tooltip, className, placement="bottom" }) => {
  const renderTooltip = ({ arrowProps, show, outOfBoundaries, scheduleUpdate, ...props }) => (
    <div className={classNames(className, "d-tooltip", { "d-tooltip--empty": !tooltip})} {...props}>
      {tooltip}
    </div>
  );

  return (
    <OverlayTrigger
      placement={placement}
      delay={{ show: 400, hide: 100 }}
      overlay={renderTooltip}
      trigger="hover"
    >
      {children}
    </OverlayTrigger>
  )
};

OverlayComponent.propTypes = {
  className: PropTypes.string,
  placement: PropTypes.string,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default OverlayComponent;