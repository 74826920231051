import React, { useState } from "react";
import { headRowsWL } from "./winnersLosersHead";
import useDashboardTable from "../../../../hooks/dashboardTable.hook";
import useTableParams from "../../../../hooks/useTableParams";
import { currencySymbols } from "../../utils";
import useUsers from "../../../../hooks/users.hook";
import DTabs from "../../../components/DTabs/DTabs";
import TabWithTable from "../OrdersRefunds/TabWithTable";
import OverlayComponent from "../../../components/OverlayComponent";
import DSplashScreen from "../../../components/SplashScreen/DSplashScreen";

const WinnersAndLosers = () => {
  const { getWinners, getLosers, winners, losers, winnersLoading, losersLoading } = useDashboardTable();
  const { setParams: setWParams } = useTableParams(getWinners, { limit: 10, autoRefresh: false });
  const { setParams: setLParams } = useTableParams(getLosers,{ limit: 10, autoRefresh: false });

  const { user } = useUsers({ fetch: false });

  const [currentTab, setTab] = useState("winners");
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const tabsData = [
    {
      value: "winners",
      label: <OverlayComponent tooltip="Use this tab to view your best performing listings">
        <span>Top 10 Winners</span>
      </OverlayComponent>,
      content: <div className="d-splash-screen--wrap">
        {winnersLoading && <DSplashScreen />}
        <TabWithTable
          className="dashboard__table tab1 dashboard__table--w-l"
          data={winners}
          headRows={headRowsWL}
          loading={winnersLoading}
          isPagination={false}
          disabled={true}
          setParams={setWParams}
          rowProps={{ currency: currencySymbols[user.currency] }}
      />
    </div>
    },
    {
      value: "losers",
      label: <OverlayComponent tooltip="Use this tab to view your worst performing listings">
        <span>Top 10 Losers</span>
      </OverlayComponent>,
      content: <div className="d-splash-screen--wrap">
        {losersLoading && <DSplashScreen />}
        <TabWithTable
          className="dashboard__table tab1 dashboard__table--w-l"
          data={losers}
          headRows={headRowsWL}
          loading={losersLoading}
          isPagination={false}
          disabled={true}
          setParams={setLParams}
          rowProps={{ currency: currencySymbols[user.currency] }}
        />
    </div>
    },
  ];

  return (
    <DTabs
      value={currentTab}
      tabsData={tabsData}
      onChange={handleChange}
      className="dashboard__winners-losers--tab-h dashboard"
      // tabsToolbar={
      //   <TabsToolbarWL setSearchQuery={queryParam} setStock={stockParam} tab={currentTab} defaultTab="winners" />
      // }
    />
  )
};

export default WinnersAndLosers;