import toast from "../../components/toast/toast";
import moment from 'moment';

export const testMainReports = {
  "reports": {
    "results": [
      {
        "report": "test-report",
        "from_date": "2021-06-02",
        "to_date": "2021-06-02",
        "marketplace": "A1F83G8C2ARO7P",
        "request_date": "2021-06-02T08:42:16",
        "file": "/media/Sumfully_Inventory_for_Import_Test-Adam-id26_validated_cogs.csv",
        "user": 10
      },
      {
        "report": "test-report-1",
        "from_date": "2020-09-05",
        "to_date": "2021-05-09",
        "marketplace": "A1F83G8C2ARO7P",
        "request_date": "2021-06-02T08:42:16",
        "file": "/media/Sumfully_Inventory_for_Import_Test-Adam-id26_validated_cogs.csv",
        "user": 10
      },
      {
        "report": "test-report-2",
        "from_date": "2021-04-22",
        "to_date": "2021-05-12",
        "marketplace": "A1F83G8C2ARO7P",
        "request_date": "2021-06-02T08:42:16",
        "file": "/media/Sumfully_Inventory_for_Import_Test-Adam-id26_validated_cogs.csv",
        "user": 10
      },
    ],
    "count": 3
  },
};

export const filtersOptions = [
  { value: "", label: "All reports" },
  { value: "cog_everything", label: "Cost of Goods - everything" },
  { value: "cog_missing", label: "Cost of Goods - missing information" },
  { value: "sales_everything", label: "Sales - everything" },
  { value: "sales_winners", label: "Sales - winners" },
  { value: "sales_losers", label: "Sales - losers" },
  { value: "sales_best", label: "Sales - best sellers (qty)" },
  { value: "active_stock", label: "Period End Inventory Report" },
];

export const excludeReports = ["cog_everything", 'cog_missing', 'active_stock'];
export const excludeFormFields = ["from_date", 'to_date'];

export const onChangeDate = (propsForm) => (date) => {
  const { setFieldValue, name} = propsForm;
  if (moment(date) <= moment()) {
    setFieldValue(name, date)
  } else {
    setFieldValue(name, "");
    toast.error("Wrong date!", { duration: 3000 });
  }
};