import { createSelector } from "reselect";

export const getExpensesDash = createSelector(
  state => state.dashboardTiles.expenses,
  (expenses) => expenses
);

export const getInventoryCost = createSelector(
  state => state.dashboardTiles.inventoryCost,
  (inventoryCost) => inventoryCost
);

export const getInventoryCostWarning = createSelector(
  state => state.dashboardTiles.inventoryCostWarning,
  (inventoryCostWarning) => inventoryCostWarning
);

export const getInventoryQuantity = createSelector(
  state => state.dashboardTiles.inventoryQuantity,
  (inventoryQuantity) => inventoryQuantity
);

export const getInventoryMarketplaces = createSelector(
  state => state.dashboardTiles.inventoryCostMarketplaces,
  (inventoryCostMarketplaces) => inventoryCostMarketplaces
);

export const getResaleValue = createSelector(
  state => state.dashboardTiles.resaleValue,
  (resaleValue) => resaleValue
);
