import * as PropTypes from "prop-types";

export const PermissionType = PropTypes.shape({
  permission_type: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
});

export const GroupType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  build_in: PropTypes.bool.isRequired,
  permissions: PropTypes.arrayOf(PermissionType).isRequired,
});

export const BankType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  balance: PropTypes.number.isRequired,
  nominal_code: PropTypes.string.isRequired,
  company: PropTypes.number.isRequired,
  account_currency_balance: PropTypes.string.isRequired,
  currency: PropTypes.object.isRequired,
  entry_method: PropTypes.string.isRequired,
  category_code: PropTypes.number.isRequired,
});

export const ConvRate = PropTypes.shape({
  id: PropTypes.number.isRequired,
  warn_using_closest_available_rate: PropTypes.bool.isRequired,
  date_imported: PropTypes.string.isRequired,
  exchange_rate: PropTypes.number.isRequired,
  from_currency: PropTypes.number.isRequired,
  to_currency: PropTypes.number.isRequired,
});