import {useDispatch, useSelector} from "react-redux";
import * as user from "../store/ducks/user.duck";

const useUserTheme = () => {
  const dispatch = useDispatch();
  const updateTheme = (args) => {
    dispatch(user.actions.updateUser(args));
  };

  return {
    updateTheme
  };
};

export default useUserTheme;