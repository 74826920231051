import React, { useState } from "react";
import DSearch from "../../components/DSearch/DSearch";
import Typography from "@material-ui/core/Typography";

const Toolbar = ({ setSearchQueryParam, title }) => {
  const [isClear, setClear] = useState(false);

  return (
    <div className="header--search tabs__toolbar">
      {title && <Typography variant="h4" className="page-title">{title}</Typography>}
      <DSearch
        setSearchQuery={setSearchQueryParam}
        isClear={isClear}
        setClear={setClear}
      />
    </div>
  )
};

export default Toolbar;