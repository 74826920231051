import React from "react";
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from "@material-ui/core/IconButton";
import withConfirmHook from "../../../../hooks/withConfirm.hook";
import toast from "../../../components/toast/toast";
import {TableCell} from "@material-ui/core";

const DeleteBBP = ({ row, confirm, deleteBBP }) => {
  const onDeleteBBP = (id) => () => {
    confirm(() => {
        deleteBBP({
          id,
          type: "imports",
          onDone: () => {
            toast.success("Deleted!", { duration: 3000 })
          },
          onError: () => {
            toast.error("Something went wrong!", { duration: 3000 })
          }
        })
      },
      {
        description: 'This Action is Permanent!',
        style: "d-confirm"
      })()
  };
  return (
    <TableCell align="center" className="table__cell--delete">
      <IconButton onClick={onDeleteBBP(row.id)}>
        <DeleteIcon htmlColor="var(--d-red)" fontSize="large" />
      </IconButton>
    </TableCell>
  )
};

export default withConfirmHook(DeleteBBP)