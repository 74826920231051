import React from "react";
import {Form as FormikForm, Formik} from "formik";
import Form from "react-bootstrap/Form";
import FormField from "../../components/controls/FormField";
import Button from "@material-ui/core/Button";
import useReports from "../../../hooks/reports.hook";
import useUsers from "../../../hooks/users.hook";
import toast from "../../components/toast/toast";
import DSplashScreen from "../../components/SplashScreen/DSplashScreen";
import {flags} from "../../components/Marketplaces/Marketplaces";
import Avatar from "@material-ui/core/Avatar";
import FormComponent from "../../components/controls/FormComponent";
import CheckIcon from '@material-ui/icons/Check';
import {onChangeDate} from "./utils";
import {Dialog, DialogContent, DialogTitle} from '@material-ui/core';
import {requiredValidator} from "../../components/controls/validators";

const FormAddReport = props => {
  const {user} = useUsers({fetch: false});
  const {filtersOptions, show, onClose, expensesCategories} = props;
  const {addNewReport, mainReportsLoading: loading} = useReports();

  const categoriesOptions = expensesCategories.reduce((res, item) => [...res, {
    label: item.name,
    value: item.code
  }], [{label: 'All', value: 'all'}]);


  const allMarketplaces = user?.amazon_settings?.reduce((a, b) => [...a, ...b.marketplaces], [{
    marketplace_id: "all_marketplaces",
    country_code: "all_marketplaces",
    country: "All Marketplaces"
  }]);

  const marketplaceOptions = allMarketplaces?.map(({marketplace_id, country, country_code}) =>
    ({
      label:
        <div className="label--marketplaces">
          <div className="block block--selected">
            <CheckIcon htmlColor="var(--main)"/>
          </div>
          <div className="block">
            <Avatar
              alt={country_code}
              src={flags[country_code]}
              className="country-flag"
            />
            <p className="country-name">
              {country}
            </p>
          </div>
        </div>,
      value: marketplace_id
    })
  );

  let reportOptions = [
    ...filtersOptions.filter((el) => !!el.value),
    {label: "Detailed Profit & Loss", value: "profit_loss"}
  ];

  const interfaces = [
    {
      id: `report`,
      name: "report",
      type: "RSelect",
      label: "Report",
      validate: requiredValidator("Report is required!"),
      render: ({customProps, ...props}) => {
        const {setFieldValue, name} = props;
        const onChange = (value) => {
          setFieldValue(name, value);
          setFieldValue('categories', {label: 'All', value: 'all'});
          setFieldValue('file_format', {value: 'pdf', label: 'pdf'});
        };
        return <FormComponent customProps={{onChange, options: reportOptions}} {...props} />
      },
    },
    {
      id: 'categories',
      name: 'categories',
      type: 'RSelect_custom',
      label: 'Category',
      validate: '',
      render: ({customProps, ...props}) => {
        const {values} = props;
        const isCategories = values.report && values?.report?.value === 'profit_loss';
        return isCategories ? <FormComponent customProps={{options: categoriesOptions}} {...props} /> : <></>
      },
    },
    {
      id: `marketplace`,
      name: "marketplace",
      label: "Marketplace",
      type: "MSelectWithButton",
      validate: requiredValidator("Marketplace is required!"),
      render: ({customProps, ...props}) => {
        const {setFieldValue, values, name} = props;
        const onChange = (event) => {
          let value = event.target.value;
          if (value.indexOf("all_marketplaces") === value.length - 1 || value.length === marketplaceOptions.length - 1) {
            if (value.length === 0 || (value.length === 1 && values["marketplace"]?.includes("all_marketplaces"))) {
              value = [];
            } else {
              value = ["all_marketplaces"]
            }
          } else if (value.indexOf("all_marketplaces") === 0) {
            value = value.slice(1);
          }
          setFieldValue(name, value);
        };

        return (
          <FormComponent
            customProps={{
              className: "new-main-report",
              options: marketplaceOptions,
              onChange,
            }}
            {...props}
          />
        )
      },
    },
    {
      id: `from_date`,
      name: "from_date",
      type: "dateRB",
      label: "From",
      validate: requiredValidator("From is required!"),
      render: ({customProps, ...props}) => <FormComponent customProps={{onChange: onChangeDate(props)}} {...props} />,
    },
    {
      id: `to_date`,
      name: "to_date",
      type: "dateRB",
      label: "To",
      validate: requiredValidator("To is required!"),
      render: ({customProps, ...props}) => <FormComponent customProps={{onChange: onChangeDate(props)}} {...props} />,
    },
    {
      id: `file_format`,
      name: "file_format",
      type: "RSelect",
      label: "Format",
      validate: requiredValidator("File Format is required!"),
      render: ({customProps, ...props}) => {
        const {values} = props;
        const options = [
          ...values?.report?.value !== 'profit_loss'
            ? [{value: "csv", label: "csv"}, {value: "xlsx", label: "xlsx"}]
            : [],
          {value: "pdf", label: "pdf"}
        ];
        return <FormComponent customProps={{options}} {...props} />
      },
    },
  ];

  let initData = {};

  return (
    <Formik
      enableReinitialize
      initialValues={initData}
      onSubmit={(values, {setSubmitting, resetForm}) => {

        const data = {...values};
        data.file_format = data.file_format ? data.file_format.value : "";
        data.report = !!data.report ? data.report.value : "";

        const onDone = () => {
          toast.success("Add Report", {duration: 3000}, {isClose: false});
          setSubmitting(false);
          resetForm();
          onClose();
        };

        const onError = (error) => {
          toast.error("Something went wrong", {duration: 3000}, {isClose: false});
          setSubmitting(false);
          console.error(error);
        };

        if (data.categories) {
          data.categories = [data.categories.value]
        }
        if (data.report) {
          if (data.report !== 'profit_loss') {
            if (data.categories) {
              delete data.categories
            }
          }
        }
        addNewReport({values: data, onDone, onError});
      }}
    >
      {({...form}) => {
        return (
          <Dialog
            open={show}
            onClose={() => {
              form.resetForm();
              onClose();
            }}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
            className="sales__reports__modal new-report__modal"
            maxWidth="sm"
            scroll="body"
          >
            <FormikForm>
              <DialogTitle>Report Selection</DialogTitle>
              <DialogContent>
                <div className="products__details__body new-report__body">
                  <div className="top wrap">
                    <div className="d-splash-screen--wrap">
                      {loading && <DSplashScreen/>}
                      {!loading && <div className="products__details__inventory-cost sales">
                        {interfaces.map((item) => {
                          return <Form.Group key={item.id} controlId={item.id} className={`form-group--${item.id}`}>
                            <Form.Label column={false}>{item.label}</Form.Label>
                            <FormField
                              name={item.id}
                              label={item.label}
                              type={item.type}
                              customProps={item.customProps}
                              render={item.render}
                              validate={item.validate}
                              {...form}
                            />
                          </Form.Group>
                        })}
                      </div>}
                    </div>
                    <div className="products__details__wrap-btn">
                      <Button
                        variant="outlined"
                        className="products__details__btn--cancel"
                        onClick={onClose}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        className="products__details__btn products__details__btn--add"
                        disabled={form.isSubmitting}
                      >
                        Generate Report
                      </Button>
                    </div>
                  </div>
                </div>
              </DialogContent>
            </FormikForm>
          </Dialog>
        )
      }}
    </Formik>
  );
};

export default FormAddReport;