import React, {useEffect} from "react";
import {Typography, Popper, Fade, Paper} from "@material-ui/core";
import {ReactComponent as CalendarIcon} from "../../../../media/icons/calendar-2.svg";
import ChartTime from "../../Dashboard/ChartSection/ChartTime";
import {dateOptions} from "../../Dashboard/ChartSection/dataChart";
import {periodTitles} from "./asinIntarfaces";

const AsinDate = props => {
  const {onSelectedPeriod, onCustomPeriod, title, name, time, childEl, isClose, anchor, setAnchor, handleClickDate} = props;

  useEffect(() => {
    isClose && setAnchor({anchorEl: null, name: "" });
  }, [isClose]);

  const onSelect = ({name}) => (props) => {
    if (props.value === "custom") {
      return
    }
    onSelectedPeriod({name, ...props});
  };

  const onSelectCustom = ({name}) => (props) => {
    onCustomPeriod({name, ...props});
  };

  return (
    <div className="metrics-row__main-header metrics-row__header">
      <Typography variant="h6" className="title">
        {`${periodTitles[name]} - ${title}`}
      </Typography>
      <div
        aria-describedby={anchor?.name}
        className='date_picker_container asin__chart-legend'
        onClick={(event) => handleClickDate(event, name)}
      >
        {childEl}
        <CalendarIcon type="button" />
      </div>

      <Popper
        id={anchor?.name}
        open={anchor?.name === name}
        anchorEl={anchor?.anchorEl || null}
        transition className="d-tooltip--popper metrics-row__popper"
        placement="bottom"
      >
        {({TransitionProps}) => (
          <Fade{...TransitionProps}>
            <Paper elevation={8}>
              <ChartTime
                dateOptions={dateOptions.map(({tooltip, ...item}) => item)}
                onSelectedPeriod={onSelect({name})}
                time={time}
                onCustomPeriod={onSelectCustom({name})}
              />
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  )
};

export default AsinDate;