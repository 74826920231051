import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { toAbsoluteUrl } from "../../../../../../_metronic";
import classNames from 'clsx'
import {INTERCOM_ID} from "../../../../home/utils";

export const menuItems = [
  { name: 'Home', link: '/', anchor: '#', global: false },
  { name: 'Features', link: '', anchor: '#features-section-anchor', global: false },
  { name: 'Pricing', link: '', anchor: '#pricing-anchor', global: false },
  { name: 'Contact', link: '/contact', anchor: '', global: false },
  { name: 'Free Course ', link: 'https://www.arbiversity.com/', anchor: '', global: true },
];

function Main({ parent, current }) {
  const [headerScroll, setheaderScroll] = useState(false)
  const [click, setClick] = useState(false);
  // const [tabclick, setTabclick] = useState(false)
  const [activeMenuItem, setActiveMenuItem] = useState(current || 'Home');
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    //chat bot
    var APP_ID = INTERCOM_ID;

    (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
  }, []);

  const onAnchor = (anchor) => {
    window.location.href = anchor;
  };

  useEffect(() => {
    const fixedHeader = () => {
      if (window.pageYOffset > 150) {
        setheaderScroll(true)
      } else {
        setheaderScroll(false)
      }
    }
    window.addEventListener('scroll', fixedHeader)
  }, []);

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change


  const onMenu = (name) => {
    setActiveMenuItem(name);
    setClick(false);
  }

  return (
    <>

      <header className={headerScroll ? "fixed fix_style" : "fixed"}>
        <div className="container">

          <nav className="navbar navbar-expand-lg">
            <Link className="navbar-brand" to="/">
              <img src={toAbsoluteUrl("media/logos/new-logo-w-2.svg")} alt="logo" />
            </Link>
            <button className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
                    onClick={() => setClick(!click)}
            >
              <div className="navbar-toggler-icon" >
                <div className={`toggle-wrap ${click ? 'active' : ''}`}>
                  <span className="toggle-bar" />
                </div>
              </div>
            </button>

            <div className={`collapse navbar-collapse ${click ? "show" : ""}`} id="navbarSupportedContent">
              <ul className="navbar-nav ml-auto">
                {
                  menuItems.map(({ name, link, anchor, global }) => (
                    <li
                      key={name}
                      className={classNames("nav-item", {"active": activeMenuItem === name})}
                      onClick={() => onMenu(name)}
                    >

                      {
                        global ? (
                          <a className="nav-link" href={link} target="_blank">
                            {name}
                          </a>
                        ) : (
                          <Link
                            className="nav-link"
                            to={`${parent ? parent : link}${anchor}`}
                            onClick={() => anchor ? onAnchor(anchor) : undefined}
                          >
                            {name}
                          </Link>
                        )
                      }
                    </li>
                  ))
                }
                <li className="nav-item" onClick={() => setClick(false)}>
                  <a href={`${parent}#pricing`} className="btn btn_main">
                    START FREE TRIAL
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>

      </header>
    </>
  )
}

export default Main
