import Typography from "@material-ui/core/Typography";
import React from "react";
import DateTimeField from "../../../components/controls/DateTimeField";
import {Link} from "react-router-dom";

const DateWizard = () => {
  return <div className="wizard__date">
    <Typography variant="h6" className="wizard__content__title">Opening  Balances Date</Typography>
    <Typography variant="subtitle1" className="wizard__content__text">
      Your opening balances date should be the date you stopped using your old system and are starting to use Pandle.
      This is usually the start of a new financial year but it is possible to start part way through.
      However it is important that you start using Pandle from the beginning of a VAT period.
    </Typography>
    {/*TODO need link to "opening balances support article"*/}
    {/*<Typography variant="subtitle1" className="wizard__content__text">*/}
      {/*More information on opening balances can be found in the*/}
      {/*<Link to="#" className="wizard__link">opening balances support article</Link>.*/}
    {/*</Typography>*/}
    <div className="wizard__date-picker">
      <DateTimeField
        fullWidth={false}
        id="date_opening_balance"
        label="Opening balances on of"
        name="date_opening_balance"
        // setFieldValue={setFieldValue}
      />
    </div>
  </div>
};

export default DateWizard;