export const dateOptions = [
  {label: "dd/mm/yy (eg 31/12/15)", value: "dd/mm/yy (eg 31/12/15)"},
  {label: "dd-mm-yy (eg 31-12-15)", value: "dd-mm-yy (eg 31-12-15)"},
  {label: "dd.mm.yy (eg 31.12.15)", value: "dd.mm.yy (eg 31.12.15)"},
  {label: "dd/mm/yyyy (eg 31/12/2015)", value: "dd/mm/yyyy (eg 31/12/2015)"},
  {label: "dd-mm-yyyy (eg 31-12-2015)", value: "dd-mm-yyyy (eg 31-12-2015)"},
  {label: "dd.mm.yyyy (eg 31.12.2015)", value: "dd.mm.yyyy (eg 31.12.2015)"},
  {label: "dd-mmm-yy (eg 31-Dec-15)", value: "dd-mmm-yy (eg 31-Dec-15)"},
  {label: "dd mmm yy (eg 31 Dec 15)", value: "dd mmm yy (eg 31 Dec 15)"},
  {label: "ddmmmyy (eg 31Dec15)", value: "ddmmmyy (eg 31Dec15)"},
  {label: "dd-mmm-yyyy (eg 31-Dec-2015)", value: "dd-mmm-yyyy (eg 31-Dec-2015)"},
  {label: "dd mmm yyyy (eg 31 Dec 2015)", value: "dd mmm yyyy (eg 31 Dec 2015)"},
  {label: "ddmmmyyyy (eg 31Dec2015)", value: "ddmmmyyyy (eg 31Dec2015)"},
  {label: "yyyy-mm-dd hh:mm:ss (eg 2015-12-31 00:00:00)", value: "yyyy-mm-dd hh:mm:ss (eg 2015-12-31 00:00:00)"},
  {label: "yyyy-mm-ddThh:mm:ssZ (eg 2015-12-31T00:00:00Z)", value: "yyyy-mm-ddThh:mm:ssZ (eg 2015-12-31T00:00:00Z)"},
  {label: "yyyymmdd (eg 20151231)", value: "yyyymmdd (eg 20151231)"},
  {label: "yyyy-mm-dd (eg 2015-12-31)", value: "yyyy-mm-dd (eg 2015-12-31)"},
  {label: "mm/dd/yy (eg 12/31/15)", value: "mm/dd/yy (eg 12/31/15)"},
  {label: "mm-dd-yy (eg 12-31-15)", value: "mm-dd-yy (eg 12-31-15)"},
  {label: "mm.dd.yy (eg 12.31.15)", value: "mm.dd.yy (eg 12.31.15)"},
  {label: "mm/dd/yyyy (eg 12/31/2015)", value: "mm/dd/yyyy (eg 12/31/2015)"},
  {label: "mm-dd-yyyy (eg 12-31-2015)", value: "mm-dd-yyyy (eg 12-31-2015)"},
  {label: "mm.dd.yyyy (eg 12.31.2015)", value: "mm.dd.yyyy (eg 12.31.2015)"},
  {label: "mmm-dd-yy (eg Dec-31-15)", value: "mmm-dd-yy (eg Dec-31-15)"},
  {label: "mmm dd yy (eg Dec 31 15)", value: "mmm dd yy (eg Dec 31 15)"},
  {label: "mmm-dd-yyyy (eg Dec-31-2015)", value: "mmm-dd-yyyy (eg Dec-31-2015)"},
  {label: "mmm dd yyyy (eg Dec 31 2015)", value: "mmm dd yyyy (eg Dec 31 2015)"},
  {label: "mmmddyyyy (eg Dec312015)", value: "mmmddyyyy (eg Dec312015)"},
];

export const optionsDataName = [
  {label: "Ignore This Column", "value": "ignore"},
  {label: "Date", "value": "date"},
  {label: "Description", "value": "description"},
  {label: "Amount", "value": "amount"},
];

export const formatOptions = [
  {label: "Money In", value: "MI"},
  {label: "Money Out", value: "MO"},
  {
    label: "Money In & Out (Bank Account)", value: "MIBAN",
    help_text: {
      title: `You have selected Money In & Out (Bank Account)`,
      content: `This means that the amounts as they appear in your statement will be treated as follows:`,
      list: [`£10 : received`, `-£10 : outgoing`]
    },
  },
  {
    label: "Money In & Out (Credit Card)", value: "MICC",
    help_text: {
      title: `You have selected Money In & Out (Credit Card`,
      content: `This means that the amounts as they appear in your statement will be treated as follows:`,
      list: [`-£10 : received (paid off)`, `£10 : outgoing (spent)`]
    }
  },
];