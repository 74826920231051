import React, { useEffect, useRef, useState } from "react";
import Wrapper from "../../components/Wrapper";
import TableComponent from "../../components/TableComponent/TableComponent";
import { headRows } from "./headRows"
import useTableParams from "../../../hooks/useTableParams";
import useAsin from "../../../hooks/asin.hook";
import useTab from "../../../hooks/tab.hook";
import DTabs from "../../components/DTabs/DTabs";
import Typography from "@material-ui/core/Typography";
import TabsToolbar from "./TabsToolbar";
import debounce from "lodash/debounce";
import classNames from "clsx";
import DSplashScreen from "../../components/SplashScreen/DSplashScreen";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { Themes } from "./../../../types/enums";


const Empty = ({usedParams, items}) => <>
    <Typography component="p" variant="h4">
        {usedParams.length && !items.length ? "Not Found" : "SKUify tracks your shipments one month prior to sign up. Check back here in the days to follow for updates."}
    </Typography>
</>;


const options = {
    "q": "q",
};


const Jorney = () => {
    const {
        asinHistories,
        asinHistoriesTotalCount,
        loadingAsinHistories,
        getAsinHisties,
        updateAsinHistory,
    } = useAsin();

    const {currentTheme} = useThemeSwitcher();
    const isDark = currentTheme === Themes.dark;

    const { setParams } = useTableParams(getAsinHisties, {});
    const [currentTab, setTab] = useTab('reconcilation');
    const [isResetPagination, setResetPagination] = useState(false);
    const [usedParams, setUsedParams] = useState([]);

    const [height, setHeight] = useState(0);

    const refElem = useRef(null);
    useEffect(() => {
        setHeight(refElem.current.clientHeight);
    }, [currentTab]);

    const decoratorDebounce = (param, setParams, ms) => debounce((q) => {
        const params = { offset: 0, ordering: "" };
        params[options[param]] = q;
        if (!usedParams.includes(param)) {
            setUsedParams(usedParams => [...usedParams, param]);

        }
        setParams(params);
        setResetPagination(true);
    }, ms || 500);

    const clientHeight = window.innerHeight;
    const remainingHeight = (clientHeight - height - 250) > 0;


    const extractShipmentsToSeparateLine = jorneys => {
        const data = [];
        jorneys.forEach(jorney => {
            jorney.inbound_shipments.forEach((shipment, idx) => {
                data.push({
                    ...shipment,
                    isShipment: true,
                    sku: jorney.sku,
                    jorneyId: jorney.id,
                    buy_price: shipment.stage_product_ordered?.buy_price,
                    product: jorney.product,
                    isPrimary: idx === 0,
                    createdDate: shipment.created_date,
                })
            });
        })
        return data;
    }

    const tabsData = [
        {
            value: "reconcilation",
            toolbar: <TabsToolbar
                setSearchQueryParam={decoratorDebounce('q', setParams)}
                tab={currentTab}
                usedParams={usedParams}
            />,
            content: <div
                className={classNames("d-splash-screen--wrap", { "small-screen": remainingHeight })}
                ref={refElem}
            >
                {loadingAsinHistories && <DSplashScreen />}
                <TableComponent
                    className="products__table"
                    headRows={headRows}
                    items={extractShipmentsToSeparateLine(asinHistories)}
                    count={asinHistoriesTotalCount}
                    isResetPagination={isResetPagination}
                    setResetPagination={setResetPagination}
                    setParams={setParams}
                    forEmpty={<Empty usedParams={usedParams} items={extractShipmentsToSeparateLine(asinHistories)} />}
                    rowProps={{ update: updateAsinHistory, isDark }}
                />
            </div>
        }
    ];
    const onChangeTab = (event, newValue) => {
        setTab(event, newValue);
        setResetPagination(true);
        setUsedParams([]);
        const emptyParams = {
            offset: 0,
            ordering: "",
            q: "",
        };
        setParams(emptyParams);
    };

    return (
        <Wrapper elevation={0} classWrapper="journey">
            <div className="products page__table__wrap kt-portlet">
                <DTabs
                    value={currentTab}
                    tabsData={tabsData}
                    onChange={onChangeTab}
                    className="products__tabs dashboard"
                />
            </div>
        </Wrapper>
    )
};

export default Jorney;
