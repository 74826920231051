import { requests } from "./utils";

export default {
  getCurrencies: () => requests.get('/currency'),
  getCurrency: (id) => requests.get(`/currency/${id}`),
  getSettingsMeta: () => requests.get('/users/meta_info'),
  getTaxes: () => requests.get('/taxes'),
  getConvRates: (fromCurrency, toCurrency) => requests.get(`/conversions/${fromCurrency}/${toCurrency}/`),

  // async task
  getTaskStatus: (taskId) => requests.get(`/task/status/${taskId}`),
};
