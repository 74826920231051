import React, { useEffect, useState } from "react";
import { requiredValidator } from "../../components/controls/validators";
import { typeOptions } from "../Transaction/bankOptions";
import AutocompleteWithTooltip from "../Transaction/NewBankTransaction/AutocompleteWithTooltip";
import { goToSuppliersTab, round } from "../Transaction/utils";
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import FunctionsIcon from "@material-ui/icons/Functions";
import Notification from "../../components/Notification";
import QuickConversionTransactionModal from "../Transaction/NewBankTransaction/QuickConversionTransactionModal";
import DButton from "../../components/Button";
import ReceiptUploadsModal from "../../components/ReceiptUploadsModal/ReceiptUploadsModal";
import { useSubmit } from "../../../hooks/submit.hook";
import useCurrencies from "../../../hooks/currencies.hook";
import { ActionButtonsNewTransaction } from "../Transaction/NewBankTransaction/TransactionForm/ActionButtonsNewTransaction";
import { FieldArray, Form as FormikForm, Formik } from "formik";
import FormField from "../../components/controls/FormField";
import { connect } from "react-redux";
import * as TransactionsSelector from "../../../selectors/transactions.selectors";
import * as commonSelectors from "../../../selectors/common.selectors";
import * as TransactionsDuck from "../../../store/ducks/transactions.duck";
import * as commonDuck from "../../../store/ducks/common.duck";
import * as PropTypes from "prop-types";
import { getCurrentCompanyId } from "../../../crud/utils";
import { processError } from "../Transaction/EnterTransactionsBanking/EditAddBankingModal";
import { useHistory } from "react-router-dom";
import { getInvoicePageName, goToCustomersTab, goToRecurringTransactionsPage } from "../Transaction/utils";
import { useModal, useParseHistory } from "../../../hooks/modal.hook";
import useCompany from "../../../hooks/company.hook";
import SplitBankTransactionModal from "../Transaction/NewBankTransaction/SplitBankTransactionModal";
import CreateEditRecurTransModal from "../Tools/RecurringTransactions/CreateEditRecurTransModal";
import FormComponent from "../../components/controls/FormComponent";
import { getFieldName, getTaxValue, namesSaveBtn, WrapContainer, items, titleOptions } from "./utils";
import classNames from "clsx";
import TableComponent from "../../components/TableComponent/TableComponent";
import { headerRowsInvoices } from "./headerRowsInvoices";
import useSuppliersCustomers from "../../../hooks/suppliersCustomers.hook";
import useCategories from "../../../hooks/categories.hook";

const Invoices = (props) => {
  const {
    taxes,
    getTaxes,
    getDescriptions,
    descriptions,
    step,
  } = props;
  const [conversionRateDisable, setConversionRateDisable] = useState(false);
  const [isQuickConvModalShow, setIsQuickConvModalShow] = useState(false);
  const [isReceiptUploadsModalShow, setIsReceiptUploadsModalShow] = useState(false);
  const toggleReceiptUploadsModalShow = () => setIsReceiptUploadsModalShow(!isReceiptUploadsModalShow);
  const toggleQuickConvModalShow = () => setIsQuickConvModalShow(!isQuickConvModalShow);
  const [errors, isErrorsShow, toggleErrorsShow, onSubmitFormClick] = useSubmit("transactions");
  const [isSplitModalShow, toggleSplitModalShow] = useModal();
  const [isNewRecurTransModalShow, toggleNewRecurTransModalShow] = useModal();

  const { company } = useCompany();
  const { categories } = useCategories();
  const { suppliers, customers } = useSuppliersCustomers({ suppliers: true, customers: true });
  const history = useHistory();
  const pathName = history.location.pathname;
  const type = useParseHistory().type;
  const invoicesName = getInvoicePageName(pathName, type);
  const { currencies } = useCurrencies();
  const recurType = type || invoicesName === "edit recurring invoice";
  useEffect(() => {
    getTaxes();
    getDescriptions({ companyId: getCurrentCompanyId() });
  }, []);

  const initial = {
    date: '2020-01-01',
    type: undefined,
    account: undefined,
    category: undefined,
    inv_ref: undefined,
    description: undefined,
    currency: undefined,
    net_amount: undefined,
    tax_code: undefined,
    tax_amount: undefined,
    total_amount: undefined,
    conversion_rate: undefined,
  };

  let initialData = {};
  initialData.invoices = [initial];

  if (type === "SI") {
    initialData.invoices = [{
      account: { label: "Supplier", value: 205 },
      category: { label: "UK Sales", value: 205 },
      description: "test Supplier",
      currency: { label: "GBP", value: 156 },
      ...initial,
    }]
  } else if (invoicesName === "edit recurring invoice") {
    initialData.invoices = [{
      account: { label: "Customer", value: 205 },
      category: { label: "Sales", value: 205 },
      description: "test",
      currency: { label: "GBP", value: 156 },
      ...initial,
    }]
  }

  const invoicesInterface = [
    ...recurType
      // for recur
      ? []
      : [{
      id: 'date',
      label: 'Date',
      type: 'date',
      validator: requiredValidator("Date is required"),
    }],
    ...step
      // for wizard
      ? [{
          id: 'type',
          label: 'Type',
          type: 'RSelect',
          validator: requiredValidator("Type is required"),
          customProps: () => ({
            options: typeOptions.map(({ label, value }) => ({ label: `${label} | ${value}`, value }))
          }),
        }]
      : [],
    {
      id: 'account',
      label: 'Account',
      type: 'RSelect',
      validator: requiredValidator('Account is required'),
      customProps: () => ({ index, setFieldValue }) => {
        const onChange = ({ category, ...value }) => {
          if (invoicesName === "new suppliers" || type === "PurchaseInvoice" || type === "SI") {
            setFieldValue(getFieldName(index, 'account'), {...value, suppliers});
          } else {
            setFieldValue(getFieldName(index, 'account'), {...value, customers});
          }
        };
        let options;
        if (invoicesName === "new suppliers" || type === "PurchaseInvoice" || type === "SI") {
          options = suppliers.map(supplier => ({label: supplier.name, value: supplier.id, supplier}))
        } else {
          options = customers.map(customer => ({label: customer.name, value: customer.id, customer}))
        }
        return {
          onChange,
          options: options
        }
      }
    },
    ...step
      // for wizard
      ? []
      : [{
        id: 'category',
        label: 'Category',
        type: 'RSelect',
        validator: requiredValidator('Category is required'),
        customProps: () => ({ index, setFieldValue }) => {
          const onChange = ({ category, ...value }) => {
            setFieldValue(getFieldName(index, 'category'), {...value, category});
          };
          return {
            onChange,
            options: categories.details.map(category => ({label: category.name, value: category.id, category}))
          }
        }
      }],
    ...recurType
      // for recur
      ? []
      : [{
      id: 'inv_ref',
      label: 'Inv. Ref',
      type: 'text'
    }],
    {
      id: 'description',
      label: 'Description',
      type: 'text',
      validator: requiredValidator("Description is required"),
      render: (props) => {
        const onChange = (e, value) => {
          props.setFieldValue(props.name, value);
        };
        return <AutocompleteWithTooltip
          onChange={onChange}
          value={props.value}/>
      }
    },
    {
      id: 'currency',
      label: 'Currency',
      validator: requiredValidator("Currency is required"),
      // defaultValue: bank.currency,
      type: 'RSelect',
      customProps: () => () => ({
        options: currencies.details.map(({ currency_code, id }) => ({ label: `${currency_code}`, value: id, })),
      }),
    },
    {
      id: 'net_amount',
      label: 'Net',
      type: 'number',
      validator: requiredValidator("Net is required"),
      customProps: () => ({index, setFieldValue, values}) => {
        const onChange = (e) => {
          let tax = 0;
          if (values.tax_code && values.tax_code.value) {
            tax = getTaxValue(values.tax_code.tax);
          }
          const taxAmount = e.target.value / 100 * tax;
          setFieldValue(getFieldName(index, 'tax_amount'), round(taxAmount));
          setFieldValue(getFieldName(index, 'total_amount'), round(taxAmount + parseFloat(e.target.value)));
          setFieldValue(getFieldName(index, 'net_amount'), e.target.value);
        };
        return { onChange }
      },
    },
    {
      id: 'tax_code',
      validator: requiredValidator("Tax Code is required"),
      label: 'Tax Code',
      type: 'RSelect',
      customProps: () => ({index, setFieldValue, values}) => {
        const onChange = (value) => {
          let tax = getTaxValue(value.tax);
          const netAmount = parseFloat(values.net_amount);
          const taxAmount = round((netAmount / 100) * tax);
          setFieldValue(getFieldName(index, 'tax_amount'), taxAmount);
          setFieldValue(getFieldName(index, 'tax_code'), value);
          setFieldValue(getFieldName(index, 'total_amount'), round(netAmount + taxAmount));
        };
        return {
          onChange,
          options: taxes.map(tax => ({ label: tax.name, value: tax.id, tax: parseFloat(tax.tax_rate) }))
        }
      },
    },
    {
      id: 'tax_amount',
      validator: requiredValidator("Tax is required"),
      label: 'Tax',
      type: 'number',
    },
    {
      id: 'total_amount',
      label: 'Total',
      type: 'number',
      validator: requiredValidator("Total is required"),
      customProps: () => ({index, setFieldValue, values}) => {
        const onChange = (e) => {
          let taxPercent = 0;
          if (values.tax_code && values.tax_code.value) {
            taxPercent = getTaxValue(values.tax_code.tax);
          }
          const total = parseFloat(e.target.value);
          const netAmount = round(total * 100 / (taxPercent + 100));
          const taxAmount = round((netAmount / 100) * taxPercent);
          setFieldValue(getFieldName(index, 'total_amount'), total);
          setFieldValue(getFieldName(index, 'tax_amount'), taxAmount);
          setFieldValue(getFieldName(index, 'net_amount'), netAmount);
        };
        return { onChange }
      },
    },
    ...recurType
      ? []
      : [{
        id: 'conversion_rate',
        label: 'Conv. Rate',
        disable: conversionRateDisable,
        type: "number",
        render: ({...props}) => (
          <div className="conversion_rate">
            <FormComponent name="conversion_rate" {...props} />
            {!conversionRateDisable
            && <IconButton color="primary" onClick={toggleQuickConvModalShow}><FunctionsIcon/></IconButton>}
          </div>
        )
      }],
    {
      id: 'actions',
      label: 'Action',
      render: ({ extraProps }) => {
        return recurType && extraProps
          ? <ActionButtonsNewTransaction onSplit={() => console.log("split")} row={extraProps.row} />
          : extraProps && <ActionButtonsNewTransaction
            addNewRow={addNewRow(extraProps.arrayHelpers)}
            deleteTransaction={removeRow(extraProps.arrayHelpers, extraProps.index, extraProps.invoices)}
            onReceiptUploads={onReceiptUploads}
            rowIndex={extraProps.index}
            row={extraProps.row}
          />
      }
    }
  ];

  const addNewRow = (arrayHelpers) => () => arrayHelpers.push(initial);

  const removeRow = (arrayHelpers, index, array) => () => {
    if (array.length > 1) {
      arrayHelpers.remove(index);
    }
  };

  const onReceiptUploads = company && company.uses_receipt_upload ? toggleReceiptUploadsModalShow : undefined;

  const onNewRecur = () => toggleNewRecurTransModalShow();

  const getNameSaveBtn = () => namesSaveBtn[invoicesName] || "Save All";

  const onBack = () => {
    switch (invoicesName) {
      case "new customers":
      case "edit customers":
        goToCustomersTab(history);
        break;
      case "new suppliers":
      case "edit suppliers":
        goToSuppliersTab(history);
        break;
      case "new recurring invoice":
      case "edit recurring invoice":
        goToRecurringTransactionsPage(history);
        break;
    }
  };

  const title = () => titleOptions[invoicesName] || "Invoices";

  const isNewInvoices = invoicesName === "new customers" || invoicesName === "new suppliers";

  const onAttachments = (row) => () => console.log("onAttachments", row);

  return <WrapContainer step={step}>
    <Formik
      enableReinitialize
      initialValues={initialData}
      onSubmit={(values, {setSubmitting, resetForm}) => {
        const data = {...values};
        const onDone = () => {
          setSubmitting(false);
          resetForm();
        };
        const onError = (error) => {
          processError(error);
          setSubmitting(false);
        };
        // if (initialData) {
        //   updateInvoice({
        //     value: data,
        //   })
        // } else {
        //   addInvoice({
        //
        //   })
        // }
      }}>
      {({...form}) => {

        const setConvRate = (index) => (value) => {
          form.setFieldValue(getFieldName(index, 'conversion_rate'), value);
        };

        const errorTransaction = errors && isErrorsShow &&
          <Notification onClose={toggleErrorsShow} errors={errors} name="invoices" />;

        return (
          <FormikForm>
            <div className="invoices">
              <Typography variant="h6" className="invoices__title">
                {title()}
              </Typography>
              {errorTransaction}
              {isNewInvoices && <TableComponent
                className="invoices__table"
                headRows={headerRowsInvoices}
                items={items}
                isPagination={false}
                rowProps={{ onAttachments }}
              />}
              <div className="table__wrapper invoices__table">
              <Table className="form__table table">
                {!isNewInvoices
                  && <TableHead>
                    <TableRow className="form__row">
                      {invoicesInterface.map(item => (
                        <TableCell key={item.id} className="form__cell" align={item.align || "left"}>{item.label}</TableCell>))
                      }
                      {/*<TableCell className="form__cell" align="center">Actions</TableCell>*/}
                    </TableRow>
                  </TableHead>
                }
                <TableBody>
                  <FieldArray
                    name="invoices"
                    render={(arrayHelpers) => {
                      const extraProps = { arrayHelpers };
                      return form.values.invoices.map((values, index) => {
                        extraProps.row = form.values.invoices[index];
                        extraProps.index = index;
                        extraProps.invoices = form.values.invoices;
                        return <TableRow key={index} className="form__row form__row--body">
                          {invoicesInterface.map((item) => {
                            return <TableCell
                              key={item.id}
                              aria-label={item.label}
                              className={classNames("form__cell cell__invoices__" + item.id, {
                                "cell__invoices__recurring": recurType
                              })}
                              align={item.align || "left"}
                            >
                              <FormField
                                render={item.render}
                                name={`invoices.${index}.${item.id}`}
                                label={item.label}
                                index={index}
                                type={item.type}
                                validate={item.validator}
                                customProps={item.customProps && item.customProps()}
                                extraProps={extraProps}
                                {...form}
                                values={values}
                              />
                            </TableCell>
                          })}
                          <QuickConversionTransactionModal
                            submit={setConvRate(index)}
                            show={isQuickConvModalShow}
                            onClose={toggleQuickConvModalShow}
                          />
                        </TableRow>
                      })
                    }}
                  />
                </TableBody>
              </Table>
                </div>
              <div className="container-button container-button--modal container-button__bottom-btns">
                {invoicesName === "new recurring invoice"
                  ? <DButton
                    typeOfButton="filter"
                    type="submit"
                    onClickCustom={onNewRecur}>
                    {getNameSaveBtn()}
                  </DButton>
                  : <DButton
                    onClickCustom={onSubmitFormClick(form)}
                    typeOfButton="doneAllSecondary"
                    disabled={form.isSubmitting}>
                    {getNameSaveBtn()}
                  </DButton>}
                {
                  !(invoicesName === "invoices")
                  && <DButton onClickCustom={onBack} typeOfButton="backPrimary">Back</DButton>
                }
              </div>
              <ReceiptUploadsModal show={isReceiptUploadsModalShow} onClose={toggleReceiptUploadsModalShow}/>
              {isSplitModalShow
              && <SplitBankTransactionModal
                show={isSplitModalShow}
                onClose={toggleSplitModalShow}
                taxes={taxes}
                currencies={currencies}
              />}
              <CreateEditRecurTransModal
                show={isNewRecurTransModalShow}
                onClose={toggleNewRecurTransModalShow}
                type={invoicesName}
              />
            </div>
          </FormikForm>
        )
      }}
    </Formik>
  </WrapContainer>
};

Invoices.propTypes = {
  getDescriptions: PropTypes.func,
  getConvRates: PropTypes.func,
  getTaxes: PropTypes.func,
  taxes: PropTypes.array,
  descriptions: PropTypes.array,
};

const mapStateToProps = (state, props) => {
  return {
    descriptions: TransactionsSelector.getDescriptions(state, props),
    taxes: commonSelectors.getTaxes(state),
  }
};

const mapDispatchToProps = {
  getDescriptions: TransactionsDuck.actions.getDescriptions,
  getTaxes: commonDuck.actions.getTaxes,
  getConvRates: commonDuck.actions.getConvRates,
};

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);