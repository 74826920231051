import React from "react";
import {Formik} from "formik";
import {Button, FormControlLabel, Switch} from "@material-ui/core";
import {connect} from "react-redux";
import * as auth from "../../../store/ducks/auth.duck";
import {useHistory} from "react-router-dom";
import toast from "../../components/toast/toast";
import "../../components/controls/switch.scss";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

const Step3 = ({saveSettings, onRedirectToFinish, finishInfo = {}}) => {
  const {refresh_token, seller_id} = finishInfo;
  let history = useHistory();

  return (
    <>
      <div className="connect-doddled__form-top">
        <p className="name">seller ID</p>
        {!!seller_id ? <p className="value">{seller_id}</p> : <CloseIcon htmlColor="var(--d-red)"/>}

        <p className="name">received refresh token</p>
        {!!refresh_token ? <CheckIcon htmlColor="var(--d-green)"/> : <CloseIcon htmlColor="var(--d-red)"/>}

      </div>
      <div className="kt-login__form connect-doddled__form">
        <Formik
          initialValues={{
            newsletter: false,
            location: "europe",
          }}
          onSubmit={(values, {setStatus, setSubmitting}) => {
            const data = {...values};
            setSubmitting(true);
            saveSettings({
              values: data,
              onDone: () => {
                setSubmitting(false);

                if (onRedirectToFinish) {
                  onRedirectToFinish()
                } else {
                  history.push('/products');
                }
              },
              onError: (error) => {
                setSubmitting(false);
                toast.error(error, {duration: null}, {isClose: false});
                console.log('error');
              }
            });
          }}
        >
          {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => {
            return (
              <form
                noValidate={true}
                autoComplete="off"
                className="kt-form"
                onSubmit={handleSubmit}
              >
                <div className="row">
                  <div className="form-group form-group--newsletter col-12">
                    <FormControlLabel
                      // name="newsletter"
                      label="Subscribe to our newsletter. Only important updates, no spam!"
                      control={
                        <Switch
                          name="newsletter"
                          className="d-switch"
                          checked={values.newsletter}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          inputProps={{'aria-label': `newsletter--checkbox`}}
                        />
                      }
                    />
                  </div>
                </div>
                <Button
                  type="submit"
                  color="secondary"
                  size="large"
                  className="step__btn"
                  variant="contained"
                  disabled={isSubmitting || !(!!seller_id && !!refresh_token)}
                >
                  Start Sync
                </Button>
              </form>
            )
          }}
        </Formik>
      </div>
    </>

  )
};

export default connect(
  null,
  auth.actions
)(Step3);