import React, {useState} from "react";
import DButton from "../../components/Button";
import Link from "@material-ui/core/Link";
import {FormControl, FormHelperText, Input, InputLabel} from "@material-ui/core";
import {Formik} from "formik";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import axios from "axios";
import useUsers from "../../../hooks/users.hook";
import useSettings from "../../../hooks/settings.hook";
import toast from "../../components/toast/toast";
import {toAbsoluteUrl} from "../../../../_metronic";
import {ENDPOINT_PPP} from "./utils";

const FirstVisit = () => {
  const [showForm, setShowForm] = useState(false);
  const handlerSingIn = () => setShowForm(true);
  const {user} = useUsers({fetch: false});
  const {updateAccountSetting} = useSettings({fetch: false});

  if (showForm) {
    return (
      <div className="ppp-page__sing-in products__details__body">
        <div className="ppp-page__sing-in__title">
          <Typography variant="h4">Sign In</Typography>
        </div>
        <Formik
          initialValues={{
            email: "",
            password: ""
          }}
          onSubmit={async (values, {setSubmitting, resetForm}) => {
            const apiKey = await axios.post(`${ENDPOINT_PPP}/login.php`, {}, {
              auth: {
                username: values.email,
                password: values.password,
                // username: "stupak.alexn@gmail.com",
                // password: "4ppD3vT35T!"
              },
              headers: {
                'X-Authorization': 'D227-93F7-A61E-B73D-B720',
              }
            })
              .then(res => res.data)
              .catch(errors => console.error(errors));

            if (apiKey?.isSuccess) {
              const onDone = () => {
                setSubmitting(false);
                resetForm();
              };
              const onError = (error) => {
                setSubmitting(false);
                toast.error(error);
              };
              updateAccountSetting({
                id: user.id,
                values: {
                  ppp_api_key: apiKey["x-api-key"],
                  // ppp_email: "stupak.alexn@gmail.com",
                  ppp_email: values.email,
                },
                onDone,
                onError,
              });
              // getCredentials({ apiKey: apiKey["x-api-key"], email: "stupak.alexn@gmail.com" })
            } else {
              toast.error(apiKey.Error, {duration: 3000}, {isClose: false})
            }
          }}
        >
          {({
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
            <form
              className="kt-form ppp-page__form"
              noValidate={true}
              autoComplete="off"
              onSubmit={handleSubmit}
            >

              <FormControl
                error={(touched.email && errors.email) ? true : null}
                fullWidth
                margin="dense"
              >
                <InputLabel htmlFor="component-error" margin="dense">Email</InputLabel>
                <Input
                  id="email"
                  name="email"
                  type="text"
                  label=""
                  aria-describedby="component-error-text"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.email && errors.email && (
                  <FormHelperText id="component-error-text">
                    {errors.email}
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl
                error={(touched.password && errors.password) ? true : null}
                fullWidth
                margin="dense"
              >
                <InputLabel htmlFor="component-error" margin="dense">Password</InputLabel>
                <Input
                  id="password"
                  name="password"
                  type="password"
                  label=""
                  aria-describedby="component-error-text"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.password && errors.password && (
                  <FormHelperText id="component-error-text">
                    {errors.password}
                  </FormHelperText>
                )}
              </FormControl>

              <div className="kt-login__actions">
                <Button
                  id="kt_login_signin_back"
                  variant="contained"
                  color="primary"
                  className="ppp-page__form__btn btn-capitalize"
                  onClick={() => setShowForm(false)}
                >
                  Back
                </Button>
                <Button
                  id="kt_login_signin_submit"
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  className="ppp-page__form__btn btn-capitalize"
                >
                  Sign In
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    )
  }

  return (
    <div className="before-form">
      <img className="ppp-logo" src={toAbsoluteUrl("/media/logos/PPPLogoBLUETransparent.png")} alt="ppp-logo"/>
      <p className="ppp-logo-text">
        Integrate ProfitProtectorPro - The World's Most Powerful Algorithmic Repricer -
        With SKUify For Increasing Your Sales & Your Profit Seamlessly From One Interface.
      </p>
      <div className="wrap-btn">
        <DButton className="btn-capitalize" onClickCustom={handlerSingIn}>Sign in</DButton>
        <Link href="https://www.profitprotectorpro.com/index.php#pricing" target="_blank">
          <DButton className="btn-capitalize">Sign up</DButton>
        </Link>
      </div>
    </div>
  )
};

export default FirstVisit;