export const dataTotalTable = (nameTable) => {
  switch (nameTable) {
    case "Sales":
      return [{
        id: 0,
        name: "Total Sales",
        data: {
          balance: 33379.64,
          currency: {
            id: 156,
            name: "British Pound",
            currency_code: "GBP",
            currency_symbol: "£",
          }
        }}];
    case "Direct Costs":
      return [{
        id: 0,
        name: "Total Direct Costs",
        data: {
          balance: 10185.10,
          currency: {
            id: 157,
            name: "United States Dollar",
            currency_code: "USD",
            currency_symbol: "$",
          }
        }},
        {
          id: 1,
          name: "Gross Profit",
          data: {
            balance: 96194.54,
            currency: {
              id: 157,
              name: "United States Dollar",
              currency_code: "USD",
              currency_symbol: "$",
            }
          }}];
    case "Less Expenses":
      return [{
        id: 0,
        name: "Total Expense",
        data: {
          balance: 297.25,
          currency: {
            id: 157,
            name: "United States Dollar",
            currency_code: "USD",
            currency_symbol: "$",
          }
        }},
        {
          id: 1,
          name: "Net Profit",
          data: {
            balance: 22491.79,
            currency: {
              id: 157,
              name: "United States Dollar",
              currency_code: "USD",
              currency_symbol: "$",
            }
          }}];
    case "Assets":
      return [{
        id: 0,
        name: "Total Assets",
        data: {
          balance: 16879.64,
          currency: {
            id: 156,
            name: "British Pound",
            currency_code: "GBP",
            currency_symbol: "£",
          }
        }}];
    case "Liabilities":
      return [{
        id: 0,
        name: "Total Liabilities",
        data: {
          balance: 297.25,
          currency: {
            id: 157,
            name: "United States Dollar",
            currency_code: "USD",
            currency_symbol: "$",
          }
        }},
        {
          id: 1,
          name: "Assets - Liabilities",
          data: {
            balance: 22491.79,
            currency: {
              id: 157,
              name: "United States Dollar",
              currency_code: "USD",
              currency_symbol: "$",
            }
          }}];
    case "Capital":
      return [{
        id: 0,
        name: "Total Capital",
        data: {
          balance: 79.64,
          currency: {
            id: 156,
            name: "British Pound",
            currency_code: "GBP",
            currency_symbol: "£",
          }
        }}];
    default:
      return [{
      id: 0,
      name: "Total",
      data: {
        debit: 33379.64,
        credit: 33379.64,
        currency: {
          id: 156,
          name: "British Pound",
          currency_code: "GBP",
          currency_symbol: "£",
        }
      }}];
  }
};