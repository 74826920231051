import React, { useState } from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import Wrapper from "../../components/Wrapper";
import FormField from "../../components/controls/FormField";
import { ActionButtonsNewBankModal } from "../Transaction/NewBankTransaction/ActionButtonsNewBankModal";
import DButton from "../../components/Button";
import useCurrencies from "../../../hooks/currencies.hook";
import { FieldArray, Form as FormikForm, Formik } from "formik";
import Typography from "@material-ui/core/Typography";
import SearchIcon from '@material-ui/icons/Search';
import useCategories from "../../../hooks/categories.hook";
import { requiredValidator } from "../../components/controls/validators";
import AdjustmentsModal, { adjustmentsItems } from "./AdjustmentsModal";
import { useModal, useParseHistory } from "../../../hooks/modal.hook";
import { useHistory } from "react-router-dom";
import { goToCategoryPage, goToNewAdjustmentPage } from "./utils";
import Notification from "../../components/Notification";
import ReceiptUploadsModal from "../../components/ReceiptUploadsModal/ReceiptUploadsModal";
import { onValidation } from "../../components/CreateEditModal";
import withConfirmHook from "../../../hooks/withConfirm.hook";
import { getInvoicePageName, goToRecurringTransactionsPage } from "../Transaction/utils";
import CreateEditRecurTransModal from "../Tools/RecurringTransactions/CreateEditRecurTransModal";
import { DFab } from "../../components/TableComponent/TableToolbarActions";

const initAmounts = {
  category: undefined,
  debit_amount: undefined,
  credit_amount: undefined,
};

const rates = [
  {currency: "British Pound", rate: "0"},
  {currency: "United States Dollar", rate: "0.754716"},
  {currency: "Euro", rate: "0.846713"},
];

const getAmountsFieldName = (index, name) => `amounts.${index}.${name}`;
const typeOfToNumber = (value) => {
  if (typeof value === "string") {
    return parseFloat(value)
  } else return value;
};

const CreateEditAdjustment = (props) => {
  const {
    // getAdjustments,
    // deleteAdjustment,
    // adjustments,
    // adjustment,
    // getAdjustment,
    // updateAdjustment,
    // addAdjustment,
  } = props;
  const {currencies} = useCurrencies();
  const {categories} = useCategories();
  const [isModalShow, toggleModalShow] = useModal();
  const [isNewRecurTransModalShow, toggleNewRecurTransModalShow] = useModal();
  const [isReceiptUploadsModalShow, toggleReceiptUploadsModal] = useModal();

  const [errors, setErrorsHandle] = useState([]);
  const [isShowErrors, setIsShowErrors] = useState(false);
  const toggleShowErrors = () => setIsShowErrors(false);
  let history = useHistory();
  const pathName = history.location.pathname;
  const newRecur = getInvoicePageName(pathName) === "new recurring adjustment";
  const adjustmentId = useParseHistory().adjustment_id;
  const editRecur = useParseHistory().type;

  // useEffect(() => {
    // getAdjustments({ companyId: getCurrentCompanyId() });
  // if (adjustmentId) {
    // getAdjustment({ companyId: getCurrentCompanyId(), id: adjustmentId });
  // };
  // }, []);

  // when will be api need remove currentAdjustment
  const currentAdjustment = adjustmentId
    && adjustmentsItems.find(adjustment => adjustment.id === +adjustmentId);

  let initialData;
  // when will be api need replace currentAdjustment with adjustment
  if (currentAdjustment) {
    initialData = currentAdjustment
  } else {
    initialData = {
      amounts: [initAmounts, initAmounts,]
    };
  }

  const newAdjustment = [
    {
      // TODO rate must be related to date and currency
      id: 'date',
      label: 'Date',
      type: 'date',
      validator: requiredValidator('Date is required'),
    },
    {
      id: 'currency',
      label: 'Currency For Splits',
      type: 'RSelect',
      customProps: () => ({ setFieldValue }) => {
        const onChange = (value) => {
          // TODO rate must be related to date and currency
          const valueConvRate = rates.find(item =>
            item.currency === value.label);
          const rate = valueConvRate ? valueConvRate.rate : 0.1234;
          setFieldValue("currency", value);
          setFieldValue("conv_rate", rate)
        };
        return {
          onChange,
          options: currencies.details.map(currency => ({ label: currency.name, value: currency.id, })),
        }
      },
    },
    {
      id: 'conv_rate',
      label: 'Conversion Rate',
      type: 'number',
      customProps: () => ({ setFieldValue }) => {
        // TODO rate must be related to date and currency
        const onChange = (e) => {
          setFieldValue("conv_rate", e.target.value);
        };
        return { onChange }
      },
    },
    {
      id: 'description',
      label: 'Description',
      type: 'text',
      validator: requiredValidator("Description can't be blank"),
    },
  ];

  const recurringAdjustment = [
    {
      id: 'currency',
      label: 'Currency',
      type: 'RSelect',
      customProps: () => ({ setFieldValue }) => {
        const onChange = (value) => {
          // TODO rate must be related to date and currency
          const valueConvRate = rates.find(item =>
            item.currency === value.label);
          const rate = valueConvRate ? valueConvRate.rate : 0.1234;
          setFieldValue("currency", value);
          setFieldValue("conv_rate", rate)
        };
        return {
          onChange,
          options: currencies.details.map(currency => ({ label: currency.name, value: currency.id })),
        }
      },
    },
    {
      id: 'description',
      label: 'Description',
      type: 'text',
      validator: requiredValidator("Description can't be blank"),
    },
  ];

  const amounts = [
    {
      id: 'category',
      label: 'Category',
      type: 'RSelect',
      // validator: requiredValidator("Account can't be blank"),
      customProps: () => () => ({
          options: categories.details.map((category) => ({ label: category.name, value: category.id }))
      }),
    },
    {
      id: 'debit_amount',
      label: 'Debit Amount',
      type: 'number',
      customProps: () => ({ index, setFieldValue, values }) => {
        const onChange = (e) => {
          // set Debit Amount to the row
          setFieldValue(getAmountsFieldName(index, 'debit_amount'), e.target.value);
          // correct total debit amount
          if (values.amounts[index].credit_amount) {
            let currentCredit = values.amounts[index].credit_amount;
            let currentTotalCreditAmount = typeOfToNumber(values.total_credit_amount) - typeOfToNumber(currentCredit);
            setFieldValue("total_credit_amount", currentTotalCreditAmount); // set corrected total credit amount
          }
          // remove Credit Amount to the row
          setFieldValue(getAmountsFieldName(index, 'credit_amount'), undefined);
        };
        const onBlur = () => {
          let count = 0;
          values.amounts.forEach(amount => {
            if (amount.debit_amount) {
              count = count + parseFloat(amount.debit_amount);
            }
          });
          setFieldValue("total_debit_amount", count); // set corrected total debit amount
        };
        return { onChange, onBlur }
      },
      // validator: requiredValidator("Amount can't be blank"),
    },
    {
      id: 'credit_amount',
      label: 'Credit Amount',
      type: 'number',
      customProps: () => ({ index, setFieldValue, values }) => {
        const onChange = (e) => {
          // set Credit Amount to the row
          setFieldValue(getAmountsFieldName(index, 'credit_amount'), e.target.value);
          // correct total debit amount
          if (values.amounts[index].debit_amount) {
            let currentDebit = values.amounts[index].debit_amount;
            let currentTotalDebitAmount = typeOfToNumber(values.total_debit_amount) - typeOfToNumber(currentDebit);
            setFieldValue("total_debit_amount", currentTotalDebitAmount); // set corrected total debit amount
          }
          // remove Debit Amount to the row
          setFieldValue(getAmountsFieldName(index, 'debit_amount'), undefined);
        };

        const onBlur = () => {
          let count = 0;
          values.amounts.forEach(amount => {
            if (amount.credit_amount) {
              count = count + parseFloat(amount.credit_amount);
            }
          });
          setFieldValue("total_credit_amount", count); // set corrected total credit amount
        };
        return { onChange, onBlur }
      },
      // TODO add column validation
      // validator: requiredValidator("Amount can't be blank"),
    },
  ];

  const total = [
    // TODO add total amount validation
    { id: 'total_debit_amount', label: 'Debit Amount', type: 'number', disabled: true },
    { id: 'total_credit_amount', label: 'Credit Amount', type: 'number', disabled: true },
  ];

  const fieldsArray = (newRecur || editRecur) ? recurringAdjustment : newAdjustment;

  const onBack = (resetForm) => () => {
    if (newRecur || editRecur) {
      goToRecurringTransactionsPage(history)
    } else if (currentAdjustment) {
      resetForm();
      goToNewAdjustmentPage(history);
    } else {
      goToCategoryPage(history)
    }
  };

  const addNewAmounts = (arrayHelpers) => () => {
    arrayHelpers.push(initAmounts)
  };

  const removeAmounts = (arrayHelpers, index, amounts) => () => {
    if (amounts.length > 2) {
      arrayHelpers.remove(index);
    }
  };

  const onNewRecur = () => {
    toggleNewRecurTransModalShow();
  };

  const resetValue = (data) => {
    setErrorsHandle([]);
    if (data) {
      if (!data.total_debit_amount || data.total_debit_amount === 0) {
        setErrorsHandle(old => [...old, "Debit can't be blank"])
      }
      if (!data.total_credit_amount || data.total_credit_amount === 0) {
        setErrorsHandle(old => [...old, "Credit can't be blank"])
      }
      if (data.total_debit_amount !== data.total_credit_amount) {
        setErrorsHandle(old => [...old, "The credit and debit amounts must be equal"]);
      }
      if (data.amounts.find(amount => !amount.category)) {
        setErrorsHandle(old => [...old, "Account can't be blank"]);
      }
      // if (errors.length !== 0) {
      //   setErrorsHandle(old => [...old, "Adjustment amount is invalid"])
      // }
    }
  };

  let title;
  if (editRecur) {
    title = "Edit Recurring Adjustment";
  } else if (newRecur) {
    title = "New Recurring Adjustment"
  } else if (adjustmentId) {
    title = "Edit Adjustment"
  } else {
    title = "New Adjustment"
  }

  return <Formik
    enableReinitialize
    initialValues={initialData}
    onSubmit={(values, { setSubmitting, resetForm }) => {
      const data = {...values};
      // const onDone = () => {
      //   setSubmitting(false);
      //   resetForm();
      // };
      // const onError = (errors) => {
      //   toast.error(errors);
      //   setSubmitting(false);
      // };
      resetValue(data);
      if (errors.length !== 0) {
        setIsShowErrors(true);
        return;
      }
      data.amounts = data.amounts.map((amount) => ({
        ...amount,
        category: amount.category.value,
      }));
      // if (currentAdjustment) {
      //   updateAdjustments({
      //     id: adjustment_id,
      //     values: data,
      //     onDone,
      //     onError,
      //   })
      // } else {
      //   addAdjustments({
      //     values: data,
      //     onDone,
      //     onError,
      //   })
      // }
    }}
  >
    {({...form}) => {
      const data = {...form.values};
      const resetValue = () => {
        setErrorsHandle([]);
        if (data) {
          if (!data.total_debit_amount || data.total_debit_amount === 0) {
            setErrorsHandle(old => [...old, "Debit can't be blank"])
          }
          if (!data.total_credit_amount || data.total_credit_amount === 0) {
            setErrorsHandle(old => [...old, "Credit can't be blank"])
          }
          if (data.total_debit_amount !== data.total_credit_amount) {
            setErrorsHandle(old => [...old, "The credit and debit amounts must be equal"]);
          }
          if (data.amounts.find(amount => !amount.category)) {
            setErrorsHandle(old => [...old, "Account can't be blank"]);
          }
          // if (errors.length !== 0) {
          //   setErrorsHandle(old => [...old, "Adjustment amount is invalid"])
          // }
        }
      };
      return (
        <FormikForm>
          <Wrapper className="new-adjustment">
            <div className="new-adjustment__title">
              <Typography variant="h6" className="new-adjustment__title--text">{title}</Typography>
              {
                !(newRecur || editRecur)
                && <div className="new-adjustment__title__actions">
                  <DFab onClick={toggleModalShow}><SearchIcon/></DFab>
                  {/*<DFab onClick={toggleReceiptUploadsModal}><AttachFileIcon/></DFab>*/}
                </div>
              }
            </div>
            {isShowErrors && <Notification errors={errors} onClose={toggleShowErrors} name="Adjustment" />}
            <div className="row">
              <Table className="form__table">
                <TableHead>
                  <TableRow className="form__row">
                    {fieldsArray.map(item => (
                      <TableCell key={item.id} className="form__cell">{item.label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow className="form__row form__row--body">
                    {fieldsArray.map((item) => (
                      <TableCell
                        key={item.id}
                        aria-label={item.label}
                        className={"form__cell cell__" + item.id}
                      >
                        <FormField
                          name={item.id}
                          label={item.label}
                          type={item.type}
                          validate={item.validator}
                          customProps={item.customProps && item.customProps()}
                          {...form}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </div>
            <Typography variant="h6" className="new-adjustment__title--text">Amounts</Typography>
            <div className="row">
              <Table className="form__table">
                <TableHead>
                  <TableRow className="form__row">
                    {amounts.map(item => (
                      <TableCell key={item.id} className={"form__cell cell__" + item.id}>{item.label}</TableCell>
                    ))}
                    <TableCell className="form__cell cell__actions">Actions</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <FieldArray
                    name="amounts"
                    render={(arrayHelpers) => {
                      return form.values.amounts.map((values, index) => {
                        return <TableRow key={index} className="form__row form__row--body">
                          {amounts.map((item) => (
                            <TableCell
                              key={item.id} aria-label={item.label}
                              className={"form__cell cell__" + item.id}
                            >
                              <FormField
                                name={`amounts.${index}.${item.id}`}
                                label={item.label}
                                index={index}
                                type={item.type}
                                validate={item.validator}
                                customProps={item.customProps && item.customProps()}
                                {...form}
                              />
                            </TableCell>
                          ))}
                          <TableCell aria-label="Actions" className="form__cell cell__actions">
                            <ActionButtonsNewBankModal
                              addNewRow={addNewAmounts(arrayHelpers)}
                              deleteTransaction={removeAmounts(arrayHelpers, index, form.values.amounts)}
                            />
                          </TableCell>
                        </TableRow>
                      })
                    }}
                  />
                  <TableRow className="form__row form__row--body">
                    <TableCell aria-label="Total" className="form__cell cell__total">Total</TableCell>
                    {total.map((item) => (
                      <TableCell
                        key={item.id}
                        aria-label={item.label}
                        className={"form__cell cell__" + item.id}
                      >
                        <FormField
                          showLabel={false}
                          name={item.id}
                          label={item.label}
                          type={item.type}
                          disabled={item.disabled}
                          {...form}
                        />
                      </TableCell>))}
                    <TableCell className="form__cell" />
                  </TableRow>
                </TableBody>
              </Table>
            </div>
            <div className="container-button container-button--modal new-adjustment__bottom-btns">
              {newRecur || editRecur
                ? <DButton
                  typeOfButton="filter"
                  type="submit"
                  onClickCustom={newRecur
                    ? onNewRecur
                    : onValidation(form.validateForm, setErrorsHandle, setIsShowErrors, resetValue)}
                >
                  Save and Recur
                </DButton>
                : <DButton
                  typeOfButton="doneAllSecondary"
                  type="submit"
                  onClickCustom={onValidation(form.validateForm, setErrorsHandle, setIsShowErrors, resetValue)}
                >
                  Save
                </DButton>}
              <DButton typeOfButton="backPrimary" onClickCustom={onBack(form.resetForm)}>Back</DButton>
            </div>
            <AdjustmentsModal onClose={toggleModalShow} show={isModalShow} />
            <ReceiptUploadsModal show={isReceiptUploadsModalShow} onClose={toggleReceiptUploadsModal} />
            <CreateEditRecurTransModal show={isNewRecurTransModalShow} onClose={toggleNewRecurTransModalShow} />
          </Wrapper>
        </FormikForm>
      )
    }}
  </Formik>
};

export default withConfirmHook(CreateEditAdjustment);