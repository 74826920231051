import React, {useEffect} from "react";
import {connect} from "react-redux";
import moment from 'moment';
import _ from 'lodash';
import * as categoryDuck from "../../../../store/ducks/category.duck";
import * as BankDuck from "../../../../store/ducks/bunk.duck";
import * as TransactionsDuck from "../../../../store/ducks/transactions.duck";
import * as CategorySelector from "../../../../selectors/category.selectors";
import * as TransactionsSelector from "../../../../selectors/transactions.selectors";
import * as PropTypes from "prop-types";
import {Table, TableHead, TableBody, TableRow, TableCell, Typography} from "@material-ui/core";
import {Form as FormikForm, Formik} from "formik";
import FormField from "../../../components/controls/FormField";
import {typeEditorOptions} from "../bankOptions";
import DButton from "../../../components/Button";
import * as commonDuck from "../../../../store/ducks/common.duck";
import * as commonSelectors from "../../../../selectors/common.selectors";
import * as bankSelectors from "../../../../selectors/bank.selectors";
import {requiredValidator} from "../../../components/controls/validators";
import {getCurrentCompanyId} from "../../../../crud/utils";
import Wrapper from "../../../components/Wrapper";
import AutocompleteWithTooltip from "../NewBankTransaction/AutocompleteWithTooltip";
import {useHistory} from "react-router-dom";
import {goToTransactionsListPage} from "../utils";

const BulkTransactionEditor = (props) => {
  const {
    taxes,
    getTaxes,
    banks,
    getBanks,
    getCategories,
    categories,
    getDescriptions,
    descriptions
  } = props;

  useEffect(() => {
    getCategories();
    getTaxes();
    getBanks();
    getDescriptions({companyId: getCurrentCompanyId()});
  }, []);

  const initialData = {
    date: '2020-01-01',
    bank_accounts: undefined,
    type: undefined,
    category: undefined,
    description: undefined,
    tax_code: undefined,
  };

  const transactionsEditorInterface = [
    {
      id: 'date',
      label: 'Date',
      type: 'date',
      validator: requiredValidator("Date is required"),
    },
    {
      id: 'bank_accounts',
      label: 'Bank Account',
      type: 'RSelect',
      validator: requiredValidator("Bank Account is required"),
      customProps: () => {
        return {
          options: banks.map((bank) => (
            {
              label: bank.name,
              value: bank.id,
              bank
            }
          ))
        }
      },
    }, {
      id: 'type',
      label: 'Type',
      type: 'RSelect',
      validator: requiredValidator("Type is required"),
      customProps: () => {
        return {
          options: typeEditorOptions.map(({label, value}) => (
            {
              label: `${label} | ${value}`,
              value
            }
          ))
        }
      },
    },
    {
      id: 'category',
      label: 'Category',
      type: 'RSelect',
      validator: requiredValidator('Category is required'),
      customProps: () => ({setFieldValue, values}) => {
        const onChange = ({category, ...value}) => {
          setFieldValue('category', {...value, category});

          // if category doesn't have default_tax_code set NV
          const nvTax = taxes.find(({taxcode}) => taxcode === "NV");
          setFieldValue('tax_code', category.default_tax_code || nvTax.id);
        };
        return {
          onChange,
          options: categories.map((category) => ({label: category.name, value: category.id, category}))
        }
      }
    },
    {
      id: 'description',
      label: 'Description',
      type: 'text',
      validator: requiredValidator("Description is required"),
      render: (props) => {
        const onChange = (e, value) => {
          props.setFieldValue(props.name, value);
        };
        return <div>
          <AutocompleteWithTooltip
            onChange={onChange}
            value={props.value}/>
        </div>
      }
    },
    {
      id: 'tax_code',
      validator: requiredValidator("Tax Code is required"),
      label: 'Tax Code',
      type: 'RSelect',
      customProps: () => {
        return {
          options: taxes.map((tax) => {
              return {label: tax.name, value: tax.id,}
            }
          )
        }
      },
    },
  ];

  let history = useHistory();
  const onBack = () => goToTransactionsListPage(history);

  const onSubmit = (values, {setSubmitting, resetForm}) => {
    // BankApi.batchCreateTransaction([values]).then(() => {
    //   setSubmitting(false);
    //   // resetForm(initialData);
    //   // setIsShowSuccess(true);
    // }).catch((e) => {
    //   setSubmitting(false);
    //   logger.error(e);
    // });
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initialData}
      onSubmit= {async (data, actions) => {
        const values = _.clone(data);
        values.company = getCurrentCompanyId();
        values.bank_accounts = values.bank_accounts.value;
        values.date = moment(values.date).format('YYYY-MM-DDThh:mm');
        values.type = values.type.value;
        values.category = values.category.value;
        values.tax_code = values.tax_code.value;
        await onSubmit(values, actions);
      }}
    >
      {({...form}) => {
        return (
          <Wrapper>
            <Typography gutterBottom variant="h5" component="h2" className="bulk-editor__title">
              Bulk Transaction Editor
            </Typography>
            <Typography gutterBottom variant="body1" className="bulk-editor__text">
              Changes to the entries below will amend all selected transactions.
              If you do not want to change an entry then please leave it blank.
            </Typography>
            <Typography gutterBottom variant="body1" className="bulk-editor__text">
              Changes here can affect price, net and tax amounts for selected transactions.
              However, the total amount will remain the same.
            </Typography>
            <Typography gutterBottom variant="body1" className="bulk-editor__text">
              The currency and conversion rates for any transaction will remain the same, even if the date is changed.
              If it is your intent for the currency and/or conversion rate to change,
              you will need to update these transactions individually.
            </Typography>
            <FormikForm>
              <div className="new-bank-transaction bulk-editor">
                  <Table className="form__table">
                    <TableHead>
                      <TableRow className="form__row">
                        {transactionsEditorInterface.map(item => (
                            <TableCell key={item.id} className="form__cell">{item.label}</TableCell>))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow className="form__row form__row--body">
                        {
                          transactionsEditorInterface.map((item) => (
                            <TableCell key={item.id} aria-label={item.label} className={"form__cell cell__" + item.id}>
                              <FormField
                                render={item.render}
                                name={item.id}
                                label={item.label}
                                type={item.type}
                                validate={item.validator}
                                customProps={item.customProps && item.customProps()}
                                {...form}
                              />
                            </TableCell>
                          ))
                        }
                      </TableRow>
                    </TableBody>
                  </Table>
                <div className="container-button container-button--modal container-button--settings-books">
                  <DButton typeOfButton="doneAllSecondary" disabled={form.isSubmitting} type="submit">Submit</DButton>
                  <DButton typeOfButton="backPrimary" onClickCustom={onBack}>Back</DButton>
                </div>
              </div>
            </FormikForm>
          </Wrapper>
        )
      }}
    </Formik>
  )
};

BulkTransactionEditor.propTypes = {
  getCategories: PropTypes.func.isRequired,
  getBanks: PropTypes.func,
  banks: PropTypes.array,
  categories: PropTypes.array.isRequired,
  // descriptions: PropTypes.array,
  getDescriptions: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    categories: CategorySelector.getCategories(state, props),
    descriptions: TransactionsSelector.getDescriptions(state, props),
    taxes: commonSelectors.getTaxes(state),
    banks: bankSelectors.getBanks(state),
  }
};

const mapDispatchToProps = {
  getCategories: categoryDuck.actions.getCategories,
  getBanks: BankDuck.actions.getBanks,
  getDescriptions: TransactionsDuck.actions.getDescriptions,
  getTaxes: commonDuck.actions.getTaxes,
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkTransactionEditor);