import { createSelector } from "reselect";


export const getGroups = createSelector(
  state => state.common,
  (common) => common.groups,
);

export const getPermissions = createSelector(
  state => state.common,
  (common) => common.permissions,
);

export const getTaxes = createSelector(
  state => state.common,
  (common) => common.taxes,
);

export const getCurrencies = createSelector(
  state => state.common,
  (common) => common.currencies,
);