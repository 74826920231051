export const rois = {
  roi1: {
    title:'Method 1 - Default Method on BBP',
    disabled: false,
    vat_exempt_on_amazon: {
      text1:'Profit (Sell price - COG (COG = product\u00A0cost, shipping, Prep, Bundle, Extra\u00A0fees, storage\u00A0costs, Amazon\u00A0Fees, VAT))',
      text2:'Buy Price',
      text3:'* 100 = ROI'
    },
    is_vat_registered: {
      text1:'Profit (Sell price - COG (COG = product\u00A0cost, shipping, Prep, Bundle, Extra\u00A0fees, storage\u00A0costs, Amazon\u00A0Fees, VAT))',
      text2:'Buy Price',
      text3:'* 100 = ROI'
    },
    scheme: {
      text1:'Profit (Sell price - COG (COG = product\u00A0cost, shipping, Prep, Bundle, Extra\u00A0fees, storage\u00A0costs, Amazon\u00A0Fees, VAT))',
      text2:'Buy Price',
      text3:'* 100 = ROI'
    },
  },

  roi2: {
    title:'Method 2 - Excludes All Amazon Fees',
    disabled: false,
    vat_exempt_on_amazon: {
      text1:'Sell price - COG (COG = product\u00A0cost, shipping, Prep, Bundle, Extra\u00A0fees)',
      text2:'COG (COG = Product\u00A0cost, Shipping, Prep, Bundle, Extra\u00A0fees)',
      text3:'* 100 = ROI'
    },
    is_vat_registered: {
      text1:'Sell price - COG (COG = product\u00A0cost, shipping, Prep, Bundle, Extra\u00A0fees, VAT)',
      text2:'COG (COG = Product\u00A0cost, Shipping, Prep, Bundle, Extra\u00A0fees, VAT)',
      text3:'* 100 = ROI'
    },
    scheme: {
      text1:'Sell price - COG (Product\u00A0Cost + Shipping + Prep + Bundle + Extra\u00A0fees + 7.50%\u00A0of\u00A0Sell\u00A0Price)',
      text2:'COG (Product\u00A0Cost + Shipping + Prep + Bundle + Extra\u00A0fees + 7.50%\u00A0of\u00A0Sell\u00A0Price)',
      text3:'* 100 = ROI'
    },
  },

  roi3: {
    title:'Method 3 - Excludes Storage Fees',
    disabled: false,
    vat_exempt_on_amazon: {
      text1: "Sell price - COG (COG = product\u00A0cost, shipping, Prep, Bundle, Extra\u00A0fees, Amazon\u00A0fees)",
      text2: "COG (COG = Product\u00A0cost, shipping, Prep, Bundle, Extra\u00A0fees, Amazon\u00A0fees)",
      text3:'* 100 = ROI',
    },
    is_vat_registered: {
      text1: "Sell price - COG (COG = product\u00A0cost, shipping, Prep, Bundle, Extra\u00A0fees, Amazon\u00A0Fees, VAT)",
      text2: "COG (COG = Product\u00A0cost, shipping, Prep, Bundle, Extra\u00A0fees, Amazon\u00A0fees, VAT)",
      text3:'* 100 = ROI',
    },
    scheme: {
      text1: "Sell price - COG (Product\u00A0Cost + Shipping + Prep + Bundle + Extra\u00A0fees + Amazon\u00A0fees + 7.50%\u00A0of\u00A0Sell\u00A0Price)",
      text2: "COG (Product\u00A0Cost + Shipping + Prep + Bundle + Extra\u00A0fees + Amazon\u00A0fees + 7.50%\u00A0of\u00A0Sell\u00A0Price)",
      text3:'* 100 = ROI',
    },
  },

  roi4: {
    title:'Method 4 - Comprehensive ROI',
    disabled: false,
    vat_exempt_on_amazon: {
      text1: "Sell price - COG (COG = product\u00A0cost, shipping, Prep, Bundle, Extra\u00A0fees, storage\u00A0costs, Amazon\u00A0fees)",
      text2: "COG (COG = Product\u00A0cost, shipping, Prep, Bundle, Extra\u00A0fees, storage\u00A0costs, Amazon\u00A0fees)",
      text3:'* 100 = ROI',
    },
    is_vat_registered: {
      text1: "Sell price - COG (COG = product cost, shipping, Prep, Bundle, Extra fees, storage costs, Amazon fees, VAT)",
      text2: "COG (COG = Product\u00A0cost, shipping, Prep, Bundle, Extra\u00A0fees, storage\u00A0fees, Amazon\u00A0fees, VAT)",
      text3:'* 100 = ROI',
    },
    scheme: {
      text1: "Sell price - COG (Product Cost + Shipping + Prep + Bundle + Extra fees + Storage + Amazon fees + 7.50% of Sell Price)",
      text2: "COG (Product\u00A0Cost + Shipping + Prep + Bundle + Extra\u00A0fees + Storage + Amazon\u00A0fees + 7.50%\u00A0of\u00A0Sell\u00A0Price)",
      text3:'* 100 = ROI',
    },
  },

  roi5: {
    title:'Method 5 - US & Canadian Sellers (Coming soon)',
    disabled: true,
    vat_exempt_on_amazon: {
      text1: "Sell Price - COG",
      text2: "Product\u00A0Cost, Shipping, Prep, Bundle\u00A0Fee, Extra\u00A0Fees, All\u00A0Amazon\u00A0Fees (All\u00A0Excluding\u00A0VAT)",
      text3:'* 100 = ROI',
    },
    is_vat_registered: {
      text1: "Sell Price - COG",
      text2: "Product\u00A0Cost, Shipping, Prep, Bundle\u00A0Fee, Extra\u00A0Fees, All\u00A0Amazon\u00A0Fees (All\u00A0Excluding\u00A0VAT)",
      text3:'* 100 = ROI',
    },
    scheme: {
      text1: "Sell Price - COG",
      text2: "Product\u00A0Cost, Shipping, Prep, Bundle\u00A0Fee, Extra\u00A0Fees, All\u00A0Amazon\u00A0Fees (All\u00A0Excluding\u00A0VAT)",
      text3:'* 100 = ROI',
    },
  },

  roi6: {
    title:'Method 6 - Profit Over Total Costs',
    disabled: false,
    vat_exempt_on_amazon: {
      text1:'Profit',
      text2:'Product Cost + Total costs',
      text3:'* 100 = ROI',
    },
    is_vat_registered: {
      text1:'Profit',
      text2:'Product Cost + Total costs',
      text3:'* 100 = ROI'
    },
    scheme: {
      text1:'Profit',
      text2:'Product Cost + Total costs',
      text3:'* 100 = ROI',
    },
  },

  roi7: {
    title:'Method 7 - Profit Over Total COGs',
    disabled: false,
    vat_exempt_on_amazon: {
      text1: "Profit",
      text2: "Product Cost + Total COGs",
      text3:'* 100 = ROI',
    },
    is_vat_registered: {
      text1: "Profit",
      text2: "Product Cost + Total COGs",
      text3:'* 100 = ROI',
    },
    scheme: {
      text1: "Profit",
      text2: "Product Cost + Total COGs",
      text3:'* 100 = ROI',
    },
  },
};