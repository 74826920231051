import React, {useCallback, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {TextField, Typography} from "@material-ui/core";
import {Formik} from "formik";
import {injectIntl} from "react-intl";
import clsx from "clsx";
import {login} from "../../../crud/auth.crud";
import {connect} from "react-redux";
import * as auth from "../../../store/ducks/auth.duck";
import {useNotification} from "../../../hooks/notification.hook";
import {GoogleReCaptchaProvider, useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import toast from "../../components/toast/toast";


const FormComponent = (props) => {
  const {intl, bottom, onAuth} = props;
  const [loading, setLoading] = useState(false);
  const [remember, setRemember] = useState(false);
  const [error, setError] = useState(null);
  const [errNotification, setIsShowNotification] = useNotification(false, "error", error && error.detail);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });

  const [tokenGoogle, setToken] = useState("");
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    const recTokenResult = await executeRecaptcha('contactForm');
    setToken(recTokenResult)
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({paddingRight: "3.5rem"});
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({paddingRight: "2.5rem"});
  };

  const onChangeRemember = () => {
    setRemember((old) => !old)
  };

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        remember: false,
      }}
      validate={values => {
        const errors = {};

        if (!values.email) {
          // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
          errors.email = intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD"
          });
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = intl.formatMessage({
            id: "AUTH.VALIDATION.INVALID_FIELD"
          });
        }

        if (!values.password) {
          errors.password = intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD"
          });
        }

        return errors;
      }}
      onSubmit={(values, {setStatus, setSubmitting}) => {
        if (tokenGoogle) {
          handleReCaptchaVerify().then(function(){
            enableLoading();
            login(values.email, values.password)
              .then((res) => {
                if (res?.data?.access) {
                  props.login(res.data.access);
                  onAuth && onAuth();
                }
                setSubmitting(false);
                disableLoading();
              })
              .catch((error) => {
                setError(error.response && error.response.data);
                setIsShowNotification(true);
                disableLoading();
                setSubmitting(false);
                setStatus(
                  intl.formatMessage({
                    id: "AUTH.VALIDATION.INVALID_LOGIN"
                  })
                );
              });
          });
        } else {
          toast.error("Something went wrong ");
        }
      }}
    >
      {({
          values,
          status,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
        <form autoComplete="off" className="kt-form" onSubmit={handleSubmit}>
          {errNotification}
          <div className="form-group">
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              type="email"
              label="Email"
              margin="normal"
              className="kt-width-full"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email}
              helperText={touched.email && errors.email}
              error={Boolean(touched.email && errors.email)}
            />
          </div>

          <div className="form-group">
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              type="password"
              margin="normal"
              label="Password"
              className="kt-width-full"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.password}
              helperText={touched.password && errors.password}
              error={Boolean(touched.password && errors.password)}
            />
          </div>

          <div className="kt-login__actions">
            {/*<FormControlLabel*/}
              {/*className="form__field field__checkbox"*/}
              {/*name="remember"*/}
              {/*checked={remember}*/}
              {/*onChange={onChangeRemember}*/}
              {/*control={<Checkbox*/}
                {/*name="remember"*/}
                {/*color="primary"*/}
              {/*/>}*/}
              {/*label="Remember me"*/}
              {/*labelPlacement="end"*/}
            {/*/>*/}

            <Link
              to="/auth/forgot-password"
              className="kt-link kt-login__link-forgot"
            >
              <Typography className="onboarding__link">Forgot your password?</Typography>
            </Link>
          </div>

          <div className="kt-login__actions full-size">
            <button
              id="kt_login_signin_submit"
              type="submit"
              disabled={isSubmitting}
              className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                {
                  "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                }
              )}`}
            >
              Log in
            </button>
            <div className="kt-login__head">
              <span className="kt-login__signup-label">
                Don’t have an account?
              </span>
              &nbsp;&nbsp;
              <Link to="/auth" className="kt-link kt-login__signup-link">
                Sign up now
              </Link>
            </div>
          </div>
          {bottom && bottom()}
        </form>
      )}
    </Formik>
  )
};


const LoginForm = (props) => {
  return(
    <GoogleReCaptchaProvider reCaptchaKey="6Lfi_UUoAAAAAGlj0P02UjMhX74M8MYQIOUYN7ir" scriptProps={{ async: true }}>
      <FormComponent {...props} />
    </GoogleReCaptchaProvider>
  )
};

export default injectIntl(
  connect(
    null,
    auth.actions
  )(LoginForm)
);