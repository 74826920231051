import React from "react";
import {integration, pppInterface, buttonInterface, initData, onSubmit} from "./utils";
import IntegrationBlock from "./IntegrationBlock";
import Container from "./Container";
import useSettings from "../../../../hooks/settings.hook";
import useUsers from "../../../../hooks/users.hook";

const Integration = () => {
  const {accountSettings, updateAccountSetting} = useSettings({fetch: false});
  const {user} = useUsers({fetch: false});

  let initialValues = initData;

  if (accountSettings.length !== 0) {
    initialValues = {...accountSettings}
  }

  return (
    <Container
      title="Integrations"
      className="settings-main__integration"
      initData={initialValues}
      onSubmit={onSubmit({user, updateAccountSetting})}
    >
      {
        (form) => (
          <>
            <div className="border-1 radius-small">
              <IntegrationBlock
                title="BuyBotPro"
                form={form}
                formInterface={integration}
                buttonInterface={buttonInterface}
                buttonApiKey="bbp_api_key"
              />
            </div>

            <div className="border-1 radius-small">
              <IntegrationBlock
                title="Profit Protector Pro"
                form={form}
                formInterface={pppInterface}
                buttonInterface={buttonInterface}
                buttonApiKey="ppp_api_key"
              />
            </div>
          </>
        )
      }
    </Container>
  )
};

export default Integration;